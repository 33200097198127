import { useFormikContext } from "formik";
import _ from "lodash";
import * as React from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormTemplatesConstants from "../../../Constants/FormTemplatesConstants";
import { ShowCondition } from "../../../modules/template/domain/types";
import { ApplicationState } from "../../../store";
import {
    PROCESS_ACTIVITY_FORM_CONTENT_HIDDEN_FIELD_ADD,
    PROCESS_ACTIVITY_FORM_CONTENT_HIDDEN_FIELD_REMOVE,
} from "../../../store/process-activity-form/actions";
import FormControlError from "./FormControlError";

interface Props {
    contentConfig: {
        field: string;
        showConditions: ShowCondition[];
    };
}

/**
 *  This component wraps child components and is used to evaluate conditional rendering rules from the items config.
 * @param props
 * @returns
 */
const FormControlBase: React.FC<Props> = (props) => {
    const { contentConfig, children } = props;
    const { values } = useFormikContext();
    const dispatch = useDispatch();

    const hiddenFields = useSelector((state: ApplicationState) => {
        return state.processActivityForm?.ui.viewForm.hiddenFields ?? [];
    });

    // Check for nulls
    if (!contentConfig.showConditions) {
        contentConfig.showConditions = [];
    }

    /**
     *Evaluate a render condition using supported fields
     * @param showCondition
     * @returns boolean of if condition is met
     */

    const checkCondition = (showCondition: ShowCondition): boolean => {
        switch (showCondition.operator) {
            case FormTemplatesConstants.Conditions.Operator.Equals: {
                const value = _.get(values as object, showCondition.field);
                if (!value) return false;

                // Has to pass show condition but don't compare against hidden fields
                return (
                    (value as string) === showCondition.value &&
                    !hiddenFields.includes(showCondition.field)
                );
            }
            default:
                throw Error(
                    `show condition operator '${showCondition.operator}' not supported`,
                );
        }
    };

    const orConditions = contentConfig.showConditions.filter(
        (a) => a.logic === FormTemplatesConstants.Conditions.Logic.Or,
    );

    const andConditions = contentConfig.showConditions.filter(
        (a) => a.logic === FormTemplatesConstants.Conditions.Logic.And,
    );

    // Check for and and or rules compliance
    const anyOrMet = orConditions.some(checkCondition);
    const allAndMet = andConditions.every(checkCondition);
    const show = // If no conditions
        contentConfig.showConditions.length === 0 ||
        // If one or condition is met and no and
        (anyOrMet && allAndMet);

    // If a components show value changes update the hidden fields state
    const [preShow, setPreShow] = React.useState(null);
    React.useEffect(() => {
        if (show !== preShow) {
            if (show && hiddenFields.includes(contentConfig.field)) {
                dispatch({
                    type: PROCESS_ACTIVITY_FORM_CONTENT_HIDDEN_FIELD_REMOVE,
                    data: contentConfig.field,
                });
            }
            if (!show && !hiddenFields.includes(contentConfig.field)) {
                dispatch({
                    type: PROCESS_ACTIVITY_FORM_CONTENT_HIDDEN_FIELD_ADD,
                    data: contentConfig.field,
                });
            }
            setPreShow(show);
        }
    }, [preShow, show, hiddenFields, contentConfig.field, dispatch]);

    // Do not render hidden fields
    if (hiddenFields.includes(contentConfig.field)) {
        return null;
    } else {
        return (
            <>
                <Form.Group key={contentConfig.field}>
                    {children}
                    {contentConfig?.field && (
                        <FormControlError field={contentConfig.field} />
                    )}
                </Form.Group>
            </>
        );
    }
};

export default FormControlBase;
