import { isArray } from "lodash";
import { useOrganisationId } from "../../modules/organisation/hooks/useOrganisationId";
import { hasUserOrgPermission } from "../../store/features/user/user-api-slice";

const useOrgPermission = (permissionName: string | string[]): boolean => {
    const { organisationId } = useOrganisationId();

    if (isArray(permissionName)) {
        return permissionName
            .map(
                (permission) =>
                    hasUserOrgPermission(organisationId, permission)
                        .hasPermission,
            )
            .every(Boolean);
    }

    return hasUserOrgPermission(organisationId, permissionName).hasPermission;
};

export default useOrgPermission;
