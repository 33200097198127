import consumerApiService from "../../../../../customization/api/consumerApiService";
import { postData } from "../../../../../helpers/ApiHelper";
import { ExamManagement } from "../../../examinations/domain/examManagement";
import { ChangeExamTypeModel } from "../../../examinations/domain/examManagementDtos";

enum UrlPaths {
    BaseResource = "organisation",
    ExaminationManagement = "examinationManagement",
    ChangeExamType = "change-exam-type",
}
const getBaseUrl = () =>
    `${consumerApiService.baseApiUrl}/${UrlPaths.BaseResource}`;

export async function getInfiniteExamManagement(
    organisationId: string,
): Promise<ExamManagement[]> {
    const url = `${getBaseUrl()}/${organisationId}/${
        UrlPaths.ExaminationManagement
    }`;

    const result = postData<ExamManagement[]>(url);

    const response = await result;
    if (!response) return;
    return response;
}

interface ScheduleExaminationsArgs {
    organisationId: string;
    patchModel: ChangeExamTypeModel;
}

export function changeExamType({
    organisationId,
    patchModel,
}: ScheduleExaminationsArgs): Promise<void> {
    const url = `${getBaseUrl()}/${organisationId}/${
        UrlPaths.ExaminationManagement
    }/${UrlPaths.ChangeExamType}`;

    return postData(url, patchModel);
}
