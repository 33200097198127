import React, { useState } from "react";
import { useOrgTitleWithShortName } from "../../../../common/hooks/useTitle";
import pageTitles from "./pageTitles";
import Breadcrumbs from "../../../../common/components/breadcrumbs/Breadcrumbs";
import { AppRoutes } from "../../../../router/AppRoutes";
import { useNavigation } from "../../../../router/useNavigate";
import { useParams } from "react-router";
import { Button, Card, Col, Row } from "react-bootstrap";
import IncidentUpdateForm from "../components/IncidentUpdateForm";
import IncidentViewForm from "../components/IncidentViewForm";
import CancelIncidentModal from "../components/modals/CancelIncidentModal";
import { CardBody, CardHeader } from "reactstrap";

const IncidentDetailsPage: React.FC = () => {
    useOrgTitleWithShortName(pageTitles.apcCreate);
    const { getOrgLink: getLink } = useNavigation();
    const { apcId } = useParams<{ apcId: string }>();
    const [editing, setEditing] = useState(false);
    const [cancelling, setCancelling] = useState(false);
    const [allowCancel, setAllowCancel] = useState(true);

    const CancelModelOnClose = () => {
        setCancelling(false);
    };

    if (editing) {
        return (
            <Row>
                <Col>
                    <Row className="mt-3">
                        <Col>
                            <Breadcrumbs
                                items={[
                                    ["APC", getLink(AppRoutes.Apc)],
                                    ["Incident Details"],
                                ]}
                            ></Breadcrumbs>
                        </Col>
                    </Row>
                    <Card className="m-1">
                        <CardHeader>
                            <h2>Incident Details</h2>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <IncidentUpdateForm
                                        incidentId={apcId}
                                        setEditingState={setEditing}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    } else {
        return (
            <>
                {cancelling && (
                    <CancelIncidentModal
                        onClose={CancelModelOnClose}
                        incidentId={apcId}
                    />
                )}
                <Row className="mt-3">
                    <Col>
                        <Breadcrumbs
                            items={[
                                ["APC", getLink(AppRoutes.Apc)],
                                ["Incident Details"],
                            ]}
                        ></Breadcrumbs>
                    </Col>
                </Row>
                <Card className="m-1">
                    <CardHeader>
                        <h2>Incident Details</h2>
                    </CardHeader>
                    <CardBody>
                        <div className="d-flex h-100 mr-3 ml-3">
                            <div className="align-self-start mr-auto">
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        setEditing(true);
                                    }}
                                >
                                    Edit Incident
                                </Button>
                            </div>
                            <div>
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        setCancelling(true);
                                    }}
                                    disabled={!allowCancel}
                                >
                                    Cancel Incident
                                </Button>
                            </div>
                        </div>
                        <Row>
                            <Col>
                                <IncidentViewForm
                                    incidentId={apcId}
                                    allowCancel={setAllowCancel}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </>
        );
    }
};

export default IncidentDetailsPage;
