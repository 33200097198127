import { Tags } from "../../../store/features/dcpApi";

export interface OrganisationAndFolderIds {
    organisationId: string;
    folderId: string;
}

export function getListedFolderTag({
    organisationId,
    folderId,
}: OrganisationAndFolderIds): { id: string; type: Tags } {
    return {
        type: Tags.ListedFolder,
        id: [organisationId, folderId].join("/"),
    };
}
