import {
    Grid,
    GridCellProps,
    GridColumn,
    GridExpandChangeEvent,
} from "@progress/kendo-react-grid";
import React, { useEffect } from "react";
import {
    emptyFilterDescriptor,
    SimplifiedMenuFilter,
} from "../../../../common/components/table/kendo/columnFilters";
import useGridProps from "../../../../common/components/table/kendo/useGridProps";
import { jSDateFromIso } from "../../../../helpers/dateTimeHelpers";
import { Workbank } from "../domain/types";
import WorkbankDetails from "./WorkbankDetails";
import { SortDescriptor } from "@progress/kendo-data-query";
import DownloadCell from "./DownloadCell";

interface Props {
    workbanks: Workbank[];
    organisationId: string;
}

type WorkbankData = {
    expanded?: boolean;
    createdOn: Date;
} & Omit<Workbank, "createdOn">;

function getWorkbankData(workbanks: Workbank[]): WorkbankData[] {
    return workbanks.map(({ createdOn, ...other }) => ({
        expanded: false,
        createdOn: jSDateFromIso(createdOn) || null,
        ...other,
    }));
}

const WorkbanksGrid: React.FC<Props> = ({ workbanks, organisationId }) => {
    const [workbanksData, setWorkbankData] = React.useState<WorkbankData[]>(
        getWorkbankData(workbanks),
    );

    useEffect(() => {
        setWorkbankData(getWorkbankData(workbanks));
    }, [workbanks]);

    const initialSort: Array<SortDescriptor> = [
        { field: "createdOn", dir: "desc" },
    ];

    const { gridProps } = useGridProps(workbanksData, {
        filterable: true,
        initialFilter: emptyFilterDescriptor,
        sortable: true,
        pageable: false,
        initialSort: initialSort,
    });

    const handleExpand = (event: GridExpandChangeEvent) => {
        const newData = workbanksData.map((item: WorkbankData) => {
            if (item.id === event.dataItem.id) {
                item.expanded = !event.dataItem.expanded;
            }
            return item;
        });
        setWorkbankData(newData);
    };

    const GridDownloadCell = (props: GridCellProps) => (
        <DownloadCell cellProps={props} organisationId={organisationId} />
    );

    return (
        <>
            <div className="py-2">
                <Grid
                    {...gridProps}
                    style={{ cursor: "pointer" }}
                    detail={WorkbankDetails}
                    expandField="expanded"
                    onExpandChange={handleExpand}
                >
                    <GridColumn
                        field="name"
                        title="Name"
                        columnMenu={SimplifiedMenuFilter}
                    />
                    <GridColumn
                        field="status"
                        title="Status"
                        columnMenu={SimplifiedMenuFilter}
                    />
                    <GridColumn
                        field="createdOn"
                        title="Creation date"
                        filter={"date"}
                        format="{0:d MMM y h:mm a}"
                        columnMenu={SimplifiedMenuFilter}
                    />
                    <GridColumn
                        field="contractYear"
                        title="Contract year"
                        columnMenu={SimplifiedMenuFilter}
                    />
                    <GridColumn width={60} cell={GridDownloadCell} />
                </Grid>
            </div>
        </>
    );
};

export default WorkbanksGrid;
