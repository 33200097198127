import { dcpApi, Tags } from "../dcpApi";
import {
    ProcessTemplate,
    ProcessTemplateListItem,
} from "../../../modules/process-template/domain/types";
import ProcessTemplateConstants from "../../../Constants/ProcessTemplateConstants";
import { HttpMethod } from "../../../common/http/HttpMethod";
import {
    ProcessTemplateCreateRequest,
    ProcessTemplateCreateResponse,
    ProcessTemplateUpdateRequest,
} from "./process-template-api-dto";

export const processTemplateApi = dcpApi.injectEndpoints({
    endpoints: (builder) => ({
        getProcessTemplatesByOrgId: builder.query<
            ProcessTemplateListItem[],
            string
        >({
            query: (organisationId) =>
                `${ProcessTemplateConstants.ResourceName}?organisationId=${organisationId}`,
            providesTags: (result, error, id) => [
                { type: Tags.ProcessTemplates, id },
            ],
        }),
        getProcessTemplateById: builder.query<ProcessTemplate, string>({
            query: (processTemplateId) =>
                `${ProcessTemplateConstants.ResourceName}/${processTemplateId}`,
            providesTags: (result, error, id) => [
                { type: Tags.ProcessTemplate, id },
            ],
        }),
        addProcessTemplate: builder.mutation<
            ProcessTemplateCreateResponse,
            ProcessTemplateCreateRequest
        >({
            query: (patch) => ({
                url: `${ProcessTemplateConstants.ResourceName}`,
                method: HttpMethod.Post,
                body: {
                    ...patch,
                },
            }),
            invalidatesTags: (_, error, params) =>
                error
                    ? []
                    : [
                          {
                              type: Tags.ProcessTemplates,
                              id: params.organisationId,
                          },
                      ],
        }),
        updateProcessTemplate: builder.mutation<
            void,
            ProcessTemplateUpdateRequest
        >({
            query: ({ id, ...patch }) => ({
                url: `${ProcessTemplateConstants.ResourceName}/${id}`,
                method: HttpMethod.Put,
                body: {
                    ...patch,
                },
            }),
            invalidatesTags: (_, error, params) =>
                error
                    ? []
                    : [
                          {
                              type: Tags.ProcessTemplates,
                              id: params.organisationId,
                          },
                          {
                              type: Tags.ProcessTemplate,
                              id: params.id,
                          },
                      ],
        }),
        deleteProcessTemplate: builder.mutation<
            void,
            { id: string; organisationId: string }
        >({
            query: ({ id }) => ({
                url: `${ProcessTemplateConstants.ResourceName}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, params) => [
                {
                    type: Tags.ProcessTemplate,
                    id: params.id,
                },
                {
                    type: Tags.ProcessTemplates,
                    id: params.organisationId,
                },
            ],
        }),
    }),
});

export const {
    useGetProcessTemplatesByOrgIdQuery,
    useGetProcessTemplateByIdQuery,
    useAddProcessTemplateMutation,
    useUpdateProcessTemplateMutation,
    useDeleteProcessTemplateMutation,
} = processTemplateApi;
