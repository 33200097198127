import { HttpMethod } from "../../../common/http/HttpMethod";
import {
    BasicFileProps,
    ListedFolder,
} from "../../../modules/files/domain/folder";
import {
    getListedFolderTag,
    OrganisationAndFolderIds,
} from "../../../modules/files/utils/OrganisationAndFolderIds";
import { dcpApi, Tags } from "../dcpApi";

interface ChangeFolderParams extends OrganisationAndFolderIds {
    folderName: string;
}

interface ChangeFolderNameParams extends ChangeFolderParams {
    parentFolderId: string;
}

interface DeleteFolderParams extends OrganisationAndFolderIds {
    parentFolderId: string;
}
interface TagParams extends OrganisationAndFolderIds {
    tag: string;
}

interface MoveToFolderParams extends OrganisationAndFolderIds {
    sourceFolderId: string;
    toMove: FileAndFolderIds;
}
interface FavoriteFolderParams extends OrganisationAndFolderIds {
    isFavorite: boolean;
}

interface FileAndFolderIds {
    fileIds: string[];
    folderIds: string[];
}

export const folderApi = dcpApi.injectEndpoints({
    endpoints: (builder) => ({
        listFolder: builder.query<ListedFolder, OrganisationAndFolderIds>({
            query: ({ organisationId, folderId }) =>
                `organisation/${organisationId}/folder/${folderId}`,
            providesTags: (_, error, params) =>
                error ? [] : [getListedFolderTag(params)],
        }),
        addNewFolder: builder.mutation<BasicFileProps, ChangeFolderParams>({
            query: ({ organisationId, folderId, folderName }) => ({
                url: `organisation/${organisationId}/folder/${folderId}`,
                method: HttpMethod.Post,
                body: { name: folderName },
            }),
            invalidatesTags: (_, error, params) =>
                error
                    ? []
                    : [
                          getListedFolderTag(params),
                          {
                              type: Tags.FileSearch,
                          },
                      ],
        }),
        changeFolderName: builder.mutation<void, ChangeFolderNameParams>({
            query({ organisationId, folderId, folderName }) {
                const body = new FormData();
                body.append("name", folderName);
                return {
                    url: `organisation/${organisationId}/folder/${folderId}`,
                    method: HttpMethod.Patch,
                    body,
                };
            },
            invalidatesTags: (_, error, params) =>
                error
                    ? []
                    : [
                          getListedFolderTag({
                              organisationId: params.organisationId,
                              folderId: params.parentFolderId,
                          }),
                          {
                              type: Tags.FileSearch,
                          },
                      ],
        }),
        deleteFolder: builder.mutation<void, DeleteFolderParams>({
            query: ({ organisationId, folderId }) => ({
                url: `organisation/${organisationId}/folder/${folderId}`,
                method: HttpMethod.Delete,
            }),
            invalidatesTags: (_, error, params) =>
                error
                    ? []
                    : [
                          getListedFolderTag({
                              organisationId: params.organisationId,
                              folderId: params.parentFolderId,
                          }),
                      ],
        }),
        addFolderTag: builder.mutation<void, TagParams>({
            query: ({ organisationId, folderId, tag }) => ({
                url: `organisation/${organisationId}/folder/${folderId}/tags`,
                method: HttpMethod.Post,
                body: { tag },
            }),
            invalidatesTags: (_, error, params) =>
                error
                    ? []
                    : [
                          getListedFolderTag({
                              organisationId: params.organisationId,
                              folderId: params.folderId,
                          }),
                          {
                              type: Tags.OrganisationTagHints,
                              id: params.organisationId,
                          },
                      ],
        }),
        patchFolderTags: builder.mutation<void, TagParams>({
            query: ({ organisationId, folderId, tag }) => ({
                url: `organisation/${organisationId}/folder/${folderId}/tags`,
                method: HttpMethod.Patch,
                body: { tag },
            }),
            invalidatesTags: (_, error, params) =>
                error
                    ? []
                    : [
                          getListedFolderTag({
                              organisationId: params.organisationId,
                              folderId: params.folderId,
                          }),
                      ],
        }),
        moveToFolder: builder.mutation<void, MoveToFolderParams>({
            query: ({ organisationId, folderId, toMove }) => ({
                url: `organisation/${organisationId}/folder/${folderId}`,
                method: HttpMethod.Put,
                body: {
                    fileIds: toMove.fileIds,
                    folderIds: toMove.folderIds,
                },
            }),
            invalidatesTags: (_, error, params) =>
                error
                    ? []
                    : [
                          getListedFolderTag(params),
                          getListedFolderTag({
                              organisationId: params.organisationId,
                              folderId: params.sourceFolderId,
                          }),
                      ],
        }),
        favoriteFolder: builder.mutation<void, FavoriteFolderParams>({
            query: (params) => ({
                url: `organisation/${params.organisationId}/folder/${params.folderId}/set-favorite/${params.isFavorite}`,
                method: HttpMethod.Patch,
            }),
            invalidatesTags: (_, error, params) =>
                error ? [] : [getListedFolderTag(params)],
        }),
    }),
});

export const {
    useListFolderQuery,
    useAddNewFolderMutation,
    useChangeFolderNameMutation,
    useDeleteFolderMutation,
    useAddFolderTagMutation,
    usePatchFolderTagsMutation,
    useMoveToFolderMutation,
    useFavoriteFolderMutation,
} = folderApi;
