import {
    TemplateContentFilter,
    TemplateContentFilterOperator,
    TemplateContentFilterProperty,
} from "../domain/types";

export const filterItems = (
    filter: TemplateContentFilter,
    item: unknown,
): boolean => {
    if (!filter) {
        return true;
    }

    return selectFilter(
        filter.operator,
        filter.values,
        item[selectProperty(filter.property)],
    );
};

const selectProperty = (property: TemplateContentFilterProperty) => {
    switch (property) {
        case TemplateContentFilterProperty.UserGroup:
            return "userGroups";
        default:
            console.error("Unsupported property");
    }
};

const anyContainsFilter = (filters: string[], values: string[]): boolean =>
    filters.some((v) => values.includes(v));

const allContainsFilter = (filters: string[], values: string[]): boolean =>
    filters.every((v) => values.includes(v));

const selectFilter = (
    operator: TemplateContentFilterOperator,
    filters: string[],
    values: string[],
): boolean => {
    switch (operator) {
        case TemplateContentFilterOperator.All:
            return allContainsFilter(filters, values);
        case TemplateContentFilterOperator.Any:
            return anyContainsFilter(filters, values);
        default:
            console.error("Unsupported operator");
    }
};
