import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router";
import OrganisationBlurb from "../components/OrganisationBlurb";
import OrganisationImage from "../components/OrganisationImage";
import { useGetOrgByShortNameQuery } from "../../../../store/features/organisation/organisation-api-slice";
import CoveringLoadingPanel from "../../../../common/components/CoveringLoadingPanel";
import "./OrganisationOverviewPage.scss";
import BridgeConditionIndexSpeedComponent from "../../components/bci-components/BridgeConditionIndexSpeedComponent";

const OrganisationOverviewPage: React.FC = () => {
    const params = useParams<{ orgShortName: string }>();
    const { data: organisation, isLoading } = useGetOrgByShortNameQuery(
        params.orgShortName,
    );
    if (isLoading) {
        return <CoveringLoadingPanel />;
    }

    return (
        <Container fluid>
            <div className="overviewTitle display-4 mt-4 mb-4">
                {organisation.name}
            </div>
            <OrganisationImage organisation={organisation} />
            <Row>
                <Col className="col-lg-12">
                    <OrganisationBlurb organisation={organisation} />
                </Col>
            </Row>
            <Row>
                <Col className="col-lg-12 mt-4">
                    <BridgeConditionIndexSpeedComponent
                        title={"Bridge Condition Index"}
                        bciConfigName={organisation.shortName + "-bridge"}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default OrganisationOverviewPage;
