import { jSDateFromIso } from "../../../helpers/dateTimeHelpers";
import { FileSearchItem, SearchResultType } from "../domain/folder";
import { EntryType, FileViewEntry } from "./FileViewEntry";

export type FileSearchForm = {
    name: string;
};

export const checkValidSearch = (searchForm: FileSearchForm): boolean => {
    return searchForm.name.trim().length > 0;
};

export const buildInitialQuery = (): FileSearchForm => ({
    name: "",
});

export const buildSearchQuery = (query: FileSearchForm): string => {
    const { name } = query;

    return `query=${name}`;
};

export const buildFormFromQuery = (query: string): FileSearchForm => {
    const searchParams = new URLSearchParams(query);

    const name = searchParams.get("query");

    return {
        name: name ?? "",
    };
};

const mapSearchTypeToEntry = (type: SearchResultType): EntryType => {
    switch (type) {
        case SearchResultType.Folder:
            return EntryType.Folder;
        case SearchResultType.File:
            return EntryType.File;
    }
};

export const mapSeachToViewEntry = (item: FileSearchItem): FileViewEntry => {
    return {
        label: item.name,
        type: mapSearchTypeToEntry(item.type),
        inEdit: false,
        id: item.id,
        parentId: item.parentId,
        dateModified: jSDateFromIso(item.dateModified) || null,
        modifiedBy: "",
        selected: false,
        location: item.location,
    };
};
