export interface Notification {
    content: string;
    type: NotificationType;
}

export enum NotificationType {
    Warning,
    Success,
    Error,
}
