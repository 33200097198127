import * as React from "react";
import cx from "classnames";
import { IconType } from "react-icons/lib";
import "./IconButton.scss";

interface IconButtonProps {
    className?: string;
    icon: IconType;
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    iconSize?: string;
}
export const IconButton: React.FC<IconButtonProps> = ({
    className,
    icon: Icon,
    onClick,
    iconSize,
}) => {
    return (
        <button className={cx(className, "btn btn-icon")} onClick={onClick}>
            <Icon size={iconSize ?? "16"} />
        </button>
    );
};
