import * as React from "react";
import { Link } from "react-router-dom";
import "./DefectDetails.scss";
import { Organisation } from "../../../organisation/domain/types";
import { DefectDto } from "../../domain/types";

interface DefectDetailsProps {
    defect: DefectDto;
    orgShortName: string;
    organisation: Organisation;
}

const DefectDetails: React.FC<DefectDetailsProps> = ({
    defect,
    orgShortName,
    organisation,
}) => {
    const latestActivity =
        defect.associatedActivities[defect.associatedActivities.length - 1];
    return (
        <div className="defect-details-title">
            <div className="title">
                <h1>{defect.displayName}</h1>
            </div>
            <Link
                className="btn btn-primary"
                to={`/${orgShortName}/process/${latestActivity.processId}/activity/${latestActivity.processActivityId}`}
            >
                Go to latest{" "}
                {organisation?.features?.process?.createName ?? "Process"}
            </Link>
        </div>
    );
};

export default DefectDetails;
