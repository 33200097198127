import { Dialog } from "@progress/kendo-react-dialogs";
import * as React from "react";
import {
    useListFolderQuery,
    useMoveToFolderMutation,
} from "../../../store/features/folder/folderApiSlice";
import { stopMoving } from "../../../store/features/folder/moveFilesAndFoldersSlice";
import { addSuccess } from "../../../store/features/notifications/notificationsSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";
import { MoveConfirmationDialog } from "./MoveConfirmationDialog";
import { SelectFolderDialog } from "./SelectFolderDialog";

enum MoveOperationStep {
    Select,
    Confirm,
}

interface Props {
    folderId: string;
}

export function MoveCoordinator({ folderId }: Props): JSX.Element {
    const dispatch = useAppDispatch();
    const [targetFolderId, setTargetFolderId] = React.useState<string>(null);
    const [currentMoveOperationStep, setCurrentMoveOperationStep] =
        React.useState<MoveOperationStep>(MoveOperationStep.Select);

    const { toMove } = useAppSelector((s) => s.moveFilesAndFolders);
    const { organisationId } = useOrganisationId();
    const { data: sourceFolder } = useListFolderQuery(
        { organisationId: organisationId, folderId },
        { skip: !organisationId },
    );
    const { data: targetFolder } = useListFolderQuery(
        { organisationId: organisationId, folderId: targetFolderId },
        { skip: !organisationId || !targetFolderId },
    );
    const [moveToFolder] = useMoveToFolderMutation();

    const handleConfirmMove = () => {
        if (!targetFolderId || !toMove) {
            return;
        }

        moveToFolder({
            organisationId,
            sourceFolderId: folderId,
            folderId: targetFolderId,
            toMove: {
                fileIds: toMove.files.map((x) => x.id),
                folderIds: toMove.folders.map((x) => x.id),
            },
        })
            .unwrap()
            .then(() => {
                dispatch(addSuccess("The items have been moved successfully."));
                close();
            });
    };

    const handleCancelMove = () => {
        setTargetFolderId(null);
        setCurrentMoveOperationStep(MoveOperationStep.Select);
    };

    const isDialogVisible = React.useMemo(() => toMove !== null, [toMove]);

    React.useEffect(() => {
        if (isDialogVisible) {
            setCurrentMoveOperationStep(MoveOperationStep.Select);
        }
    }, [isDialogVisible]);

    React.useEffect(() => {
        if (targetFolderId) {
            setCurrentMoveOperationStep(MoveOperationStep.Confirm);
        }
    }, [targetFolderId]);

    const close = () => {
        setTargetFolderId(null);
        dispatch(stopMoving(null));
    };

    return (
        <>
            {isDialogVisible && (
                <Dialog title="Navigate to the target" onClose={close}>
                    {currentMoveOperationStep === MoveOperationStep.Select && (
                        <SelectFolderDialog
                            initialFolderId={folderId}
                            onSelect={setTargetFolderId}
                            onClose={close}
                        />
                    )}
                    {currentMoveOperationStep === MoveOperationStep.Confirm && (
                        <MoveConfirmationDialog
                            sourceFolder={sourceFolder}
                            targetFolder={targetFolder}
                            toMove={toMove}
                            onBack={handleCancelMove}
                            onConfirm={handleConfirmMove}
                        />
                    )}
                </Dialog>
            )}
        </>
    );
}
