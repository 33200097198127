import cx from "classnames";
import { ErrorMessage, FieldAttributes, useField } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { SelectOptionItem } from "../../types/reactSelect/SelectOptionItem";
import "./customSelect.scss";
import TextInput from "./TextInput";

type Props = {
    label?: string;
    options: SelectOptionItem[];
    className?: string;
    readOnly?: boolean;
    placeholder?: string;
    isMulti?: boolean;
    description?: string;
    plainText?: boolean;
    isClearable?: boolean;
} & FieldAttributes<unknown>;

export const CustomSelect: React.FC<Props> = ({
    name,
    label,
    options,
    isMulti,
    className,
    readOnly,
    placeholder,
    description,
    plainText,
    isClearable,
}) => {
    const [field, meta, helpers] = useField<unknown>(name);

    if (plainText)
        return (
            <TextInput
                name={field.name}
                label={label}
                readOnly={true}
                plainText={true}
            />
        );

    return (
        <Form.Group controlId={field.name}>
            {label && <Form.Label>{label}</Form.Label>}
            <Select
                name={field.name}
                value={options.find((o) => o.value === field.value)}
                placeholder={placeholder}
                onChange={(value) => helpers.setValue(value)}
                options={options}
                onBlur={field.onBlur}
                isMulti={isMulti}
                isClearable={isClearable ?? true}
                classNamePrefix="custom-select"
                className={cx(className, {
                    "is-invalid": meta.touched && meta.error,
                })}
                isDisabled={readOnly}
            />
            <ErrorMessage
                name={field.name}
                className="invalid-feedback"
                component="div"
            ></ErrorMessage>
            {description && (
                <Form.Text className="text-muted">{description}</Form.Text>
            )}
        </Form.Group>
    );
};

export default CustomSelect;
