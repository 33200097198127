import { ProcessTemplate } from "../../modules/process-template/domain/types";

export const REQUEST_PROCESS_TEMPLATE_FORM = "REQUEST_PROCESS_TEMPLATE_FORM";
export const RECEIVE_PROCESS_TEMPLATE_FORM = "RECEIVE_PROCESS_TEMPLATE_FORM";
export const PROCESS_TEMPLATE_REQUEST_TEMPLATES_START =
    "PROCESS_TEMPLATE_REQUEST_TEMPLATES_START";
export const PROCESS_TEMPLATE_REQUEST_TEMPLATES_SUCCESS =
    "PROCESS_TEMPLATE_REQUEST_TEMPLATES_SUCCESS";

// These are the actions which mutate state.
export interface RequestProcessTemplateFormAction {
    type: typeof REQUEST_PROCESS_TEMPLATE_FORM;
}

export interface ReceiveProcessTemplateFormAction {
    type: typeof RECEIVE_PROCESS_TEMPLATE_FORM;
    data: ProcessTemplate;
}

export interface ProcessTemplateRequestTemplatesStart {
    type: typeof PROCESS_TEMPLATE_REQUEST_TEMPLATES_START;
}

export interface ProcessTemplateRequestTemplatesSuccess {
    type: typeof PROCESS_TEMPLATE_REQUEST_TEMPLATES_SUCCESS;
    data: ProcessTemplate[];
}
