import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import React from "react";
import { Button, Col, Form, Row, Form as bsForm } from "react-bootstrap";
import { useGetFileQuery } from "../../../../store/features/file/fileApiSlice";
import CoveringLoadingPanel from "../../../../common/components/CoveringLoadingPanel";

interface ViewMetaDataProps {
    fileId: string;
    organisationId: string;
    folderId: string;
    onClose: () => void;
}

const ViewMetaDataModal: React.FC<ViewMetaDataProps> = (props) => {
    const { fileId, organisationId, folderId, onClose } = props;
    const { data: fileDetails, isLoading } = useGetFileQuery({
        fileId,
        organisationId,
        folderId,
    });

    if (isLoading) {
        return <CoveringLoadingPanel />;
    }

    return (
        <Dialog
            title={"View Metadata"}
            onClose={onClose}
            width="calc(100vw - 40px)"
            height="calc(100vh - 40px)"
            className="view-metadata-modal"
        >
            <Form className="view-metadata-form">
                {fileDetails?.metadata ? (
                    Object.entries(fileDetails.metadata).map(([key, value]) => {
                        return (
                            <Row key={key + "-row"} className="mb-3">
                                <Col xs={4} className="text-end">
                                    <bsForm.Label htmlFor={key}>
                                        {key}
                                    </bsForm.Label>
                                </Col>
                                <Col xs={8}>
                                    <Form.Control
                                        as="textarea"
                                        key={key + "-control"}
                                        type="text"
                                        name={key}
                                        value={value}
                                        disabled={true}
                                    />
                                </Col>
                            </Row>
                        );
                    })
                ) : (
                    <div className="col mt-3 mb-3 text-center">
                        <div>{"No Metadata"}</div>
                    </div>
                )}
                <div className="action-buttons">
                    <DialogActionsBar>
                        <Button
                            type="button"
                            variant="outline-secondary"
                            onClick={onClose}
                        >
                            Close
                        </Button>
                    </DialogActionsBar>
                </div>
            </Form>
        </Dialog>
    );
};

export default ViewMetaDataModal;
