import { DateTime } from "luxon";
import { ApprovedExam } from "./approvedExam";

export interface ApprovedExamDto {
    id: string;
    name: string;
    type: string;
    state: string;
    carrsExamId: string;
    batchName: string;
    elr: string;
    miles: number;
    yards: number;
    chains: string;
    structureRefNum: string;
    contractYear: string;
    examType: string;
    examDate?: string;
}

export const mapToApprovedExam = (dto: ApprovedExamDto): ApprovedExam => ({
    ...dto,
    examDate: dto.examDate ? DateTime.fromISO(dto.examDate).toJSDate() : null,
});

export interface ReturnExamReasonModel {
    comment: string;
    rejectedByNR: boolean;
    newCarrsExamId?: number;
}
