import {
    Grid,
    GridColumn,
    GridItemChangeEvent,
    GridRowClickEvent,
} from "@progress/kendo-react-grid";
import { ArrayHelpers, getIn, useFormikContext } from "formik";
import cx from "classnames";
import * as React from "react";
import { TypedGridCellProps } from "../../../../common/types/TypedGridCellProps";
import { ContainsMenuFilter } from "../../../../common/components/table/kendo/columnFilters";
import useGridProps from "../../../../common/components/table/kendo/useGridProps";
import {
    FormControlEmployeesGridDataItem,
    mapToEmployeesGridItem,
} from "./FormControlEmployeesGridDataItem";
import {
    ShowCondition,
    TemplateContentTableRow,
} from "../../../template/domain/types";
import FormControlEmployeesGridActions from "./FormControlEmployeesGridActions";
import { TemplateContentEmployeesRecord } from "../../domain/types";
import EmployeesConstants from "../consts/EmployeesConstants";

interface FormControlEmployeesGridConfig {
    field: string;
    label: string;
    prompt: string;
    uri: string;
    rows: TemplateContentTableRow[];
    isReadOnly: boolean;
    showConditions: ShowCondition[];
}

interface Props {
    config: FormControlEmployeesGridConfig;
    arrayHelpers: ArrayHelpers;
    isValid: boolean;
}

const FormControlEmployeesGrid: React.FC<Props> = ({
    config,
    arrayHelpers,
    isValid,
}) => {
    const [editId, setEditId] = React.useState<string>(null);
    const formik = useFormikContext();
    const rowData = React.useMemo(
        () =>
            (getIn(formik.values, config.field) ||
                []) as TemplateContentEmployeesRecord[],
        [formik.values, config.field],
    );

    const data = React.useMemo(
        () =>
            rowData.map((row, index) =>
                mapToEmployeesGridItem(row, index, editId),
            ),
        [rowData, editId],
    );

    const { gridProps } = useGridProps(data, {
        filterable: true,
        sortable: true,
        initialSort: [{ field: "createdOn", dir: "desc" }],
        pageable: false,
    });

    const ActionsCell = React.useMemo(
        () =>
            // eslint-disable-next-line react/display-name
            (props: TypedGridCellProps<FormControlEmployeesGridDataItem>) =>
                (
                    <td>
                        <FormControlEmployeesGridActions
                            {...props}
                            onRemove={(dataItem) => {
                                arrayHelpers.remove(dataItem.originalIndex);
                            }}
                        />
                    </td>
                ),
        [arrayHelpers],
    );

    const handleRowClick = (event: GridRowClickEvent) => {
        if (!config.isReadOnly) {
            setEditId(event.dataItem.id);
        }
    };

    const handleEdit = (event: GridItemChangeEvent) => {
        const newData = rowData.map((item) =>
            item.id === event.dataItem.id
                ? { ...item, [event.field]: event.value }
                : item,
        );
        formik.setFieldValue(config.field, newData);
    };

    return (
        <Grid
            {...gridProps}
            className={cx(gridProps.className, "dynamic-form-grid", {
                "is-invalid": !isValid,
            })}
            editField="inEdit"
            onRowClick={handleRowClick}
            onItemChange={handleEdit}
        >
            <GridColumn
                field={EmployeesConstants.EmployeeField}
                title={EmployeesConstants.EmployeeLabel}
                sortable
                width={window.innerWidth * EmployeesConstants.EmployeeWidth}
                columnMenu={ContainsMenuFilter}
                editable={!config.isReadOnly}
                editor="text"
            />
            <GridColumn
                field={EmployeesConstants.RoleField}
                title={EmployeesConstants.RoleLabel}
                sortable
                width={window.innerWidth * EmployeesConstants.RoleWidth}
                columnMenu={ContainsMenuFilter}
                editable={!config.isReadOnly}
                editor="text"
            />
            <GridColumn
                field={EmployeesConstants.CompetenceField}
                title={EmployeesConstants.CompetenceLabel}
                sortable
                width={window.innerWidth * EmployeesConstants.CompetenceWidth}
                columnMenu={ContainsMenuFilter}
                editable={!config.isReadOnly}
                editor="text"
            />
            <GridColumn
                field={EmployeesConstants.TrainingField}
                title={EmployeesConstants.TrainingLabel}
                sortable
                width={window.innerWidth * EmployeesConstants.TrainingWidth}
                columnMenu={ContainsMenuFilter}
                editable={!config.isReadOnly}
                editor="text"
            />
            <GridColumn
                field={EmployeesConstants.ExperienceField}
                title={EmployeesConstants.ExperienceLabel}
                sortable
                width={window.innerWidth * EmployeesConstants.ExperienceWidth}
                columnMenu={ContainsMenuFilter}
                editable={!config.isReadOnly}
                editor="text"
            />
            <GridColumn
                field={EmployeesConstants.ContactField}
                title={EmployeesConstants.ContactLabel}
                sortable
                width={window.innerWidth * EmployeesConstants.ContactWidth}
                columnMenu={ContainsMenuFilter}
                editable={!config.isReadOnly}
                editor="text"
            />
            <GridColumn
                field={EmployeesConstants.MedicalField}
                title={EmployeesConstants.MedicalLabel}
                sortable
                width={window.innerWidth * EmployeesConstants.MedicalWidth}
                columnMenu={ContainsMenuFilter}
                editable={!config.isReadOnly}
                editor="text"
            />
            {!config.isReadOnly && (
                <GridColumn
                    filterable={false}
                    cell={ActionsCell}
                    width={window.innerWidth * EmployeesConstants.ActionWidth}
                />
            )}
        </Grid>
    );
};
export default FormControlEmployeesGrid;
