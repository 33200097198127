import AdminConstants from "../../../../Constants/AdminConstants";
import * as React from "react";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTitle } from "../../../../common/hooks/useTitle";
import UserAdminPageTitles from "./pageTitles";

const UsersAdminPage: React.FC = () => {
    useTitle(UserAdminPageTitles.Main);

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={"div"}>
                            <Link to={`${AdminConstants.ResourceName}`}>
                                Admin
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item linkAs={"div"} active>
                            Users
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>Users Admin</h1>
                </Col>
            </Row>
            <Row className="top30"></Row>
        </React.Fragment>
    );
};

export default UsersAdminPage;
