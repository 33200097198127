import * as React from "react";
import useGridProps from "../../../../common/components/table/kendo/useGridProps";
import {
    ContainsMenuFilter,
    emptyFilterDescriptor,
} from "../../../../common/components/table/kendo/columnFilters";
import { Grid } from "@progress/kendo-react-grid";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import UserGroupsTableActions from "./UserGroupsTableActions";
import { UserGroup } from "../../domain/types";
import GridLoadingPanel from "../../../../common/components/table/kendo/GridLoadingPanel";

export interface TableUserGroup {
    id: string;
    name: string;
}

interface Props {
    userGroups: UserGroup[];
    orgShortName: string;
    isFetching: boolean;
    isLoading: boolean;
}

const UserGroupTable: React.FC<Props> = ({
    userGroups,
    orgShortName,
    isFetching,
    isLoading,
}) => {
    const UserGroupCommandCell = React.useCallback(
        (props) => (
            <td>
                <UserGroupsTableActions
                    userGroup={props.dataItem}
                    orgShortName={orgShortName}
                />
            </td>
        ),
        [orgShortName],
    );
    const wrapperRef = React.createRef<HTMLElement>();

    const { gridProps } = useGridProps(userGroups, {
        filterable: true,
        initialFilter: emptyFilterDescriptor,
        sortable: true,
        initialSort: [{ field: "username", dir: "asc" }],
        pageable: true,
    });
    if (isLoading || isFetching) {
        return <GridLoadingPanel gridRef={wrapperRef} />;
    } else {
        return (
            <Grid {...gridProps} className="table-h">
                <Column
                    field="name"
                    title="Group"
                    headerClassName="grid-header"
                    columnMenu={ContainsMenuFilter}
                />
                <Column
                    field="actions"
                    title="Actions"
                    cell={UserGroupCommandCell}
                />
            </Grid>
        );
    }
};

export default UserGroupTable;
