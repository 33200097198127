import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { push } from "connected-react-router";
import React from "react";
import { useDispatch } from "react-redux";
import { useUpdateCurrentUserMutation } from "../../../store/features/user/user-api-slice";
import { useAppSelector } from "../../../store/hooks";
import authService from "../services/AuthService";

const UpdateUserRedirectPage: React.FC = () => {
    const { inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const dispatch = useDispatch();
    const [updateUser, updateUserResult] = useUpdateCurrentUserMutation();
    const user = useAppSelector((state) => state.authUser);

    React.useEffect(() => {
        if (inProgress !== InteractionStatus.None) return;
        if (!isAuthenticated) {
            authService.signIn();
        } else if (isAuthenticated) {
            if (updateUserResult.isLoading || updateUserResult.isSuccess) {
                return;
            }
            updateUser(user.username).then(() => {
                dispatch(push(""));
            });
        }
    }, [
        dispatch,
        inProgress,
        isAuthenticated,
        updateUser,
        user,
        updateUserResult,
    ]);

    return <></>;
};

export default UpdateUserRedirectPage;
