import { useEffect } from "react";
import { useParams } from "react-router";
import { Organisation } from "../../modules/organisation/domain/types";
import {
    useGetOrgByIdQuery,
    useGetOrgByShortNameQuery,
} from "../../store/features/organisation/organisation-api-slice";

const loadingText = "Loading...";

const useRestoreTitle = (): void => {
    useEffect(() => {
        const previousTitle = document.title;

        return () => {
            document.title = previousTitle;
        };
    }, []);
};

export const useTitle = (title: string): void => {
    useRestoreTitle();

    useEffect(() => {
        document.title = title ?? loadingText;
    }, [title]);
};

const formatOrgTitle = (
    isLoading: boolean,
    organisation: Organisation,
    subTitle: string,
): string => {
    if (isLoading || !organisation) return loadingText;

    return `${organisation.name} - ${subTitle}`;
};

export const useOrgTitleById = (orgId: string, subTitle: string): void => {
    const { data: organisation, isLoading } = useGetOrgByIdQuery(orgId, {
        skip: !orgId,
    });

    useRestoreTitle();

    useEffect(() => {
        const title = formatOrgTitle(isLoading, organisation, subTitle);

        document.title = title;
    }, [organisation, subTitle, isLoading]);
};
export const useOrgTitleWithShortName = (subTitle: string): void => {
    const { orgShortName } = useParams<{ orgShortName: string }>();
    const { data, isLoading } = useGetOrgByShortNameQuery(orgShortName);

    useRestoreTitle();

    useEffect(() => {
        setDocumentTitle(isLoading, data, subTitle);
    }, [data, subTitle, isLoading]);
};

const setDocumentTitle = (
    isLoading: boolean,
    data: Organisation,
    subTitle: string,
) => {
    const title = formatOrgTitle(isLoading, data, subTitle);

    document.title = title;
};
