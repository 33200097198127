import * as React from "react";
import { useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import QueryResultStatus from "../../../common/components/QueryResultStatus";
import { useOrgTitleById } from "../../../common/hooks/useTitle";
import { useURLQueryParams } from "../../../common/hooks/useURLQueryParams";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import ProcessTemplateConstants from "../../../Constants/ProcessTemplateConstants";
import { useGetOrgByIdQuery } from "../../../store/features/organisation/organisation-api-slice";
import {
    useDeleteProcessTemplateMutation,
    useGetProcessTemplateByIdQuery,
    useUpdateProcessTemplateMutation,
} from "../../../store/features/process-template/process-template-api-slice";
import { TemplateFormActionArg } from "../../template/viewModel/TemplateFormActions";
import ProcessTemplateForm from "../components/ProcessTemplateForm";
import ProcessTemplateAuditActionList from "../containers/ProcessTemplateAuditActionList";
import { ProcessTemplateUiTabs } from "../domain/types";
import { ProcessTemplateFormValues } from "../viewModel/ProcessTemplateFormValues";
import ProcessTemplatePageTitles from "./pageTitles";

const ProcessTemplateViewPage: React.FC = () => {
    const params = useParams<{ id: string }>();

    const selectedTab = useURLQueryParams("tab");
    const [activeTabKey, setTabKey] = useState(selectedTab ?? "home");

    const [updateProcessTemplate, updateProcessTemplateResult] =
        useUpdateProcessTemplateMutation();
    const updateTemplateActionCallback = ({
        values,
    }: TemplateFormActionArg<ProcessTemplateFormValues>) => {
        return updateProcessTemplate({
            id: params.id,
            ...values,
            config: values.config,
            states: values.statesConfig ? JSON.parse(values.statesConfig) : [],
            propertyConfig: values.propertyConfig
                ? JSON.parse(values.propertyConfig)
                : [],
            features: values.featuresConfig
                ? JSON.parse(values.featuresConfig)
                : {},
        });
    };

    const [deleteProcessTemplate, deleteProcessTemplateResult] =
        useDeleteProcessTemplateMutation();
    const deleteTemplateActionCallback = () => {
        return deleteProcessTemplate({
            id: params.id,
            organisationId: orgId,
        });
    };

    const { data: processTemplate, ...processTemplateQueryResult } =
        useGetProcessTemplateByIdQuery(params.id, {
            skip:
                !params.id ||
                deleteProcessTemplateResult.isLoading ||
                deleteProcessTemplateResult.isSuccess,
        });

    const orgId = processTemplate?.organisationId ?? "";

    const { data: organisation, ...organisationQueryResult } =
        useGetOrgByIdQuery(orgId, {
            skip: !orgId,
        });

    useOrgTitleById(orgId, ProcessTemplatePageTitles.View);

    const createRedirectPath = ProcessTemplateConstants.RoutePath;
    const deleteRedirectTabKey = "processTemplates";

    const initialValues: ProcessTemplateFormValues = React.useMemo(
        () => ({
            name: processTemplate?.name ?? "",
            description: processTemplate?.description ?? "",
            config: processTemplate?.config ?? "",
            statesConfig: JSON.stringify(
                processTemplate?.states || [],
                null,
                4,
            ),
            organisationId: orgId,
            propertyConfig: JSON.stringify(
                processTemplate?.propertyConfig || [],
                null,
                4,
            ),
            featuresConfig: JSON.stringify(
                processTemplate?.features || {},
                null,
                4,
            ),
        }),
        [processTemplate, orgId],
    );

    if (!processTemplate)
        return <QueryResultStatus queryResult={processTemplateQueryResult} />;
    if (!organisation)
        return <QueryResultStatus queryResult={organisationQueryResult} />;

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={"span"}>
                            <Link
                                to={`${OrganisationConstants.RoutePath}/${organisation.id}`}
                            >
                                Manage
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item linkAs={"span"}>
                            <Link
                                to={`${OrganisationConstants.RoutePath}/${organisation.id}?tab=processTemplates`}
                            >
                                {organisation?.features?.process
                                    ?.sideMenuName ?? "Processes"}{" "}
                                Templates
                            </Link>
                        </Breadcrumb.Item>
                        {!processTemplateQueryResult.isLoading && (
                            <Breadcrumb.Item active linkAs={"span"}>
                                {" "}
                                {processTemplate.name}{" "}
                            </Breadcrumb.Item>
                        )}
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>{organisation.name}</h1>
                    <h2>
                        {organisation?.features?.process?.sideMenuName ??
                            "Processes"}{" "}
                        Template
                    </h2>
                </Col>
            </Row>
            <Row className="py-3">
                <Col>
                    <Tabs
                        activeKey={activeTabKey}
                        onSelect={(key) => setTabKey(key)}
                        defaultActiveKey="home"
                        id="tab"
                    >
                        <Tab
                            eventKey="home"
                            title={ProcessTemplateUiTabs.ProcessTab}
                        >
                            <Row className="pt-3">
                                <Col>
                                    <ProcessTemplateForm
                                        templateId={processTemplate.id}
                                        organisationId={
                                            processTemplate.organisationId
                                        }
                                        initialValues={initialValues}
                                        updateTemplate={{
                                            actionCallback:
                                                updateTemplateActionCallback,
                                            actionResult:
                                                updateProcessTemplateResult,
                                        }}
                                        deleteTemplate={{
                                            actionCallback:
                                                deleteTemplateActionCallback,
                                            actionResult:
                                                deleteProcessTemplateResult,
                                        }}
                                        createRedirectPath={createRedirectPath}
                                        deleteRedirectTabKey={
                                            deleteRedirectTabKey
                                        }
                                    />
                                </Col>
                            </Row>
                        </Tab>
                        <Tab
                            eventKey="history"
                            title={ProcessTemplateUiTabs.HistoryTab}
                        >
                            <ProcessTemplateAuditActionList
                                template={processTemplate}
                            ></ProcessTemplateAuditActionList>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
            <Row className="top30"></Row>
        </React.Fragment>
    );
};
export default ProcessTemplateViewPage;
