import {
    GridFieldsMap,
    GridFieldType,
} from "../../../../../common/components/table/kendo/GridFieldsMap";

export const gridFieldsMap: GridFieldsMap = {
    processId: {
        field: "processId",
        label: "Process Id",
        dataType: "string",
        width: 250,
        fieldType: GridFieldType.MultiText,
        filterType: "text",
    },

    contractYear: {
        field: "contractYear",
        label: "Contract Year",
        width: 120,
        dataType: "string",
        filterType: "text",
    },
    carrsExamId: {
        field: "carrsExamId",
        label: "CARRS Exam ID",
        dataType: "string",
        width: 150,
        fieldType: GridFieldType.MultiText,
        filterType: "text",
    },
    carrsGUID: {
        field: "carrsGUID",
        label: "CARRS GUID",
        dataType: "string",
        width: 250,
        fieldType: GridFieldType.MultiText,
        filterType: "text",
    },
    examType: {
        field: "examType",
        label: "Exam Type",
        width: 100,
        dataType: "string",
        filterType: "text",
    },

    elr: {
        field: "elr",
        label: "ELR",
        width: 100,
        dataType: "string",
        filterType: "text",
    },
    miles: {
        field: "miles",
        label: "Miles",
        width: 75,
        dataType: "number",
        filterType: "numeric",
    },
    yards: {
        field: "yards",
        label: "Yards",
        width: 75,
        dataType: "number",
        filterType: "numeric",
    },
    chains: {
        field: "chains",
        label: "Chains",
        width: 75,
        dataType: "string",
        filterType: "text",
    },

    assignedExaminer: {
        field: "assignedExaminer",
        label: "Assigned Examiner",
        width: 300,
        dataType: "string",
        filterType: "text",
    },
    assignedEngineer: {
        field: "assignedEngineer",
        label: "Assigned Engineer",
        width: 300,
        dataType: "string",
        filterType: "text",
    },

    processState: {
        field: "state",
        label: "State",
        dataType: "string",
        width: 100,
        fieldType: GridFieldType.ProcessStateMultiSelect,
        filterType: "text",
    },
};

export const gridFilterOperators = {
    text: [
        {
            text: "grid.filterContainsOperator",
            operator: "contains",
        },
    ],
    numeric: [
        {
            text: "grid.filterEqOperator",
            operator: "eq",
        },
        {
            text: "grid.filterGteOperator",
            operator: "gte",
        },
        {
            text: "grid.filterLteOperator",
            operator: "lte",
        },
    ],
    date: [
        {
            text: "grid.filterEqOperator",
            operator: "eq",
        },
        { text: "grid.filterAfterOrEqualOperator", operator: "gte" },
        { text: "grid.filterBeforeOrEqualOperator", operator: "lte" },
    ],
};
