import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Formik } from "formik";
import * as React from "react";
import { Button } from "react-bootstrap";
import { BatchExam } from "../../domain/batching";
import { useBatchAddExamsMutation } from "../../queries/BatchingApiSlice";
import {
    BatchAddExamGrid,
    BATCHING_EXAMS_SELECTED_FIELD,
} from "./BatchAddExamGrid";

interface AddExamsModalProps {
    batchId: string;
    organisationId: string;
    onClose: () => void;
}

const AddExamsModal: React.FC<AddExamsModalProps> = ({
    batchId,
    organisationId,
    onClose,
}) => {
    const [addExams] = useBatchAddExamsMutation();

    const initialFormValues = React.useMemo(() => {
        return { [BATCHING_EXAMS_SELECTED_FIELD]: [] };
    }, []);

    const onSubmit = React.useCallback(
        async (values) => {
            const selectedExams: BatchExam[] =
                values[BATCHING_EXAMS_SELECTED_FIELD];

            await addExams({
                id: batchId,
                processIds: selectedExams.map((x) => x.processId),
                organisationId,
            });
            onClose();
        },
        [addExams, batchId, onClose, organisationId],
    );

    return (
        <Dialog
            title="Add Exam"
            onClose={onClose}
            width="calc(100vw - 40px)"
            height="calc(100vh - 40px)"
        >
            <Formik onSubmit={onSubmit} initialValues={initialFormValues}>
                {({ values, handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                        <BatchAddExamGrid organisationId={organisationId} />
                        <DialogActionsBar>
                            <Button
                                type="button"
                                variant="outline-secondary"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="primary"
                                disabled={
                                    !values[BATCHING_EXAMS_SELECTED_FIELD]
                                        ?.length
                                }
                            >
                                Add
                            </Button>
                        </DialogActionsBar>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default AddExamsModal;
