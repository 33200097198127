export default class HelpSectionConstants {
    static readonly RoutePath = "/help-items";
    static readonly ActionCreate = "/create";
    static readonly RoutePathCreate = `${HelpSectionConstants.RoutePath}${HelpSectionConstants.ActionCreate}`;
}

export enum HelpSectionFormMode {
    Create,
    View,
    Edit,
}
