import { DateTime } from "luxon";
import { ExamRequest } from "./examRequest";

export interface ExamRequestDto
    extends Omit<
        ExamRequest,
        | "examDate"
        | "recordModifiedDate"
        | "requiredDate"
        | "siteRequiredToleranceEarliest"
        | "siteRequiredToleranceLatest"
        | "plannedDate"
    > {
    requiredDate: string;
    siteRequiredToleranceEarliest: string;
    siteRequiredToleranceLatest: string;
    plannedDate: string;
    examDate: string;
    recordModifiedDate: string;
}

export const mapToExamRequest = (dto: ExamRequestDto): ExamRequest => ({
    ...dto,
    requiredDate: dto.requiredDate
        ? DateTime.fromISO(dto.requiredDate).toJSDate()
        : null,
    siteRequiredToleranceEarliest: dto.siteRequiredToleranceEarliest
        ? DateTime.fromISO(dto.siteRequiredToleranceEarliest).toJSDate()
        : null,
    siteRequiredToleranceLatest: dto.siteRequiredToleranceLatest
        ? DateTime.fromISO(dto.siteRequiredToleranceLatest).toJSDate()
        : null,
    plannedDate: dto.plannedDate
        ? DateTime.fromISO(dto.plannedDate).toJSDate()
        : null,
    examDate: dto.examDate ? DateTime.fromISO(dto.examDate).toJSDate() : null,
    recordModifiedDate: dto.recordModifiedDate
        ? DateTime.fromISO(dto.recordModifiedDate).toJSDate()
        : null,
});
