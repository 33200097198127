// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

import {
    ReportFormListItem,
    ReportTemplate,
    ReportTemplateListItem,
} from "../../modules/report/domain/types";

export const REQUEST_REPORT_LIST_ITEMS = "REQUEST_REPORT_LIST_ITEMS";
export const RECEIVE_REPORT_LIST_ITEMS = "RECEIVE_REPORT_LIST_ITEMS";
export const RECEIVE_REPORT_TEMPLATE = "RECEIVE_REPORT_TEMPLATE";
export const REQUEST_REPORT_TEMPLATE = "REQUEST_REPORT_TEMPLATE";
export const RECEIVE_ORG_TEMPLATE_LIST_ITEMS =
    "RECEIVE_ORG_TEMPLATE_LIST_ITEMS";
export const REQUEST_ORG_TEMPLATE_LIST_ITEMS =
    "REQUEST_ORG_TEMPLATE_LIST_ITEMS";
export const DELETE_ORG_REPORT_TEMPLATE_SUCCESS =
    "DELETE_ORG_REPORT_TEMPLATE_SUCCESS";
export const DELETE_ORG_REPORT_SUCCESS = "DELETE_ORG_REPORT_SUCCESS";

export const REPORT_EDIT_START = "ORG_REPORT_EDIT_START";

export interface ReportEditStart {
    type: typeof REPORT_EDIT_START;
}

export interface RequestReportListItemsAction {
    type: typeof REQUEST_REPORT_LIST_ITEMS;
}

export interface ReceiveReportListItemsAction {
    type: typeof RECEIVE_REPORT_LIST_ITEMS;
    data: ReportFormListItem[];
}

export interface RequestReportTemplateAction {
    type: typeof REQUEST_REPORT_TEMPLATE;
}

export interface ReceiveReportTemplateAction {
    type: typeof RECEIVE_REPORT_TEMPLATE;
    data: ReportTemplate;
}

export interface RequestReportTemplateListItemsAction {
    type: typeof REQUEST_ORG_TEMPLATE_LIST_ITEMS;
}

export interface ReceiveReportTemplateListItemsAction {
    type: typeof RECEIVE_ORG_TEMPLATE_LIST_ITEMS;
    data: ReportTemplateListItem[];
}

export interface DeleteOrgReportTemplateSuccessAction {
    type: typeof DELETE_ORG_REPORT_TEMPLATE_SUCCESS;
    data: string;
}

export interface DeleteOrgReportSuccessAction {
    type: typeof DELETE_ORG_REPORT_SUCCESS;
    data: string;
}
