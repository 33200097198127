export interface ExaminationBulkUpdate {
    id: string;
    name: string;
    status: ExaminationBulkUpdateStatus;
    createdBy: string;
    createdOn: string;
    errorMessage: string;
    rowErrors: ExaminationBulkUpdateResultError[];
    result: ExaminationBulkUpdateResult;
}

export interface ExaminationBulkUpdateResultError {
    row: number;
    message: string;
}

export interface ExaminationBulkUpdateResult {
    updatedExaminationCount: number;
    skippedExaminationCount: number;
    failedExaminationCount: number;
    totalCount: number;
    completedCount: number;
}

export const enum ExaminationBulkUpdateStatus {
    Pending = "Pending",
    Processing = "Processing",
    Complete = "Complete",
    Error = "Error",
}
