import { formatIsoDate } from "../../../helpers/dateTimeHelpers";
import { Approval, ApprovalStatus } from "../domain/types";

export interface ApprovalTableItem {
    inEdit: boolean;
    id?: string;
    name: string;
    status: string;
    updatedAt: string;
    comment: string;
}

function getNewApprovalTableItem(hasNewItem: boolean): ApprovalTableItem[] {
    if (hasNewItem) {
        return [
            {
                inEdit: true,
                id: null,
                name: "",
                status: "",
                updatedAt: "",
                comment: "",
            },
        ];
    }
    return [];
}

const mapToApprovalTableItem = (approval: Approval): ApprovalTableItem => ({
    inEdit: false,
    id: approval.id,
    name: approval.approver?.userEmail,
    status: ApprovalStatus[approval.status],
    updatedAt: formatIsoDate(approval.updatedAt),
    comment:
        approval.comments.length > 0
            ? approval.comments[approval.comments.length - 1]
            : "",
});

export function mapToApprovalTableItems(
    approvals: Approval[],
    hasNewItem: boolean,
): { virtualItems: ApprovalTableItem[]; approvalItems: ApprovalTableItem[] } {
    return {
        virtualItems: getNewApprovalTableItem(hasNewItem),
        approvalItems: approvals.map((approval) =>
            mapToApprovalTableItem(approval),
        ),
    };
}
