import * as React from "react";
import ProcessConstants from "../../../Constants/ProcessConstants";
import { Link } from "react-router-dom";
import { ProcessActivityListItem as ProcessActivityListItemType } from "../domain/types";
import { useGetProcessByIdQuery } from "../../../store/features/process/process-api-slice";
import ProcessActivityCard from "../../process-activity/components/ProcessActivityCard";
import { Button } from "react-bootstrap";
import { resolveActivityStatusFlags } from "../../process-activity/helpers/activityStateHelpers";

interface Props {
    activity: ProcessActivityListItemType;
    organisationId: string;
    processId: string;
}

export const ProcessActivityListItem: React.FC<Props> = (props) => {
    const { activity } = props;

    const { data: process } = useGetProcessByIdQuery(props.processId);
    const { isDisabled } = resolveActivityStatusFlags(activity);
    return (
        <ProcessActivityCard activity={activity}>
            <div className="d-flex justify-content-end">
                {isDisabled ? (
                    <Button disabled>Disabled</Button>
                ) : (
                    <Link
                        to={`${process.id}${ProcessConstants.Activity}/${activity.id}`}
                        className="btn btn-primary"
                    >
                        View
                    </Link>
                )}
            </div>

            <p className="card-text">{activity.description}</p>
        </ProcessActivityCard>
    );
};
export default ProcessActivityListItem;
