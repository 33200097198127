import { TranslateTagFunc } from "../../../query/configureQuery";
import { Tags } from "../../../store/features/dcpApi";
import { activityAssetsKey, orgAssetsKey } from "./assetQueryKeys";

const tagsToQueryKeys: TranslateTagFunc = (tag) => {
    switch (tag.type) {
        case Tags.OrganisationAssets:
            return orgAssetsKey(tag.id);
        case Tags.ProcessActivityAssets:
            return activityAssetsKey(tag.id);
        default:
            return null;
    }
};

export const assetQueryKeysTranslator = {
    tagsToQueryKeys,
};
