import * as React from "react";
import { useGetOrgByIdQuery } from "../../../../../store/features/organisation/organisation-api-slice";
import ProcessActivityFormWrapper from "../../../../process-activity-form/components/ProcessActivityFormWrapper";
import { useGetReviewFindingsFormByReviewId } from "../../query/examinationsReviewQueries";
import { Button, Card, Collapse } from "react-bootstrap";
import cx from "classnames";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { resolveActivityState } from "../../../../process-activity/helpers/activityStateHelpers";
import { useGetProcessActivityFormByIdQuery } from "../../../../process-activity-form/store/processActivityFormApiSlice";

interface Props {
    reviewId: string;
    processId: string;
    organisationId: string;
}

const ReviewFindings: React.FC<Props> = ({
    reviewId,
    processId,
    organisationId,
}) => {
    const [commentsOpen, setCommentsOpen] = React.useState(true);
    const { data: organisation } = useGetOrgByIdQuery(organisationId);
    const { data: reviewFindings } = useGetReviewFindingsFormByReviewId(
        organisationId,
        reviewId,
    );
    const { data: activityForm } = useGetProcessActivityFormByIdQuery(
        { activityId: reviewFindings?.activityId, processId },
        {
            skip: !reviewFindings?.activityId,
        },
    );

    const { isComplete, isNotApplicable } = resolveActivityState(activityForm);

    let headerCss = "activity-header";

    switch (true) {
        case isComplete:
            headerCss = cx(headerCss, "complete");
            break;
        case isNotApplicable:
            headerCss = cx(headerCss, "na");
            break;
        case !isComplete && !isNotApplicable:
            headerCss = cx(headerCss, "open");
            break;
        default:
            break;
    }

    return (
        <Card>
            <Card.Header className={headerCss}>
                <Button
                    onClick={() => setCommentsOpen(!commentsOpen)}
                    variant="link"
                    className="mr-2 p-0"
                    style={{ color: "#35373d" }}
                    aria-controls="commentsOpen"
                    aria-expanded={commentsOpen}
                >
                    {commentsOpen ? <FaAngleUp /> : <FaAngleDown />}
                </Button>
                <span>Engineers Comments</span>
            </Card.Header>
            <Collapse in={commentsOpen}>
                <Card.Body>
                    {reviewFindings && (
                        <ProcessActivityFormWrapper
                            processId={processId}
                            activityId={reviewFindings.activityId}
                            organisation={organisation}
                            disableTopBar={true}
                        />
                    )}
                </Card.Body>
            </Collapse>
        </Card>
    );
};

export default ReviewFindings;
