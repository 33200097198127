import { useState } from "react";

const useError = <T>(): {
    isError: boolean;
    error: T;
    setError: (error: T) => void;
    clearError: () => void;
} => {
    const [isError, setIsError] = useState<boolean>(false);
    const [error, setErrorData] = useState<T>(null);

    const clearError = (): void => {
        setErrorData(null);
        setIsError(false);
    };

    const setError = (errorData: T): void => {
        setErrorData(errorData);
        setIsError(true);
    };

    return { isError, error, setError, clearError };
};

export default useError;
