import { DateTime } from "luxon";
import { DataContinuationResult } from "../../../../common/types/DataResult";
import { InfiniteQueryOptions } from "../../../../common/types/SortAndFilterOptions";
import consumerApiService from "../../../../customization/api/consumerApiService";
import { patchData, postData } from "../../../../helpers/ApiHelper";
import { Work } from "../domain/Work";
import { ExaminationPlanning } from "../domain/examinationsPlanning";
import {
    ExaminationAssignModel,
    ExaminationCancelModel,
    ExaminationPlanningDto,
    ExaminationScheduleModel,
    ExaminationStateChangeModel,
    mapToExaminationPlanning,
} from "../domain/examinationsPlanningDtos";

enum UrlPaths {
    BaseResource = "organisation",
    Examinations = "examinations",
    SetPlannedDate = "setPlannedDate",
    Assign = "assign",
    Cancel = "cancel",
    Schedule = "schedule",
    Open = "open",
    MyWork = "my-work",
    ClaimExam = "claim-exam",
}

export interface ExaminationsSearchOptions extends InfiniteQueryOptions {
    onlyUnallocated: boolean;
}

export type MyWorkSearchOptions = InfiniteQueryOptions;

interface GetExaminationsArgs {
    organisationId: string;
    query: ExaminationsSearchOptions;
}

interface GetMyWorkArgs {
    organisationId: string;
    query: MyWorkSearchOptions;
}

const getBaseUrl = () =>
    `${consumerApiService.baseApiUrl}/${UrlPaths.BaseResource}`;

export async function getExaminationPlannings({
    organisationId,
    query,
}: GetExaminationsArgs): Promise<ExaminationPlanning[]> {
    const url = `${getBaseUrl()}/${organisationId}/${UrlPaths.Examinations}`;
    const result = postData<ExaminationPlanningDto[]>(url, query);

    const response = await result;
    if (!response) return;
    return response.map(mapToExaminationPlanning);
}

interface ScheduleExaminationsArgs {
    organisationId: string;
    patchModel: ExaminationScheduleModel;
}

export function setExaminationsPlannedDates({
    organisationId,
    patchModel,
}: ScheduleExaminationsArgs): Promise<void> {
    const url = `${getBaseUrl()}/${organisationId}/${UrlPaths.Examinations}/${
        UrlPaths.SetPlannedDate
    }`;

    return patchData(url, patchModel);
}
interface ClaimExamArgs {
    organisationId: string;
    processId: string;
    examDate: Date;
}

export function setClaimExam({
    organisationId,
    processId,
    examDate,
}: ClaimExamArgs): Promise<void> {
    const url = `${getBaseUrl()}/${organisationId}/${UrlPaths.Examinations}/${
        UrlPaths.ClaimExam
    }`;

    return postData(url, { processId, examDate });
}

interface AssignExaminationsArgs {
    organisationId: string;
    patchModel: ExaminationAssignModel;
}

export function assignExaminationPlannings({
    organisationId,
    patchModel,
}: AssignExaminationsArgs): Promise<void> {
    const url = `${getBaseUrl()}/${organisationId}/${UrlPaths.Examinations}/${
        UrlPaths.Assign
    }`;

    return patchData(url, patchModel);
}

interface CancelExaminationsArgs {
    organisationId: string;
    postModel: ExaminationCancelModel;
}

export function cancelExaminationPlannings({
    organisationId,
    postModel,
}: CancelExaminationsArgs): Promise<void> {
    const url = `${getBaseUrl()}/${organisationId}/${UrlPaths.Examinations}/${
        UrlPaths.Cancel
    }`;

    return postData(url, postModel);
}

interface ChangeExaminationsStateArgs {
    organisationId: string;
    postModel: ExaminationStateChangeModel;
}

export function scheduleExaminationPlannings({
    organisationId,
    postModel,
}: ChangeExaminationsStateArgs): Promise<void> {
    const url = `${getBaseUrl()}/${organisationId}/${UrlPaths.Examinations}/${
        UrlPaths.Schedule
    }`;

    return postData(url, postModel);
}

export function openExaminationPlannings({
    organisationId,
    postModel,
}: ChangeExaminationsStateArgs): Promise<void> {
    const url = `${getBaseUrl()}/${organisationId}/${UrlPaths.Examinations}/${
        UrlPaths.Open
    }`;

    return postData(url, postModel);
}

export async function getWork({
    organisationId,
    query,
}: GetMyWorkArgs): Promise<DataContinuationResult<Work>> {
    const url = `${getBaseUrl()}/${organisationId}/${UrlPaths.Examinations}/${
        UrlPaths.MyWork
    }`;
    const result = postData<DataContinuationResult<Work>>(url, query);

    const response = await result;
    if (!response.data) return;
    return {
        ...response,
        data: response.data.map(
            (a): Work => ({
                ...a,
                // Convert any date string to JS date object needed for kendo grid
                plannedDateJs: a.plannedDate
                    ? DateTime.fromISO(a.plannedDate).toJSDate()
                    : null,
            }),
        ),
    };
}
