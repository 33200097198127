import * as React from "react";
import { PhotoOrder } from "../../types";
import { TypedGridCellProps } from "../../../../../common/types/TypedGridCellProps";

const FileSourceCell: React.FC<TypedGridCellProps<PhotoOrder>> = (props) => {
    const { dataItem } = props;

    return (
        <td>
            <p>{dataItem.activityLabel + " > " + dataItem.inputLabel}</p>
        </td>
    );
};
export default FileSourceCell;
