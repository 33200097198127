import * as React from "react";
import { CefaFormControlFileTableDataItem } from "./CefaFormControlFileTableDataItem";
import { TypedGridCellProps } from "../../../../../../common/types/TypedGridCellProps";
import TextareaAutosize from "react-textarea-autosize";
import { formatCefaComment } from "../../../helpers/commentHelper";

interface Props {
    cell: TypedGridCellProps<CefaFormControlFileTableDataItem>;
}

const CefaFormControlFileTableCommentCell: React.FC<Props> = (props) => {
    const { cell } = props;
    const [comment, setComment] = React.useState<string>(cell.dataItem.comment);

    const onChange = (e) => {
        // Return if user presses the enter key
        if (e.nativeEvent.inputType === "insertLineBreak") return;

        if (cell.onChange) {
            cell.onChange({
                dataIndex: 0,
                dataItem: cell.dataItem,
                field: cell.field,
                syntheticEvent: e.syntheticEvent,
                value: formatCefaComment(
                    e.target.value,
                    cell.dataItem.captionDate,
                ),
            });

            setComment(e.target.value);
        }
    };

    return (
        <TextareaAutosize
            value={comment}
            onChange={onChange}
            style={{
                width: "100%",
                resize: "none",
                border: "none",
            }}
            readOnly={cell.dataItem.readOnly}
        />
    );
};

export default CefaFormControlFileTableCommentCell;
