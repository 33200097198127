import { Grid, GridColumn } from "@progress/kendo-react-grid";
import React from "react";
import {
    emptyFilterDescriptor,
    getColumnMenuCheckboxFilter,
} from "../../../common/components/table/kendo/columnFilters";
import useGridProps from "../../../common/components/table/kendo/useGridProps";
import { formatIsoDate } from "../../../helpers/dateTimeHelpers";
import { FileVersion } from "../domain/file";
import { downloadFile } from "../services/downloadFile";

interface Props {
    olderFileVersions: FileVersion[];
    folderId: string;
    fileId: string;
    organisationId: string;
}

const OlderFileVersionsGrid: React.FC<Props> = ({
    olderFileVersions,
    folderId,
    fileId,
    organisationId,
}) => {
    const { gridProps } = useGridProps(olderFileVersions, {
        filterable: true,
        initialFilter: emptyFilterDescriptor,
        sortable: true,
        pageable: false,
    });

    const columnMenuCheckboxFilter = getColumnMenuCheckboxFilter(
        olderFileVersions.map((ver) => ({
            name: ver.name,
            createdBy: ver.createdBy,
        })),
    );

    return (
        <>
            {olderFileVersions.length > 0 && (
                <div className="py-4">
                    <hr />
                    <h4>Previous Versions</h4>
                    <div className="pt-3">
                        <Grid
                            {...gridProps}
                            onRowClick={async (e) => {
                                await downloadFile({
                                    fileId,
                                    folderId,
                                    organisationId,
                                    fileName: e.dataItem.name,
                                    fileVerId: e.dataItem.id,
                                });
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            <GridColumn
                                field="versionNo"
                                title="Version"
                                width={96}
                            />
                            <GridColumn
                                field="name"
                                title="Name"
                                columnMenu={columnMenuCheckboxFilter}
                            />
                            <GridColumn
                                field="createdBy"
                                title="Created by"
                                columnMenu={columnMenuCheckboxFilter}
                            />
                            <GridColumn
                                field="createdOn"
                                title="Created on"
                                cell={(cell) => (
                                    <td
                                        colSpan={cell.colSpan}
                                        role={"gridcell"}
                                        aria-colindex={cell.ariaColumnIndex}
                                    >
                                        {formatIsoDate(cell.dataItem.createdOn)}
                                    </td>
                                )}
                            />
                        </Grid>
                    </div>
                </div>
            )}
        </>
    );
};

export default OlderFileVersionsGrid;
