import { Grid, GridColumn } from "@progress/kendo-react-grid";
import React, { useMemo } from "react";
import { ContainsMenuFilter } from "../../../../common/components/table/kendo/columnFilters";
import useGridProps from "../../../../common/components/table/kendo/useGridProps";

interface Props {
    values: string[];
}

const MassUpdateErrorsGrid: React.FC<Props> = ({ values }) => {
    const gridValues = useMemo(() => {
        return values.map((v, i) => {
            return { name: v, id: i };
        });
    }, [values]);

    const { gridProps } = useGridProps(gridValues, {
        filterable: true,
        sortable: true,
        initialSort: [{ field: "id", dir: "asc" }],
        pageable: true,
    });

    return (
        <Grid {...gridProps}>
            <GridColumn
                field="name"
                title="Message"
                columnMenu={ContainsMenuFilter}
            />
        </Grid>
    );
};

export default MassUpdateErrorsGrid;
