import {
    createSlice,
    PayloadAction,
    SliceCaseReducers,
} from "@reduxjs/toolkit";

export type TabState = string;
const initialState: TabState = "details";

const navigationTabSlice = createSlice<TabState, SliceCaseReducers<TabState>>({
    name: "navigationTab",
    initialState,
    reducers: {
        setTab(_, { payload }: PayloadAction<string>): TabState {
            return payload;
        },
    },
});

export const { setTab } = navigationTabSlice.actions;
export const reducer = navigationTabSlice.reducer;
