import {
    AssetTemplate,
    Template,
    TemplateCreateModel,
    TemplateCreateResponse,
    TemplateUpdateModel,
} from "../../../modules/template/domain/types";
import { dcpApi, Tags } from "../dcpApi";

class UrlPaths {
    static readonly ResourceName = "/assettemplate";
}

export const assetTemplateApi = dcpApi.injectEndpoints({
    endpoints: (builder) => ({
        postAssetTemplate: builder.mutation<
            TemplateCreateResponse,
            TemplateCreateModel
        >({
            query: (body) => ({
                url: `${UrlPaths.ResourceName}`,
                method: "POST",
                body,
            }),
            invalidatesTags: (result, error, arg) => [
                {
                    type: Tags.OrganisationAssetTemplates,
                    id: arg.organisationId,
                },
            ],
        }),
        putAssetTemplateById: builder.mutation<
            Template,
            Pick<TemplateUpdateModel, "id"> & Partial<TemplateUpdateModel>
        >({
            query: ({ id, ...patch }) => ({
                url: `${UrlPaths.ResourceName}/${id}`,
                method: "PUT",
                body: patch,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: Tags.AssetTemplate, id: arg.id },
                {
                    type: Tags.OrganisationAssetTemplates,
                    id: arg.organisationId,
                },
            ],
        }),
        getAssetTemplateById: builder.query<AssetTemplate, string>({
            query: (templateID) => `${UrlPaths.ResourceName}/${templateID}`,
            providesTags: (result, error, id) => [
                { type: Tags.AssetTemplate, id },
            ],
        }),
        getAssetTemplateByOrgId: builder.query<AssetTemplate[], string>({
            query: (organisationId) =>
                `${UrlPaths.ResourceName}?organisationId=${organisationId}`,
            providesTags: (result, error, id) => [
                { type: Tags.OrganisationAssetTemplates, id },
            ],
        }),
        deleteAssetTemplate: builder.mutation<
            void,
            { id: string; organisationId: string }
        >({
            query: (assetTemplate) => ({
                url: `${UrlPaths.ResourceName}/${assetTemplate.id}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, assetTemplate) => [
                {
                    type: Tags.AssetTemplate,
                    id: assetTemplate.id,
                },
                {
                    type: Tags.OrganisationAssetTemplates,
                    id: assetTemplate.organisationId,
                },
            ],
        }),
    }),
});

export const {
    useGetAssetTemplateByIdQuery,
    usePostAssetTemplateMutation,
    usePutAssetTemplateByIdMutation,
    useGetAssetTemplateByOrgIdQuery,
    useDeleteAssetTemplateMutation,
} = assetTemplateApi;
