import React from "react";
import { Alert, Button } from "react-bootstrap";
import CoveringLoadingPanel from "../../../common/components/CoveringLoadingPanel";
import { HttpMethod } from "../../../common/http/HttpMethod";
import ApiConstants from "../../../Constants/ApiConstants";
import { DownloadFile } from "../../../helpers/ApiHelper";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";
import { useGetUserRequestedFileStatus } from "../queries/userRequestedFileQueries";

type UserRequestedFileStatusDownloadProps = {
    fileId: string;
};

const baseUrl = `${ApiConstants.ApiPrefix}/organisation`;

function UserRequestedFileStatusDownload({
    fileId,
}: UserRequestedFileStatusDownloadProps): JSX.Element {
    const { organisationId } = useOrganisationId();
    const { data, isFetching, refetch } = useGetUserRequestedFileStatus(
        organisationId,
        fileId,
    );
    const [isDownloading, setIsDownloading] = React.useState(false);

    const downloadReport = React.useCallback(async () => {
        setIsDownloading(true);
        DownloadFile({
            method: HttpMethod.Get,
            url: `${baseUrl}/${organisationId}/UserRequestedFiles/GetUserRequestedFile/${fileId}`,
        })
            .then(() => {
                setIsDownloading(false);
            })
            .catch(() => {
                setIsDownloading(false);
            });
    }, [organisationId, fileId]);

    const isLoading = isFetching || isDownloading;

    const alert = () => {
        if (!data) {
            return <></>;
        }
        switch (data.status) {
            case "Complete":
                return (
                    <Alert variant="success">
                        <Alert.Heading>
                            Report generation finished!
                        </Alert.Heading>
                        <p>{`The report is ready to be downloaded.`}</p>
                        <Button
                            disabled={isLoading}
                            onClick={() => downloadReport()}
                        >
                            Download
                        </Button>
                    </Alert>
                );
            case "Failed":
                return (
                    <Alert variant="danger">
                        <Alert.Heading>Report generation failed!</Alert.Heading>
                        <p>{`The process has failed, please contact the support if this problem occurs multiple times.`}</p>
                    </Alert>
                );

            default:
                return (
                    <Alert variant="info">
                        <Alert.Heading>
                            Report is being generated!
                        </Alert.Heading>
                        <p>{`The report is currently being generated. It can take up to several minutes. Don't leave this page in order to download it after completion.`}</p>
                        <p>Current status: {data.status}</p>{" "}
                        <Button disabled={isLoading} onClick={() => refetch()}>
                            Check status
                        </Button>
                    </Alert>
                );
        }
    };

    return (
        <>
            {isFetching && <CoveringLoadingPanel />}
            <div className="mt-4">{alert()}</div>
        </>
    );
}

export default UserRequestedFileStatusDownload;
