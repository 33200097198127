import { load } from "@progress/kendo-react-intl";
import "bootstrap/dist/css/bootstrap.css";
import currencyData from "cldr-core/supplemental/currencyData.json";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import weekData from "cldr-core/supplemental/weekData.json";
import gbCaGregorian from "cldr-dates-full/main/en-GB/ca-gregorian.json";
import gbDateFields from "cldr-dates-full/main/en-GB/dateFields.json";
import gbLocalCurrency from "cldr-numbers-full/main/en-GB/currencies.json";
import gbNumbers from "cldr-numbers-full/main/en-GB/numbers.json";
import { ConnectedRouter } from "connected-react-router";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import App from "./App";
import AppInsights from "./AppInsights";
import { AuthProvider } from "./AuthProvider";
import { ConsumerApiProvider } from "./customization/api/ConsumerApiProvider";
import { CefaCustomComponentsProvider } from "./modules/cefa/customization/CefaCustomComponentsProvider";
import { queryClient } from "./query/configureQuery";
import { QueryClientSetup } from "./QueryClientSetup";
import { Startup } from "./Startup";
import { history, store } from "./store/configureStore";
import "./styles/main.scss";

load(
    likelySubtags,
    currencyData,
    gbDateFields,
    gbCaGregorian,
    weekData,
    gbNumbers,
    gbLocalCurrency,
);

ReactDOM.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <QueryClientSetup>
                <Startup>
                    <ConsumerApiProvider>
                        <CefaCustomComponentsProvider>
                            <AppInsights>
                                <AuthProvider>
                                    <ConnectedRouter history={history}>
                                        <App />
                                    </ConnectedRouter>
                                </AuthProvider>
                            </AppInsights>
                        </CefaCustomComponentsProvider>
                    </ConsumerApiProvider>
                </Startup>
            </QueryClientSetup>
        </QueryClientProvider>
    </Provider>,
    document.getElementById("root"),
);
