import { HttpMethod } from "../../../../common/http/HttpMethod";
import { DataContinuationResult } from "../../../../common/types/DataResult";
import { InfiniteQueryOptions } from "../../../../common/types/SortAndFilterOptions";
import consumerApiService from "../../../../customization/api/consumerApiService";
import { DownloadFile, postData } from "../../../../helpers/ApiHelper";
import { ExamRequest } from "../domain/examRequest";
import { ExamRequestDto, mapToExamRequest } from "../domain/examRequestDtos";

export enum UrlPaths {
    BaseResource = "organisation",
    ExamRequests = "ces/exam-requests",
}

export interface ExamRequestsSearchOptions extends InfiniteQueryOptions {
    contractYear?: string;
}

interface GetExaminationsArgs {
    organisationId: string;
    query: ExamRequestsSearchOptions;
}

const getBaseUrl = (organisationId: string) =>
    `${consumerApiService.baseApiUrl}/${UrlPaths.BaseResource}/${organisationId}`;

export async function getInfiniteExamRequests({
    organisationId,
    query,
}: GetExaminationsArgs): Promise<DataContinuationResult<ExamRequest>> {
    const url = `${getBaseUrl(organisationId)}/${UrlPaths.ExamRequests}`;
    const result = postData<DataContinuationResult<ExamRequestDto>>(url, query);

    const response = await result;
    if (!response.data) return;
    return {
        ...response,
        data: response.data.map(mapToExamRequest),
    };
}

interface DownloadExamRequestsCsvArgs {
    organisationId: string;
    contractYear?: string;
}
export function downloadExamRequestsCsv({
    organisationId,
    contractYear,
}: DownloadExamRequestsCsvArgs): Promise<void> {
    const url = `${getBaseUrl(organisationId)}/${
        UrlPaths.ExamRequests
    }/download`;

    return DownloadFile({
        method: HttpMethod.Post,
        url,
        payload: { contractYear },
    });
}
