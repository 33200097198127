import * as Formik from "formik";
import * as React from "react";
import { Alert, Button, Col, Row, Spinner } from "react-bootstrap";
import { CustomSelect } from "../../../../common/components/form";
import {
    useGetApcIncidentByIdQuery,
    useUpdateApcIncidentMutation,
} from "../queries/apcApiSlice";
import { useOrganisationId } from "../../../organisation/hooks/useOrganisationId";
import {
    ApcIncidentAsset,
    ApcIncidentUpdateRequestData,
} from "../domain/types";
import { getCossIwaOptions } from "../common/options";
import CoveringLoadingPanel from "../../../../common/components/CoveringLoadingPanel";
import { SelectOptionItem } from "../../../../common/types/reactSelect/SelectOptionItem";
import * as yup from "yup";
import ExamViewControl from "./ExamViewControl";
import { Card, CardBody } from "reactstrap";
import ExamCreateInput from "./ExamCreateInput";
import ViewAssetFormInput from "./ViewAssetFormInput";
import SelectAssetFormInput from "./SelectAssetFormInput";
import { IncidentFormModel } from "./types";
import NetworkRailControlInfo from "./NetworkRailControlInfo";
import { baseSchema } from "./validationSchemas";
import ExaminerInformationControl from "./ExaminerInformationControl";
import ExaminerControl from "./ExaminerControl";
import CompleteControl from "./CompleteControl";

interface IncidentUpdateFormModel extends IncidentFormModel {
    apcAssetDetails?: ApcIncidentAsset;
    lineDispensation: string | SelectOptionItem;
    trainsStop: string | SelectOptionItem;
    momStatus: string | SelectOptionItem;
    examinerId: string | SelectOptionItem;
    cossIwaForm: string | SelectOptionItem;
    reportType: string | SelectOptionItem;
    createExam?: string[];
}

const formInitialValues: IncidentUpdateFormModel = {
    assetId: null,
    nrIncidentNumber: "",
    ameyNotifiedAt: null,
    incidentAt: null,
    nrNotifiedAt: null,
    natureOfIncident: "",
    nrControlTelNum: "",
    nrControllerName: "",
    lineDispensation: null,
    trainsStop: null,
    momStatus: null,
    momName: "",
    momEta: "",
    momTelNum: "",
    signalBoxName: "",
    signalBoxTelNum: "",
    apcAssetDetails: null,
    examinerId: null,
    examinerMobNum: "",
    examinerNotifiedAt: null,
    examinerEtaAt: null,
    cossIwaForm: null,
    examinerConfirmedToNrAt: null,
    examinerSiteArrivalAt: null,
    timePassesAtMph: null,
    passedAtMph: null,
    passedAtLineSpeedAt: null,
    examinerDepartureSiteAt: null,
    incidentClearedAt: null,
    incidentNotes: "",
    reportType: null,
    carrsExamId: null,
    createExam: [],
};
const apcValidation = baseSchema.shape({
    assetId: yup
        .string()
        .nullable()
        .when("createExam", {
            is: (val) => val?.length > 0,
            then: yup
                .string()
                .nullable()
                .required("Required to create exam report"),
        })
        .when("incidentClearedAt", {
            is: (val) => (val === "" ? false : val),
            then: yup.string().required("Required").nullable(),
        }),
    examinerId: yup
        .mixed()
        .nullable()
        .when("createExam", {
            is: (val) => val?.length > 0,
            then: yup
                .mixed()
                .nullable()
                .required("Required to create exam report"),
        })
        .when("incidentClearedAt", {
            is: (val) => (val === "" ? false : val),
            then: yup.mixed().required("Required").nullable(),
        }),
    reportType: yup.mixed().when("createExam", {
        is: (val) => val?.length > 0,
        then: yup.mixed().required("Required to create exam report"),
    }),
});

function mapUpdateModel(
    organisationId: string,
    incidentId: string,
    formValues: IncidentUpdateFormModel,
): ApcIncidentUpdateRequestData {
    return {
        ...formValues,
        organisationId: organisationId,
        id: incidentId,
        examinerId:
            (formValues.examinerId as SelectOptionItem)?.value ??
            (formValues.examinerId as string),
        lineDispensation:
            (formValues.lineDispensation as SelectOptionItem)?.value ??
            (formValues.lineDispensation as string),
        momStatus:
            (formValues.momStatus as SelectOptionItem)?.value ??
            (formValues.momStatus as string),
        trainsStop:
            (formValues.trainsStop as SelectOptionItem)?.value ??
            (formValues.trainsStop as string),
        cossIwaForm:
            (formValues.cossIwaForm as SelectOptionItem)?.value ??
            (formValues.cossIwaForm as string),
        examType:
            (formValues.reportType as SelectOptionItem)?.value ??
            (formValues.reportType as string),
        createExam: formValues.createExam?.length > 0,
        examinerEtaAt:
            formValues.examinerEtaAt === "" ? null : formValues.examinerEtaAt,
        examinerNotifiedAt:
            formValues.examinerNotifiedAt === ""
                ? null
                : formValues.examinerNotifiedAt,
        ameyNotifiedAt:
            formValues.ameyNotifiedAt === "" ? null : formValues.ameyNotifiedAt,
        nrNotifiedAt:
            formValues.nrNotifiedAt === "" ? null : formValues.nrNotifiedAt,
        passedAtMph:
            formValues.passedAtMph === "" ? null : formValues.passedAtMph,
        examinerConfirmedToNrAt:
            formValues.examinerConfirmedToNrAt === ""
                ? null
                : formValues.examinerConfirmedToNrAt,
        examinerSiteArrivalAt:
            formValues.examinerSiteArrivalAt === ""
                ? null
                : formValues.examinerSiteArrivalAt,
        examinerDepartureSiteAt:
            formValues.examinerDepartureSiteAt === ""
                ? null
                : formValues.examinerDepartureSiteAt,
        timePassesAtMph:
            formValues.timePassesAtMph === ""
                ? null
                : formValues.timePassesAtMph,
        passedAtLineSpeedAt:
            formValues.passedAtLineSpeedAt === ""
                ? null
                : formValues.passedAtLineSpeedAt,
        incidentClearedAt:
            formValues.incidentClearedAt === ""
                ? null
                : formValues.incidentClearedAt,
        incidentAt: formValues.incidentAt === "" ? null : formValues.incidentAt,
    };
}

const IncidentUpdateForm: React.FC<{
    incidentId: string;
    setEditingState: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ incidentId, setEditingState }) => {
    const { organisationId } = useOrganisationId();
    const { data, isLoading } = useGetApcIncidentByIdQuery({
        id: incidentId,
        organisationId: organisationId,
    });
    const [updateMutation] = useUpdateApcIncidentMutation();

    const onSubmit = React.useCallback(
        (
            formValues: IncidentUpdateFormModel,
            { setSubmitting }: Formik.FormikHelpers<IncidentUpdateFormModel>,
        ): void => {
            updateMutation(
                mapUpdateModel(organisationId, incidentId, formValues),
            )
                .unwrap()
                .then((result) => {
                    setEditingState(false);
                })
                .catch(() => setSubmitting(false));
        },
        [updateMutation, organisationId, incidentId, setEditingState],
    );

    if (isLoading) {
        return <CoveringLoadingPanel />;
    }

    return (
        <Formik.Formik
            validationSchema={apcValidation}
            enableReinitialize
            initialValues={isLoading ? formInitialValues : data}
            onSubmit={onSubmit}
            initialTouched={{
                natureOfIncident: true,
                incidentAt: true,
                ameyNotifiedAt: true,
                nrNotifiedAt: true,
                nrControlTelNum: true,
                nrControllerName: true,
                lineDispensation: true,
                trainsStop: true,
                createExam: true,
                momStatus: true,
                examinerId: true,
                examinerMobNum: true,
                examinerNotifiedAt: true,
                examinerEtaAt: true,
                cossIwaForm: true,
                examinerConfirmedToNrAt: true,
                examinerSiteArrivalAt: true,
                passedAtLineSpeedAt: true,
                examinerDepartureSiteAt: true,
            }}
            validateOnMount={false}
        >
            {({ isSubmitting }) => (
                <Card className="m-3">
                    <CardBody>
                        <Formik.Form noValidate>
                            <NetworkRailControlInfo />

                            <h2>Asset Information</h2>
                            {data.examDetails && (
                                <>
                                    <Alert variant="secondary">
                                        <Alert.Heading>
                                            <h5>
                                                An exam is associated with this
                                                asset and incident. In order to
                                                change asset, this incident must
                                                be cancelled and a replacement
                                                incident created.
                                            </h5>
                                        </Alert.Heading>
                                    </Alert>
                                    <ViewAssetFormInput />
                                </>
                            )}
                            {!data.examDetails && (
                                <SelectAssetFormInput
                                    organisationId={organisationId}
                                />
                            )}
                            <ExaminerControl />
                            {data.examDetails && (
                                <>
                                    <Alert variant="secondary">
                                        <Alert.Heading>
                                            <h5>
                                                An exam is associated with this
                                                asset and incident. In order to
                                                change asset, this incident must
                                                be cancelled and a replacement
                                                incident created.
                                            </h5>
                                        </Alert.Heading>
                                    </Alert>
                                    <ExamViewControl
                                        processId={data.examDetails.processId}
                                    />
                                </>
                            )}
                            {!data.examDetails && <ExamCreateInput />}
                            <Row>
                                <Col xs={"auto"}>
                                    <CustomSelect
                                        name="cossIwaForm"
                                        label="COSS/IWA Form"
                                        description={
                                            "Have you asked Examiner if they have filled in COSS/IWA Form for Use in 'Exceptional Circumstances'"
                                        }
                                        options={getCossIwaOptions()}
                                    />
                                </Col>
                            </Row>

                            <ExaminerInformationControl />
                            <CompleteControl />
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {isSubmitting && (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                                Update Incident
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    setEditingState(false);
                                }}
                                disabled={isSubmitting}
                            >
                                Cancel
                            </Button>
                        </Formik.Form>
                    </CardBody>
                </Card>
            )}
        </Formik.Formik>
    );
};
export default IncidentUpdateForm;
