import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react";
import { FaDownload } from "react-icons/fa";
import { HttpMethod } from "../../../../common/http/HttpMethod";
import useConsumerApiSettings from "../../../../customization/api/useConsumerApiSettings";
import { DownloadFile } from "../../../../helpers/ApiHelper";

interface DownloadCellProps {
    cellProps: GridCellProps;
    organisationId: string;
}
const DownloadCell: React.FC<DownloadCellProps> = ({
    cellProps,
    organisationId,
}) => {
    const [isDownloading, setIsDownloading] = React.useState(false);

    const { baseApiUrl } = useConsumerApiSettings();

    const downloadFile = () => {
        DownloadFile({
            method: HttpMethod.Get,
            url: `${baseApiUrl}/organisation/${organisationId}/CommercialMassUpdater/${cellProps.dataItem.id}`,
        })
            .then(() => {
                setIsDownloading(false);
            })
            .catch(() => {
                setIsDownloading(false);
            });
    };

    return (
        <td className="k-command-cell">
            <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-save-command"
                onClick={downloadFile}
                disabled={isDownloading}
            >
                <FaDownload />
            </button>
        </td>
    );
};
export default DownloadCell;
