import React from "react";
import { FaClipboard } from "react-icons/fa";
import NavLink from "../../../../common/components/NavLink";
import useIsClient from "../../../../common/hooks/useIsClient";
import PermissionConstants from "../../../../common/permissions/PermissionConstants";
import { AppRoutes } from "../../../../router/AppRoutes";
import { useNavigation } from "../../../../router/useNavigate";
import { hasUserOrgPermission } from "../../../../store/features/user/user-api-slice";
import CefaConstants from "../../constants/CefaConstants";

const MyWorkMenuItem: React.FC<{ organisationId: string }> = ({
    organisationId,
}) => {
    const { getOrgLink: getLink } = useNavigation();

    const permission = hasUserOrgPermission(
        organisationId,
        PermissionConstants.MyWork,
    ).hasPermission;

    const isCefa = useIsClient(CefaConstants.ClientName);

    if (permission && isCefa) {
        return (
            <NavLink
                to={getLink(AppRoutes.MyWork)}
                label="My Work"
                icon={FaClipboard}
                eventKey="my-work"
            />
        );
    } else {
        return null;
    }
};

export default MyWorkMenuItem;
