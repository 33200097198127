import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import ButtonSpinner from "../../../common/components/button/ButtonSpinner";
import { useGenerateMutation } from "../store/ProcessActivityArtefactGenerationApiSlice";

interface Props {
    id: string;
}

const ProcessActivityArtefactGenerationActions: React.FC<Props> = ({ id }) => {
    const [generate, generationResult] = useGenerateMutation();

    return (
        <Row className="mt-3">
            <Col>
                <Button
                    variant="primary"
                    onClick={() => generate(id)}
                    disabled={generationResult.isLoading}
                >
                    Generate
                    {generationResult.isLoading && <ButtonSpinner />}
                </Button>
            </Col>
        </Row>
    );
};

export default ProcessActivityArtefactGenerationActions;
