import React from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { ExaminationState } from "../../../examinations/domain/ExaminationState";
import { BatchDetails, BatchExam } from "../../domain/batching";

type Props = {
    batch: BatchDetails;
};

export const isInvalidBatch = (batch: BatchDetails): boolean => {
    return invalidExams(batch.exams).length > 0;
};

export const invalidExams = (batchExam: BatchExam[]): BatchExam[] => {
    const validStates = [
        ExaminationState.AwaitingBatching.toString(),
        ExaminationState.Delivered.toString(),
        ExaminationState.Submitting.toString(),
        ExaminationState.Submitted.toString(),
    ];

    return batchExam.filter((a) => !validStates.includes(a.processState));
};

const InvalidExamsAlert: React.FC<Props> = ({ batch }) => {
    if (isInvalidBatch(batch)) {
        return (
            <Row>
                <Col>
                    <Alert variant="danger">
                        <Alert.Heading>Batch Invalid</Alert.Heading>
                        <p>
                            {" "}
                            Only exams in state &quot;Awaiting Batching&quot; or
                            &quot;Delivered&quot; can be exported.
                        </p>
                        {invalidExams(batch.exams).map((a) => (
                            <li key={a.processId}>
                                {" "}
                                {a.processName} {a.structureRefNo} -{" "}
                                {a.processState}
                            </li>
                        ))}
                    </Alert>
                </Col>
            </Row>
        );
    } else {
        return null;
    }
};

export default InvalidExamsAlert;
