import {
    createSlice,
    PayloadAction,
    SliceCaseReducers,
} from "@reduxjs/toolkit";
import { IdAndName } from "../../../common/types/IdAndName";

export interface MoveFilesAndFoldersState {
    toMove: FileAndFolderIds | null;
}
const initialState: MoveFilesAndFoldersState = {
    toMove: null,
};

export interface FileAndFolderIds {
    files: IdAndName[];
    folders: IdAndName[];
}

const moveFilesAndFoldersSlice = createSlice<
    MoveFilesAndFoldersState,
    SliceCaseReducers<MoveFilesAndFoldersState>
>({
    name: "moveFilesAndFolders",
    initialState,
    reducers: {
        startMoving(state, { payload }: PayloadAction<FileAndFolderIds>) {
            state.toMove = payload;
        },
        stopMoving(state) {
            state.toMove = null;
        },
    },
});

export const { startMoving, stopMoving } = moveFilesAndFoldersSlice.actions;
export const reducer = moveFilesAndFoldersSlice.reducer;
