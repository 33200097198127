import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import ApiConstants from "../../../../Constants/ApiConstants";
import { postData } from "../../../../helpers/ApiHelper";
import { RequestedFileDto } from "../../../user-requested-files/queries/types";
import userRequestedFileQueryKeys from "../../../user-requested-files/queries/userRequestedFileQueryKeys";

class SelectedDatesDto {
    StartDate: Date;
    EndDate: Date;
    AllOrgs: string;
}

enum UrlPaths {
    BaseResource = "organisation",
}

const baseUrl = `${ApiConstants.ApiPrefix}/${UrlPaths.BaseResource}`;

export const useGetSsowMutation = (
    organisationId: string,
    selectedDates: SelectedDatesDto,
): UseMutationResult<RequestedFileDto, unknown, unknown> => {
    const queryClient = useQueryClient();

    const mutationFn = () => {
        const url = `${baseUrl}/${organisationId}/ssowDataExport`;
        return postData<RequestedFileDto>(url, { dates: selectedDates });
    };

    return useMutation(mutationFn, {
        onSuccess(result, _args) {
            return queryClient.invalidateQueries(
                userRequestedFileQueryKeys.userRequestedFileKey(result.id),
            );
        },
    });
};
