import React from "react";
import { Button } from "react-bootstrap";
import { TypedGridCellProps } from "../../../common/types/TypedGridCellProps";
import { ApprovalTableItem } from "../viewModel/ApprovalTableItem";

interface Props {
    isReadOnly: boolean;
    isApproversReadOnly: boolean;
    onRequest: (dataItem: ApprovalTableItem) => void;
    onRemove: (dataItem: ApprovalTableItem) => void;
}

export const ApprovalActionsCell: React.FC<
    TypedGridCellProps<ApprovalTableItem> & Props
> = ({ isReadOnly, isApproversReadOnly, dataItem, onRemove, onRequest }) => {
    if (isReadOnly || dataItem.inEdit) {
        return <td></td>;
    }

    return (
        <td>
            <Button
                variant="outline-secondary"
                className="ml-1 my-1"
                onClick={() => {
                    onRequest(dataItem);
                }}
            >
                Request
            </Button>
            {!isApproversReadOnly && (
                <Button
                    variant="outline-danger"
                    className="ml-1 my-1"
                    onClick={() => {
                        onRemove(dataItem);
                    }}
                >
                    Remove
                </Button>
            )}
        </td>
    );
};
