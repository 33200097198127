import { GridToolbar } from "@progress/kendo-react-grid";
import * as React from "react";
import { Button } from "react-bootstrap";
import Breadcrumbs from "../../../../../common/components/breadcrumbs/Breadcrumbs";
import { SortAndFilterOptions } from "../../../../../common/types/SortAndFilterOptions";
import { useOrganisationId } from "../../../../organisation/hooks/useOrganisationId";
import MissingCarrsExamInfiniteGrid from "../components/MissingCarrsExamInfiniteGrid";
import MissingCarrsExamToolUploadDialog from "../components/MissingCarrsExamToolUploadDialog";
import { downloadTemplate } from "../services/missingCarrsExamService";
import pageTitles from "./pageTitles";

const MissingCarrsExamToolPage: React.FC = () => {
    const { organisationId } = useOrganisationId();
    const [showUploadDialog, setShowUploadDialog] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [currentParams, setCurrentParams] =
        React.useState<SortAndFilterOptions>({});

    const handleDownloadTemplate = async (): Promise<void> => {
        setIsLoading(true);
        try {
            await downloadTemplate({
                organisationId,
                query: currentParams,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleParamsChange = (params: SortAndFilterOptions) => {
        setCurrentParams(params);
    };

    return (
        <>
            <div className="pt-2">
                <Breadcrumbs
                    items={[[pageTitles.missingCarrsExamTool]]}
                ></Breadcrumbs>
            </div>

            <h1>{pageTitles.missingCarrsExamTool}</h1>
            <MissingCarrsExamInfiniteGrid
                organisationId={organisationId}
                onFilterSortChange={handleParamsChange}
            >
                <GridToolbar>
                    <Button
                        variant="primary"
                        onClick={handleDownloadTemplate}
                        disabled={isLoading}
                    >
                        Export Excel
                    </Button>

                    <Button
                        variant="primary"
                        onClick={() => setShowUploadDialog(true)}
                    >
                        Upload
                    </Button>
                </GridToolbar>
            </MissingCarrsExamInfiniteGrid>

            {showUploadDialog && (
                <MissingCarrsExamToolUploadDialog
                    onClose={() => setShowUploadDialog(false)}
                    organisationId={organisationId}
                />
            )}
        </>
    );
};

export default MissingCarrsExamToolPage;
