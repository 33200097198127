import { useMutation, UseMutationResult } from "react-query";
import { ReactiveExamCreatorModel } from "../domain/ReactiveExamCreatorDtos";
import { create } from "../services/ReactiveExamCreatorService";

export const useReactiveExamCreatorMutation = (
    organisationId: string,
): UseMutationResult<unknown, unknown, ReactiveExamCreatorModel> => {
    const reactiveExamCreatorFunction = (postModel: ReactiveExamCreatorModel) =>
        create({ organisationId, postModel });

    return useMutation(reactiveExamCreatorFunction);
};
