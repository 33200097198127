import React from "react";
import { Card } from "react-bootstrap";
import { TargetLink } from "../domain/types";
interface Props {
    target: TargetLink;
}

const TargetLinkCard: React.FC<Props> = ({ target }) => {
    if (!target) return null;

    return (
        <Card>
            <Card.Body>
                <Card.Title>Report Link</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                    Approval Target
                </Card.Subtitle>
                <Card.Link href={target.url}>View report here</Card.Link>
            </Card.Body>
        </Card>
    );
};
export default TargetLinkCard;
