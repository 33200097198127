import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import * as Formik from "formik";
import { ErrorMessage } from "formik";
import * as React from "react";
import { Button, Form } from "react-bootstrap";
import { Input } from "reactstrap";
import * as yup from "yup";
import { useUploadExaminationBulkUpdateMutation } from "../../query/examinationsBulkUpdatesQueries";

interface Props {
    organisationId: string;
    onClose: () => void;
}

interface FormValues {
    file: File;
}

const ExaminationBulkUpdaterUploadDialog: React.FC<Props> = ({
    organisationId,
    onClose,
}) => {
    const initialFormValues: FormValues = {
        file: null,
    };

    const mutation = useUploadExaminationBulkUpdateMutation(organisationId);

    const validation = yup.object({
        file: yup.mixed().required("Required"),
    });

    const handleUploadSubmit = async ({ file }: FormValues): Promise<void> => {
        await mutation.mutateAsync(file);
        onClose();
    };

    return (
        <Dialog
            title="Bulk Update Upload"
            onClose={() => onClose()}
            width={400}
        >
            <Formik.Formik
                initialValues={initialFormValues}
                validationSchema={validation}
                onSubmit={handleUploadSubmit}
            >
                {({ setFieldValue, touched, errors, isSubmitting }) => (
                    <Formik.Form>
                        <Form.Group className="py-4">
                            <Input
                                type="file"
                                name="file"
                                className={
                                    touched.file && errors.file
                                        ? "is-invalid"
                                        : ""
                                }
                                role="button"
                                onChange={(e) => {
                                    setFieldValue("file", e.target.files[0]);
                                }}
                            />
                            <ErrorMessage
                                name={"file"}
                                className="invalid-feedback"
                                component="div"
                            />
                        </Form.Group>
                        <DialogActionsBar layout="end">
                            <div className="pt-3">
                                <Button
                                    variant="link"
                                    onClick={() => onClose()}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Confirm
                                </Button>
                            </div>
                        </DialogActionsBar>
                    </Formik.Form>
                )}
            </Formik.Formik>
        </Dialog>
    );
};

export default ExaminationBulkUpdaterUploadDialog;
