import { goBack, push } from "connected-react-router";
import * as Formik from "formik";
import React, { useEffect } from "react";
import { useAppDispatch } from "../../../store/hooks";

import { Row, Col, FormGroup, Form, Button, Breadcrumb } from "react-bootstrap";
import { useParams } from "react-router";
import {
    ProcessReportTemplate,
    usePostProcessReportTemplateMutation,
} from "../../../store/features/process-report-template/process-report-template-api-slice";
import { Link } from "react-router-dom";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import ProcessReportTemplateConstants from "../../../Constants/ProcessReportTemplateConstants";
import { Errors } from "../../../common/types/Errors";
import bsCustomFileInput from "bs-custom-file-input";
import { useOrgTitleById } from "../../../common/hooks/useTitle";
import ReportTemplatePageTitles from "./pageTitles";
import { useGetOrgByShortNameQuery } from "../../../store/features/organisation/organisation-api-slice";

interface ReportTemplateCreatePageProps {
    orgShortName: string;
}
interface FormValues {
    fileTemplate: string;
    name: string;
    description: string;
}

const ReportTemplateCreatePage: React.FC<ReportTemplateCreatePageProps> = ({
    orgShortName,
}) => {
    const appDispatch = useAppDispatch();
    const { id: organisationId } = useParams<{ id: string }>();
    const handleCancel = () => {
        appDispatch(goBack());
    };
    const params = useParams<{
        processTemplateId: string;
    }>();
    const { data: organisation } = useGetOrgByShortNameQuery(orgShortName, {
        skip: !orgShortName,
    });
    const createSuccessful = (res: ProcessReportTemplate | undefined) => {
        appDispatch(
            push(
                `${ProcessReportTemplateConstants.ReportTemplateRoutePath}/${params.processTemplateId}${ProcessReportTemplateConstants.ReportTemplateView}/${res.id}`,
            ),
        );
    };

    const [createProcessReportTemplate] =
        usePostProcessReportTemplateMutation();

    const getInitialValues = (): FormValues => {
        const values = {
            fileTemplate: "",
            name: "",
            description: "",
        };
        return values;
    };
    const validate = (values: FormValues) => {
        const errors: Errors = {};

        if (!values.name) {
            errors.name = "Required";
        }
        if (!values.fileTemplate) {
            errors.fileTemplate = "Required";
        }
        return errors;
    };

    useOrgTitleById(organisationId, ReportTemplatePageTitles.Create);

    useEffect(() => {
        bsCustomFileInput.init();
    }, []);

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={"div"}>
                            <Link
                                to={`${OrganisationConstants.RoutePath}/${organisationId}`}
                            >
                                Manage
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link
                                to={`${OrganisationConstants.RoutePath}/${organisationId}?tab=processTemplates`}
                            >
                                {organisation?.features?.process?.createName ??
                                    "Process"}{" "}
                                Templates
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Create </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div>
                        <Formik.Formik
                            initialValues={getInitialValues()}
                            validate={validate}
                            onSubmit={async (values, { setSubmitting }) => {
                                setSubmitting(true);
                                const formData = new FormData();
                                formData.append("file", values.fileTemplate);
                                formData.append("name", values.name);
                                formData.append(
                                    "description",
                                    values.description,
                                );
                                formData.append(
                                    "processTemplateId",
                                    params.processTemplateId,
                                );

                                createProcessReportTemplate(formData)
                                    .unwrap()
                                    .then((payload) => {
                                        console.log("PayLoad" + payload.id);
                                        createSuccessful(payload);
                                    })
                                    .catch((error) => {
                                        console.log(
                                            "Request failed:",
                                            error.message,
                                        );
                                        setSubmitting(false);
                                    });
                                setSubmitting(false);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                getFieldProps,
                            }) => (
                                <Formik.Form>
                                    <FormGroup>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            as="input"
                                            name="name"
                                            {...getFieldProps("name")}
                                            value={values.name}
                                            className={
                                                touched.name && errors.name
                                                    ? "is-invalid"
                                                    : "form-control"
                                            }
                                        />
                                        {errors.name ? (
                                            <div className="invalid-feedback">
                                                {errors.name}
                                            </div>
                                        ) : null}
                                    </FormGroup>
                                    <Form.Group controlId="formDescription">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            name="description"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </Form.Group>
                                    <FormGroup>
                                        <Form.File
                                            id="formcheck-api-custom"
                                            lang="en"
                                            custom
                                            className="bottom10 "
                                        >
                                            <Form.File.Label>
                                                Template file
                                            </Form.File.Label>
                                            <Form.File.Input
                                                type="file"
                                                name="fileTemplate"
                                                id="fileTemplate"
                                                onChange={(event) => {
                                                    setFieldValue(
                                                        "fileTemplate",
                                                        event.target.files[0],
                                                    );
                                                }}
                                                className={
                                                    touched.fileTemplate &&
                                                    errors.fileTemplate
                                                        ? "is-invalid"
                                                        : "form-control"
                                                }
                                                onBlur={handleBlur}
                                            />
                                            <Formik.ErrorMessage
                                                name="fileTemplate"
                                                component="div"
                                                className="invalid-feedback display"
                                            />
                                        </Form.File>
                                    </FormGroup>

                                    <div className="top10">
                                        <Button variant="primary" type="submit">
                                            Create
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            className="ml-1"
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </Formik.Form>
                            )}
                        </Formik.Formik>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};
export default ReportTemplateCreatePage;
