import { noop } from "lodash";
import * as React from "react";

interface NameChangeContextProviderProps {
    submitNameChange: () => void;
    cancelNameChange: () => void;
    children: React.ReactNode;
}

interface NameChangeActions {
    cancelNameChange: () => void;
    submitNameChange: () => void;
}

export const NameChangeContext = React.createContext<NameChangeActions>({
    cancelNameChange: noop,
    submitNameChange: noop,
});

const NameChangeContextProvider: React.FC<NameChangeContextProviderProps> = ({
    submitNameChange,
    cancelNameChange,
    children,
}) => {
    return (
        <NameChangeContext.Provider
            value={{ submitNameChange, cancelNameChange }}
        >
            {children}
        </NameChangeContext.Provider>
    );
};

export default NameChangeContextProvider;
