import { GridCellProps } from "@progress/kendo-react-grid";
import React from "react";
import { FaDownload } from "react-icons/fa";
import useConsumerApiSettings from "../../../../customization/api/useConsumerApiSettings";
import { downloadWorkbank } from "../services/downloadWorkbank";

interface DownloadCellProps {
    cellProps: GridCellProps;
    organisationId: string;
}
const DownloadCell: React.FC<DownloadCellProps> = ({
    cellProps,
    organisationId,
}) => {
    const { baseApiUrl } = useConsumerApiSettings();

    return (
        <td className="k-command-cell">
            <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base k-grid-save-command"
                onClick={(e) =>
                    downloadWorkbank({
                        url: `${baseApiUrl}/organisation/${organisationId}/workbank/${cellProps.dataItem.id}`,
                    })
                }
            >
                <FaDownload />
            </button>
        </td>
    );
};
export default DownloadCell;
