import { Grid, GridColumn } from "@progress/kendo-react-grid";
import React from "react";
import { BatchSummary } from "../../domain/batching";
import { useGetBatchExamsSummaryQuery } from "../../queries/BatchingApiSlice";

type Props = {
    organisationId: string;
};

function mapToEntries(kpis: string[], summary: Record<string, BatchSummary>) {
    const getRowValues = (kpi: string) => {
        const values = {};
        Object.entries(summary).forEach((entry) => {
            if (kpi === "Exams not batched") {
                values[`${[entry[0]]}`] = entry[1].examsNotBatchedCount;
            } else if (kpi === "Exams missing NR Exam Id") {
                values[`${[entry[0]]}`] =
                    entry[1].examsWithMissingNrExamIdCount;
            }
        });

        return values;
    };

    return kpis.map((kpi) => {
        return {
            kpi,
            ...getRowValues(kpi),
        };
    });
}

const BatchExamsSummary: React.FC<Props> = ({ organisationId }: Props) => {
    const {
        data: summary,
        isLoading,
        isError,
    } = useGetBatchExamsSummaryQuery(organisationId, {
        skip: !organisationId,
        refetchOnMountOrArgChange: true,
    });

    if (isLoading || isError) {
        return null;
    }

    if (Object.keys(summary).length === 0) {
        return <h2>There are no exams awaiting batching</h2>;
    }

    const kpis = ["Exams not batched", "Exams missing NR Exam Id"];
    const entries = mapToEntries(kpis, summary);

    return (
        <>
            <Grid data={entries} scrollable="none">
                <GridColumn field="kpi" title="KPI" />
                {Object.keys(summary).map((field, index) => (
                    <GridColumn key={index} field={field} title={field} />
                ))}
            </Grid>
        </>
    );
};

export default BatchExamsSummary;
