import * as React from "react";
import { Tab, Tabs } from "react-bootstrap";
import QueryResultStatus from "../../../common/components/QueryResultStatus";
import AuditActionsList from "../../../common/components/audit/AuditActionsList";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";
import { useGetProcessByIdQuery } from "../../../store/features/process/process-api-slice";
import AssetLinkTab from "../../process-activity/components/AssetLinkTab";
import ProcessActivityWrapper from "../../process-activity/components/ProcessActivityWrapper";
import { resolveActivityState } from "../../process-activity/helpers/activityStateHelpers";
import { formatActivityPageTitle } from "../../process-activity/pages/pageTitles";
import {
    useCompleteMutation,
    useGetByIdQuery,
    useNotApplicableMutation,
    useOpenMutation,
} from "../store/ProcessActivityArtefactGenerationApiSlice";
import ProcessActivityArtefactGenerationActions from "./ProcessActivityArtefactGenerationActions";
import ProcessActivityArtefactGenerationList from "./ProcessActivityArtefactGenerationList";
import { Organisation } from "../../organisation/domain/types";
interface Props {
    processId: string;
    activityId: string;
    organisation: Organisation;
}

const ProcessActivityArtefactGenerationWrapper: React.FC<Props> = ({
    processId,
    activityId,
    organisation,
}) => {
    const { data: process, ...processResult } = useGetProcessByIdQuery(
        processId,
        { skip: !processId },
    );

    const { data: activity, ...activityResult } = useGetByIdQuery(
        { activityId, processId },
        {
            skip: !activityId,
        },
    );

    const [complete, completeResult] = useCompleteMutation();
    const [setNotApplicable, notApplicableResult] = useNotApplicableMutation();
    const [open, openResult] = useOpenMutation();

    useOrgTitleWithShortName(
        formatActivityPageTitle(
            process?.name,
            activity?.displayName,
            !process || !activity,
        ),
    );

    if (!process) return <QueryResultStatus queryResult={processResult} />;
    if (!activity) return <QueryResultStatus queryResult={activityResult} />;

    const assetLinkEnabled = activity?.assetLink?.enabled;
    const { canEdit } = resolveActivityState(activity);

    return (
        <ProcessActivityWrapper
            processQuery={[process, processResult]}
            activityQuery={[activity, activityResult]}
            activityActions={{
                complete: {
                    actionCallback: complete,
                    actionResult: completeResult,
                },
                notApplicable: {
                    actionCallback: setNotApplicable,
                    actionResult: notApplicableResult,
                },
                open: {
                    actionCallback: open,
                    actionResult: openResult,
                },
            }}
            organisation={organisation}
        >
            <Tabs defaultActiveKey="content" id="tab">
                <Tab eventKey="content" title="Content">
                    {activity && (
                        <>
                            {canEdit && (
                                <ProcessActivityArtefactGenerationActions
                                    id={activity.id}
                                />
                            )}
                            <div className="mt-3">
                                <h3>Requests</h3>
                                <ProcessActivityArtefactGenerationList
                                    activityId={activity.id}
                                    items={activity.requests || []}
                                />
                            </div>
                        </>
                    )}
                </Tab>
                {assetLinkEnabled && (
                    <Tab eventKey="asset" title="Assets">
                        <AssetLinkTab
                            activity={activity}
                            organisation={organisation}
                        />
                    </Tab>
                )}
                <Tab eventKey="history" title="History">
                    <AuditActionsList
                        auditActions={activity?.auditActions || []}
                    />
                </Tab>
            </Tabs>
        </ProcessActivityWrapper>
    );
};

export default ProcessActivityArtefactGenerationWrapper;
