import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import * as React from "react";
import { Button } from "react-bootstrap";
import { IdAndName } from "../../../common/types/IdAndName";
import { FileAndFolderIds } from "../../../store/features/folder/moveFilesAndFoldersSlice";
import { getPathAsString, ListedFolder } from "../domain/folder";
import "./select-folder-dialog.css";

interface Props {
    sourceFolder: ListedFolder;
    targetFolder: ListedFolder;
    toMove: FileAndFolderIds;
    onBack: () => void;
    onConfirm: () => void;
}

const ItemsElement: React.FC<{ items: IdAndName[] }> = ({ items }) => {
    if (items.length === 0) return null;

    return (
        <>
            {items.map((item: IdAndName) => (
                <div key={item.id}>{`- ${item.name}`}</div>
            ))}
        </>
    );
};

export function filesAndFoldersElemets({
    files,
    folders,
}: FileAndFolderIds): JSX.Element {
    const foldersElement =
        folders.length > 0
            ? {
                  name: "folders",
                  element: <ItemsElement items={folders} />,
              }
            : null;

    const filesElement =
        files.length > 0
            ? {
                  name: "files",
                  element: <ItemsElement items={files} />,
              }
            : null;

    const all = [foldersElement, filesElement].filter((x) => x);

    return (
        <>
            {all.map(({ name, element }, index: number) => {
                return index === 0 ? (
                    <React.Fragment key={index}>
                        {name}:{element}
                    </React.Fragment>
                ) : (
                    <div key={index}>
                        and {name}: {element}
                    </div>
                );
            })}
        </>
    );
}

export const MoveConfirmationDialog: React.FC<Props> = ({
    sourceFolder,
    targetFolder,
    toMove,
    onBack,
    onConfirm,
}) => {
    if (!sourceFolder || !targetFolder) return null;

    return (
        <>
            <div className="select-folder-dialog__confirmation">
                <div className="mb-2">
                    Are you sure want to move {filesAndFoldersElemets(toMove)}
                </div>
                <div>from:</div>
                <div>
                    {"- "} {getPathAsString(sourceFolder)}
                </div>
                <div>to:</div>
                <div>
                    {"- "} {getPathAsString(targetFolder)}
                </div>
            </div>
            <DialogActionsBar layout="end">
                <Button variant="link" onClick={onBack}>
                    Back
                </Button>
                <Button variant="primary" onClick={onConfirm}>
                    Confirm
                </Button>
            </DialogActionsBar>
        </>
    );
};
