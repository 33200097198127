import * as React from "react";
import { Notification } from "../domain/types";
import { useAppSelector } from "../../../store/hooks";
import { NotificationGroup } from "@progress/kendo-react-notification";
import NotificationToast from "./NotificationToast";

const NotificationToastsWrapper: React.FC = () => {
    const notifications = useAppSelector((state) => state.notifications);

    return (
        <div className="toast-wrapper">
            <NotificationGroup
                style={{
                    right: 8,
                    bottom: 0,
                    alignItems: "flex-start",
                    flexWrap: "wrap-reverse",
                }}
            >
                {notifications.map((item: Notification) => (
                    <NotificationToast key={item.content} item={item} />
                ))}
            </NotificationGroup>
        </div>
    );
};

export default NotificationToastsWrapper;
