import React from "react";
import { Route, RouteProps } from "react-router-dom";

import Unauthorised from "../common/components/Unauthorised";
import {
    useGetCurrentUserRolesQuery,
    useGetCurrentUserVerifiedQuery,
} from "../store/features/user/user-api-slice";
import QueryResultStatus from "../common/components/QueryResultStatus";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useIsAuthenticated,
} from "@azure/msal-react";
import NavMenu from "../common/components/navigation-top/NavMenu";

interface AuthorizeRouteProps extends RouteProps {
    requiredRoles?: string[];
    // TODO: @<shailesh.deosarkar@amey.co.uk> It is not a good idea to hack your components this way.
    //       Main reason: you loose type checking this way. There is a better solution: a function that
    //       takes some parameters and returns a Component function.
    additionalComponentProps?;
}

const AuthorizeRoute: React.FC<AuthorizeRouteProps> = (props) => {
    const isAuthenticated = useIsAuthenticated();
    const { isSuccess } = useGetCurrentUserVerifiedQuery(null, {
        skip: !isAuthenticated,
    });
    const { data: userRoles, ...userResult } = useGetCurrentUserRolesQuery(
        null,
        { skip: !isSuccess },
    );

    let authorised = false;
    if (props.requiredRoles) {
        //Check here that user has all required system roles to view content
        authorised = props.requiredRoles.every((a) =>
            (userRoles ?? []).includes(a),
        );
    } else {
        authorised = true;
    }

    const { component: Component, additionalComponentProps, ...rest } = props;

    return (
        <>
            <AuthenticatedTemplate>
                <Route
                    {...rest}
                    render={(renderProps) => {
                        return (
                            <>
                                {userResult.isLoading || userResult.isError ? (
                                    <QueryResultStatus
                                        queryResult={userResult}
                                    />
                                ) : (
                                    ""
                                )}

                                {authorised && userResult.isSuccess ? (
                                    <Component
                                        {...renderProps}
                                        {...additionalComponentProps}
                                    />
                                ) : (
                                    ""
                                )}
                                {authorised ? "" : <Unauthorised />}
                            </>
                        );
                    }}
                />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <NavMenu />
                <p className="text-center">
                    Please sign-in in order to view the application.
                </p>
            </UnauthenticatedTemplate>
        </>
    );
};

export default AuthorizeRoute;
