interface TextAreaConfigSupportedOptions {
    Rows: string;
}

class TextTemplateConfigRowsBuilder {
    static SupportedOptions: TextAreaConfigSupportedOptions = {
        Rows: "rows",
    };
}

export default TextTemplateConfigRowsBuilder;
