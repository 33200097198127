import React, { useCallback } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { RequestedFileDto } from "./types";
import userRequestedFileQueryKeys from "./userRequestedFileQueryKeys";
import { getUserRequestedFileStatus } from "./userRequestedFileService";

export const useGetUserRequestedFileStatus = (
    organisationId: string,
    fileId: string,
): UseQueryResult<RequestedFileDto> => {
    const query = useCallback(() => {
        return getUserRequestedFileStatus({ organisationId, fileId });
    }, [organisationId, fileId]);

    const keys = React.useMemo(
        () => userRequestedFileQueryKeys.userRequestedFileKey(fileId),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fileId],
    );

    return useQuery(keys, query, {
        enabled: (!organisationId || !fileId) === false,
    });
};
