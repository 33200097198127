import { TemplateTypes } from "../../../common/types/TemplateTypes";

enum TemplateTitles {
    CreatePrefix = "Create",
    Sufix = "Template",
}

const formatTemplateCreateTitle = (templateType: TemplateTypes): string => {
    if (templateType === TemplateTypes.Form)
        return `${TemplateTitles.CreatePrefix} Form ${TemplateTitles.Sufix}`;

    return `${TemplateTitles.CreatePrefix} Asset ${TemplateTitles.Sufix}`;
};

const formatTemplateViewTitle = (templateType: TemplateTypes): string => {
    if (templateType === TemplateTypes.Form)
        return `Form ${TemplateTitles.Sufix}`;

    return `Asset ${TemplateTitles.Sufix}`;
};

export { formatTemplateCreateTitle, formatTemplateViewTitle };
