import * as React from "react";
import { Alert, Button, Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTitle } from "../../../common/hooks/useTitle";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { OrganisationListItem } from "../domain/organisation";
import OrganisationPageTitles from "./pageTitles";
import { useIsCurrentUserAdmin } from "../../../store/features/user/user-api-slice";
import { OrganisationRowItem } from "../components/OrganisationRowItem";
import { useGetOrganisationsQuery } from "../../../store/features/organisations/organisations-api-slice";
import CoveringLoadingPanel from "../../../common/components/CoveringLoadingPanel";
import SbimConstants from "../../sbim/constants/SbimConstants";
import useIsClient from "../../../common/hooks/useIsClient";
import "./OrganisationsPage.scss";

const OrganisationsPage: React.FC = () => {
    const { isAdmin } = useIsCurrentUserAdmin();
    const canCreate = isAdmin;
    const { data: records, isLoading, isFetching } = useGetOrganisationsQuery();

    const isSBIM = useIsClient(SbimConstants.ClientName);
    useTitle(OrganisationPageTitles.List);

    const renderControls = (): JSX.Element => {
        return (
            <Row>
                <Col>
                    <h1 id="tableLabel">Organisations</h1>
                </Col>
                <Col>
                    {canCreate && (
                        <Button
                            id="createOrganisationButton"
                            as={Link}
                            className="float-right"
                            variant="primary"
                            to={OrganisationConstants.RoutePathCreate}
                        >
                            Create
                        </Button>
                    )}
                </Col>
            </Row>
        );
    };

    const renderOrganisationsTable = (): JSX.Element => {
        const filteredRecords =
            records?.filter((item) => item.canView || item.canManage) || [];
        if (filteredRecords?.length > 0) {
            return (
                <Row className="top5">
                    <Col>
                        <Table id="OrganisationTable">
                            {isSBIM ? (
                                <colgroup>
                                    <col span={2} width={35} />
                                    <col span={1} width={35} />
                                    <col span={1} width={25} />
                                </colgroup>
                            ) : (
                                <></>
                            )}
                            <thead>
                                <tr>
                                    <th colSpan={2}>Name</th>
                                    <th>Description</th>
                                    <th>Owner</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredRecords?.map(
                                    (organisation: OrganisationListItem) => (
                                        <OrganisationRowItem
                                            key={organisation.id}
                                            organisation={organisation}
                                        />
                                    ),
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            );
        } else {
            return (
                <Alert variant="info">
                    <Alert.Heading>
                        You are not currently a member of any organisations
                    </Alert.Heading>
                    <p>{`You will need to be invited  to one before you can continue to interact with the system.`}</p>
                </Alert>
            );
        }
    };

    if (isLoading || isFetching) {
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <h1 id="tableLabel">Organisations</h1>
                    </Col>
                </Row>
                <CoveringLoadingPanel />
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                {renderControls()}
                {renderOrganisationsTable()}
            </React.Fragment>
        );
    }
};
export default OrganisationsPage;
