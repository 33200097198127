import { QueryKey } from "react-query";

export const ApprovedExamsKey = "approved-exams";

const approvedExamsQueryKeys = {
    approvedExams: (orgId: string): QueryKey => [
        ApprovedExamsKey,
        `${ApprovedExamsKey}-${orgId}`,
    ],
};

export default approvedExamsQueryKeys;
