import * as React from "react";
import { Grid } from "@progress/kendo-react-grid";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import { Approval, ProcessActivityApproval } from "../domain/types";
import {
    ApprovalTableItem,
    mapToApprovalTableItems,
} from "../viewModel/ApprovalTableItem";
import { ApprovalActionsCell } from "./ApprovalActionsCell";
import { TypedGridCellProps } from "../../../common/types/TypedGridCellProps";
import {
    useActivityRequestAprovalsMutation,
    useAddApprovalMutation,
    useRemoveByApprovalIdMutation,
    useResendByApprovalIdMutation,
} from "../store/processActivityApprovalApiSlice";
import { MultiMutationResultStatus } from "../../../common/components/MutationResultStatus";
import { UsernameCell } from "./ApprovalUsernameCell";
import { useActivityApprovalApprovers } from "../hooks/useActivityApprovalApprovers";
import { Button, ButtonToolbar } from "react-bootstrap";
import ButtonSpinner from "../../../common/components/button/ButtonSpinner";
import useGridProps from "../../../common/components/table/kendo/useGridProps";

interface Props {
    organisationId: string;
    activity: ProcessActivityApproval;
    approvals: Approval[];
    isReadOnly: boolean;
}

const ApprovalList: React.FC<Props> = ({
    organisationId,
    activity,
    approvals,
    isReadOnly,
}) => {
    const [isAdding, setIsAdding] = React.useState(false);

    const [remove, removeResult] = useRemoveByApprovalIdMutation();
    const [resend, resendResult] = useResendByApprovalIdMutation();
    const [addApprover, addApproverResult] = useAddApprovalMutation();
    const [sendRequests, sendRequestsResult] =
        useActivityRequestAprovalsMutation();

    const { approvers } = useActivityApprovalApprovers(
        organisationId,
        activity,
    );

    const { virtualItems, approvalItems } = React.useMemo(
        () => mapToApprovalTableItems(approvals, isAdding),
        [approvals, isAdding],
    );

    const { gridProps, gridData } = useGridProps(approvalItems, {
        filterable: false,
        sortable: true,
        pageable: false,
    });

    const withVirtualRows = virtualItems.concat(
        gridData as ApprovalTableItem[],
    );

    const GridApprovalActionsCell = (
        props: TypedGridCellProps<ApprovalTableItem>,
    ) => (
        <ApprovalActionsCell
            {...props}
            isReadOnly={isReadOnly}
            isApproversReadOnly={activity.approversReadonly}
            onRequest={(dataItem) => {
                resend({
                    activityId: activity.id,
                    approvalId: dataItem.id,
                });
            }}
            onRemove={(dataItem) => {
                remove({
                    activityId: activity.id,
                    approvalId: dataItem.id,
                });
            }}
        />
    );

    const GridUsernameCell = (props: TypedGridCellProps<ApprovalTableItem>) => (
        <UsernameCell
            {...props}
            users={approvers}
            onSelect={(selectedUser) => {
                if (!isAdding) return;

                setIsAdding(false);
                addApprover({
                    activityId: activity.id,
                    user: selectedUser,
                });
            }}
        />
    );

    return (
        <>
            {!isReadOnly && (
                <div className="pt-2">
                    <ButtonToolbar>
                        {!activity.approversReadonly && (
                            <Button
                                variant="outline-secondary"
                                className="mr-1"
                                onClick={() => {
                                    setIsAdding(!isAdding);
                                }}
                            >
                                {isAdding
                                    ? "Cancel adding user"
                                    : activity.addActionDisplayName ??
                                      "Add user"}{" "}
                                {addApproverResult.isLoading && (
                                    <ButtonSpinner />
                                )}
                            </Button>
                        )}
                        <Button
                            variant="outline-secondary"
                            onClick={() => {
                                sendRequests({
                                    activityId: activity.id,
                                });
                            }}
                        >
                            Request All{" "}
                            {sendRequestsResult.isLoading && <ButtonSpinner />}
                        </Button>
                    </ButtonToolbar>
                </div>
            )}

            <div className="pt-2">
                <Grid {...gridProps} data={withVirtualRows}>
                    <Column field="name" title="Name" cell={GridUsernameCell} />
                    <Column field="status" title="Status" />
                    <Column field="updatedAt" title="Updated At" />
                    <Column field="comment" title="Comment" />
                    <Column cell={GridApprovalActionsCell} />
                </Grid>
                <MultiMutationResultStatus
                    showLoading
                    results={[
                        removeResult,
                        resendResult,
                        addApproverResult,
                        sendRequestsResult,
                    ]}
                />
            </div>
        </>
    );
};
export default ApprovalList;
