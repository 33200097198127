import cx from "classnames";
import * as React from "react";
import { Button, ButtonToolbar, Spinner } from "react-bootstrap";
import {
    TemplateContentFilter,
    TemplateContentFilterOperator,
    TemplateContentFilterProperty,
} from "../../../../common/filters/domain/types";
import { nonInstructedProcessNames } from "../../../reactive-exam-creator/pages/ReactiveExamCreatorPage";
import { ExaminationPlanning } from "../../domain/examinationsPlanning";
import {
    useAssignExaminationsMutation,
    useOpenExaminationsMutation,
    useScheduleExaminationsMutation,
} from "../../query/examinationsPlanningQueries";
import AssignModal from "../AssignModal";
import CancelModal from "../CancelModal";
import ScheduleModal from "../ScheduleModal";
import "./examinationSchedulesGrid.scss";
import { useExaminationSchedulerPermissions } from "./useExaminationSchedulerPermissions";

const usersFilter: TemplateContentFilter = {
    property: TemplateContentFilterProperty.UserGroup,
    operator: TemplateContentFilterOperator.Any,
    values: ["Examiner"],
};

interface Props {
    organisationId: string;
    selectedItems: ExaminationPlanning[];
}

const ExaminationSchedulesGridActions: React.FC<Props> = ({
    organisationId,
    selectedItems,
}) => {
    const [scheduleActive, setScheduleActive] = React.useState<boolean>(false);
    const [assignActive, setAssignActive] = React.useState<boolean>(false);
    const [cancelActive, setCancelActive] = React.useState<boolean>(false);

    const { canPlanExamination, canCancelExaminations } =
        useExaminationSchedulerPermissions(organisationId);

    const assignMutation = useAssignExaminationsMutation(organisationId);
    const completeMutation = useScheduleExaminationsMutation(organisationId);
    const openMutation = useOpenExaminationsMutation(organisationId);

    const nonInstructedProcessTemplateNames = React.useMemo(
        () => nonInstructedProcessNames.map((x) => x.value),
        [],
    );

    const exceptNonInstructedSelectedItemsIds = React.useMemo(
        () =>
            selectedItems
                .filter(
                    (x) =>
                        !nonInstructedProcessTemplateNames.includes(
                            x.processTemplateName,
                        ),
                )
                .map((i) => i.id),
        [selectedItems, nonInstructedProcessTemplateNames],
    );

    const exceptNonInstructedSelectedItems = React.useMemo(
        () =>
            selectedItems.filter(
                (x) =>
                    exceptNonInstructedSelectedItemsIds.includes(x.id) &&
                    x.id != null &&
                    x.id !== "",
            ),
        [selectedItems, exceptNonInstructedSelectedItemsIds],
    );

    const isAllNonInstructedProcessNames = React.useMemo(
        () =>
            selectedItems
                .map((a) => a.processTemplateName)
                .every((a) => nonInstructedProcessTemplateNames.includes(a)),
        [selectedItems, nonInstructedProcessTemplateNames],
    );

    return (
        <div>
            {scheduleActive && (
                <ScheduleModal
                    examinations={exceptNonInstructedSelectedItems}
                    organisationId={organisationId}
                    onClose={() => setScheduleActive(false)}
                />
            )}
            {assignActive && (
                <AssignModal
                    title={"Assign Examiner"}
                    examinations={selectedItems}
                    organisationId={organisationId}
                    usersFilter={usersFilter}
                    assignMutation={assignMutation}
                    onClose={() => setAssignActive(false)}
                />
            )}
            {cancelActive && (
                <CancelModal
                    examinations={selectedItems}
                    organisationId={organisationId}
                    onClose={() => setCancelActive(false)}
                    isAllNonInstructedProcessNames={
                        isAllNonInstructedProcessNames
                    }
                />
            )}

            <ButtonToolbar
                className={cx({
                    invisible: selectedItems.length === 0,
                })}
            >
                {canPlanExamination && (
                    <>
                        <Button
                            className="mr-2"
                            variant="outline-secondary"
                            onClick={() => {
                                setScheduleActive(true);
                            }}
                            disabled={isAllNonInstructedProcessNames}
                        >
                            Set Planned Date
                        </Button>
                        <Button
                            className="mr-2"
                            variant="outline-secondary"
                            onClick={() => {
                                setAssignActive(true);
                            }}
                        >
                            Set Examiner
                        </Button>
                        <Button
                            className="mr-2"
                            variant="outline-secondary"
                            onClick={() => {
                                completeMutation.mutate({
                                    processActivityIds:
                                        exceptNonInstructedSelectedItemsIds,
                                });
                            }}
                            disabled={
                                completeMutation.isLoading ||
                                isAllNonInstructedProcessNames
                            }
                        >
                            Schedule Exam
                            {completeMutation.isLoading && (
                                <>
                                    {" "}
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </>
                            )}
                        </Button>
                        <Button
                            className="mr-2"
                            variant="outline-secondary"
                            onClick={() => {
                                openMutation.mutate({
                                    processActivityIds:
                                        exceptNonInstructedSelectedItemsIds,
                                });
                            }}
                            disabled={
                                openMutation.isLoading ||
                                isAllNonInstructedProcessNames
                            }
                        >
                            Reset Exam
                            {openMutation.isLoading && (
                                <>
                                    {" "}
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </>
                            )}
                        </Button>
                    </>
                )}
                {canCancelExaminations && (
                    <Button
                        variant="outline-secondary"
                        onClick={() => {
                            setCancelActive(true);
                        }}
                    >
                        Cancel Examinations
                    </Button>
                )}
            </ButtonToolbar>
        </div>
    );
};

export default ExaminationSchedulesGridActions;
