import React, { useCallback } from "react";
import {
    useInfiniteQuery,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationResult,
    useQueryClient,
} from "react-query";
import { useDebounce } from "use-debounce";
import { DataContinuationResult } from "../../../../../common/types/DataResult";
import { InfiniteQueryOptions } from "../../../../../common/types/SortAndFilterOptions";
import { useInvalidateTags } from "../../../../../store/hooks";
import { examinationsKey } from "../../../examinations/query/examinationQueryKeys";
import { MissingCarrsExam } from "../domain/missingCarrsExam";
import {
    getInfiniteMissingCarrsExam,
    uploadMissingCarrsExam,
} from "../services/missingCarrsExamService";
import missingCarrsExamsQueryKeys from "./missingCarrsExamQueryKeys";
import { missingCarrsExamQueryKeysTranslator } from "./missingCarrsExamQueryKeysTranslator";

const { queryKeysToTags } = missingCarrsExamQueryKeysTranslator;

export const useGetInfiniteMissingCarrsExamQuery = (
    organisationId: string,
    query: InfiniteQueryOptions,
): UseInfiniteQueryResult<DataContinuationResult<MissingCarrsExam>> => {
    const [debouncedQuery] = useDebounce(query, 1000);
    const getExaminations = useCallback(
        ({
            pageParam = null,
        }): Promise<DataContinuationResult<MissingCarrsExam>> => {
            const continuationToken = pageParam;

            return getInfiniteMissingCarrsExam(organisationId, {
                ...debouncedQuery,
                continuationToken,
            });
        },
        [debouncedQuery, organisationId],
    );

    const queryKeys = React.useMemo(
        () =>
            missingCarrsExamsQueryKeys.filteredMissingCarrsExams(
                organisationId,
                debouncedQuery,
            ),
        [organisationId, debouncedQuery],
    );

    return useInfiniteQuery<
        DataContinuationResult<MissingCarrsExam>,
        [string, { page: number }]
    >(queryKeys, getExaminations, {
        getNextPageParam: (lastResult) => {
            // eslint-disable-next-line no-undefined
            return lastResult.continuationToken || undefined;
        },
    });
};

export const useUploadMissingCarrsExamMutation = (
    organisationId: string,
): UseMutationResult<void, unknown, File> => {
    const queryClient = useQueryClient();
    const { invalidateTags } = useInvalidateTags();

    const uploadMissingCarrsExamFunction = (file: File) =>
        uploadMissingCarrsExam({ organisationId, file });

    const queryKeys = React.useMemo(
        () => missingCarrsExamsQueryKeys.missingCarrsExams(organisationId),
        [organisationId],
    );

    return useMutation(uploadMissingCarrsExamFunction, {
        onSuccess(_result) {
            invalidateTags(queryKeysToTags(queryKeys));

            queryClient.invalidateQueries(examinationsKey);
            return queryClient.invalidateQueries(queryKeys);
        },
    });
};
