export enum ExaminationState {
    Planned = "Planned",
    Unallocated = "Unallocated",
    ExaminationInProgress = "Examination in Progress",
    AwaitingEngineer = "Awaiting Engineer",
    AtEngineer = "At Engineer",
    ReturnedToExaminer = "Returned To Examiner",
    AwaitingBatching = "Awaiting Batching",
    RejectedByNetworkRail = "Rejected By Network Rail",
    Delivered = "Delivered",
    Submitting = "NR Submitting",
    Submitted = "NR Submitted",
}

export const ExaminationStates: string[] = [
    ExaminationState.Planned,
    ExaminationState.Unallocated,
    ExaminationState.ExaminationInProgress,
    ExaminationState.AwaitingEngineer,
    ExaminationState.AtEngineer,
    ExaminationState.ReturnedToExaminer,
    ExaminationState.AwaitingBatching,
    ExaminationState.RejectedByNetworkRail,
    ExaminationState.Delivered,
    ExaminationState.Submitting,
    ExaminationState.Submitted,
];
