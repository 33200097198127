import { Button } from "@progress/kendo-react-buttons";
import {
    ListItemProps,
    MultiSelect,
    MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import React from "react";
import { ExaminationStatusType } from "../../domain/examinationsPlanning";

const multiSelectItemRender = (
    li: React.ReactElement<
        HTMLLIElement,
        string | React.JSXElementConstructor<unknown>
    >,
    itemProps: ListItemProps,
) => {
    const { dataItem, selected } = itemProps;
    const child = (
        <span>
            <input
                type="checkbox"
                name={dataItem}
                checked={selected}
                onChange={(e) => {
                    itemProps.onClick(
                        itemProps.index,
                        e as unknown as React.MouseEvent<
                            HTMLLIElement,
                            MouseEvent
                        >,
                    );
                }}
            />
            &nbsp;{li.props.children}
        </span>
    );

    const liProps = {
        ...li.props,
        className: li.props.className.replace("k-state-focused", ""),
    };

    return React.cloneElement(li, liProps, child);
};

interface Props extends GridFilterCellProps {
    statuses: string[];
    value: string;
}

export const ExaminationStatusFilterCell: React.FC<Props> = ({
    statuses,
    onChange,
    value,
}) => {
    const hanldeChangeStatuses = (event: MultiSelectChangeEvent) => {
        onChange({
            value: (event.value as string[]).join(","),
            operator: "isIn",
            syntheticEvent: event.syntheticEvent,
        });
    };

    const onClear: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        onChange({
            value: "",
            operator: "isIn",
            syntheticEvent: event,
        });
    };

    const selected = React.useMemo(
        () => value.split(",").filter(Boolean),
        [value],
    );

    return (
        <div className="k-filtercell">
            <div className="k-filtercell-wrapper">
                <MultiSelect
                    data={statuses || Object.values(ExaminationStatusType)}
                    onChange={hanldeChangeStatuses}
                    value={selected}
                    itemRender={multiSelectItemRender}
                />
                <Button
                    title="Clear"
                    disabled={!value}
                    onClick={onClear}
                    icon="filter-clear"
                />
            </div>
        </div>
    );
};
