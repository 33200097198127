import { useParams } from "react-router";
import authFetch from "../../helpers/ApiHelper";
import React, { useCallback } from "react";
import { Button, Col, Row } from "react-bootstrap";

const DirectDownloadPage: React.FC = () => {
    const { activityId, fileId } = useParams<{
        activityId: string;
        fileId: string;
    }>();
    const endpoint = `/api/process-activities/${activityId}/ProcessActivityFile/${fileId}`;

    const downloadFile = useCallback(async () => {
        try {
            const response = await authFetch(endpoint, {
                method: "GET",
            }).then((res) =>
                res.ok ? res : Promise.reject(new Error(res.statusText)),
            );

            const fileName = response.headers.get("X-File-Name");
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);

            link.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("File download failed", error);
        }
    }, [endpoint]);

    React.useEffect(() => {
        downloadFile();
    }, [downloadFile]);

    return (
        <React.Fragment>
            <Row className="ml-5 mt-5">
                <Col>
                    <h1>Preparing Your Download...</h1>
                </Col>
            </Row>
            <Row className="ml-5 mt-3">
                <Col>
                    <p>
                        If the download doesn&apos;t start automatically, please
                        click the button below.
                    </p>
                    <Button onClick={downloadFile}>Download</Button>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default DirectDownloadPage;
