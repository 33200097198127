import { noop } from "lodash";
import * as React from "react";

export type GetFileUrlFunc = (fileId: string) => string;

//Hidden fields handling - will be used for future refactoring (to replace redux hidden fields)
interface DynamicFormContextContent {
    hiddenFields: string[];
    addHiddenField: (field: string) => void;
    removeHiddenField: (field: string) => void;
    getFileUrl: GetFileUrlFunc;
}

export const DynamicFormContext =
    React.createContext<DynamicFormContextContent>({
        hiddenFields: [],
        addHiddenField: noop,
        removeHiddenField: noop,
        getFileUrl: () => "",
    });

const DynamicFormContextProvider: React.FC<{
    getFileUrl: GetFileUrlFunc;
}> = ({ getFileUrl, children }) => {
    const [hiddenFields, setHiddenFields] = React.useState<string[]>([]);

    const add = (field: string) => setHiddenFields([...hiddenFields, field]);
    const remove = (field: string) =>
        setHiddenFields(hiddenFields.filter((f) => f !== field));

    return (
        <DynamicFormContext.Provider
            value={{
                getFileUrl,
                hiddenFields,
                addHiddenField: add,
                removeHiddenField: remove,
            }}
        >
            {children}
        </DynamicFormContext.Provider>
    );
};

export default DynamicFormContextProvider;
