import * as React from "react";
import { Button } from "react-bootstrap";
import { Organisation } from "../../../organisation/domain/types";
import cx from "classnames";

interface Props {
    organisation: Organisation;
    onClick: () => void;
}
export const ViewMetaDataButton: React.FC<Props> = (props) => {
    const { organisation, onClick } = props;
    if (!organisation?.features?.files?.collectMetaData) {
        return null;
    }
    return (
        <Button
            variant="outline-secondary"
            className={cx("position-relative", "overflow-hidden", "mt-1")}
        >
            Toggle Metadata
            <input
                type="button"
                className="file-button__input"
                onClick={onClick}
            />
        </Button>
    );
};
