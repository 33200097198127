import * as React from "react";
import { useTitle } from "../../../common/hooks/useTitle";
import OrganisationCreateForm from "../containers/OrganisationCreateForm";
import OrganisationPageTitles from "./pageTitles";

const OrganisationCreatePage: React.FC = () => {
    useTitle(OrganisationPageTitles.Create);
    return (
        <React.Fragment>
            <h1>Create New Organisation</h1>
            <OrganisationCreateForm></OrganisationCreateForm>
        </React.Fragment>
    );
};
export default OrganisationCreatePage;
