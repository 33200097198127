import * as React from "react";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import {
    DatePicker,
    DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";

export const betweenDates = (
    current: Date,
    { minDate, maxDate }: { minDate: Date; maxDate: Date },
) => {
    return (
        (minDate === null || new Date(current) >= new Date(minDate)) &&
        (maxDate === null || new Date(current) <= new Date(maxDate))
    );
};

export const BetweenDatesFilterCell: React.FC<GridFilterCellProps> = (
    props,
) => {
    const hasValue = (minDate: Date, maxDate: Date) =>
        Boolean(minDate || maxDate);

    let minDate = props?.value?.minDate;
    let maxDate = props?.value?.maxDate;

    const onClearButtonClick = (event) => {
        event.preventDefault();
        minDate = null;
        maxDate = null;
        props.onChange({
            value: {
                minDate: null,
                maxDate: null,
            },
            operator: betweenDates,
            syntheticEvent: event,
        });
    };

    const onMinDateChange = React.useCallback(
        (e: DatePickerChangeEvent) => {
            minDate = e.value;
            props.onChange({
                value: {
                    minDate: e.value,
                    maxDate: maxDate,
                },
                operator: betweenDates,
                syntheticEvent: e.syntheticEvent,
            });
        },
        [maxDate, props],
    );

    const onMaxDateChange = React.useCallback(
        (e: DatePickerChangeEvent) => {
            maxDate = e.value;
            props.onChange({
                value: {
                    minDate: minDate,
                    maxDate: e.value,
                },
                operator: betweenDates,
                syntheticEvent: e.syntheticEvent,
            });
        },
        [minDate, props],
    );

    return (
        <div className="k-filtercell">
            <div className="k-filtercell-wrapper">
                <DatePicker
                    value={minDate == null ? null : new Date(minDate)}
                    onChange={onMinDateChange}
                />
                <DatePicker
                    value={maxDate == null ? null : new Date(maxDate)}
                    onChange={onMaxDateChange}
                />
                <div className="k-filtercell-operator">
                    <Button
                        title="Clear"
                        disabled={
                            !hasValue(new Date(minDate), new Date(maxDate))
                        }
                        onClick={onClearButtonClick}
                        icon="filter-clear"
                    />
                </div>
            </div>
        </div>
    );
};
export default BetweenDatesFilterCell;
