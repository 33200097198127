import { FieldArray, Formik, FormikProps, useFormikContext } from "formik";
import * as React from "react";
import { useState } from "react";
import { Button, Form, FormGroup, FormLabel } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { v4 as uuid } from "uuid";
import {
    ShowCondition,
    TemplateContentTableRow,
    TemplateContentStyle,
} from "../../../template/domain/types";
import FormControlBase from "../../../../common/components/dynamic-form/FormControlBase";
import FormControlEmployeesGrid from "./FormControlEmployeesGrid";
import { TemplateContentEmployeesRecord } from "../../domain/types";
import EmployeesConstants from "../consts/EmployeesConstants";
import { useGetUsersByOrgIdQuery } from "../../../../store/features/organisation/organisation-api-slice";
import { useOrganisationId } from "../../../organisation/hooks/useOrganisationId";
import Select, { OptionTypeBase } from "react-select";

interface FormControlEmployeesConfig {
    field: string;
    label: string;
    style: TemplateContentStyle;
    prompt: string;
    uri: string;
    rows: TemplateContentTableRow[];
    isReadOnly: boolean;
    multiple: boolean;
    showConditions: ShowCondition[];
}

interface Props {
    config: FormControlEmployeesConfig;
}

interface EmployeesInfo {
    employee: string;
    role: string;
    competence: string;
    training: string;
    experience: string;
    contact: string;
    medical: string;
}

interface EmployeeOption {
    label: string;
    value: string;
}

const FormControlEmployees: React.FC<Props> = (props) => {
    const { config } = props;
    const formik = useFormikContext();
    const { organisationId, isLoadingOrgId } = useOrganisationId();
    const { data: users } = useGetUsersByOrgIdQuery(organisationId, {
        skip: isLoadingOrgId,
    });

    const [isAdding, setIsAdding] = useState(false);

    const onClickAdd = () => {
        setIsAdding(true);
    };

    const setValueOnChange = (
        formikProps: FormikProps<EmployeesInfo>,
        currentValue: EmployeeOption,
    ) => {
        if (!currentValue) {
            return "";
        }
        formikProps.setFieldValue(
            EmployeesConstants.EmployeeField,
            currentValue.label,
        );
    };

    const empValue = (formikProps: FormikProps<EmployeesInfo>) => {
        const currentValue = formikProps.values.employee as string;

        if (!currentValue) {
            return "";
        }

        return options.find((item) => item.label === currentValue) || "";
    };

    const value = (formik.values[config.field] ||
        []) as TemplateContentTableRow[];

    const options = users?.map((i) => ({
        label: `${i.userDisplayName} (${i.username})`,
        value: i.userId,
    }));

    const showAddButton =
        !isAdding &&
        !config.isReadOnly &&
        (config.multiple || value.length < 1);

    return (
        <FormControlBase
            contentConfig={{
                field: config.field,
                showConditions: config.showConditions,
            }}
        >
            <FieldArray name={config.field}>
                {(arrayHelpers) => (
                    <Form.Group key={config.field}>
                        <FormLabel>
                            <strong>{config.label}</strong>
                        </FormLabel>
                        {config.prompt && (
                            <Form.Text className="text-muted mb-2">
                                {config.prompt}
                            </Form.Text>
                        )}
                        {showAddButton && (
                            <div>
                                <Button size="sm" onClick={onClickAdd}>
                                    <FaPlus />
                                </Button>
                            </div>
                        )}
                        {isAdding && (
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    employee: "",
                                    role: "",
                                    competence: "",
                                    training: "",
                                    experience: "",
                                    contact: "",
                                    medical: "",
                                }}
                                onSubmit={(
                                    values: EmployeesInfo,
                                    { resetForm },
                                ): void => {
                                    const employeesInfo: TemplateContentEmployeesRecord =
                                        {
                                            id: uuid(),
                                            employee: values.employee,
                                            role: values.role,
                                            competence: values.competence,
                                            training: values.training,
                                            experience: values.experience,
                                            contact: values.contact,
                                            medical: values.medical,
                                        };
                                    arrayHelpers.push(employeesInfo);
                                    resetForm();
                                    setIsAdding(false);
                                }}
                            >
                                {(formikProps: FormikProps<EmployeesInfo>) => {
                                    return (
                                        <Form noValidate>
                                            <FormGroup>
                                                <Form.Label
                                                    htmlFor={
                                                        EmployeesConstants.EmployeeField
                                                    }
                                                >
                                                    {
                                                        EmployeesConstants.EmployeeLabel
                                                    }
                                                </Form.Label>
                                                <Select
                                                    name={
                                                        EmployeesConstants.EmployeeField
                                                    }
                                                    onChange={(
                                                        currentValue: EmployeeOption,
                                                    ) =>
                                                        setValueOnChange(
                                                            formikProps,
                                                            currentValue,
                                                        )
                                                    }
                                                    options={options || []}
                                                    value={
                                                        empValue(
                                                            formikProps,
                                                        ) as OptionTypeBase
                                                    }
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Label
                                                    htmlFor={
                                                        EmployeesConstants.RoleField
                                                    }
                                                >
                                                    {
                                                        EmployeesConstants.RoleLabel
                                                    }
                                                </Form.Label>
                                                <Form.Control
                                                    name={
                                                        EmployeesConstants.RoleField
                                                    }
                                                    onChange={(
                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                    ) => {
                                                        formikProps.setFieldValue(
                                                            EmployeesConstants.RoleField,
                                                            event.target.value,
                                                        );
                                                    }}
                                                    value={
                                                        formikProps.values.role
                                                    }
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Label
                                                    htmlFor={
                                                        EmployeesConstants.CompetenceField
                                                    }
                                                >
                                                    {
                                                        EmployeesConstants.CompetenceLabel
                                                    }
                                                </Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name={
                                                        EmployeesConstants.CompetenceField
                                                    }
                                                    onChange={(
                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                    ) => {
                                                        formikProps.setFieldValue(
                                                            EmployeesConstants.CompetenceField,
                                                            event.target.value,
                                                        );
                                                    }}
                                                    value={
                                                        formikProps.values
                                                            .competence
                                                    }
                                                >
                                                    <option key="0" value="0">
                                                        Please select...
                                                    </option>
                                                    <option
                                                        key="1"
                                                        value="Amey PHS (Personal Highway Safety eLearning)"
                                                    >
                                                        Amey PHS (Personal
                                                        Highway Safety
                                                        eLearning)
                                                    </option>
                                                    <option
                                                        key="2"
                                                        value="National Highways Common Induction / Passport"
                                                    >
                                                        National Highways Common
                                                        Induction / Passport
                                                    </option>
                                                    <option
                                                        key="3"
                                                        value="Not Applicable. Will be escorted at all times by Competent Senior Amey Employee"
                                                    >
                                                        Not Applicable. Will be
                                                        escorted at all times by
                                                        Competent Senior Amey
                                                        Employee
                                                    </option>
                                                </Form.Control>
                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Label
                                                    htmlFor={
                                                        EmployeesConstants.TrainingField
                                                    }
                                                >
                                                    {
                                                        EmployeesConstants.TrainingLabel
                                                    }
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={3}
                                                    name={
                                                        EmployeesConstants.TrainingField
                                                    }
                                                    onChange={(
                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                    ) => {
                                                        formikProps.setFieldValue(
                                                            EmployeesConstants.TrainingField,
                                                            event.target.value,
                                                        );
                                                    }}
                                                    value={
                                                        formikProps.values
                                                            .training
                                                    }
                                                />
                                                <Form.Text className="text-muted">
                                                    Please list all relevant
                                                    training applicable to the
                                                    Activity(s) to be undertaken
                                                    and refer to the HSEQ
                                                    Training Matrix as
                                                    appropriate. Examples
                                                    include: ISAR 3 or
                                                    equivalent (Working near
                                                    water), PTS (Working near
                                                    Railway), Confined Space
                                                    Training (Confined Space
                                                    working) and NHSS Section 8
                                                    – G39 (Electrical).
                                                </Form.Text>
                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Label
                                                    htmlFor={
                                                        EmployeesConstants.ExperienceField
                                                    }
                                                >
                                                    {
                                                        EmployeesConstants.ExperienceLabel
                                                    }
                                                </Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    name={
                                                        EmployeesConstants.ExperienceField
                                                    }
                                                    onChange={(
                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                    ) => {
                                                        formikProps.setFieldValue(
                                                            EmployeesConstants.ExperienceField,
                                                            event.target.value,
                                                        );
                                                    }}
                                                    value={
                                                        formikProps.values
                                                            .experience
                                                    }
                                                >
                                                    <option key="0" value="0">
                                                        Please select...
                                                    </option>
                                                    <option
                                                        key="1"
                                                        value="Less than 1 Year "
                                                    >
                                                        Less than 1 Year
                                                    </option>
                                                    <option
                                                        key="2"
                                                        value="1 to 3 Years"
                                                    >
                                                        1 to 3 Years
                                                    </option>
                                                    <option
                                                        key="3"
                                                        value="3 to 6 Years"
                                                    >
                                                        3 to 6 Years
                                                    </option>
                                                    <option
                                                        key="4"
                                                        value="6 to 10 Years"
                                                    >
                                                        6 to 10 Years
                                                    </option>
                                                    <option
                                                        key="5"
                                                        value="10+ Years"
                                                    >
                                                        10+ Years
                                                    </option>
                                                </Form.Control>
                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Label
                                                    htmlFor={
                                                        EmployeesConstants.ContactField
                                                    }
                                                >
                                                    {
                                                        EmployeesConstants.ContactLabel
                                                    }
                                                </Form.Label>
                                                <Form.Control
                                                    name={
                                                        EmployeesConstants.ContactField
                                                    }
                                                    onChange={(
                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                    ) => {
                                                        formikProps.setFieldValue(
                                                            EmployeesConstants.ContactField,
                                                            event.target.value,
                                                        );
                                                    }}
                                                    value={
                                                        formikProps.values
                                                            .contact
                                                    }
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Label
                                                    htmlFor={
                                                        EmployeesConstants.MedicalField
                                                    }
                                                >
                                                    {
                                                        EmployeesConstants.MedicalLabel
                                                    }
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={5}
                                                    name={
                                                        EmployeesConstants.MedicalField
                                                    }
                                                    onChange={(
                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                    ) => {
                                                        formikProps.setFieldValue(
                                                            EmployeesConstants.MedicalField,
                                                            event.target.value,
                                                        );
                                                    }}
                                                    value={
                                                        formikProps.values
                                                            .medical
                                                    }
                                                />
                                            </FormGroup>
                                            <div className="mt-2">
                                                <Button
                                                    variant="primary"
                                                    onClick={() => {
                                                        formikProps.submitForm();
                                                    }}
                                                >
                                                    Add
                                                </Button>
                                                <Button
                                                    variant="secondary"
                                                    className="ml-1"
                                                    onClick={() => {
                                                        formikProps.resetForm();
                                                        setIsAdding(false);
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        )}
                        <div className="mt-3">
                            <FormControlEmployeesGrid
                                arrayHelpers={arrayHelpers}
                                config={config}
                                isValid={!formik.errors[config.field]}
                            />
                        </div>

                        {formik.errors[config.field] ? (
                            <div className="invalid-feedback display">
                                {formik.errors[config.field]}
                            </div>
                        ) : null}
                    </Form.Group>
                )}
            </FieldArray>
        </FormControlBase>
    );
};

export default FormControlEmployees;
