import {
    SchedulerSlot,
    SchedulerSlotProps,
} from "@progress/kendo-react-scheduler";
import moment from "moment";
import * as React from "react";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";

interface Props extends SchedulerSlotProps {
    setStart: React.Dispatch<React.SetStateAction<string>>;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomSchedulerSlot: React.FC<Props> = (props) => {
    const { organisationId } = useOrganisationId();

    const canCreateProcesses = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgProcess.create,
    ).hasPermission;

    const handleProcessCreationConfirmationModal = React.useCallback(
        () => props.setShowModal(true),
        [],
    );

    const redirectToProcessCreation = React.useCallback(() => {
        if (canCreateProcesses) {
            props.setStart(
                moment(props.start).add(1, "h").format().split("+")[0],
            );
            handleProcessCreationConfirmationModal();
        }
    }, [canCreateProcesses, handleProcessCreationConfirmationModal, props]);

    return (
        <SchedulerSlot {...props} onDoubleClick={redirectToProcessCreation} />
    );
};

export default CustomSchedulerSlot;
