import React from "react";
import { useParams } from "react-router-dom";
import { useGetBciScoreQuery } from "../../queries/bciApiSlice";
import { useGetOrgByShortNameQuery } from "../../../../store/features/organisation/organisation-api-slice";
import BridgeComponentSpeedometer from "./BridgeComponentSpeedometer";
import CoveringLoadingPanel from "../../../../common/components/CoveringLoadingPanel";

export interface BridgeConditionIndexSpeedComponentProps {
    title: string;
    bciConfigName: string;
}

const BridgeConditionIndexSpeedComponent: React.FC<
    BridgeConditionIndexSpeedComponentProps
> = (props) => {
    const { title, bciConfigName } = props;

    const params = useParams<{ orgShortName: string }>();
    const { data: organisation } = useGetOrgByShortNameQuery(
        params.orgShortName,
        {
            skip: !params.orgShortName,
        },
    );

    const { data: bciCritScore, isLoading: a } = useGetBciScoreQuery({
        organisationId: organisation?.id,
        params: {
            bciConfigName: bciConfigName,
            scoreType: "Critical",
        },
    });
    const { data: bciAvgScore, isLoading: b } = useGetBciScoreQuery({
        organisationId: organisation?.id,
        params: { bciConfigName: bciConfigName, scoreType: "Average" },
    });

    if (a || b) {
        return <CoveringLoadingPanel />;
    }

    return (
        <BridgeComponentSpeedometer
            title={title}
            bciCritScore={bciCritScore ?? 0}
            bciAvgScore={bciAvgScore ?? 0}
        />
    );
};
export default BridgeConditionIndexSpeedComponent;
