import { consumerApi } from "../../../customization/api/consumerRTKQueryApi";
import { BridgeComponentChart } from "../domain/types";

export class BciRoutes {
    static readonly BciApiRoute = "bciscore";
}
export interface BciScoreQueryParams {
    bciConfigName?: string;
    scoreType?: string;
}

export interface BciChartParams {
    chartType?: string;
    scoreType?: string;
    bciConfigName?: string;
}

const apiSubPath = (organisationId: string) =>
    `/organisation/${organisationId}/${BciRoutes.BciApiRoute}`;

export const bciApi = consumerApi.injectEndpoints({
    endpoints: (builder) => ({
        getBciScore: builder.query<
            number,
            {
                organisationId: string;
                params: BciScoreQueryParams;
            }
        >({
            query: ({ organisationId, params }) => {
                const queryString = new URLSearchParams(
                    params as string,
                ).toString();
                return `${apiSubPath(organisationId)}?${queryString}`;
            },
        }),
        getBciChart: builder.query<
            BridgeComponentChart,
            {
                organisationId: string;
                params: BciChartParams;
            }
        >({
            query: ({ organisationId, params }) => {
                const queryString = new URLSearchParams(
                    params as string,
                ).toString();
                return `${apiSubPath(organisationId)}/chart?${queryString}`;
            },
        }),
    }),
});
export const { useGetBciScoreQuery, useGetBciChartQuery } = bciApi;
