import React from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { useGetExamContextBar } from "../query/examContextBarQueries";

interface Props {
    organisationId: string;
    processId: string;
}

export const CefaExamContextBar: React.FC<Props> = ({
    organisationId,
    processId,
}) => {
    const { data, isFetching } = useGetExamContextBar(
        organisationId,
        processId,
    );

    if (isFetching || !data) return null;

    return (
        <>
            <Row style={{ position: "fixed", zIndex: 1000, width: "100%" }}>
                <Col style={{ paddingLeft: 0 }}>
                    <Alert variant={"primary"} className="rounded-0 sticky-top">
                        {data}
                    </Alert>
                </Col>
            </Row>
            <Row style={{ paddingTop: 10, paddingBottom: 40 }}></Row>
        </>
    );
};
