import { useField } from "formik";
import * as React from "react";

interface Props {
    field: string;
}

const FormControlError: React.FC<Props> = (props) => {
    const { field } = props;
    const [, meta] = useField(field);

    const errorMessage = (error: string) => {
        return <div className="invalid-feedback display">{error}</div>;
    };

    if (meta.error) {
        if (Array.isArray(meta.error)) {
            return <>{errorMessage(`Rows have errors`)}</>;
        } else {
            return errorMessage(meta.error);
        }
    } else {
        return null;
    }
};

export default FormControlError;
