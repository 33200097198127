export interface ExaminationsMassRemoval {
    id: string;
    name: string;
    status: ExaminationsMassRemovalStatus;
    createdBy: string;
    createdOn: string;
    errorMessage: string;
    rowErrors: ExaminationsMassRemovalResultError[];
    result: ExaminationsMassRemovalResult;
}

export interface ExaminationsMassRemovalResultError {
    row: number;
    message: string;
}

export interface ExaminationsMassRemovalResult {
    updatedExaminationCount: number;
    skippedExaminationCount: number;
    failedExaminationCount: number;
    totalCount: number;
    completedCount: number;
}

export const enum ExaminationsMassRemovalStatus {
    Pending = "Pending",
    Processing = "Processing",
    Complete = "Complete",
    Error = "Error",
}
