import { FieldAttributes, useField } from "formik";
import React from "react";
import { Form, ListGroup } from "react-bootstrap";
import { ProcessPropertyListValue } from "../../../modules/process/domain/types";

type Props = {
    readOnly?: boolean;
    label?: string;
    className?: string;
    description?: string;
    valuesList?: ProcessPropertyListValue[];
} & FieldAttributes<unknown>;

const ListTextInput: React.FC<Props> = ({
    readOnly,
    label,
    className,
    description,
    placeholder,
    valuesList,
    ...props
}) => {
    const [field] = useField<string>(props);

    return (
        <Form.Group controlId={field.name}>
            {label && <Form.Label>{label}</Form.Label>}
            <ListGroup>
                {valuesList.map((v) => {
                    return (
                        <ListGroup.Item key={v.id}>{v.value}</ListGroup.Item>
                    );
                })}
                {!valuesList.length && (
                    <ListGroup.Item>No entires.</ListGroup.Item>
                )}
            </ListGroup>
        </Form.Group>
    );
};

ListTextInput.defaultProps = {
    readOnly: true,
};

export default ListTextInput;
