import React from "react";
import { Link } from "react-router-dom";
import { ListItemState } from "../domain/types";

export class ListItemStateOptional implements ListItemState {
    button: JSX.Element;
    cardBodyClass: string;
    icon: JSX.Element;

    constructor(activityUrl: string) {
        this.button = (
            <Link to={activityUrl} className="btn btn-primary">
                View
            </Link>
        );

        this.icon = null;
        this.cardBodyClass = "";
    }
}
