import { HttpMethod } from "../../../../../common/http/HttpMethod";
import { DataContinuationResult } from "../../../../../common/types/DataResult";
import {
    InfiniteQueryOptions,
    SortAndFilterOptions,
} from "../../../../../common/types/SortAndFilterOptions";
import consumerApiService from "../../../../../customization/api/consumerApiService";
import {
    DownloadFile,
    postData,
    postFormData,
} from "../../../../../helpers/ApiHelper";
import { MissingCarrsExam } from "../domain/missingCarrsExam";

enum UrlPaths {
    BaseResource = "organisation",
    ExaminationsBulkUpdater = "examinationsbulkupdater",
    MissingCarrsExamTool = "missingCarrsExamTool",
}

const getBaseUrl = (organisationId: string) =>
    `${consumerApiService.baseApiUrl}/${UrlPaths.BaseResource}/${organisationId}`;

export async function getInfiniteMissingCarrsExam(
    organisationId: string,
    query: InfiniteQueryOptions,
): Promise<DataContinuationResult<MissingCarrsExam>> {
    const url = `${getBaseUrl(organisationId)}/${
        UrlPaths.ExaminationsBulkUpdater
    }/paginated/${UrlPaths.MissingCarrsExamTool}`;

    const result = postData<DataContinuationResult<MissingCarrsExam>>(
        url,
        query,
    );

    const response = await result;
    if (!response.data) return;
    return {
        ...response,
        data: response.data,
    };
}

export interface UploadMissingCarrsExamArgs {
    organisationId: string;
    file: File;
}

export async function uploadMissingCarrsExam({
    organisationId,
    file,
}: UploadMissingCarrsExamArgs): Promise<void> {
    const url = `${getBaseUrl(organisationId)}/${
        UrlPaths.ExaminationsBulkUpdater
    }/${UrlPaths.MissingCarrsExamTool}`;

    const body = new FormData();
    body.append("file", file);

    await postFormData<void>(url, body);
}

interface DownloadTemplateArgs {
    organisationId: string;
    query: SortAndFilterOptions;
}
export function downloadTemplate({
    organisationId,
    query,
}: DownloadTemplateArgs): Promise<void> {
    const url = `${getBaseUrl(organisationId)}/${
        UrlPaths.ExaminationsBulkUpdater
    }/${UrlPaths.MissingCarrsExamTool}/template`;

    return DownloadFile({
        method: HttpMethod.Post,
        url,
        payload: query,
    });
}
