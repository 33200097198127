import React from "react";
import { useParams } from "react-router";
import { useGetOrgByShortNameQuery } from "../../../../store/features/organisation/organisation-api-slice";
import { useGetBciChartQuery } from "../../queries/bciApiSlice";
import CoveringLoadingPanel from "../../../../common/components/CoveringLoadingPanel";
import { BridgeConditionIndexGraph } from "./BridgeConditionIndexGraph";
import { SeriesType } from "@progress/kendo-react-charts";

export interface BridgeConditionIndexGraphComponentProps {
    chartType: string;
    scoreType: string;
    bciConfigName?: string;
    title: string;
    type: SeriesType;
}

const BridgeConditionIndexGraphComponent: React.FC<
    BridgeConditionIndexGraphComponentProps
> = (props) => {
    const { chartType, scoreType, title, bciConfigName, type } = props;

    const params = useParams<{ orgShortName: string }>();
    const { data: organisation } = useGetOrgByShortNameQuery(
        params.orgShortName,
        {
            skip: !params.orgShortName,
        },
    );
    const { data: bciChart, isLoading } = useGetBciChartQuery({
        organisationId: organisation?.id,
        params: { chartType, scoreType, bciConfigName },
    });

    if (isLoading) {
        return <CoveringLoadingPanel />;
    }
    return (
        <BridgeConditionIndexGraph chart={bciChart} title={title} type={type} />
    );
};

export default BridgeConditionIndexGraphComponent;
