import {
    SelectOptionItem,
    mapToSelectOptions,
} from "../../../../common/types/reactSelect/SelectOptionItem";
import ExamNamesConstants from "../../examinations/Constants/ExamNamesConstants";

export const getLineDispensationOptions = (): SelectOptionItem[] =>
    mapToSelectOptions(
        lineDispensationsOptions,
        (i) => i.label,
        (i) => i.value,
    );

export const getTrainsStopOptions = (): SelectOptionItem[] =>
    mapToSelectOptions(
        trainsStopOptions,
        (i) => i.label,
        (i) => i.value,
    );

export const getMomStatusOptions = (): SelectOptionItem[] =>
    mapToSelectOptions(
        momStatusOptions,
        (i) => i.label,
        (i) => i.value,
    );

export const getCossIwaOptions = (): SelectOptionItem[] =>
    mapToSelectOptions(
        cossIwaOptions,
        (i) => i.label,
        (i) => i.value,
    );

export const getCreateExamOptions = (): SelectOptionItem[] =>
    mapToSelectOptions(
        createExamOptions,
        (i) => i.label,
        (i) => i.value,
    );

const createExamOptions = [
    {
        label: ExamNamesConstants.RapidResponseVehicle,
        value: ExamNamesConstants.RapidResponseVehicle,
    },
    {
        label: ExamNamesConstants.RapidResponseNonVehicle,
        value: ExamNamesConstants.RapidResponseNonVehicle,
    },
];

const lineDispensationsOptions = [
    {
        label: "Green",
        value: "Green",
    },
    {
        label: "Double Amber",
        value: "Double Amber",
    },
    {
        label: "Red",
        value: "Red",
    },
    {
        label: "Delayed Response Agreed",
        value: "Delayed Response Agreed",
    },
    {
        label: "Unknown",
        value: "Unknown",
    },
];

const trainsStopOptions = [
    {
        label: "Yes",
        value: "Yes",
    },
    {
        label: "No",
        value: "No",
    },
];

const momStatusOptions = [
    {
        label: "Unknown",
        value: "Unknown",
    },
    {
        label: "Present",
        value: "Present",
    },
    {
        label: "On Way",
        value: "On Way",
    },
    {
        label: "Not Attending",
        value: "Not Attending",
    },
];

const cossIwaOptions = [
    {
        label: "Yes",
        value: "Yes",
    },
    {
        label: "No",
        value: "No",
    },
];
