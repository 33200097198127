import { State } from "@progress/kendo-data-query";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/configureStore";
import {
    setExaminationReviewGridState,
    setExaminationReviewColumnProps,
    setExaminationReviewSelectedColumns,
} from "../../../../store/features/examination-review-grid/examinationReviewGridSlice";
import { GridColumnProps } from "@progress/kendo-react-grid";
import React from "react";
import { GridColumnDef } from "../../../asset/hooks/useCombinedAssetsTemplates";

export type UseExaminationReviewPersistedGridFilters = {
    gridState: State;
    setGridState: (state: State) => {
        payload: State;
        type: string;
    };
    gridColumnProps: GridColumnProps[];
    gridSelectedColumns: GridColumnDef[];
    setColumnsProps: (columns: GridColumnProps[]) => void;
    setSelectedColumns: (columns: GridColumnDef[]) => void;
};

export const usePersistedExaminationReviewGridState =
    (): UseExaminationReviewPersistedGridFilters => {
        const dispatch = useDispatch();
        const { gridState, gridColumnProps, gridSelectedColumns } = useSelector(
            (state: RootState) => state.examinationReviewGridFilters,
        );

        const setColumnsProps = React.useCallback(
            (columns: GridColumnProps[]) => {
                dispatch(setExaminationReviewColumnProps(columns));
            },
            [dispatch],
        );

        const setSelectedColumns = React.useCallback(
            (columns: GridColumnDef[]) => {
                dispatch(setExaminationReviewSelectedColumns(columns));
            },
            [dispatch],
        );

        return {
            gridSelectedColumns,
            setSelectedColumns,
            gridColumnProps,
            setColumnsProps,
            gridState,
            setGridState: (state: State) =>
                dispatch(setExaminationReviewGridState(state)),
        };
    };
