import { isNil } from "lodash";
import { QueryKey } from "react-query";
import { SortAndFilterOptions } from "../../../common/types/SortAndFilterOptions";
import { ProcessActivityListSearchOptions } from "../services/processActivityListService";

export const queryKey = "process-activity-lists";

const processActivityListQueryKeys = {
    all: (): QueryKey => queryKey,
    filtered: (
        listActivityId: string,
        searchOptions: ProcessActivityListSearchOptions,
    ): QueryKey => [
        queryKey,
        listActivityId,
        ...searchOptionsKey(searchOptions),
    ],
};

const searchOptionsKey = (searchOptions: SortAndFilterOptions) => {
    if (isNil(searchOptions)) return [];

    const { filterParam } = searchOptions;

    const filterKeys = isNil(filterParam)
        ? []
        : filterParam.map((f) => `${f.field}.${f.operator}:${f.value}`);

    return [...filterKeys];
};

export default processActivityListQueryKeys;
