export interface TagWithIndex {
    id: string;
    text: string;
}

export function wrapTags(tags: string[]): TagWithIndex[] {
    if (tags) {
        return tags.map((value, index) => {
            return { id: index.toString(), text: value };
        });
    }
}
