import * as React from "react";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { Grid } from "@progress/kendo-react-grid";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import { AssetPreviewModel } from "../../../store/features/asset/asset-api-slice";
import {
    ContainsMenuFilter,
    emptyFilterDescriptor,
} from "../../../common/components/table/kendo/columnFilters";
import { useAppDispatch } from "../../../store/hooks";
import { Dispatch } from "@reduxjs/toolkit";
import { CallHistoryMethodAction, push } from "connected-react-router";
import useGridProps from "../../../common/components/table/kendo/useGridProps";

const navigateToAsset = (
    dispatch: Dispatch<CallHistoryMethodAction<[string, unknown?]>>,
    orgShortName: string,
    id: string,
): void => {
    dispatch(push(`/${orgShortName}${OrganisationConstants.Asset}/${id}`));
};

interface Props {
    assets: AssetPreviewModel[];
    orgShortName: string;
}

const AssetList: React.FC<Props> = ({ assets, orgShortName }) => {
    const dispatch = useAppDispatch();

    const { gridProps } = useGridProps(assets, {
        filterable: true,
        initialFilter: emptyFilterDescriptor,
        sortable: true,
        initialSort: [{ field: "name", dir: "asc" }],
        pageable: true,
    });

    return (
        <Grid
            {...gridProps}
            onRowDoubleClick={(e) =>
                navigateToAsset(dispatch, orgShortName, e.dataItem.id)
            }
        >
            <Column
                field="name"
                title="Name"
                headerClassName="grid-header"
                columnMenu={ContainsMenuFilter}
            />
            <Column
                field="description"
                title="Description"
                headerClassName="grid-header"
                columnMenu={ContainsMenuFilter}
            />
        </Grid>
    );
};
export default AssetList;
