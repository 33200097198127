import * as Formik from "formik";
import * as React from "react";
import {
    getCossIwaOptions,
    getLineDispensationOptions,
    getMomStatusOptions,
    getTrainsStopOptions,
} from "../common/options";
import { Alert, Card, Col, Row } from "react-bootstrap";
import {
    CustomSelect,
    DateTimeSelect,
    TextArea,
    TextInput,
} from "../../../../common/components/form";
import TextInputPhone from "../../../../common/components/form/TextInputPhone";
import { ApcIncidentAsset } from "../domain/types";
import ViewAssetFormInput from "./ViewAssetFormInput";
import { useOrganisationId } from "../../../organisation/hooks/useOrganisationId";
import { useGetApcIncidentByIdQuery } from "../queries/apcApiSlice";
import CoveringLoadingPanel from "../../../../common/components/CoveringLoadingPanel";
import ExamViewControl from "./ExamViewControl";
import { CardBody } from "reactstrap";

interface IncidentCreateFormModel {
    apcAssetDetails: ApcIncidentAsset;
    nrIncidentNumber: string;
    ameyNotifiedAt: string;
    incidentAt: string;
    nrNotifiedAt: string;
    natureOfIncident: string;
    nrControlTelNum: string;
    nrControllerName: string;
    lineDispensation: string;
    trainsStop: boolean;
    momStatus: string;
    momName: string;
    momEta: string;
    momTelNum: string;
    signalBoxName: string;
    signalBoxTelNum: string;
    examiner: string;
    examinerMobNum: string;
    examinerNotifiedAt: string;
    examinerEtaAt: string;
    cossIwaForm: string;
    examinerConfirmedToNrAt: string;
    examinerSiteArrivalAt: string;
    timePassesAtMph: string;
    passedAtMph: string;
    passedAtLineSpeedAt: string;
    examinerDepartureSiteAt: string;
    incidentClearedAt: string;
    incidentNotes: string;
}

const formInitialValues: IncidentCreateFormModel = {
    nrIncidentNumber: "",
    ameyNotifiedAt: "",
    incidentAt: "",
    nrNotifiedAt: "",
    natureOfIncident: "",
    nrControlTelNum: "",
    nrControllerName: "",
    lineDispensation: "",
    trainsStop: false,
    momStatus: "",
    momName: "",
    momEta: "",
    momTelNum: "",
    signalBoxName: "",
    signalBoxTelNum: "",
    apcAssetDetails: {
        assetId: "",
        assetType: "",
        chains: "",
        elr: "",
        miles: "",
        structureName: "",
        structureRefNum: "",
        yards: "",
    },
    examiner: "",
    examinerMobNum: "",
    examinerNotifiedAt: "",
    examinerEtaAt: "",
    cossIwaForm: "",
    examinerConfirmedToNrAt: "",
    examinerSiteArrivalAt: "",
    timePassesAtMph: "",
    passedAtMph: "",
    passedAtLineSpeedAt: "",
    examinerDepartureSiteAt: "",
    incidentClearedAt: "",
    incidentNotes: "",
};

interface IncidentViewFormProps {
    allowCancel: (value: boolean) => void;
    incidentId: string;
}

const IncidentViewForm: React.FC<IncidentViewFormProps> = ({
    incidentId,
    allowCancel,
}) => {
    const { organisationId } = useOrganisationId();
    const { data, isLoading } = useGetApcIncidentByIdQuery({
        id: incidentId,
        organisationId: organisationId,
    });

    if (isLoading) return <CoveringLoadingPanel />;

    allowCancel(data.state !== "Cancelled");

    return (
        <Formik.Formik
            enableReinitialize
            initialValues={isLoading ? formInitialValues : data}
            validateOnMount={true}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onSubmit={() => {}}
        >
            {() => (
                <Formik.Form>
                    <Card className="m-3">
                        <CardBody>
                            <h2>Network Rail Control Information</h2>
                            <Row>
                                <Col xs={"auto"}>
                                    <TextInput
                                        label="Network Rail Incident Number"
                                        name="nrIncidentNumber"
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={"auto"}>
                                    <DateTimeSelect
                                        label="Date and Time Incident"
                                        name="incidentAt"
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={"auto"}>
                                    <DateTimeSelect
                                        label="Time Amey Notified"
                                        name="ameyNotifiedAt"
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={"auto"}>
                                    <DateTimeSelect
                                        label="Time Reported to NR"
                                        name="nrNotifiedAt"
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextArea
                                        label="Nature of Incident"
                                        name="natureOfIncident"
                                        rows={5}
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={"auto"}>
                                    <TextInputPhone
                                        label="NR Control Tel No"
                                        name="nrControlTelNum"
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={"auto"}>
                                    <TextInput
                                        label="NR Controller Name"
                                        name="nrControllerName"
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={"4"}>
                                    <CustomSelect
                                        name="lineDispensation"
                                        label="Line Dispensation"
                                        options={getLineDispensationOptions()}
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={"auto"}>
                                    <CustomSelect
                                        name="trainsStop"
                                        label="Are Trains on Stop?"
                                        options={getTrainsStopOptions()}
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={"3"}>
                                    <CustomSelect
                                        name="momStatus"
                                        label="MOM Status"
                                        options={getMomStatusOptions()}
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={"auto"}>
                                    <TextInput
                                        label="MOM Name"
                                        name="momName"
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={"auto"}>
                                    <DateTimeSelect
                                        label="MOM ETA"
                                        name="momEta"
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={"auto"}>
                                    <TextInputPhone
                                        label="MOM Tel No"
                                        name="momTelNum"
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={"auto"}>
                                    <TextInput
                                        label="Signal Box Name"
                                        name="signalBoxName"
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={"auto"}>
                                    <TextInputPhone
                                        label="Signal Box Tel No"
                                        name="signalBoxTelNum"
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <h2>Asset Information</h2>
                            <ViewAssetFormInput />
                            <h2>Examiner Details</h2>
                            <Alert variant="primary">
                                <Alert.Heading>
                                    NOW USE CEFA Rapid Response SharePoint Site
                                    TO IDENTIFY AND CONTACT THE NEAREST EXAMINER
                                </Alert.Heading>
                                <Alert.Link
                                    target="_blank"
                                    href="https://ameygroup.sharepoint.com/sites/RapidResponsetest/"
                                >
                                    https://ameygroup.sharepoint.com/sites/RapidResponsetest/
                                </Alert.Link>
                            </Alert>
                            <Row>
                                <Col xs={2}>
                                    <TextInput
                                        label="Examiner"
                                        name="examinerEmail"
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={"auto"}>
                                    <TextInputPhone
                                        label="Examiner Mob No"
                                        name="examinerMobNum"
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={"auto"}>
                                    <DateTimeSelect
                                        label="Time Examiner Notified"
                                        name="examinerNotifiedAt"
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={"auto"}>
                                    <DateTimeSelect
                                        label="Examiner ETA"
                                        name="examinerEtaAt"
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={"auto"}>
                                    <CustomSelect
                                        name="cossIwaForm"
                                        label="COSS/IWA Form"
                                        description={
                                            "Have you asked Examiner if they have filled in COSS/IWA Form for Use in 'Exceptional Circumstances'"
                                        }
                                        options={getCossIwaOptions()}
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            {!!data.examDetails && (
                                <ExamViewControl
                                    processId={data.examDetails.processId}
                                />
                            )}
                            <h2>Examiner Information</h2>
                            <Row>
                                <Col>
                                    <Alert variant="primary">
                                        <Alert.Heading>
                                            NOW RING NR TO INFORM OF EXAMINER
                                            DETAILS AND ENTER CALL TIME BELOW
                                        </Alert.Heading>
                                    </Alert>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={"auto"}>
                                    <DateTimeSelect
                                        label="Time Examiner Details Confirmed to NR"
                                        name="examinerConfirmedToNrAt"
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={"auto"}>
                                    <DateTimeSelect
                                        label="Time Examiner Arrived on Site"
                                        name="examinerSiteArrivalAt"
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={"auto"}>
                                    <DateTimeSelect
                                        label="Time Passes at MPH"
                                        name="timePassesAtMph"
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={"auto"}>
                                    <DateTimeSelect
                                        label="Passed at (MPH)"
                                        name="passedAtMph"
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={"auto"}>
                                    <DateTimeSelect
                                        label="Time Passed at Linespeed"
                                        name="passedAtLineSpeedAt"
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={"auto"}>
                                    <DateTimeSelect
                                        label="Time Examiner Departure from Site"
                                        name="examinerDepartureSiteAt"
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <h2>Incident Completion</h2>
                            <Row>
                                <Col>
                                    <Alert variant="primary">
                                        <Alert.Heading>
                                            NOW RING NR TO INFORM THEM THAT
                                            INCIDENT IS CLEARED
                                        </Alert.Heading>
                                    </Alert>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={"auto"}>
                                    <DateTimeSelect
                                        label="Time Incident Cleared"
                                        name="incidentClearedAt"
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <TextArea
                                        label="Incident Notes"
                                        name="incidentNotes"
                                        rows={5}
                                        plainText={true}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Formik.Form>
            )}
        </Formik.Formik>
    );
};
export default IncidentViewForm;
