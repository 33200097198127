import React from "react";
import { Button, ButtonToolbar } from "react-bootstrap";
import ButtonSpinner from "../../../common/components/button/ButtonSpinner";
import { Approval } from "../domain/types";

export interface ResetActionProps {
    actionCallback: (arg: {
        activityId: string;
        approvalId: string;
    }) => Promise<unknown>;
    actionResult: {
        isLoading: boolean;
        isError: boolean;
        isSuccess: boolean;
        error?: unknown;
    };
}

interface Props {
    activityId: string;
    approval: Approval;

    reset: ResetActionProps;
}

const ApprovalResetForm: React.FC<Props> = ({
    activityId,
    approval,
    reset,
}) => {
    const handleResetApproval = (): void => {
        reset.actionCallback({ activityId, approvalId: approval.id });
    };

    return (
        <>
            <ButtonToolbar className="top10">
                <Button
                    variant="danger"
                    disabled={reset.actionResult.isLoading}
                    onClick={handleResetApproval}
                >
                    Reset approval{" "}
                    {reset.actionResult.isLoading && <ButtonSpinner />}
                </Button>
            </ButtonToolbar>
        </>
    );
};

export default ApprovalResetForm;
