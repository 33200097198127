import { consumerApi } from "../../../../customization/api/consumerRTKQueryApi";
import { Tags } from "../../../../store/features/dcpApi";
import {
    ApcIncident,
    ApcIncidentCreateRequestData,
    ApcIncidentUpdateRequestData,
} from "../domain/types";

export class ApcApiRoutes {
    static readonly ApiRoute = "/apc";
    static readonly CreateRoute = "/create";
}

export interface ApcIncidentGetRequestData {
    id: string;
    organisationId: string;
}

const apiSubPath = (organisationId: string) =>
    `/organisation/${organisationId}${ApcApiRoutes.ApiRoute}`;

export const apcApi = consumerApi.injectEndpoints({
    endpoints: (builder) => ({
        getApcIncidentById: builder.query<
            ApcIncident,
            ApcIncidentGetRequestData
        >({
            query: (data) => `${apiSubPath(data.organisationId)}/${data.id}`,
            providesTags: (_result, _error, data) => [
                { type: Tags.Apc, id: data.id },
            ],
        }),
        getApcIncidents: builder.query<
            ApcIncident[],
            { organisationId: string }
        >({
            query: ({ organisationId }) => apiSubPath(organisationId),
            providesTags: () => [{ type: Tags.Apc }],
        }),
        createApcIncident: builder.mutation<
            string,
            ApcIncidentCreateRequestData
        >({
            query: (data) => ({
                url: `${apiSubPath(data.organisationId)}`,
                method: "POST",
                body: { ...data },
            }),
            invalidatesTags: () => [{ type: Tags.Apc }],
        }),
        cancelApcIncident: builder.mutation<
            string,
            { organisationId: string; entityId: string }
        >({
            query: (data) => ({
                url: `${apiSubPath(data.organisationId)}/${
                    data.entityId
                }/cancel`,
                method: "PATCH",
                body: {},
            }),
            invalidatesTags: (id) => [
                { type: Tags.Apc },
                { type: Tags.Apc, id: id },
            ],
        }),
        updateApcIncident: builder.mutation<
            string,
            ApcIncidentUpdateRequestData
        >({
            query: (data) => ({
                url: `${apiSubPath(data.organisationId)}/${data.id}`,
                method: "PUT",
                body: { ...data },
            }),
            invalidatesTags: (id) => [
                { type: Tags.Apc },
                { type: Tags.Apc, id: id },
            ],
        }),
    }),
});

export const {
    useGetApcIncidentsQuery,
    useGetApcIncidentByIdQuery,
    useCreateApcIncidentMutation,
    useUpdateApcIncidentMutation,
    useCancelApcIncidentMutation,
} = apcApi;
