import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import authService from "../../modules/authorization/services/AuthService";
import { Tags } from "../../store/features/dcpApi";
import consumerApiService from "./consumerApiService";

/* 
Custom baseQuery with dynamic baseUrl.

Sets the url using consumer's API address that is accessible via consumerApiService (read and persisted there from config/json file on App start)
Must use this service as hooks can't be used here 

Solution inspired by documentation:
https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#constructing-a-dynamic-base-url-using-redux-state
*/
const customBaseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    const baseApiUrl = consumerApiService.baseApiUrl;

    // Handle scenarios where data to generate the URL is missing
    if (!baseApiUrl) {
        return {
            error: {
                status: 400,
                statusText: "Bad Request",
                data: "No Consumer Api Endpoint Address received",
            },
        };
    }

    return fetchBaseQuery({
        baseUrl: baseApiUrl,
        async prepareHeaders(headers) {
            headers.set(
                "Authorization",
                `Bearer ${await authService.getAccessToken()}`,
            );
            headers.set("Accept", "application/json");
            return headers;
        },
    })(args, api, extraOptions);
};

export const consumerApi = createApi({
    reducerPath: "consumerApi",
    baseQuery: customBaseQuery,
    tagTypes: Object.values(Tags),
    endpoints: () => ({}),
    keepUnusedDataFor: 30,
});
