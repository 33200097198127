import {
    Grid,
    GridColumn,
    GridRowDoubleClickEvent,
} from "@progress/kendo-react-grid";
import cx from "classnames";
import { push } from "connected-react-router";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { ContainsMenuFilter } from "../../../common/components/table/kendo/columnFilters";
import useGridProps from "../../../common/components/table/kendo/useGridProps";
import { GridSelectionMode } from "../../../common/components/table/kendo/useGridSelectableProps";
import { TypedGridCellProps } from "../../../common/types/TypedGridCellProps";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { AssetPreviewModel } from "../../../store/features/asset/asset-api-slice";
import { ActivityAssetPreview } from "../../../store/features/process-activity/types";
import AssetLinkActions from "./AssetLinkActions";
import { AssetLinkListNameCell } from "./AssetLinkListNameCell";

const DATA_ITEM_KEY = "id";

interface AssetLinkListProps {
    orgShortName: string;
    assets: ActivityAssetPreview[];
    onRemove: (assetId: string) => void | Promise<void>;
    onSelectedAssetsChange?: (selected: AssetPreviewModel[]) => void;
    editable: boolean;
    isLoading: boolean;
}

const AssetLinkList: React.FC<AssetLinkListProps> = ({
    orgShortName,
    assets,
    onRemove,
    editable,
    onSelectedAssetsChange,
    isLoading,
}) => {
    const { processId } = useParams<{
        processId: string;
        orgShortName: string;
    }>();

    const dispatch = useDispatch();

    const navigateToAsset = (assetItem: AssetPreviewModel): void => {
        dispatch(
            push(
                `/${orgShortName}${OrganisationConstants.Asset}/${assetItem.id}`,
            ),
        );
    };

    const NameCell = React.useMemo(
        // eslint-disable-next-line react/display-name
        () => (cell: TypedGridCellProps<ActivityAssetPreview>) =>
            (
                <AssetLinkListNameCell
                    cell={cell}
                    orgShortName={orgShortName}
                    processId={processId}
                />
            ),

        [orgShortName, processId],
    );

    const ActionsCell = React.useMemo(
        // eslint-disable-next-line react/display-name
        () => (cell: TypedGridCellProps<ActivityAssetPreview>) =>
            (
                <td>
                    {!cell.dataItem.mirrored && editable && (
                        <AssetLinkActions
                            key={cell.dataItem.id}
                            onRemove={() => onRemove(cell.dataItem.id)}
                            isLoading={isLoading}
                        />
                    )}
                </td>
            ),
        [editable, onRemove, isLoading],
    );

    const gridData = React.useMemo(() => assets || [], [assets]);
    const { gridProps } = useGridProps(gridData, {
        dataItemKey: DATA_ITEM_KEY,
        pageable: true,
        sortable: true,
        initialSort: [{ field: "name", dir: "asc" }],
        selectable: editable
            ? {
                  config: {
                      enabled: true,
                      drag: true,
                      mode: GridSelectionMode.Multiple,
                  },
                  onSelectChange: onSelectedAssetsChange,
              }
            : null,
    });

    return (
        <>
            <Grid
                {...gridProps}
                className={cx(gridProps.className, "selectable-rows mt-3")}
                scrollable="none"
                onRowDoubleClick={(event: GridRowDoubleClickEvent) => {
                    navigateToAsset(event.dataItem);
                }}
            >
                <GridColumn
                    field="name"
                    title="Name"
                    width="auto"
                    columnMenu={ContainsMenuFilter}
                    cell={NameCell}
                />
                {editable && <GridColumn width="60px" cell={ActionsCell} />}
            </Grid>
        </>
    );
};

export default AssetLinkList;
