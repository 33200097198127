import { FieldAttributes } from "formik";
import * as React from "react";
import {
    TextArea,
    TextInput,
    FileDownload,
} from "../../../common/components/form";
import ListTextInput from "../../../common/components/form/ListTextInput";
import { ProcessProperty, ProcessPropertyFile } from "../domain/types";

type FieldCommonProps = {
    label?: string;
    readOnly?: boolean;
} & FieldAttributes<unknown>;

const typeToJsx = (
    processProperty: ProcessProperty,
): React.ComponentType<FieldCommonProps> => {
    const { type, label, value } = processProperty;

    switch (type) {
        case "string":
            return TextInput;
        case "text":
            return TextArea;
        case "file": {
            const fileName =
                (processProperty as ProcessPropertyFile).fileProperties
                    ?.fileName || "";

            // eslint-disable-next-line react/display-name
            return () => (
                <FileDownload label={label} url={value} fileName={fileName} />
            );
        }
        case "list": {
            return ListTextInput;
        }
        default:
            return TextInput;
    }
};

const PropertyDynamicField: React.FC<ProcessProperty> = (processProperty) => {
    const Component = typeToJsx(processProperty);

    const options = React.useMemo(() => {
        const pOptions = {};

        processProperty.configOptions?.forEach(({ name, value }) => {
            pOptions[name] = value;
        });

        pOptions["valuesList"] = processProperty.valuesList;

        return pOptions;
    }, [processProperty.configOptions, processProperty.valuesList]);

    return (
        <Component
            readOnly={true}
            label={processProperty.label}
            name={processProperty.field}
            {...options}
        />
    );
};

export default PropertyDynamicField;
