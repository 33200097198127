import * as React from "react";
import { Input } from "@progress/kendo-react-inputs";
import { GridColumnMenuFilterUIProps } from "@progress/kendo-react-grid";

const CustomTextFilter: React.FC<GridColumnMenuFilterUIProps> = (
    props,
): JSX.Element => {
    const { firstFilterProps } = props;

    const onChange = (event) => {
        const newValue = event.target.value;

        firstFilterProps.onChange({
            value: newValue,
            operator: "contains",
            syntheticEvent: event.syntheticEvent,
        });
    };

    const value = firstFilterProps.value;

    return (
        <div>
            <div className="mb-3">
                <Input
                    placeholder="Contains..."
                    name="text"
                    type="text"
                    value={value}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};

export default CustomTextFilter;
