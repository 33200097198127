import * as React from "react";
import { Alert } from "react-bootstrap";
import { ProcessActivityAlert } from "../domain/types";
import ProcessActivityAlertButton from "./ProcessActivityAlertButton";

interface Props {
    alerts: ProcessActivityAlert[];
}

const ProcessActivityAlertBar: React.FC<Props> = ({ alerts }) => {
    const generateAlert = (alert: ProcessActivityAlert) => {
        return (
            <Alert key={alert.type} variant={alert.displayType}>
                <Alert.Heading>{alert.title}</Alert.Heading>
                <p className="mb-0">{alert.message}</p>
                {alert.buttons?.length ? (
                    alert.buttons.map((alertButton) => (
                        <ProcessActivityAlertButton
                            key={alertButton.type}
                            alertButton={alertButton}
                        ></ProcessActivityAlertButton>
                    ))
                ) : (
                    <></>
                )}
            </Alert>
        );
    };

    if (!alerts) return null;

    const alertsComponents = alerts.map(generateAlert);

    return <div>{alertsComponents}</div>;
};

export default ProcessActivityAlertBar;
