import * as React from "react";
import { createPortal } from "react-dom";

interface GridLoadingPanelProps {
    gridRef: React.RefObject<HTMLElement>;
}

const GridLoadingPanel: React.FC<GridLoadingPanelProps> = ({ gridRef }) => {
    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image" />
            <div className="k-loading-color" />
        </div>
    );

    const gridContent = gridRef.current;

    return gridContent ? createPortal(loadingPanel, gridContent) : loadingPanel;
};

export default GridLoadingPanel;
