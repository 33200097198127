import { saveAs } from "file-saver";
import authFetch from "../../../../helpers/ApiHelper";

interface DownloadWorkbankParams {
    url: string;
}

export function downloadWorkbank({
    url,
}: DownloadWorkbankParams): Promise<void> {
    return authFetch(url, {
        method: "GET",
    })
        .then(async (res) => {
            const filename = res.headers
                .get("Content-Disposition")
                .split("filename=")[1]
                .split(";")[0];
            return { blob: await res.blob(), filename };
        })
        .then(({ blob, filename }) => {
            return saveAs(blob, filename);
        });
}
