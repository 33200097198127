import * as React from "react";
import {
    Grid,
    GridColumn as Column,
    GridRowProps,
} from "@progress/kendo-react-grid";
import { DragAndDrop } from "@progress/kendo-react-common";
import { DraggableRow } from "./DraggableRow";
import { DragHandleCell } from "./DragHandleCell";
import { PhotoOrder } from "../../types";
import PhotoThumbnailCell from "./PhotoThumbnailCell";
import FileSourceCell from "./FileSourceCell";
import Detailrow from "./DetailRow";
import PhotoCaptionCell from "./PhotoCaptionCell";

// Context to share reordering functions across components
// - `direction`: Places the dragged item before or after `dataItem`. If `null`, no action is taken.
type ContextProps = {
    reorder: (
        dataItem: PhotoOrder,
        direction: "before" | "after" | null,
    ) => void;
    dragStart: (dataItem: PhotoOrder) => void;
};

export const ReorderContext = React.createContext<ContextProps>({
    reorder: () => {},
    dragStart: () => {},
});

interface Props {
    gridData: PhotoOrder[];
    setGridData: React.Dispatch<React.SetStateAction<PhotoOrder[]>>;
    height: number;
}

const ExamPhotoOrderListGrid: React.FC<Props> = ({
    gridData,
    setGridData,
    height,
}) => {
    const [activeItem, setActiveItem] = React.useState<PhotoOrder | null>(null);

    const reorder = (
        dataItem: PhotoOrder,
        direction: "before" | "after" | null,
    ) => {
        if (activeItem === dataItem || direction === null) return; // No change if dropping in the same spot

        const reorderedData = [...gridData]; // Create a copy of the data array

        // Locate the index of the item currently being dragged
        const prevIndex = reorderedData.findIndex((p) => p === activeItem);
        if (prevIndex === -1) return; // Exit if the active item isn't found

        // Determine the index where the active item should be placed
        let nextIndex =
            reorderedData.findIndex((p) => p === dataItem) +
            (direction === "before" ? -1 : 0);

        if (prevIndex > nextIndex) nextIndex++;

        // Move the active item to the new position
        reorderedData.splice(prevIndex, 1); // Remove from the old position
        reorderedData.splice(nextIndex, 0, activeItem); // Insert at the new position

        setGridData(
            // Add the array index position into item so it can be displayed on grid
            reorderedData.map((a, index) => {
                return { ...a, order: index + 1 };
            }),
        ); // Update the grid data state
        setActiveItem(null); // Clear the active item after drop
    };

    // Sets the item being dragged as the active item
    const dragStart = (dataItem) => {
        setActiveItem(dataItem);
    };

    const expandChange = (event) => {
        const newData = gridData.map((item) => {
            if (item.fileId === event.dataItem.fileId) {
                item.expanded = !event.dataItem.expanded;
            }
            return item;
        });
        setGridData(newData);
    };

    const row = (
        row: React.ReactElement<HTMLTableRowElement>,
        rowProps: GridRowProps,
    ) => <DraggableRow elementProps={row.props} {...rowProps} />;

    return (
        <ReorderContext.Provider value={{ reorder, dragStart }}>
            <DragAndDrop>
                <Grid
                    style={{ height: height }}
                    data={gridData}
                    dataItemKey={"storageName"}
                    rowRender={row}
                    detail={Detailrow}
                    expandField="expanded"
                    onExpandChange={expandChange}
                >
                    <Column title="" width="40px" cell={DragHandleCell} />
                    <Column field="order" title="Order" width="65px" />
                    <Column
                        title="Preview"
                        width="70px"
                        cell={PhotoThumbnailCell}
                    />
                    <Column
                        title="Source"
                        cell={FileSourceCell}
                        width="250px"
                    />
                    <Column cell={PhotoCaptionCell} title="Comment" />
                    <Column field="fileName" title="Filename" width="250px" />
                </Grid>
            </DragAndDrop>
        </ReorderContext.Provider>
    );
};

export default ExamPhotoOrderListGrid;
