import React from "react";
import { RouteComponentProps } from "react-router";
import Breadcrumbs from "../../../../../common/components/breadcrumbs/Breadcrumbs";
import { useOrgTitleWithShortName } from "../../../../../common/hooks/useTitle";
import { useOrganisationId } from "../../../../organisation/hooks/useOrganisationId";
import DeliveredExamsGrid from "../components/ApprovedExamsGrid";
import pageTitles from "./pageTitles";

const ApprovedExamsReportPage: React.FC<
    RouteComponentProps<{
        orgShortName: string;
    }>
> = () => {
    const { organisationId } = useOrganisationId();

    useOrgTitleWithShortName(pageTitles.deliveredExamsReport);

    return (
        <>
            <div className="mt-2">
                <Breadcrumbs
                    items={[[pageTitles.deliveredExamsReport]]}
                ></Breadcrumbs>
            </div>
            <h1>{pageTitles.deliveredExamsReport}</h1>
            <div className="mt-2">
                <DeliveredExamsGrid organisationId={organisationId} />
            </div>
        </>
    );
};

export default ApprovedExamsReportPage;
