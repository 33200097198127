import React from "react";
import { Alert } from "react-bootstrap";
import { BatchDetails } from "../../domain/batching";

type Props = {
    batch: BatchDetails;
};

const BatchErrorDetailsAlert: React.FC<Props> = ({ batch }) => {
    if (!batch.errorDetails) return null;

    return (
        <Alert variant="danger">
            <Alert.Heading>Upload Error</Alert.Heading>
            {batch.errorDetails}
        </Alert>
    );
};

export default BatchErrorDetailsAlert;
