import * as React from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import * as AdminStore from "../../../../store/admin/store";
import { Table, Row, Col } from "react-bootstrap";
import { useTable, CellProps } from "react-table";
import RoleUserTableActions from "./RoleUserTableActions";

/**
 * Options object for conditional rendering per RoleUser
 */
export interface TableRoleUser {
    id: string;
    name: string;
}

/**
 *  Table to display an ogranisation's RoleUsers and actions
 */
const RoleUsersList: React.FC = () => {
    // Get the RoleUsers and map to an options object
    const RoleUsers = useSelector((state: ApplicationState) => {
        return (
            state.admin?.domain.role.users ??
            AdminStore.unloadedState.domain.role.users
        );
    });

    const tableItem = RoleUsers.map((a): TableRoleUser => {
        return {
            id: a.id,
            name: a.username,
        };
    });

    const columns = React.useMemo(
        () => [
            {
                Header: "User",
                accessor: (row: TableRoleUser) => row.name,
            },
            {
                Header: "Actions",
                accessor: (row: TableRoleUser) => row.name,
                Cell: ({ row }: CellProps<TableRoleUser>) => {
                    return (
                        <RoleUserTableActions
                            userId={row.original.id}
                        ></RoleUserTableActions>
                    );
                },
            },
        ],
        [],
    );

    const data = React.useMemo(() => tableItem, [tableItem]);

    const tableInstance = useTable({ columns, data });

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance;

    return (
        <Row>
            <Col>
                <Table {...getTableProps()}>
                    <thead>
                        {
                            // Loop over the header rows
                            headerGroups.map((headerGroup) => (
                                // Apply the header row props

                                // eslint-disable-next-line react/jsx-key
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        // Loop over the headers in each row
                                        headerGroup.headers.map((column) => (
                                            // Apply the header cell props

                                            // eslint-disable-next-line react/jsx-key
                                            <th {...column.getHeaderProps()}>
                                                {
                                                    // Render the header
                                                    column.render("Header")
                                                }
                                            </th>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                            // Loop over the table rows
                            rows.map((row) => {
                                // Prepare the row for display
                                prepareRow(row);
                                return (
                                    // Apply the row props

                                    // eslint-disable-next-line react/jsx-key
                                    <tr {...row.getRowProps()}>
                                        {
                                            // Loop over the rows cells
                                            row.cells.map((cell) => {
                                                // Apply the cell props
                                                return (
                                                    // eslint-disable-next-line react/jsx-key
                                                    <td
                                                        {...cell.getCellProps()}
                                                    >
                                                        {
                                                            // Render the cell contents
                                                            cell.render("Cell")
                                                        }
                                                    </td>
                                                );
                                            })
                                        }
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
};

export default RoleUsersList;
