import { Grid, GridColumn, GridRowProps } from "@progress/kendo-react-grid";
import cx from "classnames";
import { useField } from "formik";
import { DateTime } from "luxon";
import React, { useMemo } from "react";
import CoveringLoadingPanel from "../../../../../common/components/CoveringLoadingPanel";
import { ColumnMenuFilter } from "../../../../../common/components/table/kendo/columnFilters";
import useGridProps from "../../../../../common/components/table/kendo/useGridProps";
import { BatchExam } from "../../domain/batching";
import { useGetBatchExamsQuery } from "../../queries/BatchingApiSlice";

export const BATCHING_EXAMS_SELECTED_FIELD = "exams";

type Props = {
    organisationId: string;
};

type BatchExamRow = BatchExam & {
    examDateJs?: Date;
};

export const BatchAddExamGrid: React.FC<Props> = ({ organisationId }) => {
    const { data, isLoading } = useGetBatchExamsQuery(organisationId, {
        skip: !organisationId,
    });
    const [, , helpers] = useField<BatchExamRow[]>(
        BATCHING_EXAMS_SELECTED_FIELD,
    );

    const mapData = () => {
        return (
            data?.map<BatchExamRow>(({ examDate, ...other }) => {
                return {
                    // Example: ISO date string to JS date with timezone
                    examDateJs: DateTime.fromISO(examDate, { zone: "utc" })
                        .setZone(
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                        )
                        .toJSDate(),
                    ...other,
                };
            }) ?? []
        );
    };
    const parsedData = useMemo(mapData, [data]);

    const { gridProps } = useGridProps(parsedData, {
        filterable: true,
        sortable: true,
        pageable: true,
        dataItemKey: "processId",
        selectable: {
            config: {
                enabled: true,
                mode: "multiple",
            },
            onSelectChange: (newSelected) => {
                helpers.setValue(newSelected);
            },
        },
    });

    const rowRender = (
        row: React.ReactElement<HTMLTableRowElement>,
        rowProps: GridRowProps,
    ) => {
        const trProps = {
            ...row.props,
            className: rowProps.dataItem.highlightExam
                ? cx("table-danger", row.props.className)
                : row.props.className,
        };

        return React.cloneElement(row, { ...trProps }, row.props.children);
    };

    if (isLoading) return <CoveringLoadingPanel />;

    return (
        <Grid {...gridProps} rowRender={rowRender} className="mt-3">
            <GridColumn
                field="processName"
                title="Process Name"
                sortable
                columnMenu={ColumnMenuFilter}
            />
            <GridColumn
                field="processType"
                title="Process Type"
                sortable
                columnMenu={ColumnMenuFilter}
            />
            <GridColumn
                field="structureRefNo"
                title="Structure Ref"
                width="auto"
                sortable
                columnMenu={ColumnMenuFilter}
            />
            <GridColumn
                field="examDateJs"
                title="Exam Date"
                format="{0:d MMM y}"
                sortable
                columnMenu={ColumnMenuFilter}
            />
            <GridColumn
                field="daysSinceExamDate"
                title="Days Since Exam Date"
                width="auto"
                sortable
                columnMenu={ColumnMenuFilter}
            />
        </Grid>
    );
};
