import consumerApiService from "../../../../customization/api/consumerApiService";
import { postData } from "../../../../helpers/ApiHelper";
import { ProcessCreateResponse } from "../../../process/domain/types";
import { ReactiveExamCreatorModel } from "../domain/ReactiveExamCreatorDtos";

enum UrlPaths {
    BaseResource = "organisation",
    Process = "reactiveExamCreator",
}

const getBaseUrl = () =>
    `${consumerApiService.baseApiUrl}/${UrlPaths.BaseResource}`;

interface ReactiveExamCreatorArgs {
    organisationId: string;
    postModel: ReactiveExamCreatorModel;
}

export function create({
    organisationId,
    postModel,
}: ReactiveExamCreatorArgs): Promise<ProcessCreateResponse> {
    const url = `${getBaseUrl()}/${organisationId}/${UrlPaths.Process}/`;
    return postData(url, postModel);
}
