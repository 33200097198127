import { getBaseApiUrl } from "./ConsumerApiConfig";

/* Service containing ClientApiEndpointAddress config data - to be used if needed within services (or wherever hooks can't be used) */
class ConsumerApiService {
    private _consumerApiAddress: string;
    private _baseApiUrl: string;

    initialize(consumerApiAddress: string): void {
        this._consumerApiAddress = consumerApiAddress;
        this._baseApiUrl = getBaseApiUrl(consumerApiAddress);
    }

    public get consumerApiAddress() {
        return this._consumerApiAddress;
    }

    public get baseApiUrl() {
        return this._baseApiUrl;
    }
}

const consumerApiService = new ConsumerApiService();
export default consumerApiService;
