import { Card } from "react-bootstrap";
import React, { useState } from "react";
import { CardBody } from "reactstrap";
import useOrganisationFile from "../../../organisation/hooks/useOrganisationFile";
import { Organisation } from "../../../organisation/domain/types";

interface Props {
    organisation: Organisation;
}
const OrganisationImage: React.FC<Props> = (props) => {
    const { organisation } = props;
    const [orgElevationImageUrl, setOrgElevationImageUrl] = useState<
        string | null
    >(null);
    const fileName =
        organisation.features.organisationFiles?.files?.overviewImage;

    useOrganisationFile(
        organisation.shortName,
        fileName,
        "image",
        setOrgElevationImageUrl,
    );

    return (
        <Card className="mb-3" id="organisationElevationImage">
            <CardBody>
                <div>
                    {orgElevationImageUrl && (
                        <img
                            src={orgElevationImageUrl}
                            alt={organisation.name}
                            style={{ height: "20rem" }}
                        />
                    )}
                </div>
            </CardBody>
        </Card>
    );
};

export default OrganisationImage;
