import React from "react";
import { useOrgTitleWithShortName } from "../../../../common/hooks/useTitle";
import pageTitles from "./pageTitles";
import Breadcrumbs from "../../../../common/components/breadcrumbs/Breadcrumbs";
import { AppRoutes } from "../../../../router/AppRoutes";
import { useNavigation } from "../../../../router/useNavigate";
import { Col, Row } from "react-bootstrap";
import IncidentCreateForm from "../components/IncidentCreateForm";

const IncidentCreatePage: React.FC = () => {
    useOrgTitleWithShortName(pageTitles.apcCreate);

    const { getOrgLink: getLink } = useNavigation();

    return (
        <Row>
            <Col>
                <Row className="mt-3">
                    <Col>
                        <Breadcrumbs
                            items={[
                                ["APC", getLink(AppRoutes.Apc)],
                                ["Create"],
                            ]}
                        ></Breadcrumbs>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h1>Create Incident</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <IncidentCreateForm />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default IncidentCreatePage;
