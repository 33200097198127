import { isArray } from "lodash";
import {
    TranslateQueryKeyFunc,
    TranslateTagFunc,
} from "../../../../../query/configureQuery";
import { Tags } from "../../../../../store/features/dcpApi";
import { missingCarrsExamsKey } from "./missingCarrsExamQueryKeys";

const tagsToQueryKeys: TranslateTagFunc = (tag) => {
    if (tag.type === Tags.ProcessActivity) {
        return missingCarrsExamsKey;
    }

    return null;
};

const queryKeysToTags: TranslateQueryKeyFunc = (queryKey, ids) => {
    if (
        queryKey === missingCarrsExamsKey ||
        (isArray(queryKey) && queryKey.includes(missingCarrsExamsKey))
    ) {
        return [{ type: Tags.ProcessActivity }];
    } else {
        return null;
    }
};

export const missingCarrsExamQueryKeysTranslator = {
    tagsToQueryKeys,
    queryKeysToTags,
};
