import * as React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import MutationResultStatus from "../../../common/components/MutationResultStatus";
import useOrgPermission from "../../../common/hooks/useOrgPermission";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import {
    useRemoveUserGroupMutation,
    useUpdateUserGroupMutation,
} from "../../../store/features/organisation/organisation-api-slice";
import { CreateUserGroup, UserGroup } from "../domain/types";
import { UserGroupForm } from "./userGroup/UserGroupForm";

interface UpdateUserGroupProps {
    orgId: string;
    userGroup: UserGroup;
}

const UpdateUserGroupForm: React.FC<UpdateUserGroupProps> = ({
    orgId,
    userGroup,
}) => {
    const history = useHistory();
    const [updateUserGroup, updateMutationResult] =
        useUpdateUserGroupMutation();
    const [removeUserGroup, removeMutationResult] =
        useRemoveUserGroupMutation();

    const canRemoveGroup = useOrgPermission(
        PermissionConstants.OrgUserGroup.delete,
    );
    const canUpdateGroup = useOrgPermission(
        PermissionConstants.OrgUserGroup.update,
    );

    const [isEdit, setIsEdit] = React.useState<boolean>(false);

    const onUpdateGroup = async (model: CreateUserGroup) => {
        await updateUserGroup({
            orgId: orgId,
            id: userGroup.id,
            model: model,
        });

        setIsEdit(false);
    };

    const onRemove = async () => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            await removeUserGroup({
                orgId: orgId,
                id: userGroup.id,
            }).unwrap();

            history.goBack();
        }
    };

    const onCancel = (): void => {
        setIsEdit(false);
    };

    const onStartEdit = (): void => {
        setIsEdit(true);
    };

    const userGroupForm: CreateUserGroup = {
        name: userGroup.name,
        description: userGroup.description,
    };

    return (
        <>
            <MutationResultStatus mutationResult={updateMutationResult} />
            <MutationResultStatus mutationResult={removeMutationResult} />
            <UserGroupForm
                userGroup={userGroupForm}
                onSubmit={onUpdateGroup}
                editable={isEdit}
            >
                {(isSubmitting) =>
                    isEdit ? (
                        <>
                            <Button
                                className="mr-1"
                                variant="primary"
                                type="submit"
                                disabled={isSubmitting}
                                key="update_button"
                            >
                                Update
                            </Button>
                            <Button
                                variant="outline-secondary"
                                type="button"
                                onClick={onCancel}
                                disabled={isSubmitting}
                            >
                                Cancel
                            </Button>
                        </>
                    ) : (
                        <>
                            {canUpdateGroup && (
                                <Button
                                    className="mr-1"
                                    variant="primary"
                                    type="button"
                                    onClick={onStartEdit}
                                >
                                    Edit
                                </Button>
                            )}
                            {canRemoveGroup && (
                                <Button
                                    variant="danger"
                                    type="button"
                                    onClick={onRemove}
                                >
                                    Remove
                                </Button>
                            )}
                        </>
                    )
                }
            </UserGroupForm>
        </>
    );
};

export default UpdateUserGroupForm;
