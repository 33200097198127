export default class RoleConstants {
    static readonly ApiPrefix = "/api";
    static readonly ResourceName = "/role";
    static readonly user = "/user";
    static readonly availableUsers = "/available-users";
    static readonly ApiEndpoint = `${RoleConstants.ApiPrefix}${RoleConstants.ResourceName}`;
    static readonly Roles = {
        Admin: "Admin",
    };
}
