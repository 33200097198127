import {
    CompositeFilterDescriptor,
    filterBy,
    orderBy,
    SortDescriptor,
} from "@progress/kendo-data-query";
import {
    GridFilterChangeEvent,
    GridPageChangeEvent,
    GridPagerSettings,
    GridProps,
    GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import React, { useMemo } from "react";
import { basicPagerSettings } from "./basicPagerSettings";
import { PageState } from "./PageState";
import useGridSelectableProps, {
    GridSelectionMode,
    SelectableSettings,
} from "./useGridSelectableProps";

interface HookResult {
    gridProps: GridProps;
    filter: CompositeFilterDescriptor;
    sort: SortDescriptor[];
    page: PageState;
    gridData: unknown[];
}

interface Settings<T> {
    dataItemKey?: string;

    filterable?: boolean;
    initialFilter?: CompositeFilterDescriptor;

    pageable?: boolean;
    initialPageSize?: number;
    pagerSettings?: GridPagerSettings;

    sortable?: boolean;
    initialSort?: SortDescriptor[];

    selectable?: SelectableSettings<T>;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const useGridProps = <T>(data: T[], settings: Settings<T>): HookResult => {
    const {
        dataItemKey = "id",
        filterable = true,
        initialFilter = null,
        pageable = true,
        initialPageSize = 20,
        sortable = true,
        initialSort = [],
        selectable = {
            config: { enabled: false, mode: GridSelectionMode.Single },
        },
    } = settings;

    const [filter, setFilter] =
        React.useState<CompositeFilterDescriptor>(initialFilter);

    const [sort, setSort] = React.useState<SortDescriptor[]>(initialSort);

    const initialPagerState: PageState = {
        skip: 0,
        take: initialPageSize,
    };
    const [page, setPage] = React.useState<PageState>(initialPagerState);

    const filterableProps = filterable
        ? {
              filter: filter,
              onFilterChange: (e: GridFilterChangeEvent) => setFilter(e.filter),
          }
        : {};

    const sortableProps = sortable
        ? {
              sortable: true,
              sort: sort,
              onSortChange: (e: GridSortChangeEvent) => setSort(e.sort),
          }
        : {};

    const sortedFilteredData = useMemo(() => {
        let result = data || [];

        if (filterable) result = filterBy(result, filter);
        if (sortable) result = orderBy(result, sort);

        return result;
    }, [filter, sort, data, filterable, sortable]);

    const pagedData = useMemo(() => {
        if (pageable)
            return sortedFilteredData.slice(page.skip, page.take + page.skip);

        return sortedFilteredData;
    }, [page, sortedFilteredData, pageable]);

    const pageableProps =
        pageable && sortedFilteredData?.length > 0
            ? {
                  pageable: settings.pagerSettings || basicPagerSettings,
                  pageSize: page.take,
                  skip: page.skip,
                  take: page.take,
                  total: sortedFilteredData.length,
                  onPageChange: (e: GridPageChangeEvent) => setPage(e.page),
              }
            : {};

    const gridSelectable = useGridSelectableProps(
        pagedData,
        dataItemKey,
        selectable,
    );
    const selectableProps = gridSelectable.gridProps;
    const gridData = gridSelectable.gridData;

    return {
        gridProps: {
            data: gridData,
            dataItemKey,

            ...selectableProps,
            ...filterableProps,
            ...sortableProps,
            ...pageableProps,

            resizable: true,
            reorderable: true,
        },
        filter,
        sort,
        page,
        gridData: gridData,
    };
};

export default useGridProps;
