import * as Formik from "formik";
import * as React from "react";
import { MultiMutationResultStatus } from "../../../common/components/MutationResultStatus";
import TextArea from "../../../common/components/form/TextArea";
import TextInput from "../../../common/components/form/TextInput";
import {
    ProcessTemplateCreateRequest,
    ProcessTemplateUpdateRequest,
} from "../../../store/features/process-template/process-template-api-dto";
import { BaseTemplateFormProps } from "../../template/viewModel/templateForm";
import { ProcessTemplateFormValues } from "../viewModel/ProcessTemplateFormValues";
import { validationSchema } from "../viewModel/ProcessTemplateValidation";

type Props = BaseTemplateFormProps<
    ProcessTemplateFormValues,
    ProcessTemplateCreateRequest,
    ProcessTemplateUpdateRequest
>;

const ProcessTemplateForm: React.FC<Props> = (props) => {
    const { initialValues, createTemplate, updateTemplate, deleteTemplate } =
        props;

    const mutationResults = [
        createTemplate?.actionResult,
        updateTemplate?.actionResult,
        deleteTemplate?.actionResult,
    ].filter(Boolean);

    return (
        <div className="pt-2">
            <MultiMutationResultStatus showLoading results={mutationResults} />
            <Formik.Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={() => null}
            >
                {({ values }) => (
                    <Formik.Form>
                        <TextInput
                            label="Name"
                            placeholder="Name"
                            description="The name for your process template."
                            name="name"
                            value={values.name}
                            readOnly={true}
                        />
                        <TextArea
                            label="Description"
                            name="description"
                            value={values.description}
                            readOnly={true}
                            rows={3}
                        />
                        <TextArea
                            label="States JSON Configuration"
                            name="statesConfig"
                            readOnly={true}
                            rows={10}
                        />
                        <TextArea
                            label="Activities JSON Configuration"
                            name="config"
                            readOnly={true}
                            rows={20}
                        />
                        <TextArea
                            label="Property Json Configuration"
                            name="propertyConfig"
                            readOnly={true}
                            rows={15}
                        />
                        <TextArea
                            label="Features Json Configuration"
                            name="featuresConfig"
                            readOnly={true}
                            rows={10}
                        />
                    </Formik.Form>
                )}
            </Formik.Formik>
        </div>
    );
};

export default ProcessTemplateForm;
