import * as React from "react";
import { FileButton } from "../../../common/components/button/FileButton";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import { UploadFileParams } from "../../../store/features/file/fileApiSlice";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import { Organisation } from "../../organisation/domain/types";
import CaptureMetadataModal from "./metadata/CaptureMetadataModal";
import { useState } from "react";
interface FileUploadButtonProps {
    folderId: string;
    organisation: Organisation;
    uploadFile: (params: UploadFileParams) => void;
}

export const FileUploadButton: React.FC<FileUploadButtonProps> = ({
    folderId,
    uploadFile,
    organisation,
}) => {
    const canCreate = hasUserOrgPermission(
        organisation.id,
        PermissionConstants.OrgFilesFile.create,
    ).hasPermission;

    const [isUploading, setIsUploading] = useState(false);
    const [fileList, setFileList] = useState<File[] | null>();
    const closeMetadataModal = () => {
        setIsUploading(false);
    };

    const handleFileSelection = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { files },
        } = ev;
        if (organisation.features?.files?.collectMetaData) {
            files?.length && setFileList(Array.from(files));
            setIsUploading(true);
        } else {
            uploadFile({
                organisationId: organisation.id,
                folderId,
                files: ev.target.files,
            });
        }
    };

    const buttonLabel = `Upload ${
        organisation?.features?.files?.sideMenuName?.toLowerCase().trim() ??
        "files"
    }`;

    if (canCreate) {
        return isUploading && organisation.features?.files?.collectMetaData ? (
            <CaptureMetadataModal
                uploadFile={uploadFile}
                organisation={organisation}
                onClose={closeMetadataModal}
                folderId={folderId}
                fileList={fileList}
            />
        ) : (
            <FileButton onChange={handleFileSelection} multiple={true}>
                {buttonLabel}
            </FileButton>
        );
    }

    return null;
};
