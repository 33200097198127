export default class ExamNamesConstants {
    static readonly RapidResponseVehicle = "Rapid Response Vehicle";
    static readonly RapidResponseNonVehicle = "Rapid Response Non-Vehicle";
    static readonly PreliminaryAdviceNotification =
        "Preliminary Advice Notification";
    static readonly UnderwaterExam = "Underwater Exam";
    static readonly UnderwaterScourOne = "Underwater Exam + Scour Stage 1";
    static readonly UnderwaterScourTwo = "Underwater Exam + Scour Stage 2";
    static readonly ScourExam = "Scour Examination";
    static readonly ScourStageOne = "Scour Stage 1";
    static readonly ScourStageOneReview = "Scour Stage 1 Review";
    static readonly ScourStageTwo = "Scour Stage 2";
    static readonly UnderwaterScourOneReview =
        "Underwater Exam + Scour Stage 1 Review";
    static readonly UnderwaterScourTwoReview =
        "Underwater Exam + Scour Stage 2 Review";
    static readonly BridgeDetailed = "Bridge Detailed";
    static readonly BridgeDetailedHCE = "Bridge Detailed + HCE";
}
