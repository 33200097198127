import { Figure } from "react-bootstrap";
import { OrganisationListItem } from "../domain/types";
import React from "react";

interface Props {
    organisation: OrganisationListItem;
    orgLogo?: string;
}

export const OrganisationRowImage: React.FC<Props> = ({
    orgLogo,
    organisation,
}) => {
    if (orgLogo) {
        return (
            <Figure>
                <Figure.Image
                    className="organisationSelectionImage"
                    src={orgLogo}
                    fluid
                    alt={organisation.description}
                    style={{ display: "block" }}
                />
            </Figure>
        );
    } else {
        return <></>;
    }
};
