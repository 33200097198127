import * as React from "react";
import { Button } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { Organisation } from "../../organisation/domain/types";
import { useGetConfigQuery } from "../../../store/config/configApi";
import useViewPermissions from "../../../common/hooks/useViewPermissions";
import { useGetOrgByShortNameQuery } from "../../../store/features/organisation/organisation-api-slice";
import useIsClient from "../../../common/hooks/useIsClient";
import SbimConstants from "../constants/SbimConstants";

const HomeButton = () => {
    const history = useHistory();
    const handleClick = () => {
        history.push(`/`);
    };

    return (
        <Button onClick={handleClick} color={null} className="nav-button">
            Home
        </Button>
    );
};

const DocumentationButton = () => {
    const { data: config } = useGetConfigQuery();

    if (!config.DocumentationUrl) return null;

    const handleClick = () => {
        window.location.href = config.DocumentationUrl;
    };

    return (
        <Button onClick={handleClick} color={null} className="nav-button">
            Documentation
        </Button>
    );
};

const HelpButton = ({ organisation }: { organisation: Organisation }) => {
    const history = useHistory();

    const { canViewHelpSection } = useViewPermissions(organisation?.id);

    const handleClick = () => {
        history.push(
            `/${organisation.shortName}/${OrganisationConstants.help}`,
        );
    };

    if (organisation?.shortName && canViewHelpSection) {
        return (
            <Button onClick={handleClick} color={null} className="nav-button">
                Help
            </Button>
        );
    } else {
        return null;
    }
};

const SbimTopMenu: React.FC = () => {
    const params = useParams<{ orgShortName: string }>();
    const isSBIM = useIsClient(SbimConstants.ClientName);
    const { data: organisation } = useGetOrgByShortNameQuery(
        params.orgShortName,
        {
            skip: !params.orgShortName,
        },
    );

    return (
        <React.Fragment>
            {isSBIM && (
                <React.Fragment>
                    <HomeButton />
                    <DocumentationButton />
                    <HelpButton organisation={organisation} />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default SbimTopMenu;
