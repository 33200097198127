import { goBack, push } from "connected-react-router";
import * as Formik from "formik";
import * as React from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import ReportService from "../../../services/ReportService";
import ReportFormTable from "./ReportFormTable";
import * as ReportStore from "../../../store/report/store";
import { useGetOrgByIdQuery } from "../../../store/features/organisation/organisation-api-slice";
import { useParams } from "react-router";

/**
 * A dynamic object to hold error messages
 */
interface Errors {
    [key: string]: string;
}

interface DropDownOption {
    value: string;
    display: string;
}

export interface FormValues {
    name: string;
    configurationId: string;
    description: string;
    formTemplateId: string;
    formSelection: string[];
}

const getItemsWithPlaceholder = (
    input: DropDownOption[],
    placeholder: string,
): DropDownOption[] => {
    const placeholderArray = [{ value: "", display: placeholder }];
    return placeholderArray.concat(input);
};

const getTemplateDropDownOptions = (): DropDownOption[] => {
    // todo - use RTK query to get formTemplates - it is not in state.formTemplate?.templates any more
    // const data = state.formTemplate?.templates ?? [];
    const data = [];
    const options = data.map((a) => {
        return { value: a.id, display: a.name };
    });
    return getItemsWithPlaceholder(options, "Select template");
};

const getReportConfigurationDropDownOptions = (): DropDownOption[] => {
    // TODO this should come from api
    const data = [{ id: "1", displayName: "Excel" }];
    const options = data.map((a) => {
        return { value: a.id, display: a.displayName };
    });
    return getItemsWithPlaceholder(options, "Select report configuration");
};

const OrgReportCreateForm: React.FC = () => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();
    const { data: org } = useGetOrgByIdQuery(id);
    const formTemplateOptions = useSelector(getTemplateDropDownOptions);
    const formReportConfigurationOptions = useSelector(
        getReportConfigurationDropDownOptions,
    );

    /**
     * Validate the forms inputs
     * @param values the form input values
     */
    const validate = (values: FormValues): Errors => {
        const errors: Errors = {};

        if (!values.name) {
            errors.name = "Required";
        }

        if (!values.formTemplateId) {
            errors.formTemplateId = "Required";
        }

        if (values.formSelection.length === 0) {
            errors.formSelection = "Required";
        }

        return errors;
    };

    /**
     * Action to take when the report form submission is a success
     * @param res the response object from the server
     */
    const createSuccessful = (reportId: string): void => {
        dispatch(
            push(
                `/${org.shortName}${OrganisationConstants.Report}/${reportId}`,
            ),
        );
    };

    const cancel = (): void => {
        dispatch(goBack());
    };

    return (
        <Row>
            <Col>
                <Formik.Formik
                    enableReinitialize
                    initialValues={{
                        name: "",
                        description: "",
                        configurationId: "",
                        formTemplateId: "",
                        formSelection: [],
                    }}
                    validate={validate}
                    onSubmit={async (
                        values: FormValues,
                        { setSubmitting, resetForm },
                    ): Promise<void> => {
                        // When button submits form and form is in the process of submitting, submit button is disabled
                        setSubmitting(true);
                        ReportService.Create(
                            values.name,
                            values.description,
                            values.configurationId,
                            values.formTemplateId,
                            values.formSelection,
                            org.id,
                        )
                            .then((data) => {
                                createSuccessful(data.id);
                                resetForm();
                            })
                            .catch((error) => {
                                console.log("Request failed:", error.message);
                                setSubmitting(false);
                            });
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        setFieldTouched,
                        validateForm,
                        isSubmitting,
                    }): JSX.Element => (
                        <Formik.Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Name"
                                    name="name"
                                    /* Set onChange to handleChange */
                                    onChange={handleChange}
                                    /* Set onBlur to handleBlur */
                                    onBlur={handleBlur}
                                    /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
                                    value={values.name}
                                    className={
                                        touched.name && errors.name
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                />
                                {errors.name ? (
                                    <div className="invalid-feedback">
                                        {errors.name}
                                    </div>
                                ) : null}
                                <Form.Text className="text-muted">
                                    The name for your report.
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="reportDescription">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="description"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.description}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Select Report Configuration
                                </Form.Label>
                                <Form.Control
                                    as="select"
                                    id="formReportConfiguration"
                                    name="Select Report Configuration"
                                    value={values.configurationId}
                                    onChange={(e): void => {
                                        // Custom form controls must set the value in this way. If this is set manually it will reset the state.
                                        setFieldValue(
                                            "configurationId",
                                            e.currentTarget.value,
                                        );
                                    }}
                                    onBlur={handleBlur}
                                >
                                    {formReportConfigurationOptions.map(
                                        (template: DropDownOption) => (
                                            <option
                                                key={template.value}
                                                value={template.value}
                                            >
                                                {template.display}
                                            </option>
                                        ),
                                    )}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Select Template</Form.Label>
                                <Form.Control
                                    as="select"
                                    id="formTemplate"
                                    name="Select Template"
                                    value={values.formTemplateId}
                                    className={
                                        touched.formTemplateId &&
                                        errors.formTemplateId
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                    onChange={(e): void => {
                                        // Custom form controls must set the value in this way. If this is set manually it will reset the state.
                                        setFieldValue(
                                            "formTemplateId",
                                            e.currentTarget.value,
                                        );

                                        dispatch(
                                            ReportStore.actionCreators.getReportListItemsByTemplate(
                                                e.currentTarget.value,
                                            ),
                                        );
                                    }}
                                    onBlur={handleBlur}
                                >
                                    {formTemplateOptions.map(
                                        (template: DropDownOption) => (
                                            <option
                                                key={template.value}
                                                value={template.value}
                                            >
                                                {template.display}
                                            </option>
                                        ),
                                    )}
                                </Form.Control>
                                {errors.formTemplateId ? (
                                    <div className="invalid-feedback">
                                        {errors.formTemplateId}
                                    </div>
                                ) : null}
                            </Form.Group>
                            <Form.Group>
                                {values.formTemplateId ? (
                                    <Form.Label>Forms</Form.Label>
                                ) : null}
                                {values.formTemplateId ? (
                                    <Form.Text className="text-muted bottom5">
                                        The forms to appear in the report.
                                        Please select at least one form.
                                    </Form.Text>
                                ) : null}
                                {values.formTemplateId ? (
                                    <ReportFormTable
                                        formikValues={values}
                                        touched={touched}
                                        validate={validateForm}
                                        setValue={setFieldValue}
                                        setTouched={setFieldTouched}
                                        errors={errors}
                                    ></ReportFormTable>
                                ) : null}
                                {touched.formSelection &&
                                errors.formSelection &&
                                !errors.formTemplateId ? (
                                    <div className="invalid-feedback display">
                                        {errors.formSelection}
                                    </div>
                                ) : null}
                            </Form.Group>
                            <div className="float-right">
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Create
                                </Button>
                                <Button
                                    variant="danger"
                                    className="ml-1"
                                    onClick={cancel}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Formik.Form>
                    )}
                </Formik.Formik>
            </Col>
        </Row>
    );
};

export default OrgReportCreateForm;
