import React from "react";
import { useGetUserRequestedFileHistoryQuery } from "../../../store/features/user-requested-files/userRequestedFilesApiSlice";
import CoveringLoadingPanel from "../../../common/components/CoveringLoadingPanel";
import UserRequestedFilesHistoryTablePanel from "./UserRequestedFilesHistoryTablePanel";

export interface UserRequestedFilesHistoryTableComponentProps {
    orgId: string;
    filterOnTags: boolean;
    tagsToFilterOn: string[];
    reportTitle: string;
}

const UserRequestedFilesHistoryTableComponent: React.FC<
    UserRequestedFilesHistoryTableComponentProps
> = (props) => {
    const { data: reportList, isLoading } = useGetUserRequestedFileHistoryQuery(
        {
            organisationId: props.orgId,
            tags: props.tagsToFilterOn,
            filterOnTags: props.filterOnTags,
        },
    );

    if (isLoading) {
        return <CoveringLoadingPanel />;
    }
    return (
        <UserRequestedFilesHistoryTablePanel
            data={reportList}
            reportTitle={props.reportTitle}
        />
    );
};

export default UserRequestedFilesHistoryTableComponent;
