import React, { useCallback } from "react";
import {
    useInfiniteQuery,
    UseInfiniteQueryResult,
    useQuery,
    UseQueryResult,
} from "react-query";
import { useDebounce } from "use-debounce";
import { DataContinuationResult } from "../../../common/types/DataResult";
import { postData } from "../../../helpers/ApiHelper";
import { AssetWithPropsModel } from "../../../store/features/asset/asset-api-slice";
import { AssetSortAndFilter } from "../../asset/query/assetQueries";
import assetQueryKeys from "../../asset/query/assetQueryKeys";
import { ProcessActivityNavigator } from "../domain/types";
import {
    baseUrl,
    getActivityAssetsContinuation,
    UrlPaths,
} from "../services/activityAssetService";
import {
    getInfiniteProcessActivityNavigatableItems,
    ProcessActivitySearchOptions,
} from "../services/processActivityService";
import processActivityQueryKeys from "./processActivityQueryKeys";

type AssetType = DataContinuationResult<AssetWithPropsModel>;
type ErrorType = [string, { page: number }];

export const useGetInfiniteSuggestedAssets = (
    organisationId: string,
    activityId: string,
    sortAndFilter: AssetSortAndFilter,
): UseInfiniteQueryResult<DataContinuationResult<AssetWithPropsModel>> => {
    const assetTemplatesString = JSON.stringify(sortAndFilter.assetTemplates);
    const filtersString = JSON.stringify(sortAndFilter.filterParams);

    const getAssets = useCallback(
        ({
            pageParam = null,
        }): Promise<DataContinuationResult<AssetWithPropsModel>> => {
            const continuationToken = pageParam;

            return getActivityAssetsContinuation({
                continuationToken,
                activityId,
                ...sortAndFilter,
                organisationId,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [organisationId, activityId, assetTemplatesString, filtersString],
    );

    const keys = React.useMemo(
        () =>
            assetQueryKeys.orgActivityAssets(
                organisationId,
                activityId,
                sortAndFilter,
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [organisationId, activityId, assetTemplatesString, filtersString],
    );

    const options = {
        getNextPageParam: (lastPage) => {
            // eslint-disable-next-line no-undefined
            return lastPage.continuationToken || undefined;
        },
    };

    return useInfiniteQuery<AssetType, ErrorType>(keys, getAssets, options);
};

export const useGetInfiniteOrgAssetsCount = (
    organisationId: string,
    sortAndFilter: AssetSortAndFilter,
    activityId: string,
): UseQueryResult<number> => {
    const url = `${baseUrl}/${organisationId}/${UrlPaths.ProcessActivity}/${activityId}/${UrlPaths.Assets}/count`;

    const assetTemplatesString = JSON.stringify(sortAndFilter.assetTemplates);
    const filtersString = JSON.stringify(sortAndFilter.filterParams);

    const getAssetsCount = useCallback(
        (): Promise<number> => {
            return postData<number>(url, sortAndFilter);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [organisationId, activityId, assetTemplatesString, filtersString],
    );

    const keys = React.useMemo(
        () =>
            assetQueryKeys.OrgAssetsLinkCount(
                organisationId,
                activityId,
                sortAndFilter,
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [organisationId, activityId, assetTemplatesString, filtersString],
    );

    return useQuery(keys, getAssetsCount);
};

export const useGetInfiniteProcessNavigatableItems = (
    activityId: string,
    query: ProcessActivitySearchOptions,
): UseInfiniteQueryResult<DataContinuationResult<ProcessActivityNavigator>> => {
    const [debouncedQuery] = useDebounce(query, 1000);
    const getPaginatedItems = useCallback(
        ({
            pageParam = null,
        }): Promise<DataContinuationResult<ProcessActivityNavigator>> => {
            const continuationToken = pageParam;

            return getInfiniteProcessActivityNavigatableItems({
                activityId,
                query: { ...debouncedQuery, continuationToken },
            });
        },
        [debouncedQuery, activityId],
    );

    const queryKeys = React.useMemo(
        () => processActivityQueryKeys.filtered(activityId, debouncedQuery),
        [debouncedQuery, activityId],
    );

    return useInfiniteQuery<
        DataContinuationResult<ProcessActivityNavigator>,
        [string, { page: number }]
    >(queryKeys, getPaginatedItems, {
        getNextPageParam: (lastResult) => {
            return lastResult.continuationToken;
        },
    });
};

export function customButtonAction(url: string): Promise<void> {
    return postData(url);
}
