import React from "react";
import { Row } from "react-bootstrap";
import CoveringLoadingPanel from "../../../common/components/CoveringLoadingPanel";
import SendInvitationForm from "../components/SendInvitationForm";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";

const InvitationPage: React.FC = () => {
    const { organisationId, isLoadingOrgId } = useOrganisationId();

    if (isLoadingOrgId) {
        return <CoveringLoadingPanel />;
    }

    return (
        <>
            <h1 className="top30">New Invitation</h1>
            <Row>
                <div className="col-md-4">
                    <SendInvitationForm organisationId={organisationId} />
                </div>
            </Row>
        </>
    );
};

export default InvitationPage;
