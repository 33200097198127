import { FormikProps } from "formik";
import React from "react";
import { SelectOptionItem } from "../../../common/types/reactSelect/SelectOptionItem";
import { ProcessTemplateListItem } from "../../process-template/domain/types";
import { FormValues } from "../containers/ProcessCreateForm";

export interface DateTimeWrapperProps {
    formik: FormikProps<FormValues>;
    processTemplates: ProcessTemplateListItem[];
}

export const DateTimeWrapper: React.FC<DateTimeWrapperProps> = ({
    formik,
    processTemplates,
    children,
}) => {
    const selectedTemplateOption = (
        formik.values["processTemplate"] as SelectOptionItem
    )?.value;

    const isScheduleEnabled = React.useMemo(() => {
        const selectedTemplateData = processTemplates?.find(
            (t) => t.id === selectedTemplateOption,
        );
        return selectedTemplateData?.features.scheduleEnabled.enabled;
    }, [processTemplates, selectedTemplateOption]);

    React.useEffect(() => {
        if (!isScheduleEnabled && isScheduleEnabled != null) {
            formik.setFieldValue("scheduledStart", null);
            formik.setFieldValue("scheduledEnd", null);
        }
    }, [isScheduleEnabled]);

    return isScheduleEnabled === true ? <>{children}</> : null;
};
