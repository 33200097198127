import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HttpMethod } from "../../common/http/HttpMethod";
import { ConfigModel } from "../../common/types/ConfigModel";

export const configApi = createApi({
    reducerPath: "configApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `/`,
        async prepareHeaders(headers) {
            headers.set("Accept", "application/json");
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getConfig: builder.query<ConfigModel, void>({
            query: () => ({
                url: `config.json`,
                method: HttpMethod.Get,
            }),
        }),
    }),
});

export const { useGetConfigQuery } = configApi;
