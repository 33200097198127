import { isArray } from "lodash";
import {
    TranslateQueryKeyFunc,
    TranslateTagFunc,
} from "../../../../../query/configureQuery";
import { Tags } from "../../../../../store/features/dcpApi";
import { ApprovedExamsKey } from "./approvedExamsQueryKeys";

const tagsToQueryKeys: TranslateTagFunc = (tag) => {
    if (tag.type === Tags.Batch) {
        return ApprovedExamsKey;
    } else {
        return null;
    }
};

const queryKeysToTags: TranslateQueryKeyFunc = (queryKey, ids) => {
    if (
        queryKey === ApprovedExamsKey ||
        (isArray(queryKey) && queryKey.includes(ApprovedExamsKey))
    ) {
        return [
            { type: Tags.ProcessActivitiesForProcess, id: ids[0] },
            { type: Tags.Process, id: ids[0] },
            { type: Tags.ProcessList },
        ];
    } else {
        return null;
    }
};

export const approvedExamsQueryKeysTranslator = {
    tagsToQueryKeys,
    queryKeysToTags,
};
