import * as React from "react";
import Breadcrumbs from "../../../../../common/components/breadcrumbs/Breadcrumbs";
import { useOrganisationId } from "../../../../organisation/hooks/useOrganisationId";
import pageTitles from "./pageTitles";
import ExamManagementInfiniteGrid from "../components/ExamManagementInfiniteGrid";

const ExamManagementTool: React.FC = () => {
    const { organisationId } = useOrganisationId();

    return (
        <>
            <div className="pt-2">
                <Breadcrumbs
                    items={[[pageTitles.examManagement]]}
                ></Breadcrumbs>
            </div>

            <h1>{pageTitles.examManagement}</h1>
            <ExamManagementInfiniteGrid
                organisationId={organisationId}
            ></ExamManagementInfiniteGrid>
        </>
    );
};

export default ExamManagementTool;
