interface CrudPermissions {
    baseName: string;
    read: string;
    create: string;
    update: string;
    delete: string;
    copy: string;
    displayNameUpdateOwn: string;
    displayNameUpdateOthers: string;
}

export function crudPermissions(...name: string[]): CrudPermissions {
    const base = name.join("/");
    return {
        baseName: base,
        create: base + "/create",
        read: base + "/read",
        update: base + "/update",
        delete: base + "/delete",
        copy: base + "/copy",
        displayNameUpdateOwn: base + "/self/display-name/update",
        displayNameUpdateOthers: base + "/other/display-name/update",
    };
}

export function withMoveOperation<T extends CrudPermissions>(
    permissions: T,
): T & { move: string } {
    return Object.assign({}, permissions, {
        move: permissions.baseName + "/move",
    });
}

export function withFavoriteOperation<T extends CrudPermissions>(
    permissions: T,
): T & { favorite: string } {
    return Object.assign({}, permissions, {
        favorite: permissions.baseName + "/favorite",
    });
}
