import consumerApiService from "../../../../customization/api/consumerApiService";
import { getData, postFormData } from "../../../../helpers/ApiHelper";
import { ExaminationsMassRemoval } from "../domain/examinationsMassRemoval.types";

enum UrlPaths {
    BaseResource = "organisation",
    ExaminationsMassRemoval = "ExaminationsMassRemoval",
    DownloadTemplate = "DownloadTemplate",
}

interface GetMassRemovalsArgs {
    organisationId: string;
}

const getBaseUrl = () =>
    `${consumerApiService.baseApiUrl}/${UrlPaths.BaseResource}`;

export async function getExaminationMassRemovals({
    organisationId,
}: GetMassRemovalsArgs): Promise<ExaminationsMassRemoval[]> {
    const url = `${getBaseUrl()}/${organisationId}/${
        UrlPaths.ExaminationsMassRemoval
    }`;
    const response = await getData<ExaminationsMassRemoval[]>(url);

    if (!response) return;

    return response;
}

export interface UploadMassRemovalsArgs {
    organisationId: string;
    file: File;
}

export async function uploadExaminationMassRemoval({
    organisationId,
    file,
}: UploadMassRemovalsArgs): Promise<void> {
    const url = `${getBaseUrl()}/${organisationId}/${
        UrlPaths.ExaminationsMassRemoval
    }`;
    const body = new FormData();
    body.append("file", file);

    await postFormData<void>(url, body);
}
