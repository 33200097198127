import { useFormikContext } from "formik";
import * as React from "react";
import { Button, ButtonToolbar } from "react-bootstrap";

interface FormEditButtonToolbarProps {
    isEdit: boolean;
    onEdit: () => void;
    onCancel: () => void;
}

const FormEditButtonToolbar: React.FC<FormEditButtonToolbarProps> = ({
    isEdit,
    onCancel,
    onEdit,
}) => {
    const { isSubmitting, resetForm } = useFormikContext();

    const handleCancel = React.useCallback(() => {
        onCancel();
        resetForm();
    }, [onCancel, resetForm]);

    if (isEdit)
        return (
            <ButtonToolbar
                key="btn-update-panel"
                className="justify-content-end"
            >
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                    Update
                </Button>
                <Button
                    type="button"
                    variant="outline-secondary"
                    className="ml-1"
                    disabled={isSubmitting}
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
            </ButtonToolbar>
        );

    return (
        <ButtonToolbar key="btn-edit-panel" className="justify-content-end">
            <Button variant="primary" type="button" onClick={onEdit}>
                Edit
            </Button>
        </ButtonToolbar>
    );
};

export default FormEditButtonToolbar;
