import React from "react";
import { Card } from "react-bootstrap";
import { useNavigation } from "../../../router/useNavigate";
import {
    Approval,
    ApprovalStatus,
    ProcessActivityApproval,
} from "../domain/types";
import { RedirectResponse } from "../store/processActivityApprovalApiDtos";
import {
    useApproveByApprovalIdMutation,
    useRejectByApprovalIdMutation,
    useResetByApprovalIdMutation,
} from "../store/processActivityApprovalApiSlice";
import ApprovalResetForm from "./ApprovalResetForm";
import ApproveRejectForm from "./ApproveRejectForm";

interface Props {
    activity: ProcessActivityApproval;
    approval: Approval;
    title: string;
}

const ApprovalWrapper: React.FC<Props> = ({ activity, approval, title }) => {
    const [approve, approveResult] = useApproveByApprovalIdMutation();
    const [reject, rejectResult] = useRejectByApprovalIdMutation();
    const [reset, resetResult] = useResetByApprovalIdMutation();
    const { navigateToOrgPath: navigateToPath } = useNavigation();

    const hasAlreadyApproved = React.useMemo(
        () =>
            approval.status !== ApprovalStatus.New &&
            approval.status !== ApprovalStatus.Pending,
        [approval.status],
    );

    const resetForm = (
        <ApprovalResetForm
            activityId={activity.id}
            approval={approval}
            reset={{
                actionCallback: reset,
                actionResult: resetResult,
            }}
        />
    );

    const onApprove = async (arg: {
        activity: ProcessActivityApproval;
        approvalId: string;
        comment: string;
    }) => {
        const result = (await approve(arg)) as { data: RedirectResponse };
        if (result?.data?.redirectUrl) {
            onNavigate(result.data.redirectUrl);
        }
    };

    const onReject = async (arg: {
        activity: ProcessActivityApproval;
        approvalId: string;
        comment: string;
    }) => {
        const result = (await reject(arg)) as { data: RedirectResponse };
        if (result?.data?.redirectUrl) {
            onNavigate(result.data.redirectUrl);
        }
    };
    const onNavigate = React.useCallback(
        (path: string) => {
            navigateToPath(`/:orgShortName/${path}`);
        },
        [navigateToPath],
    );

    const responseForm = (
        <ApproveRejectForm
            activity={activity}
            approval={approval}
            approve={{
                actionCallback: onApprove,
                actionResult: approveResult,
                actionDisplayName: activity.approvalActionDisplayName,
            }}
            reject={{
                actionCallback: onReject,
                actionResult: rejectResult,
                actionDisplayName: activity.rejectActionDisplayName,
            }}
        />
    );

    return (
        <Card>
            <Card.Body>
                <Card.Title>{title ?? "Approval Response"}</Card.Title>
                {hasAlreadyApproved ? resetForm : responseForm}
            </Card.Body>
        </Card>
    );
};

export default ApprovalWrapper;
