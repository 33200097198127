export function simulateHtmlEvent<T extends EventTarget>(
    element: T,
    eventType: string,
    eventPostProcessing?: (ev: Event) => void,
): void {
    const ev = document.createEvent("HTMLEvents");
    eventPostProcessing?.(ev);
    ev.initEvent(eventType, true, false);
    element.dispatchEvent(ev);
}
