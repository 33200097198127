import produce from "immer";
import { Action, Reducer } from "redux";
import {
    ProcessTemplateUiTabs,
    UiMode,
} from "../../modules/process-template/domain/types";
import {
    ProcessTemplateRequestTemplatesSuccess,
    PROCESS_TEMPLATE_REQUEST_TEMPLATES_START,
    PROCESS_TEMPLATE_REQUEST_TEMPLATES_SUCCESS,
} from "./actions";
import {
    KnownAction,
    ProcessTemplateState,
    ReceiveProcessTemplateFormAction,
    RECEIVE_PROCESS_TEMPLATE_FORM,
    REQUEST_PROCESS_TEMPLATE_FORM,
} from "./store";

export const unloadedState: ProcessTemplateState = {
    domain: {
        template: {
            id: "",
            name: "",
            description: "",
            config: "",
            states: [],
            auditActions: [],
            organisationId: "",
            lastUpdatedAt: "",
            version: 0,
            propertyConfig: [],
            features: { userAssignment: {}, scheduleEnabled: {} },
        },
        templates: [],
    },
    application: {
        selectRoleId: "",
    },
    ui: {
        tabs: {
            [ProcessTemplateUiTabs.ProcessTab]: {
                mode: UiMode.VIEWING,
            },
            [ProcessTemplateUiTabs.HistoryTab]: {
                mode: UiMode.VIEWING,
            },
            [ProcessTemplateUiTabs.UserRolesTab]: {
                mode: UiMode.VIEWING,
            },
        },
    },
};

const handlers = {} as Record<
    string,
    (state: ProcessTemplateState, action: unknown) => ProcessTemplateState
>;

handlers[REQUEST_PROCESS_TEMPLATE_FORM] = (
    state: ProcessTemplateState,
): ProcessTemplateState => {
    return produce(state, (newState) => {
        newState.ui.tabs = { ...unloadedState.ui.tabs };
    });
};

handlers[RECEIVE_PROCESS_TEMPLATE_FORM] = (
    state: ProcessTemplateState,
    action: ReceiveProcessTemplateFormAction,
): ProcessTemplateState => {
    return produce(state, (newState) => {
        newState.domain.template = action.data;
    });
};

handlers[PROCESS_TEMPLATE_REQUEST_TEMPLATES_START] = (
    state: ProcessTemplateState,
): ProcessTemplateState => {
    return produce(state, (newState) => {
        newState.domain.templates = [];
    });
};

handlers[PROCESS_TEMPLATE_REQUEST_TEMPLATES_SUCCESS] = (
    state: ProcessTemplateState,
    action: ProcessTemplateRequestTemplatesSuccess,
): ProcessTemplateState => {
    return produce(state, (newState) => {
        newState.domain.templates = action.data;
    });
};

export const reducer: Reducer<ProcessTemplateState> = (
    state: ProcessTemplateState | undefined,
    incomingAction: Action,
): ProcessTemplateState => {
    if (!state) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    const handler = handlers[action.type];
    if (handler) {
        return handler(state, action);
    }
    return state;
};
