import * as React from "react";
import { Button } from "react-bootstrap";
import CoveringLoadingPanel from "../../../../../common/components/CoveringLoadingPanel";
import { HttpMethod } from "../../../../../common/http/HttpMethod";
import PermissionConstants from "../../../../../common/permissions/PermissionConstants";
import useConsumerApiSettings from "../../../../../customization/api/useConsumerApiSettings";
import { DownloadFile } from "../../../../../helpers/ApiHelper";
import { hasUserOrgPermission } from "../../../../../store/features/user/user-api-slice";
import { useGetExaminationBulkUpdates } from "../../query/examinationsBulkUpdatesQueries";
import ExaminationBulkUpdaterGrid from "./ExaminationBulkUpdaterGrid";
import ExaminationBulkUpdaterUploadDialog from "./ExaminationBulkUpdaterUploadDialog";

interface Props {
    organisationId: string;
}

const ExaminationBulkUpdaterActions: React.FC<Props> = ({ organisationId }) => {
    const [showUploadDialog, setShowUploadDialog] = React.useState(false);

    const canUpload = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgExaminationsBulkUpdate.create,
    ).hasPermission;

    const { data, isFetching } = useGetExaminationBulkUpdates(organisationId);

    const { baseApiUrl } = useConsumerApiSettings();

    const handleUploadSubmit = React.useCallback(async (): Promise<void> => {
        await DownloadFile({
            method: HttpMethod.Get,
            url: `${baseApiUrl}/organisation/${organisationId}/ExaminationsBulkUpdater/DownloadTemplate`,
        });
    }, [organisationId, baseApiUrl]);

    return (
        <>
            {canUpload && (
                <Button
                    className="ml-1"
                    variant="primary"
                    onClick={() => setShowUploadDialog(true)}
                >
                    Upload
                </Button>
            )}
            <Button
                className="ml-1"
                variant="outline-secondary"
                onClick={handleUploadSubmit}
            >
                Download Template
            </Button>
            {isFetching && <CoveringLoadingPanel />}
            {!isFetching && data && (
                <div className="pt-2">
                    <ExaminationBulkUpdaterGrid
                        organisationId={organisationId}
                        bulkUpdates={data}
                    />
                </div>
            )}
            {showUploadDialog && (
                <ExaminationBulkUpdaterUploadDialog
                    onClose={() => setShowUploadDialog(false)}
                    organisationId={organisationId}
                />
            )}
        </>
    );
};

export default ExaminationBulkUpdaterActions;
