import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

interface BreadcrumbItemProps {
    active: boolean;
    linkTo: string;
    onClick?: () => void;
    children?: React.ReactNode;
}

export const BreadCrumbItem: React.FC<BreadcrumbItemProps> = ({
    active,
    linkTo,
    onClick,
    children,
}) => {
    const suppliedClick = (
        <a
            href="#"
            onClick={(ev) => {
                ev.preventDefault();
                onClick();
            }}
        >
            {children}
        </a>
    );

    const suppliedOrDefault = onClick ? (
        suppliedClick
    ) : (
        <Link to={linkTo}>{children}</Link>
    );

    const content = active ? children : suppliedOrDefault;

    return (
        <Breadcrumb.Item active={active} linkAs={"span"}>
            {content}
        </Breadcrumb.Item>
    );
};
