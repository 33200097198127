import * as React from "react";
import { Col, Row } from "react-bootstrap";
import ProcessTemplateList from "./ProcessTemplatesList";

const ProcessTemplateManagement: React.FC = () => {
    return (
        <Row>
            <Col>
                <Row className="top10">
                    <Col>
                        <ProcessTemplateList></ProcessTemplateList>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default ProcessTemplateManagement;
