import * as React from "react";
import { Permission } from "../../../organisation/domain/types";
import useGridProps from "../../../../common/components/table/kendo/useGridProps";
import {
    ContainsMenuFilter,
    emptyFilterDescriptor,
} from "../../../../common/components/table/kendo/columnFilters";
import { Grid } from "@progress/kendo-react-grid";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import UserGroupPermissionsTableActions from "./UserGroupPermissionsTableActions";

interface Props {
    orgId: string;
    userGroupId: string;
    permissions: Permission[];
    canRemovePermissions: boolean;
    isFetching: boolean;
    isLoading: boolean;
}

const UserGroupPermissionsTable: React.FC<Props> = (props) => {
    const { canRemovePermissions, orgId, userGroupId } = props;
    const PermissionCommandCell = React.useCallback(
        (props) => (
            <td>
                <UserGroupPermissionsTableActions
                    orgId={orgId}
                    userGroupId={userGroupId}
                    canRemovePermissions={canRemovePermissions}
                    permission={props.dataItem}
                />
            </td>
        ),
        [orgId, userGroupId, canRemovePermissions],
    );

    const { gridProps } = useGridProps(props.permissions, {
        filterable: true,
        initialFilter: emptyFilterDescriptor,
        sortable: true,
        initialSort: [{ field: "name", dir: "asc" }],
        pageable: true,
    });

    return (
        <Grid {...gridProps}>
            <Column
                field="name"
                title="Permission"
                headerClassName="grid-header"
                columnMenu={ContainsMenuFilter}
            />
            <Column
                field="actions"
                title="Actions"
                cell={PermissionCommandCell}
                width="200px"
            />
        </Grid>
    );
};

export default UserGroupPermissionsTable;
