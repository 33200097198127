import React from "react";

/**
 * The covering panel recommended by Telerik for the KendoReact Grid (and more).
 *
 * official docs: https://www.telerik.com/kendo-react-ui/knowledge-base/show-loading-indicator-in-the-kendoreact-grid/
 */
const CoveringLoadingPanel: React.FC<{ text?: string }> = ({ text }) => (
    <div className="k-loading-mask">
        <span className="k-loading-text">Loading</span>
        {text && <div>{text}</div>}
        <div className="k-loading-image" />
        <div className="k-loading-color" />
    </div>
);

export default CoveringLoadingPanel;
