import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import * as Formik from "formik";
import { ErrorMessage } from "formik";
import * as React from "react";
import { Button, Form } from "react-bootstrap";
import { Input } from "reactstrap";
import * as yup from "yup";
import CoveringLoadingPanel from "../../../../../common/components/CoveringLoadingPanel";
import { PlanningInfoContentFieldNames } from "../../../constants/ProcessActivityContentFieldsConsts";
import { useUploadMissingCarrsExamMutation } from "../queries/missingCarrsExamQueries";

interface Props {
    organisationId: string;
    onClose: () => void;
}

interface MissingCarrsExamFileFormValues {
    missingCarrsExamFile: File;
}

const MissingCarrsExamToolUploadDialog: React.FC<Props> = ({
    organisationId,
    onClose,
}) => {
    const initialFormValues: MissingCarrsExamFileFormValues = {
        missingCarrsExamFile: null,
    };

    const mutation = useUploadMissingCarrsExamMutation(organisationId);

    const validation = yup.object({
        missingCarrsExamFile: yup.mixed().required("Required"),
    });

    const handleUploadSubmit = async ({
        missingCarrsExamFile,
    }: MissingCarrsExamFileFormValues): Promise<void> => {
        await mutation.mutateAsync(missingCarrsExamFile);
        onClose();
    };

    return (
        <Dialog
            title="Missing Carrs Exam Upload"
            onClose={() => onClose()}
            width={400}
        >
            {mutation.isLoading && <CoveringLoadingPanel />}
            <Formik.Formik
                initialValues={initialFormValues}
                validationSchema={validation}
                onSubmit={handleUploadSubmit}
            >
                {({ setFieldValue, touched: touch, errors }) => (
                    <Formik.Form>
                        <Form.Group className="py-4">
                            <ErrorMessage
                                name={"missingCarrsExamFile"}
                                className="invalid-feedback"
                                component="div"
                            />
                            <Input
                                type="file"
                                name="missingCarrsExamFile"
                                className={
                                    touch.missingCarrsExamFile &&
                                    errors.missingCarrsExamFile
                                        ? "is-invalid"
                                        : ""
                                }
                                role="button"
                                onChange={(e) => {
                                    setFieldValue(
                                        PlanningInfoContentFieldNames.MissingCarrsExamFile,
                                        e.target.files[0],
                                    );
                                }}
                            />
                        </Form.Group>
                        <DialogActionsBar layout="end">
                            <div className="pt-3">
                                <Button
                                    variant="link"
                                    onClick={() => onClose()}
                                >
                                    Back
                                </Button>
                                <Button variant="primary" type="submit">
                                    Confirm
                                </Button>
                            </div>
                        </DialogActionsBar>
                    </Formik.Form>
                )}
            </Formik.Formik>
        </Dialog>
    );
};

export default MissingCarrsExamToolUploadDialog;
