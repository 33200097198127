import React, { useMemo } from "react";
import { useParams } from "react-router";
import BridgeConditionIndexSpeedComponent from "../components/bci-components/BridgeConditionIndexSpeedComponent";
import { subsetConfigs } from "./orgReportsBciConfigs";

const OrgReportsBciGaugePage: React.FC = () => {
    const { orgShortName } = useParams<{ orgShortName: string }>();

    const getOrgSpecificCharts = useMemo(() => {
        return subsetConfigs[orgShortName]?.flatMap((config) => {
            return [
                <BridgeConditionIndexSpeedComponent
                    key={`${config.bciConfigName}`}
                    title={`Overall Bridge Condition Index for ${config.displayName}`}
                    bciConfigName={config.bciConfigName}
                />,
            ];
        });
    }, [orgShortName]);

    return (
        <div>
            <h1>BCI Gauges</h1>
            <BridgeConditionIndexSpeedComponent
                title="Overall Bridge Condition Index"
                bciConfigName={orgShortName + "-bridge"}
            />
            {getOrgSpecificCharts}
        </div>
    );
};

export default OrgReportsBciGaugePage;
