import * as React from "react";
import { Nav } from "react-bootstrap";
import { IconType } from "react-icons";
import { NavGroupLink } from "../NavGroupLink";
import NavSubMenuItem, { NavSubLink } from "./NavSubMenuItem";

interface NavGroupLinkWithMenuProps {
    label: string;
    icon: IconType;
    eventKey: string;
    subLinks: NavSubLink[];
}

const NavGroupWithMenu = ({
    label,
    icon,
    subLinks,
    eventKey,
}: NavGroupLinkWithMenuProps): JSX.Element => {
    const [isExpanded, setIsExpanded] = React.useState(false);

    return (
        <>
            <Nav.Item onClick={() => setIsExpanded(!isExpanded)}>
                <NavGroupLink label={label} icon={icon} eventKey={eventKey} />
            </Nav.Item>

            {isExpanded && (
                <>
                    {subLinks.length > 0 && <hr className="m-0" />}
                    {subLinks.map((subItem) => (
                        <NavSubMenuItem key={subItem.label} {...subItem} />
                    ))}
                    {subLinks.length > 0 && <hr className="m-0" />}
                </>
            )}
        </>
    );
};

export default NavGroupWithMenu;
