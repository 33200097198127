import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React from "react";
import { Figure } from "react-bootstrap";
import { getActivityFileResUrl } from "../../../../process-activity/helpers/urlBuilder";
import { getFormFile } from "../../../../../services/FileDownloadService";
import { PhotoOrder } from "../../types";

const Detailrow: React.FC<GridDetailRowProps> = (props: GridDetailRowProps) => {
    const data: PhotoOrder = props.dataItem;

    const [fileBlob, setFileBlob] = React.useState(new Blob([]));

    const url = React.useMemo(() => {
        return getActivityFileResUrl(data.activityId)(data.fileId) + "";
    }, [data]);

    React.useEffect(() => {
        if (url) {
            getFormFile(url)
                .then((a) => {
                    setFileBlob(a);
                })
                .catch(() => {
                    console.error("Failed to fetch img");
                    setFileBlob(new Blob([]));
                });
        } else {
            setFileBlob(new Blob([]));
        }
    }, [url]);

    if (
        data.fileName.toLowerCase().endsWith("png") ||
        data.fileName.toLowerCase().endsWith("jpeg") ||
        data.fileName.toLowerCase().endsWith("jpg") ||
        data.fileName.toLowerCase().endsWith("gif")
    ) {
        return (
            <Figure className="mb-0">
                <Figure.Image
                    className="mb-0"
                    src={URL.createObjectURL(fileBlob)}
                />
            </Figure>
        );
    } else {
        return <strong>This File Type is not valid for previewing.</strong>;
    }
};

export default Detailrow;
