import * as React from "react";
import InfiniteScroll from "react-infinite-scroller";
import InlineCenteredLoader from "./InlineCenteredLoader";

interface Props {
    fetchNextPage: () => void;
    hasNextPage: boolean;
}
const InfiniteScrollWrapper: React.FC<Props> = ({
    fetchNextPage,
    hasNextPage,
    children,
}) => {
    return (
        <InfiniteScroll
            pageStart={0}
            loadMore={() => fetchNextPage()}
            hasMore={hasNextPage}
            loader={<InlineCenteredLoader />}
        >
            {children}
        </InfiniteScroll>
    );
};

export default InfiniteScrollWrapper;
