import {
    GridFieldsMap,
    GridFieldType,
} from "../../../common/components/table/kendo/GridFieldsMap";

export const gridFieldsMap: GridFieldsMap = {
    name: {
        field: "name",
        label: "Name",
        dataType: "string",
        fieldType: GridFieldType.Text,
    },
    templateName: {
        field: "templateName",
        label: "Type",
        dataType: "string",
        fieldType: GridFieldType.Text,
    },
    assignedToName: {
        field: "assignedToName",
        label: "Assigned to",
        dataType: "string",
        fieldType: GridFieldType.Text,
    },
    state: {
        field: "state",
        label: "State",
        dataType: "string",
        fieldType: GridFieldType.Text,
    },
};

export const gridFilterOperators = {
    text: [
        {
            text: "grid.filterContainsOperator",
            operator: "contains",
        },
    ],
    date: [
        {
            text: "grid.filterEqOperator",
            operator: "eq",
        },
    ],
};
