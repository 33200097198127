import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import * as Formik from "formik";
import React, { ChangeEventHandler, FocusEventHandler } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Process, ProcessCopyCreate } from "../domain/types";

interface Errors {
    [key: string]: string;
}

interface BuildFormContent {
    handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
    handleChange: ChangeEventHandler<HTMLInputElement>;
    handleBlur: FocusEventHandler<HTMLInputElement>;
    values: ProcessCopyCreate;
    touched: Formik.FormikTouched<ProcessCopyCreate>;
    errors: Formik.FormikErrors<ProcessCopyCreate>;
    props: React.PropsWithChildren<Props>;
    isSubmitting: boolean;
}

interface Props {
    process: Process;
    onBack: () => void;
    onConfirm: (processCopy: ProcessCopyCreate) => void;
}

export const ProcessCopyDialog: React.FC<Props> = (props) => {
    const validate = (values: ProcessCopyCreate) => {
        const errors: Errors = {};

        if (!values.name) {
            errors.name = "Required";
        }

        if (values.scheduledEnd && values.scheduledStart) {
            if (values.scheduledEnd <= values.scheduledStart)
                errors.scheduledEnd =
                    "Scheduled End must be after Scheduled Start";
        }

        return errors;
    };

    return (
        <Row>
            <Col>
                <Formik.Formik
                    enableReinitialize
                    initialValues={{
                        id: null,
                        name: props.process.name,
                        scheduledStart: props.process.scheduledStart,
                        scheduledEnd: props.process.scheduledEnd,
                        includeFormContent: false,
                    }}
                    validate={validate}
                    onSubmit={async (
                        values: ProcessCopyCreate,
                        { setSubmitting },
                    ) => {
                        setSubmitting(true);
                        props.onConfirm(values);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                    }) =>
                        createFormContent({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            errors,
                            props,
                            isSubmitting,
                        })
                    }
                </Formik.Formik>
            </Col>
        </Row>
    );
};

function createFormContent(data: BuildFormContent): JSX.Element {
    return (
        <Formik.Form onSubmit={data.handleSubmit}>
            <Form.Group controlId="processName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Name"
                    name="name"
                    onChange={data.handleChange}
                    onBlur={data.handleBlur}
                    value={data.values.name}
                    className={
                        data.touched.name && data.errors.name
                            ? "process-control is-invalid"
                            : "process-control"
                    }
                />
                {data.errors.name ? (
                    <div className="invalid-feedback">{data.errors.name}</div>
                ) : null}
            </Form.Group>
            <Form.Group controlId="scheduledStart">
                <Form.Label>Scheduled Start</Form.Label>
                <Form.Control
                    type="datetime-local"
                    name="scheduledStart"
                    onChange={data.handleChange}
                    onBlur={data.handleBlur}
                    value={data.values.scheduledStart?.toString() || ""}
                    className={data.errors.scheduledStart ? "is-invalid" : ""}
                />
                {data.errors.scheduledStart ? (
                    <div className="invalid-feedback">
                        {data.errors.scheduledStart}
                    </div>
                ) : null}
            </Form.Group>
            <Form.Group controlId="scheduledEnd">
                <Form.Label>Scheduled End</Form.Label>
                <Form.Control
                    type="datetime-local"
                    name="scheduledEnd"
                    onChange={data.handleChange}
                    onBlur={data.handleBlur}
                    value={data.values.scheduledEnd?.toString() || ""}
                    className={data.errors.scheduledEnd ? "is-invalid" : ""}
                />
                {data.errors.scheduledEnd ? (
                    <div className="invalid-feedback">
                        {data.errors.scheduledEnd}
                    </div>
                ) : null}
            </Form.Group>
            <Form.Group>
                <Form.Check
                    type="checkbox"
                    name="includeFormContent"
                    label="Should include form data?"
                    className="form-checkbox"
                    value={data.values.includeFormContent.toString()}
                    onChange={data.handleChange}
                    onBlur={data.handleBlur}
                />
            </Form.Group>

            <DialogActionsBar layout="end">
                <Button variant="link" onClick={() => data.props.onBack()}>
                    Back
                </Button>
                <Button
                    variant="primary"
                    type="submit"
                    disabled={data.isSubmitting}
                >
                    Save
                </Button>
            </DialogActionsBar>
        </Formik.Form>
    );
}
