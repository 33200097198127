import * as React from "react";
import useGridProps from "../../table/kendo/useGridProps";
import {
    ContainsMenuFilter,
    emptyFilterDescriptor,
} from "../../table/kendo/columnFilters";
import { Grid, GridCellProps } from "@progress/kendo-react-grid";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import GridLoadingPanel from "../../table/kendo/GridLoadingPanel";
import { User } from "../../../../modules/organisation/domain/organisation";
import { UserGroup } from "../../../../modules/user-management/domain/types";
import UserGroupsTableActions from "./UserGroupsTableActions";

interface Props {
    orgId: string;
    user: User;
    userGroups: UserGroup[];
    refetchUserGroups: () => object;
    isLoading: boolean;
    setCanCloseDialog: (value: boolean) => void;
}

const UserGroupTable: React.FC<Props> = ({
    orgId,
    user,
    userGroups,
    refetchUserGroups,
    isLoading,
    setCanCloseDialog,
}) => {
    const UserGroupCommandCell = React.useCallback(
        (props: GridCellProps) => (
            <td>
                <UserGroupsTableActions
                    orgId={orgId}
                    user={user}
                    userGroup={props.dataItem}
                    refetchUserGroups={refetchUserGroups}
                    setCanCloseDialog={setCanCloseDialog}
                />
            </td>
        ),
        [orgId, user, refetchUserGroups, setCanCloseDialog],
    );
    const wrapperRef = React.createRef<HTMLElement>();

    const { gridProps } = useGridProps(userGroups, {
        filterable: true,
        initialFilter: emptyFilterDescriptor,
        sortable: true,
        initialSort: [{ field: "username", dir: "asc" }],
        pageable: true,
    });

    return (
        <>
            <Grid {...gridProps} className="table-h">
                <Column
                    field="name"
                    title="Group"
                    headerClassName="grid-header"
                    columnMenu={ContainsMenuFilter}
                />
                <Column
                    field="actions"
                    title="Actions"
                    cell={UserGroupCommandCell}
                />
            </Grid>
            {isLoading && <GridLoadingPanel gridRef={wrapperRef} />}
        </>
    );
};

export default UserGroupTable;
