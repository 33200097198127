import bsCustomFileInput from "bs-custom-file-input";
import { FieldArray, Formik, FormikProps, useFormikContext } from "formik";
import * as React from "react";
import { useState } from "react";
import { Button, Form, FormGroup, FormLabel } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import {
    ShowCondition,
    TemplateContent,
    CefaTemplateContentFileRecord,
    TemplateContentStyle,
    YupValidationConfig,
} from "../../../../../template/domain/types";
import FormControlBase from "../../../../../../common/components/dynamic-form/FormControlBase";
import { generateYupForSubForm } from "../../../../../../common/components/dynamic-form/yupValidation/YupValidation";
import CefaFormControlFileTable from "./CefaFormControlFileTable";
import ExifReader from "exifreader";
import moment from "moment";
import CefaConstants from "../../../../constants/CefaConstants";
import { formatCefaComment } from "../../../helpers/commentHelper";
import { useGetExaminationReview } from "../../../../examinations/query/examinationsReviewQueries";
import { useOrganisationId } from "../../../../../organisation/hooks/useOrganisationId";

interface FormControlMediaConfig {
    field: string;
    label: string;
    style: TemplateContentStyle;
    prompt: string;
    uri: string;
    isReadOnly: boolean;
    files: CefaTemplateContentFileRecord[];
    validationsFile: YupValidationConfig;
    validationsComment: YupValidationConfig;
    mediaOnly: boolean;
    multiple: boolean;
    showConditions: ShowCondition[];
}

interface Props {
    config: FormControlMediaConfig;
    processId: string;
}

interface FileInfo {
    mediaFiles: File[];
    mediaComment: string;
}

function generateFileSubFieldContents(
    mediaFieldConfig: FormControlMediaConfig,
) {
    return {
        mediaFiles: {
            field: "mediaFiles",
            label: "Files",
            validationType: mediaFieldConfig.validationsFile.validationType,
            validations: [
                ...mediaFieldConfig.validationsFile.validations,
                // Add in any permanent validation rules here
                ...[
                    {
                        type: "fileNumberLimit",
                        params: [
                            `Please select between 1 and ${CefaConstants.MultiSelectFileLimit} files`,
                            CefaConstants.MultiSelectFileLimit,
                        ],
                    },
                ],
            ],
        } as TemplateContent,
        mediaComment: {
            field: "mediaComment",
            label: "Comment",
            validationType: mediaFieldConfig.validationsComment.validationType,
            validations: mediaFieldConfig.validationsComment.validations,
        } as TemplateContent,
    };
}

async function getFileDetails(
    file: File,
    mediaComment: string,
    examDate?: string,
) {
    let captionDate = "";

    // Get the date from the file for use as the caption date
    try {
        const tags = await ExifReader.load(file);
        const imageDate = tags["DateTimeOriginal"]?.description;
        const momentDate = moment(imageDate, "YYYY:MM:DD HH:mm:ss");
        const imageDateString = momentDate.isValid()
            ? momentDate.toISOString(true)
            : null;
        captionDate = imageDateString;
    } catch {
        // do nothing
    }

    // If the file didn't have a date, use the exam date, if available
    if (!captionDate && examDate) {
        const momentDate = moment(examDate);
        captionDate = momentDate.isValid()
            ? momentDate.toISOString(true)
            : null;
    }

    // If there's still no caption date, use today's date
    captionDate = captionDate ?? moment().toISOString(true);

    const fileBasename = file.name.replace(new RegExp(/\.[^/.]+$/), "");
    const fileComment = mediaComment.replaceAll(
        CefaConstants.TemplateValueFilename,
        fileBasename,
    );

    const fileInfo: CefaTemplateContentFileRecord = {
        uuid: uuidv4(),
        id: uuidv4(), // This is a temporary id to allow the grid to work correctly for new files
        comment: formatCefaComment(fileComment, captionDate),
        captionDate: captionDate,
        fileName: file.name,
        localFile: file,
        size: file.size,
        storageName: "",
        createdOn: "",
        newFile: true,
    };

    return fileInfo;
}

const CefaFormControlFile: React.FC<Props> = (props) => {
    const { config, processId } = props;
    const { organisationId } = useOrganisationId();
    const formik = useFormikContext();
    const fieldsContents = React.useMemo(
        () => generateFileSubFieldContents(config),
        [config],
    );

    const formValidationSchema = React.useMemo(
        () =>
            generateYupForSubForm([
                fieldsContents.mediaFiles,
                fieldsContents.mediaComment,
            ]),
        [fieldsContents],
    );

    // This is a hack to reset the file input component after submission
    const [fileInputKey, setFileInputKey] = React.useState(Date.now());
    const [isAdding, setIsAdding] = useState(false);

    React.useEffect(() => {
        bsCustomFileInput.init();
    }, [isAdding]);

    const onClickAdd = () => {
        setIsAdding(true);
    };

    // Get exam date from the exam
    const { data: examination } = useGetExaminationReview(
        organisationId,
        processId,
    );
    const examDate = examination?.examinationDate
        ? examination?.examinationDate.toString()
        : "";

    const value = (formik.values[config.field] ||
        []) as CefaTemplateContentFileRecord[];

    const showAddButton =
        !isAdding &&
        !config.isReadOnly &&
        (config.multiple || value.length < 1);

    const mediaNote = `Note: ${CefaConstants.TemplateValueFilename} gets replaced with name of file`;

    return (
        <FormControlBase
            contentConfig={{
                field: config.field,
                showConditions: config.showConditions,
            }}
        >
            <FieldArray name={config.field}>
                {(arrayHelpers) => (
                    <>
                        <FormLabel>
                            <strong>{config.label}</strong>
                        </FormLabel>
                        {config.prompt && (
                            <Form.Text className="text-muted mb-2">
                                {config.prompt}
                            </Form.Text>
                        )}
                        {showAddButton && (
                            <div>
                                <Button size="sm" onClick={onClickAdd}>
                                    <FaPlus />
                                </Button>
                            </div>
                        )}
                        {isAdding && (
                            <Formik
                                validationSchema={formValidationSchema}
                                enableReinitialize={true}
                                initialValues={{
                                    mediaFiles: [] as File[],
                                    mediaComment:
                                        CefaConstants.DefaultMediaComment,
                                }}
                                onSubmit={(
                                    values: FileInfo,
                                    { resetForm },
                                ): void => {
                                    values.mediaFiles.forEach(
                                        async (file: File) => {
                                            const fileInfo =
                                                await getFileDetails(
                                                    file,
                                                    values.mediaComment,
                                                    examDate,
                                                );

                                            // Push to array with a created on date set so that sorting will make this new file
                                            // appear at the top of the list
                                            fileInfo.createdOn =
                                                new Date().toISOString();
                                            arrayHelpers.push(fileInfo);
                                        },
                                    );
                                    setFileInputKey(Date.now());
                                    resetForm();
                                    setIsAdding(false);
                                }}
                            >
                                {(formikProps: FormikProps<FileInfo>) => {
                                    return (
                                        <Form noValidate>
                                            <FormGroup>
                                                <Form.Label
                                                    htmlFor={"mediaFiles"}
                                                >
                                                    {
                                                        fieldsContents
                                                            .mediaFiles.label
                                                    }
                                                </Form.Label>
                                                <Form.File
                                                    id="formcheck-api-custom"
                                                    lang="en"
                                                    custom
                                                    className="mb-2 "
                                                    key={fileInputKey}
                                                >
                                                    <Form.File.Label
                                                        data-browse="Button text"
                                                        className={
                                                            formikProps.errors
                                                                .mediaFiles
                                                                ? "is-invalid form-control"
                                                                : ""
                                                        }
                                                    >
                                                        Select up to{" "}
                                                        {
                                                            CefaConstants.MultiSelectFileLimit
                                                        }{" "}
                                                        media files
                                                    </Form.File.Label>
                                                    <Form.File.Input
                                                        name={"mediaFiles"}
                                                        multiple
                                                        onChange={(
                                                            event: React.ChangeEvent<HTMLInputElement>,
                                                        ) => {
                                                            formikProps.setFieldValue(
                                                                "mediaFiles",
                                                                Array.from(
                                                                    event
                                                                        .currentTarget
                                                                        .files,
                                                                ),
                                                            );
                                                        }}
                                                        onBlur={
                                                            formikProps.handleBlur
                                                        }
                                                    />
                                                    {formikProps.errors
                                                        .mediaFiles && (
                                                        <div className="invalid-feedback display">
                                                            {
                                                                formikProps
                                                                    .errors
                                                                    .mediaFiles
                                                            }
                                                        </div>
                                                    )}
                                                </Form.File>
                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Label
                                                    htmlFor={"mediaComment"}
                                                >
                                                    {
                                                        fieldsContents
                                                            .mediaComment.label
                                                    }
                                                </Form.Label>
                                                <Form.Control
                                                    name={"mediaComment"}
                                                    onChange={(
                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                    ) => {
                                                        formikProps.setFieldValue(
                                                            "mediaComment",
                                                            event.target.value,
                                                        );
                                                    }}
                                                    value={
                                                        formikProps.values
                                                            .mediaComment
                                                    }
                                                    className={
                                                        formikProps.errors[
                                                            "mediaComment"
                                                        ]
                                                            ? "is-invalid"
                                                            : ""
                                                    }
                                                    // This section prevents the page from refreshing when pressing Enter.
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                />
                                                {formikProps.errors
                                                    .mediaComment && (
                                                    <div className="invalid-feedback display">
                                                        {
                                                            formikProps.errors
                                                                .mediaComment
                                                        }
                                                    </div>
                                                )}
                                            </FormGroup>
                                            <div>{mediaNote}</div>
                                            <div className="mt-4">
                                                <Button
                                                    disabled={
                                                        !formikProps.dirty ||
                                                        !formikProps.isValid
                                                    }
                                                    variant="primary"
                                                    onClick={() => {
                                                        formikProps.submitForm();
                                                    }}
                                                >
                                                    Attach
                                                </Button>
                                                <Button
                                                    variant="secondary"
                                                    className="ml-1"
                                                    onClick={() => {
                                                        formikProps.resetForm();
                                                        setIsAdding(false);
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        )}
                        <div className="mt-3">
                            <CefaFormControlFileTable
                                arrayHelpers={arrayHelpers}
                                config={config}
                                isValid={!formik.errors[config.field]}
                                readonly={config.isReadOnly}
                            />
                        </div>
                    </>
                )}
            </FieldArray>
        </FormControlBase>
    );
};

export default CefaFormControlFile;
