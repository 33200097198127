import * as React from "react";

interface State {
    hasError: boolean;
    error: string;
}

export default class ErrorBoundary extends React.Component {
    state: State;

    constructor(props: { children: React.ReactNode }) {
        super(props);
        this.state = { hasError: false, error: "" };
    }

    static getDerivedStateFromError(error: string): {
        hasError: true;
        error: string;
    } {
        // Update state so the next render will show the fallback UI.

        return { hasError: true, error: error };
    }

    componentDidCatch(error: { stack?: string }): void {
        this.setState({ error: error.stack });
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <p>{this.state.error}</p>;
        }

        // eslint-disable-next-line react/prop-types
        return this.props.children;
    }
}
