import { Card, Col, Row } from "react-bootstrap";
import React from "react";
import ReactSpeedometer from "react-d3-speedometer";

export interface Props {
    title: string;
    bciAvgScore: number;
    bciCritScore: number;
}

const BridgeComponentSpeedometer: React.FC<Props> = (props) => {
    const { bciAvgScore, bciCritScore, title } = props;
    const id = crypto.randomUUID();

    return (
        <div className="mt-2">
            <Card className="card d-flex flex-column">
                <Card.Header>
                    <p
                        className="h4"
                        style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                        {title}
                    </p>
                </Card.Header>
                <Card.Body
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Row>
                        <Col>
                            <p
                                style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                }}
                            >
                                BCI Average: {bciAvgScore}
                            </p>
                            <ReactSpeedometer
                                key={id + "avg"}
                                minValue={0}
                                maxValue={100}
                                value={bciAvgScore}
                                needleColor="orange"
                                startColor="red"
                                segments={10}
                                endColor="green"
                            />
                        </Col>
                        <Col>
                            <p
                                style={{
                                    textAlign: "center",
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                }}
                            >
                                BCI Critical: {bciCritScore}
                            </p>
                            <ReactSpeedometer
                                key={id + "crit"}
                                minValue={0}
                                maxValue={100}
                                value={bciCritScore}
                                needleColor="orange"
                                startColor="red"
                                segments={10}
                                endColor="green"
                            />
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer>
                    <small style={{ fontSize: "0.7em" }}>
                        BCI scores are valid only when all elements of the
                        bridge or structure have been inspected and logged in
                        the system
                    </small>
                </Card.Footer>
            </Card>
        </div>
    );
};

export default BridgeComponentSpeedometer;
