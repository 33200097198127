import { dcpApi, Tags } from "../../../store/features/dcpApi";
import {
    ActivityActionParams,
    PostNotApplicableParams,
} from "../../../store/features/process-activity/types";
import { BaseUser } from "../../organisation/domain/organisation";
import { ProcessActivity } from "../../process-activity/domain/types";
import { createActivityTags } from "../../process-activity/store/activityTag";
import { ProcessActivityApproval } from "../domain/types";
import {
    mapApproverToAddApproverRequest as mapUserToAddApproverRequest,
    mapCommentToApprovalRequest as mapCommentToApproveRequest,
    mapCommentToRejectRequest,
    mapDtoToModel,
    ProcessActivityApprovalDto,
    RedirectResponse,
} from "./processActivityApprovalApiDtos";

enum UrlPaths {
    BaseResourceName = "/processactivityapproval",
    NotApplicable = "/not-applicable",
    Open = "/open",

    Approvals = "/approvals",
    RequestApprovals = "/request",

    ApprovalApprove = "/approve",
    ApprovalReject = "/reject",
    ApprovalReset = "/reset",
    ApprovalResend = "/resend",
}

const activityApprovalTags = (
    activity: ProcessActivity,
    otherTags?: { type: Tags; id?: string }[],
) => createActivityTags(activity, otherTags);

export const processActivityApprovalApi = dcpApi.injectEndpoints({
    endpoints: (builder) => ({
        getActivityById: builder.query<
            ProcessActivityApproval,
            { activityId: string; processId: string }
        >({
            query: ({ activityId }) =>
                `${UrlPaths.BaseResourceName}/${activityId}`,
            transformResponse: (response: ProcessActivityApprovalDto) =>
                mapDtoToModel(response),
            providesTags: (result, error, arg) => [
                { type: Tags.ProcessActivity, id: arg.activityId },
                { type: Tags.ProcessActivitiesForProcess, id: arg.processId },
            ],
        }),

        activityRequestAprovals: builder.mutation<void, { activityId: string }>(
            {
                query: ({ activityId }) => ({
                    url: `${UrlPaths.BaseResourceName}/${activityId}${UrlPaths.Approvals}${UrlPaths.RequestApprovals}`,
                    method: "PATCH",
                }),
                invalidatesTags: (result, error, query) => [
                    {
                        type: Tags.ProcessActivity,
                        id: query.activityId,
                    },
                ],
            },
        ),

        addApproval: builder.mutation<
            void,
            { activityId: string; user: BaseUser }
        >({
            query: ({ activityId, user }) => ({
                url: `${UrlPaths.BaseResourceName}/${activityId}${UrlPaths.Approvals}`,
                method: "POST",
                body: mapUserToAddApproverRequest(user),
            }),
            invalidatesTags: (result, error, query) => [
                { type: Tags.ProcessAssignedList },
                { type: Tags.ProcessActivity, id: query.activityId },
            ],
        }),
        removeByApprovalId: builder.mutation<
            void,
            { activityId: string; approvalId: string }
        >({
            query: ({ activityId, approvalId }) => ({
                url: `${UrlPaths.BaseResourceName}/${activityId}${UrlPaths.Approvals}/${approvalId}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, query) => [
                { type: Tags.ProcessAssignedList },
                { type: Tags.ProcessActivity, id: query.activityId },
            ],
        }),
        resendByApprovalId: builder.mutation<
            void,
            { activityId: string; approvalId: string }
        >({
            query: ({ activityId, approvalId }) => ({
                url: `${UrlPaths.BaseResourceName}/${activityId}${UrlPaths.Approvals}/${approvalId}${UrlPaths.ApprovalResend}`,
                method: "POST",
            }),
            invalidatesTags: (result, error, query) => [
                { type: Tags.ProcessActivity, id: query.activityId },
            ],
        }),
        approveByApprovalId: builder.mutation<
            RedirectResponse,
            { activity: ProcessActivity; approvalId: string; comment: string }
        >({
            query: ({ activity, approvalId, comment }) => ({
                url: `${UrlPaths.BaseResourceName}/${activity.id}${UrlPaths.Approvals}/${approvalId}${UrlPaths.ApprovalApprove}`,
                method: "PATCH",
                body: mapCommentToApproveRequest(comment),
            }),
            invalidatesTags: (_result, _error, query) =>
                createActivityTags(query.activity),
        }),
        rejectByApprovalId: builder.mutation<
            RedirectResponse,
            { activity: ProcessActivity; approvalId: string; comment: string }
        >({
            query: ({ activity, approvalId, comment }) => ({
                url: `${UrlPaths.BaseResourceName}/${activity.id}${UrlPaths.Approvals}/${approvalId}${UrlPaths.ApprovalReject}`,
                method: "PATCH",
                body: mapCommentToRejectRequest(comment),
            }),
            invalidatesTags: (_result, _error, query) =>
                createActivityTags(query.activity),
        }),
        resetByApprovalId: builder.mutation<
            void,
            { activityId: string; approvalId: string }
        >({
            query: ({ activityId, approvalId }) => ({
                url: `${UrlPaths.BaseResourceName}/${activityId}${UrlPaths.Approvals}/${approvalId}${UrlPaths.ApprovalReset}`,
                method: "PATCH",
            }),
            invalidatesTags: (result, error, query) => [
                { type: Tags.ProcessActivity, id: query.activityId },
            ],
        }),
        activityNotApplicable: builder.mutation<void, PostNotApplicableParams>({
            query: ({ activity, comment, reason }) => ({
                url: `${UrlPaths.BaseResourceName}/${activity.id}${UrlPaths.NotApplicable}`,
                method: "PATCH",
                body: { comment, reason },
            }),
            invalidatesTags: (result, error, { activity }) =>
                error ? [] : activityApprovalTags(activity),
        }),
        activityOpen: builder.mutation<void, ActivityActionParams>({
            query: ({ activity }) => ({
                url: `${UrlPaths.BaseResourceName}/${activity.id}${UrlPaths.Open}`,
                method: "PATCH",
            }),
            invalidatesTags: (result, error, { activity }) =>
                error
                    ? []
                    : activityApprovalTags(activity, [
                          {
                              type: Tags.ProcessAssignedList,
                          },
                      ]),
        }),
    }),
});

export const {
    useGetActivityByIdQuery,

    useActivityRequestAprovalsMutation,

    useAddApprovalMutation,
    useRemoveByApprovalIdMutation,
    useApproveByApprovalIdMutation,
    useRejectByApprovalIdMutation,
    useResetByApprovalIdMutation,
    useResendByApprovalIdMutation,
    useActivityNotApplicableMutation,
    useActivityOpenMutation,
} = processActivityApprovalApi;
