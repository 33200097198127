export interface TemplateContentFilter {
    property: TemplateContentFilterProperty;
    operator: TemplateContentFilterOperator;
    values: string[];
}

export enum TemplateContentFilterProperty {
    UserGroup = "UserGroup",
}

export enum TemplateContentFilterOperator {
    All = "All",
    Any = "Any",
}
