import * as React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import CoveringLoadingPanel from "../../../../common/components/CoveringLoadingPanel";
import { HttpMethod } from "../../../../common/http/HttpMethod";
import PermissionConstants from "../../../../common/permissions/PermissionConstants";
import useConsumerApiSettings from "../../../../customization/api/useConsumerApiSettings";
import { DownloadFile } from "../../../../helpers/ApiHelper";
import { hasUserOrgPermission } from "../../../../store/features/user/user-api-slice";
import { useGetCommercialMassUpdates } from "../queries/commercialMassUpdatesQueries";
import CommercialMassUpdaterGrid from "./CommercialMassUpdaterGrid";
import CommercialMassUpdaterUploadDialog from "./CommercialMassUpdaterUploadDialog";

interface Props {
    organisationId: string;
}

const CommercialMassUpdaterActions: React.FC<Props> = ({ organisationId }) => {
    const [showUploadDialog, setShowUploadDialog] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const canUpload = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgCommercialMessUpdater.create,
    ).hasPermission;

    const { data, isFetching } = useGetCommercialMassUpdates(organisationId);

    const { baseApiUrl } = useConsumerApiSettings();

    const downloadTemplate = async () => {
        setIsLoading(true);
        await DownloadFile({
            method: HttpMethod.Get,
            url: `${baseApiUrl}/organisation/${organisationId}/CommercialMassUpdater/DownloadTemplate`,
        });
        setIsLoading(false);
    };

    return (
        <>
            {canUpload && (
                <Button
                    className="ml-1"
                    variant="primary"
                    onClick={() => setShowUploadDialog(true)}
                    disabled={isLoading}
                >
                    Upload
                </Button>
            )}
            <Button
                className="ml-1"
                variant="outline-secondary"
                onClick={() => downloadTemplate()}
            >
                Download Template
            </Button>
            {isFetching && <CoveringLoadingPanel />}
            {!isFetching && data && (
                <div className="pt-2">
                    <CommercialMassUpdaterGrid
                        organisationId={organisationId}
                        bulkUpdates={data}
                    />
                </div>
            )}
            {showUploadDialog && (
                <CommercialMassUpdaterUploadDialog
                    onClose={() => setShowUploadDialog(false)}
                    organisationId={organisationId}
                />
            )}
        </>
    );
};

export default CommercialMassUpdaterActions;
