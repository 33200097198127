import { HttpMethod } from "../../../common/http/HttpMethod";
import { Invitation } from "../../../modules/invitation/domain/Invitation";
import { dcpApi, Tags } from "../dcpApi";

interface SendInvitationParams {
    organisationId: string;
    email: string;
    userGroupIds: string[];
}

export const invitationApi = dcpApi.injectEndpoints({
    endpoints: (builder) => ({
        getInvitationsByOrganisationId: builder.query<Invitation[], string>({
            query: (organisationId) =>
                `invitation?organisationId=${organisationId}`,
            providesTags: (result, error, arg) => [
                { type: Tags.OrganisationInvitationList, id: arg },
            ],
        }),
        sendInvitation: builder.mutation<void, SendInvitationParams>({
            query: ({ organisationId, email, userGroupIds }) => ({
                url: `invitation/${organisationId}`,
                method: HttpMethod.Post,
                body: { organisationId, email, userGroupIds },
            }),
            invalidatesTags: (result, error, arg) => [
                {
                    type: Tags.OrganisationInvitationList,
                    id: arg.organisationId,
                },
            ],
        }),
    }),
});

export const {
    useGetInvitationsByOrganisationIdQuery,
    useSendInvitationMutation,
} = invitationApi;
