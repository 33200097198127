import produce from "immer";
import { Action, Reducer } from "redux";
import { KnownAction } from "./actionCreators";
import {
    AdminRoleUserAddUserReceiveUsersAction,
    AdminRoleUserRemoveSuccessAction,
    AdminRoleUsersReceiveAction,
    ADMIN_ROLE_USERS_RECEIVE,
    ADMIN_ROLE_USER_ADD_CANCEL,
    ADMIN_ROLE_USER_ADD_RECEIVE_USERS,
    ADMIN_ROLE_USER_ADD_START,
    ADMIN_ROLE_USER_ADD_SUCCESS,
    ADMIN_ROLE_USER_REMOVE_SUCCESS,
    ReceiveRoleAction,
    ReceiveRolesAction,
    RECEIVE_ROLE,
    RECEIVE_ROLES,
    REQUEST_ROLE,
    REQUEST_ROLES,
} from "./actions";
import { AdminState } from "./store";

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const unloadedState: AdminState = {
    domain: {
        roles: [],
        role: {
            id: "",
            name: "",
            users: [],
        },
        availableRoleUsers: [],
    },
    application: {},
    ui: {
        role: {
            user: {
                isAdding: false,
            },
        },
    },
};

const handlers = {} as Record<
    string,
    (state: AdminState, action: unknown) => AdminState
>;

handlers[REQUEST_ROLES] = (state: AdminState): AdminState => {
    return produce(state, (newState) => {
        newState.domain.roles = [];
    });
};

handlers[RECEIVE_ROLES] = (
    state: AdminState,
    action: ReceiveRolesAction,
): AdminState => {
    return produce(state, (newState) => {
        newState.domain.roles = action.data;
    });
};

handlers[REQUEST_ROLE] = (state: AdminState): AdminState => {
    return produce(state, (newState) => {
        newState.domain.role = unloadedState.domain.role;
    });
};

handlers[RECEIVE_ROLE] = (
    state: AdminState,
    action: ReceiveRoleAction,
): AdminState => {
    return produce(state, (newState) => {
        newState.domain.role = action.data;
    });
};

handlers[ADMIN_ROLE_USER_REMOVE_SUCCESS] = (
    state: AdminState,
    action: AdminRoleUserRemoveSuccessAction,
): AdminState => {
    return produce(state, (newState) => {
        const index = state.domain.role.users.findIndex(
            (a) => a.id === action.data.userId,
        );
        if (index > -1) {
            newState.domain.role.users.splice(index, 1);
        }
    });
};

handlers[ADMIN_ROLE_USER_ADD_START] = (state: AdminState): AdminState => {
    return produce(state, (newState) => {
        newState.ui.role.user.isAdding = true;
        newState.domain.availableRoleUsers = [];
    });
};

const adminRoleUserCancelIsAdding = (state: AdminState): AdminState => {
    return produce(state, (newState) => {
        newState.ui.role.user.isAdding = false;
    });
};

handlers[ADMIN_ROLE_USER_ADD_CANCEL] = adminRoleUserCancelIsAdding;
handlers[ADMIN_ROLE_USER_ADD_SUCCESS] = adminRoleUserCancelIsAdding;

handlers[ADMIN_ROLE_USER_ADD_RECEIVE_USERS] = (
    state: AdminState,
    action: AdminRoleUserAddUserReceiveUsersAction,
): AdminState => {
    return produce(state, (newState) => {
        newState.domain.availableRoleUsers = action.data;
    });
};

handlers[ADMIN_ROLE_USERS_RECEIVE] = (
    state: AdminState,
    action: AdminRoleUsersReceiveAction,
): AdminState => {
    return produce(state, (newState) => {
        newState.domain.role.users = action.data;
    });
};

export const reducer: Reducer<AdminState> = (
    state: AdminState | undefined,
    incomingAction: Action,
): AdminState => {
    if (!state) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    const handler = handlers[action.type];
    if (handler) {
        return handler(state, action);
    }
    return state;
};
