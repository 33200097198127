import { Dialog } from "@progress/kendo-react-dialogs";
import { push } from "connected-react-router";
import * as React from "react";
import {
    Badge,
    Breadcrumb,
    Button,
    Col,
    Row,
    Tab,
    Tabs,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import AuditActionsList from "../../../common/components/audit/AuditActionsList";
import CoveringLoadingPanel from "../../../common/components/CoveringLoadingPanel";
import NoAccess from "../../../common/components/NoAccess";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";
import useViewPermissions from "../../../common/hooks/useViewPermissions";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import { isQueryErrorForbidden } from "../../../common/types/rtkQuery/QueryResult";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import ProcessConstants from "../../../Constants/ProcessConstants";
import {
    useGetProcessByIdQuery,
    usePostCopyProcessByIdMutation,
} from "../../../store/features/process/process-api-slice";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import { useGetOrgByShortNameQuery } from "../../../store/features/organisation/organisation-api-slice";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";
import { ProcessCopyDialog } from "../components/ProcessCopyDialog";
import ProcessActivityList from "../containers/ProcessActivityList";
import ProcessDetailsForm from "../containers/ProcessDetailsForm";
import ProcessPropertiesList from "../containers/ProcessPropertiesList";
import { ProcessCopyCreate } from "../domain/types";
import { formatViewProcessPageTitle } from "./pageTitles";
import { CefaExamContextBar } from "../../cefa/examinations/components/CefaExamContextBar";
import CefaConstants from "../../cefa/constants/CefaConstants";
import useIsClient from "../../../common/hooks/useIsClient";

const ProcessPage: React.FC = () => {
    const dispatch = useDispatch();
    const { id, orgShortName } = useParams<{
        id: string;
        orgShortName: string;
    }>();
    const { data: organisation } = useGetOrgByShortNameQuery(orgShortName, {
        skip: !orgShortName,
    });

    const { data: process, ...processResult } = useGetProcessByIdQuery(id, {
        skip: !id,
        refetchOnMountOrArgChange: true,
    });
    const hasProperties = React.useMemo(
        () => process?.properties && process.properties.length > 0,
        [process],
    );

    const isCefa = useIsClient(CefaConstants.ClientName);

    const canCopyProcess =
        hasUserOrgPermission(
            process?.organisationId,
            PermissionConstants.OrgProcess.copy,
        ).hasPermission && !process?.readOnly;

    const [state, setState] = React.useState({ isDialogVisible: false });
    const [copyProcess, { isLoading: isCopyLoading }] =
        usePostCopyProcessByIdMutation();

    useOrgTitleWithShortName(formatViewProcessPageTitle(process?.name));

    const { organisationId } = useOrganisationId();
    const { canViewProcesses } = useViewPermissions(organisationId);

    const toggleDialog = () =>
        setState({ isDialogVisible: !state.isDialogVisible });

    const saveCopy = (copy: ProcessCopyCreate) =>
        copyProcess({ ...copy, id, organisationId: process.organisationId })
            .unwrap()
            .then((data) => {
                toggleDialog();
                dispatch(
                    push(
                        `/${orgShortName}${ProcessConstants.ResourceName}/${data.id}`,
                    ),
                );
            });

    if (processResult.isFetching) return <CoveringLoadingPanel />;

    if (isQueryErrorForbidden(processResult)) {
        return <NoAccess />;
    }

    if (!process) return <></>;

    return (
        <>
            {state.isDialogVisible && (
                <Dialog
                    title={`New ${
                        organisation?.features?.process?.createName ?? "Process"
                    } details`}
                    onClose={toggleDialog}
                >
                    {isCopyLoading && <CoveringLoadingPanel />}
                    <ProcessCopyDialog
                        process={process}
                        onBack={toggleDialog}
                        onConfirm={saveCopy}
                    />
                </Dialog>
            )}

            {isCefa && (
                <CefaExamContextBar
                    organisationId={organisationId}
                    processId={process?.id}
                />
            )}

            <Row className="pt-2">
                <Col>
                    <Breadcrumb>
                        {canViewProcesses && (
                            <Breadcrumb.Item linkAs={"div"}>
                                <Link
                                    to={`/${orgShortName}${OrganisationConstants.Process}`}
                                >
                                    {organisation?.features?.process
                                        ?.sideMenuName ?? "Processes"}
                                </Link>
                            </Breadcrumb.Item>
                        )}
                        <Breadcrumb.Item active>{process.name}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <div>
                <h1>
                    {process.name}{" "}
                    {process.state && (
                        <Badge variant="secondary">{process.state}</Badge>
                    )}
                </h1>
            </div>

            <Row className="mt-4">
                <Col>
                    <Tabs
                        defaultActiveKey={
                            hasProperties ? "properties" : "activities"
                        }
                        id="tab"
                    >
                        {hasProperties && (
                            <Tab eventKey="properties" title="Properties">
                                <Row className="pt-2">
                                    <Col>
                                        <ProcessPropertiesList
                                            properties={process.properties}
                                        />
                                    </Col>
                                </Row>
                            </Tab>
                        )}
                        <Tab eventKey="activities" title="Activities">
                            <Row className="pt-2">
                                <Col>
                                    <ProcessActivityList
                                        activities={process.activities}
                                        processId={process.id}
                                        organisationId={organisationId}
                                    />
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="options" title="Options">
                            <Row>
                                <Col>
                                    {canCopyProcess && (
                                        <Button
                                            className="my-2 mx-1"
                                            variant="secondary"
                                            onClick={toggleDialog}
                                        >
                                            Copy
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                            <ProcessDetailsForm
                                processData={process}
                                organisation={organisation}
                            />
                        </Tab>
                        <Tab eventKey="history" title="History">
                            <AuditActionsList
                                auditActions={process.auditActions}
                            />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </>
    );
};

export default ProcessPage;
