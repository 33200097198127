import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import { consumerApi } from "../customization/api/consumerRTKQueryApi";
import { AppDispatch, RootState } from "./configureStore";
import { dcpApi, InvalidateTag } from "./features/dcpApi";

export const useAppDispatch = (): Dispatch<AnyAction> =>
    useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useInvalidateTags = (): {
    invalidateTags: (tags: InvalidateTag[]) => void;
} => {
    const dispatch = useAppDispatch();

    const invalidateTags = (tags: InvalidateTag[]) => {
        dispatch(dcpApi.util.invalidateTags(tags));
        dispatch(consumerApi.util.invalidateTags(tags));
    };

    return { invalidateTags };
};
