import {
    isRejectedWithValue,
    Middleware,
    MiddlewareAPI,
} from "@reduxjs/toolkit";
import { ErrorResponse } from "../../common/types/Errors";
import { getNotificationsForSpecialErrors } from "../features/notifications/errorNotification";
import { addError } from "../features/notifications/notificationsSlice";

export const globalErrorNotificationMiddleware: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        if (isRejectedWithValue(action)) {
            showNotificationsForSpecialErrors(api, action);
        }

        return next(action);
    };

const showNotificationsForSpecialErrors = (api: MiddlewareAPI, action) => {
    const error = getNotificationsForSpecialErrors(
        (action.payload?.data || action.payload?.error) as ErrorResponse,
    );

    if (error) api.dispatch(addError(error));
};
