import { DataContinuationResult } from "../../../common/types/DataResult";
import { SortAndFilterOptions } from "../../../common/types/SortAndFilterOptions";
import ApiConstants from "../../../Constants/ApiConstants";
import { postData } from "../../../helpers/ApiHelper";
import { ProcessActivityListItemModel } from "../domain/types";

enum UrlPaths {
    BaseResource = "ProcessActivityList",
    Paginated = "paginated",
}

export interface ProcessActivityListSearchOptions extends SortAndFilterOptions {
    continuationToken?: string;
}

interface GetProcessesArgs {
    listActivityId: string;
    query: ProcessActivityListSearchOptions;
}

const baseUrl = `${ApiConstants.ApiPrefix}/${UrlPaths.BaseResource}`;

export async function getInfiniteProcessActivityListItems({
    listActivityId,
    query,
}: GetProcessesArgs): Promise<
    DataContinuationResult<ProcessActivityListItemModel>
> {
    const url = `${baseUrl}/${UrlPaths.Paginated}/${listActivityId}`;
    const result = postData<
        DataContinuationResult<ProcessActivityListItemModel>
    >(url, query);

    const response = await result;
    if (!response.data) return;
    return {
        ...response,
        data: response.data,
    };
}
