import { FilesPageMode } from "../viewModel/FilesPageMode";
import { useState } from "react";
import { EntryType } from "../viewModel/FileViewEntry";

const initialState: FilesPageMode = {
    mode: "Default",
};

interface StateActions {
    startAddingNew: () => void;
    startEditing: (id: string, type: EntryType) => void;
    goBackToDefaultMode: () => void;
}

export function useFilesPageState(): [FilesPageMode, StateActions] {
    const [state, setState] = useState(initialState);

    const startAddingNew = (): void => setState({ mode: "AddNew" });

    const startEditing = (id: string, type: EntryType) =>
        setState({ mode: "EditExisting", id, type });

    const goBackToDefaultMode = (): void => setState({ mode: "Default" });

    return [state, { startAddingNew, startEditing, goBackToDefaultMode }];
}
