import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Formik } from "formik";
import * as React from "react";
import CoveringLoadingPanel from "../../../../common/components/CoveringLoadingPanel";
import { CustomSelect } from "../../../../common/components/form";
import {
    SelectOptionItem,
    mapToSelectOptions,
} from "../../../../common/types/reactSelect/SelectOptionItem";
import { useGetUsersByOrgIdQuery } from "../../../../store/features/organisation/organisation-api-slice";
import { TemplateContentFilter } from "../../../common/filters/domain/types";
import { withUserFilter } from "../../../common/filters/helpers/userFilters";
import { Examination } from "../domain/examination";
import { ExaminationAssignModel } from "../domain/examinationsPlanningDtos";
import { AssignExaminationMutation } from "../query/commonQueryTypes";
import { Button, Alert } from "react-bootstrap";

interface FormValues {
    assignedUser: SelectOptionItem;
}

interface Props {
    title: string;
    examinations: Examination[];
    organisationId: string;
    usersFilter: TemplateContentFilter;
    assignMutation: AssignExaminationMutation;
    onClose: () => void;
}

const AssignModal: React.FC<Props> = ({
    title,
    examinations,
    organisationId,
    usersFilter,
    assignMutation,
    onClose,
}) => {
    const appliedTitle = title ? title : "Assign User";
    const { data: organisationUsers } = useGetUsersByOrgIdQuery(organisationId);

    const assignedToOptions = React.useMemo(() => {
        return mapToSelectOptions(
            withUserFilter(organisationUsers, usersFilter),
            (i) => i.userId,
            (i) => `${i.userDisplayName} (${i.username})`,
        );
    }, [organisationUsers, usersFilter]);

    const lengthOfSelectedExam = examinations?.length ?? 0;

    const lengthOfExamsAssignedWithEngineer =
        examinations?.filter((x) => x?.assignedEngineer)?.length ?? 0;

    let warningMessage = "";

    if (
        lengthOfExamsAssignedWithEngineer < lengthOfSelectedExam &&
        lengthOfExamsAssignedWithEngineer > 0
    ) {
        warningMessage =
            "Few exams are already assigned to an engineer. This operation will override the existing engineer with the selected engineer.";
    } else if (lengthOfExamsAssignedWithEngineer > 0) {
        warningMessage =
            lengthOfExamsAssignedWithEngineer > 1
                ? "Selected exams are already assigned to an engineer. This operation will override the existing engineer with the selected engineer."
                : "Selected exam is already assigned to an engineer. This operation will override the existing engineer with the selected engineer.";
    }

    const processIds = React.useMemo(
        () => examinations.map((e) => e.processId),
        [examinations],
    );

    const initialValues: FormValues = {
        assignedUser: null,
    };

    const onSubmit = React.useCallback(
        async (values: FormValues) => {
            assignMutation.mutate(
                {
                    processIds: processIds,
                    assignedUserIds: values.assignedUser
                        ? [values.assignedUser.value]
                        : [],
                } as ExaminationAssignModel,
                { onSuccess: () => onClose(), onError: () => onClose() },
            );
        },
        [assignMutation, processIds, onClose],
    );

    return (
        <Dialog
            title={appliedTitle}
            width={600}
            onClose={onClose}
            contentStyle={{ overflow: "visible" }}
        >
            {assignMutation.isLoading && <CoveringLoadingPanel />}
            {warningMessage && (
                <Alert variant="warning">{warningMessage}</Alert>
            )}
            <Formik onSubmit={onSubmit} initialValues={initialValues}>
                {() => (
                    <Form>
                        <CustomSelect
                            name="assignedUser"
                            label="Select Assigned User"
                            placeholder="Select user..."
                            options={assignedToOptions}
                            isMulti={false}
                        />

                        <DialogActionsBar>
                            <Button
                                type="button"
                                variant="outline-secondary"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button type="submit" variant="primary">
                                Submit
                            </Button>
                        </DialogActionsBar>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default AssignModal;
