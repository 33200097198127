import * as Formik from "formik";
import React from "react";
import { useHistory } from "react-router";
import { Button } from "react-bootstrap";
import * as yup from "yup";
import CoveringLoadingPanel from "../../../common/components/CoveringLoadingPanel";
import { ErrorInfo } from "../../../common/components/ErrorInfo";
import TextInput from "../../../common/components/form/TextInput";
import { useSendInvitationMutation } from "../../../store/features/invitation/invitationApiSlice";
import { useGetUserGroupsByOrgIdQuery } from "../../../store/features/organisation/organisation-api-slice";
import CustomSelect from "../../../common/components/form/CustomSelect";
import {
    mapToSelectOptions,
    SelectOptionItem,
} from "../../../common/types/reactSelect/SelectOptionItem";

interface Props {
    organisationId: string;
}

interface FormValues {
    email: string;
    userGroups: SelectOptionItem[];
}

const validation = yup.object({
    email: yup.string().required("Required").email("Invalid email"),
    userGroups: yup
        .array()
        .required("Required")
        .min(1, "You must select at least one user group"),
});

const SendInvitationForm: React.FC<Props> = ({ organisationId }) => {
    const [sendInvitation, { error: sendInvitationError }] =
        useSendInvitationMutation();
    const {
        data: userGroups,
        isLoading,
        error: getUserGroupsError,
    } = useGetUserGroupsByOrgIdQuery(organisationId);
    const history = useHistory();

    const initialFormValues: FormValues = React.useMemo(() => {
        return { email: "", userGroups: [] };
    }, []);

    const serverError = sendInvitationError || getUserGroupsError;

    const handleSubmit = async (formValues: FormValues) => {
        await sendInvitation({
            email: formValues.email,
            organisationId: organisationId,
            userGroupIds: formValues.userGroups.map((group) => {
                return group.value;
            }),
        })
            .unwrap()
            .then(() => history.goBack());
    };

    if (isLoading || !userGroups) {
        return <CoveringLoadingPanel />;
    }

    return (
        <>
            <Formik.Formik
                initialValues={initialFormValues}
                onSubmit={handleSubmit}
                validationSchema={validation}
            >
                <Formik.Form>
                    <TextInput label="Email" placeholder="Email" name="email" />
                    <CustomSelect
                        name="userGroups"
                        label="User Groups"
                        placeholder="Select user group..."
                        isMulti
                        options={mapToSelectOptions(
                            userGroups,
                            (g) => g.id,
                            (g) => g.name,
                        )}
                    />
                    <Button variant="primary" type="submit">
                        Send
                    </Button>
                </Formik.Form>
            </Formik.Formik>
            {serverError && <ErrorInfo error={serverError} />}
        </>
    );
};

export default SendInvitationForm;
