import {
    Grid,
    GridColumn,
    GridItemChangeEvent,
    GridRowClickEvent,
} from "@progress/kendo-react-grid";
import { ArrayHelpers, getIn, useFormikContext } from "formik";
import cx from "classnames";
import * as React from "react";
import { TypedGridCellProps } from "../../../../common/types/TypedGridCellProps";
import { ContainsMenuFilter } from "../../../../common/components/table/kendo/columnFilters";
import useGridProps from "../../../../common/components/table/kendo/useGridProps";
import {
    FormControlRiskGridDataItem,
    mapToRiskGridItem,
} from "./FormControlRiskGridDataItem";
import {
    ShowCondition,
    TemplateContentTableRow,
} from "../../../template/domain/types";
import FormControlRiskGridActions from "./FormControlRiskGridActions";
import { TemplateContentRiskRecord } from "../../domain/types";
import RiskConstants from "../consts/RiskConstants";

interface FormControlRiskGridConfig {
    field: string;
    label: string;
    prompt: string;
    uri: string;
    rows: TemplateContentTableRow[];
    isReadOnly: boolean;
    showConditions: ShowCondition[];
}

interface Props {
    config: FormControlRiskGridConfig;
    arrayHelpers: ArrayHelpers;
    isValid: boolean;
}

const FormControlRiskGrid: React.FC<Props> = ({
    config,
    arrayHelpers,
    isValid,
}) => {
    const [editId, setEditId] = React.useState<string>(null);
    const formik = useFormikContext();
    const rowData = React.useMemo(
        () =>
            (getIn(formik.values, config.field) ||
                []) as TemplateContentRiskRecord[],
        [formik.values, config.field],
    );

    const data = React.useMemo(
        () =>
            rowData.map((row, index) => mapToRiskGridItem(row, index, editId)),
        [rowData, editId],
    ) as FormControlRiskGridDataItem[];

    const { gridProps } = useGridProps(data, {
        filterable: true,
        sortable: true,
        initialSort: [{ field: "createdOn", dir: "desc" }],
        pageable: false,
    });

    const ActionsCell = React.useMemo(
        () =>
            // eslint-disable-next-line react/display-name
            (props: TypedGridCellProps<FormControlRiskGridDataItem>) =>
                (
                    <td>
                        <FormControlRiskGridActions
                            {...props}
                            onRemove={(dataItem) => {
                                arrayHelpers.remove(dataItem.originalIndex);
                            }}
                        />
                    </td>
                ),
        [arrayHelpers],
    );

    const handleRowClick = (event: GridRowClickEvent) => {
        if (!config.isReadOnly) {
            setEditId(event.dataItem.id);
        }
    };

    const handleEdit = (event: GridItemChangeEvent) => {
        const newData = rowData.map((item) =>
            item.id === event.dataItem.id
                ? { ...item, [event.field]: event.value }
                : item,
        );
        formik.setFieldValue(config.field, newData);
    };

    return (
        <Grid
            {...gridProps}
            className={cx(gridProps.className, "dynamic-form-grid", {
                "is-invalid": !isValid,
            })}
            editField="inEdit"
            onRowClick={handleRowClick}
            onItemChange={handleEdit}
        >
            <GridColumn
                field={RiskConstants.AssessmentRefField}
                title={RiskConstants.AssessmentRefLabel}
                sortable
                width={window.innerWidth * RiskConstants.AssessmentRefWidth}
                columnMenu={ContainsMenuFilter}
                editable={!config.isReadOnly}
                editor="text"
            />
            <GridColumn
                field={RiskConstants.SubActivityField}
                title={RiskConstants.SubActivityLabel}
                sortable
                width={window.innerWidth * RiskConstants.SubActivityWidth}
                columnMenu={ContainsMenuFilter}
                editable={!config.isReadOnly}
                editor="text"
            />
            <GridColumn
                field={RiskConstants.HazardField}
                title={RiskConstants.HazardLabel}
                sortable
                width={window.innerWidth * RiskConstants.HazardWidth}
                columnMenu={ContainsMenuFilter}
                editable={!config.isReadOnly}
                editor="text"
            />
            <GridColumn
                field={RiskConstants.ResidualRiskField}
                title={RiskConstants.ResidualRiskLabel}
                sortable
                width={window.innerWidth * RiskConstants.ResidualRiskWidth}
                columnMenu={ContainsMenuFilter}
                editable={!config.isReadOnly}
                editor="numeric"
            />
            <GridColumn
                field={RiskConstants.ControlField}
                title={RiskConstants.ControlLabel}
                sortable
                width={window.innerWidth * RiskConstants.ControlWidth}
                columnMenu={ContainsMenuFilter}
                editable={!config.isReadOnly}
                editor="text"
            />
            {!config.isReadOnly && (
                <GridColumn
                    filterable={false}
                    cell={ActionsCell}
                    width={window.innerWidth * RiskConstants.ActionWidth}
                />
            )}
        </Grid>
    );
};
export default FormControlRiskGrid;
