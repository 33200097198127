import { ErrorMessage, FieldAttributes, useField } from "formik";
import React, { useRef } from "react";
import cx from "classnames";
import { Form } from "react-bootstrap";
import useAutosizeTextArea from "../../hooks/useAutosizeTextArea";

type Props = {
    label?: string;
    readOnly?: boolean;
    className?: string;
    rows?: number;
    onValueChange?: (value: string) => void;
    plainText?: boolean;
} & FieldAttributes<unknown>;

const TextArea: React.FC<Props> = ({
    readOnly,
    rows,
    label,
    className,
    plainText,
    ...props
}) => {
    const [field, meta] = useField<string>(props);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    useAutosizeTextArea(textAreaRef.current, field.value);
    return (
        <Form.Group controlId={field.name}>
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Control
                as="textarea"
                name={field.name}
                rows={rows}
                readOnly={readOnly}
                className={cx(className, {
                    "is-invalid": meta.touched && meta.error,
                })}
                onBlur={field.onBlur}
                onChange={field.onChange}
                value={field.value}
                plaintext={plainText}
                ref={textAreaRef}
            />
            <ErrorMessage
                name={field.name}
                className="invalid-feedback"
                component="div"
            />
        </Form.Group>
    );
};

TextArea.defaultProps = {
    readOnly: false,
    rows: 1,
};

export default TextArea;
