import { ErrorMessage, FieldAttributes, useField } from "formik";
import React from "react";
import cx from "classnames";
import { Form } from "react-bootstrap";

type Props = {
    readOnly?: boolean;
    label?: string;
    className?: string;
    description?: string;
} & FieldAttributes<unknown>;

const NumberInput: React.FC<Props> = ({
    readOnly,
    label,
    className,
    description,
    placeholder,
    ...props
}) => {
    const [field, meta] = useField<string>(props);

    return (
        <Form.Group controlId={field.name}>
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Control
                type="number"
                placeholder={placeholder}
                name={field.name}
                readOnly={readOnly}
                className={cx(className, {
                    "is-invalid": meta.touched && meta.error,
                })}
                onBlur={field.onBlur}
                onChange={field.onChange}
                value={field.value}
            />
            <ErrorMessage
                name={field.name}
                className="invalid-feedback"
                component="div"
            />
            {description && (
                <Form.Text className="text-muted">{description}</Form.Text>
            )}
        </Form.Group>
    );
};

NumberInput.defaultProps = {
    readOnly: false,
};

export default NumberInput;
