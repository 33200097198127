import { Form, Formik } from "formik";
import { noop } from "lodash";
import * as React from "react";
import PropertyDynamicField from "../components/PropertyDynamicField";
import { ProcessProperty } from "../domain/types";

interface Props {
    properties: ProcessProperty[];
}

const formatDateToGB = (dateValue: string) => {
    const parsedDate = Date.parse(dateValue);
    if (!isNaN(parsedDate)) {
        const date = new Date(parsedDate);
        return new Intl.DateTimeFormat("en-GB").format(date);
    } else {
        return dateValue;
    }
};

const ProcessPropertiesList: React.FC<Props> = ({ properties }) => {
    const propertyData = React.useMemo(() => {
        const pPropertyData = {};

        properties.forEach(({ type, field, value }) => {
            if (type.toLowerCase().includes("date")) {
                pPropertyData[field] = formatDateToGB(value);
            } else {
                pPropertyData[field] = value;
            }
        });

        return pPropertyData;
    }, [properties]);

    return (
        <Formik initialValues={propertyData} onSubmit={noop}>
            <Form>
                {properties.map((property) => (
                    <PropertyDynamicField key={property.field} {...property} />
                ))}
            </Form>
        </Formik>
    );
};

export default ProcessPropertiesList;
