import { AnyObject } from "immer/dist/internal";
import { MixedSchema } from "yup/lib/mixed";
import FormValuesHelper from "../helpers/FormValuesHelper";
import { YupMixedSchemaType, YupStringSchemaType } from "./YupTypes";

export const validatorIsStringBoolean = function (
    this: YupStringSchemaType,
    message: string,
): YupStringSchemaType {
    return this.test("is-string-boolean", message, (value?: string) => {
        //ignore if no value
        if (!value) {
            return true;
        }

        if (["Y", "N"].indexOf(value) !== -1) {
            return true;
        } else {
            return false;
        }
    });
};

export const validatorFileSizeMax = function (
    this: MixedSchema<File[], AnyObject, unknown>,
    message: string,
    maxSize: number,
): YupMixedSchemaType {
    return this.test("is-file-size-under-max", message, (value: File[]) => {
        if (!value) return true;
        if (typeof value === "string") {
            return value === FormValuesHelper.Constants.FileFoundString;
        }
        return value.every((a) => a.size <= maxSize);
    });
};

export const validatorFileTypeMime = function (
    this: MixedSchema<File[], AnyObject, unknown>,
    message: string,
    extensionArray: string[],
): YupMixedSchemaType {
    return this.test("is-file-mime-type-valid", message, (value: File[]) => {
        //ignore if no value
        if (!value) return true;
        if (typeof value === "string") {
            return value === FormValuesHelper.Constants.FileFoundString;
        }

        return value.every((a) => extensionArray.includes(a.type));
    });
};

export const validatorFileRequired = function (
    this: MixedSchema<File[], AnyObject, unknown>,
    message: string,
): YupMixedSchemaType {
    return this.test("is-file-required", message, (value: File[] | string) => {
        //ignore if no value
        if (!value || value.length === 0) return false;
        if (typeof value === "string") {
            return value === FormValuesHelper.Constants.FileFoundString;
        }
        return true;
    });
};

export const validatorFileNumberLimit = function (
    this: MixedSchema<File[], AnyObject, unknown>,
    message: string,
    maxNumberOfFiles: number,
): YupMixedSchemaType {
    return this.test(
        "is-number-of-files-outside-limit",
        message,
        (value: File[] | string) => {
            if (!value || value.length === 0 || value.length > maxNumberOfFiles)
                return false;
            if (typeof value === "string") {
                return value === FormValuesHelper.Constants.FileFoundString;
            }
            return true;
        },
    );
};

export const validatorMinDate = function (
    this: MixedSchema<string, AnyObject, unknown>,
    message: string,
    minDate: string,
): YupMixedSchemaType {
    return this.test("minDate", message, (value: string) => {
        //ignore if no value
        if (!value) return false;

        const min = Date.parse(minDate);
        const date = Date.parse(value);

        return date >= min;
    });
};

export const validatorMaxDate = function (
    this: MixedSchema<string, AnyObject, unknown>,
    message: string,
    maxDate: string,
): YupMixedSchemaType {
    return this.test("maxDate", message, (value: string) => {
        //ignore if no value
        if (!value) return false;

        const min = Date.parse(maxDate);
        const date = Date.parse(value);

        return date <= min;
    });
};

export const validatorRegex = function (
    this: MixedSchema<string, AnyObject, unknown>,
    message: string,
    regex: string,
): YupMixedSchemaType {
    return this.test("regex", message, (value?: string) => {
        if (!value) {
            return true;
        }
        const regExp = new RegExp(regex);
        return regExp.test(value.toString());
    });
};
