import ProcessConstants from "../../../Constants/ProcessConstants";
import {
    Process,
    ProcessCopyCreate,
    ProcessCreate,
    ProcessCreateResponse,
    ProcessListItem,
    ProcessUpdate,
} from "../../../modules/process/domain/types";
import { dcpApi, Tags } from "./../dcpApi";

export const processApi = dcpApi.injectEndpoints({
    endpoints: (builder) => ({
        getProcessListItemsByOrganisationId: builder.query<
            ProcessListItem[],
            { organisationId: string }
        >({
            query: ({ organisationId }) =>
                `${ProcessConstants.ResourceName}?organisationId=${organisationId}`,
            providesTags: () => [{ type: Tags.ProcessList }],
        }),
        getProcessById: builder.query<Process, string>({
            query: (processId) =>
                `${ProcessConstants.ResourceName}/${processId}`,
            providesTags: (_result, _error, id) => [
                { type: Tags.Process, id },
                { type: Tags.ProcessActivitiesForProcess, id },
            ],
        }),
        deleteProcessById: builder.mutation<void, string>({
            query: (id) => ({
                url: `${ProcessConstants.ResourceName}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: () => [{ type: Tags.ProcessList }],
        }),
        createProcess: builder.mutation<ProcessCreateResponse, ProcessCreate>({
            query: (data) => ({
                url: `${ProcessConstants.ResourceName}?processTemplateId=${data.processTemplateId}`,
                method: "POST",
                body: { ...data },
            }),
            invalidatesTags: () => [{ type: Tags.ProcessList }],
        }),
        updateProcess: builder.mutation<ProcessCreateResponse, ProcessUpdate>({
            query: (data) => ({
                url: `${ProcessConstants.ResourceName}/${data.id}`,
                method: "PUT",
                body: { ...data },
            }),
            invalidatesTags: (_result, _error, { id }) => [
                { type: Tags.ProcessList },
                { type: Tags.Process, id },
                { type: Tags.ProcessAssignedList },
            ],
        }),
        postCopyProcessById: builder.mutation<Process, ProcessCopyCreate>({
            query: (data) => ({
                url: `${ProcessConstants.ResourceName}/${data.id}${ProcessConstants.Copy}`,
                method: "POST",
                body: {
                    name: data.name,
                    scheduledStart: data.scheduledStart,
                    scheduledEnd: data.scheduledEnd,
                    organisationId: data.organisationId,
                    includeFormContent: data.includeFormContent,
                },
            }),
            invalidatesTags: () => [{ type: Tags.ProcessList }],
        }),
    }),
});

export const {
    useGetProcessByIdQuery,
    useGetProcessListItemsByOrganisationIdQuery,
    useDeleteProcessByIdMutation,
    usePostCopyProcessByIdMutation,
    useCreateProcessMutation,
    useUpdateProcessMutation,
} = processApi;
