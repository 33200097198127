import * as Formik from "formik";
import { useFormikContext } from "formik";
import * as React from "react";
import MutationResultStatus from "../../../common/components/MutationResultStatus";
import TextArea from "../../../common/components/form/TextArea";
import TextInput from "../../../common/components/form/TextInput";
import { TemplateFormValues } from "../viewModel/TemplateFormValues";
import { BaseTemplateFormProps } from "../viewModel/templateForm";

export type ValidatedCallback = ({
    value,
    isValid,
}: {
    value: string;
    isValid: boolean;
}) => void;

type TemplateFormProps<T1, T2> = BaseTemplateFormProps<
    TemplateFormValues,
    T1,
    T2
>;

/*** Disable eslint rules for {} in extend that is common to make generic component} ***/
// eslint-disable-next-line @typescript-eslint/ban-types
const TemplateForm = <T1 extends {}, T2 extends {}>(
    props: TemplateFormProps<T1, T2>,
): JSX.Element => {
    return (
        <div className="pt-2">
            {props.createTemplate && (
                <MutationResultStatus
                    mutationResult={props.createTemplate.actionResult}
                    showLoading
                />
            )}
            {props.updateTemplate && (
                <MutationResultStatus
                    mutationResult={props.updateTemplate.actionResult}
                    showLoading
                />
            )}
            {props.deleteTemplate && (
                <MutationResultStatus
                    mutationResult={props.deleteTemplate.actionResult}
                    showLoading
                />
            )}

            <Formik.Formik
                enableReinitialize
                initialValues={props.initialValues}
                onSubmit={() => null}
            >
                {({ values }) => (
                    <Formik.Form>
                        {props.onConfigValidated && (
                            <PostConfigValidation
                                onValidated={props.onConfigValidated}
                            />
                        )}
                        <TextInput
                            label="Name"
                            placeholder="Name"
                            description="The name for your template."
                            name="name"
                            value={values.name}
                            readOnly={true}
                        />
                        <TextArea
                            label="Description"
                            name="description"
                            value={values.description}
                            readOnly={true}
                            rows={3}
                        />
                        <TextArea
                            label="Json Configuration"
                            name="config"
                            readOnly={true}
                            rows={20}
                        />
                        {props.initialValues.trackedObjects && (
                            <TextArea
                                label="Tracked Objects"
                                name="trackedObjects"
                                readOnly={true}
                                rows={10}
                            />
                        )}
                    </Formik.Form>
                )}
            </Formik.Formik>
        </div>
    );
};

const PostConfigValidation: React.FC<{
    onValidated: ValidatedCallback;
}> = ({ onValidated }) => {
    const { values, isValidating, errors, setErrors } =
        useFormikContext<TemplateFormValues>();

    React.useEffect(() => {
        // Trick that allows to catch all config filed changes after its validation,
        // when errors are updated (even if the error content hasn't changed)
        setErrors({ ...errors, config: "" });
    }, [errors, setErrors, values.config]);

    React.useEffect(() => {
        if (!isValidating) {
            onValidated({ value: values.config, isValid: !errors.config });
        }
    }, [errors, isValidating, onValidated, values.config]);

    return null;
};

export default TemplateForm;
