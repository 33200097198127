import {
    GridFieldsMap,
    GridFieldType,
} from "../../../../common/components/table/kendo/GridFieldsMap";

export const gridFieldsMap: GridFieldsMap = {
    assetCarrsGuid: {
        field: "assetCarrsGuid",
        label: "Asset CARRS GUID",
        dataType: "string",
        fieldType: GridFieldType.MultiText,
    },
    region: {
        field: "region",
        label: "Region",
        dataType: "string",
    },
    route: {
        field: "route",
        label: "Route",
        dataType: "string",
    },
    area: {
        field: "area",
        label: "Area",
        dataType: "string",
    },

    elr: {
        field: "elr",
        label: "ELR",
        dataType: "string",
    },
    startMileage: {
        field: "startMileage",
        label: "StartMileage",
        dataType: "number",
        fieldType: GridFieldType.Number,
    },
    endMileage: {
        field: "endMileage",
        label: "EndMileage",
        dataType: "number",
        fieldType: GridFieldType.Number,
    },
    railwayId: {
        field: "railwayId",
        label: "RailwayId",
        dataType: "string",
    },
    assetDescription: {
        field: "assetDescription",
        label: "AssetDescription",
        dataType: "string",
    },
    assetGroup: {
        field: "assetGroup",
        label: "AssetGroup",
        dataType: "string",
    },
    assetType: {
        field: "assetType",
        label: "AssetType",
        dataType: "string",
    },

    taskListStatus: {
        field: "taskListStatus",
        label: "TaskListStatus",
        dataType: "string",
    },
    examinationType: {
        field: "examinationType",
        label: "ExaminationType",
        dataType: "string",
    },
    examId: {
        field: "examId",
        label: "ExamId",
        dataType: "number",
        fieldType: GridFieldType.Number,
    },
    examFrequency: {
        field: "examFrequency",
        label: "ExamFrequency",
        dataType: "string",
    },

    requiredDate: {
        field: "requiredDate",
        label: "RequiredDate",
        dataType: "Date",
        fieldType: GridFieldType.Date,
    },
    siteRequiredToleranceEarliest: {
        field: "siteRequiredToleranceEarliest",
        label: "SiteRequiredToleranceEarliest",
        dataType: "Date",
        fieldType: GridFieldType.Date,
    },
    siteRequiredToleranceLatest: {
        field: "siteRequiredToleranceLatest",
        label: "SiteRequiredToleranceLatest",
        dataType: "Date",
        fieldType: GridFieldType.Date,
    },
    plannedDate: {
        field: "plannedDate",
        label: "PlannedDate",
        dataType: "Date",
        fieldType: GridFieldType.Date,
    },

    crId: {
        field: "crId",
        label: "CrId",
        dataType: "string",
    },
    jobNumber: {
        field: "jobNumber",
        label: "JobNumber",
        dataType: "string",
    },
    specificExamRequirement: {
        field: "specificExamRequirement",
        label: "SpecificExamRequirement",
        dataType: "string",
    },
    commentsToSec: {
        field: "commentsToSec",
        label: "CommentsToSec",
        dataType: "string",
    },
    examGroupId: {
        field: "examGroupId",
        label: "ExamGroupId",
        dataType: "number",
        fieldType: GridFieldType.Number,
    },
    hce: {
        field: "hce",
        label: "Hce",
        dataType: "string",
    },
    bcmiRequired: {
        field: "bcmiRequired",
        label: "BcmiRequired",
        dataType: "string",
    },
    nrInternalComment: {
        field: "nrInternalComment",
        label: "NrInternalComment",
        dataType: "string",
    },
    tenantedArch: {
        field: "tenantedArch",
        label: "TenantedArch",
        dataType: "string",
    },
    examRequestStatus: {
        field: "examRequestStatus",
        label: "ExamRequestStatus",
        dataType: "string",
    },
    examReportStatus: {
        field: "examReportStatus",
        label: "ExamReportStatus",
        dataType: "string",
    },

    examDate: {
        field: "examDate",
        label: "ExamDate",
        dataType: "Date",
        fieldType: GridFieldType.Date,
    },
    recordModifiedDate: {
        field: "recordModifiedDate",
        label: "RecordModifiedDate",
        dataType: "Date",
        fieldType: GridFieldType.Date,
    },
};

export const gridFilterOperators = {
    text: [
        {
            text: "grid.filterContainsOperator",
            operator: "contains",
        },
    ],
    numeric: [
        {
            text: "grid.filterEqOperator",
            operator: "eq",
        },
    ],
    date: [
        {
            text: "grid.filterEqOperator",
            operator: "eq",
        },
    ],
};
