import { ProcessActivity } from "./types";

export class ProcessActivityExtensions {
    public static canDisplayOptions(activity: ProcessActivity): boolean {
        return (
            activity?.renameEnabled ||
            activity?.scheduleEnabled ||
            activity?.userAssignment?.enabled
        );
    }
}
