import { State, SortDescriptor } from "@progress/kendo-data-query";
import { GridColumnProps } from "@progress/kendo-react-grid";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GridColumnDef } from "../assets-grid/assetsGridSlice";
export interface MyWorkGridState {
    gridState?: State;
    gridColumnProps?: GridColumnProps[];
    gridSelectedColumns?: GridColumnDef[];
}

const initialSort: Array<SortDescriptor> = [
    { field: "elr", dir: "asc" },
    { field: "miles", dir: "asc" },
    { field: "yards", dir: "asc" },
];

const initialState: MyWorkGridState = {
    gridState: { sort: initialSort },
    gridColumnProps: [],
    gridSelectedColumns: [],
};

export const myWorkGridFiltersSlice = createSlice({
    name: "myWorkGridFilters",
    initialState,
    reducers: {
        setMyWorkSelectedColumns: (
            state,
            action: PayloadAction<GridColumnDef[]>,
        ) => {
            state.gridSelectedColumns = action.payload;
        },
        setMyWorkColumnProps: (
            state,
            action: PayloadAction<GridColumnProps[]>,
        ) => {
            state.gridColumnProps = action.payload;
        },
        setMyWorkGridState: (state, action: PayloadAction<State>) => {
            state.gridState = action.payload;
        },
    },
});

export const {
    setMyWorkGridState,
    setMyWorkColumnProps,
    setMyWorkSelectedColumns,
} = myWorkGridFiltersSlice.actions;

export const reducer = myWorkGridFiltersSlice.reducer;
