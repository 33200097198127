import { push } from "connected-react-router";
import * as React from "react";
import { Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import LinkRow from "../../../../common/components/link-row";
import { Organisation } from "../../../organisation/domain/types";
import { AssociatedAsset } from "../../domain/types";

type Props = {
    assetId: AssociatedAsset[];
    organisation: Organisation;
};

const DefectAssets: React.FC<Props> = ({ assetId, organisation }) => {
    const dispatch = useDispatch();

    const onNavigate = (assetId: string) => () => {
        dispatch(push(`/${organisation.shortName}/asset/${assetId}`));
    };

    return (
        <div className="mt-3">
            <h4>Related Features</h4>
            <Table borderless={true}>
                <tbody>
                    {assetId.map((asset) => (
                        <LinkRow
                            key={asset.assetId}
                            title={asset.name}
                            description={
                                <div className="defect-description">
                                    <div className="col">
                                        <div>{asset.description}</div>
                                    </div>
                                    <div className="col">
                                        <div className="btn btn-primary">
                                            Show Feature
                                        </div>
                                    </div>
                                </div>
                            }
                            onNavigate={onNavigate(asset.assetId)}
                        />
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default DefectAssets;
