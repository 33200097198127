import * as React from "react";
import { Figure, Spinner } from "react-bootstrap";
import { getFormFile } from "../../../../services/FileDownloadService";
import { TypedGridCellProps } from "../../../types/TypedGridCellProps";
import { DynamicFormContext } from "../DynamicFormContextProvider";
import { FormControlFileTableDataItem } from "./FormControlFileTableDataItem";

interface Props {
    cell: TypedGridCellProps<FormControlFileTableDataItem>;
}

const thumbnailPreviewWidth = 50;

const FormControlFileTablePreview: React.FC<Props> = (props) => {
    const { getFileUrl } = React.useContext(DynamicFormContext);
    const [isDownloading, setIsDownloading] = React.useState(false);

    const { cell } = props;
    const { localFile, id: fileId } = cell.dataItem;

    const [fileBlob, setFileBlob] = React.useState(new Blob([]));

    const thumbnailUrl = React.useMemo(
        () => getFileUrl(fileId) + "/thumbnail",
        [getFileUrl, fileId],
    );

    React.useEffect(() => {
        if (thumbnailUrl && fileId) {
            setIsDownloading(true);
            getFormFile(thumbnailUrl)
                .then((a) => {
                    setFileBlob(a);
                })
                .catch(() => {
                    console.error("Failed to fetch img");
                    setFileBlob(new Blob([]));
                })
                .finally(() => {
                    setIsDownloading(false);
                });
        } else {
            setFileBlob(new Blob([]));
        }
    }, [fileId, thumbnailUrl]);

    if (isDownloading) {
        return (
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="<output>"
                aria-hidden="true"
                style={{ marginLeft: 15 }}
            />
        );
    } else if (localFile) {
        return (
            <Figure className="mb-0">
                <Figure.Image
                    className="mb-0"
                    width={thumbnailPreviewWidth}
                    src={URL.createObjectURL(localFile)}
                />
            </Figure>
        );
    } else {
        return (
            <Figure className="mb-0">
                <Figure.Image
                    className="mb-0"
                    width={thumbnailPreviewWidth}
                    src={URL.createObjectURL(fileBlob)}
                />
            </Figure>
        );
    }
};
export default FormControlFileTablePreview;
