import { InvalidateTag, Tags } from "../../../store/features/dcpApi";
import { ProcessActivity } from "../domain/types";

export const createActivityTags = (
    activity: ProcessActivity,
    otherTags: { type: Tags; id?: string }[] = [],
): { type: Tags; id?: string }[] => {
    return [
        { type: Tags.ProcessActivity, id: activity.id },
        { type: Tags.Process, id: activity.processId },
        ...otherTags,
    ].concat(
        activity.parentActivityId
            ? [{ type: Tags.ProcessActivity, id: activity.parentActivityId }]
            : [],
    );
};

export const activitiesDetailsTags = (ids: string[]): InvalidateTag[] =>
    ids.map((id) => ({
        type: Tags.ProcessActivity,
        id,
    }));
