import { push } from "connected-react-router";
import React from "react";
import { useParams } from "react-router";
import { useAppDispatch } from "../store/hooks";
import { AppRoute, createLink, OrganisationLinkParams } from "./AppRoute";

export const useNavigation = (): {
    navigateToPath: (link: string) => void;
    navigateTo: <T = unknown>(route: AppRoute<T>, params?: T) => void;
    getLink: <T = unknown>(route: AppRoute<T>, params?: T) => string;

    navigateToOrgPath: (link: string) => void;
    navigateToWithOrg: <
        T extends OrganisationLinkParams = OrganisationLinkParams,
    >(
        route: AppRoute<T>,
        params?: Omit<T, "orgShortName">,
    ) => void;
    getOrgLink: <T extends OrganisationLinkParams = OrganisationLinkParams>(
        route: AppRoute<T>,
        params?: Omit<T, "orgShortName">,
    ) => string;
} => {
    const dispatch = useAppDispatch();
    const { orgShortName } = useParams<{ orgShortName: string }>();

    const navigateToPath = React.useCallback(
        (path: string) => {
            const link = createLink(path, {});

            dispatch(push(link));
        },
        [dispatch],
    );

    const navigateTo = React.useCallback(
        <T = unknown>(route: AppRoute<T>, params?: T) => {
            const link = route.toLink({
                ...(params ? params : {}),
            } as T);

            dispatch(push(link));
        },
        [dispatch],
    );

    const getLink = React.useCallback(
        <T = unknown>(route: AppRoute<T>, params?: T) => {
            return route.toLink({
                ...(params ? params : {}),
            } as T);
        },
        [],
    );

    const navigateToOrgPath = React.useCallback(
        (path: string) => {
            const link = createLink(path, { orgShortName });

            dispatch(push(link));
        },
        [orgShortName, dispatch],
    );

    const navigateToWithOrg = React.useCallback(
        <T extends OrganisationLinkParams = OrganisationLinkParams>(
            route: AppRoute<T>,
            params?: Omit<T, "orgShortName">,
        ) => {
            const link = route.toLink({
                orgShortName,
                ...(params ? params : {}),
            } as T);

            dispatch(push(link));
        },
        [orgShortName, dispatch],
    );

    const getOrgLink = React.useCallback(
        <T extends OrganisationLinkParams = OrganisationLinkParams>(
            route: AppRoute<T>,
            params?: Omit<T, "orgShortName">,
        ) => {
            return route.toLink({
                orgShortName,
                ...(params ? params : {}),
            } as T);
        },
        [orgShortName],
    );

    return {
        navigateToPath,
        navigateTo,
        getLink,

        navigateToOrgPath,
        navigateToWithOrg,
        getOrgLink,
    };
};
