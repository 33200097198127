import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import * as Formik from "formik";
import * as React from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { useClaimExamMutation } from "../query/MyWorkQueries";
import * as yup from "yup";

interface FormValues {
    examDate: Date;
}
interface Props {
    onClose: () => void;
    processId: string;
    organisationId: string;
}

const formValidation = yup.object().shape({
    examDate: yup
        .date()
        .required("Date required to claim exam")
        .min(new Date("01/01/1900"), "Date must be after 1900."),
});

const ClaimExamModal: React.FC<Props> = ({
    onClose,
    processId,
    organisationId,
}) => {
    const claimExamMutation = useClaimExamMutation();

    const onSubmit = React.useCallback(
        (values) => {
            claimExamMutation.mutate(
                {
                    organisationId: organisationId,
                    processId: processId,
                    examDate: values.examDate,
                },

                { onSuccess: () => onClose(), onError: () => onClose() },
            );
            onClose();
        },
        [organisationId, processId, claimExamMutation, onClose],
    );

    const initialValues: FormValues = {
        examDate: new Date(Date.now()),
    };

    return (
        <Dialog
            title={"Claim Exam"}
            height={"150"}
            width={400}
            contentStyle={{ overflow: "visible" }}
            closeIcon={false}
        >
            <Formik.Formik<FormValues>
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={formValidation}
                validateOnMount={true}
            >
                {({ isSubmitting, handleChange, values, errors }) => (
                    <Formik.Form className={"k-form"} noValidate>
                        <Form.Group controlId={"examDate"}>
                            <DatePicker
                                className={errors.examDate ? "is-invalid" : ""}
                                name={"examDate"}
                                onChange={handleChange}
                                format={"dd/MM/yyyy"}
                                width={350}
                                value={values.examDate}
                            ></DatePicker>
                            <Formik.ErrorMessage
                                name={"examDate"}
                                className={"invalid-feedback"}
                                component={"div"}
                            />
                        </Form.Group>
                        <DialogActionsBar>
                            <Button
                                type="button"
                                variant="outline-secondary"
                                onClick={onClose}
                                disabled={isSubmitting}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="primary"
                                disabled={isSubmitting}
                            >
                                {isSubmitting && (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="output"
                                        aria-hidden="true"
                                    />
                                )}
                                Submit
                            </Button>
                        </DialogActionsBar>
                    </Formik.Form>
                )}
            </Formik.Formik>
        </Dialog>
    );
};

export default ClaimExamModal;
