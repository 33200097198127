import * as yup from "yup";
import { yupJson } from "../../../helpers/jsonHelper";

export const validationSchema = yup.object({
    name: yup.string().required("Required"),
    config: yupJson(),
    propertyConfig: yupJson(),
    statesConfig: yupJson(),
    featuresConfig: yupJson(),
});
