import authFetch from "../../../helpers/ApiHelper";
import { FolderUploadData, FolderUploadInfo } from "../domain/folder";

export function getFolderUploadDiff(
    organisationId: string,
    folderId: string,
    uploadForm: FolderUploadData,
): Promise<FolderUploadInfo> {
    return authFetch(
        `api/organisation/${organisationId}/folder/${folderId}/difference`,
        {
            method: "POST",
            body: JSON.stringify(uploadForm),
            headers: {
                "content-type": "application/json",
            },
        },
    ).then((data) => data.json());
}
