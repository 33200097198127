import { OrganisationLinkParams, OrganisationRoute } from "./AppRoute";

type SchedulerFromParams = OrganisationLinkParams & { scheduledStart: string };
type ProcessParams = OrganisationLinkParams & {
    processId: string;
};
type ProcessActivityParams = ProcessParams & {
    activityId: string;
};
type EngineerReviewDetailsParams = OrganisationLinkParams & {
    reviewId: string;
};
type BatchingDetailsParams = OrganisationLinkParams & {
    batchId: string;
};

type ApcDetailsParams = OrganisationLinkParams & {
    apcId: string;
};

const RouteLink = {
    Scheduler: "scheduler",
    OrganisationOverview: "organisation-overview",
    SchedulerFrom: "scheduler/:scheduledStart",
    SchedulerExaminations: "scheduler-examinations",
    SearchExaminations: "search-examinations",
    EngineerReview: "engineer-review",
    EngineerReviewDetails: "engineer-review/:reviewId",
    Process: "process/:processId",
    ProcessActivity: "process/:processId/activity/:activityId",
    Workbank: "workbank",
    CommercialReport: "commercial-report",
    DefectReport: "defect-report",
    ProgressReport: "progress-report",
    PerformanceReport: "performance-report",
    reactiveExamCreator: "reactive-exam-creator",
    Batching: "batching",
    BatchingDetails: "batching/:batchId",
    MissingCarrsExamTool: "missing-carrs-exam",
    ExamManagement: "exam-management",
    ApprovedExams: "approved-exams",
    MyWork: "my-work",
    SsowDataExport: "ssow-data-export",
    ExamRequests: "exam-requests",
    Apc: "apc",
    ApcDetails: "apc/:apcId",
    ApcCreate: "apc/create",
};

const OrganisationRoutes = {
    OrganisationOverview: new OrganisationRoute(RouteLink.OrganisationOverview),
    Scheduler: new OrganisationRoute(RouteLink.Scheduler),
    SchedulerFrom: new OrganisationRoute<SchedulerFromParams>(
        RouteLink.SchedulerFrom,
    ),
    SchedulerExaminations: new OrganisationRoute(
        RouteLink.SchedulerExaminations,
    ),
    SearchExaminations: new OrganisationRoute(RouteLink.SearchExaminations),
    EngineerReview: new OrganisationRoute(RouteLink.EngineerReview),
    EngineerReviewDetails: new OrganisationRoute<EngineerReviewDetailsParams>(
        RouteLink.EngineerReviewDetails,
    ),
    Process: new OrganisationRoute<ProcessParams>(RouteLink.Process),
    ProcessActivity: new OrganisationRoute<ProcessActivityParams>(
        RouteLink.ProcessActivity,
    ),
    Workbank: new OrganisationRoute(RouteLink.Workbank),
    CommercialReport: new OrganisationRoute(RouteLink.CommercialReport),
    DefectReport: new OrganisationRoute(RouteLink.DefectReport),
    ProgressReportExport: new OrganisationRoute(RouteLink.ProgressReport),
    PerformanceReportExport: new OrganisationRoute(RouteLink.PerformanceReport),
    ReactiveExamCreator: new OrganisationRoute(RouteLink.reactiveExamCreator),
    Batching: new OrganisationRoute(RouteLink.Batching),
    BatchingDetails: new OrganisationRoute<BatchingDetailsParams>(
        RouteLink.BatchingDetails,
    ),
    ExamManagement: new OrganisationRoute(RouteLink.ExamManagement),
    MissingCarrsExam: new OrganisationRoute(RouteLink.MissingCarrsExamTool),
    ApprovedExams: new OrganisationRoute(RouteLink.ApprovedExams),
    MyWork: new OrganisationRoute(RouteLink.MyWork),
    SsowDataExport: new OrganisationRoute(RouteLink.SsowDataExport),
    ExamRequests: new OrganisationRoute(RouteLink.ExamRequests),
    Apc: new OrganisationRoute(RouteLink.Apc),
    ApcCreate: new OrganisationRoute(RouteLink.ApcCreate),
    ApcDetails: new OrganisationRoute<ApcDetailsParams>(RouteLink.ApcDetails),
};

export const AppRoutes = {
    ...OrganisationRoutes,
    // ...ManagementRoutes
    // ...AdminRoutes
};
