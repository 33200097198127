import React, { cloneElement } from "react";
import { FileViewEntry } from "../viewModel/FileViewEntry";
import { useState } from "react";
import { GridRowProps } from "@progress/kendo-react-grid";

interface Offset {
    left: number;
    top: number;
}

type ContextMenuState = {
    dataItem: FileViewEntry | null;
    offset: Offset;
};

const initialState: ContextMenuState = {
    dataItem: null,
    offset: { left: 0, top: 0 },
};

interface HookResult {
    dataItem: FileViewEntry | null;
    offset: Offset;
    closeContextMenu: () => void;
    rowRender: (
        row: React.ReactElement<HTMLTableRowElement>,
        props: GridRowProps,
    ) => React.ReactNode;
}

export function useContextMenuState(
    contextMenuIsNeeded: (dataItem: FileViewEntry) => boolean,
): HookResult {
    const [state, setState] = useState(initialState);
    const openContextMenu = (dataItem: FileViewEntry, offset: Offset): void =>
        setState({ dataItem, offset });
    const closeContextMenu = (): void => setState(initialState);

    const rowRender = (
        row: React.ReactElement<HTMLTableRowElement>,
        props: GridRowProps,
    ): React.ReactNode => {
        const dataItem: FileViewEntry = props.dataItem;
        if (!contextMenuIsNeeded(dataItem)) {
            return row;
        }
        const trProps = {
            ...row.props,
            onContextMenu: (e: React.MouseEvent<HTMLTableRowElement>) => {
                e.preventDefault();
                openContextMenu(dataItem, { left: e.clientX, top: e.clientY });
            },
        };
        return cloneElement(row, { ...trProps }, row.props.children);
    };

    return {
        offset: state.offset,
        closeContextMenu,
        dataItem: state.dataItem,
        rowRender,
    };
}
