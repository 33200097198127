import { ChipListDataChangeEvent } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import React from "react";
import MutationResultStatus from "../../../common/components/MutationResultStatus";
import QueryResultStatus from "../../../common/components/QueryResultStatus";
import Tags from "../../../common/components/Tags";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import {
    useAddFolderTagMutation,
    usePatchFolderTagsMutation,
} from "../../../store/features/folder/folderApiSlice";
import { useGetTagHintsQuery } from "../../../store/features/organisation/organisation-api-slice";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import { ListedFolder } from "../domain/folder";
import { TagWithIndex, wrapTags } from "../utils/fileAndFolderTags";

interface Props {
    folder: ListedFolder;
    folderId: string;
    organisationId: string;
}

const FolderTags: React.FC<Props> = ({ folder, folderId, organisationId }) => {
    const [addTag, addTagResult] = useAddFolderTagMutation();
    const [deleteTag, deleteTagResult] = usePatchFolderTagsMutation();
    const { data: tagHints, ...hintsResult } =
        useGetTagHintsQuery(organisationId);

    const canAddTag = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgFilesFolderTags.create,
    ).hasPermission;
    const canDeleteTag = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgFilesFolderTags.delete,
    ).hasPermission;

    const handleAddTag = (event: ComboBoxChangeEvent) => {
        const value = event.value;
        if (!value || !value.text || folder.tags.includes(value.text)) return;

        addTag({
            folderId,
            organisationId,
            tag: value.text,
        });
    };

    const handleRemoveTag = (event: ChipListDataChangeEvent) => {
        if (!event.value) return;
        const tags = event.value.map((tag: TagWithIndex) => tag.text);
        const tagToRemove = folder.tags.filter((tag) => !tags.includes(tag))[0];

        deleteTag({
            folderId,
            organisationId,
            tag: tagToRemove,
        });
    };

    return (
        <>
            <QueryResultStatus queryResult={hintsResult} />
            <MutationResultStatus mutationResult={addTagResult} showLoading />
            <MutationResultStatus
                mutationResult={deleteTagResult}
                showLoading
            />
            <Tags
                canCreateTags={canAddTag}
                canDeleteTags={canDeleteTag}
                addTag={handleAddTag}
                removeTag={handleRemoveTag}
                elementTags={wrapTags(folder.tags)}
                availableTags={wrapTags(tagHints)}
            />
        </>
    );
};

export default FolderTags;
