import { filterBy, orderBy } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import * as React from "react";
import {
    ContainsMenuFilter,
    emptyFilterDescriptor,
    SimplifiedMenuFilter,
} from "../../../common/components/table/kendo/columnFilters";
import useGridProps from "../../../common/components/table/kendo/useGridProps";
import { jSDateFromIso } from "../../../helpers/dateTimeHelpers";
import { ProcessCompleteDto } from "../domain/types";

interface Props {
    processes: ProcessCompleteDto[];
}

type ProcessesData = {
    completedAtDate: Date;
    reportSubmissionTargetDate: Date;
} & Omit<ProcessCompleteDto, "completedAt" | "reportSubmissionTargetDateValue">;

function getInspectionsData(processes: ProcessCompleteDto[]): ProcessesData[] {
    return processes.map(
        ({ completedAt, reportSubmissionTargetDateValue, ...other }) => ({
            completedAtDate: jSDateFromIso(completedAt) || null,
            reportSubmissionTargetDate:
                jSDateFromIso(reportSubmissionTargetDateValue) || null,
            ...other,
        }),
    );
}

const InspectionsGrid: React.FC<Props> = ({ processes }) => {
    const processesData: ProcessesData[] = React.useMemo(
        () => getInspectionsData(processes),
        [processes],
    );

    const { gridProps, filter, sort } = useGridProps(processesData, {
        filterable: true,
        initialFilter: emptyFilterDescriptor,
        sortable: true,
        pageable: true,
    });

    const _export = React.useRef<ExcelExport | null>(null);
    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    const reportEntries = orderBy(filterBy(processesData, filter), sort);

    return (
        <ExcelExport data={reportEntries} ref={_export}>
            <Grid {...gridProps}>
                <GridToolbar>
                    <button
                        title="Export Excel"
                        className="k-button k-primary"
                        onClick={excelExport}
                    >
                        Export to Excel
                    </button>
                </GridToolbar>
                <GridColumn
                    field="processName"
                    title="Name"
                    columnMenu={ContainsMenuFilter}
                />
                <GridColumn
                    field="processType"
                    title="Type"
                    columnMenu={ContainsMenuFilter}
                />
                <GridColumn
                    field="completedAtDate"
                    title="Completed Date"
                    filter={"date"}
                    format="{0:d MMM y h:mm a}"
                    columnMenu={SimplifiedMenuFilter}
                />
                <GridColumn
                    field="reportSubmissionTargetDate"
                    title="Report Submission Target Date"
                    filter={"date"}
                    format="{0:d MMM y h:mm a}"
                    columnMenu={SimplifiedMenuFilter}
                />
                <GridColumn
                    field="submissionDateAchieved"
                    title="Submission Date Achieved"
                    columnMenu={SimplifiedMenuFilter}
                />
                <GridColumn
                    field="status"
                    title="Status"
                    columnMenu={SimplifiedMenuFilter}
                />
            </Grid>
        </ExcelExport>
    );
};

export default InspectionsGrid;
