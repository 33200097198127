import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaRegStar, FaStar } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { IconButton } from "../../../common/components/icon-button/IconButton";
import { useFavoriteFolderMutation } from "../../../store/features/folder/folderApiSlice";
import { CreateFolderButton } from "./CreateFolderButton";
import { FileSearchCore } from "./FileSearchCore";
import { FileUploadButton } from "./FileUploadButton";
import FolderTags from "./FolderTags";
import FolderUpload from "../containers/FolderUpload";
import { ListedFolder } from "../domain/folder";
import { useFilesPageState } from "../hooks/useFilesPageState";
import { buildFormFromQuery } from "../viewModel/FileSearch";
import { Organisation } from "../../organisation/domain/types";
import { UploadFileParams } from "../../../store/features/file/fileApiSlice";
import { ViewMetaDataButton } from "./metadata/ViewMetaDataButton";

interface Props {
    organisation: Organisation;
    folderId: string;
    uploadFile(params: UploadFileParams);
    folder: ListedFolder;
    startAddingNewFolder();
    toggleMetadata();
    orgPermissions: {
        canCreateFiles: boolean;
        canUpdateFiles: boolean;
        canReplaceFiles: boolean;
        canUpdateFolders: boolean;
        canCreateFolders: boolean;
        canSetFavorites: boolean;
    };
}

export const FileSearchBar: React.FC<Props> = ({
    organisation,
    folderId,
    uploadFile,
    folder,
    startAddingNewFolder,
    toggleMetadata,
    orgPermissions,
}) => {
    const location = useLocation();

    const currentQuery = React.useMemo(
        () => buildFormFromQuery(location.search),
        [location.search],
    );

    const [state] = useFilesPageState();
    const {
        canCreateFiles,
        canUpdateFiles,
        canReplaceFiles,
        canUpdateFolders,
        canCreateFolders,
        canSetFavorites,
    } = orgPermissions;

    const [setFavorite] = useFavoriteFolderMutation();

    const onSetFavorite = () => {
        setFavorite({
            organisationId: organisation.id,
            folderId: folderId,
            isFavorite: !folder.isFavorite,
        });
    };

    return (
        <Row style={{ paddingBottom: "1rem", justifyContent: "center" }}>
            <Col lg={5}>
                <FileSearchCore
                    organisation={organisation}
                    initialQuery={currentQuery}
                />
            </Col>
            <Col lg={2} style={{ width: "100%", padding: "0 15px" }}>
                {folder && (
                    <FolderTags
                        folder={folder}
                        folderId={folderId}
                        organisationId={organisation.id}
                    />
                )}
            </Col>
            <Col lg={4}>
                <div style={{ display: "inline-block" }} className="mr-2">
                    <CreateFolderButton
                        organisationId={organisation.id}
                        buttonProps={{
                            onClick:
                                state.mode === "Default"
                                    ? startAddingNewFolder
                                    : null,
                            disabled: state.mode !== "Default",
                        }}
                    />
                </div>

                <div style={{ display: "inline-block" }} className="mr-2">
                    <FileUploadButton
                        organisation={organisation}
                        folderId={folderId}
                        uploadFile={uploadFile}
                    />
                </div>
                <div style={{ display: "inline-block" }} className="mr-2">
                    {canCreateFiles &&
                        canCreateFolders &&
                        canUpdateFiles &&
                        canUpdateFolders &&
                        canReplaceFiles && (
                            <FolderUpload
                                organisation={organisation}
                                folderId={folderId}
                            />
                        )}
                </div>
                <div style={{ display: "inline-block" }} className="mr-2">
                    <ViewMetaDataButton
                        organisation={organisation}
                        onClick={toggleMetadata}
                    />
                </div>
            </Col>
            <Col lg={1} className="text-end">
                {folder && canSetFavorites && (
                    <div style={{ display: "inline-block" }}>
                        <IconButton
                            icon={folder.isFavorite ? FaStar : FaRegStar}
                            onClick={onSetFavorite}
                            iconSize="20"
                        />
                    </div>
                )}
            </Col>
        </Row>
    );
};
