import { QueryKey } from "react-query";

export const commercialMassUpdates = "commercial-mass-updates";

const commercialQueryKeys = {
    commercialMassUpdates: (orgId: string): QueryKey => [
        `${commercialMassUpdates}-${orgId}`,
    ],
};

export default commercialQueryKeys;
