import * as React from "react";
import { Figure, Spinner } from "react-bootstrap";
import { getFormFile } from "../../../../../services/FileDownloadService";
import { PhotoOrder } from "../../types";
import { getActivityFileResUrl } from "../../../../process-activity/helpers/urlBuilder";
import { TypedGridCellProps } from "../../../../../common/types/TypedGridCellProps";

const thumbnailPreviewWidth = 70;

const PhotoThumbnailCell: React.FC<TypedGridCellProps<PhotoOrder>> = (
    props,
) => {
    const { dataItem } = props;

    const [fileBlob, setFileBlob] = React.useState(new Blob([]));
    const thumbnailUrl = React.useMemo(() => {
        return (
            getActivityFileResUrl(dataItem.activityId)(dataItem.fileId) +
            "/thumbnail"
        );
    }, [dataItem]);

    React.useEffect(() => {
        if (thumbnailUrl) {
            getFormFile(thumbnailUrl)
                .then((a) => {
                    setFileBlob(a);
                })
                .catch(() => {
                    console.error("Failed to fetch img");
                    setFileBlob(new Blob([]));
                });
        } else {
            setFileBlob(new Blob([]));
        }
    }, [thumbnailUrl]);

    if (fileBlob.size > 0) {
        return (
            <td>
                <Figure className="figure">
                    <Figure.Image
                        className="figure-img img-fluid rounded"
                        width={thumbnailPreviewWidth}
                        src={URL.createObjectURL(fileBlob)}
                    />
                </Figure>
            </td>
        );
    } else {
        return (
            <td>
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </td>
        );
    }
};
export default PhotoThumbnailCell;
