export function getYearsNames(startingYear = 2010): string[] {
    const lastYear = 2032;

    const yearsNames: string[] = [];

    for (let i = startingYear; i <= lastYear; i++) {
        yearsNames.push(`${i}/${i + 1}`);
    }

    return yearsNames;
}
