import React from "react";
import { Button, Form } from "react-bootstrap";
import { downloadFile } from "../../../services/FileDownloadService";

interface Props {
    label?: string;
    url: string;
    fileName: string;
}
export const FileDownload: React.FC<Props> = ({ label, url, fileName }) => {
    return (
        <Form.Group>
            {label && <Form.Label>{label}</Form.Label>}
            <div>
                {fileName && <span className="pr-2">{fileName}</span>}
                {url ? (
                    <Button
                        variant="primary"
                        onClick={() => {
                            downloadFile(url, fileName).catch(() => {
                                console.error("failed to download file");
                            });
                        }}
                    >
                        Download
                    </Button>
                ) : (
                    <span>-</span>
                )}
            </div>
        </Form.Group>
    );
};

export default FileDownload;
