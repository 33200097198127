import { SerializedError } from "@reduxjs/toolkit";
import {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
    MutationDefinition,
} from "@reduxjs/toolkit/dist/query";
import { MutationActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";
import { Tags } from "../../../store/features/dcpApi";

/* 
    Simple type to be used as RTK mutation result object
*/
export interface MutationResult {
    isLoading: boolean;
    isSuccess?: boolean;
    isError?: boolean;
    error?: FetchBaseQueryError | SerializedError;
}

/* 
    Type to be used as RTK mutation action result (promise)
*/
export type MutationActionResult<T, U> = MutationActionCreatorResult<
    MutationDefinition<
        Partial<T>,
        BaseQueryFn<
            string | FetchArgs,
            unknown,
            FetchBaseQueryError,
            unknown,
            FetchBaseQueryMeta
        >,
        Tags,
        U,
        "dcpApi"
    >
>;

export function isMutationData<T>(
    response: { data: T } | { error: FetchBaseQueryError | SerializedError },
): response is { data: T } {
    return "data" in response;
}

export function isMutationError<T>(
    response: { data: T } | { error: FetchBaseQueryError | SerializedError },
): response is { error: FetchBaseQueryError } {
    return "error" in response && "status" in response.error;
}
