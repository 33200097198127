import { ProcessActivityType } from "../../../modules/process-activity/domain/types";
import { dcpApi, Tags } from "../dcpApi";

enum UrlPaths {
    ProcessActivities = "/process-activities",
    ResourceName = "/processactivityfile",
}

export interface UploadProcessActivityFileParams
    extends ProcessActivityFileParamsBase {
    activityId: string;
    file: File;
}

interface ChangeProcessActivityFilenameParams
    extends ProcessActivityFileAndActivityIdParams {
    filename: string;
}

interface ProcessActivityFileAndActivityIdParams
    extends ProcessActivityFileParamsBase {
    activityId: string;
    fileId: string;
}

interface ProcessActivityFileParamsBase {
    paType: ProcessActivityType;
}

const processActivityFileTagInvalidator = (
    result,
    error,
    params: { activityId: string; paType: ProcessActivityType },
) => [{ type: Tags.ProcessActivity, id: params.activityId }];

export const processActivityFileApi = dcpApi.injectEndpoints({
    endpoints: (builder) => ({
        uploadProcessActivityFile: builder.mutation<
            void,
            UploadProcessActivityFileParams
        >({
            query: (params) => {
                const body = new FormData();
                body.append("file", params.file);
                return {
                    url: `${UrlPaths.ProcessActivities}/${params.activityId}${UrlPaths.ResourceName}`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: processActivityFileTagInvalidator,
        }),
        deleteProcessActivityFile: builder.mutation<
            void,
            ProcessActivityFileAndActivityIdParams
        >({
            query: (params) => ({
                url: `${UrlPaths.ProcessActivities}/${params.activityId}${UrlPaths.ResourceName}/${params.fileId}`,
                method: "DELETE",
            }),
            invalidatesTags: processActivityFileTagInvalidator,
        }),
        changeProcessActivityFilename: builder.mutation<
            void,
            ChangeProcessActivityFilenameParams
        >({
            query(params) {
                return {
                    url: `${UrlPaths.ProcessActivities}/${params.activityId}${UrlPaths.ResourceName}/${params.fileId}`,
                    method: "PATCH",
                    body: { name: params.filename },
                };
            },
            invalidatesTags: processActivityFileTagInvalidator,
        }),
    }),
});

export const {
    useUploadProcessActivityFileMutation,
    useDeleteProcessActivityFileMutation,
    useChangeProcessActivityFilenameMutation,
} = processActivityFileApi;
