import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import authService from "../../modules/authorization/services/AuthService";

export type InvalidateTag = { type: Tags; id?: string };

export enum Tags {
    FormTemplate = "FormTemplate",
    AssetTemplate = "AssetTemplate",
    Process = "Process",
    // for process list in organisation, occurences should be replace in future with organisationId
    ProcessList = "ProcessList",
    // for process activities updates (for grids) in organisation, occurences should be replace in future with organisationId
    ProcessActivities = "ProcessActivities",
    // for process activity
    ProcessActivity = "ProcessActivity",
    ProcessActivitiesForProcess = "ProcessActivitiesForProcess",
    ProcessAssignedList = "ProcessAssignedList",
    Organisation = "Organisation",
    Organisations = "Organisations",
    OrganisationAvailableUsers = "OrganisationAvailableUsers",
    OrganisationUserGroups = "OrganisationUserGroups",
    OrganisationUserGroup = "OrganisationUserGroup",
    OrganisationUsers = "OrganisationUsers",
    OrganisationFormTemplate = "Organisation/FormTemplate",
    OrganisationAssetTemplates = "Organisation/AssetTemplates",
    ProcessReportTemplate = "ProcessReportTemplate",
    ProcessReportTemplates = "ProcessReportTemplates",
    ProcessReportTemplateFile = "ProcessReportTemplateFile",
    ProcessTemplate = "ProcessTemplate",
    OrganisationAssets = "Organisation/Assets",
    ProcessActivityAssets = "ProcessActivity/Assets",
    Asset = "Asset",
    ProcessTemplates = "ProcessTemplates",
    FilesAndFolders = "FilesAndFolders",
    ListedFolder = "ListedFolder",
    FileSearch = "File/Search",
    FileDetails = "FileDetails",
    FolderTags = "FolderTags",
    FileTags = "FileTags",
    FileDetailsComments = "FileDetailsComments",
    OrganisationHelpItemList = "Organisation/HelpItemList",
    UserCurrentRoles = "User/Current/Roles",
    UserCurrentVerified = "User/Current/Verified",
    UserCurrentOrgPermissions = "User/Current/Organisation/Permission",
    Invitation = "Invitation",
    OrganisationInvitationList = "Organisation/InvitationList",
    OrganisationAssetTags = "Organisation/AssetTags",
    OrganisationPermissions = "OrganisationPermissions",
    OrganisationTagHints = "OrganisationTagHints",
    // Cefa only Tags should be moved to src\modules\cefa\api\consumerApi.ts
    WorkbankUpload = "WorkbankUpload",
    Batch = "Batch",
    BatchExams = "BatchExams",
    MissingCarrsExam = "MissingCarrsExam",
    MissingCarrsExamUpload = "MissingCarrsExamUpload",
    BatchCesStatus = "BatchCesStatus",
    Apc = "Apc",
}

export const dcpApi = createApi({
    reducerPath: "dcpApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `/api`,
        async prepareHeaders(headers) {
            headers.set(
                "Authorization",
                `Bearer ${await authService.getAccessToken()}`,
            );
            headers.set("Accept", "application/json");
            return headers;
        },
    }),
    tagTypes: Object.values(Tags),
    endpoints: () => ({}),
    keepUnusedDataFor: 30,
});
