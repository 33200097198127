import * as React from "react";
import { PhotoOrder } from "../../types";
import { TypedGridCellProps } from "../../../../../common/types/TypedGridCellProps";
import moment from "moment";

const formatPhotoComment = (comment: string) => {
    const splitString = comment.split("$$$,");
    if (splitString.length > 1) {
        return `${splitString[0]} (${moment(splitString[1]).format(
            "DD-MM-yyyy",
        )})`;
    } else {
        return comment;
    }
};

const PhotoCaptionCell: React.FC<TypedGridCellProps<PhotoOrder>> = (props) => {
    const { dataItem } = props;

    return (
        <td>
            <p>{formatPhotoComment(dataItem.comment)}</p>
        </td>
    );
};
export default PhotoCaptionCell;
