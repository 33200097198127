import * as React from "react";
import { Breadcrumb } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import QueryResultStatus from "../../../common/components/QueryResultStatus";
import { useOrgTitleById } from "../../../common/hooks/useTitle";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import ProcessTemplateConstants from "../../../Constants/ProcessTemplateConstants";
import { useGetOrgByIdQuery } from "../../../store/features/organisation/organisation-api-slice";
import { useAddProcessTemplateMutation } from "../../../store/features/process-template/process-template-api-slice";
import { TemplateFormActionArg } from "../../template/viewModel/TemplateFormActions";
import ProcessTemplateForm from "../components/ProcessTemplateForm";
import { ProcessTemplateFormValues } from "../viewModel/ProcessTemplateFormValues";
import ProcessTemplatePageTitles from "./pageTitles";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ProcessTemplateCreatePage: React.FC = () => {
    const query = useQuery();
    const orgId = query.get("organisationId");

    useOrgTitleById(orgId, ProcessTemplatePageTitles.Create);

    const { data: organisation, ...organisationQueryResult } =
        useGetOrgByIdQuery(orgId, {
            skip: !orgId,
        });

    const [createTemplate, createTemplateResult] =
        useAddProcessTemplateMutation();
    const createTemplateActionCallback = ({
        values,
    }: TemplateFormActionArg<ProcessTemplateFormValues>) => {
        return createTemplate({
            ...values,
            config: values.config,
            states: values.statesConfig ? JSON.parse(values.statesConfig) : [],
            propertyConfig: values.propertyConfig
                ? JSON.parse(values.propertyConfig)
                : [],
            features: values.featuresConfig
                ? JSON.parse(values.featuresConfig)
                : {},
        });
    };

    const createRedirectPath = ProcessTemplateConstants.RoutePath;
    const deleteRedirectTabKey = "processTemplates";

    const initialValues: ProcessTemplateFormValues = {
        name: "",
        description: "",
        config: "[]",
        statesConfig: "[]",
        organisationId: orgId,
        propertyConfig: "[]",
        featuresConfig: "{}",
    };

    if (!organisation)
        return <QueryResultStatus queryResult={organisationQueryResult} />;

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={"div"}>
                            <Link
                                to={`${OrganisationConstants.RoutePath}/${orgId}`}
                            >
                                Manage
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link
                                to={`${OrganisationConstants.RoutePath}/${orgId}`}
                            >
                                {organisation?.features?.process
                                    ?.sideMenuName ?? "Processes"}{" "}
                                Template
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Create </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>{organisation.name}</h1>
                    <h2>
                        Create{" "}
                        {organisation?.features?.process?.sideMenuName ??
                            "Processes"}{" "}
                        template
                    </h2>
                </Col>
            </Row>
            <Row className="py-3">
                <Col>
                    <ProcessTemplateForm
                        organisationId={orgId}
                        initialValues={initialValues}
                        createTemplate={{
                            actionCallback: createTemplateActionCallback,
                            actionResult: createTemplateResult,
                        }}
                        createRedirectPath={createRedirectPath}
                        deleteRedirectTabKey={deleteRedirectTabKey}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default ProcessTemplateCreatePage;
