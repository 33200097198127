import {
    Grid,
    GridColumn,
    GridPagerSettings,
} from "@progress/kendo-react-grid";
import * as React from "react";
import { Button } from "react-bootstrap";
import { TypedGridCellProps } from "../../../common/types/TypedGridCellProps";
import { formatIsoDate } from "../../../helpers/dateTimeHelpers";
import ReportService from "../../../services/ReportService";
import {
    ProcessActivityArtefactGenerationRequest,
    ProcessActivityArtefactGenerationState,
} from "../domain/types";
import useGridProps from "../../../common/components/table/kendo/useGridProps";
import { basicPagerSettings } from "../../../common/components/table/kendo/basicPagerSettings";

interface Props {
    activityId: string;
    items: ProcessActivityArtefactGenerationRequest[];
}

const ProcessActivityArtefactGenerationList: React.FC<Props> = ({
    activityId,
    items,
}) => {
    const paggerSettings: GridPagerSettings = {
        ...basicPagerSettings,
        pageSizes: false,
    };

    const { gridProps } = useGridProps(items, {
        filterable: false,
        sortable: true,
        pageable: true,
        initialPageSize: 10,
        pagerSettings: paggerSettings,
    });

    const StatusCell = (
        cell: TypedGridCellProps<ProcessActivityArtefactGenerationRequest>,
    ) => <td>{SelectStateCell(cell.dataItem.state)}</td>;

    const ResultCell = (
        cell: TypedGridCellProps<ProcessActivityArtefactGenerationRequest>,
    ) => (
        <td className="text-center">
            {cell.dataItem.state ===
                ProcessActivityArtefactGenerationState.Completed && (
                <Button
                    variant="primary"
                    onClick={() =>
                        ReportService.DownloadFile(activityId, cell.dataItem.id)
                    }
                >
                    Download
                </Button>
            )}
        </td>
    );

    const DateCell = (
        cell: TypedGridCellProps<ProcessActivityArtefactGenerationRequest>,
    ) => <td>{formatIsoDate(cell.dataItem.createdOn)}</td>;

    const SelectStateCell = (state: ProcessActivityArtefactGenerationState) => {
        switch (state) {
            case ProcessActivityArtefactGenerationState.Completed:
                return <span className="badge badge-success">Completed</span>;
            case ProcessActivityArtefactGenerationState.InProgress:
                return <span className="badge badge-warning">In progress</span>;
            case ProcessActivityArtefactGenerationState.Failed:
                return <span className="badge badge-danger">Failed</span>;
            case ProcessActivityArtefactGenerationState.Pending:
                return <span className="badge badge-info">Pending</span>;
            default:
                return null;
        }
    };
    return (
        <>
            <Grid {...gridProps}>
                <GridColumn
                    field="state"
                    title="State"
                    width="125px"
                    cell={StatusCell}
                />
                <GridColumn field="createdBy" title="Created by" />
                <GridColumn
                    field="createdOn"
                    title="Created on"
                    cell={DateCell}
                />
                <GridColumn
                    width="125px"
                    sortable={false}
                    field="result"
                    title="Result"
                    cell={ResultCell}
                />
            </Grid>
        </>
    );
};

export default ProcessActivityArtefactGenerationList;
