import * as yup from "yup";
import { RequiredStringSchema } from "yup/lib/string";
import { AnyObject } from "yup/lib/types";

export const parseJson = (json: string): unknown => {
    try {
        const parsedJson = JSON.parse(json);
        return parsedJson;
    } catch {
        return;
    }
};

export const cleanJson = (
    record: Record<string, unknown>,
): Record<string, unknown | unknown[]> => {
    const cleaned = { ...record };
    for (const propName in record) {
        if (!record[propName] || record[propName] === "") {
            delete cleaned[propName];
        }
        if (
            Array.isArray(record[propName]) &&
            (record[propName] as unknown[]).length === 0
        ) {
            //delete cleaned[propName];
        }
    }
    return cleaned;
};

export const yupJson = (): RequiredStringSchema<string, AnyObject> =>
    yup
        .string()
        .required("Required")
        .test("is-correct-config", "Configuration error", (rawConfig) => {
            const config = parseJson(rawConfig);
            if (!config) {
                return false;
            }
            return true;
        });
