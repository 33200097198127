import React, { useCallback } from "react";
import {
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from "react-query";
import examinationQueryKeys from "../../../examinations/query/examinationQueryKeys";
import {
    changeExamType,
    getInfiniteExamManagement,
} from "../services/examManagementService";
import { ExamManagement } from "../../../examinations/domain/examManagement";
import { ChangeExamTypeModel } from "../../../examinations/domain/examManagementDtos";

export const useGetInfiniteExamManagementExamQuery = (
    organisationId: string,
): UseQueryResult<ExamManagement[]> => {
    const getExaminations = useCallback((): Promise<ExamManagement[]> => {
        return getInfiniteExamManagement(organisationId);
    }, [organisationId]);

    const queryKeys = React.useMemo(
        () => examinationQueryKeys.examinationManagement(organisationId),
        [organisationId],
    );

    return useQuery<ExamManagement[], [string]>(queryKeys, getExaminations);
};

type ScheduleMutationResult = UseMutationResult<
    unknown,
    unknown,
    ChangeExamTypeModel
>;
export const useChangeExamTypeMutation = (
    organisationId: string,
): ScheduleMutationResult => {
    const queryClient = useQueryClient();

    const scheduleExaminationsMutationFunction = (
        patchModel: ChangeExamTypeModel,
    ) => changeExamType({ organisationId, patchModel });

    return useMutation(scheduleExaminationsMutationFunction, {
        onSuccess(_result, args) {
            return queryClient.invalidateQueries({
                queryKey:
                    examinationQueryKeys.examinationManagement(organisationId),
            });
        },
    });
};
