import {
    CompositeFilterDescriptor,
    FilterDescriptor,
} from "@progress/kendo-data-query";
import BusinessRule from "../common/types/BusinessRule";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isBusinessRule(data): data is BusinessRule {
    return (
        "type" in data &&
        "title" in data &&
        "status" in data &&
        "detail" in data
    );
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isFilterDescriptor(data): data is FilterDescriptor {
    return "field" in data;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isCompositeFilterDesc(data): data is CompositeFilterDescriptor {
    return "logic" in data;
}
