import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import * as AdminStore from "../../../../store/admin/store";
import ConfirmBox from "../../../../common/components/kendo-dialog-boxes/ConfirmBox";

interface Props {
    userId: string;
}

const RoleUserTableActions: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [showConfirmation, setShowConfirmation] = useState(false);

    const roleId = useSelector((state: ApplicationState) => {
        return (
            state.admin?.domain.role.id ??
            AdminStore.unloadedState.domain.role.id
        );
    });

    const onRemove = () => {
        dispatch(
            AdminStore.actionCreators.removeRoleUser(roleId, props.userId),
        );
        setShowConfirmation(false);
    };

    const clickRemove = () => {
        setShowConfirmation(true);
    };

    return (
        <>
            <Button
                variant="danger"
                className="top5 ml-1"
                onClick={clickRemove}
            >
                Remove
            </Button>
            {showConfirmation && (
                <ConfirmBox
                    title="Remove User"
                    message="Are you sure you want to remove this user?"
                    onCancel={() => setShowConfirmation(false)}
                    onConfirm={onRemove}
                />
            )}
        </>
    );
};

export default RoleUserTableActions;
