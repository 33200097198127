import { isNil } from "lodash";

export interface SortAndFilterOptions {
    filterParam?: FilterParam;
    sortParam?: SortParam;
}

export interface InfiniteQueryOptions extends SortAndFilterOptions {
    continuationToken?: string;
}

export interface SortParam {
    field: string;
    isDesc: boolean;
    isCustomField?: boolean;
}

export type FilterParam = FilterModel[];

export interface FilterModel {
    field: string;
    dataType: string;
    operator: string;
    value: string;
    isCustomField: boolean;
}

export const searchOptionsKey = (
    searchOptions: SortAndFilterOptions,
): string[] => {
    if (isNil(searchOptions)) return [];

    const { filterParam, sortParam } = searchOptions;

    const filterKeys = isNil(filterParam)
        ? []
        : filterParam.map((f) => `${f.field}.${f.operator}:${f.value}`);

    const sortKey = isNil(sortParam?.field)
        ? []
        : [`sort:${sortParam.field}:${sortParam.isDesc}`];

    return [...filterKeys, ...sortKey];
};
