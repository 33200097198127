import * as React from "react";
import ExcelReportDownload from "../../common/components/ExcelReportDownload";
import { useGetProgressReportMutation } from "../queries/progressReportQueries";

const ProgressReportExportPage: React.FC = () => {
    return (
        <ExcelReportDownload
            pageTitle="Progress Report Download"
            callToAction="Select Contract Year"
            fileName="Progress Report"
            includeDateInFileName
            mutationFn={useGetProgressReportMutation}
        />
    );
};

export default ProgressReportExportPage;
