import * as React from "react";
import { FaDownload } from "react-icons/fa";
import NavLink from "../../../../common/components/NavLink";
import useViewPermissions from "../../../../common/hooks/useViewPermissions";
import { AppRoutes } from "../../../../router/AppRoutes";
import { useNavigation } from "../../../../router/useNavigate";
import { useOrganisationId } from "../../../organisation/hooks/useOrganisationId";

const HseqSideMenu: React.FC = () => {
    const { getOrgLink: getLink } = useNavigation();

    const { organisationId } = useOrganisationId();

    const { canViewReports } = useViewPermissions(organisationId);

    return (
        <>
            {canViewReports && (
                <NavLink
                    to={getLink(AppRoutes.SsowDataExport)}
                    label="Data Export"
                    icon={FaDownload}
                    eventKey="ssow-data-export"
                />
            )}
        </>
    );
};

export default HseqSideMenu;
