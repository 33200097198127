import PermissionConstants from "../../../../../common/permissions/PermissionConstants";
import { hasUserOrgPermission } from "../../../../../store/features/user/user-api-slice";

type HookResult = {
    canPlanExamination: boolean;
    canCancelExaminations: boolean;
};
export const useExaminationSchedulerPermissions = (
    organisationId: string,
): HookResult => {
    const canPlanExamination = hasUserOrgPermission(
        organisationId,
        PermissionConstants.Examinations.plan,
    ).hasPermission;

    const canCancelExaminations = hasUserOrgPermission(
        organisationId,
        PermissionConstants.Examinations.cancel,
    ).hasPermission;

    return {
        canPlanExamination,
        canCancelExaminations,
    };
};
