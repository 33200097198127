import * as React from "react";
import { FileButton } from "../../../common/components/button/FileButton";
import { UploadProcessActivityFileParams } from "../../../store/features/process-activity-file/processActivityFileApiSlice";
import { ProcessActivityType } from "../../process-activity/domain/types";

interface FileUploadButtonProps {
    activityId: string;
    uploadFile: (params: UploadProcessActivityFileParams) => void;
    paType: ProcessActivityType;
}

export const FileUploadButton: React.FC<FileUploadButtonProps> = ({
    activityId,
    uploadFile,
    paType,
}) => {
    const handleFileSelection = (ev: React.ChangeEvent<HTMLInputElement>) =>
        Array.from(ev.target.files || []).forEach((file) =>
            uploadFile({ activityId, file, paType }),
        );

    return <FileButton onChange={handleFileSelection}>Upload file</FileButton>;
};
