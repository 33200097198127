import * as React from "react";
import { useState } from "react";
import * as Formik from "formik";
import { RouteComponentProps } from "react-router";
import severnPageTitles from "../../pages/pageTitles";
import { useOrgTitleWithShortName } from "../../../../common/hooks/useTitle";
import { useOrganisationId } from "../../../organisation/hooks/useOrganisationId";
import CoveringLoadingPanel from "../../../../common/components/CoveringLoadingPanel";
import { Breadcrumb, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import OrganisationConstants from "../../../../Constants/OrganisationConstants";
import { DateTimeSelect } from "../../../../common/components/form";
import { useDefectsReportMutation } from "../queries/defectsReportQueries";
import * as yup from "yup";
import { OrgReportsDefectsFormModel } from "../domain/OrgReportsDefectsDtos";
import { useGetUserRequestedFileStatus } from "../../../user-requested-files/queries/userRequestedFileQueries";
import UserRequestedFileStatusDownload from "../../../user-requested-files/components/UserRequestedFileStatusDownload";

interface DefectReportFormValues {
    startDate: Date;
    endDate: Date;
    defectType: string;
    includeFiles: boolean;
}

const OrgDefectsReportPage: React.FC<
    RouteComponentProps<{ orgShortName: string }>
> = (props) => {
    const pageTitle = severnPageTitles.defects;
    const orgShortName = props.match.params.orgShortName;
    const { organisationId, isLoadingOrgId } = useOrganisationId();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [buttonStatus, setButtonStatus] = useState(true);

    useOrgTitleWithShortName(pageTitle);

    const defectReportMutation = useDefectsReportMutation(organisationId);

    const formInitialValues: OrgReportsDefectsFormModel = {
        startDate: null,
        endDate: null,
        defectType: "All",
        includeFiles: false,
    };

    const onSubmit = React.useCallback(
        (
            {
                startDate,
                endDate,
                defectType,
                includeFiles,
            }: DefectReportFormValues,
            { setSubmitting }: Formik.FormikHelpers<DefectReportFormValues>,
        ) => {
            console.log("hit");
            setIsSubmitted(() => true);
            setButtonStatus(() => false);
            defectReportMutation.mutate({
                startDate,
                endDate,
                defectType,
                includeFiles,
            });
        },
        [defectReportMutation],
    );

    const { data: fileStatusData } = useGetUserRequestedFileStatus(
        organisationId,
        defectReportMutation.data?.id,
    );

    React.useMemo(() => {
        if (
            fileStatusData?.status === "Complete" ||
            fileStatusData?.status === "Failed"
        ) {
            setButtonStatus(true);
        }
    }, [fileStatusData]);

    const validation = yup.object({
        startDate: yup.date().required("Start Date is required").nullable(),
        endDate: yup
            .date()
            .required("End Date is required")
            .nullable()
            .min(
                yup.ref("startDate"),
                "End date cannot be before the Start date",
            ),
    });

    if (isLoadingOrgId) return <CoveringLoadingPanel />;

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item linkAs={"div"}>
                    <Link
                        to={`/${orgShortName}${OrganisationConstants.Report}`}
                    >
                        Reports
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    {severnPageTitles.defects}
                </Breadcrumb.Item>
            </Breadcrumb>
            <div>
                <div
                    style={{
                        opacity: isLoadingOrgId ? "0.4" : "1",
                        transition: "opacity 0.3s ease",
                    }}
                >
                    {pageTitle && <h1>{pageTitle}</h1>}
                    {isSubmitted &&
                        (defectReportMutation.data ? (
                            <UserRequestedFileStatusDownload
                                fileId={defectReportMutation.data.id}
                            />
                        ) : (
                            <CoveringLoadingPanel />
                        ))}
                </div>
                <Formik.Formik
                    enableReinitialize
                    initialValues={formInitialValues}
                    onSubmit={onSubmit}
                    validationSchema={validation}
                >
                    {({ isSubmitting }) => {
                        return (
                            <div style={{ width: "300px" }}>
                                <Formik.Form>
                                    <Form.Group>
                                        <DateTimeSelect
                                            type="date"
                                            label="Start Date"
                                            name="startDate"
                                        ></DateTimeSelect>
                                        <DateTimeSelect
                                            type="date"
                                            label="End Date"
                                            name="endDate"
                                        ></DateTimeSelect>
                                        <div id="defect-options">
                                            Defect Options
                                        </div>
                                        <fieldset
                                            role="group"
                                            aria-labelledby="defect-options"
                                        >
                                            <div>
                                                <label>
                                                    <Formik.Field
                                                        type="radio"
                                                        name="defectType"
                                                        value="All"
                                                        checked="checked"
                                                    />
                                                    All
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <Formik.Field
                                                        type="radio"
                                                        name="defectType"
                                                        value="Active"
                                                    />
                                                    Active
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <Formik.Field
                                                        type="radio"
                                                        name="defectType"
                                                        value="Repaired"
                                                    />
                                                    Repaired
                                                </label>
                                            </div>
                                        </fieldset>
                                        <div id="checkbox-group">
                                            <label>
                                                <Formik.Field
                                                    type="checkbox"
                                                    name="includeFiles"
                                                />
                                                Include Files
                                            </label>
                                        </div>
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            disabled={!buttonStatus}
                                        >
                                            Generate
                                        </Button>
                                    </Form.Group>
                                </Formik.Form>
                            </div>
                        );
                    }}
                </Formik.Formik>
            </div>
        </>
    );
};
export default OrgDefectsReportPage;
