import React from "react";
import { Button } from "react-bootstrap";
import ApiConstants from "../../../Constants/ApiConstants";
import { DownloadFile } from "../../../helpers/ApiHelper";
import { HttpMethod } from "../../../common/http/HttpMethod";
import { GridCellProps } from "@progress/kendo-react-grid";
import { RequestedFileDto } from "../queries/types";
import { useAppSelector } from "../../../store/hooks";

const baseUrl = `${ApiConstants.ApiPrefix}/organisation`;

const UserRequestedFilesHistoryDownloadButtonCell: React.FC<GridCellProps> = (
    props,
) => {
    const user = useAppSelector((state) => state.authUser);
    const reportInfo = props.dataItem as RequestedFileDto;
    const downloadReport = React.useCallback(async () => {
        DownloadFile({
            method: HttpMethod.Get,
            url: `${baseUrl}/${reportInfo.organisationId}/UserRequestedFiles/GetUserRequestedFile/${reportInfo.id}`,
        });
    }, [reportInfo]);

    return user.username.toUpperCase() ===
        reportInfo.requestedBy.toUpperCase() ? (
        <td>
            <Button onClick={() => downloadReport()}>Download</Button>
        </td>
    ) : (
        <td />
    );
};

export default UserRequestedFilesHistoryDownloadButtonCell;
