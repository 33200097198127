import {
    AdminRole,
    Role,
    RoleUser,
} from "../../modules/admin/role/domain/types";
// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export const REQUEST_ROLES = "REQUEST_ROLES";
export const RECEIVE_ROLES = "RECEIVE_ROLES";
export const REQUEST_ROLE = "REQUEST_ROLE";
export const RECEIVE_ROLE = "RECEIVE_ROLE";
export const ADMIN_ROLE_USER_REMOVE_SUCCESS = "ADMIN_ROLE_USER_REMOVE_SUCCESS";
export const ADMIN_ROLE_USER_ADD_START = "ADMIN_ROLE_USER_ADD_START";
export const ADMIN_ROLE_USER_ADD_CANCEL = "ADMIN_ROLE_USER_ADD_CANCEL";
export const ADMIN_ROLE_USER_ADD_SUCCESS = "ADMIN_ROLE_USER_ADD_SUCCESS";
export const ADMIN_ROLE_USER_ADD_RECEIVE_USERS =
    "ADMIN_ROLE_USER_ADD_RECEIVE_USERS";
export const ADMIN_ROLE_USERS_RECEIVE = "ADMIN_ROLE_USERS_RECEIVE";

export interface RequestRolesAction {
    type: typeof REQUEST_ROLES;
}

export interface ReceiveRolesAction {
    type: typeof RECEIVE_ROLES;
    data: Role[];
}

export interface RequestRoleAction {
    type: typeof REQUEST_ROLE;
}

export interface ReceiveRoleAction {
    type: typeof RECEIVE_ROLE;
    data: AdminRole;
}

export interface AdminRoleUserRemoveSuccessAction {
    type: typeof ADMIN_ROLE_USER_REMOVE_SUCCESS;
    data: {
        roleId: string;
        userId: string;
    };
}

export interface AdminRoleUserAddStartAction {
    type: typeof ADMIN_ROLE_USER_ADD_START;
}

export interface AdminRoleUserAddCancelAction {
    type: typeof ADMIN_ROLE_USER_ADD_CANCEL;
}

export interface AdminRoleUserAddSuccessAction {
    type: typeof ADMIN_ROLE_USER_ADD_SUCCESS;
}

export interface AdminRoleUserAddUserReceiveUsersAction {
    type: typeof ADMIN_ROLE_USER_ADD_RECEIVE_USERS;
    data: RoleUser[];
}

export interface AdminRoleUsersReceiveAction {
    type: typeof ADMIN_ROLE_USERS_RECEIVE;
    data: RoleUser[];
}
