import { FormikProps } from "formik";
import React from "react";
import { SelectOptionItem } from "../../../common/types/reactSelect/SelectOptionItem";
import { ProcessTemplateListItem } from "../../process-template/domain/types";
import { FormValues } from "../containers/ProcessCreateForm";

export interface AssignedToWrapperProps {
    formik: FormikProps<FormValues>;
    processTemplates: ProcessTemplateListItem[];
}

export const AssignedToWrapper: React.FC<AssignedToWrapperProps> = ({
    formik,
    processTemplates,
    children,
}) => {
    const selectedTemplateOption = (
        formik.values["processTemplate"] as SelectOptionItem
    )?.value;

    const isUserAssignmentEnabled = React.useMemo(() => {
        const selectedTemplateData = processTemplates?.find(
            (t) => t.id === selectedTemplateOption,
        );

        return selectedTemplateData?.features.userAssignment.enabled;
    }, [processTemplates, selectedTemplateOption]);

    React.useEffect(() => {
        if (!isUserAssignmentEnabled) {
            formik.setFieldValue("assignedTo", null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserAssignmentEnabled]);

    if (!isUserAssignmentEnabled) {
        return null;
    }

    return <>{children}</>;
};
