import * as React from "react";
import { useRef } from "react";
import { Tab, Tabs } from "react-bootstrap";
import AuditActionsList from "../../../common/components/audit/AuditActionsList";
import { DynamicFormFormikProps } from "../../../common/components/dynamic-form/types/dynamicFormTypes";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";
import { useGetProcessByIdQuery } from "../../../store/features/process/process-api-slice";
import AssetLinkTab from "../../process-activity/components/AssetLinkTab";
import ProcessActivityDetailsForm from "../../process-activity/components/ProcessActivityDetailsForm";
import ProcessActivityWrapper from "../../process-activity/components/ProcessActivityWrapper";
import { ProcessActivityExtensions } from "../../process-activity/domain/ProcessActivityExtensions";
import { ProcessActivityType } from "../../process-activity/domain/types";
import { resolveActivityState } from "../../process-activity/helpers/activityStateHelpers";
import { formatActivityPageTitle } from "../../process-activity/pages/pageTitles";
import ProcessActivityFilesSection from "../../processActivityFile/ProcessActivityFilesSection";
import {
    useGetProcessActivityFormByIdQuery,
    usePatchProcessActivityFormMutation,
    usePostProcessActivityFormCompleteMutation,
    usePostProcessActivityFormNotApplicableMutation,
    usePostProcessActivityFormOpenMutation,
} from "../store/processActivityFormApiSlice";
import ProcessActivityFormForm from "./ProcessActivityFormForm";
import { Organisation } from "../../organisation/domain/types";

interface Props {
    processId: string;
    activityId: string;
    organisation: Organisation;
    disableTopBar?: boolean;
    setUserHasCompleted?: (isComplete: boolean) => void;
}

const ProcessActivityFormWrapper: React.FC<Props> = ({
    processId,
    activityId,
    organisation,
    disableTopBar,
    setUserHasCompleted,
}) => {
    const formikRef = useRef<DynamicFormFormikProps>();

    const { data: process, ...processResult } = useGetProcessByIdQuery(
        processId,
        { skip: !processId },
    );

    const { data: activityForm, ...activityFormResult } =
        useGetProcessActivityFormByIdQuery(
            { activityId, processId },
            {
                skip: !activityId,
            },
        );

    useOrgTitleWithShortName(
        formatActivityPageTitle(
            process?.name,
            activityForm?.displayName,
            !process || !activityForm,
        ),
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [completeForm, completeFormResult] =
        usePostProcessActivityFormCompleteMutation();
    const [setFormNotApplicable, notApplicableFormResult] =
        usePostProcessActivityFormNotApplicableMutation();
    const [openForm, openFormResult] = usePostProcessActivityFormOpenMutation();
    const [updateForm, updateFormResult] =
        usePatchProcessActivityFormMutation();

    const formikPostComplete = (): void => {
        formikRef.current.handleSubmit();
    };

    const { canEdit } = resolveActivityState(activityForm);
    const assetLinkEnabled = activityForm?.assetLink?.enabled;
    const canCopyActivity =
        activityForm?.parentActivityType === ProcessActivityType.List;
    const canDisplayOptions =
        ProcessActivityExtensions.canDisplayOptions(activityForm);

    return (
        <ProcessActivityWrapper
            processQuery={[process, processResult]}
            activityQuery={[activityForm, activityFormResult]}
            activityActions={{
                complete: {
                    actionCallback: formikPostComplete,
                    actionResult: completeFormResult,
                },
                notApplicable: {
                    actionCallback: setFormNotApplicable,
                    actionResult: notApplicableFormResult,
                },
                open: {
                    actionCallback: openForm,
                    actionResult: openFormResult,
                },
            }}
            organisation={organisation}
            disableTopBar={disableTopBar ?? false}
        >
            {process && activityForm && (
                <Tabs defaultActiveKey="content" id="tab">
                    <Tab eventKey="content" title="Content">
                        <ProcessActivityFormForm
                            activity={activityForm}
                            formikRef={formikRef}
                            onComplete={async (): Promise<void> => {
                                await completeForm({ activity: activityForm });
                                if (setUserHasCompleted) {
                                    setUserHasCompleted(true);
                                }
                            }}
                        />
                    </Tab>
                    {(canDisplayOptions || canCopyActivity) && (
                        <Tab eventKey="Options" title="Options">
                            {canDisplayOptions && (
                                <ProcessActivityDetailsForm
                                    organisationId={process.organisationId}
                                    activity={activityForm}
                                    updateProps={{
                                        actionCallback: updateForm,
                                        actionResult: updateFormResult,
                                    }}
                                />
                            )}
                        </Tab>
                    )}
                    {assetLinkEnabled && (
                        <Tab eventKey="asset" title="Assets">
                            <AssetLinkTab
                                activity={activityForm}
                                organisation={organisation}
                            />
                        </Tab>
                    )}
                    <Tab eventKey="history" title="History">
                        <AuditActionsList
                            auditActions={activityForm.auditActions ?? []}
                        />
                    </Tab>
                    {activityForm.fileManagementTabEnabled && (
                        <Tab eventKey="files" title="Files">
                            <ProcessActivityFilesSection
                                paFiles={activityForm.files}
                                activityId={activityForm.id}
                                paType={ProcessActivityType.Form}
                                readOnly={!canEdit}
                                organisation={organisation}
                            />
                        </Tab>
                    )}
                </Tabs>
            )}
        </ProcessActivityWrapper>
    );
};

export default ProcessActivityFormWrapper;
