import { dcpApi, Tags } from "../dcpApi";
import { HttpMethod } from "../../../common/http/HttpMethod";
import { OrganisationAndFolderIds } from "../../../modules/files/utils/OrganisationAndFolderIds";
import { FileComment } from "../../../modules/files/domain/file";

export interface FileIdsParams extends OrganisationAndFolderIds {
    fileId: string;
}
export interface AddFileCommentModel {
    organisationId: string;
    fileId: string;
    folderId: string;
    data: string;
}
export interface UpdateFileCommentModel {
    organisationId: string;
    fileId: string;
    folderId: string;
    commentId: string;
    data: string;
}
export interface DeleteFileCommentModel {
    organisationId: string;
    fileId: string;
    folderId: string;
    commentId: string;
}

const getBaseUrl = ({
    organisationId,
    folderId,
}: OrganisationAndFolderIds): string =>
    `organisation/${organisationId}/folder/${folderId}/fileComments`;

const fileCommentDetailsTagInvalidator = (
    _,
    error,
    params: { fileId: string },
) =>
    error
        ? []
        : [
              {
                  type: Tags.FileDetailsComments,
                  id: params.fileId,
              },
          ];

export const fileCommentsApi = dcpApi.injectEndpoints({
    endpoints: (builder) => ({
        getFileComments: builder.query<FileComment[], FileIdsParams>({
            query: (params) => [getBaseUrl(params), params.fileId].join("/"),
            providesTags: fileCommentDetailsTagInvalidator,
        }),
        addFileComment: builder.mutation<void, AddFileCommentModel>({
            query(model) {
                return {
                    url: getBaseUrl(model),
                    method: HttpMethod.Post,
                    body: model,
                };
            },
            invalidatesTags: fileCommentDetailsTagInvalidator,
        }),
        updateFileComment: builder.mutation<void, UpdateFileCommentModel>({
            query(model) {
                return {
                    url: getBaseUrl(model),
                    method: HttpMethod.Patch,
                    body: model,
                };
            },
            invalidatesTags: fileCommentDetailsTagInvalidator,
        }),
        deleteFileComment: builder.mutation<void, DeleteFileCommentModel>({
            query(model) {
                return {
                    url: `${getBaseUrl(model)}/${model.commentId}`,
                    method: HttpMethod.Delete,
                };
            },
            invalidatesTags: fileCommentDetailsTagInvalidator,
        }),
    }),
});

export const {
    useGetFileCommentsQuery,
    useAddFileCommentMutation,
    useUpdateFileCommentMutation,
    useDeleteFileCommentMutation,
} = fileCommentsApi;
