import {
    ApprovalResponseType,
    ApprovalStatus,
    Approver,
    ProcessActivityApproval,
    TargetFile,
    TargetLink,
} from "../domain/types";
import { BaseUser } from "../../organisation/domain/organisation";
import { ProcessActivity } from "../../process-activity/domain/types";
import { TemplateContentFilter } from "../../common/filters/domain/types";

export interface ApprovalCommentDto {
    message: string;
}

export interface ApprovalDto {
    id: string;
    approver: Approver;
    status: ApprovalStatus;
    comments: ApprovalCommentDto[];
    updatedAt?: string;
}

export interface ApprovalResponseDto {
    id: string;
    approver: Approver;
    responseType: ApprovalResponseType;
    comments: ApprovalCommentDto[];
    performedAt: string;
}

export interface ProcessActivityApprovalDto extends ProcessActivity {
    approvals: ApprovalDto[];
    approvalResponses: ApprovalResponseDto[];
    requiresAllApprovals: boolean;
    approversFilter: TemplateContentFilter;
    targetType: string;
    targetFile: TargetFile;
    targetLink: TargetLink;
    approversReadonly: boolean;
    approvalActionDisplayName: string;
    rejectActionDisplayName: string;
    approvalActionHistoricalDisplayName: string;
    rejectActionHistoricalDisplayName: string;
    approversDisplayName: string;
    approvalDisplayName: string;
    addActionDisplayName: string;
    approveCommentDisabled: boolean;
}

export interface ProcessActivityTemplateContentFilterDto {
    property: string;
    operator: string;
    values: string[];
}

export interface ProcessActivityApprovalRequest {
    approvers: Approver[];
    requiresAllApprovals: boolean;
}

export interface ApprovalRequest {
    comments: ApprovalCommentDto[];
}

export interface RejectRequest {
    comments: ApprovalCommentDto[];
}

export interface UpdateRequireAllApprovalsRequest {
    requiresAllApprovals: boolean;
}

export interface AddApproverRequest {
    approver: Approver;
}
export interface RedirectResponse {
    redirectUrl?: string;
}

export const mapDtoToModel = (
    dto: ProcessActivityApprovalDto,
): ProcessActivityApproval => {
    const {
        approvals,
        approvalResponses,
        requiresAllApprovals,
        targetType,
        targetFile,
        targetLink,
        approvalActionDisplayName,
        rejectActionDisplayName,
        approvalActionHistoricalDisplayName,
        rejectActionHistoricalDisplayName,
        approversDisplayName,
        approvalDisplayName,
        addActionDisplayName,
        ...processActivityRest
    } = dto;

    return {
        ...processActivityRest,
        approvals: approvals.map((approvalDto) => ({
            id: approvalDto.id,
            approver: approvalDto.approver,
            status: approvalDto.status,
            comments: approvalDto.comments.map(
                (commentDto) => commentDto.message,
            ),
            updatedAt: approvalDto.updatedAt,
        })),
        approversFilter: processActivityRest.approversFilter,
        requiresAllApprovals: requiresAllApprovals,
        approvalResponses: (approvalResponses || []).map((responseDto) => ({
            id: responseDto.id,
            approver: responseDto.approver,
            responseType: responseDto.responseType,
            comments: responseDto.comments.map(
                (commentDto) => commentDto.message,
            ),
            performedAt: responseDto.performedAt,
        })),
        targetType: targetType,
        targetFile: targetFile,
        targetLink: targetLink,
        approvalActionDisplayName,
        rejectActionDisplayName,
        approvalActionHistoricalDisplayName:
            approvalActionHistoricalDisplayName,
        rejectActionHistoricalDisplayName: rejectActionHistoricalDisplayName,
        approversDisplayName: approversDisplayName,
        approvalDisplayName: approvalDisplayName,
        addActionDisplayName: addActionDisplayName,
    };
};

export const mapActivityToSaveRequest = (
    model: ProcessActivityApproval,
): ProcessActivityApprovalRequest => ({
    approvers: model.approvals.map((a) => a.approver),
    requiresAllApprovals: model.requiresAllApprovals,
});

export const mapCommentToApprovalRequest = (
    comment: string,
): ApprovalRequest => ({
    comments: [{ message: comment }],
});

export const mapCommentToRejectRequest = (comment: string): RejectRequest => ({
    comments: [{ message: comment }],
});

export const mapFlagToUpdateRequireAllApprovalsRequest = (
    flag: boolean,
): UpdateRequireAllApprovalsRequest => ({
    requiresAllApprovals: flag,
});

export const mapApproverToAddApproverRequest = (
    user: BaseUser,
): AddApproverRequest => ({
    approver: { userId: user.userId, userEmail: user.username },
});
