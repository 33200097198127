import React from "react";
import "./ButtonSpinner.css";

const ButtonSpinner: React.FC = () => {
    return (
        <>
            {" "}
            <div className="btn-spinner ml-0" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </>
    );
};
export default ButtonSpinner;
