import React from "react";
import { Col } from "react-bootstrap";
import LoadingSpinner from "../../../../common/components/CoveringLoadingPanel";
import { useGetHelpItemsByOrgIdQuery } from "../../../../store/features/help-items/help-items-api-slice";
import { HelpItem } from "../../../help-section/domain/types";
import HelpSectionHelpListItem from "./HelpSectionHelpListItem";

interface Props {
    organisationId: string;
}

const HelpSectionHelpItemList: React.FC<Props> = ({ organisationId }) => {
    const { data: helpItems, isLoading } = useGetHelpItemsByOrgIdQuery(
        { organisationId },
        { skip: organisationId === "" },
    );

    if (isLoading || !helpItems) {
        return <LoadingSpinner />;
    }

    return (
        <Col>
            {helpItems &&
                helpItems.length !== 0 &&
                helpItems.map((item: HelpItem) => (
                    <HelpSectionHelpListItem
                        key={item.id}
                        helpItem={item}
                        organisationId={organisationId}
                    />
                ))}
        </Col>
    );
};

export default HelpSectionHelpItemList;
