import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { AssetModel } from "../../../store/features/asset/asset-api-slice";

export enum AssetUrlPaths {
    ResourceName = "/asset",
    Tags = "/tags",
    Content = "/content",
}

export const getBaseAssetUrl = (organisationId: string): string =>
    `${OrganisationConstants.RoutePath}/${organisationId}${AssetUrlPaths.ResourceName}`;

export const getAssetFileResUrl = (asset: AssetModel) => {
    return (fileId: string): string =>
        asset?.id && asset?.organisationId && fileId
            ? `/api${getBaseAssetUrl(asset.organisationId)}/${
                  asset.id
              }/files/${fileId}`
            : "";
};
