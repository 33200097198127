import * as Formik from "formik";
import * as React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import * as yup from "yup";
import CustomSelect from "../../../../common/components/form/CustomSelect";
import {
    mapToSelectOptions,
    SelectOptionItem,
} from "../../../../common/types/reactSelect/SelectOptionItem";
import { useAddUserToGroupMutation } from "../../../../store/features/organisation/organisation-api-slice";
import { OrganisationUser } from "../../../organisation/domain/types";

interface FormValues {
    selectedUser: SelectOptionItem;
}

const validation = yup.object({
    selectedUser: yup.object().nullable().required("Required"),
});

interface Props {
    orgId: string;
    groupId: string;
    users: OrganisationUser[];
}

const UserGroupAddUserForm: React.FC<Props> = (props) => {
    const [isAddingUser, setIsAddingUser] = useState(false);
    const [addUserToGroup] = useAddUserToGroupMutation();

    const initialUserFormValues: FormValues = React.useMemo(() => {
        return { selectedUser: null };
    }, []);

    const onSubmit = async (
        values: FormValues,
        { setSubmitting, resetForm }: Formik.FormikHelpers<FormValues>,
    ): Promise<void> => {
        setSubmitting(true);
        await addUserToGroup({
            orgId: props.orgId,
            groupId: props.groupId,
            body: { userId: values.selectedUser?.value },
        });

        resetForm();
        setSubmitting(false);
        setIsAddingUser(false);
    };

    if (isAddingUser) {
        return (
            <div className="pt-2">
                <Formik.Formik
                    enableReinitialize
                    initialValues={initialUserFormValues}
                    validationSchema={validation}
                    onSubmit={onSubmit}
                >
                    {({ handleSubmit, isSubmitting }): JSX.Element => (
                        <Formik.Form translate="yes" onSubmit={handleSubmit}>
                            <CustomSelect
                                name="selectedUser"
                                label="Add user"
                                placeholder="Select user..."
                                options={mapToSelectOptions(
                                    props.users,
                                    (u) => u.userId,
                                    (u) => u.username,
                                )}
                            />
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                Add
                            </Button>
                            <Button
                                variant="outline-secondary"
                                className="ml-1"
                                onClick={() => setIsAddingUser(false)}
                            >
                                Cancel
                            </Button>
                        </Formik.Form>
                    )}
                </Formik.Formik>
            </div>
        );
    } else {
        return (
            <div className="d-flex justify-content-end">
                <Button onClick={() => setIsAddingUser(true)}>Add User</Button>
            </div>
        );
    }
};

export default UserGroupAddUserForm;
