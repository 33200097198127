import * as React from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import AdminConstants from "../../../../Constants/AdminConstants";

interface Props {
    name: string;
    id: string;
}

const RolesListItem: React.FC<Props> = (props) => {
    return (
        <Card className="bottom10">
            <Card.Body>
                <Card.Title>{props.name}</Card.Title>
                <Link
                    to={`${AdminConstants.RolesPagePath}/${props.id}`}
                    className="card-link"
                >
                    View
                </Link>
            </Card.Body>
        </Card>
    );
};
export default RolesListItem;
