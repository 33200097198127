import React from "react";
import { Button } from "reactstrap";
import { useParams } from "react-router";
import {
    useIsCurrentUserAdmin,
    hasUserOrgPermission,
} from "../../../store/features/user/user-api-slice";
import { useIsAuthenticated } from "@azure/msal-react";
import authService from "../../../modules/authorization/services/AuthService";
import { useAppSelector } from "../../../store/hooks";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import { useOrganisationId } from "../../../modules/organisation/hooks/useOrganisationId";
import { WithOrgShortName } from "../../../common/types/WithOrg";
import "./NavMenu.scss";
import SbimConstants from "../../../modules/sbim/constants/SbimConstants";
import useIsClient from "../../hooks/useIsClient";
import SbimTopMenu from "../../../modules/sbim/components/SbimTopMenu";
import UserGroupsButton from "./admin-user-groups-menu/UserGroupsButton";

const LoginMenu: React.FC = () => {
    const isSBIM = useIsClient(SbimConstants.ClientName);
    const isAuthenticated = useIsAuthenticated();
    const user = useAppSelector((state) => state.authUser);
    const { organisationId } = useOrganisationId();
    const { orgShortName } = useParams<WithOrgShortName>();

    const canUpdateOwnDisplayName = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgUser.displayNameUpdateOwn,
    ).hasPermission;

    const displayName = React.useMemo(
        () =>
            user.displayName
                ? `${user.displayName} (${user.username})`
                : `${user.username}`,
        [user],
    );
    const { isAdmin } = useIsCurrentUserAdmin();
    const login = () => authService.signIn();
    const logout = () => authService.signOut();
    const editProfile = () => authService.editProfile();

    const logoutButton = () => {
        return (
            <Button
                onClick={logout}
                color={isSBIM ? null : "light"}
                className="nav-button"
            >
                Logout
            </Button>
        );
    };
    const userGreeting = () => {
        if (canUpdateOwnDisplayName) {
            return (
                <Button
                    onClick={editProfile}
                    color={isSBIM ? null : "light"}
                    className="nav-button"
                >
                    Hello {displayName}
                </Button>
            );
        } else {
            return (
                <div className="p-2 nav-button" color={isSBIM ? null : "light"}>
                    Hello {displayName}
                </div>
            );
        }
    };

    const authenticatedView = () => {
        return (
            <React.Fragment>
                {userGreeting()}
                <SbimTopMenu />
                {logoutButton()}
            </React.Fragment>
        );
    };

    const administratorView = (adminPath: string) => {
        return (
            <React.Fragment>
                {userGreeting()}
                <Button
                    onClick={() => {
                        window.location.href = adminPath;
                    }}
                    color={isSBIM ? null : "light"}
                    className="nav-button"
                >
                    Admin
                </Button>
                {organisationId && (
                    <UserGroupsButton
                        color={isSBIM ? null : "light"}
                        className="nav-button"
                        orgId={organisationId}
                        orgShortName={orgShortName}
                    />
                )}
                <SbimTopMenu />
                {logoutButton()}
            </React.Fragment>
        );
    };

    const anonymousView = () => {
        return (
            <React.Fragment>
                <Button onClick={login} color="primary">
                    Login
                </Button>
            </React.Fragment>
        );
    };

    if (!isAuthenticated) {
        return anonymousView();
    } else if (isAdmin) {
        const adminPath = `${"/admin"}`;

        return administratorView(adminPath);
    } else {
        return authenticatedView();
    }
};

export default LoginMenu;
