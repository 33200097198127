import * as React from "react";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { RouteComponentProps } from "react-router";
import { useOrgTitleById } from "../../../common/hooks/useTitle";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import AssetTemplateManagement from "../containers/asset-template/AssetTemplateManagement";
import FormTemplateManagement from "../containers/form-template/FormTemplateManagement";
import HelpSectionManagement from "../containers/help-section/HelpSectionManagement";
import OrganisationAuditActionList from "../containers/OrganisationAuditActionList";
import OrganisationForm from "../containers/OrganisationForm";
import PermissionManagement from "../containers/permission/PermissionManagement";
import ProcessTemplateManagement from "../containers/process-template/ProcessTemplateManagement";
import OrganisationPageTitles from "./pageTitles";
import {
    hasUserOrgPermission,
    useIsCurrentUserAdmin,
} from "../../../store/features/user/user-api-slice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setTab } from "../../../store/features/navigation-tabs/navigationTabsSlice";
import { useGetOrgByIdQuery } from "../../../store/features/organisation/organisation-api-slice";

/**
 * This component reads in the url properties from the route to get the id of the object to request from the server
 */
const OrganisationManagePage: React.FC<RouteComponentProps<{ id: string }>> = (
    props,
) => {
    const { id } = props.match.params;
    const dispatch = useAppDispatch();
    const activeTabKey = useAppSelector((state) => state.navigationTab);
    const setTabKey = (key: string) => dispatch(setTab(key));

    const canViewFormTemplates = hasUserOrgPermission(
        id,
        PermissionConstants.OrgFormTemplate.read,
    ).hasPermission;

    const canViewProcessTemplates = hasUserOrgPermission(
        id,
        PermissionConstants.OrgFilesFile.create,
    ).hasPermission;

    const canManageHelpSection = hasUserOrgPermission(
        id,
        PermissionConstants.OrgHelpSection.read,
    );

    const { isAdmin } = useIsCurrentUserAdmin();

    const { id: organisationId } = props.match.params;

    useOrgTitleById(id, OrganisationPageTitles.Manage);
    const { data: organisation } = useGetOrgByIdQuery(organisationId, {
        skip: !organisationId,
    });

    return (
        <React.Fragment>
            <Breadcrumb>
                <Breadcrumb.Item active>Manage</Breadcrumb.Item>
            </Breadcrumb>
            <Row>
                <Col>
                    <h1>Manage Organisation</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Tabs
                        defaultActiveKey="details"
                        id="tab"
                        activeKey={activeTabKey}
                        mountOnEnter={true}
                        onSelect={(key) => setTabKey(key)}
                    >
                        <Tab eventKey="details" title="Details">
                            <OrganisationForm
                                organisationId={props.match.params.id}
                            />
                        </Tab>
                        {isAdmin && (
                            <Tab eventKey="permissions" title="Permissions">
                                <PermissionManagement />
                            </Tab>
                        )}
                        {canViewFormTemplates && (
                            <Tab
                                eventKey="formTemplates"
                                title="Form Templates"
                            >
                                <FormTemplateManagement />
                            </Tab>
                        )}

                        <Tab
                            eventKey="assetTemplates"
                            title={`${
                                organisation?.features?.assets?.createName ??
                                "Asset"
                            } Templates`}
                        >
                            <AssetTemplateManagement />
                        </Tab>

                        {canViewProcessTemplates && (
                            <Tab
                                eventKey="processTemplates"
                                title={`${
                                    organisation?.features?.process
                                        ?.createName ?? "Processes"
                                } Templates`}
                            >
                                <ProcessTemplateManagement />
                            </Tab>
                        )}
                        <Tab eventKey="history" title="History">
                            <OrganisationAuditActionList />
                        </Tab>
                        {canManageHelpSection && (
                            <Tab eventKey="help" title="Help">
                                <HelpSectionManagement />
                            </Tab>
                        )}
                    </Tabs>
                </Col>
            </Row>
        </React.Fragment>
    );
};
export default OrganisationManagePage;
