import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React from "react";
import { ListGroup } from "react-bootstrap";
import { HintWithTooltip } from "../../../../../common/components/HintWithTooltip";
import {
    ExaminationsMassRemoval,
    ExaminationsMassRemovalStatus,
} from "../../domain/examinationsMassRemoval.types";

const ExaminationsMassRemovalGridDetails: React.FC<GridDetailRowProps> = (
    props: GridDetailRowProps,
) => {
    const data: ExaminationsMassRemoval = props.dataItem;

    if (data.status === ExaminationsMassRemovalStatus.Pending) {
        return <strong>This Mass Removal is being processed.</strong>;
    }
    if (
        data.status === ExaminationsMassRemovalStatus.Error &&
        data.errorMessage
    )
        return (
            <>
                <strong>Failed to validate input rows</strong>
                <ListGroup>
                    <ListGroup.Item>
                        <p>{data.errorMessage}</p>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <p>
                            Validation will fail on the first row where an issue
                            is found. Please check all rows before resubmitting.
                        </p>
                    </ListGroup.Item>
                </ListGroup>
            </>
        );
    if (data.status === ExaminationsMassRemovalStatus.Error)
        return (
            <strong>
                An error occurred while processing the file. Please contact the
                administrator for more details.
            </strong>
        );

    return (
        <>
            <div className="pb-3">
                <strong>
                    The Mass Removal has been processed with the following
                    results:
                </strong>
            </div>
            {data.result && (
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        <p>
                            Completed rows{" "}
                            <HintWithTooltip
                                id="completed"
                                text="The amount of rows that were completed processed/total-amount."
                            />
                            : {data.result.completedCount}/
                            {data.result.totalCount}
                        </p>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <p>
                            Failed rows{" "}
                            <HintWithTooltip
                                id="failed"
                                text="The amount of rows that failed to be processed. Details can be found under the current table."
                            />
                            : {data.result.failedExaminationCount}
                        </p>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <p>
                            Skipped rows{" "}
                            <HintWithTooltip
                                id="skipped"
                                text="If there were no changes to the data, the row was
                                skipped."
                            />
                            : {data.result.skippedExaminationCount}{" "}
                        </p>
                    </ListGroup.Item>
                    {data.rowErrors && (
                        <ListGroup.Item>
                            <ListGroup variant="flush">
                                {data.rowErrors.map((rowError) => {
                                    return (
                                        <ListGroup.Item key={rowError.row}>
                                            <strong>Row: </strong>
                                            {rowError.row}{" "}
                                            <strong>Issue: </strong>
                                            {rowError.message}
                                        </ListGroup.Item>
                                    );
                                })}
                            </ListGroup>
                        </ListGroup.Item>
                    )}
                </ListGroup>
            )}
        </>
    );
};

export default ExaminationsMassRemovalGridDetails;
