import React, { useCallback } from "react";
import {
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from "react-query";
import { CommercialMassUpdate } from "../domain/commercialMassUpdate.types";
import {
    getCommercialMassUpdates,
    uploadCommercialMassUpdate,
} from "../services/commercialMassUpdateService";
import commercialQueryKeys from "./commercialQueryKeys";

export const useGetCommercialMassUpdates = (
    organisationId: string,
): UseQueryResult<CommercialMassUpdate[]> => {
    const getQuery = useCallback(() => {
        return getCommercialMassUpdates({ organisationId });
    }, [organisationId]);

    const queryKeys = React.useMemo(
        () => commercialQueryKeys.commercialMassUpdates(organisationId),
        [organisationId],
    );

    return useQuery(queryKeys, getQuery);
};

export const useUploadCommercialMassUpdateMutation = (
    organisationId: string,
): UseMutationResult<void, unknown, File> => {
    const queryClient = useQueryClient();

    const uploadCommercialMassUpdateFunction = (file: File) =>
        uploadCommercialMassUpdate({ organisationId, file });

    const queryKeys = React.useMemo(
        () => commercialQueryKeys.commercialMassUpdates(organisationId),
        [organisationId],
    );

    return useMutation(uploadCommercialMassUpdateFunction, {
        onSuccess(_result) {
            return queryClient.invalidateQueries(queryKeys);
        },
    });
};
