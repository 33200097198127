import React from "react";
import { Button } from "reactstrap";
import UserGroupsDialog from "./UserGroupsDialog";

interface UserGroupButtonProps {
    color: string;
    className: string;
    orgId: string;
    orgShortName: string;
}

const UserGroupButton: React.FC<UserGroupButtonProps> = ({
    color,
    className,
    orgId,
    orgShortName,
}) => {
    const [dialogActive, setDialogActive] = React.useState<boolean>(false);

    const DialogOnClose = React.useCallback(() => {
        setDialogActive(false);
    }, []);

    const DialogOnOpen = React.useCallback(() => {
        setDialogActive(true);
    }, []);

    return (
        <>
            {dialogActive && (
                <UserGroupsDialog
                    orgId={orgId}
                    orgShortName={orgShortName}
                    onClose={DialogOnClose}
                />
            )}
            <Button color={color} className={className} onClick={DialogOnOpen}>
                Change User Groups
            </Button>
        </>
    );
};

export default UserGroupButton;
