import {
    createSlice,
    PayloadAction,
    SliceCaseReducers,
} from "@reduxjs/toolkit";

export type NameState = string;
const initialState: NameState = "";

const folderSlice = createSlice<NameState, SliceCaseReducers<NameState>>({
    name: "fileOrFolder",
    initialState,
    reducers: {
        setName(_, { payload }: PayloadAction<string>): NameState {
            return payload;
        },
    },
});

export const { setName } = folderSlice.actions;
export const reducer = folderSlice.reducer;
