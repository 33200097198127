import { noop } from "lodash";
import React, { createContext, useMemo, useState } from "react";

export enum ExamGridType {
    Defect = "Defect",
    Recommendation = "Recommendation",
    UnexaminedPart = "Unexamined Part",
    None = "",
}

// Create a Context
const ExamContext = createContext({
    activityToEdit: {
        activityId: "",
        activityType: ExamGridType.None,
    },
    setActivityToEdit: noop,
});

// Create a Context Provider component
export const ExamProvider = ({ children }) => {
    const [activityToEdit, setActivityToEdit] = useState({
        activityId: "",
        activityType: ExamGridType.None,
    });

    const contextValue = useMemo(
        () => ({
            activityToEdit,
            setActivityToEdit,
        }),
        [activityToEdit, setActivityToEdit],
    );

    return (
        <ExamContext.Provider value={contextValue}>
            {children}
        </ExamContext.Provider>
    );
};

export default ExamContext;
