import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React from "react";
import MassUpdateErrorsGrid from "../../common/components/MassUpdateErrorsGrid";
import { Workbank, WorkbankStatus } from "../domain/types";
import CreatedAssetsGrid from "./CreatedAssetsGrid";
import FailedItemsGrid from "./FailedItemsGrid";

const ResultList: React.FC<{
    label: string;
    values: string[];
}> = ({ label, values }) => {
    if (values.length === 0) return null;
    return (
        <li className="pb-3">
            <p>{label}</p>
            <ul>
                {values.map((x, i) => {
                    return <li key={i}>{x}</li>;
                })}
            </ul>
        </li>
    );
};

const WorkbankDetails: React.FC<GridDetailRowProps> = (
    props: GridDetailRowProps,
) => {
    const data: Workbank = props.dataItem;

    if (
        data.status === WorkbankStatus.Pending ||
        data.status === WorkbankStatus.Processing
    ) {
        return <strong>This Workbank is being processed.</strong>;
    }
    if (data.status === WorkbankStatus.Error && data.errorMessage) {
        const messages = data.errorMessage.split("\n");
        return (
            <>
                <strong>Failed to validate input rows</strong>
                <MassUpdateErrorsGrid values={messages} />
            </>
        );
    }
    if (data.status === WorkbankStatus.Error)
        return (
            <strong>
                An error occurred while processing the file. Please contact the
                administrator for more details.
            </strong>
        );

    return (
        <>
            <div className="pb-3">
                <strong>
                    The Workbank has been processed with the following results:
                </strong>
            </div>
            <ul>
                <li>
                    <p>Created inspections: {data.createdProcessesCount}</p>
                </li>
                <li>
                    <CreatedAssetsGrid values={data.createdAssets} />
                </li>
                <li>
                    <p>Failed rows: {data.failedRowsCount}</p>
                </li>
                <li>
                    <p>Skipped rows: {data.skippedRowsCount}</p>
                </li>
                <li>
                    <FailedItemsGrid values={data.failedItems} />
                </li>
                <ResultList
                    label={"Asset types for which we do not have templates:"}
                    values={data.missingAssetTemplateNames}
                />
                <ResultList
                    label={
                        "AssetType:ExamType combinations for which we do not have mappings:"
                    }
                    values={data.missingMappings}
                />
                <ResultList
                    label={
                        "Inspection symbols for which no matching process template was found:"
                    }
                    values={data.missingProcessTemplateNames}
                />
            </ul>
        </>
    );
};

export default WorkbankDetails;
