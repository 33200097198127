import { FieldArray, Formik, FormikProps, useFormikContext } from "formik";
import * as React from "react";
import { useState } from "react";
import { Button, Form, FormGroup, FormLabel, Row, Col } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { v4 as uuid } from "uuid";
import {
    ShowCondition,
    TemplateContentTableRow,
    TemplateContentStyle,
} from "../../../template/domain/types";
import FormControlBase from "../../../../common/components/dynamic-form/FormControlBase";
import FormControlSiteRiskGrid from "./FormControlSiteRiskGrid";
import { TemplateContentSiteRiskRecord } from "../../domain/types";
import SiteRiskConstants from "../consts/SiteRiskConstants";

interface FormControlSiteRiskConfig {
    field: string;
    label: string;
    style: TemplateContentStyle;
    prompt: string;
    uri: string;
    rows: TemplateContentTableRow[];
    isReadOnly: boolean;
    multiple: boolean;
    showConditions: ShowCondition[];
}

interface Props {
    config: FormControlSiteRiskConfig;
}

interface SiteRiskInfo {
    identifiedRisk: string;
    hazard: string;
    riskSeverity: number;
    riskLikelihood: number;
    riskOverall: number;
    control: string;
    resRiskSeverity: number;
    resRiskLikelihood: number;
    resRiskOverall: number;
}

const FormControlSiteRisk: React.FC<Props> = (props) => {
    const { config } = props;
    const formik = useFormikContext();
    const [isAdding, setIsAdding] = useState(false);

    const onClickAdd = () => {
        setIsAdding(true);
    };

    const value = (formik.values[config.field] ||
        []) as TemplateContentTableRow[];

    const showAddButton =
        !isAdding &&
        !config.isReadOnly &&
        (config.multiple || value.length < 1);

    return (
        <FormControlBase
            contentConfig={{
                field: config.field,
                showConditions: config.showConditions,
            }}
        >
            <FieldArray name={config.field}>
                {(arrayHelpers) => (
                    <Form.Group key={config.field}>
                        <FormLabel>
                            <strong>{config.label}</strong>
                        </FormLabel>
                        {config.prompt && (
                            <Form.Text className="text-muted mb-2">
                                {config.prompt}
                            </Form.Text>
                        )}
                        {showAddButton && (
                            <div>
                                <Button size="sm" onClick={onClickAdd}>
                                    <FaPlus />
                                </Button>
                            </div>
                        )}
                        {isAdding && (
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    identifiedRisk: "",
                                    hazard: "",
                                    riskSeverity: 0,
                                    riskLikelihood: 0,
                                    riskOverall: 0,
                                    control: "",
                                    resRiskSeverity: 0,
                                    resRiskLikelihood: 0,
                                    resRiskOverall: 0,
                                }}
                                onSubmit={(
                                    values: SiteRiskInfo,
                                    { resetForm },
                                ): void => {
                                    const siteRiskInfo: TemplateContentSiteRiskRecord =
                                        {
                                            id: uuid(),
                                            identifiedRisk:
                                                values.identifiedRisk,
                                            hazard: values.hazard,
                                            riskSeverity: values.riskSeverity,
                                            riskLikelihood:
                                                values.riskLikelihood,
                                            riskOverall: values.riskOverall,
                                            control: values.control,
                                            resRiskSeverity:
                                                values.resRiskSeverity,
                                            resRiskLikelihood:
                                                values.resRiskLikelihood,
                                            resRiskOverall:
                                                values.resRiskOverall,
                                        };
                                    arrayHelpers.push(siteRiskInfo);
                                    resetForm();
                                    setIsAdding(false);
                                }}
                            >
                                {(formikProps: FormikProps<SiteRiskInfo>) => {
                                    return (
                                        <Form noValidate>
                                            <FormGroup>
                                                <Form.Label
                                                    htmlFor={
                                                        SiteRiskConstants.SiteRiskField
                                                    }
                                                >
                                                    {
                                                        SiteRiskConstants.SiteRiskLabel
                                                    }
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={3}
                                                    name={
                                                        SiteRiskConstants.SiteRiskField
                                                    }
                                                    onChange={(
                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                    ) => {
                                                        formikProps.setFieldValue(
                                                            SiteRiskConstants.SiteRiskField,
                                                            event.target.value,
                                                        );
                                                    }}
                                                    value={
                                                        formikProps.values
                                                            .identifiedRisk
                                                    }
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Label
                                                    htmlFor={
                                                        SiteRiskConstants.SiteHazardField
                                                    }
                                                >
                                                    {
                                                        SiteRiskConstants.SiteHazardLabel
                                                    }
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={5}
                                                    name={
                                                        SiteRiskConstants.SiteHazardField
                                                    }
                                                    onChange={(
                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                    ) => {
                                                        formikProps.setFieldValue(
                                                            SiteRiskConstants.SiteHazardField,
                                                            event.target.value,
                                                        );
                                                    }}
                                                    value={
                                                        formikProps.values
                                                            .hazard
                                                    }
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Row>
                                                    <Col>
                                                        <Form.Label
                                                            htmlFor={
                                                                SiteRiskConstants.SiteSeverityField
                                                            }
                                                        >
                                                            {
                                                                SiteRiskConstants.SiteSeverityLabel
                                                            }
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            name={
                                                                SiteRiskConstants.SiteSeverityField
                                                            }
                                                            onChange={(
                                                                event: React.ChangeEvent<HTMLInputElement>,
                                                            ) => {
                                                                formikProps.setFieldValue(
                                                                    SiteRiskConstants.SiteSeverityField,
                                                                    event.target
                                                                        .value,
                                                                );
                                                            }}
                                                            value={
                                                                formikProps
                                                                    .values
                                                                    .riskSeverity
                                                            }
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label
                                                            htmlFor={
                                                                SiteRiskConstants.SiteLikelihoodField
                                                            }
                                                        >
                                                            {
                                                                SiteRiskConstants.SiteLikelihoodLabel
                                                            }
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            name={
                                                                SiteRiskConstants.SiteLikelihoodField
                                                            }
                                                            onChange={(
                                                                event: React.ChangeEvent<HTMLInputElement>,
                                                            ) => {
                                                                formikProps.setFieldValue(
                                                                    SiteRiskConstants.SiteLikelihoodField,
                                                                    event.target
                                                                        .value,
                                                                );
                                                            }}
                                                            value={
                                                                formikProps
                                                                    .values
                                                                    .riskLikelihood
                                                            }
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label
                                                            htmlFor={
                                                                SiteRiskConstants.SiteRiskLevelField
                                                            }
                                                        >
                                                            {
                                                                SiteRiskConstants.SiteRiskLevelLabel
                                                            }
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            name={
                                                                SiteRiskConstants.SiteRiskLevelField
                                                            }
                                                            onChange={(
                                                                event: React.ChangeEvent<HTMLInputElement>,
                                                            ) => {
                                                                formikProps.setFieldValue(
                                                                    SiteRiskConstants.SiteRiskLevelField,
                                                                    event.target
                                                                        .value,
                                                                );
                                                            }}
                                                            value={
                                                                formikProps
                                                                    .values
                                                                    .riskOverall
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Label
                                                    htmlFor={
                                                        SiteRiskConstants.SiteControlField
                                                    }
                                                >
                                                    {
                                                        SiteRiskConstants.SiteControlLabel
                                                    }
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={5}
                                                    name={
                                                        SiteRiskConstants.SiteControlField
                                                    }
                                                    onChange={(
                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                    ) => {
                                                        formikProps.setFieldValue(
                                                            SiteRiskConstants.SiteControlField,
                                                            event.target.value,
                                                        );
                                                    }}
                                                    value={
                                                        formikProps.values
                                                            .control
                                                    }
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Row>
                                                    <Col>
                                                        <Form.Label
                                                            htmlFor={
                                                                SiteRiskConstants.SiteResidualSeverityField
                                                            }
                                                        >
                                                            {
                                                                SiteRiskConstants.SiteResidualSeverityLabel
                                                            }
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            name={
                                                                SiteRiskConstants.SiteResidualSeverityField
                                                            }
                                                            onChange={(
                                                                event: React.ChangeEvent<HTMLInputElement>,
                                                            ) => {
                                                                formikProps.setFieldValue(
                                                                    SiteRiskConstants.SiteResidualSeverityField,
                                                                    event.target
                                                                        .value,
                                                                );
                                                            }}
                                                            value={
                                                                formikProps
                                                                    .values
                                                                    .resRiskSeverity
                                                            }
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label
                                                            htmlFor={
                                                                SiteRiskConstants.SiteResidualLikelihoodField
                                                            }
                                                        >
                                                            {
                                                                SiteRiskConstants.SiteResidualLikelihoodLabel
                                                            }
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            name={
                                                                SiteRiskConstants.SiteResidualLikelihoodField
                                                            }
                                                            onChange={(
                                                                event: React.ChangeEvent<HTMLInputElement>,
                                                            ) => {
                                                                formikProps.setFieldValue(
                                                                    SiteRiskConstants.SiteResidualLikelihoodField,
                                                                    event.target
                                                                        .value,
                                                                );
                                                            }}
                                                            value={
                                                                formikProps
                                                                    .values
                                                                    .resRiskLikelihood
                                                            }
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label
                                                            htmlFor={
                                                                SiteRiskConstants.SiteResidualRiskLevelField
                                                            }
                                                        >
                                                            {
                                                                SiteRiskConstants.SiteResidualRiskLevelLabel
                                                            }
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            name={
                                                                SiteRiskConstants.SiteResidualRiskLevelField
                                                            }
                                                            onChange={(
                                                                event: React.ChangeEvent<HTMLInputElement>,
                                                            ) => {
                                                                formikProps.setFieldValue(
                                                                    SiteRiskConstants.SiteResidualRiskLevelField,
                                                                    event.target
                                                                        .value,
                                                                );
                                                            }}
                                                            value={
                                                                formikProps
                                                                    .values
                                                                    .resRiskOverall
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <div className="mt-2">
                                                <Button
                                                    variant="primary"
                                                    onClick={() => {
                                                        formikProps.submitForm();
                                                    }}
                                                >
                                                    Add
                                                </Button>
                                                <Button
                                                    variant="secondary"
                                                    className="ml-1"
                                                    onClick={() => {
                                                        formikProps.resetForm();
                                                        setIsAdding(false);
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        )}
                        <div className="mt-3">
                            <FormControlSiteRiskGrid
                                arrayHelpers={arrayHelpers}
                                config={config}
                            />
                        </div>

                        {formik.errors[config.field] ? (
                            <div className="invalid-feedback display">
                                {formik.errors[config.field]}
                            </div>
                        ) : null}
                    </Form.Group>
                )}
            </FieldArray>
        </FormControlBase>
    );
};

export default FormControlSiteRisk;
