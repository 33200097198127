import * as React from "react";
import CoveringLoadingPanel from "../../../../common/components/CoveringLoadingPanel";
import { useOrgTitleWithShortName } from "../../../../common/hooks/useTitle";
import PermissionConstants from "../../../../common/permissions/PermissionConstants";
import { hasUserOrgPermission } from "../../../../store/features/user/user-api-slice";
import { useOrganisationId } from "../../../organisation/hooks/useOrganisationId";
import ExcelReportDownload from "../../common/components/ExcelReportDownload";
import { useGetCommercialReportMutation } from "../queries/commercialReportQueries";
import { Breadcrumb, Tab, Tabs } from "react-bootstrap";
import CommercialMassUpdaterActions from "../components/CommercialMassUpdaterActions";

const CommercialReportExportPage: React.FC = () => {
    const pageTitle = "Commercial Report Download";
    const { organisationId, isLoadingOrgId } = useOrganisationId();
    const canView = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgCommercialMessUpdater.read,
    ).hasPermission;

    useOrgTitleWithShortName(pageTitle);

    if (isLoadingOrgId) return <CoveringLoadingPanel />;

    return (
        <>
            <Breadcrumb className="top10">
                <Breadcrumb.Item active>{pageTitle}</Breadcrumb.Item>
            </Breadcrumb>
            <div>
                <h1>{pageTitle}</h1>
            </div>
            <Tabs
                defaultActiveKey="report-download"
                id="commercial-report-download-tabs"
                className="pt-2"
                fill
            >
                <Tab eventKey="report-download" title="Report Download">
                    <div className="pt-2">
                        <ExcelReportDownload
                            pageTitle=""
                            callToAction="Select Contract Year"
                            fileName="Commercial Report"
                            includeDateInFileName
                            mutationFn={useGetCommercialReportMutation}
                        />
                    </div>
                </Tab>
                {canView && (
                    <Tab eventKey="mass-updater" title="Mass Update">
                        <div className="pt-2">
                            <CommercialMassUpdaterActions
                                organisationId={organisationId}
                            />
                        </div>
                    </Tab>
                )}
            </Tabs>
        </>
    );
};

export default CommercialReportExportPage;
