import { conditionTag } from "../../../helpers/storeHelper";
import { assetQueryKeysTranslator } from "../../../modules/asset/query/assetQueryKeysTranslator";
import {
    ProcessActivity,
    ProcessActivityNavigators,
    ProcessActivityPreview,
} from "../../../modules/process-activity/domain/types";
import { wrapTagsWithQueryInvalidation } from "../../../query/configureQuery";
import { AssetPreviewModel } from "../asset/asset-api-slice";
import { dcpApi, Tags } from "../dcpApi";
import { ActivityAssetPreview, MirrorAssetUpdate } from "./types";

enum ProcessActivityConstants {
    Organisation = "/organisation",
    ResourceName = "/processactivity",
    Ui = "/ui",
    Complete = "/complete",
    Open = "/open",
    TypePath = "/type",
    Preview = "/preview",
    Schedule = "/schedule",
    NavigatableActivitiesPath = "/navigatableActivities",
    OrganisationId = "organisationId",
    OnlyScheduled = "onlyScheduled",
}

const invalidateMirroredActivities = ({
    activityIds,
}: MirrorAssetUpdate): { type: Tags; id?: string }[] => {
    return activityIds.map((id) => ({ type: Tags.ProcessActivityAssets, id }));
};

const { tagsToQueryKeys: tagsToAssetQueryKeys } = assetQueryKeysTranslator;

export const processActivityApi = dcpApi.injectEndpoints({
    endpoints: (builder) => ({
        getByOrganisationId: builder.query<
            ProcessActivity[],
            { organisationId: string; onlyScheduled?: boolean }
        >({
            query: ({ organisationId, onlyScheduled }) =>
                `${ProcessActivityConstants.ResourceName}?${ProcessActivityConstants.OrganisationId}=${organisationId}&${ProcessActivityConstants.OnlyScheduled}=${onlyScheduled}`,
        }),
        getActivityPreview: builder.query<ProcessActivityPreview, string>({
            query: (activityId) =>
                `${ProcessActivityConstants.ResourceName}/${activityId}${ProcessActivityConstants.Preview}`,
            providesTags: (_result, error, activityId) =>
                error ? [] : [{ type: Tags.ProcessActivity, id: activityId }],
        }),
        getProcessActivityNavigationActivitiesByActivityId: builder.query<
            ProcessActivityNavigators,
            { activity: ProcessActivityPreview; processId: string }
        >({
            query: ({ activity }) =>
                `${ProcessActivityConstants.ResourceName}/${activity.id}${ProcessActivityConstants.NavigatableActivitiesPath}`,
            providesTags: (_result, _error, args) =>
                conditionTag(
                    [
                        { type: Tags.ProcessActivity, id: args.activity.id },
                        {
                            type: Tags.ProcessActivitiesForProcess,
                            id: args.processId,
                        },
                    ],
                    !!args.activity.parentActivityId,
                    {
                        type: Tags.ProcessActivity,
                        id: args.activity.parentActivityId,
                    },
                ),
        }),
        patchProcessActivityScheduleById: builder.mutation<
            boolean,
            { activityId: string; scheduledStart: string; scheduledEnd: string }
        >({
            query: ({ activityId, scheduledStart, scheduledEnd }) => ({
                url:
                    `${ProcessActivityConstants.ResourceName}/${activityId}` +
                    ProcessActivityConstants.Schedule,
                method: "PATCH",
                body: {
                    scheduledStart,
                    scheduledEnd,
                },
            }),
            invalidatesTags: (_result, _error, query) => [
                { type: Tags.ProcessActivity, id: query.activityId },
            ],
        }),
        getActivityAssets: builder.query<
            ActivityAssetPreview[],
            { activity: ProcessActivity; organisationId: string }
        >({
            query: ({ organisationId, activity }) =>
                `${ProcessActivityConstants.Organisation}/${organisationId}${ProcessActivityConstants.ResourceName}/${activity.id}/assets`,
            providesTags: (_result, _error, { activity }) => [
                { type: Tags.ProcessActivityAssets, id: activity.id },
            ],
        }),
        replaceProcessActivityAsset: builder.mutation<
            MirrorAssetUpdate,
            {
                activity: ProcessActivity;
                assetId: string;
                organisationId: string;
                current: AssetPreviewModel[];
            }
        >({
            query: ({ activity, assetId, organisationId }) => ({
                url: `${ProcessActivityConstants.Organisation}/${organisationId}${ProcessActivityConstants.ResourceName}/${activity.id}/assets`,
                method: "PUT",
                body: { assetId },
            }),
            invalidatesTags: (
                result,
                _error,
                { activity, organisationId, assetId, current },
            ) =>
                wrapTagsWithQueryInvalidation(
                    [
                        { type: Tags.ProcessActivityAssets, id: activity.id },
                        { type: Tags.OrganisationAssets, id: organisationId },
                        { type: Tags.Asset, id: assetId },
                        ...current.map(({ id }) => ({ type: Tags.Asset, id })),
                        ...invalidateMirroredActivities(result),
                    ],
                    tagsToAssetQueryKeys,
                ),
        }),
        removeOneProcessActivityAsset: builder.mutation<
            MirrorAssetUpdate,
            {
                activity: ProcessActivity;
                assetId: string;
                organisationId: string;
            }
        >({
            query: ({ activity, assetId, organisationId }) => ({
                url: `${ProcessActivityConstants.Organisation}/${organisationId}${ProcessActivityConstants.ResourceName}/${activity.id}/assets/${assetId}`,
                method: "DELETE",
            }),
            invalidatesTags: (
                result,
                _error,
                { activity, organisationId, assetId },
            ) =>
                wrapTagsWithQueryInvalidation(
                    [
                        { type: Tags.ProcessActivityAssets, id: activity.id },
                        { type: Tags.OrganisationAssets, id: organisationId },
                        { type: Tags.Asset, id: assetId },
                        ...invalidateMirroredActivities(result),
                    ],
                    tagsToAssetQueryKeys,
                ),
        }),
        removeManyProcessActivityAssets: builder.mutation<
            MirrorAssetUpdate,
            {
                activity: ProcessActivity;
                assetIds: string[];
                organisationId: string;
            }
        >({
            query: ({ activity, assetIds, organisationId }) => ({
                url: `${ProcessActivityConstants.Organisation}/${organisationId}${ProcessActivityConstants.ResourceName}/${activity.id}/assets/remove`,
                method: "POST",
                body: { assetIds },
            }),
            invalidatesTags: (result, _error, args) =>
                processActivityAssetsTagsInvalidator(result, args),
        }),
        addProcessActivityAssets: builder.mutation<
            MirrorAssetUpdate,
            {
                activity: ProcessActivity;
                assetIds: string[];
                organisationId: string;
            }
        >({
            query: ({ activity, assetIds, organisationId }) => ({
                url: `${ProcessActivityConstants.Organisation}/${organisationId}${ProcessActivityConstants.ResourceName}/${activity.id}/assets`,
                method: "POST",
                body: { assetIds },
            }),
            invalidatesTags: (result, _error, args) =>
                processActivityAssetsTagsInvalidator(result, args),
        }),
    }),
});

const processActivityAssetsTagsInvalidator = (
    result: MirrorAssetUpdate,
    { activity, organisationId, assetIds },
) =>
    wrapTagsWithQueryInvalidation(
        [
            { type: Tags.ProcessActivityAssets, id: activity.id },
            { type: Tags.OrganisationAssets, id: organisationId },
            ...assetIds.map((id: string) => ({ type: Tags.Asset, id })),
            ...invalidateMirroredActivities(result),
        ],
        tagsToAssetQueryKeys,
    );

export const {
    useGetByOrganisationIdQuery,
    useGetProcessActivityNavigationActivitiesByActivityIdQuery,
    usePatchProcessActivityScheduleByIdMutation,
    useGetActivityPreviewQuery,
    useAddProcessActivityAssetsMutation,
    useRemoveOneProcessActivityAssetMutation,
    useRemoveManyProcessActivityAssetsMutation,
    useReplaceProcessActivityAssetMutation,
    useGetActivityAssetsQuery,
} = processActivityApi;
