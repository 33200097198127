import cx from "classnames";
import { ErrorMessage, FieldAttributes, useField } from "formik";
import React from "react";
import { Form } from "react-bootstrap";

type Props = {
    values: SimpleSelectItem[];
    label?: string;
    readOnly?: boolean;
    className?: string;
} & FieldAttributes<unknown>;

export interface SimpleSelectItem {
    key: string;
    value: string;
    display: string;
    hidePlaceholder?: boolean;
}

const Select: React.FC<Props> = ({
    values,
    label,
    readOnly,
    className,
    ...props
}) => {
    const [field, meta, helpers] = useField<string>(props);

    return (
        <Form.Group>
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Control
                as="select"
                name={field.name}
                className={cx(className, {
                    "is-invalid": meta.touched && meta.error,
                })}
                onBlur={field.onBlur}
                onChange={(e): void => {
                    helpers.setValue(e.currentTarget.value);
                }}
                value={field.value}
                readOnly={readOnly}
                disabled={readOnly}
            >
                {values.map((item: SimpleSelectItem) => (
                    <option
                        key={item.key}
                        value={item.value}
                        selected={item.key === field.value}
                        hidden={item.hidePlaceholder}
                    >
                        {item.display}
                    </option>
                ))}
            </Form.Control>
            <ErrorMessage
                name={field.name}
                className="invalid-feedback"
                component="div"
            />
        </Form.Group>
    );
};

Select.defaultProps = {
    readOnly: false,
};

export default Select;
