import { useField } from "formik";
import React from "react";
import { Row, Col } from "react-bootstrap";
import {
    Checkbox,
    CustomSelect,
    TextInput,
} from "../../../../common/components/form";
import { getCreateExamOptions } from "../common/options";

const ExamCreateInput: React.FC = () => {
    const [field] = useField<boolean>("createExam");

    return (
        <>
            <Row>
                <Col>
                    <Checkbox name="createExam" label="Create Exam Report" />
                </Col>
            </Row>
            {field.value ? (
                <>
                    <Row>
                        <Col xs={4}>
                            <TextInput
                                label="CARRS Exam Id"
                                placeholder="CARRS Exam Id"
                                name="carrsExamId"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <CustomSelect
                                name="reportType"
                                label="Select Report Type"
                                options={getCreateExamOptions()}
                            />
                        </Col>
                    </Row>
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export default ExamCreateInput;
