import * as React from "react";
import { Alert } from "react-bootstrap";

const NotSupportedActivity: React.FC = () => {
    return (
        <Alert variant="warning" className="top10">
            Activity not supported
        </Alert>
    );
};

export default NotSupportedActivity;
