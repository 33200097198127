import { saveAs } from "file-saver";
import authFetch from "../../../helpers/ApiHelper";

interface DownloadFileParams {
    organisationId: string;
    folderId: string;
    fileId: string;
    fileName: string;
    fileVerId?: string;
}

export function downloadFile({
    organisationId,
    folderId,
    fileId,
    fileName,
    fileVerId,
}: DownloadFileParams): Promise<void> {
    const endpoint = fileVerId
        ? `/api/organisation/${organisationId}/folder/${folderId}/file/${fileId}/ver/${fileVerId}`
        : `/api/organisation/${organisationId}/folder/${folderId}/file/${fileId}`;
    return authFetch(endpoint, {
        method: "GET",
    })
        .then((res) =>
            res.ok ? res.blob() : Promise.reject(new Error(res.statusText)),
        )
        .then((blob) => saveAs(blob, fileName));
}

export function openPdfInTab({
    organisationId,
    folderId,
    fileId,
    fileVerId,
    fileName,
}: DownloadFileParams): Promise<void> {
    const endpoint = fileVerId
        ? `/api/organisation/${organisationId}/folder/${folderId}/file/${fileId}/ver/${fileVerId}`
        : `/api/organisation/${organisationId}/folder/${folderId}/file/${fileId}`;

    return authFetch(endpoint, {
        method: "GET",
    })
        .then((res) =>
            res.ok ? res.blob() : Promise.reject(new Error(res.statusText)),
        )
        .then((blob) => {
            if (blob.type !== "application/pdf") {
                saveAs(blob, fileName);
                return;
            }

            const url = URL.createObjectURL(blob);
            const newTab = window.open();
            newTab.location.href = url;

            if (
                !newTab ||
                newTab.closed ||
                typeof newTab.closed == "undefined"
            ) {
                console.error(
                    "Popup blocked. Please allow popups for this website.",
                );
            }

            URL.revokeObjectURL(url);
        });
}
