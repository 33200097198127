import { Examination } from "./examination";

export enum ExaminationActivityType {
    Examination,
    Review,
}

export interface ExaminationPlanning extends Examination {
    carrsGuid?: string;
    carrsExamId?: string;
    lotNumber?: string;
    chains?: string;

    nrRequestedDate?: Date;
    plannedStartDate?: Date;
    plannedExaminer?: string;
    processTemplateName: string;
    programmeDate?: Date;
    baselineDate?: Date;
    examinationScheduleStatus: ExaminationScheduleProgressStatus;
    errorMessage: string;
    processState: string;
}

export enum ExaminationStatusType {
    Planned = "Planned",
    Unallocated = "Unallocated",
    Cancelled = "Cancelled",
}

export const enum ExaminationScheduleProgressStatus {
    Todo,
    Pending,
    Processing,
}
