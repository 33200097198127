import React from "react";
import { UseQueryResult, useQuery } from "react-query";
import examinationQueryKeys from "./examinationQueryKeys";
import { ExaminationsCount } from "../services/examinationsReviewService";
import { getExamContextBar } from "../services/examContextBarService";

export const useGetExamContextBar = (
    organisationId: string,
    processId: string,
): UseQueryResult<ExaminationsCount> => {
    const queryKeys = React.useMemo(
        () => examinationQueryKeys.examinationContextBar(processId),
        [],
    );

    return useQuery(queryKeys, () =>
        getExamContextBar(organisationId, processId),
    );
};
