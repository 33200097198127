import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaQuestionCircle } from "react-icons/fa";

export const HintWithTooltip: React.FC<{ id: string; text: string }> = ({
    id,
    text,
}) => {
    return (
        <OverlayTrigger
            key={`overlay-tooltip-${id}`}
            placement="top"
            overlay={<Tooltip id={`tooltip-${id}`}>{text}</Tooltip>}
        >
            <FaQuestionCircle />
        </OverlayTrigger>
    );
};
