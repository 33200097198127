import { saveAs } from "file-saver";
import authFetch from "../../../helpers/ApiHelper";
import ProcessActivityApprovalConstants from "../ProcessActivityApprovalconstants";

export const downloadFile = (
    processActivityId: string,
    fileName: string,
): Promise<void> => {
    return authFetch(
        `api/${ProcessActivityApprovalConstants.Endpoint}/${processActivityId}/target/file`,
        {
            method: "GET",
        },
    )
        .then((res) => res.blob())
        .then((blob) => saveAs(blob, fileName));
};
