import { Form, Formik } from "formik";
import * as React from "react";
import { FormControl } from "react-bootstrap";
import { useHistory } from "react-router";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import {
    buildInitialQuery,
    buildSearchQuery,
    FileSearchForm,
} from "../viewModel/FileSearch";
import { Organisation } from "../../organisation/domain/types";
interface Props {
    initialQuery?: FileSearchForm;
    organisation: Organisation;
}

const { fileSearch } = OrganisationConstants;

export const FileSearchCore: React.FC<Props> = ({
    initialQuery,
    organisation,
}): JSX.Element => {
    const history = useHistory();

    const handleSearch = React.useCallback(
        (values: FileSearchForm): void => {
            if (!values.name) return;

            history.push(
                `/${organisation.shortName}/${fileSearch}?${buildSearchQuery(
                    values,
                )}`,
            );
        },
        [history, organisation],
    );

    const initialSearchForm = initialQuery ?? buildInitialQuery();

    return (
        <Formik initialValues={initialSearchForm} onSubmit={handleSearch}>
            {({ values, handleChange }) => (
                <Form className="m-0">
                    <FormControl
                        type="text"
                        name="name"
                        placeholder="Search"
                        onChange={handleChange}
                        value={values.name}
                    />
                </Form>
            )}
        </Formik>
    );
};

export default FileSearchCore;
