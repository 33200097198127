import { useGetConfigQuery } from "../../store/config/configApi";
import { ConsumerApiConfig, getBaseApiUrl } from "./ConsumerApiConfig";

/* Hook returning ClientApiEndpointAddress config data - to be used if needed within components */
const useConsumerApiSettings = (): ConsumerApiConfig => {
    const { data: config } = useGetConfigQuery();

    return {
        consumerApiAddress: config.ClientApiEndpointAddress,
        baseApiUrl: getBaseApiUrl(config.ClientApiEndpointAddress),
    };
};

export default useConsumerApiSettings;
