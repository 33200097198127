import CefaConstants from "../../constants/CefaConstants";
import moment from "moment";

// A cefa comment can either just be a comment string, or it can be a delimited string with a comment
// string and a caption date

export function formatCefaComment(
    commentValue: string,
    captionDateValue: Date | string,
): string {
    let captionDateIso = "";
    captionDateValue = captionDateValue || "";
    commentValue = commentValue || "";

    if (
        captionDateValue instanceof Date &&
        !isNaN(captionDateValue.getTime())
    ) {
        // Convert to iso string, keeping offset
        captionDateIso = moment(captionDateValue).toISOString(true);
    } else if (
        captionDateValue !== "" &&
        !isNaN(new Date(captionDateValue).getTime())
    ) {
        captionDateIso = captionDateValue.toString();
    }

    return captionDateIso
        ? `${commentValue}${CefaConstants.CaptionFieldDelimiter}${captionDateIso}`
        : commentValue;
}

export function getCommentFieldFromCefaComment(cefaComment: string): string {
    return cefaComment
        ? cefaComment.split(CefaConstants.CaptionFieldDelimiter)[0] || ""
        : "";
}

export function getCaptionDateFieldFromCefaComment(
    cefaComment: string,
): string {
    return cefaComment
        ? cefaComment.split(CefaConstants.CaptionFieldDelimiter)[1] || ""
        : "";
}
