import {
    createSlice,
    PayloadAction,
    SliceCaseReducers,
} from "@reduxjs/toolkit";
import { ProcessChangeNotification } from "../../../common/types/Notifications";

export enum MessageType {
    TestMessageType = "testMessageType",
}

export interface NotificationMessagesState {
    testMessageType: ProcessChangeNotification[];
}
const initialState: NotificationMessagesState = {
    testMessageType: [],
};

const notificationsMessagesSlice = createSlice<
    NotificationMessagesState,
    SliceCaseReducers<NotificationMessagesState>
>({
    name: "notificationsMessages",
    initialState,
    reducers: {
        addMessage(
            state,
            {
                payload,
            }: PayloadAction<{ messageType: MessageType; data: string }>,
        ): NotificationMessagesState {
            return {
                ...state,
                [payload.messageType]: [
                    ...state[payload.messageType],
                    { id: payload.data },
                ],
            };
        },
        removeMessage(
            state,
            {
                payload,
            }: PayloadAction<{ messageType: MessageType; data: string }>,
        ): NotificationMessagesState {
            return {
                ...state,
                [payload.messageType]: state[payload.messageType].filter(
                    (item) => item.id !== payload.data,
                ),
            };
        },
    },
});

export const { addMessage, removeMessage } = notificationsMessagesSlice.actions;
export const reducer = notificationsMessagesSlice.reducer;

/* 
============================================================
HANDLING MESSAGES STORED IN REDUX IN COMPONENTS EXAMPLE:
============================================================
const sOMEcOMPONENT: React.FC = () => {
    ...

    const testMessages = useAppSelector(
        (state) => state.notificationsMessages.testMessageType,
    );
    React.useEffect(() => {
        if (!type) {
            return;
        }

        testMessages.forEach((message) => {
            ...Handle the message...
            
            dispatch(
                removeMessage({
                    messageType: MessageType.TestMessageType,
                    data: message.id,
                }),
            );
        });
    }, [testMessages]);
    
    ...

    };
*/
