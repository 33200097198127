import { AuditAction } from "../../../common/types/AuditAction";
import { CommonFeature } from "../../../common/types/CommonFeature";
import {
    ProcessActivityStatusType,
    ProcessActivityType,
} from "../../process-activity/domain/types";

export interface ProcessProperty {
    type: string;
    label: string;
    field: string;
    value: string;
    uri: string;
    configOptions: { name: string; value: string }[];
    valuesList?: ProcessPropertyListValue[];
}
export interface ProcessPropertyListValue {
    id: string;
    value: string;
    createdOn: string;
}
export interface ProcessPropertyFile extends ProcessProperty {
    fileProperties: ProcessPropertyFileProperties;
}

export interface ProcessPropertyFileProperties {
    fileName: string;
}

export interface Process {
    id: string;
    name: string;
    description: string;
    assignedToName: string;
    assignedToId: string;
    completedAt: string;
    completedBy: string;
    organisationId: string;
    processTemplateId: string;
    currentProcessActivityId: string;
    activities: ProcessActivityListItem[];
    auditActions: AuditAction[];
    scheduledStart?: string;
    scheduledEnd?: string;
    properties: ProcessProperty[];
    state: string;
    userAssignment: CommonFeature;
    scheduleEnabled: CommonFeature;
    readOnly: boolean;
}

export interface ProcessCreate {
    name: string;
    description: string;
    organisationId: string;
    assignedToId: string;
    processTemplateId: string;
    scheduledStart: string;
    scheduledEnd: string;
}

export interface ProcessCopyCreate {
    id: string;
    scheduledStart?: string;
    scheduledEnd?: string;
    name: string;
    organisationId?: string;
    includeFormContent: boolean;
}

export interface ProcessUpdate {
    id: string;
    name: string;
    description: string;
    organisationId: string;
    assignedToId?: string;
    scheduledStart: string;
    scheduledEnd: string;
}

export interface ProcessCreateResponse {
    id: string;
}

export interface ProcessActivityListItem {
    id: string;
    displayName: string;
    name: string;
    description: string;
    type: string;
    statusType: ProcessActivityStatusType;
    disabled: boolean;
}

export type ProcessListItem = {
    id: string;
    name: string;
    description: string;
    assignedToName: string;
    assignedToId: string;
    templateName: string;
    scheduledStart?: string;
    scheduledEnd?: string;
    state: string;
};

export const enum ProcessType {
    Process = "process",
}

export type AssignedListItemType = ProcessActivityType | ProcessType;

export interface AssignedListItem {
    id: string;
    name: string;
    parentId: string;
    parentName: string;
    type: AssignedListItemType;
    scheduledStart?: string;
    scheduledEnd?: string;
    processState: string;
}
