import { useFormikContext } from "formik";
import * as React from "react";
import { Form } from "react-bootstrap";
import {
    ShowCondition,
    TemplateContentStyle,
} from "../../../../modules/template/domain/types";
import FormControlBase from "../FormControlBase";
import { CSSProperties } from "react";

export interface FormControlTextAreaConfig {
    field: string;
    label: string;
    styles: TemplateContentStyle | CSSProperties;
    isReadOnly: boolean;
    rows: number;
    prompt: string;
    uri: string;
    showConditions: ShowCondition[];
}

interface Props {
    config: FormControlTextAreaConfig;
}

const FormControlTextArea: React.FC<Props> = (props) => {
    const { config } = props;
    const formik = useFormikContext();
    return (
        <FormControlBase
            contentConfig={{
                field: config.field,
                showConditions: config.showConditions,
            }}
        >
            <Form.Label htmlFor={config.field}>{config.label}</Form.Label>
            <Form.Control
                as="textarea"
                name={config.field}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{ ...config.styles }}
                value={formik.values[config.field] ?? ""}
                className={formik.errors[config.field] ? "is-invalid" : ""}
                readOnly={config.isReadOnly}
                rows={config.rows}
            />
            <Form.Text className="text-muted">{config.prompt}</Form.Text>
        </FormControlBase>
    );
};

export default FormControlTextArea;
