import { DataContinuationResult } from "../../../common/types/DataResult";
import ApiConstants from "../../../Constants/ApiConstants";
import { postData } from "../../../helpers/ApiHelper";
import {
    AssetContQueryParams,
    AssetWithPropsModel,
} from "../../../store/features/asset/asset-api-slice";

export enum UrlPaths {
    BaseResource = "organisation",
    Assets = "assets",
}

export const baseUrl = `${ApiConstants.ApiPrefix}/${UrlPaths.BaseResource}`;

export function getAssetsContinuation({
    organisationId,
    ...filterQuery
}: AssetContQueryParams): Promise<DataContinuationResult<AssetWithPropsModel>> {
    const url = `${baseUrl}/${organisationId}/${UrlPaths.Assets}`;
    return postData<DataContinuationResult<AssetWithPropsModel>>(
        url,
        filterQuery,
    );
}
