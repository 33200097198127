import React from "react";
import cx from "classnames";
import { Nav } from "react-bootstrap";
import { FaCogs } from "react-icons/fa";
import { NavLink, Route } from "react-router-dom";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import { Organisation } from "../../organisation/domain/types";
import MyAssignedMenuItem from "./MyAssignedMenuItem";
import SbimConstants from "../../sbim/constants/SbimConstants";
import useIsClient from "../../../common/hooks/useIsClient";

const ProcessesMenu: React.FC<{ organisation: Organisation }> = ({
    organisation,
}) => {
    const permission = hasUserOrgPermission(
        organisation.id,
        PermissionConstants.OrgProcess.read,
    ).hasPermission;

    const isSBIM = useIsClient(SbimConstants.ClientName);

    if (permission) {
        return (
            <>
                <Nav.Item>
                    <NavLink
                        to={`/${organisation.shortName}${OrganisationConstants.Process}`}
                        className={cx("d-flex align-items-center nav-link", {
                            "d-flex align-items-center nav-link padding button-override":
                                isSBIM,
                        })}
                        exact
                        key="processes"
                    >
                        <FaCogs size="16" className="menu-icon" />
                        <span className="navbar-font-override">
                            {organisation?.features?.process?.sideMenuName ??
                                "Processes"}
                        </span>
                    </NavLink>
                </Nav.Item>
                <Route
                    path={`/${organisation.shortName}${OrganisationConstants.Process}`}
                >
                    <MyAssignedMenuItem organisation={organisation} />
                </Route>
            </>
        );
    } else {
        return null;
    }
};

export default ProcessesMenu;
