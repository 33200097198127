import OrganisationConstants from "../../../Constants/OrganisationConstants";
import ProcessConstants from "../../../Constants/ProcessConstants";

export function useActivityNavigation(
    orgShortNameParam: string,
    processIdParam: string,
): {
    getActivityUrl: (activity: string, processIdOverride?: string) => string;
} {
    const orgShortName = orgShortNameParam;
    const processId = processIdParam;

    const getActivityUrl = (activityId: string, processIdOverride?: string) =>
        `/${orgShortName}${OrganisationConstants.Process}/${
            processIdOverride ?? processId
        }${ProcessConstants.Activity}/${activityId}`;

    return { getActivityUrl };
}
