import * as React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useGetOrgByShortNameQuery } from "../../../../store/features/organisation/organisation-api-slice";
import OrganisationConstants from "../../../../Constants/OrganisationConstants";
import UserRequestedFilesHistoryTableComponent from "../../../user-requested-files/components/UserRequestedFilesHistoryTable";

const OrgsDefectsHistoryPage: React.FC = () => {
    const params = useParams<{ orgShortName: string }>();
    const { orgShortName } = params;
    const { data: orgInfo } = useGetOrgByShortNameQuery(orgShortName);

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item linkAs={"div"}>
                    <Link
                        to={`/${orgShortName}${OrganisationConstants.Report}`}
                    >
                        Reports
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Defect Report History</Breadcrumb.Item>
            </Breadcrumb>
            <UserRequestedFilesHistoryTableComponent
                filterOnTags={false}
                orgId={orgInfo.id}
                tagsToFilterOn={[]}
                reportTitle="Defect Report History"
            />
        </>
    );
};

export default OrgsDefectsHistoryPage;
