import { QueryKey } from "react-query";
import { searchOptionsKey } from "../../../common/types/SortAndFilterOptions";
import { ProcessSearchOptions } from "../services/processService";

export const processesKey = "processes";

const processQueryKeys = {
    all: (): QueryKey => processesKey,
    filteredProcesses: (searchOptions: ProcessSearchOptions): QueryKey => [
        processesKey,
        ...searchOptionsKey(searchOptions),
    ],
};

export default processQueryKeys;
