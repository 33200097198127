import * as React from "react";
import { Button } from "react-bootstrap";
import Breadcrumbs from "../../../../common/components/breadcrumbs/Breadcrumbs";
import MutationResultStatus from "../../../../common/components/MutationResultStatus";
import QueryResultStatus from "../../../../common/components/QueryResultStatus";
import { useOrgTitleWithShortName } from "../../../../common/hooks/useTitle";
import PermissionConstants from "../../../../common/permissions/PermissionConstants";
import useConsumerApiSettings from "../../../../customization/api/useConsumerApiSettings";
import { hasUserOrgPermission } from "../../../../store/features/user/user-api-slice";
import { useOrganisationId } from "../../../organisation/hooks/useOrganisationId";
import WorkbanksGrid from "../components/WorkbanksGrid";
import { downloadWorkbank } from "../services/downloadWorkbank";
import {
    useGetWorkbanksQuery,
    useUploadWorkbankMutation,
} from "../store/WorkbankApiSlice";
import pageTitles from "./pageTitles";
import WorkbankUploadDialog from "./WorkbankUploadDialog";

const WorkbankUploadPage: React.FC = () => {
    useOrgTitleWithShortName(pageTitles.workbankUpload);

    const [showUploadDialog, setShowUploadDialog] = React.useState(false);

    const { organisationId } = useOrganisationId();

    const { data: workbanks, ...workbanksQueryResult } = useGetWorkbanksQuery(
        organisationId,
        { skip: !organisationId },
    );
    const [uploadWorkbank, uploadWorkbankResult] = useUploadWorkbankMutation();

    const canUploadWorkbanks = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgWorkbank.create,
    ).hasPermission;

    const { baseApiUrl } = useConsumerApiSettings();

    return (
        <>
            <QueryResultStatus queryResult={workbanksQueryResult} />
            <MutationResultStatus
                mutationResult={uploadWorkbankResult}
                showLoading
            />
            <div className="pt-2">
                <Breadcrumbs
                    items={[[pageTitles.workbankUpload]]}
                ></Breadcrumbs>
            </div>
            <div>
                <h1>{pageTitles.workbankUpload}</h1>
            </div>
            <div className="pt-2">
                {canUploadWorkbanks && (
                    <Button
                        className="ml-1"
                        variant="primary"
                        onClick={() => setShowUploadDialog(true)}
                    >
                        Upload
                    </Button>
                )}
                <Button
                    className="ml-1"
                    variant="outline-secondary"
                    onClick={() =>
                        downloadWorkbank({
                            url: `${baseApiUrl}/organisation/${organisationId}/workbank/template`,
                        })
                    }
                >
                    Download Template
                </Button>
            </div>
            {workbanks && (
                <div className="pt-2">
                    <WorkbanksGrid
                        workbanks={workbanks}
                        organisationId={organisationId}
                    />
                </div>
            )}
            {showUploadDialog && (
                <WorkbankUploadDialog
                    uploadWorkbank={uploadWorkbank}
                    onClose={() => setShowUploadDialog(false)}
                    organisationId={organisationId}
                />
            )}
        </>
    );
};

export default WorkbankUploadPage;
