import {
    Grid,
    GridColumn,
    GridItemChangeEvent,
    GridRowClickEvent,
} from "@progress/kendo-react-grid";
import { ArrayHelpers, getIn, useFormikContext } from "formik";
import * as React from "react";
import { TypedGridCellProps } from "../../../../common/types/TypedGridCellProps";
import { ContainsMenuFilter } from "../../../../common/components/table/kendo/columnFilters";
import useGridProps from "../../../../common/components/table/kendo/useGridProps";
import {
    FormControlSiteRiskGridDataItem,
    mapToSiteRiskGridItem,
} from "./FormControlSiteRiskGridDataItem";
import {
    ShowCondition,
    TemplateContentTableRow,
} from "../../../template/domain/types";
import { TemplateContentSiteRiskRecord } from "../../domain/types";
import FormControlSiteRiskGridActions from "./FormControlSiteRiskGridActions";
import SiteRiskConstants from "../consts/SiteRiskConstants";

interface FormControlSiteRiskGridConfig {
    field: string;
    label: string;
    prompt: string;
    uri: string;
    rows: TemplateContentTableRow[];
    isReadOnly: boolean;
    showConditions: ShowCondition[];
}

interface Props {
    config: FormControlSiteRiskGridConfig;
    arrayHelpers: ArrayHelpers;
}

const FormControlSiteRiskGrid: React.FC<Props> = ({ config, arrayHelpers }) => {
    const [editId, setEditId] = React.useState<string>(null);
    const formik = useFormikContext();
    const rowData = React.useMemo(
        () =>
            (getIn(formik.values, config.field) ||
                []) as TemplateContentSiteRiskRecord[],
        [formik.values, config.field],
    );

    const data = React.useMemo(
        () =>
            rowData.map((row, index) =>
                mapToSiteRiskGridItem(row, index, editId),
            ),
        [rowData, editId],
    );

    const { gridProps } = useGridProps(data, {
        filterable: true,
        sortable: true,
        initialSort: [{ field: "createdOn", dir: "desc" }],
        pageable: false,
    });

    const ActionsCell = React.useMemo(
        () =>
            // eslint-disable-next-line react/display-name
            (props: TypedGridCellProps<FormControlSiteRiskGridDataItem>) =>
                (
                    <td>
                        <FormControlSiteRiskGridActions
                            {...props}
                            onRemove={(dataItem) => {
                                arrayHelpers.remove(dataItem.originalIndex);
                            }}
                        />
                    </td>
                ),
        [arrayHelpers],
    );

    const handleRowClick = (event: GridRowClickEvent) => {
        if (!config.isReadOnly) {
            setEditId(event.dataItem.id);
        }
    };

    const handleEdit = (event: GridItemChangeEvent) => {
        const newData = rowData.map((item) =>
            item.id === event.dataItem.id
                ? { ...item, [event.field]: event.value }
                : item,
        );
        formik.setFieldValue(config.field, newData);
    };

    return (
        <Grid
            {...gridProps}
            className={(gridProps.className, "dynamic-form-grid")}
            editField="inEdit"
            onRowClick={handleRowClick}
            onItemChange={handleEdit}
        >
            <GridColumn
                field={SiteRiskConstants.SiteRiskField}
                title={SiteRiskConstants.SiteRiskLabel}
                sortable
                width={window.innerWidth * SiteRiskConstants.SiteRiskWidth}
                columnMenu={ContainsMenuFilter}
                editable={!config.isReadOnly}
                editor="text"
            />
            <GridColumn
                field={SiteRiskConstants.SiteHazardField}
                title={SiteRiskConstants.SiteHazardLabel}
                sortable
                width={window.innerWidth * SiteRiskConstants.SiteHazardWidth}
                columnMenu={ContainsMenuFilter}
                editable={!config.isReadOnly}
                editor="text"
            />
            <GridColumn
                field={SiteRiskConstants.SiteSeverityField}
                title={SiteRiskConstants.SiteSeverityLabel}
                sortable
                width={window.innerWidth * SiteRiskConstants.SiteSeverityWidth}
                columnMenu={ContainsMenuFilter}
                editable={!config.isReadOnly}
                editor="numeric"
            />
            <GridColumn
                field={SiteRiskConstants.SiteLikelihoodField}
                title={SiteRiskConstants.SiteLikelihoodLabel}
                sortable
                width={
                    window.innerWidth * SiteRiskConstants.SiteLikelihoodWidth
                }
                columnMenu={ContainsMenuFilter}
                editable={!config.isReadOnly}
                editor="numeric"
            />
            <GridColumn
                field={SiteRiskConstants.SiteRiskLevelField}
                title={SiteRiskConstants.SiteRiskLevelLabel}
                sortable
                width={window.innerWidth * SiteRiskConstants.SiteRiskLevelWidth}
                columnMenu={ContainsMenuFilter}
                editable={!config.isReadOnly}
                editor="numeric"
            />
            <GridColumn
                field={SiteRiskConstants.SiteControlField}
                title={SiteRiskConstants.SiteControlLabel}
                sortable
                width={window.innerWidth * SiteRiskConstants.SiteControlWidth}
                columnMenu={ContainsMenuFilter}
                editable={!config.isReadOnly}
                editor="text"
            />
            <GridColumn
                field={SiteRiskConstants.SiteResidualSeverityField}
                title={SiteRiskConstants.SiteResidualSeverityLabel}
                sortable
                width={
                    window.innerWidth *
                    SiteRiskConstants.SiteResidualSeverityWidth
                }
                columnMenu={ContainsMenuFilter}
                editable={!config.isReadOnly}
                editor="numeric"
            />
            <GridColumn
                field={SiteRiskConstants.SiteResidualLikelihoodField}
                title={SiteRiskConstants.SiteResidualLikelihoodLabel}
                sortable
                width={
                    window.innerWidth *
                    SiteRiskConstants.SiteResidualLikelihoodWidth
                }
                columnMenu={ContainsMenuFilter}
                editable={!config.isReadOnly}
                editor="numeric"
            />
            <GridColumn
                field={SiteRiskConstants.SiteResidualRiskLevelField}
                title={SiteRiskConstants.SiteResidualRiskLevelLabel}
                sortable
                width={
                    window.innerWidth *
                    SiteRiskConstants.SiteResidualRiskLevelWidth
                }
                columnMenu={ContainsMenuFilter}
                editable={!config.isReadOnly}
                editor="numeric"
            />
            {!config.isReadOnly && (
                <GridColumn
                    filterable={false}
                    cell={ActionsCell}
                    width={window.innerWidth * SiteRiskConstants.ActionWidth}
                />
            )}
        </Grid>
    );
};
export default FormControlSiteRiskGrid;
