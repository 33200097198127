import cx from "classnames";
import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { ProcessActivityListItemModel } from "../../process-activity-list/domain/types";
import { ProcessActivityListItem } from "../../process/domain/types";
import { ProcessActivity } from "../domain/types";
import { resolveActivityStatusFlags } from "../helpers/activityStateHelpers";

interface Props {
    activity:
        | ProcessActivityListItem
        | ProcessActivity
        | ProcessActivityListItemModel;
}

const ProcessActivityCard: React.FC<Props> = ({ activity, children }) => {
    const { isComplete, isNotApplicable, isRequired } =
        resolveActivityStatusFlags(activity);

    return (
        <div className="card mb-2">
            <div
                className={cx("card-body ", {
                    "alert-dark": isNotApplicable,
                    "alert-success": isComplete,
                    "alert-warning": isRequired,
                })}
            >
                <Row>
                    <Col xs={1} className="d-flex">
                        {isComplete && (
                            <FaCheckCircle
                                name="rowing"
                                size="24"
                                color="green"
                                className="align-self-center"
                            />
                        )}
                        {isNotApplicable && (
                            <FaTimesCircle
                                name="rowing"
                                size="24"
                                color="black"
                                className="align-self-center"
                            />
                        )}
                    </Col>
                    <Col xs={11}>
                        <h4 className="card-title">{activity.displayName}</h4>

                        {children}
                    </Col>
                </Row>
            </div>
        </div>
    );
};
export default ProcessActivityCard;
