import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React from "react";
import { Figure } from "react-bootstrap";
import { TemplateContentFileRecord } from "../../../../modules/template/domain/types";
import { getFormFile } from "../../../../services/FileDownloadService";
import { DynamicFormContext } from "../DynamicFormContextProvider";

const FormControlFileDetails: React.FC<GridDetailRowProps> = (
    props: GridDetailRowProps,
) => {
    const data: TemplateContentFileRecord = props.dataItem;

    const { getFileUrl } = React.useContext(DynamicFormContext);

    const { localFile, id: fileId } = data;

    const [fileBlob, setFileBlob] = React.useState(new Blob([]));

    const url = React.useMemo(() => getFileUrl(fileId), [getFileUrl, fileId]);

    React.useEffect(() => {
        if (url && fileId) {
            getFormFile(url)
                .then((a) => {
                    setFileBlob(a);
                })
                .catch(() => {
                    console.error("Failed to fetch img");
                    setFileBlob(new Blob([]));
                });
        } else {
            setFileBlob(new Blob([]));
        }
    }, [fileId, url]);

    if (
        data.fileName.toLowerCase().endsWith("png") ||
        data.fileName.toLowerCase().endsWith("jpeg") ||
        data.fileName.toLowerCase().endsWith("jpg") ||
        data.fileName.toLowerCase().endsWith("gif")
    ) {
        if (localFile) {
            return (
                <Figure className="mb-0">
                    <Figure.Image
                        className="mb-0"
                        src={URL.createObjectURL(localFile)}
                    />
                </Figure>
            );
        } else {
            return (
                <Figure className="mb-0">
                    <Figure.Image
                        className="mb-0"
                        src={URL.createObjectURL(fileBlob)}
                    />
                </Figure>
            );
        }
    } else {
        return <strong>This File Type is not valid for viewing here.</strong>;
    }
};

export default FormControlFileDetails;
