import * as React from "react";
import AuditActionListItem from "./AuditActionListItem";
import { AuditAction } from "../../types/AuditAction";

const AuditActionsList: React.FC<{ auditActions: AuditAction[] }> = (props) => {
    const { auditActions } = props;
    return (
        <div>
            <ul className="timeline">
                {auditActions.map((item: AuditAction, index: number) => (
                    <AuditActionListItem key={index.toString()} data={item} />
                ))}
            </ul>
        </div>
    );
};

export default AuditActionsList;
