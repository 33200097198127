import { isNil } from "lodash";
import { QueryKey } from "react-query";
import { SortAndFilterOptions } from "../../../common/types/SortAndFilterOptions";
import { ProcessActivitySearchOptions } from "../services/processActivityService";

export const queryKey = "process-activities";

const processActivityQueryKeys = {
    all: (): QueryKey => queryKey,
    filtered: (
        activityId: string,
        searchOptions: ProcessActivitySearchOptions,
    ): QueryKey => [queryKey, activityId, ...searchOptionsKey(searchOptions)],
};

const searchOptionsKey = (searchOptions: SortAndFilterOptions) => {
    if (isNil(searchOptions)) return [];

    const { filterParam } = searchOptions;

    const filterKeys = isNil(filterParam)
        ? []
        : filterParam.map((f) => `${f.field}.${f.operator}:${f.value}`);

    return [...filterKeys];
};

export default processActivityQueryKeys;
