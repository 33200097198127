import * as Formik from "formik";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
    CustomSelect,
    DateTimeSelect,
    TextArea,
    TextInput,
} from "../../../common/components/form";
import FormEditButtonToolbar from "../../../common/components/form/FormEditButtonToolbar";
import { Errors } from "../../../common/types/Errors";
import {
    mapToSelectOptions,
    SelectOptionItem,
} from "../../../common/types/reactSelect/SelectOptionItem";
import { addSuccess } from "../../../store/features/notifications/notificationsSlice";
import { useGetUsersByOrgIdQuery } from "../../../store/features/organisation/organisation-api-slice";
import { useGetProcessTemplatesByOrgIdQuery } from "../../../store/features/process-template/process-template-api-slice";
import { useUpdateProcessMutation } from "../../../store/features/process/process-api-slice";
import { Process } from "../domain/types";
import { Organisation } from "../../organisation/domain/types";
interface FormValues {
    name: string;
    description: string;
    assignedTo?: SelectOptionItem;
    processTemplateName: string;
    scheduledStart?: string;
    scheduledEnd?: string;
}

const validate = (values: FormValues) => {
    const errors: Errors = {};

    if (!values.name) {
        errors.name = "Required";
    }

    if (values.scheduledStart && !values.scheduledEnd) {
        errors.scheduledEnd = "Scheduled End is required";
    }

    if (values.scheduledEnd && !values.scheduledStart) {
        errors.scheduledStart = "Scheduled Start is required";
    }
    if (values.scheduledEnd && values.scheduledStart) {
        if (values.scheduledEnd <= values.scheduledStart)
            errors.scheduledEnd = "Scheduled End must be after Scheduled Start";
    }

    return errors;
};

interface Props {
    processData: Process;
    organisation: Organisation;
}

const ProcessDetailsForm: React.FC<Props> = (props) => {
    const { organisation } = props;
    const { processData } = props;

    const dispatch = useDispatch();
    const [update] = useUpdateProcessMutation();

    const [isEdit, setIsEdit] = React.useState<boolean>(false);

    const { data: orgProcessTemplatesData } =
        useGetProcessTemplatesByOrgIdQuery(processData.organisationId, {
            skip: !processData.organisationId,
        });

    const { data: orgUsersData, isLoading: orgUsersIsLoading } =
        useGetUsersByOrgIdQuery(processData.organisationId, {
            skip: !processData.organisationId,
        });

    const assignedToOptions = React.useMemo(
        () =>
            mapToSelectOptions(
                orgUsersData,
                (i) => i.userId,
                (i) => `${i.userDisplayName} (${i.username})`,
            ),
        [orgUsersData],
    );

    const assignedUserOption = React.useMemo(
        () =>
            assignedToOptions.find((o) => o.value === processData.assignedToId),
        [processData.assignedToId, assignedToOptions],
    );

    const isUserAssignmentEnabled = processData?.userAssignment.enabled;
    const isScheduleEnabled = processData?.scheduleEnabled.enabled ?? true;

    const initialValues: FormValues = React.useMemo(
        () => ({
            ...(isUserAssignmentEnabled
                ? { assignedTo: assignedUserOption }
                : {}),
            name: processData.name,
            description: processData.description,
            processTemplateName:
                (orgProcessTemplatesData ?? []).find(
                    (t) => t.id === processData.processTemplateId,
                )?.name || "",
            ...(isScheduleEnabled
                ? {
                      scheduledStart: processData.scheduledStart,
                      scheduledEnd: processData.scheduledEnd,
                  }
                : {}),
        }),
        [
            isUserAssignmentEnabled,
            isScheduleEnabled,
            assignedUserOption,
            processData,
            orgProcessTemplatesData,
        ],
    );

    const onSubmit = (
        values: FormValues,
        { setSubmitting }: Formik.FormikHelpers<FormValues>,
    ) => {
        setSubmitting(true);
        update({
            ...(isUserAssignmentEnabled
                ? { assignedToId: values.assignedTo?.value }
                : {}),

            id: processData?.id,
            name: values.name,
            description: values.description,
            organisationId: processData.organisationId,
            ...(isScheduleEnabled
                ? {
                      scheduledStart:
                          values.scheduledStart === ""
                              ? null
                              : values.scheduledStart,
                      scheduledEnd:
                          values.scheduledEnd === ""
                              ? null
                              : values.scheduledEnd,
                  }
                : { scheduledStart: null, scheduledEnd: null }),
        })
            .unwrap()
            .then(() => {
                dispatch(
                    addSuccess(
                        `${
                            organisation?.features?.process?.sideMenuName ??
                            "Processes"
                        } saved successfully.`,
                    ),
                );
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <div className="pt-2">
            <Formik.Formik
                enableReinitialize
                initialValues={initialValues}
                validate={validate}
                onSubmit={onSubmit}
            >
                <Formik.Form>
                    <TextInput
                        label="Name"
                        placeholder="Name"
                        name="name"
                        description={`The name for your ${
                            organisation?.features?.process?.createName ??
                            "Process"
                        }.`}
                        readOnly={!isEdit}
                    />
                    <TextArea
                        label="Description"
                        name="description"
                        rows={3}
                        readOnly={!isEdit}
                    />
                    {isScheduleEnabled && (
                        <div>
                            <DateTimeSelect
                                label="Scheduled Start"
                                name="scheduledStart"
                                readOnly={!isEdit}
                            />
                            <DateTimeSelect
                                label="Scheduled End"
                                name="scheduledEnd"
                                readOnly={!isEdit}
                            />
                        </div>
                    )}

                    {isUserAssignmentEnabled && (
                        <CustomSelect
                            name="assignedTo"
                            label="Select Assigned User"
                            placeholder={
                                assignedUserOption?.label || "Select user..."
                            }
                            readOnly={orgUsersIsLoading || !isEdit}
                            options={assignedToOptions}
                        />
                    )}

                    <TextInput
                        label="Template"
                        name="processTemplateName"
                        readOnly={true}
                    />
                    {!processData.readOnly && (
                        <FormEditButtonToolbar
                            isEdit={isEdit}
                            onCancel={() => setIsEdit(false)}
                            onEdit={() => setIsEdit(true)}
                        />
                    )}
                </Formik.Form>
            </Formik.Formik>
        </div>
    );
};

export default ProcessDetailsForm;
