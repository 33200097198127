enum ProcessPageTitles {
    Create = "Create Process",
    List = "Processes",
    View = "Process:",
    MyAssigned = "My Assigned",
}

const formatViewProcessPageTitle = (processName: string): string =>
    `${ProcessPageTitles.View} ${processName}`;

export { ProcessPageTitles, formatViewProcessPageTitle };
