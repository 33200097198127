import { TemplateContentEmployeesRecord } from "../../domain/types";

export interface FormControlEmployeesGridDataItem
    extends TemplateContentEmployeesRecord {
    originalIndex: number;
    inEdit: boolean;
}

export const mapToEmployeesGridItem = (
    employeesRecord: TemplateContentEmployeesRecord,
    index: number,
    inEditId: string,
): FormControlEmployeesGridDataItem =>
    ({
        ...employeesRecord,
        originalIndex: index,
        inEdit: employeesRecord.id === inEditId,
    } as FormControlEmployeesGridDataItem);
