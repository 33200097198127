import * as React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { AssetModel } from "../../../store/features/asset/asset-api-slice";
import { Organisation } from "../../organisation/domain/types";
interface Props {
    asset: AssetModel;
    organisation: Organisation;
}

function getAssetParents(asset: AssetModel): AssetModel[] {
    const result: AssetModel[] = [];
    while (asset) {
        result.unshift(asset);
        asset = asset.parent;
    }
    return result;
}

const AssetViewBreadcrumbs: React.FC<Props> = ({ asset, organisation }) => {
    const assetParents = getAssetParents(asset.parent);

    return (
        <Breadcrumb>
            <Breadcrumb.Item linkAs={"span"}>
                <Link
                    to={`/${organisation.shortName}${OrganisationConstants.Asset}`}
                >
                    {organisation?.features?.assets?.sideMenuName ?? "Assets"}
                </Link>
            </Breadcrumb.Item>
            {assetParents.length > 0 &&
                assetParents.map((parent) => (
                    <Breadcrumb.Item linkAs={"span"} key={parent.id}>
                        <Link
                            to={`/${organisation.shortName}${OrganisationConstants.Asset}/${parent.id}`}
                        >
                            {parent.name}
                        </Link>
                    </Breadcrumb.Item>
                ))}
            <Breadcrumb.Item active>{asset.name}</Breadcrumb.Item>
        </Breadcrumb>
    );
};

export default AssetViewBreadcrumbs;
