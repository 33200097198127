import * as Formik from "formik";
import * as React from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ReportService from "../../../services/ReportService";
import { ApplicationState } from "../../../store";
import * as Store from "../../../store/report/store";
import ReportFormTable from "./ReportFormTable";

/**
 * A dynamic object to hold error messages
 */
interface Errors {
    [key: string]: string;
}

/**
 * Describe the values the form will be collecting
 */
interface FormValues {
    name: string;
    description: string;
    configurationId: string;
    formTemplateId: string;
    formSelection: string[];
}

interface DropDownOption {
    value: string;
    display: string;
}

const getItemsWithPlaceholder = (
    input: DropDownOption[],
    placeholder: string,
): DropDownOption[] => {
    const placeholderArray = [{ value: "", display: placeholder }];
    return placeholderArray.concat(input);
};

const getTemplateDropDownOptions = (): DropDownOption[] => {
    // todo - use RTK query to get formTemplates - it is not in state.formTemplate?.templates any more
    // const data = state.formTemplate?.templates ?? [];
    const data = [];
    const options = data.map((a) => {
        return { value: a.id, display: a.name };
    });
    return getItemsWithPlaceholder(options, "Select template");
};

const getReportTemplateData = (
    state: ApplicationState,
): Store.ReportTemplate => {
    return (
        state.report?.domain.reportTemplate ??
        Store.unloadedState.domain.reportTemplate
    );
};

const getReportConfigurationDropDownOptions = (): DropDownOption[] => {
    const data = [{ id: "1", displayName: "Excel" }];
    const options = data.map((a) => {
        return { value: a.id, display: a.displayName };
    });
    return getItemsWithPlaceholder(options, "Select report configuration");
};

const OrgReportManageForm: React.FC = () => {
    const dispatch = useDispatch();
    const formTemplateOptions = useSelector(getTemplateDropDownOptions);
    const reportTemplateData = useSelector(getReportTemplateData);
    const formReportConfigurationOptions = useSelector(
        getReportConfigurationDropDownOptions,
    );

    const isEdit = useSelector(
        (state: ApplicationState) =>
            state.report?.ui.reportTemplate.isEdit ??
            Store.unloadedState.ui.reportTemplate.isEdit,
    );

    /**
     * Validate the forms inputs
     * @param values the form input values
     */
    const validate = (values: FormValues): Errors => {
        const errors: Errors = {};

        if (!values.name) {
            errors.name = "Required";
        }

        if (!values.formTemplateId) {
            errors.formTemplateId = "Required";
        }

        if (values.formSelection.length === 0) {
            errors.formSelection = "Required";
        }

        return errors;
    };

    const updateSuccessful = (): void => {
        dispatch(Store.actionCreators.initEdit(reportTemplateData.id));
    };

    const cancel = (): void => {
        dispatch(Store.actionCreators.initEdit(reportTemplateData.id));
    };

    const edit = (): void => {
        dispatch(
            Store.actionCreators.startEdit(reportTemplateData.formTemplateId),
        );
    };

    return (
        <>
            {!isEdit && (
                <Row>
                    <Col>
                        <Button className="float-right" onClick={edit}>
                            Edit
                        </Button>
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    <Formik.Formik
                        enableReinitialize
                        initialValues={{
                            name: reportTemplateData.name,
                            description: reportTemplateData.description,
                            configurationId: reportTemplateData.configurationId,
                            formTemplateId: reportTemplateData.formTemplateId,
                            formSelection: reportTemplateData.formIds,
                        }}
                        validate={validate}
                        onSubmit={async (
                            values: FormValues,
                            { setSubmitting },
                        ): Promise<void> => {
                            // When button submits form and form is in the process of submitting, submit button is disabled
                            setSubmitting(true);
                            ReportService.Update(
                                reportTemplateData.id,
                                values.name,
                                values.description,
                                values.configurationId,
                                values.formSelection,
                            )
                                .then(() => {
                                    updateSuccessful();
                                })
                                .catch((error) => {
                                    console.log(
                                        "Request failed:",
                                        error.message,
                                    );
                                    setSubmitting(false);
                                });
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            setFieldTouched,
                            validateForm,
                            isSubmitting,
                        }): JSX.Element => (
                            <Formik.Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Name"
                                        name="name"
                                        /* Set onChange to handleChange */
                                        onChange={handleChange}
                                        /* Set onBlur to handleBlur */
                                        onBlur={handleBlur}
                                        /* Store the value of this input in values.name, make sure this is named the same as the name property on the form element */
                                        value={values.name}
                                        className={
                                            touched.name && errors.name
                                                ? "form-control is-invalid"
                                                : "form-control"
                                        }
                                        readOnly={!isEdit}
                                    />
                                    {errors.name ? (
                                        <div className="invalid-feedback">
                                            {errors.name}
                                        </div>
                                    ) : null}
                                    <Form.Text className="text-muted">
                                        The name for your report.
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group controlId="reportDescription">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        name="description"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.description}
                                        readOnly={!isEdit}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        Select Report Configuration
                                    </Form.Label>
                                    <Form.Control
                                        as="select"
                                        id="formReportConfiguration"
                                        name="Select Report Configuration"
                                        value={values.configurationId}
                                        onChange={(e): void => {
                                            // Custom form controls must set the value in this way. If this is set manually it will reset the state.
                                            setFieldValue(
                                                "configurationId",
                                                e.currentTarget.value,
                                            );
                                        }}
                                        onBlur={handleBlur}
                                        readOnly={!isEdit}
                                    >
                                        {formReportConfigurationOptions.map(
                                            (template: DropDownOption) => (
                                                <option
                                                    key={template.value}
                                                    value={template.value}
                                                >
                                                    {template.display}
                                                </option>
                                            ),
                                        )}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Select Template</Form.Label>
                                    <Form.Control
                                        disabled={true}
                                        as="select"
                                        id="formTemplate"
                                        name="Select Template"
                                        value={values.formTemplateId}
                                        className={
                                            touched.formTemplateId &&
                                            errors.formTemplateId
                                                ? "form-control is-invalid"
                                                : "form-control"
                                        }
                                        onChange={(e): void => {
                                            // Custom form controls must set the value in this way. If this is set manually it will reset the state.
                                            setFieldValue(
                                                "formTemplateId",
                                                e.currentTarget.value,
                                            );
                                        }}
                                        onBlur={handleBlur}
                                    >
                                        {formTemplateOptions.map(
                                            (template: DropDownOption) => (
                                                <option
                                                    key={template.value}
                                                    value={template.value}
                                                >
                                                    {template.display}
                                                </option>
                                            ),
                                        )}
                                    </Form.Control>
                                    {errors.formTemplateId ? (
                                        <div className="invalid-feedback">
                                            {errors.formTemplateId}
                                        </div>
                                    ) : null}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Forms</Form.Label>
                                    {isEdit && (
                                        <Form.Text className="text-muted bottom5">
                                            The forms to appear in the report.
                                            Please select at least one form.
                                        </Form.Text>
                                    )}
                                    <ReportFormTable
                                        formikValues={values}
                                        touched={touched}
                                        validate={validateForm}
                                        setValue={setFieldValue}
                                        setTouched={setFieldTouched}
                                        errors={errors}
                                    ></ReportFormTable>
                                </Form.Group>
                                {isEdit && (
                                    <div className="float-right bottom10">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            Update
                                        </Button>
                                        <Button
                                            variant="danger"
                                            className="ml-1"
                                            onClick={cancel}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                )}
                            </Formik.Form>
                        )}
                    </Formik.Formik>
                </Col>
            </Row>
        </>
    );
};

export default OrgReportManageForm;
