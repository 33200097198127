import { process, State } from "@progress/kendo-data-query";
import { getter } from "@progress/kendo-react-common";
import {
    getSelectedState,
    Grid,
    GridColumn,
    GridHeaderSelectionChangeEvent,
    GridSelectionChangeEvent,
} from "@progress/kendo-react-grid";
import { useField } from "formik";
import React from "react";
import { ColumnMenuFilter } from "../../../../../common/components/table/kendo/columnFilters";
import GridLoadingPanel from "../../../../../common/components/table/kendo/GridLoadingPanel";
import { SelectedState } from "../../../../../common/components/table/kendo/SelectedState";
import { PastRecommendation } from "../../domain/PastRecommendation";
import { useGetPastRecommendations } from "../../query/examinationsReviewQueries";

export const RECOMMENDATION_SELECTED_FIELD = "recommendations";

type Props = {
    organisationId: string;
    activityId: string;
};

export const PastRecommendationAddGrid: React.FC<Props> = ({
    organisationId,
    activityId,
}) => {
    const DATA_ITEM_KEY = "id";
    const SELECTED_FIELD = "selected";
    const idGetter = getter(DATA_ITEM_KEY);
    const wrapperRef = React.createRef<HTMLElement>();

    const { data, isFetching, isLoading } = useGetPastRecommendations(
        activityId,
        organisationId,
    );

    const isLoadingData = isFetching || isLoading;

    const [selectedState, setSelectedState] = React.useState<SelectedState>({});

    const flatData = React.useMemo(
        () =>
            data?.map((dataItem: PastRecommendation) =>
                Object.assign({ selected: false }, dataItem),
            ) ?? [],
        [data],
    );

    const [dataState, setDataState] = React.useState<State>({
        sort: [
            {
                field: "examId",
                dir: "desc",
            },
        ],
    });

    const [, , helpers] = useField<PastRecommendation[]>(
        RECOMMENDATION_SELECTED_FIELD,
    );

    const onSelectionChange = React.useCallback(
        (event: GridSelectionChangeEvent) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });

            setSelectedState(newSelectedState);
            helpers.setValue(
                flatData.filter((item) => newSelectedState[idGetter(item)]),
            );
        },
        [flatData, helpers, idGetter, selectedState],
    );

    const onHeaderSelectionChange = React.useCallback(
        (event: GridHeaderSelectionChangeEvent) => {
            const checkboxElement = event.syntheticEvent.target;

            const checked = (checkboxElement as HTMLInputElement).checked;
            const newSelectedState = {};

            event.dataItems.forEach((item) => {
                newSelectedState[idGetter(item)] = checked;
            });
            setSelectedState(newSelectedState);
            helpers.setValue(
                flatData.filter((item) => newSelectedState[idGetter(item)]),
            );
        },
        [flatData, helpers, idGetter],
    );

    const result = process(
        flatData
            .map((item) => ({
                ...item,
                [SELECTED_FIELD]: selectedState[idGetter(item)],
            }))
            .slice(0),
        dataState,
    );

    return (
        <>
            <Grid
                sortable={true}
                data={result}
                {...dataState}
                selectable={{
                    enabled: true,
                    mode: "multiple",
                    cell: false,
                }}
                fixedScroll={true}
                reorderable={true}
                resizable={true}
                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                onSelectionChange={onSelectionChange}
                onDataStateChange={(e) => {
                    setDataState(e.dataState);
                }}
                onHeaderSelectionChange={onHeaderSelectionChange}
                className="mt-3"
                style={{
                    height: 600,
                }}
            >
                <GridColumn
                    field={SELECTED_FIELD}
                    width="50px"
                    headerSelectionValue={
                        result.data.findIndex(
                            (item) => !selectedState[idGetter(item)],
                        ) === -1
                    }
                />
                <GridColumn
                    field="location"
                    title="Location"
                    sortable
                    columnMenu={ColumnMenuFilter}
                />
                <GridColumn
                    field="description"
                    title="Description"
                    sortable
                    columnMenu={ColumnMenuFilter}
                />
                <GridColumn
                    field="examId"
                    title="Exam Id"
                    sortable={true}
                    filter={"numeric"}
                    columnMenu={ColumnMenuFilter}
                />
                <GridColumn
                    field="quantityValue"
                    title="QuantityValue"
                    width="auto"
                    sortable
                    columnMenu={ColumnMenuFilter}
                />
                <GridColumn
                    field="quantityUnits"
                    title="Quantity Units"
                    width="auto"
                    sortable
                    columnMenu={ColumnMenuFilter}
                />
                <GridColumn
                    field="worksCategory"
                    title="Works Category"
                    width="auto"
                    sortable
                    columnMenu={ColumnMenuFilter}
                />
            </Grid>
            {isLoadingData && <GridLoadingPanel gridRef={wrapperRef} />}
        </>
    );
};
