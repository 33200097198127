import React, { useCallback } from "react";
import { useInfiniteQuery, UseInfiniteQueryResult } from "react-query";
import { useDebounce } from "use-debounce";
import { DataContinuationResult } from "../../../../common/types/DataResult";
import { ExamRequest } from "../domain/examRequest";
import {
    ExamRequestsSearchOptions,
    getInfiniteExamRequests,
} from "../services/examRequestsService";
import examRequestsQueryKeys from "./examRequestsQueryKeys";

export const useGetInfiniteExamRequests = (
    organisationId: string,
    query: ExamRequestsSearchOptions,
): UseInfiniteQueryResult<DataContinuationResult<ExamRequest>> => {
    const [debouncedQuery] = useDebounce(query, 500);
    const getExaminationRequests = useCallback(
        ({
            pageParam = null,
        }): Promise<DataContinuationResult<ExamRequest>> => {
            const continuationToken = pageParam;

            return getInfiniteExamRequests({
                organisationId,
                query: { ...debouncedQuery, continuationToken },
            });
        },
        [debouncedQuery, organisationId],
    );

    const queryKeys = React.useMemo(
        () =>
            examRequestsQueryKeys.filteredExamRequests(
                organisationId,
                debouncedQuery,
            ),
        [organisationId, debouncedQuery],
    );

    return useInfiniteQuery<
        DataContinuationResult<ExamRequest>,
        [string, { page: number }]
    >(queryKeys, getExaminationRequests, {
        getNextPageParam: (lastResult) => {
            return lastResult.continuationToken;
        },
    });
};
