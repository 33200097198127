import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { AppRoutes } from "../../../../../router/AppRoutes";
import { useNavigation } from "../../../../../router/useNavigate";
import { addSuccess } from "../../../../../store/features/notifications/notificationsSlice";
import { useCreateBatchMutation } from "../../queries/BatchingApiSlice";

type Props = {
    organisationId: string;
};

const BatchCreateButton: React.FC<Props> = ({ organisationId }) => {
    const { navigateToWithOrg } = useNavigation();
    const [createBatch, { isLoading }] = useCreateBatchMutation();
    const dispatch = useDispatch();

    const onClick = () => {
        createBatch(organisationId)
            .unwrap()
            .then((batchId) => {
                dispatch(addSuccess("Batch created successfully."));
                navigateToWithOrg(AppRoutes.BatchingDetails, { batchId });
            });
    };

    return (
        <>
            <Button variant="primary" onClick={onClick} disabled={isLoading}>
                Create
            </Button>
        </>
    );
};

export default BatchCreateButton;
