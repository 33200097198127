import { wrapTagsWithQueryInvalidation } from "../../../query/configureQuery";
import { dcpApi, Tags } from "../../../store/features/dcpApi";
import {
    ActivityActionParams,
    PostNotApplicableParams,
    ProcessActivityPatchModel,
} from "../../../store/features/process-activity/types";
import { examinationQueryKeysTranslator } from "../../cefa/examinations/query/examinationQueryKeysTranslator";
import { ProcessActivity } from "../../process-activity/domain/types";
import { createActivityTags } from "../../process-activity/store/activityTag";
import { ProcessActivityGroup } from "../domain/types";

enum UrlPaths {
    ResourceName = "/processactivitygroup",
    Complete = "/complete",
    NotApplicable = "/not-applicable",
    Open = "/open",
}

const activityGroupTags = (
    activity: ProcessActivity,
    otherTags?: { type: Tags; id?: string }[],
) => createActivityTags(activity, otherTags);

const { tagsToQueryKeys: tagsToExaminationQueryKeys } =
    examinationQueryKeysTranslator;

export const processActivityGroupApi = dcpApi.injectEndpoints({
    endpoints: (builder) => ({
        getProcessActivityGroupById: builder.query<
            ProcessActivityGroup,
            { activityId: string; processId: string }
        >({
            query: ({ activityId }) => `${UrlPaths.ResourceName}/${activityId}`,
            providesTags: (result, error, arg) => [
                { type: Tags.ProcessActivity, id: arg.activityId },
                { type: Tags.ProcessActivitiesForProcess, id: arg.processId },
            ],
        }),
        postProcessActivityGroupComplete: builder.mutation<
            ProcessActivityGroup,
            ActivityActionParams
        >({
            query: ({ activity }) => ({
                url:
                    `${UrlPaths.ResourceName}/${activity.id}` +
                    UrlPaths.Complete,
                method: "POST",
            }),
            invalidatesTags: (result, error, { activity }) =>
                error
                    ? []
                    : activityGroupTags(activity, [
                          {
                              type: Tags.ProcessAssignedList,
                          },
                      ]),
        }),
        postProcessActivityGroupNotApplicable: builder.mutation<
            void,
            PostNotApplicableParams
        >({
            query: ({ activity, comment, reason }) => ({
                url: `${UrlPaths.ResourceName}/${activity.id}${UrlPaths.NotApplicable}`,
                method: "POST",
                body: { comment, reason },
            }),
            invalidatesTags: (result, error, { activity }) =>
                error ? [] : activityGroupTags(activity),
        }),
        postProcessActivityGroupOpen: builder.mutation<
            ProcessActivityGroup,
            ActivityActionParams
        >({
            query: ({ activity }) => ({
                url: `${UrlPaths.ResourceName}/${activity.id}` + UrlPaths.Open,
                method: "POST",
            }),
            invalidatesTags: (result, error, { activity }) =>
                error ? [] : activityGroupTags(activity),
        }),
        patchProcessActivityGroup: builder.mutation<
            ProcessActivityGroup,
            ProcessActivityPatchModel
        >({
            query: (patchModel) => ({
                url: `${UrlPaths.ResourceName}/${patchModel.activityId}`,
                method: "PATCH",
                body: patchModel,
            }),
            invalidatesTags: (_result, _error, params) =>
                wrapTagsWithQueryInvalidation(
                    [
                        { type: Tags.ProcessActivity, id: params.activityId },
                        { type: Tags.Process, id: params.processId },
                        { type: Tags.ProcessAssignedList },
                    ],
                    tagsToExaminationQueryKeys,
                ),
        }),
    }),
});

export const {
    usePostProcessActivityGroupCompleteMutation,
    usePostProcessActivityGroupNotApplicableMutation,
    usePostProcessActivityGroupOpenMutation,
    useGetProcessActivityGroupByIdQuery,
    usePatchProcessActivityGroupMutation,
} = processActivityGroupApi;
