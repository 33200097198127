import * as React from "react";
import {
    FaBriefcase,
    FaClipboard,
    FaClipboardList,
    FaDownload,
    FaExchangeAlt,
    FaExclamationCircle,
    FaExclamationTriangle,
    FaLayerGroup,
    FaList,
    FaTasks,
    FaTools,
} from "react-icons/fa";
import NavGroupWithMenu from "../../../../common/components/navigation-sidebar/NavGroupWithMenu";
import NavLink from "../../../../common/components/NavLink";
import useViewPermissions from "../../../../common/hooks/useViewPermissions";
import { AppRoutes } from "../../../../router/AppRoutes";
import { useNavigation } from "../../../../router/useNavigate";
import { useOrganisationId } from "../../../organisation/hooks/useOrganisationId";

const CefaSideMenu: React.FC = () => {
    const { getOrgLink: getLink } = useNavigation();

    const { organisationId } = useOrganisationId();

    const {
        canViewWorkbanks,
        canViewExaminationsReview,
        canViewCommercialDownloadPage,
        canViewReportDownloadPage,
        canViewPerformanceDownloadPage,
        canViewReactiveExamCreatorPage,
        canViewBatching,
        canViewMissingCarrsExamToolPage,
        canViewApprovedExams,
        canViewCesExamRequest,
        canViewExamTools,
        canApc,
        canViewExamManagementPage,
    } = useViewPermissions(organisationId);

    const examToolsSubLinks = [
        canViewExamManagementPage && {
            label: "Exam Management",
            to: getLink(AppRoutes.ExamManagement),
            icon: FaExchangeAlt,
        },
        canViewMissingCarrsExamToolPage && {
            label: "Missing Carrs Exam",
            to: getLink(AppRoutes.MissingCarrsExam),
            icon: FaExclamationCircle,
        },
        canViewApprovedExams && {
            label: "Approved Exams Report",
            to: getLink(AppRoutes.ApprovedExams),
            icon: FaClipboardList,
        },
        canViewBatching && {
            label: "Batching",
            to: getLink(AppRoutes.Batching),
            icon: FaLayerGroup,
        },
    ].filter(Boolean);

    const reportsSubLinks = [
        canViewCommercialDownloadPage && {
            label: "Commercial Report",
            to: getLink(AppRoutes.CommercialReport),
            icon: FaDownload,
        },
        canViewReportDownloadPage && {
            label: "Progress Report",
            to: getLink(AppRoutes.ProgressReportExport),
            icon: FaDownload,
        },
        canViewPerformanceDownloadPage && {
            label: "Performance Report",
            to: getLink(AppRoutes.PerformanceReportExport),
            icon: FaDownload,
        },
    ].filter(Boolean);

    return (
        <>
            {canApc && (
                <NavLink
                    to={getLink(AppRoutes.Apc)}
                    label="APC"
                    icon={FaExclamationTriangle}
                    eventKey="apc"
                />
            )}
            {canViewWorkbanks && (
                <NavLink
                    to={getLink(AppRoutes.Workbank)}
                    label="Workbank Upload"
                    icon={FaBriefcase}
                    eventKey="workbank"
                />
            )}
            {canViewExaminationsReview && (
                <NavLink
                    to={getLink(AppRoutes.EngineerReview)}
                    label="Engineer Review"
                    icon={FaTasks}
                    eventKey="engineer-review"
                />
            )}
            {(canViewCommercialDownloadPage ||
                canViewReportDownloadPage ||
                canViewPerformanceDownloadPage) && (
                <NavGroupWithMenu
                    label="Reports"
                    icon={FaDownload}
                    eventKey="reports"
                    subLinks={reportsSubLinks}
                />
            )}
            {canViewReactiveExamCreatorPage && (
                <NavLink
                    to={getLink(AppRoutes.ReactiveExamCreator)}
                    label="Reactive Exam Creator"
                    icon={FaClipboard}
                    eventKey="Reactive Exam Creator"
                />
            )}
            {canViewExamTools && (
                <NavGroupWithMenu
                    label="Exam Tools"
                    icon={FaTools}
                    eventKey="examTools"
                    subLinks={examToolsSubLinks}
                />
            )}

            {canViewCesExamRequest && (
                <NavLink
                    to={getLink(AppRoutes.ExamRequests)}
                    label="Exam requests"
                    icon={FaList}
                    eventKey="exam-requests"
                />
            )}
        </>
    );
};

export default CefaSideMenu;
