import {
    createSlice,
    PayloadAction,
    SliceCaseReducers,
} from "@reduxjs/toolkit";
import {
    NotificationType,
    Notification,
} from "../../../modules/notifications/domain/types";

export type NotificationState = Notification[];

const initialState: NotificationState = [];

const notificationsSlice = createSlice<
    NotificationState,
    SliceCaseReducers<NotificationState>
>({
    name: "notifications",
    initialState,
    reducers: {
        addError(state, { payload }: PayloadAction<string>): NotificationState {
            return [
                ...state,
                { type: NotificationType.Error, content: payload },
            ];
        },
        addWarning(
            state,
            { payload }: PayloadAction<string>,
        ): NotificationState {
            return [
                ...state,
                { type: NotificationType.Warning, content: payload },
            ];
        },
        addSuccess(
            state,
            { payload }: PayloadAction<string>,
        ): NotificationState {
            return [
                ...state,
                { type: NotificationType.Success, content: payload },
            ];
        },
        remove(state, { payload }: PayloadAction<string>): NotificationState {
            return state.filter((item) => item.content !== payload);
        },
    },
});

export const { addError, addWarning, addSuccess, remove } =
    notificationsSlice.actions;
export const reducer = notificationsSlice.reducer;
