import React, { useEffect } from "react";
import { useGetConfigQuery } from "../../store/config/configApi";
import consumerApiService from "./consumerApiService";

export const ConsumerApiProvider: React.FC = ({ children }) => {
    const configResult = useGetConfigQuery();

    useEffect(() => {
        if (configResult.isSuccess) {
            consumerApiService.initialize(
                configResult.data.ClientApiEndpointAddress,
            );
        }
    }, [configResult]);

    return <>{children}</>;
};
