import cx from "classnames";
import { ErrorMessage, FieldAttributes, useField } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { mapToSelectOptions } from "../../types/reactSelect/SelectOptionItem";
import "./customSelect.scss";
import { useGetUsersByOrgIdQuery } from "../../../store/features/organisation/organisation-api-slice";
import { withUserFilter } from "../../../modules/common/filters/helpers/userFilters";
import { TemplateContentFilter } from "../../../modules/common/filters/domain/types";
import TextInput from "./TextInput";

type Props = {
    label?: string;
    className?: string;
    readOnly?: boolean;
    plainText?: boolean;
    placeholder?: string;
    organisationId: string;
    filterOptions: TemplateContentFilter;
} & FieldAttributes<unknown>;

export const UserSelect: React.FC<Props> = ({
    name,
    label,
    className,
    readOnly,
    placeholder,
    filterOptions,
    organisationId,
    plainText,
}) => {
    const [field, meta, helpers] = useField<unknown>(name);

    const { data: organisationUsers } = useGetUsersByOrgIdQuery(organisationId);

    const assignedToOptions = React.useMemo(() => {
        return mapToSelectOptions(
            withUserFilter(organisationUsers, filterOptions),
            (i) => i.userId,
            (i) => i.username,
        );
    }, [organisationUsers, filterOptions]);

    if (plainText)
        return (
            <TextInput
                name={field.name}
                label={label}
                plainText={true}
                readOnly={true}
            />
        );

    return (
        <Form.Group controlId={field.name}>
            {label && <Form.Label>{label}</Form.Label>}
            <Select
                name={field.name}
                value={assignedToOptions.find(
                    (ato) => ato.value === field.value,
                )}
                placeholder={placeholder}
                onChange={(value) => helpers.setValue(value)}
                options={assignedToOptions}
                onBlur={field.onBlur}
                isMulti={false}
                isClearable={true}
                classNamePrefix="custom-select"
                className={cx(className, {
                    "is-invalid": meta.touched && meta.error,
                })}
                isDisabled={readOnly}
            />
            <ErrorMessage
                name={field.name}
                className="invalid-feedback"
                component="div"
            ></ErrorMessage>
        </Form.Group>
    );
};

export default UserSelect;
