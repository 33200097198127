import { CallHistoryMethodAction, push } from "connected-react-router";
import React, { Dispatch, useState } from "react";
import { ButtonGroup, ButtonToolbar } from "react-bootstrap";
import { FaRegStar, FaStar } from "react-icons/fa";
import { useDispatch } from "react-redux";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { MultiMutationResultStatus } from "../../../common/components/MutationResultStatus";
import QueryResultStatus from "../../../common/components/QueryResultStatus";
import { IconButton } from "../../../common/components/icon-button/IconButton";
import { SelectedState } from "../../../common/components/table/kendo/SelectedState";
import { emptyFilterDescriptor } from "../../../common/components/table/kendo/columnFilters";
import useIsClient from "../../../common/hooks/useIsClient";
import { useWindowSize } from "../../../common/hooks/useWindowSize";
import { breakpointLg } from "../../../common/layout/breakpoints";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import {
    useChangeFileNameMutation,
    useDeleteFileMutation,
    useUploadFileMutation,
} from "../../../store/features/file/fileApiSlice";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import { useAppSelector } from "../../../store/hooks";
import SbimConstants from "../../sbim/constants/SbimConstants";
import { CreateFolderButton } from "../components/CreateFolderButton";
import { DirectoryBreadcrumb } from "../components/breadCrumbs/DirectoryBreadcrumb";
import { FileAndFoldersGrid } from "../components/FileAndFoldersGrid";
import { FileOrFolderContextMenu } from "../components/FileOrFolderContextMenu";
import { FileUploadButton } from "../components/FileUploadButton";
import FolderTags from "../components/FolderTags";
import { MoveCoordinator } from "../components/MoveCoordinator";
import { MoveSelectedEntriesButton } from "../components/MoveSelectedEntriesButton";
import NameChangeContextProvider from "../components/NameChangeContextProvider";
import { isNewNameDifferent } from "../domain/folder";
import { useContextMenuState } from "../hooks/useContextMenu";
import { useFilesPageState } from "../hooks/useFilesPageState";
import { FileSearchBar } from "../components/FileSearchBar";
import { downloadFile, openPdfInTab } from "../services/downloadFile";
import {
    EntryType,
    WithDataItem,
    getSelectedViewEntries,
    isContextMenuNeeded,
    mapToViewEntries,
} from "../viewModel/FileViewEntry";
import FolderUpload from "./FolderUpload";
import { Organisation } from "../../organisation/domain/types";
import {
    useAddNewFolderMutation,
    useChangeFolderNameMutation,
    useDeleteFolderMutation,
    useFavoriteFolderMutation,
    useListFolderQuery,
} from "../../../store/features/folder/folderApiSlice";
import { setName } from "../../../store/features/folder/fileOrFolderNameSlice";
import ViewMetaDataModal from "../components/metadata/ViewMetadataModal";
import { ViewMetaDataButton } from "../components/metadata/ViewMetaDataButton";

const navigateToSubFolderOrFile =
    (
        dispatch: Dispatch<CallHistoryMethodAction<string[]>>,
        orgShortName: string,
        orgId: string,
        folderId: string,
        directDownload: boolean,
        beforeNavigation: () => void,
    ) =>
    ({ dataItem }: WithDataItem): void => {
        const { type, id } = dataItem;
        const consts = OrganisationConstants;

        switch (type) {
            case EntryType.NavigateUp:
            case EntryType.Folder:
                beforeNavigation();
                dispatch(push(`/${orgShortName}/${consts.folder}/${id}`));
                break;
            case EntryType.File:
                beforeNavigation();
                directDownload
                    ? downloadClick(folderId, orgId)({ dataItem })
                    : dispatch(
                          push(
                              `/${orgShortName}/${consts.folder}/${folderId}/${consts.file}/${id}`,
                          ),
                      );
        }
    };

const downloadClick =
    (folder: string, organisationId: string) =>
    async ({ dataItem }: WithDataItem): Promise<void> => {
        openPdfInTab({
            fileId: dataItem.id,
            fileName: dataItem.label,
            folderId: folder,
            organisationId: organisationId,
        });
    };

interface Props {
    folderId: string;
    organisation: Organisation;
}

const FolderContents: React.FC<Props> = ({ folderId, organisation }) => {
    const dispatch = useDispatch();

    const { data: folder, ...folderQueryResult } = useListFolderQuery(
        { organisationId: organisation.id, folderId },
        { skip: !organisation.id },
    );
    const gridContainerRef = React.useRef<HTMLDivElement>(null);
    const [state, stateActions] = useFilesPageState();

    const canCreateFiles = hasUserOrgPermission(
        organisation.id,
        PermissionConstants.OrgFilesFile.create,
    ).hasPermission;
    const canDeleteFiles = hasUserOrgPermission(
        organisation.id,
        PermissionConstants.OrgFilesFile.delete,
    ).hasPermission;
    const canUpdateFiles = hasUserOrgPermission(
        organisation.id,
        PermissionConstants.OrgFilesFile.update,
    ).hasPermission;
    const canMoveFiles = hasUserOrgPermission(
        organisation.id,
        PermissionConstants.OrgFilesFile.move,
    ).hasPermission;
    const canReplaceFiles = hasUserOrgPermission(
        organisation.id,
        PermissionConstants.OrgFilesFileReplace,
    ).hasPermission;

    const canDeleteFolders = hasUserOrgPermission(
        organisation.id,
        PermissionConstants.OrgFilesFolder.delete,
    ).hasPermission;
    const canUpdateFolders = hasUserOrgPermission(
        organisation.id,
        PermissionConstants.OrgFilesFolder.update,
    ).hasPermission;
    const canMoveFolders = hasUserOrgPermission(
        organisation.id,
        PermissionConstants.OrgFilesFolder.move,
    ).hasPermission;
    const canCreateFolders = hasUserOrgPermission(
        organisation.id,
        PermissionConstants.OrgFilesFolder.create,
    ).hasPermission;
    const canViewMetadata =
        hasUserOrgPermission(
            organisation.id,
            PermissionConstants.OrgFilesFolder.read,
        ).hasPermission && organisation.features?.files?.collectMetaData;

    const { offset, dataItem, closeContextMenu, rowRender } =
        useContextMenuState(
            isContextMenuNeeded(canDeleteFolders || canUpdateFolders),
        );

    const fileOrFolderName = useAppSelector((s) => s.fileOrFolderName);

    const [addNewFolder, addNewFolderResult] = useAddNewFolderMutation();
    const [changeFolderName, changeFolderNameResult] =
        useChangeFolderNameMutation();
    const [changeFileName, changeFileNameResult] = useChangeFileNameMutation();
    const [deleteFolder, deleteFolderResult] = useDeleteFolderMutation();
    const [uploadFile, uploadFileResult] = useUploadFileMutation();
    const [deleteFile, deleteFileResult] = useDeleteFileMutation();
    const [setFavorite] = useFavoriteFolderMutation();

    const isAboveLg = useWindowSize().width >= breakpointLg;
    const [filter, setFilter] = React.useState(emptyFilterDescriptor);
    const clearFilter = () => setFilter(emptyFilterDescriptor);
    const [selectedState, setSelectedState] = React.useState<SelectedState>({});
    const clearSelection = () => setSelectedState({});
    const [metaDataFile, setMetaDataFile] = useState<string>(null);

    const startAddingNewFolder = () => {
        stateActions.startAddingNew();
        dispatch(setName("New folder name"));
    };
    const startEditingFileOrFolder = () => {
        stateActions.startEditing(dataItem.id, dataItem.type);
        dispatch(setName(dataItem.label));
    };

    const canSetFavorites = hasUserOrgPermission(
        organisation.id,
        PermissionConstants.OrgFilesFolder.favorite,
    ).hasPermission;

    const onSetFavorite = () => {
        setFavorite({
            organisationId: organisation.id,
            folderId: folderId,
            isFavorite: !folder.isFavorite,
        });
    };

    const getNameChangeMutation = (): Promise<unknown> => {
        switch (state.mode) {
            case "AddNew": {
                return addNewFolder({
                    organisationId: organisation.id,
                    folderName: fileOrFolderName,
                    folderId: folderId,
                });
            }
            case "EditExisting": {
                if (
                    !isNewNameDifferent(folder, {
                        id: state.id,
                        name: fileOrFolderName,
                    })
                ) {
                    return null;
                } else if (state.type === EntryType.Folder) {
                    return changeFolderName({
                        folderId: state.id,
                        parentFolderId: folderId,
                        organisationId: organisation.id,
                        folderName: fileOrFolderName,
                    });
                } else {
                    return changeFileName({
                        fileId: state.id,
                        folderId: folderId,
                        organisationId: organisation.id,
                        fileName: fileOrFolderName,
                    });
                }
            }
            default:
                return null;
        }
    };

    const submitFileOrFolderNameChange = (): void => {
        getNameChangeMutation()?.then(clearFilter);
        stateActions.goBackToDefaultMode();
    };

    const cancelNameChange = (): void => {
        stateActions.goBackToDefaultMode();
    };

    const entries = mapToViewEntries(folder, state, selectedState);
    const selectedEntries = getSelectedViewEntries(entries);
    const isSBIM = useIsClient(SbimConstants.ClientName);

    const [viewMetadataColumns, setViewMetadataColumns] = useState(false);

    const toggleMetadata = () => {
        setViewMetadataColumns(!viewMetadataColumns);
    };

    return (
        <NameChangeContextProvider
            submitNameChange={submitFileOrFolderNameChange}
            cancelNameChange={cancelNameChange}
        >
            <div className="pt-4">
                <DirectoryBreadcrumb
                    path={folder?.path || []}
                    activeId={folderId}
                />
            </div>
            {metaDataFile && (
                <ViewMetaDataModal
                    fileId={metaDataFile}
                    folderId={folderId}
                    organisationId={organisation.id}
                    onClose={() => setMetaDataFile(null)}
                />
            )}
            {isSBIM ? (
                <div className="pt-4" ref={gridContainerRef}>
                    <FileSearchBar
                        organisation={organisation}
                        folderId={folderId}
                        uploadFile={uploadFile}
                        folder={folder}
                        startAddingNewFolder={startAddingNewFolder}
                        orgPermissions={{
                            canCreateFiles,
                            canUpdateFiles,
                            canReplaceFiles,
                            canUpdateFolders,
                            canCreateFolders,
                            canSetFavorites,
                        }}
                        toggleMetadata={toggleMetadata}
                    />
                    <QueryResultStatus queryResult={folderQueryResult} />
                    <FileAndFoldersGrid
                        entries={entries}
                        additionalColumns={{
                            dateModified: isAboveLg,
                            modifiedBy: isAboveLg,
                            menu: true,
                        }}
                        filterState={[filter, setFilter]}
                        filterByContains
                        rowRender={rowRender}
                        onRowDoubleClick={navigateToSubFolderOrFile(
                            dispatch,
                            organisation.shortName,
                            organisation.id,
                            folderId,
                            isSBIM,
                            clearSelection,
                        )}
                        selectedStateAccessors={[
                            selectedState,
                            setSelectedState,
                        ]}
                        viewMetadata={viewMetadataColumns}
                        organisation={organisation}
                    />
                </div>
            ) : (
                <>
                    {folder && (
                        <div className="pt-4">
                            <FolderTags
                                folder={folder}
                                folderId={folderId}
                                organisationId={organisation.id}
                            />
                        </div>
                    )}

                    <div className="pt-4">
                        <ButtonToolbar>
                            <ButtonGroup className="mr-2">
                                <CreateFolderButton
                                    organisationId={organisation.id}
                                    buttonProps={{
                                        onClick:
                                            state.mode === "Default"
                                                ? startAddingNewFolder
                                                : null,
                                        disabled: state.mode !== "Default",
                                    }}
                                />
                                <FileUploadButton
                                    organisation={organisation}
                                    folderId={folderId}
                                    uploadFile={uploadFile}
                                />
                                {canCreateFiles &&
                                    canCreateFolders &&
                                    canUpdateFiles &&
                                    canUpdateFolders &&
                                    canReplaceFiles && (
                                        <FolderUpload
                                            organisation={organisation}
                                            folderId={folderId}
                                        />
                                    )}
                                <ViewMetaDataButton
                                    organisation={organisation}
                                    onClick={toggleMetadata}
                                />
                            </ButtonGroup>
                            <ButtonGroup>
                                <MoveSelectedEntriesButton
                                    selectedFiles={selectedEntries.fileEntries}
                                    selectedFolders={
                                        selectedEntries.folderEntries
                                    }
                                    organisationId={organisation.id}
                                />
                            </ButtonGroup>
                            {folder && canSetFavorites && (
                                <ButtonGroup className="ml-auto">
                                    <IconButton
                                        icon={
                                            folder.isFavorite
                                                ? FaStar
                                                : FaRegStar
                                        }
                                        onClick={onSetFavorite}
                                        iconSize="20"
                                    />
                                </ButtonGroup>
                            )}
                        </ButtonToolbar>
                    </div>
                    <div className="pt-4" ref={gridContainerRef}>
                        <QueryResultStatus queryResult={folderQueryResult} />
                        <FileAndFoldersGrid
                            entries={entries}
                            additionalColumns={{
                                dateModified: isAboveLg,
                                modifiedBy: isAboveLg,
                                menu: true,
                            }}
                            filterState={[filter, setFilter]}
                            filterByContains
                            rowRender={rowRender}
                            onRowDoubleClick={navigateToSubFolderOrFile(
                                dispatch,
                                organisation.shortName,
                                organisation.id,
                                folderId,
                                isSBIM,
                                clearSelection,
                            )}
                            selectedStateAccessors={[
                                selectedState,
                                setSelectedState,
                            ]}
                            viewMetadata={viewMetadataColumns}
                            organisation={organisation}
                        />
                    </div>
                </>
            )}
            <FileOrFolderContextMenu
                folderId={folderId}
                dataItem={dataItem}
                offset={offset}
                close={closeContextMenu}
                canDeleteFiles={canDeleteFiles}
                canUpdateFiles={canUpdateFiles}
                canMoveFiles={canMoveFiles}
                canDeleteFolders={canDeleteFolders}
                canUpdateFolders={canUpdateFolders}
                canMoveFolders={canMoveFolders}
                canViewMetadata={canViewMetadata}
                organisation={organisation}
                onEditClick={startEditingFileOrFolder}
                onDeleteFolderClick={() =>
                    deleteFolder({
                        organisationId: organisation.id,
                        folderId: dataItem.id,
                        parentFolderId: folderId,
                    })
                }
                onDeleteFileClick={() =>
                    deleteFile({
                        organisationId: organisation.id,
                        folderId,
                        fileId: dataItem.id,
                    })
                }
                onDownloadClick={async () =>
                    downloadFile({
                        fileId: dataItem.id,
                        fileName: dataItem.label,
                        folderId,
                        organisationId: organisation.id,
                    })
                }
                onViewMetaDataClick={async () => {
                    setMetaDataFile(dataItem.id);
                }}
            />
            <MultiMutationResultStatus
                results={[
                    addNewFolderResult,
                    changeFolderNameResult,
                    changeFileNameResult,
                    deleteFolderResult,
                    uploadFileResult,
                    deleteFileResult,
                ]}
            />
            <MoveCoordinator folderId={folderId} />
        </NameChangeContextProvider>
    );
};

export default FolderContents;
