import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import HelpSectionHelpItemList from "./HelpSectionHelpItemList";

const HelpSectionManagement: React.FC = () => {
    const { id: organisationId } = useParams<{ id: string }>();

    return (
        <Row>
            <Col>
                <Row className="top10">
                    <Col>
                        <Link
                            to={`/organisation/${organisationId}/help-items/create`}
                            className="btn btn-primary float-right"
                        >
                            Create
                        </Link>
                    </Col>
                </Row>
                <Row className="top10">
                    <HelpSectionHelpItemList organisationId={organisationId} />
                </Row>
            </Col>
        </Row>
    );
};

export default HelpSectionManagement;
