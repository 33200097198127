import * as React from "react";
import AuditActionListItem from "../../../common/components/audit/AuditActionListItem";
import { AuditAction } from "../../../common/types/AuditAction";
import { useParams } from "react-router";
import { useGetOrgByIdQuery } from "../../../store/features/organisation/organisation-api-slice";

/**
 * This class populates a list of audit actions performed against a loaded organisation
 */
const OrganisationAuditActionList: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { data, isLoading } = useGetOrgByIdQuery(id, {
        skip: !id,
    });

    return (
        <div>
            {isLoading && <span>Loading...</span>}
            <ul className="timeline">
                {data.auditActions?.map((item: AuditAction, index: number) => (
                    <AuditActionListItem key={index.toString()} data={item} />
                )) || []}
            </ul>
        </div>
    );
};

export default OrganisationAuditActionList;
