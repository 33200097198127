import { filterBy, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import {
    Grid,
    GridNoRecords,
    GridSelectionChangeEvent,
} from "@progress/kendo-react-grid";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import { GridProps } from "@progress/kendo-react-grid/dist/npm/interfaces/GridProps";
import React from "react";
import {
    ContainsMenuFilter,
    emptyFilterDescriptor,
    getColumnMenuCheckboxFilter,
    SimplifiedMenuFilter,
} from "../../../common/components/table/kendo/columnFilters";
import { SelectedState } from "../../../common/components/table/kendo/SelectedState";
import "../../../common/components/table/table.css";
import { GroupedFileViewEntries } from "../viewModel/FileViewEntry";
import { FileViewEntryIconCell } from "./gridCells/FileViewEntryIconCell";
import { NameCell } from "./gridCells/FolderNameCell";
import { MenuIconCell } from "./gridCells/MenuIconCell";
import { Organisation } from "../../organisation/domain/types";

type FilterState = ReturnType<typeof useFilterState>;

interface FileAndFoldersGridProps {
    entries: GroupedFileViewEntries;
    additionalColumns?: ColumnOptions;
    onRowDoubleClick?: GridProps["onRowDoubleClick"];
    onRowClick?: GridProps["onRowClick"];
    rowRender?: GridProps["rowRender"];
    selectedStateAccessors?: [SelectedState, (value: SelectedState) => void];
    filterState?: FilterState;
    gridEmptyText?: string;
    filterByContains?: boolean;
    viewMetadata?: boolean;
    organisation?: Organisation;
}

interface ColumnOptions {
    dateModified?: boolean;
    modifiedBy?: boolean;
    menu?: boolean;
    location?: boolean;
    createdOn?: boolean;
    comment?: boolean;
}

function useFilterState() {
    return React.useState(emptyFilterDescriptor);
}

export function FileAndFoldersGrid({
    entries,
    additionalColumns = {},
    onRowDoubleClick,
    onRowClick,
    rowRender,
    filterState,
    selectedStateAccessors,
    gridEmptyText,
    filterByContains,
    viewMetadata,
    organisation,
}: FileAndFoldersGridProps): JSX.Element {
    const { folderEntries, fileEntries, virtualEntries } = entries;

    const [iconCellWidth, dateCellWidth] = [48, 180];
    const [sort, setSort] = React.useState<SortDescriptor[]>([]);
    let [filter, setFilter] = React.useState(emptyFilterDescriptor);

    if (filterState) {
        filter = filterState[0];
        setFilter = filterState[1];
    }
    const [selectedState, setSelectedState] = selectedStateAccessors || [];
    const columnMenuCheckboxFilter = getColumnMenuCheckboxFilter(
        folderEntries.concat(fileEntries),
    );

    const allRows = virtualEntries
        .concat(orderBy(filterBy(folderEntries, filter), sort))
        .concat(orderBy(filterBy(fileEntries, filter), sort));

    const onSelectionChange = (event: GridSelectionChangeEvent) => {
        const dataItem = event.dataItem || event.dataItems[event.startRowIndex];
        const newSelectedState = {
            ...selectedState,
            [dataItem.id]: !selectedState[dataItem.id],
        };
        setSelectedState(newSelectedState);
    };

    const metaDataColumns = () => {
        return organisation?.features?.files?.metaData?.map((field) => {
            return (
                <Column
                    key={field.field}
                    field={field.field}
                    title={field.field}
                    filterable={false}
                    editable={false}
                    resizable={true}
                    width={150}
                    reorderable={false}
                />
            );
        });
    };

    return (
        <Grid
            data={allRows}
            scrollable="none"
            onRowDoubleClick={onRowDoubleClick}
            onRowClick={onRowClick}
            editField="inEdit"
            rowRender={rowRender}
            sortable={true}
            sort={sort}
            onSortChange={(e) => setSort(e.sort)}
            filter={filter}
            onFilterChange={(e) => setFilter(e.filter)}
            className="single-line-cells selectable-rows"
            selectable={{
                enabled: selectedState !== null,
                mode: "multiple",
            }}
            selectedField="selected"
            dataItemKey="id"
            onSelectionChange={selectedState ? onSelectionChange : null}
            resizable={true}
            reorderable={true}
        >
            {gridEmptyText && <GridNoRecords>{gridEmptyText}</GridNoRecords>}
            <Column
                cell={FileViewEntryIconCell}
                width={iconCellWidth}
                editable={false}
                filterable={false}
            />
            <Column
                field="label"
                title="Name"
                cell={NameCell}
                columnMenu={
                    filterByContains
                        ? columnMenuCheckboxFilter
                        : ContainsMenuFilter
                }
            />
            {additionalColumns.location && (
                <Column
                    field="location"
                    title="Location"
                    editable={false}
                    filterable={false}
                />
            )}
            {additionalColumns.dateModified && (
                <Column
                    field="dateModified"
                    width={dateCellWidth}
                    title="Date modified"
                    editable={false}
                    filter={"date"}
                    format="{0:d MMM y h:mm a}"
                    columnMenu={SimplifiedMenuFilter}
                />
            )}
            {additionalColumns.modifiedBy && (
                <Column
                    field="modifiedBy"
                    title="Last modified by"
                    editable={false}
                    columnMenu={columnMenuCheckboxFilter}
                />
            )}
            {additionalColumns.comment && (
                <Column
                    field="comment"
                    title="Comment"
                    editable={false}
                    columnMenu={columnMenuCheckboxFilter}
                />
            )}
            {additionalColumns.createdOn && (
                <Column
                    field="createdOn"
                    title="Creation date"
                    editable={false}
                    filter={"date"}
                    columnMenu={SimplifiedMenuFilter}
                />
            )}
            {viewMetadata && metaDataColumns()}
            {additionalColumns.menu && (
                <Column
                    cell={MenuIconCell}
                    width={iconCellWidth}
                    editable={false}
                    filterable={false}
                />
            )}
        </Grid>
    );
}
