import * as React from "react";
import {
    TemplateContentStyle,
    ShowCondition,
} from "../../../modules/template/domain/types";
import FormControlBase from "./FormControlBase";
import FormPromptComponent from "./FormPromptComponent";

interface FormHeaderConfig {
    field: string;
    value: string;
    prompt: string;
    uri: string;
    style: TemplateContentStyle;
    showConditions: ShowCondition[];
}

interface Prop {
    config: FormHeaderConfig;
}

const FormHeaderComponent: React.FC<Prop> = (props) => {
    const { config } = props;

    return (
        <FormControlBase
            contentConfig={{
                field: config.field,
                showConditions: config.showConditions,
            }}
        >
            <p
                className={`h${config.style?.size ?? 4}`}
                style={{ color: `${config.style?.color ?? "none"}` }}
            >
                {config.value}
            </p>
            <FormPromptComponent config={config} />
        </FormControlBase>
    );
};

export default FormHeaderComponent;
