import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import InvitationList from "./InvitationList";
import { useOrganisationId } from "../../hooks/useOrganisationId";
import { WithOrgShortName } from "../../../../common/types/WithOrg";
import OrganisationConstants from "../../../../Constants/OrganisationConstants";

const InvitationManagement: React.FC = () => {
    const { organisationId } = useOrganisationId();
    const { orgShortName } = useParams<WithOrgShortName>();
    const { invitations, actionCreate } = OrganisationConstants;

    return (
        <>
            <h1 className="top30">Invitations</h1>
            <Row>
                <Col>
                    <Row className="top10">
                        <Col>
                            <Link
                                to={`/${orgShortName}/${invitations}${actionCreate}`}
                                className="btn btn-primary float-right"
                            >
                                Create
                            </Link>
                        </Col>
                    </Row>
                    <Row className="top10">
                        <InvitationList organisationId={organisationId} />
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default InvitationManagement;
