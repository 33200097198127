import { QueryClient, QueryKey } from "react-query";
import { InvalidateTag } from "../store/features/dcpApi";

export const queryClient = new QueryClient();

export type TranslateTagFunc = (tag: InvalidateTag) => QueryKey;
export type TranslateQueryKeyFunc = (
    queryKey: QueryKey,
    ids?: string[],
) => InvalidateTag[];

export const wrapTagsWithQueryInvalidation = (
    tags: InvalidateTag[],
    translateTagFunc: TranslateTagFunc,
): InvalidateTag[] => {
    tags.forEach((tag) => {
        const queryKey = translateTagFunc(tag);

        if (queryKey) queryClient.invalidateQueries(queryKey);
    });

    return tags;
};

export const wrapTagsWithQueriesInvalidation = (
    tags: InvalidateTag[],
    translateTagFunctions: TranslateTagFunc[],
): InvalidateTag[] => {
    tags.forEach((tag) => {
        translateTagFunctions.forEach((translateTagFunc) => {
            const queryKey = translateTagFunc(tag);

            if (queryKey) queryClient.invalidateQueries(queryKey);
        });
    });

    return tags;
};
