import {
    Grid,
    GridColumn,
    GridExpandChangeEvent,
    GridItemChangeEvent,
    GridRowClickEvent,
    GridRowProps,
} from "@progress/kendo-react-grid";
import cx from "classnames";
import { ArrayHelpers, getIn, useFormikContext } from "formik";
import * as React from "react";
import { jSDateFromIso } from "../../../../../../helpers/dateTimeHelpers";
import { CefaTemplateContentFileRecord } from "../../../../../template/domain/types";
import { downloadFormFile } from "../../../../../../services/FileDownloadService";
import { TypedGridCellProps } from "../../../../../../common/types/TypedGridCellProps";
import {
    ContainsMenuFilter,
    SimplifiedMenuFilter,
} from "../../../../../../common/components/table/kendo/columnFilters";
import useGridProps from "../../../../../../common/components/table/kendo/useGridProps";
import { DynamicFormContext } from "../../../../../../common/components/dynamic-form/DynamicFormContextProvider";
import CefaFormControlFileDetails from "./CefaFormControlFileDetails";
import FormControlMediaTableActions from "../../../../../../common/components/dynamic-form/FormControlFile/FormControlFileTableActions";
import CefaFormControlFileTableCommentCell from "./CefaFormControlFileTableCommentCell";
import {
    CefaFormControlFileTableDataItem,
    mapToFileTableItem,
} from "./CefaFormControlFileTableDataItem";
import FormControlFileTablePreview from "../../../../../../common/components/dynamic-form/FormControlFile/FormControlFileTablePreview";
import CefaFormControlFileTableCaptionDateCell from "./CefaFormControlFileTableCaptionDateCell";
import {
    getCaptionDateFieldFromCefaComment,
    getCommentFieldFromCefaComment,
} from "../../../helpers/commentHelper";

interface FormControlMediaTableConfig {
    field: string;
    mediaOnly: boolean;
    isReadOnly: boolean;
}

interface Props {
    config: FormControlMediaTableConfig;
    arrayHelpers: ArrayHelpers;
    isValid: boolean;
    readonly: boolean;
}

type FileData = {
    expanded?: boolean;
    createdOn: Date;
    captionDate: Date;
} & Omit<CefaTemplateContentFileRecord, "createdOn" | "captionDate">;

const CefaFormControlFileTable: React.FC<Props> = ({
    config,
    arrayHelpers,
    isValid,
    readonly,
}) => {
    const { getFileUrl } = React.useContext(DynamicFormContext);
    const [editId, setEditId] = React.useState<string>(null);
    const [showSection, setShowSection] = React.useState<boolean>(true);
    const [showPreviews, setShowPreviews] = React.useState<boolean>(true);
    const formik = useFormikContext();
    const rowData = React.useMemo(
        () =>
            (getIn(formik.values, config.field) ||
                []) as CefaTemplateContentFileRecord[],
        [formik.values, config.field],
    );

    function getFileData(files: CefaTemplateContentFileRecord[]): FileData[] {
        return files.map(({ createdOn, captionDate, comment, ...other }) => ({
            readOnly: config.isReadOnly,
            expanded: false,
            comment: getCommentFieldFromCefaComment(comment),
            createdOn: jSDateFromIso(createdOn) || null,
            captionDate:
                jSDateFromIso(getCaptionDateFieldFromCefaComment(comment)) ||
                null,
            ...other,
        }));
    }

    const data = React.useMemo(
        () =>
            rowData.map((row, index) => mapToFileTableItem(row, index, editId)),
        [rowData, editId],
    );

    const [filesData, setFilesData] = React.useState<FileData[]>(
        getFileData(data),
    );

    React.useEffect(() => {
        setFilesData(getFileData(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const { gridProps } = useGridProps(filesData, {
        filterable: true,
        sortable: true,
        initialSort: [{ field: "createdOn", dir: "asc" }],
        pageable: false,
    });

    const PreviewCell = React.useMemo(
        // eslint-disable-next-line react/display-name
        () => (cell: TypedGridCellProps<CefaFormControlFileTableDataItem>) =>
            (
                <td>
                    <FormControlFileTablePreview cell={cell} />
                </td>
            ),
        [],
    );

    const CommentCell = React.useMemo(
        // eslint-disable-next-line react/display-name
        () => (cell: TypedGridCellProps<CefaFormControlFileTableDataItem>) =>
            (
                <td>
                    <CefaFormControlFileTableCommentCell cell={cell} />
                </td>
            ),
        [],
    );

    const CaptionDateCell = React.useMemo(
        // eslint-disable-next-line react/display-name
        () => (cell: TypedGridCellProps<CefaFormControlFileTableDataItem>) =>
            (
                <td>
                    <CefaFormControlFileTableCaptionDateCell cell={cell} />
                </td>
            ),
        [],
    );

    const ActionsCell = React.useMemo(
        () =>
            // eslint-disable-next-line react/display-name
            (props: TypedGridCellProps<CefaFormControlFileTableDataItem>) =>
                (
                    <td>
                        <FormControlMediaTableActions
                            {...props}
                            readonly={readonly}
                            onRemove={(dataItem) => {
                                arrayHelpers.remove(dataItem.originalIndex);
                            }}
                            onDownload={(dataItem) => {
                                downloadFormFile(getFileUrl(dataItem.id)).catch(
                                    () => {
                                        console.error(
                                            "failed to download file",
                                        );
                                    },
                                );
                            }}
                        />
                    </td>
                ),
        [getFileUrl, arrayHelpers, readonly],
    );

    const emptyCell = () => {
        return <td></td>;
    };

    const handleRowClick = (event: GridRowClickEvent) => {
        if (!config.isReadOnly) {
            setEditId(event.dataItem.id);
        }
    };

    const handleEdit = (event: GridItemChangeEvent) => {
        const newData = data.map((item) =>
            item.uuid === event.dataItem.uuid
                ? { ...item, [event.field]: event.value }
                : item,
        );
        formik.setFieldValue(config.field, newData);
    };

    const handleExpand = React.useCallback(
        (event: GridExpandChangeEvent) => {
            const newData = filesData.map((item: FileData) => {
                if (
                    item.id === event.dataItem.id &&
                    item.fileName === event.dataItem.fileName
                ) {
                    item.expanded = !event.dataItem.expanded;
                }
                return item;
            });
            setFilesData(newData);
        },
        [filesData],
    );

    const rowRender = (
        row: React.ReactElement<HTMLTableRowElement>,
        rowProps: GridRowProps,
    ) => {
        const trProps = {
            ...row.props,
            className: rowProps?.dataItem?.localFile
                ? cx("new-row", row.props.className)
                : null,
        };
        return React.cloneElement(row, { ...trProps }, row.props.children);
    };

    return (
        <>
            <div
                style={{
                    width: "100%",
                    justifyContent: "space-between",
                    display: "flex",
                }}
            >
                <div>
                    <label>
                        <input
                            type="checkbox"
                            checked={showPreviews}
                            onChange={() => {
                                setShowPreviews(!showPreviews);
                            }}
                        />{" "}
                        Show Previews
                    </label>
                    <label style={{ marginLeft: 20 }}>
                        <input
                            type="checkbox"
                            checked={showSection}
                            onChange={() => {
                                setShowSection(!showSection);
                            }}
                        />{" "}
                        Show Section
                    </label>
                </div>
                <div>File Count: {filesData.length}</div>
            </div>
            <Grid
                {...gridProps}
                className={cx(gridProps.className, "dynamic-form-grid", {
                    "is-invalid": !isValid,
                })}
                editField="inEdit"
                onRowClick={handleRowClick}
                onItemChange={handleEdit}
                detail={CefaFormControlFileDetails}
                expandField="expanded"
                onExpandChange={handleExpand}
                rowRender={rowRender}
                style={{ display: showSection ? null : "none" }}
            >
                {config.mediaOnly && (
                    <GridColumn
                        title={showPreviews && showSection ? "Preview" : ""}
                        width="100px"
                        cell={
                            showPreviews && showSection
                                ? PreviewCell
                                : emptyCell
                        }
                        sortable={false}
                    />
                )}
                <GridColumn
                    field="fileName"
                    title="File name"
                    width="230px"
                    columnMenu={ContainsMenuFilter}
                    editable={false}
                />
                <GridColumn
                    field="comment"
                    title="Comment"
                    width="auto"
                    columnMenu={ContainsMenuFilter}
                    cell={CommentCell}
                />
                <GridColumn
                    field="captionDate"
                    title="Caption Date"
                    width="150px"
                    filter={"date"}
                    columnMenu={SimplifiedMenuFilter}
                    cell={CaptionDateCell}
                />
                <GridColumn
                    filterable={false}
                    cell={ActionsCell}
                    width="100px"
                    sortable={false}
                />
            </Grid>
        </>
    );
};
export default CefaFormControlFileTable;
