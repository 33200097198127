import * as React from "react";
import { OrganisationUser } from "../../../organisation/domain/types";
import GridLoadingPanel from "../../../../common/components/table/kendo/GridLoadingPanel";
import useGridProps from "../../../../common/components/table/kendo/useGridProps";
import {
    ContainsMenuFilter,
    emptyFilterDescriptor,
} from "../../../../common/components/table/kendo/columnFilters";
import { Grid } from "@progress/kendo-react-grid";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import UsersTableActions from "./UsersTableActions";
import { useGetOrgByIdQuery } from "../../../../store/features/organisation/organisation-api-slice";
import "./Table.scss";

interface UsersTableProps {
    organisationId: string;
    organisationUsers: OrganisationUser[];
    isFetching: boolean;
    isLoading: boolean;
}
/**
 * Options object for conditional rendering per user
 */
export interface TableUser extends OrganisationUser {
    isOwner: boolean;
}

/**
 *  Table to display an organisation's users and actions
 */
const UsersTable: React.FC<UsersTableProps> = (props) => {
    const orgId = props.organisationId;

    const { data: organisation } = useGetOrgByIdQuery(orgId, {
        skip: !orgId,
    });

    const users = React.useMemo<TableUser[]>(
        () =>
            props.organisationUsers?.map((value) => {
                return {
                    ...value,
                    isOwner: organisation
                        ? value.userId === organisation.ownerId
                        : true,
                };
            }) ?? [],
        [props.organisationUsers, organisation],
    );

    const wrapperRef = React.createRef<HTMLElement>();

    const UserCommandCell = React.useCallback(
        (props) => (
            <td>
                <UsersTableActions
                    user={props.dataItem}
                    organisationId={orgId}
                />
            </td>
        ),
        [orgId],
    );

    const { gridProps } = useGridProps(users, {
        filterable: true,
        initialFilter: emptyFilterDescriptor,
        sortable: true,
        initialSort: [{ field: "username", dir: "asc" }],
        pageable: true,
    });

    if (props.isLoading || props.isFetching) {
        return <GridLoadingPanel gridRef={wrapperRef} />;
    } else {
        return (
            <div className="table-h">
                <Grid {...gridProps} className="table-h">
                    <Column
                        field="username"
                        title="User Name"
                        headerClassName="grid-header"
                        columnMenu={ContainsMenuFilter}
                    />
                    <Column
                        field="userDisplayName"
                        title="Display Name"
                        headerClassName="grid-header"
                        columnMenu={ContainsMenuFilter}
                    />
                    <Column
                        field="userGroups"
                        title="User Groups"
                        headerClassName="grid-header"
                        columnMenu={ContainsMenuFilter}
                    />
                    <Column
                        field="actions"
                        title="Actions"
                        cell={UserCommandCell}
                        width="200px"
                    />
                </Grid>
            </div>
        );
    }
};

export default UsersTable;
