import React from "react";
import { Button } from "react-bootstrap";
import ButtonSpinner from "../../../../../common/components/button/ButtonSpinner";
import useConsumerApiSettings from "../../../../../customization/api/useConsumerApiSettings";
import { downloadFileWithName } from "../../../../../services/FileDownloadService";
import { useOrganisationId } from "../../../../organisation/hooks/useOrganisationId";
import { BatchDetails } from "../../domain/batching";
import { BatchingApiRoutes } from "../../queries/BatchingApiSlice";

type Props = {
    batch: BatchDetails;
    schemaType: string;
};

const BatchDownloadButton: React.FC<Props> = ({ batch, schemaType }) => {
    const [downloadingFile, setDownloadingFile] = React.useState(false);
    const { organisationId } = useOrganisationId();

    const { baseApiUrl } = useConsumerApiSettings();
    const url = `${baseApiUrl}/organisation/${organisationId}${
        BatchingApiRoutes.ApiRoute
    }/${batch.id}/download/${schemaType.toLowerCase()}`;

    const onClick = () => {
        setDownloadingFile(true);
        downloadFileWithName(url)
            .then(() => {
                setDownloadingFile(false);
            })
            .catch(() => {
                setDownloadingFile(false);
                console.error("failed to download file");
            });
    };

    return (
        <>
            <Button
                variant="secondary"
                onClick={onClick}
                disabled={downloadingFile}
            >
                Download {schemaType}
                {batch.estimatedBatchSizeMb &&
                    ` (~${batch.estimatedBatchSizeMb} MB)`}
                {downloadingFile && <ButtonSpinner />}
            </Button>
        </>
    );
};

export default BatchDownloadButton;
