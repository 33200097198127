import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "react-bootstrap";

export interface Props {
    title: string;
    message: string;
    onConfirm();
    onCancel();
}

const ConfirmBox = (props: Props): JSX.Element => {
    const { title, message, onConfirm, onCancel } = props;
    return (
        <Dialog title={title} className="kendo-dialog" onClose={onCancel}>
            {message}
            <DialogActionsBar>
                <Button variant="primary" onClick={onConfirm}>
                    Confirm
                </Button>
                <Button variant="secondary" onClick={onCancel}>
                    Cancel
                </Button>
            </DialogActionsBar>
        </Dialog>
    );
};

ConfirmBox.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default ConfirmBox;
