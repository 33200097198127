import * as React from "react";
import { Dropdown } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import "./ProcessActivityDropdownMenu.scss";

interface Props {
    onDelete: () => void;
    isReadonly: boolean;
}

const ProcessActivityDropdownMenu: React.FC<Props> = ({
    onDelete,
    isReadonly,
}) => {
    return (
        <>
            <Dropdown className="dropdown-main">
                <DropdownToggle className="dropdown-dots"></DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={onDelete} disabled={isReadonly}>
                        Delete
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </>
    );
};

export default ProcessActivityDropdownMenu;
