import * as React from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ProcessActivityStates } from "../ProcessActivityConstants";
import cx from "classnames";
import { ProcessActivityNavigator } from "../domain/types";

interface Props {
    data: ProcessActivityNavigator;
    activityUrl;
}

const ProcessActivityNavigationBar: React.FC<Props> = ({
    data,
    activityUrl,
}) => {
    return (
        <Nav.Item
            key={`activity_nav_item_${data.id}`}
            className={cx("d-flex flex-row justify-content-between", {
                "alert-warning": data.status === ProcessActivityStates.Required,
                "alert-dark":
                    data.status === ProcessActivityStates.NotApplicable,
                "alert-success": data.status === ProcessActivityStates.Complete,
                "alert-secondary":
                    data.status === ProcessActivityStates.Disabled,
            })}
        >
            <Nav.Link
                key={`activity_nav_link_${data.id}`}
                as={Link}
                to={activityUrl}
                eventKey={data.id}
            >
                {data.name}
            </Nav.Link>
        </Nav.Item>
    );
};

export default ProcessActivityNavigationBar;
