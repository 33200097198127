import { Middleware } from "@reduxjs/toolkit";
import get from "lodash/get";
import authService from "../../modules/authorization/services/AuthService";

export const logoutWhenUnauthenticated: Middleware =
    () => (next) => (action) => {
        if (isUnauthenticatedAction(action)) {
            authService.signOut();
        }
        return next(action);
    };

function isUnauthenticatedAction(action: unknown): boolean {
    const status: number = get(action, "payload.data.status", 0);
    return status === 401;
}
