import { Switch } from "react-router";
import AuthorizeRoute from "../../../../router/AuthorizeRoute";
import { AppRoutes } from "../../../../router/AppRoutes";
import * as React from "react";
import ApcOverviewPage from "../pages/ApcOverviewPage";
import IncidentCreatePage from "../pages/IncidentCreatePage";
import IncidentDetailsPage from "../pages/IncidentDetailsPage";
import CefaConstants from "../../constants/CefaConstants";
import useIsClient from "../../../../common/hooks/useIsClient";

const ApcRoutes: React.FC = () => {
    const isCefa = useIsClient(CefaConstants.ClientName);

    if (!isCefa) return null;

    return (
        <Switch>
            <AuthorizeRoute
                path={AppRoutes.ApcCreate.path}
                component={IncidentCreatePage}
            />
            <AuthorizeRoute
                path={AppRoutes.ApcDetails.path}
                component={IncidentDetailsPage}
            />
            <AuthorizeRoute
                path={AppRoutes.Apc.path}
                component={ApcOverviewPage}
            />
        </Switch>
    );
};
export default ApcRoutes;
