import { push } from "connected-react-router";
import * as React from "react";
import { Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import LinkRow from "../../../common/components/link-row";
import "./AssetDefectList.scss";
import { Organisation } from "../../organisation/domain/types";
import { useGetDefectsQuery } from "../queries/defectApiSlice";
import LoadingSpinner from "../../../common/components/CoveringLoadingPanel";

type Props = {
    assetId: string;
    organisation: Organisation;
};

const AssetDefectList: React.FC<Props> = ({ assetId, organisation }) => {
    const { data: defects, isLoading } = useGetDefectsQuery({
        organisationId: organisation.id,
        params: { assetId: [assetId] },
    });

    const dispatch = useDispatch();

    const onNavigate = (id: string) => () => {
        dispatch(push(`/${organisation.shortName}/defects/${id}`));
    };

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div className="mt-3">
            <Table key="defects" className="defect-table">
                <tbody>
                    {defects?.length > 0 ? (
                        defects.map(
                            ({ id, displayName, endDate, startDate }) => (
                                <LinkRow
                                    key={id}
                                    title={displayName}
                                    description={
                                        <div className="defect-description">
                                            <div className="col">
                                                <div>
                                                    {`State: ${
                                                        endDate
                                                            ? "Repaired"
                                                            : "Open"
                                                    }`}
                                                </div>
                                                <div>
                                                    {startDate &&
                                                        `Opened: ${new Date(
                                                            startDate,
                                                        ).toLocaleDateString(
                                                            "en-GB",
                                                        )}`}
                                                </div>
                                                <div>
                                                    {endDate &&
                                                        `Closed: ${new Date(
                                                            endDate,
                                                        ).toLocaleDateString(
                                                            "en-GB",
                                                        )}`}
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="btn btn-primary">
                                                    Show details
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    onNavigate={onNavigate(id)}
                                />
                            ),
                        )
                    ) : (
                        <tr>
                            <th>No Defects</th>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default AssetDefectList;
