import React from "react";
import {
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from "react-query";
import { useInvalidateTags } from "../../../../../store/hooks";
import { ApprovedExam } from "../domain/approvedExam";
import { ReturnExamReasonModel } from "../domain/approvedExamDtos";
import {
    getApprovedExams,
    returnToEngineer,
} from "../services/approvedExamsService";
import approvedExamsQueryKeys from "./approvedExamsQueryKeys";
import { approvedExamsQueryKeysTranslator } from "./approvedExamsQueryKeysTranslator";

const { queryKeysToTags } = approvedExamsQueryKeysTranslator;

export const useGetApprovedExamsQuery = (
    organisationId: string,
): UseQueryResult<ApprovedExam[]> => {
    const queryKey = React.useMemo(
        () => approvedExamsQueryKeys.approvedExams(organisationId),
        [organisationId],
    );

    return useQuery(queryKey, () => getApprovedExams(organisationId));
};

export const useReturnToEngineerMutation = (
    organisationId: string,
    processId: string,
): UseMutationResult<unknown, unknown, ReturnExamReasonModel> => {
    const queryClient = useQueryClient();
    const { invalidateTags } = useInvalidateTags();

    const returnToEngineerFunction = (postModel: ReturnExamReasonModel) =>
        returnToEngineer({ organisationId, processId, postModel });

    const queryKeys = React.useMemo(
        () => approvedExamsQueryKeys.approvedExams(organisationId),
        [organisationId],
    );

    return useMutation(returnToEngineerFunction, {
        onSuccess(_result, _args) {
            invalidateTags(queryKeysToTags(queryKeys, [processId]));
            return queryClient.invalidateQueries(queryKeys);
        },
    });
};
