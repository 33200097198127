import * as Formik from "formik";
import * as React from "react";
import { Button, Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { useNavigation } from "../../../../router/useNavigate";
import { AppRoutes } from "../../../../router/AppRoutes";
import { useParams } from "react-router";
import { useCreateApcIncidentMutation } from "../queries/apcApiSlice";
import { useOrganisationId } from "../../../organisation/hooks/useOrganisationId";
import SelectAssetFormInput from "./SelectAssetFormInput";
import ExamCreateInput from "./ExamCreateInput";
import { ApcIncidentCreateRequestData } from "../domain/types";
import CustomSelect from "../../../../common/components/form/CustomSelect";
import { SelectOptionItem } from "../../../../common/types/reactSelect/SelectOptionItem";
import { getCossIwaOptions } from "../common/options";
import * as yup from "yup";
import { CardBody } from "reactstrap";
import { IncidentFormModel } from "./types";
import { baseSchema } from "./validationSchemas";
import ExaminerInformationControl from "./ExaminerInformationControl";
import NetworkRailControlInfo from "./NetworkRailControlInfo";
import ExaminerControl from "./ExaminerControl";
import CompleteControl from "./CompleteControl";

interface IncidentCreateFormModel extends IncidentFormModel {
    elr: string;
    miles: string;
    yards: string;
    chains: string;
    lineDispensation: SelectOptionItem;
    trainsStop: SelectOptionItem;
    momStatus: SelectOptionItem;
    examinerId?: SelectOptionItem;
    cossIwaForm?: SelectOptionItem;
    reportType: SelectOptionItem;
    createExam?: boolean;
}

const formInitialValues: IncidentCreateFormModel = {
    nrIncidentNumber: "",
    ameyNotifiedAt: null,
    incidentAt: null,
    nrNotifiedAt: null,
    natureOfIncident: "",
    nrControlTelNum: "",
    nrControllerName: "",
    lineDispensation: null,
    trainsStop: null,
    momStatus: null,
    momName: "",
    momEta: null,
    momTelNum: "",
    signalBoxName: "",
    signalBoxTelNum: "",
    assetId: null,
    chains: "",
    elr: "",
    miles: "",
    yards: "",
    examinerId: null,
    examinerMobNum: "",
    examinerNotifiedAt: null,
    examinerEtaAt: null,
    cossIwaForm: null,
    examinerConfirmedToNrAt: null,
    examinerSiteArrivalAt: null,
    timePassesAtMph: null,
    passedAtMph: null,
    passedAtLineSpeedAt: null,
    examinerDepartureSiteAt: null,
    incidentClearedAt: null,
    incidentNotes: "",
    createExam: false,
    reportType: null,
    carrsExamId: "",
};

function mapCreateModel(
    organisationId: string,
    formValues: IncidentCreateFormModel,
): ApcIncidentCreateRequestData {
    return {
        ...formValues,
        organisationId: organisationId,
        examinerId: formValues.examinerId?.value ?? "",
        lineDispensation: formValues.lineDispensation?.value ?? "",
        momStatus: formValues.momStatus?.value ?? "",
        trainsStop: formValues.trainsStop?.value ?? "",
        cossIwaForm: formValues.cossIwaForm?.value ?? "",
        examType: formValues.reportType?.value ?? "",
        examinerEtaAt:
            formValues.examinerEtaAt === "" ? null : formValues.examinerEtaAt,
        examinerNotifiedAt:
            formValues.examinerNotifiedAt === ""
                ? null
                : formValues.examinerNotifiedAt,
        ameyNotifiedAt:
            formValues.ameyNotifiedAt === "" ? null : formValues.ameyNotifiedAt,
        nrNotifiedAt:
            formValues.nrNotifiedAt === "" ? null : formValues.nrNotifiedAt,
        passedAtMph:
            formValues.passedAtMph === "" ? null : formValues.passedAtMph,
        examinerConfirmedToNrAt:
            formValues.examinerConfirmedToNrAt === ""
                ? null
                : formValues.examinerConfirmedToNrAt,
        examinerSiteArrivalAt:
            formValues.examinerSiteArrivalAt === ""
                ? null
                : formValues.examinerSiteArrivalAt,
        examinerDepartureSiteAt:
            formValues.examinerDepartureSiteAt === ""
                ? null
                : formValues.examinerDepartureSiteAt,
        timePassesAtMph:
            formValues.timePassesAtMph === ""
                ? null
                : formValues.timePassesAtMph,
        passedAtLineSpeedAt:
            formValues.passedAtLineSpeedAt === ""
                ? null
                : formValues.passedAtLineSpeedAt,
        incidentClearedAt:
            formValues.incidentClearedAt === ""
                ? null
                : formValues.incidentClearedAt,
        incidentAt: formValues.incidentAt === "" ? null : formValues.incidentAt,
    };
}

const apcValidation = baseSchema.shape({
    assetId: yup
        .string()
        .nullable()
        .when("createExam", {
            is: true,
            then: yup
                .string()
                .nullable()
                .required("Required to create exam report"),
        })
        .when("incidentClearedAt", {
            is: (val) => (val === "" ? false : val),
            then: yup.string().required("Required").nullable(),
        }),
    examinerId: yup
        .mixed()
        .nullable()
        .when("createExam", {
            is: true,
            then: yup
                .mixed()
                .nullable()
                .required("Required to create exam report"),
        })
        .when("incidentClearedAt", {
            is: (val) => (val === "" ? false : val),
            then: yup.mixed().required("Required").nullable(),
        }),
    reportType: yup.mixed().when("createExam", {
        is: true,
        then: yup.mixed().required("Required to create exam report"),
    }),
});

const IncidentCreateForm: React.FC = () => {
    const { navigateToOrgPath: navigateToPath } = useNavigation();
    const { orgShortName } = useParams<{ orgShortName: string }>();
    const { organisationId } = useOrganisationId();
    const [createMutation] = useCreateApcIncidentMutation();

    const navigateToDetails = React.useCallback(
        (result: string) => {
            navigateToPath(
                AppRoutes.ApcDetails.toLink({
                    orgShortName: orgShortName,
                    apcId: result,
                }),
            );
        },
        [navigateToPath, orgShortName],
    );

    const navigateToGrid = React.useCallback(() => {
        navigateToPath(
            AppRoutes.Apc.toLink({
                orgShortName: orgShortName,
            }),
        );
    }, [navigateToPath, orgShortName]);

    const onSubmit = React.useCallback(
        (
            formValues: IncidentCreateFormModel,
            { setSubmitting }: Formik.FormikHelpers<IncidentCreateFormModel>,
        ): void => {
            createMutation(mapCreateModel(organisationId, formValues))
                .unwrap()
                .then((result) => navigateToDetails(result))
                .catch(() => setSubmitting(false));
        },
        [createMutation, navigateToDetails, organisationId],
    );

    return (
        <Formik.Formik
            validationSchema={apcValidation}
            enableReinitialize
            initialValues={formInitialValues}
            onSubmit={onSubmit}
            validateOnMount={true}
        >
            {({ isSubmitting }) => (
                <Formik.Form noValidate>
                    <Card className="m-3">
                        <CardBody>
                            <NetworkRailControlInfo />
                            <h2>Asset Information</h2>
                            <SelectAssetFormInput
                                organisationId={organisationId}
                            />
                            <ExaminerControl />
                            <ExamCreateInput />
                            <Row>
                                <Col xs={"auto"} md={4}>
                                    <CustomSelect
                                        name="cossIwaForm"
                                        label="COSS/IWA Form"
                                        description={
                                            "Have you asked Examiner if they have filled in COSS/IWA Form for Use in 'Exceptional Circumstances'"
                                        }
                                        isClearable={false}
                                        options={getCossIwaOptions()}
                                    />
                                </Col>
                            </Row>
                            <ExaminerInformationControl />
                            <CompleteControl />
                            <Form.Group>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting && (
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    )}
                                    Create Incident
                                </Button>
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        navigateToGrid();
                                    }}
                                    disabled={isSubmitting}
                                >
                                    Cancel
                                </Button>
                            </Form.Group>
                        </CardBody>
                    </Card>
                </Formik.Form>
            )}
        </Formik.Formik>
    );
};
export default IncidentCreateForm;
