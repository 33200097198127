import { DynamicFormValues } from "./../types/dynamicFormTypes";
import { FormikContextType } from "formik";
import { FormControlSelectConfig, Option, State } from "./FormControlSelect";

export class StateMulti implements State {
    options: Option[];
    config: FormControlSelectConfig;
    formik: FormikContextType<DynamicFormValues>;

    constructor(
        formik: FormikContextType<DynamicFormValues>,
        options: Option[],
        config: FormControlSelectConfig,
    ) {
        this.formik = formik;
        this.options = options;
        this.config = config;
    }

    convertFormikValueToOption = (): Option[] => {
        if (!this.formik.values[this.config.field]) return;
        const storedOptions = (
            this.formik.values[this.config.field] as string
        ).split(",");
        return this.options.filter((a) => storedOptions.includes(a?.value));
    };

    onChangeHandler = (newValue: Option[]): void => {
        if (newValue !== null) {
            this.formik.setFieldValue(
                this.config.field,
                newValue.map((a) => a.value).toString(),
            );
        } else {
            this.formik.setFieldValue(this.config.field, "");
        }
    };

    // we don't need this functionality for the multi-selector at the moment
    disabledFieldValidation(): boolean {
        return false;
    }
}
