import { wrapTagsWithQueryInvalidation } from "../../../query/configureQuery";
import { dcpApi, Tags } from "../../../store/features/dcpApi";
import {
    ActivityActionParams,
    PostNotApplicableParams,
    ProcessActivityPatchModel,
} from "../../../store/features/process-activity/types";
import { examinationQueryKeysTranslator } from "../../cefa/examinations/query/examinationQueryKeysTranslator";
import { ProcessActivity } from "../../process-activity/domain/types";
import { createActivityTags } from "../../process-activity/store/activityTag";
import { ProcessActivityList } from "../domain/types";

enum UrlPaths {
    ResourceName = "/processactivitylist",
    Complete = "/complete",
    NotApplicable = "/not-applicable",
    Open = "/open",
    Create = "/create",
    Items = "/items",
}

const activityListTags = (
    activity: ProcessActivity,
    otherTags?: { type: Tags; id?: string }[],
) => createActivityTags(activity, otherTags);

const { tagsToQueryKeys: tagsToExaminationQueryKeys } =
    examinationQueryKeysTranslator;

export const processActivityListApi = dcpApi.injectEndpoints({
    endpoints: (builder) => ({
        getProcessActivityListById: builder.query<
            ProcessActivityList,
            { activityId: string; processId: string }
        >({
            query: ({ activityId }) => `${UrlPaths.ResourceName}/${activityId}`,
            providesTags: (result, error, arg) => [
                { type: Tags.ProcessActivity, id: arg.activityId },
                { type: Tags.ProcessActivitiesForProcess, id: arg.processId },
            ],
        }),
        postProcessActivityListComplete: builder.mutation<
            ProcessActivityList,
            ActivityActionParams
        >({
            query: ({ activity }) => ({
                url:
                    `${UrlPaths.ResourceName}/${activity.id}` +
                    UrlPaths.Complete,
                method: "POST",
            }),
            invalidatesTags: (result, error, { activity }) =>
                error
                    ? []
                    : activityListTags(activity, [
                          {
                              type: Tags.ProcessAssignedList,
                          },
                      ]),
        }),
        postProcessActivityListNotApplicable: builder.mutation<
            void,
            PostNotApplicableParams
        >({
            query: ({ activity, comment, reason }) => ({
                url: `${UrlPaths.ResourceName}/${activity.id}${UrlPaths.NotApplicable}`,
                method: "POST",
                body: { comment, reason },
            }),
            invalidatesTags: (result, error, { activity }) =>
                error ? [] : activityListTags(activity),
        }),
        postProcessActivityListOpen: builder.mutation<
            ProcessActivityList,
            ActivityActionParams
        >({
            query: ({ activity }) => ({
                url: `${UrlPaths.ResourceName}/${activity.id}` + UrlPaths.Open,
                method: "POST",
            }),
            invalidatesTags: (result, error, { activity }) =>
                error ? [] : activityListTags(activity),
        }),
        postProcessActivityListCreateListItem: builder.mutation<
            { activityId: string },
            string
        >({
            query: (activityId) => ({
                url: `${UrlPaths.ResourceName}/${activityId}` + UrlPaths.Create,
                method: "POST",
                body: {
                    displayName: "",
                },
            }),
            invalidatesTags: (result, error, id) => [
                { type: Tags.ProcessActivity, id: id },
            ],
        }),
        patchProcessActivityList: builder.mutation<
            ProcessActivityList,
            ProcessActivityPatchModel
        >({
            query: (patchModel) => ({
                url: `${UrlPaths.ResourceName}/${patchModel.activityId}`,
                method: "PATCH",
                body: patchModel,
            }),
            invalidatesTags: (_result, _error, params) =>
                wrapTagsWithQueryInvalidation(
                    [
                        { type: Tags.ProcessActivity, id: params.activityId },
                        { type: Tags.Process, id: params.processId },
                        { type: Tags.ProcessAssignedList },
                    ],
                    tagsToExaminationQueryKeys,
                ),
        }),
        deleteProcessActivityListItem: builder.mutation<
            ProcessActivityList,
            {
                activityId: string;
                listItemActivityId: string;
            }
        >({
            query: ({ activityId, listItemActivityId }) => ({
                url: `${UrlPaths.ResourceName}/${activityId}${UrlPaths.Items}/${listItemActivityId}`,
                method: "DELETE",
                body: {
                    displayName: "",
                },
            }),
            invalidatesTags: (result, error, params) => [
                { type: Tags.ProcessActivity, id: params.activityId },
            ],
        }),
    }),
});

export const {
    usePostProcessActivityListCompleteMutation,
    usePostProcessActivityListNotApplicableMutation,
    usePostProcessActivityListOpenMutation,
    useGetProcessActivityListByIdQuery,
    usePostProcessActivityListCreateListItemMutation,
    useDeleteProcessActivityListItemMutation,
    usePatchProcessActivityListMutation,
} = processActivityListApi;
