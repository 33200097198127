import produce from "immer";
import { Action, Reducer } from "redux";
import { KnownAction } from "./actionCreators";
import * as Actions from "./actions";
import { ReportState } from "./store";

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const unloadedState: ReportState = {
    ui: {
        orgFormList: {
            isLoading: false,
            formsLoaded: false,
        },
        reportTemplate: {
            isLoading: false,
            isEdit: false,
        },
        orgReportTemplateList: {
            isLoading: false,
        },
    },
    domain: {
        orgFormsList: [],
        reportTemplate: {
            id: "",
            name: "",
            description: "",
            configurationId: "",
            formTemplateId: "",
            formIds: [],
            completedAt: "",
            completedBy: "",
            organisationId: "",
            auditActions: [],
            reportFiles: [],
        },
        reportTemplateListItems: [],
    },
    application: {},
};

const handlers = {} as Record<
    string,
    (state: ReportState, action: unknown) => ReportState
>;

handlers[Actions.REQUEST_ORG_TEMPLATE_LIST_ITEMS] = (
    state: ReportState,
): ReportState => {
    return produce(state, (newState) => {
        newState.ui.orgReportTemplateList.isLoading = true;
        newState.domain.reportTemplateListItems = [];
    });
};

handlers[Actions.RECEIVE_ORG_TEMPLATE_LIST_ITEMS] = (
    state: ReportState,
    action: Actions.ReceiveReportTemplateListItemsAction,
): ReportState => {
    return produce(state, (newState) => {
        newState.ui.orgReportTemplateList.isLoading = false;
        newState.domain.reportTemplateListItems = action.data;
    });
};

handlers[Actions.REQUEST_REPORT_LIST_ITEMS] = (
    state: ReportState,
): ReportState => {
    return produce(state, (newState) => {
        newState.ui.orgFormList.isLoading = true;
        newState.domain.orgFormsList = [];
    });
};

handlers[Actions.RECEIVE_REPORT_LIST_ITEMS] = (
    state: ReportState,
    action: Actions.ReceiveReportListItemsAction,
): ReportState => {
    return produce(state, (newState) => {
        newState.ui.orgFormList.isLoading = false;
        newState.domain.orgFormsList = action.data;
    });
};

handlers[Actions.REQUEST_REPORT_TEMPLATE] = (
    state: ReportState,
): ReportState => {
    return produce(state, (newState) => {
        newState.ui.reportTemplate.isLoading = true;
        newState.ui.orgFormList.isLoading = true;
    });
};

handlers[Actions.RECEIVE_REPORT_TEMPLATE] = (
    state: ReportState,
    action: Actions.ReceiveReportTemplateAction,
): ReportState => {
    return produce(state, (newState) => {
        newState.domain.reportTemplate = action.data;
        newState.ui.reportTemplate.isLoading = false;
        newState.ui.orgFormList.isLoading = false;
        newState.ui.reportTemplate.isEdit = false;
    });
};

handlers[Actions.DELETE_ORG_REPORT_TEMPLATE_SUCCESS] = (
    state: ReportState,
    action: Actions.DeleteOrgReportTemplateSuccessAction,
): ReportState => {
    return produce(state, (newState) => {
        newState.domain.reportTemplateListItems =
            state.domain.reportTemplateListItems.filter(
                (a) => a.id !== action.data,
            );
    });
};

handlers[Actions.DELETE_ORG_REPORT_SUCCESS] = (
    state: ReportState,
    action: Actions.DeleteOrgReportSuccessAction,
): ReportState => {
    const outState = {
        ...state,
    };
    outState.domain.reportTemplate.reportFiles =
        state.domain.reportTemplate.reportFiles.filter(
            (a) => a.id !== action.data,
        );
    return outState;
};

handlers[Actions.REPORT_EDIT_START] = (state: ReportState): ReportState => {
    return produce(state, (newState) => {
        newState.ui.reportTemplate.isEdit = true;
    });
};

export const reducer: Reducer<ReportState> = (
    state: ReportState | undefined,
    incomingAction: Action,
): ReportState => {
    if (!state) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    const handler = handlers[action.type];
    if (handler) {
        return handler(state, action);
    }
    return state;
};
