import * as React from "react";
import AuditActionListItem from "../../../common/components/audit/AuditActionListItem";
import { AuditAction } from "../../../common/types/AuditAction";
import { Template } from "../domain/types";

/**
 * This class populates a list of audit actions performed against a loaded form template
 */
interface TemplateAuditActionListProps {
    template: Template;
}

const TemplateAuditActionList: React.FC<TemplateAuditActionListProps> = ({
    template,
}) => {
    const auditActions = template.auditActions;

    return (
        <div>
            <ul className="timeline">
                {auditActions.map((item: AuditAction, index: number) => (
                    <AuditActionListItem key={index.toString()} data={item} />
                ))}
            </ul>
        </div>
    );
};

export default TemplateAuditActionList;
