import { useAppDispatch } from "../../../store/hooks";
import { NotificationType, Notification } from "../domain/types";
import { Notification as NotificationKendo } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import React, { useEffect } from "react";
import { remove } from "../../../store/features/notifications/notificationsSlice";

interface Props {
    item: Notification;
}

const NotificationToast: React.FC<Props> = ({ item }) => {
    const dispatch = useAppDispatch();

    const getStyleByEnum = (type: NotificationType) => {
        switch (type) {
            case NotificationType.Error:
                return "error";
            case NotificationType.Warning:
                return "warning";
            default:
                return "info";
        }
    };

    const removeNotification = () => dispatch(remove(item.content));

    useEffect(() => {
        const timeout = setTimeout(removeNotification, 10000);

        return () => clearTimeout(timeout);
    });

    return (
        <Fade>
            {item.type && (
                <NotificationKendo
                    type={{
                        style: getStyleByEnum(item.type),
                        icon: true,
                    }}
                    closable={true}
                    onClose={() => removeNotification()}
                >
                    <span>{item.content}</span>
                </NotificationKendo>
            )}
        </Fade>
    );
};

export default NotificationToast;
