export default class SiteRiskConstants {
    static readonly IdField = "id";
    static readonly SiteRiskField = "identifiedRisk";
    static readonly SiteRiskLabel = "Identified Risk";
    static readonly SiteRiskWidth = 0.115;
    static readonly SiteHazardField = "hazard";
    static readonly SiteHazardLabel = "Hazard";
    static readonly SiteHazardWidth = 0.2;
    static readonly SiteSeverityField = "riskSeverity";
    static readonly SiteSeverityLabel = "Severity (1-5)";
    static readonly SiteSeverityWidth = 0.05;
    static readonly SiteLikelihoodField = "riskLikelihood";
    static readonly SiteLikelihoodLabel = "Likelihood (1-5)";
    static readonly SiteLikelihoodWidth = 0.05;
    static readonly SiteRiskLevelField = "riskOverall";
    static readonly SiteRiskLevelLabel = "Risk (SxL)";
    static readonly SiteRiskLevelWidth = 0.05;
    static readonly SiteControlField = "control";
    static readonly SiteControlLabel = "Control";
    static readonly SiteControlWidth = 0.2;
    static readonly SiteResidualSeverityField = "resRiskSeverity";
    static readonly SiteResidualSeverityLabel = "Severity (1-5)";
    static readonly SiteResidualSeverityWidth = 0.05;
    static readonly SiteResidualLikelihoodField = "resRiskLikelihood";
    static readonly SiteResidualLikelihoodLabel = "Likelihood (1-5)";
    static readonly SiteResidualLikelihoodWidth = 0.05;
    static readonly SiteResidualRiskLevelField = "resRiskOverall";
    static readonly SiteResidualRiskLevelLabel = "Risk (SxL)";
    static readonly SiteResidualRiskLevelWidth = 0.05;
    static readonly ActionWidth = 0.05;
}
