import React from "react";
import { AssetTemplateModel } from "../../../store/features/asset/asset-api-slice";
export type GridColumnDef = { field: string; title: string };

const dynamicAssetPropPrefix = "dynAssetProp";

const columnsReducer = (
    originalArray: GridColumnDef[],
    currentColumn: GridColumnDef,
) => {
    if (!originalArray.find((c) => c.title === currentColumn.title)) {
        originalArray.push(currentColumn);
    }

    return originalArray;
};

function useCombinedAssetsTemplates(
    selectedTemplates: AssetTemplateModel[],
    templates: AssetTemplateModel[],
    defaultColumns: GridColumnDef[],
): GridColumnDef[] {
    return React.useMemo(() => {
        const columnsFromAllAssets =
            selectedTemplates.length > 0
                ? selectedTemplates
                      .map((template) => [
                          ...(template?.templateProperties ?? [])
                              .map(({ id, name }) => ({
                                  field: dynamicAssetPropPrefix + id,
                                  title: name,
                              }))
                              .flat(),
                      ])
                      .flat()
                : [];
        return [...defaultColumns, ...columnsFromAllAssets].reduce(
            columnsReducer,
            [],
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(templates), JSON.stringify(selectedTemplates)]);
}

export default useCombinedAssetsTemplates;
