import { Card } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import React from "react";
import { CardBody, CardHeader } from "reactstrap";
import { Organisation } from "../../../organisation/domain/types";
import useOrganisationFile from "../../../organisation/hooks/useOrganisationFile";

interface Props {
    organisation: Organisation;
}
const OrganisationBlurb: React.FC<Props> = (props) => {
    const { organisation } = props;
    const [markdownText, setMarkdownText] = React.useState<string>(null);

    const fileName = organisation.features.organisationFiles?.files?.summary;

    useOrganisationFile(
        organisation.shortName,
        fileName,
        "markdown",
        setMarkdownText,
    );

    return (
        <Card className="card d-flex flex-column">
            <CardHeader>
                <p className="h4">Overview</p>
            </CardHeader>
            <CardBody>
                {markdownText && (
                    <div className="card-text">
                        <div>
                            <ReactMarkdown>{markdownText}</ReactMarkdown>
                        </div>
                    </div>
                )}
            </CardBody>
        </Card>
    );
};

export default OrganisationBlurb;
