import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import * as Formik from "formik";
import * as React from "react";
import { Button, Spinner } from "react-bootstrap";
import { Checkbox, TextInput } from "../../../../../common/components/form";
import NumberInput from "../../../../../common/components/form/NumberInput";
import { useReturnToEngineerMutation as useReturnProcessToEngineerMutation } from "../queries/approvedExamsQueries";

interface Props {
    organisationId: string;
    processId: string;
    onClose: () => void;
}

interface ReturnProcessFormValues {
    comment: string;
    rejectedByNR: boolean;
    addNewCarrsExamId: boolean;
    newCarrsExamId?: number;
}

const ReturnReportDialog: React.FC<Props> = ({
    organisationId,
    processId,
    onClose,
}) => {
    const returnProcess = useReturnProcessToEngineerMutation(
        organisationId,
        processId,
    );

    const initialFormValues: ReturnProcessFormValues = {
        comment: "",
        rejectedByNR: false,
        addNewCarrsExamId: false,
    };

    const handleSubmit = ({
        comment,
        rejectedByNR,
        newCarrsExamId,
    }: ReturnProcessFormValues): void => {
        returnProcess.mutate(
            {
                comment,
                rejectedByNR,
                newCarrsExamId,
            },
            { onSuccess: () => onClose(), onError: () => onClose() },
        );
    };

    return (
        <Dialog title="Please confirm" onClose={() => onClose()} width={600}>
            <div className="my-2">
                <p>
                    Are you sure you want to return this process to an engineer?
                </p>
            </div>
            <Formik.Formik
                initialValues={initialFormValues}
                onSubmit={handleSubmit}
            >
                {(form) => (
                    <Formik.Form>
                        <TextInput
                            label="Comment"
                            placeholder="Write a comment..."
                            name="comment"
                        />
                        <Checkbox label="Rejected by NR" name="rejectedByNR" />
                        <Checkbox
                            label="Add a new CARRS Exam Id"
                            name="addNewCarrsExamId"
                            readOnly={!form.values.rejectedByNR}
                        />
                        <NumberInput
                            label="New CARRS Exam Id"
                            placeholder="You can optionally provide a new CARRS Exam Id..."
                            name="newCarrsExamId"
                            readOnly={!form.values.addNewCarrsExamId}
                        />
                        <DialogActionsBar layout="end">
                            <div className="pt-3">
                                <Button
                                    variant="link"
                                    onClick={() => onClose()}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={returnProcess.isLoading}
                                >
                                    Confirm{" "}
                                    {returnProcess.isLoading && (
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    )}
                                </Button>
                            </div>
                        </DialogActionsBar>
                    </Formik.Form>
                )}
            </Formik.Formik>
        </Dialog>
    );
};

export default ReturnReportDialog;
