import * as React from "react";

import {
    DropDownList,
    DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";

interface Option {
    value: string;
    text: string;
}
export const daysSinceExamInRange = (current, { min, max }) =>
    (min === null || current >= min) && (max === null || current <= max);

const DaysSinceExamFilterCell: React.FC<GridFilterCellProps> = (props) => {
    const hasValue = (value: string): boolean => Boolean(value && value !== "");

    const defaultItem: Option = { text: "", value: "" };

    let result: Option;

    if (props?.value?.min === 29) {
        result = { text: "28+", value: "29,10000" };
    } else if (props?.value?.min != null) {
        const strngwith = props?.value?.min + "-" + props?.value?.max;
        const strngcoma = props?.value?.min + "," + props?.value?.max;
        result = { text: strngwith, value: strngcoma };
    } else {
        result = defaultItem;
    }

    const options: Option[] = [
        { text: "0-7", value: "0,7" },
        { text: "8-14", value: "8,14" },
        { text: "15-21", value: "15,21" },
        { text: "22-28", value: "22,28" },
        { text: "28+", value: "29,10000" },
    ];

    const onChange = (event: DropDownListChangeEvent) => {
        const eventHasValue = hasValue(event.target.value);

        if (eventHasValue) {
            const split = event.target.value.value.split(",");
            props.onChange({
                value: {
                    min: eventHasValue ? parseInt(split[0]) : "",
                    max: eventHasValue ? parseInt(split[1]) : "",
                },
                operator: daysSinceExamInRange,
                syntheticEvent: event.syntheticEvent,
            });
        }
    };

    const onClearButtonClick = (event) => {
        event.preventDefault();
        props.onChange({
            value: "",
            operator: "",
            syntheticEvent: event,
        });
    };
    return (
        <div className="k-filtercell">
            <div className="k-filtercell-wrapper">
                <DropDownList
                    data={options}
                    onChange={onChange}
                    value={result}
                    defaultItem={defaultItem}
                    textField="text"
                />
                <div className="k-filtercell-operator">
                    <Button
                        title="Clear"
                        disabled={!hasValue(props.value)}
                        onClick={onClearButtonClick}
                        icon="filter-clear"
                    />
                </div>
            </div>
        </div>
    );
};
export default DaysSinceExamFilterCell;
