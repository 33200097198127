import { Button } from "@progress/kendo-react-buttons";
import {
    DropDownList,
    DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import React from "react";

interface Props extends GridFilterCellProps {
    value: string[];
}

const Options: string[] = ["Yes", "No"];

export const YesNoFilterCell: React.FC<Props> = ({ onChange, value }) => {
    const onValueChange = React.useCallback(
        ({ value }: DropDownListChangeEvent) => {
            onChange({
                value: value,
                operator: "eq",
                syntheticEvent: null,
            });
        },
        [],
    );

    const onClear: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        onChange({
            value: null,
            operator: "",
            syntheticEvent: event,
        });
    };

    return (
        <div className="k-filtercell">
            <div className="k-filtercell-wrapper">
                <DropDownList
                    data={Options}
                    value={value}
                    onChange={onValueChange}
                />
                <Button
                    title="Clear"
                    disabled={!value}
                    onClick={onClear}
                    icon="filter-clear"
                />
            </div>
        </div>
    );
};
