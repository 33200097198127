import * as React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import Breadcrumbs from "../../../../common/components/breadcrumbs/Breadcrumbs";
import CoveringLoadingPanel from "../../../../common/components/CoveringLoadingPanel";
import { useOrgTitleWithShortName } from "../../../../common/hooks/useTitle";
import PermissionConstants from "../../../../common/permissions/PermissionConstants";
import { hasUserOrgPermission } from "../../../../store/features/user/user-api-slice";
import { useOrganisationId } from "../../../organisation/hooks/useOrganisationId";
import ExaminationBulkUpdaterActions from "../components/ExaminationBulkUpdaterActions/ExaminationBulkUpdaterActions";
import ExaminationMassRemovalActions from "../components/ExaminationMassRemovalActions/ExaminationMassRemovalActions";
import ExaminationSchedulesGrid from "../components/ExaminationSchedulesGrid/ExaminationSchedulesGrid";
import pageTitles from "./pageTitles";

const ExaminationSchedulesPage: React.FC<
    RouteComponentProps<{ orgShortName: string }>
> = () => {
    const { organisationId, isLoadingOrgId } = useOrganisationId();
    const canViewExaminationsBatches = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgExaminationsBulkUpdate.read,
    ).hasPermission;

    const canViewExaminationsMassRemoval = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgExaminationsMassRemoval.read,
    ).hasPermission;

    useOrgTitleWithShortName(pageTitles.scheduleExaminations);

    if (isLoadingOrgId) return <CoveringLoadingPanel />;

    return (
        <>
            <div className="pt-2">
                <Breadcrumbs
                    items={[[pageTitles.scheduleExaminations]]}
                ></Breadcrumbs>
            </div>
            <div>
                <h1>{pageTitles.scheduleExaminations}</h1>
            </div>
            <Tabs
                defaultActiveKey="examinations"
                id="examinations-tabs"
                className="pt-2"
                fill
            >
                <Tab eventKey="examinations" title="List">
                    <div className="pt-2">
                        <ExaminationSchedulesGrid
                            organisationId={organisationId}
                        />
                    </div>
                </Tab>
                {canViewExaminationsBatches && (
                    <Tab eventKey="bulk-updater" title="Bulk Update">
                        <div className="pt-2">
                            <ExaminationBulkUpdaterActions
                                organisationId={organisationId}
                            ></ExaminationBulkUpdaterActions>
                        </div>
                    </Tab>
                )}
                {canViewExaminationsMassRemoval && (
                    <Tab eventKey="mass-removal" title="Bulk Cancel">
                        <div className="pt-2">
                            <ExaminationMassRemovalActions
                                organisationId={organisationId}
                            ></ExaminationMassRemovalActions>
                        </div>
                    </Tab>
                )}
            </Tabs>
        </>
    );
};

export default ExaminationSchedulesPage;
