import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import cx from "classnames";
import { BaseExamination } from "../../domain/examinationsReview";
import ConfirmBox from "../../../../../common/components/kendo-dialog-boxes/ConfirmBox";
import { TypedGridCellProps } from "../../../../../common/types/TypedGridCellProps";
import ExamContext, {
    ExamGridType,
} from "../../query/examinationsContextProvider";

interface DefaultGridActionsProps<TData extends BaseExamination> {
    props: TypedGridCellProps<TData>;
    activityType: ExamGridType;
    onDelete: (activityItemId: string) => void;
}

const ExaminationGridActions = <TData extends BaseExamination>({
    props,
    activityType,
    onDelete,
}: DefaultGridActionsProps<TData>): JSX.Element => {
    const { setActivityToEdit } = React.useContext(ExamContext);

    const [showConfirmation, setShowConfirmation] = React.useState(false);

    const rowItem = props.dataItem;

    const className =
        rowItem.status === "Closed"
            ? cx(props.className, "closed")
            : props.className;

    return (
        <td className={className} style={props.style}>
            {showConfirmation && (
                <ConfirmBox
                    title="Delete"
                    message="Are you sure you want to delete this item?"
                    onCancel={() => setShowConfirmation(false)}
                    onConfirm={() => onDelete(rowItem.activityId)}
                />
            )}

            <div className="pt-2">
                <ButtonGroup>
                    <Button
                        className="mr-1"
                        variant="primary"
                        disabled={rowItem.readonly}
                        onClick={() => {
                            setActivityToEdit({
                                activityId: rowItem.activityId,
                                activityType,
                            });
                        }}
                    >
                        Edit
                    </Button>

                    <Button
                        className="mr-1"
                        variant="danger"
                        disabled={
                            rowItem.readonly ||
                            !rowItem.canDelete ||
                            rowItem.status === "Closed"
                        }
                        onClick={() => setShowConfirmation(true)}
                    >
                        Delete
                    </Button>
                </ButtonGroup>
            </div>
        </td>
    );
};

export default ExaminationGridActions;
