import * as React from "react";
import { Row, Col, Breadcrumb } from "react-bootstrap";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import ProcessCreateForm from "../containers/ProcessCreateForm";
import { ProcessPageTitles } from "./pageTitles";
import { useGetOrgByShortNameQuery } from "../../../store/features/organisation/organisation-api-slice";

const ProcessCreatePage: React.FC = () => {
    const params = useParams<{ orgShortName: string }>();

    const queryParams = new URLSearchParams(window.location.search);

    const scheduledStartDate =
        queryParams.get(OrganisationConstants.queryParamScheduledStart) ?? "";

    const { data: organisation } = useGetOrgByShortNameQuery(
        params.orgShortName,
        {
            skip: !params.orgShortName,
        },
    );
    useOrgTitleWithShortName(ProcessPageTitles.Create);

    return (
        <>
            <Row className="top10">
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={"div"}>
                            <Link
                                to={`/${params.orgShortName}${OrganisationConstants.Process}`}
                            >
                                {organisation?.features?.process
                                    ?.sideMenuName ?? "Processes"}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Create</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>
                        Create{" "}
                        {organisation?.features?.process?.sideMenuName ??
                            "Processes"}
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ProcessCreateForm
                        scheduledStart={scheduledStartDate}
                        organisation={organisation}
                    ></ProcessCreateForm>
                </Col>
            </Row>
        </>
    );
};

export default ProcessCreatePage;
