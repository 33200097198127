import { TemplateContent } from "../../../../modules/template/domain/types";
import { FormControlTextAreaConfig } from "./TextAreaComponent";

interface TextAreaConfigSupportedOptions {
    Rows: string;
}

export interface TextAreaConfigOverrides {
    isReadOnly: boolean;
}
/**
 * Takes in dynamic config object and returns types component config
 */
class TextAreaConfigBuilder {
    static SupportedOptions: TextAreaConfigSupportedOptions = {
        Rows: "rows",
    };

    static BuildConfig(
        dynamicConfig: TemplateContent,
        overrides: TextAreaConfigOverrides,
    ): FormControlTextAreaConfig {
        // Need to get the required config options out of the options list
        const rows = parseInt(
            dynamicConfig.configOptions?.find(
                (a) => a.option === TextAreaConfigBuilder.SupportedOptions.Rows,
            )?.value ?? "1",
        );

        return {
            field: dynamicConfig.field,
            label: dynamicConfig.label,
            isReadOnly: dynamicConfig.readOnly || overrides.isReadOnly,
            styles: dynamicConfig.style,
            prompt: dynamicConfig.prompt,
            uri: dynamicConfig.uri,
            rows: rows,
            showConditions: dynamicConfig.showConditions,
        };
    }
}
export default TextAreaConfigBuilder;
