import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import React, { useEffect, useRef, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import ExamPhotoOrderListGrid from "./ExamPhotoOrderListGrid/ExamPhotoOrderListGrid";
import { PhotoOrder } from "../types";
import {
    useDeletePdfPhotoOrderByProcessIdMutation,
    useGetPdfPhotoOrderByProcessId,
    usePostPdfPhotoOrderByProcessIdMutation,
} from "../query/queries";
import moment from "moment";

interface ExamPhotosOrderModalProps {
    processId: string;
    onClose: () => void;
}

const ExamPhotosOrderModal: React.FC<ExamPhotosOrderModalProps> = ({
    processId,
    onClose,
}) => {
    const photoOrderQuery = useGetPdfPhotoOrderByProcessId(processId);
    const photoOrderPostMutation =
        usePostPdfPhotoOrderByProcessIdMutation(processId);

    const photoOrderDeleteMutation =
        useDeletePdfPhotoOrderByProcessIdMutation(processId);

    React.useEffect(() => {
        setGridData(photoOrderQuery.data?.photoSettings ?? []);
    }, [photoOrderQuery.data]);

    const [gridData, setGridData] = React.useState<PhotoOrder[]>([]);

    const submit = React.useCallback(() => {
        photoOrderPostMutation.mutate({
            processId: processId,
            photoOrder: gridData,
        });
    }, [photoOrderPostMutation, processId, gridData]);

    const reset = React.useCallback(() => {
        photoOrderDeleteMutation.mutate({
            processId: processId,
        });
    }, [photoOrderDeleteMutation, processId]);

    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef(null);

    const [alertHeight, setAlertHeight] = useState(0);
    const alertRef = useRef(null);

    const showAlert =
        photoOrderQuery.data &&
        moment(photoOrderQuery.data.systemUpdatedAt).isAfter(
            photoOrderQuery.data.userUpdatedAt,
            "seconds",
        );

    useEffect(() => {
        if (contentRef.current)
            setContentHeight(contentRef.current.clientHeight);
    }, []);

    const alertDivHeight = alertRef.current?.clientHeight ?? 0;

    useEffect(() => {
        setAlertHeight(alertDivHeight);
    }, [alertDivHeight]);

    const [saveButtonProps, setSaveButtonProps] = useState({
        variant: "primary",
        text: "Save",
    });
    useEffect(() => {
        if (photoOrderPostMutation.isSuccess) {
            setSaveButtonProps({
                text: "Saved",
                variant: "success",
            });
            setTimeout(() => {
                setSaveButtonProps({
                    text: "Save",
                    variant: "primary",
                });
            }, 2000);
        } else {
            setSaveButtonProps({
                text: "Save",
                variant: "primary",
            });
        }
    }, [photoOrderPostMutation.isSuccess]);

    return (
        <Dialog
            title={"Order PDF Report Photos"}
            onClose={onClose}
            width="calc(100vw - 40px)"
            height="calc(100vh - 40px)"
        >
            <div ref={contentRef} style={{ height: "100%" }}>
                {showAlert && (
                    <Alert ref={alertRef} variant="warning">
                        Exam images have been updated since order was last
                        saved. This may have caused the image order to change.
                        Please review.
                    </Alert>
                )}
                <ExamPhotoOrderListGrid
                    gridData={gridData}
                    setGridData={setGridData}
                    height={contentHeight - alertHeight - 8}
                />
            </div>
            <DialogActionsBar>
                <Button
                    type="button"
                    variant="outline-danger"
                    onClick={reset}
                    disabled={photoOrderDeleteMutation.isLoading}
                >
                    {photoOrderDeleteMutation.isLoading
                        ? "Resetting order..."
                        : "Reset Order"}
                </Button>
                <Button
                    type="submit"
                    variant={saveButtonProps.variant}
                    onClick={submit}
                    disabled={photoOrderPostMutation.isLoading}
                >
                    {photoOrderPostMutation.isLoading
                        ? "Saving..."
                        : saveButtonProps.text}
                </Button>
            </DialogActionsBar>
        </Dialog>
    );
};

export default ExamPhotosOrderModal;
