import * as React from "react";
import { MutationResult } from "../types/rtkQuery/MutationResult";
import CoveringLoadingPanel from "./CoveringLoadingPanel";
import { ErrorInfo } from "./ErrorInfo";

interface Props {
    mutationResult: MutationResult;
    showLoading?: boolean;
}

interface MultiMutationResultStatusProps {
    showLoading?: boolean;
    results: MutationResult[];
}

const MutationResultStatus: React.FC<Props> = ({
    mutationResult,
    showLoading,
}) => {
    return (
        <MultiMutationResultStatus
            showLoading={showLoading}
            results={[mutationResult]}
        />
    );
};

export const MultiMutationResultStatus = ({
    results,
    showLoading,
}: MultiMutationResultStatusProps): JSX.Element => {
    if (showLoading && results.some((x) => x.isLoading))
        return <CoveringLoadingPanel />;

    const withError = results.find((x) => x.isError);
    if (withError) {
        return (
            <div className="py-2">
                <ErrorInfo error={withError.error} />
            </div>
        );
    }

    return null;
};

export default MutationResultStatus;
