import { ProcessActivityStatusType } from "../domain/types";

interface ActivityState {
    statusType: ProcessActivityStatusType;
    isComplete: boolean;
    isNotApplicable: boolean;
    isRequired: boolean;
    isDisabled: boolean;
    isReadOnly: boolean;
    canEdit: boolean;
    canChangeStatus: boolean;
}
const noActivityData: ActivityState = {
    statusType: null,
    isComplete: false,
    isNotApplicable: false,
    isRequired: false,
    isDisabled: false,
    isReadOnly: true,
    canEdit: false,
    canChangeStatus: false,
};

export const resolveActivityState = (activity: {
    statusType: ProcessActivityStatusType;
    readOnly: boolean;
}): ActivityState => {
    if (!activity) {
        return noActivityData;
    }

    const { statusType, readOnly } = activity;

    const { isComplete, isNotApplicable, isRequired, isDisabled } =
        resolveActivityStatusFlags({ statusType });

    return {
        statusType,
        isComplete,
        isNotApplicable,
        isRequired,
        isDisabled,
        isReadOnly: readOnly,
        canEdit: !readOnly && !isComplete && !isNotApplicable && !isDisabled,
        canChangeStatus: !readOnly && !isDisabled,
    };
};

interface ActivityStatusFlags {
    isComplete: boolean;
    isNotApplicable: boolean;
    isRequired: boolean;
    isDisabled: boolean;
}

export const resolveActivityStatusFlags = ({
    statusType,
}: {
    statusType: ProcessActivityStatusType;
}): ActivityStatusFlags => {
    const isComplete = statusType === ProcessActivityStatusType.Complete;
    const isNotApplicable =
        statusType === ProcessActivityStatusType.NotApplicable;
    const isRequired = statusType === ProcessActivityStatusType.Required;
    const isDisabled = statusType === ProcessActivityStatusType.Disabled;

    return {
        isComplete,
        isNotApplicable,
        isRequired,
        isDisabled,
    };
};
