import React from "react";
import {
    Grid,
    GridColumn as Column,
    GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import { RequestedFileDto } from "../queries/types";
import { formatIsoAsDateTime } from "../../../helpers/dateTimeHelpers";
import UserRequestedFilesHistoryDownloadButtonCell from "./UserRequestedFilesHistoryDownloadButtonCell";

function getReportData(reportList: RequestedFileDto[]) {
    return reportList
        ? reportList.map(({ createdOn, lastDownloadedAt, ...other }) => ({
              createdAt: formatIsoAsDateTime(createdOn) || null,
              lastDownloadedOn: formatIsoAsDateTime(lastDownloadedAt) || null,
              ...other,
          }))
        : [];
}

const UserRequestedFilesHistoryTablePanel: React.FC<{
    reportTitle: string;
    data: RequestedFileDto[];
}> = ({ reportTitle, data }) => {
    const inspectionsData = React.useMemo(() => getReportData(data), [data]);
    const [page, setPage] = React.useState({ skip: 0, take: 10 });
    const handlePageChange = (event: GridPageChangeEvent) => {
        setPage(event.page);
    };

    return (
        <div>
            <h1>{reportTitle ?? "Report"}</h1>
            <Grid
                data={inspectionsData.slice(page.skip, page.skip + page.take)}
                skip={page.skip}
                take={page.take}
                total={inspectionsData.length}
                pageable={true}
                onPageChange={handlePageChange}
            >
                <Column
                    field="fileName"
                    title="Name"
                    headerClassName="grid-header"
                />
                <Column
                    field="requestedBy"
                    title="Requested By"
                    headerClassName="grid-header"
                />
                <Column
                    field="createdAt"
                    title="Created On"
                    headerClassName="grid-header"
                />
                <Column
                    field="lastDownloadedOn"
                    title="Last Downloaded At"
                    headerClassName="grid-header"
                />
                <Column
                    field="status"
                    title="Status"
                    headerClassName="grid-header"
                />
                <Column
                    field="fileType"
                    title="File Type"
                    headerClassName="grid-header"
                />
                <Column
                    field=""
                    title=""
                    headerClassName="grid-header"
                    cell={UserRequestedFilesHistoryDownloadButtonCell}
                />
            </Grid>
        </div>
    );
};

export default UserRequestedFilesHistoryTablePanel;
