import React from "react";
import CoveringLoadingPanel from "./common/components/CoveringLoadingPanel";
import QueryResultStatus from "./common/components/QueryResultStatus";
import { useGetConfigQuery } from "./store/config/configApi";

export const Startup: React.FC = ({ children }) => {
    const result = useGetConfigQuery();

    if (result.isLoading) return <CoveringLoadingPanel />;

    return result.isSuccess ? (
        <>{children}</>
    ) : (
        <QueryResultStatus queryResult={result} />
    );
};
