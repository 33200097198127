import { ErrorMessage, FieldAttributes, useField } from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import { formatIsoAsSpecificType } from "../../../helpers/dateTimeHelpers";

type Props = {
    type?: "date" | "datetime-local" | "time";
    label?: string;
    readOnly?: boolean;
    plainText?: boolean;
} & FieldAttributes<unknown>;

const DateTimeSelect: React.FC<Props> = ({
    readOnly,
    label,
    plainText,
    ...props
}) => {
    const [field, meta] = useField<string>(props);

    const type = props.type || "datetime-local";

    const fieldValueFormatted = formatIsoAsSpecificType(field.value, type);

    return (
        <Form.Group controlId={field.name}>
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Control
                type={type}
                name={field.name}
                readOnly={readOnly}
                className={meta.touched && meta.error ? "is-invalid" : ""}
                onBlur={field.onBlur}
                onChange={field.onChange}
                value={fieldValueFormatted ?? ""}
                plaintext={plainText}
            />
            <ErrorMessage
                name={field.name}
                className="invalid-feedback"
                component="div"
            />
        </Form.Group>
    );
};

DateTimeSelect.defaultProps = {
    readOnly: false,
};

export default DateTimeSelect;
