import * as React from "react";
import { ListGroup, Tab, Row, Col } from "react-bootstrap";
import CoveringLoadingPanel from "../../../common/components/CoveringLoadingPanel";
import { useGetHelpItemsByOrgIdQuery } from "../../../store/features/help-items/help-items-api-slice";
import HelpDisplayItem from "./HelpDisplayItem";

type HelpSectionListProps = {
    organisationId: string;
};

const HelpSectionList = ({
    organisationId,
}: HelpSectionListProps): JSX.Element => {
    const { data: helpItems, isLoading } = useGetHelpItemsByOrgIdQuery({
        organisationId,
    });

    if (isLoading || !helpItems) {
        return <CoveringLoadingPanel />;
    }

    if (helpItems.length === 0) return <Row>There are no items to display</Row>;

    return (
        <Tab.Container defaultActiveKey={helpItems[0].id}>
            <Row>
                <Col xs="auto">
                    <ListGroup>
                        {helpItems.map(({ name, id }) => (
                            <ListGroup.Item key={id} action eventKey={id}>
                                {name}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
                <Col>
                    <Tab.Content>
                        {helpItems.map(({ id, ...helpItem }) => (
                            <HelpDisplayItem key={id} id={id} {...helpItem} />
                        ))}
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    );
};

export default HelpSectionList;
