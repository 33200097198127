import React from "react";
import { Button, Card } from "react-bootstrap";
import { TargetFile } from "../domain/types";
import { downloadFile } from "../services/ProcessActivityApprovalService";
interface Props {
    activityId: string;
    target: TargetFile;
}

const TargetFileCard: React.FC<Props> = ({ target, activityId }) => {
    if (!target) return null;

    return (
        <Card>
            <Card.Body>
                <Card.Title>{target.fileName}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                    Approval Target
                </Card.Subtitle>
                <Card.Text>{target.comment}</Card.Text>
                <Card.Link href={target.storageLocation}></Card.Link>
                <Button
                    variant="primary"
                    onClick={() => {
                        downloadFile(activityId, target.fileName).catch(() => {
                            console.error("failed to download file");
                        });
                    }}
                >
                    Download
                </Button>
            </Card.Body>
        </Card>
    );
};
export default TargetFileCard;
