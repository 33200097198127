import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React from "react";
import { Figure, Spinner } from "react-bootstrap";
import { CefaTemplateContentFileRecord } from "../../../../../template/domain/types";
import { getFormFile } from "../../../../../../services/FileDownloadService";
import { DynamicFormContext } from "../../../../../../common/components/dynamic-form/DynamicFormContextProvider";

const CefaFormControlFileDetails: React.FC<GridDetailRowProps> = (
    props: GridDetailRowProps,
) => {
    const data: CefaTemplateContentFileRecord = props.dataItem;
    const { getFileUrl } = React.useContext(DynamicFormContext);
    const { localFile, id: fileId } = data;
    const [fileBlob, setFileBlob] = React.useState(new Blob([]));
    const [isLoading, setIsLoading] = React.useState(false);
    const url = React.useMemo(() => getFileUrl(fileId), [getFileUrl, fileId]);

    React.useEffect(() => {
        if (url && fileId) {
            setIsLoading(true);
            getFormFile(url)
                .then((a) => {
                    setFileBlob(a);
                })
                .catch(() => {
                    console.error("Failed to fetch img");
                    setFileBlob(new Blob([]));
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setFileBlob(new Blob([]));
        }
    }, [fileId, url]);

    if (
        data.fileName.toLowerCase().endsWith("png") ||
        data.fileName.toLowerCase().endsWith("jpeg") ||
        data.fileName.toLowerCase().endsWith("jpg") ||
        data.fileName.toLowerCase().endsWith("gif")
    ) {
        if (isLoading) {
            return (
                <div className="d-flex py-2">
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="<output>"
                        aria-hidden="true"
                        style={{ marginLeft: 15 }}
                    />
                </div>
            );
        } else if (localFile) {
            return (
                <Figure className="mb-0">
                    <Figure.Image
                        className="mb-0"
                        src={URL.createObjectURL(localFile)}
                    />
                </Figure>
            );
        } else {
            return (
                <Figure className="mb-0">
                    <Figure.Image
                        className="mb-0"
                        src={URL.createObjectURL(fileBlob)}
                    />
                </Figure>
            );
        }
    } else {
        return <strong>This File Type is not valid for viewing here</strong>;
    }
};

export default CefaFormControlFileDetails;
