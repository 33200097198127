import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { HelpItem } from "../../../modules/help-section/domain/types";
import { dcpApi, Tags } from "../dcpApi";

export const helpItemsApi = dcpApi.injectEndpoints({
    endpoints: (builder) => ({
        getHelpItemsByOrgId: builder.query<
            HelpItem[],
            { organisationId: string }
        >({
            query: ({ organisationId }) =>
                `${OrganisationConstants.RoutePath}/${organisationId}${OrganisationConstants.HelpItems}`,
            providesTags: (result, error, arg) => [
                { type: Tags.OrganisationHelpItemList, id: arg.organisationId },
            ],
        }),
        getHelpItemById: builder.query<
            HelpItem,
            { organisationId: string; helpItemId: string }
        >({
            query: ({ organisationId, helpItemId }) =>
                `${OrganisationConstants.RoutePath}/${organisationId}${OrganisationConstants.HelpItems}/${helpItemId}`,
            providesTags: (result, error, arg) => [
                { type: Tags.OrganisationHelpItemList, id: arg.organisationId },
            ],
        }),
        putHelpItem: builder.mutation<
            void,
            { organisationId: string; helpItem: HelpItem }
        >({
            query: ({ organisationId, helpItem }) => ({
                url: `${OrganisationConstants.RoutePath}/${organisationId}${OrganisationConstants.HelpItems}/${helpItem.id}`,
                method: "PUT",
                body: helpItem,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: Tags.OrganisationHelpItemList, id: arg.organisationId },
            ],
        }),
        deleteHelpItem: builder.mutation<
            void,
            { organisationId: string; helpItemId: string }
        >({
            query: ({ organisationId, helpItemId }) => ({
                url: `${OrganisationConstants.RoutePath}/${organisationId}${OrganisationConstants.HelpItems}/${helpItemId}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, arg) => [
                { type: Tags.OrganisationHelpItemList, id: arg.organisationId },
            ],
        }),
        addHelpItem: builder.mutation<
            void,
            { organisationId: string; helpItem: HelpItem }
        >({
            query: ({ organisationId, helpItem }) => ({
                url: `${OrganisationConstants.RoutePath}/${organisationId}${OrganisationConstants.HelpItems}`,
                method: "POST",
                body: helpItem,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: Tags.OrganisationHelpItemList, id: arg.organisationId },
            ],
        }),
    }),
});

export const {
    useGetHelpItemsByOrgIdQuery,
    useGetHelpItemByIdQuery,
    usePutHelpItemMutation,
    useDeleteHelpItemMutation,
    useAddHelpItemMutation,
} = helpItemsApi;
