import cx from "classnames";
import * as React from "react";
import { Button, ButtonToolbar } from "react-bootstrap";
import { MultiMutationResultStatus } from "../../../common/components/MutationResultStatus";
import QueryResultStatus from "../../../common/components/QueryResultStatus";
import { AssetPreviewModel } from "../../../store/features/asset/asset-api-slice";
import {
    useGetActivityAssetsQuery,
    useRemoveManyProcessActivityAssetsMutation,
    useRemoveOneProcessActivityAssetMutation,
} from "../../../store/features/process-activity/processactivity-api-slice";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";
import { ProcessActivity } from "../domain/types";
import { resolveActivityState } from "../helpers/activityStateHelpers";
import AddAssetsModal from "./AddAssetsModal";
import AssetLinkList from "./AssetLinkList";
import { Organisation } from "../../organisation/domain/types";

interface AssetLinkTabProps {
    activity: ProcessActivity;
    organisation: Organisation;
}

const AssetLinkTab: React.FC<AssetLinkTabProps> = ({
    activity,
    organisation,
}) => {
    const [selectedAssets, setSelectedAssets] = React.useState<
        AssetPreviewModel[]
    >([]);

    const [addActive, setAddActive] = React.useState<boolean>(false);
    const { organisationId, isLoadingOrgId } = useOrganisationId();
    const { canEdit } = resolveActivityState(activity);

    const { data: assets, ...assetQueryResult } = useGetActivityAssetsQuery(
        {
            activity,
            organisationId: organisationId,
        },
        { skip: isLoadingOrgId },
    );

    const [removeOneAsset, removeOneMutationResult] =
        useRemoveOneProcessActivityAssetMutation();
    const [removeManyAssets, removeManyMutationResult] =
        useRemoveManyProcessActivityAssetsMutation();

    const onRemoveOne = React.useCallback(
        async (assetId) => {
            await removeOneAsset({
                activity,
                assetId,
                organisationId: organisationId,
            });
        },
        [activity, organisationId, removeOneAsset],
    );

    const onRemoveMany = React.useCallback(async () => {
        await removeManyAssets({
            activity,
            assetIds: selectedAssets.map((a) => a.id),
            organisationId: organisationId,
        });
    }, [activity, organisationId, removeManyAssets, selectedAssets]);

    const onSelectedAssetsChange = (selected: AssetPreviewModel[]) => {
        setSelectedAssets([...(selected || [])]);
    };

    const hasAssetLink = activity?.assetLink?.enabled;
    if (!hasAssetLink) return null;

    const { multiple, editable: assetsEditable } = activity?.assetLink ?? {
        multiple: false,
        editable: false,
    };

    return (
        <div className="mt-3">
            <div className="mt-3">
                {canEdit && addActive && (
                    <AddAssetsModal
                        activity={activity}
                        current={assets}
                        multiple={multiple}
                        onClose={() => setAddActive(false)}
                        organisation={organisation}
                    />
                )}
                {canEdit && assetsEditable && (
                    <ButtonToolbar
                        key="btn-edit-panel"
                        className="justify-content-end"
                    >
                        <Button
                            variant="primary"
                            type="button"
                            onClick={() => setAddActive(true)}
                        >
                            Add
                        </Button>
                    </ButtonToolbar>
                )}
            </div>
            <div className="pt-3">
                {canEdit && assetsEditable && (
                    <ButtonToolbar>
                        <Button
                            className={cx({
                                invisible: selectedAssets.length < 2,
                            })}
                            variant="outline-secondary"
                            disabled={removeManyMutationResult.isLoading}
                            onClick={() => {
                                onRemoveMany();
                            }}
                        >
                            Remove
                        </Button>
                    </ButtonToolbar>
                )}
                <AssetLinkList
                    orgShortName={organisation.shortName}
                    assets={assets}
                    onRemove={onRemoveOne}
                    isLoading={removeOneMutationResult.isLoading}
                    editable={canEdit && assetsEditable}
                    onSelectedAssetsChange={onSelectedAssetsChange}
                />
            </div>

            <MultiMutationResultStatus
                results={[removeOneMutationResult, removeManyMutationResult]}
            />
            <QueryResultStatus queryResult={assetQueryResult} />
        </div>
    );
};

export default AssetLinkTab;
