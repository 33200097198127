import * as React from "react";
import { Row, Col, Breadcrumb } from "react-bootstrap";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import OrgReportsCreateForm from "../containers/OrgReportCreateForm";
import OrgReportPageTitles from "./pageTitles";

const OrgReportCreatePage: React.FC = () => {
    const params = useParams<{ id: string; orgShortName: string }>();

    useOrgTitleWithShortName(OrgReportPageTitles.Create);

    return (
        <>
            <Row className="top10">
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={"div"}>
                            <Link
                                to={`/${params.orgShortName}${OrganisationConstants.Report}`}
                            >
                                Reports
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Create</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>Create Report</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <OrgReportsCreateForm></OrgReportsCreateForm>
                </Col>
            </Row>
        </>
    );
};

export default OrgReportCreatePage;
