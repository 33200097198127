import { v4 as uuidv4 } from "uuid";
import { CefaTemplateContentFileRecord } from "../../../../../template/domain/types";

export interface CefaFormControlFileTableDataItem
    extends CefaTemplateContentFileRecord {
    originalIndex: number;
    inEdit: boolean;
}

export const mapToFileTableItem = (
    fileRecord: CefaTemplateContentFileRecord,
    index: number,
    inEditId: string,
): CefaFormControlFileTableDataItem =>
    ({
        ...fileRecord,
        originalIndex: index,
        inEdit: fileRecord.id === inEditId,
        uuid: uuidv4(),
    } as CefaFormControlFileTableDataItem);
