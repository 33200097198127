import saveAs from "file-saver";
import { ErrorResponse } from "../common/types/Errors";
import authService from "../modules/authorization/services/AuthService";

export type Method =
    | "get"
    | "GET"
    | "delete"
    | "DELETE"
    | "post"
    | "POST"
    | "put"
    | "PUT"
    | "patch"
    | "PATCH";

interface RequestOptions {
    method: Method;
    url: string;
    payload?: unknown;
}
interface RequestFormDataOptions {
    method: Method;
    url: string;
    payload: FormData;
}

export default async function authFetch(
    input: RequestInfo,
    init: RequestInit = { headers: {} },
): Promise<Response> {
    const token = await authService.getAccessToken();
    const newInit = { ...init };

    const tokenHeader = {
        Authorization: `Bearer ${token}`,
    };

    newInit.headers = {
        ...init.headers,
        ...tokenHeader,
    };
    return fetch(input, newInit);
}

export async function requestData<T = unknown>({
    method,
    url,
    payload,
}: RequestOptions): Promise<T> {
    return authFetch(url, {
        method,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
    }).then(async (data) => {
        if (data.status >= 200 && data.status <= 299) {
            return data.json().catch(() => {
                // data.json() fails for empty response body, so we return {} to resolve that as empty object
                return {};
            });
        } else {
            const error = (await data.json()) as ErrorResponse;
            return Promise.reject(error);
        }
    });
}
export async function requestFormData<T = unknown>({
    method,
    url,
    payload,
}: RequestFormDataOptions): Promise<T> {
    return authFetch(url, {
        method,
        headers: {
            Accept: "application/json",
        },
        body: payload,
    }).then(async (data) => {
        if (data.status >= 200 && data.status <= 299) {
            return data.json().catch(() => {
                return {};
            });
        } else {
            const error = (await data.json()) as ErrorResponse;
            return Promise.reject(error);
        }
    });
}

export async function getData<T = unknown>(url: string): Promise<T> {
    return requestData<T>({
        method: "GET",
        url,
    });
}

export async function putData<T = unknown>(
    url: string,
    payload: unknown,
): Promise<T> {
    return requestData<T>({
        method: "PUT",
        url,
        payload,
    });
}

export async function patchData<T = unknown>(
    url: string,
    payload: unknown,
): Promise<T> {
    return requestData<T>({
        method: "PATCH",
        url,
        payload,
    });
}

export async function postData<T = unknown>(
    url: string,
    payload?: unknown,
): Promise<T> {
    return requestData<T>({
        method: "POST",
        url,
        payload,
    });
}
export async function postFormData<T = unknown>(
    url: string,
    payload: FormData,
): Promise<T> {
    return requestFormData<T>({
        method: "POST",
        url,
        payload,
    });
}

export async function deleteData<T = unknown>(url: string): Promise<T> {
    return requestData<T>({
        method: "DELETE",
        url,
    });
}

export function DownloadFile({
    method,
    url,
    payload,
}: RequestOptions): Promise<void> {
    return authFetch(url, {
        method: method,
        body: JSON.stringify(payload),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    })
        .then(async (res) => {
            const filename = res.headers
                .get("Content-Disposition")
                .split("filename=")[1]
                .split(";")[0];
            return { blob: await res.blob(), filename };
        })
        .then(({ blob, filename }) => {
            return saveAs(blob, decodeURI(filename));
        });
}
