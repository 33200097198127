import * as React from "react";
import { Alert } from "react-bootstrap";

const NoAccess: React.FC = () => {
    return (
        <div className="py-2">
            <Alert variant="info">
                <Alert.Heading>No Access</Alert.Heading>
                <p>{`You don't have access to this content.`}</p>
            </Alert>
        </div>
    );
};

export default NoAccess;
