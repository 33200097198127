import { dcpApi, Tags } from "../dcpApi";
import ProcessReportTemplateConstants from "../../../Constants/ProcessReportTemplateConstants";
import { AuditAction } from "../../../common/types/AuditAction";

export interface ReportTemplateFile {
    fileName: string;
    blobFileName: string;
    version: number;
}
export interface ProcessReportTemplate {
    id: string;
    name: string;
    description: string;
    files: ReportTemplateFile[];
    auditActions: AuditAction[];
    activeFileVersion: number;
    processTemplateId: string;
}

export const processReportTemplateApi = dcpApi.injectEndpoints({
    endpoints: (builder) => ({
        postProcessReportTemplate: builder.mutation<
            ProcessReportTemplate,
            Partial<FormData>
        >({
            query: (body) => ({
                url: `${ProcessReportTemplateConstants.ResourceName}`,
                method: "POST",
                body,
            }),
            invalidatesTags: (result, error, requestBody) => [
                {
                    type: Tags.ProcessReportTemplates,
                    id: requestBody.get("processTemplateId").toString(),
                },
            ],
        }),
        getReportTemplatesByProcessTemplateId: builder.query<
            ProcessReportTemplate[],
            string
        >({
            query: (processTemplateID) =>
                `${ProcessReportTemplateConstants.ResourceName}?ProcessTemplateId=${processTemplateID}`,
            providesTags: (result, error, id) => [
                { type: Tags.ProcessReportTemplates, id },
            ],
        }),
        getReportTemplateById: builder.query<ProcessReportTemplate, string>({
            query: (reportTemplateID) =>
                `${ProcessReportTemplateConstants.ResourceName}/${reportTemplateID}`,
            providesTags: (result, error, id) => [
                { type: Tags.ProcessReportTemplate, id },
            ],
        }),
        deleteReportTemplate: builder.mutation<
            void,
            { processTemplateId: string; id: string }
        >({
            query: (reportTemplate) => ({
                url: `${ProcessReportTemplateConstants.ResourceName}/${reportTemplate.id}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, reportTemplate) => [
                {
                    type: Tags.ProcessReportTemplate,
                    id: reportTemplate.id,
                },
                {
                    type: Tags.ProcessReportTemplates,
                    id: reportTemplate.processTemplateId,
                },
            ],
        }),
        patchProcessReportTemplateById: builder.mutation<
            string,
            Partial<FormData>
        >({
            query: (body) => ({
                url: `${ProcessReportTemplateConstants.ResourceName}/${body.get(
                    "id",
                )}`,
                method: "PATCH",
                body,
            }),
            invalidatesTags: (result, error, requestBody) => [
                {
                    type: Tags.ProcessReportTemplate,
                    id: requestBody.get("id").toString(),
                },
            ],
        }),
    }),
});

export const {
    usePostProcessReportTemplateMutation,
    useGetReportTemplatesByProcessTemplateIdQuery,
    useGetReportTemplateByIdQuery,
    usePatchProcessReportTemplateByIdMutation,
    useDeleteReportTemplateMutation,
} = processReportTemplateApi;
