import * as React from "react";
import "./link-row.scss";

interface LinkRowProps {
    onNavigate: () => void;
    title: string;
    description?: React.ReactNode;
}

const LinkRow: React.FC<LinkRowProps> = ({
    title,
    description,
    children,
    onNavigate,
}) => {
    return (
        <tr
            role="button"
            onClick={onNavigate}
            className="item-highlight user-select-none"
        >
            <td>
                <h2 className="item-row-title">{title}</h2>
                <div className="item-row-description">{description}</div>
            </td>
            {children}
        </tr>
    );
};

export default LinkRow;
