import * as React from "react";
import { Form } from "react-bootstrap";

interface FormConfig {
    field: string;
    prompt: string;
    uri: string;
}

interface Prop {
    config: FormConfig;
}

const FormPromptComponent: React.FC<Prop> = (props) => {
    const { config } = props;
    return (
        <div>
            {config.uri ? (
                <a href={config.uri} target="_blank" rel="noreferrer">
                    {config.prompt}
                </a>
            ) : (
                <Form.Text className="text-muted">{config.prompt}</Form.Text>
            )}
        </div>
    );
};

export default FormPromptComponent;
