import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import useConsumerApiSettings from "../../../../customization/api/useConsumerApiSettings";
import { postData } from "../../../../helpers/ApiHelper";
import { RequestedFileDto } from "../../../user-requested-files/queries/types";
import userRequestedFileQueryKeys from "../../../user-requested-files/queries/userRequestedFileQueryKeys";
import { OrgReportsDefectsFormModel } from "../domain/OrgReportsDefectsDtos";

export const useDefectsReportMutation = (
    organisationId: string,
): UseMutationResult<RequestedFileDto, unknown, OrgReportsDefectsFormModel> => {
    const queryClient = useQueryClient();

    const { baseApiUrl } = useConsumerApiSettings();

    const mutationFn = (postModel: OrgReportsDefectsFormModel) => {
        const url = `${baseApiUrl}/organisation/${organisationId}/defectReport`;
        return postData<RequestedFileDto>(url, postModel);
    };

    return useMutation(mutationFn, {
        onSuccess(result, _args) {
            return queryClient.invalidateQueries(
                userRequestedFileQueryKeys.userRequestedFileKey(result.id),
            );
        },
    });
};
