export default class RiskConstants {
    static readonly IdField = "id";
    static readonly AssessmentRefField = "assessmentRef";
    static readonly AssessmentRefLabel = "Thematic Risk Assessment Ref";
    static readonly AssessmentRefWidth = 0.115;
    static readonly SubActivityField = "subActivity";
    static readonly SubActivityLabel = "Sub Activity";
    static readonly SubActivityWidth = 0.1;
    static readonly HazardField = "hazard";
    static readonly HazardLabel = "Hazard";
    static readonly HazardWidth = 0.2;
    static readonly ResidualRiskField = "residualRisk";
    static readonly ResidualRiskLabel = "Residual Risk Level";
    static readonly ResidualRiskWidth = 0.1;
    static readonly ControlField = "control";
    static readonly ControlLabel = "Control";
    static readonly ControlWidth = 0.2;
    static readonly ActionWidth = 0.05;
}
