import React from "react";
import { ApprovalResponse, ProcessActivityApproval } from "../domain/types";
import ApprovalResponseItem from "./ApprovalResponseItem";

interface Props {
    responses: ApprovalResponse[];
    processActivityApproval: ProcessActivityApproval;
}

const ApprovalResponsesHistory: React.FC<Props> = ({
    responses,
    processActivityApproval,
}) => {
    return (
        <div>
            <ul className="timeline">
                {responses.map((item: ApprovalResponse) => (
                    <ApprovalResponseItem
                        key={item.id}
                        data={item}
                        processActivityApproval={processActivityApproval}
                    />
                ))}
            </ul>
        </div>
    );
};

export default ApprovalResponsesHistory;
