import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { push } from "connected-react-router";
import React from "react";
import { useDispatch } from "react-redux";
import authService from "../../authorization/services/AuthService";

const InvitationRedirectPage: React.FC = () => {
    const { inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (inProgress !== InteractionStatus.None) return;
        if (!isAuthenticated) {
            authService.signIn();
        } else if (isAuthenticated) {
            dispatch(push(""));
        }
    }, [dispatch, inProgress, isAuthenticated]);

    return <></>;
};

export default InvitationRedirectPage;
