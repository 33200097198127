import { DataContinuationResult } from "../../../common/types/DataResult";
import ApiConstants from "../../../Constants/ApiConstants";
import { postData } from "../../../helpers/ApiHelper";
import {
    ActivityAssetContQueryParams,
    AssetWithPropsModel,
} from "../../../store/features/asset/asset-api-slice";

export enum UrlPaths {
    BaseResource = "organisation",
    ProcessActivity = "processactivity",
    Assets = "assets",
}

export const baseUrl = `${ApiConstants.ApiPrefix}/${UrlPaths.BaseResource}`;

export function getActivityAssetsContinuation({
    activityId,
    ...filterQuery
}: ActivityAssetContQueryParams): Promise<
    DataContinuationResult<AssetWithPropsModel>
> {
    const url = `${baseUrl}/${filterQuery.organisationId}/${UrlPaths.ProcessActivity}/${activityId}/${UrlPaths.Assets}/search`;
    return postData<DataContinuationResult<AssetWithPropsModel>>(
        url,
        filterQuery,
    );
}
