import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Formik } from "formik";
import * as React from "react";
import { Button, FormLabel } from "react-bootstrap";
import CoveringLoadingPanel from "../../../../../common/components/CoveringLoadingPanel";
import { CustomSelect } from "../../../../../common/components/form";
import { SelectOptionItem } from "../../../../../common/types/reactSelect/SelectOptionItem";
import {
    ExamManagement,
    bridgeExamTypes,
    underWaterExamTypes,
} from "../../../examinations/domain/examManagement";
import { useChangeExamTypeMutation } from "../queries/examManagementQueries";
import { ChangeExamTypeModel } from "../../../examinations/domain/examManagementDtos";
import * as yup from "yup";

interface FormValues {
    examType: SelectOptionItem;
}

interface Props {
    examinations: ExamManagement[];
    organisationId: string;
    onClose: () => void;
}

const ChangeExamTypeModal: React.FC<Props> = ({
    examinations,
    organisationId,
    onClose,
}) => {
    const processIds = React.useMemo(
        () => examinations.map((e) => e.processId),
        [examinations],
    );

    const changeExamType = useChangeExamTypeMutation(organisationId);

    const initialValues: FormValues = {
        examType: null,
    };

    const validation = yup.object({
        examType: yup.mixed().required("Required"),
    });

    const isBridgeDetailedGroup = examinations
        ?.map((x) => x.processTemplateName)
        ?.every((x) => bridgeExamTypes.some((exam) => exam.value === x));

    const isUnderWater = examinations
        ?.map((x) => x.processTemplateName)
        ?.every((x) => underWaterExamTypes.some((exam) => exam.value === x));

    const onSubmit = React.useCallback(
        async (values: FormValues) => {
            changeExamType.mutate(
                {
                    processIds: processIds,
                    examType: values.examType ? values.examType.value : "",
                } as ChangeExamTypeModel,
                {
                    onSuccess: () => onClose(),
                    onError: () => onClose(),
                },
            );
        },
        [processIds, onClose, changeExamType],
    );

    const isMultipleExamGroupSelected = !isBridgeDetailedGroup && !isUnderWater;

    return (
        <Dialog
            title={"Change Exam Type"}
            width={400}
            onClose={onClose}
            contentStyle={{ overflow: "visible" }}
        >
            {changeExamType?.isLoading && <CoveringLoadingPanel />}

            <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={validation}
            >
                {() => (
                    <Form>
                        {isMultipleExamGroupSelected && (
                            <FormLabel>
                                <strong>
                                    The selected exams belong to different
                                    groups. The exam type cannot change for
                                    these selected exams. Each exam must belong
                                    to a single exam group.
                                </strong>
                                <ul>
                                    <li>
                                        <strong>Bridge Detail Group</strong>
                                    </li>
                                    <ul>
                                        <li>Bridge Detailed</li>
                                        <li>Bridge Detailed + HCE</li>
                                    </ul>
                                    <li>
                                        <strong>UnderWater Group</strong>
                                    </li>
                                    <ul>
                                        <li>Underwater</li>
                                        <li>Underwater + Stage 1</li>
                                        <li>Underwater + Stage 2</li>
                                        <li>Underwater + Stage 1 Review</li>
                                        <li>Underwater + Stage 2 Review</li>
                                        <li>Scour</li>
                                        <li>Scour Stage 1</li>
                                        <li>Scour Stage 1 Review</li>
                                        <li>Scour Stage 2</li>
                                    </ul>
                                </ul>
                            </FormLabel>
                        )}
                        {!isMultipleExamGroupSelected && (
                            <CustomSelect
                                name="examType"
                                label="Select Exam Type"
                                placeholder="Select Exam Type..."
                                options={
                                    isUnderWater
                                        ? underWaterExamTypes
                                        : bridgeExamTypes
                                }
                                isMulti={false}
                            />
                        )}

                        <DialogActionsBar>
                            <Button
                                type="button"
                                variant="outline-secondary"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            {!isMultipleExamGroupSelected && (
                                <Button type="submit" variant="primary">
                                    Submit
                                </Button>
                            )}
                        </DialogActionsBar>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default ChangeExamTypeModal;
