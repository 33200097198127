import * as React from "react";
import { ApplicationState } from "../../../store";
import { useSelector } from "react-redux";
import GeneratedReportListItem from "./GeneratedReportListItem";
import { ReportFile } from "../domain/types";

const getReportFiles = (state: ApplicationState): ReportFile[] =>
    state.report?.domain.reportTemplate.reportFiles ?? [];

const getReportTemplateId = (state: ApplicationState): string =>
    state.report?.domain.reportTemplate.id ?? "";

const GeneratedReportList: React.FC = () => {
    const reportFiles = useSelector(getReportFiles);
    const reportTemplateId = useSelector(getReportTemplateId);

    return (
        <div>
            <ul className="timeline">
                {reportFiles.map((item: ReportFile, index: number) => (
                    <GeneratedReportListItem
                        key={index.toString()}
                        reportId={item.id}
                        reportTemplateId={reportTemplateId}
                        fileName={item.fileName}
                        performedBy={item.generatedBy}
                        performedAt={item.generatedAt}
                    />
                ))}
            </ul>
        </div>
    );
};
export default GeneratedReportList;
