import * as React from "react";
import Container from "react-bootstrap/Container";
import NavMenu from "../components/navigation-top/NavMenu";

const Layout: React.FC = (props: { children?: React.ReactNode }) => {
    return (
        <React.Fragment>
            <NavMenu />
            <Container>{props.children}</Container>
        </React.Fragment>
    );
};
export default Layout;
