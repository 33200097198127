import {
    CompositeFilterDescriptor,
    FilterDescriptor,
    State,
} from "@progress/kendo-data-query";
import {
    FilterParam,
    SortAndFilterOptions,
    SortParam,
} from "../../../types/SortAndFilterOptions";
import {
    isCompositeFilterDesc,
    isFilterDescriptor,
} from "../../../../helpers/typeGuards";

import { GridFieldsMap } from "./GridFieldsMap";
import { flatten } from "lodash";
import { jSDateToDateTimeIso } from "../../../../helpers/dateTimeHelpers";

export const extractFilterValue = (
    dataState: State,
    field: string,
    operator: string,
): string => {
    if (!dataState?.filter || !isCompositeFilterDesc(dataState.filter))
        return null;

    const compositeFilter = dataState.filter as CompositeFilterDescriptor;

    const flatFilters = flatten(
        compositeFilter.filters.map((x) =>
            isCompositeFilterDesc(x) ? x.filters : [x],
        ),
    );

    const filters = flatFilters.filter(
        (pFilter) =>
            isFilterDescriptor(pFilter) &&
            pFilter.field === field &&
            pFilter.operator === operator,
    );

    if (!filters || filters.length === 0) return null;

    return (filters[0] as FilterDescriptor).value;
};

export const extractFilters = (dataState: State): FilterDescriptor[] => {
    if (!dataState?.filter || !isCompositeFilterDesc(dataState.filter))
        return [];

    const compositeFilter = dataState.filter as CompositeFilterDescriptor;

    const flatFilters = flatten(
        compositeFilter.filters.map((x) =>
            isCompositeFilterDesc(x) ? x.filters : [x],
        ),
    );

    return flatFilters.filter((pFilter) =>
        isFilterDescriptor(pFilter),
    ) as FilterDescriptor[];
};

export const extractSortParam = (dataState: State): SortParam => {
    const { sort } = dataState;
    if (sort && sort.length > 0) {
        return {
            field: sort[0]?.field,
            isDesc: sort[0]?.dir === "desc",
        };
    }

    return null;
};

export const resolveSortAndFilterOptions = (
    gridDataState: State = {},
    gridFieldsMap: GridFieldsMap = {},
    keepClientFilter = false,
): SortAndFilterOptions => {
    const filterParam: FilterParam = extractFilters(gridDataState)
        .filter((f) => f.value !== "")
        // if required, filter out any client side custom filter functions
        .filter((f) => {
            return keepClientFilter || typeof f.operator !== "function";
        })
        .map((f) => {
            return {
                field: (f.field as string) || "",
                dataType:
                    gridFieldsMap[f.field as string]?.dataType || "string",
                operator: f.operator as string,
                value:
                    gridFieldsMap[f.field as string]?.dataType === "Date"
                        ? jSDateToDateTimeIso(f.value).toString()
                        : f.value?.toString() || "",
                isCustomField:
                    gridFieldsMap[f.field as string]?.isCustomField || false,
            };
        });

    const sortParam = extractSortParam(gridDataState);

    return {
        sortParam: {
            ...sortParam,
            isCustomField: sortParam?.field
                ? gridFieldsMap[sortParam.field]?.isCustomField || false
                : false,
        },
        filterParam: filterParam,
    };
};
