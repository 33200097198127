import * as React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { OverlayChildren } from "react-bootstrap/esm/Overlay";
import { hasOwnProperty } from "../../helpers/hasOwnProperty";

type MessageTitleAndDetails = [string, string | null];

const businessValidationTitle = "Business rule";
const badRequestTitle = "Bad request";
const authorizationErrorTitle = "Authorization error";

function getAspNetMvcValidationErrorDetails(
    data: Record<string, unknown>,
): string | null {
    if (hasOwnProperty(data, "errors")) {
        const values = Object.values(data.errors);
        const details = values[0]?.[0];
        return typeof details === "string" ? details : null;
    }
    return null;
}

function tryToExtractExceptionDetails(
    data: unknown,
): MessageTitleAndDetails | null {
    if (hasOwnProperty(data, "detail") && typeof data.detail === "string") {
        return [data.detail, null];
    }
    return null;
}

function tryToExtractAspNetMvcValidationMessage(
    error: unknown,
): MessageTitleAndDetails | null {
    if (
        typeof error === "object" &&
        error !== null &&
        hasOwnProperty(error, "data")
    ) {
        const { data } = error;
        if (hasOwnProperty(data, "title") && typeof data.title === "string") {
            if (
                data.title === businessValidationTitle ||
                data.title === badRequestTitle ||
                data.title === authorizationErrorTitle
            ) {
                return tryToExtractExceptionDetails(data);
            }
            return [data.title, getAspNetMvcValidationErrorDetails(data)];
        }
    }
    return null;
}

function tryToExtractPlainMessage(
    error: unknown,
): MessageTitleAndDetails | null {
    if (
        typeof error === "object" &&
        error !== null &&
        hasOwnProperty(error, "data")
    ) {
        const { data } = error;
        if (typeof data === "string") {
            return [data, null];
        }
    }
    return null;
}

function getTitleAndDetails(error: unknown): string[] {
    return (
        tryToExtractAspNetMvcValidationMessage(error) ||
        tryToExtractPlainMessage(error) || ["Error occurred.", null]
    );
}

function TooltipIfNeeded({
    tooltip,
    children,
}: {
    tooltip: string | null;
    children: JSX.Element;
}): JSX.Element {
    if (tooltip === null) {
        return children;
    }
    const renderTooltip: OverlayChildren = (props) => (
        <Tooltip id="error-tooltip" data-testid="error-tooltip" {...props}>
            {tooltip}
        </Tooltip>
    );
    return (
        <OverlayTrigger
            placement="top"
            delay={{ show: 100, hide: 400 }}
            overlay={renderTooltip}
        >
            {children}
        </OverlayTrigger>
    );
}

// todo: Add handling for ForbiddenError. It can be distinguished as it was before in action creators ("if (error instanceof ForbiddenError) ...")
// Previously the <Unauthorised /> component was used to show this particular error type
export const ErrorInfo: React.FC<{ error: unknown }> = ({ error }) => {
    if (error === null) {
        return null;
    }

    const [title, details] = getTitleAndDetails(error);
    return (
        <TooltipIfNeeded tooltip={details}>
            <div className="d-inline-block text-danger">{title}</div>
        </TooltipIfNeeded>
    );
};
