import { Grid, GridColumn } from "@progress/kendo-react-grid";
import React from "react";
import { SimplifiedMenuFilter } from "../../../../common/components/table/kendo/columnFilters";
import useGridProps from "../../../../common/components/table/kendo/useGridProps";
import { Item } from "../domain/types";

interface Props {
    values: Item[];
}

const FailedItemsGrid: React.FC<Props> = ({ values }) => {
    const { gridProps } = useGridProps(values, {
        filterable: true,
        sortable: true,
        initialSort: [{ field: "name", dir: "desc" }],
        pageable: true,
    });

    return (
        <div>
            <Grid {...gridProps}>
                <GridColumn
                    field="name"
                    title="Asset Name"
                    columnMenu={SimplifiedMenuFilter}
                />
                <GridColumn
                    field="status"
                    title="Failure Reason"
                    columnMenu={SimplifiedMenuFilter}
                />
            </Grid>
            <b>NOTE - Only the first 50 failures are displayed</b>
        </div>
    );
};

export default FailedItemsGrid;
