import React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useGetUserGroupsByOrgIdQuery } from "../../../../store/features/organisation/organisation-api-slice";
import { useGetCurrentUserQuery } from "../../../../store/features/user/user-api-slice";
import UserGroupsTable from "./UserGroupsTable";

interface UserGroupDialogProps {
    orgId: string;
    orgShortName: string;
    onClose: () => void;
}

const UserGroupDialog: React.FC<UserGroupDialogProps> = ({
    orgId,
    orgShortName,
    onClose,
}) => {
    const [canCloseDialog, setCanCloseDialog] = React.useState<boolean>(true);

    const {
        data: user,
        isLoading: isLoadingUser,
        isFetching: isFetchingUser,
    } = useGetCurrentUserQuery();

    const {
        data: userGroups,
        isLoading: isLoadingUserGroups,
        isFetching: isFetchingUserGroups,
        refetch: refetchUserGroups,
    } = useGetUserGroupsByOrgIdQuery(orgId, {
        skip: !orgId,
    });

    const loadingTable =
        isLoadingUser ||
        isFetchingUser ||
        isLoadingUserGroups ||
        isFetchingUserGroups;

    const tryClose = () => {
        if (canCloseDialog) {
            onClose();
        }
    };

    return (
        <Dialog
            title={"Change User Groups (" + orgShortName + ")"}
            onClose={tryClose}
            width="calc(100vw - 40px)"
            height="calc(100vh - 40px)"
        >
            <UserGroupsTable
                orgId={orgId}
                user={user}
                userGroups={userGroups}
                refetchUserGroups={refetchUserGroups}
                isLoading={loadingTable}
                setCanCloseDialog={setCanCloseDialog}
            />
        </Dialog>
    );
};

export default UserGroupDialog;
