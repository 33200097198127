import { dcpApi, Tags } from "../../../store/features/dcpApi";
import {
    ActivityActionParams,
    PostNotApplicableParams,
} from "../../../store/features/process-activity/types";
import { ProcessActivity } from "../../process-activity/domain/types";
import { createActivityTags } from "../../process-activity/store/activityTag";
import { ProcessActivityArtefactGeneration } from "../domain/types";

enum UrlPaths {
    ResourceName = "/processactivityartefactgeneration",
    Complete = "/complete",
    NotApplicable = "/not-applicable",
    Open = "/open",
    Generate = "/generate-artefact",
}

const tagsForListAndDetails = (activity: ProcessActivity) =>
    createActivityTags(activity);

export const processActivityArtefactGenerationApi = dcpApi.injectEndpoints({
    endpoints: (builder) => ({
        getById: builder.query<
            ProcessActivityArtefactGeneration,
            { activityId: string; processId: string }
        >({
            query: ({ activityId }) => `${UrlPaths.ResourceName}/${activityId}`,
            providesTags: (result, error, arg) => [
                { type: Tags.ProcessActivity, id: arg.activityId },
                { type: Tags.ProcessActivitiesForProcess, id: arg.processId },
            ],
        }),
        generate: builder.mutation<void, string>({
            query: (id) => ({
                url: `${UrlPaths.ResourceName}/${id}${UrlPaths.Generate}`,
                method: "POST",
            }),
            invalidatesTags: (result, error, id) => [
                { type: Tags.ProcessActivity, id: id },
            ],
        }),
        open: builder.mutation<void, ActivityActionParams>({
            query: ({ activity }) => ({
                url: `${UrlPaths.ResourceName}/${activity.id}${UrlPaths.Open}`,
                method: "PATCH",
            }),
            invalidatesTags: (_result, error, { activity }) =>
                error ? [] : tagsForListAndDetails(activity),
        }),
        complete: builder.mutation<void, ActivityActionParams>({
            query: ({ activity }) => ({
                url: `${UrlPaths.ResourceName}/${activity.id}${UrlPaths.Complete}`,
                method: "PATCH",
            }),
            invalidatesTags: (_result, error, { activity }) =>
                error ? [] : tagsForListAndDetails(activity),
        }),
        notApplicable: builder.mutation<void, PostNotApplicableParams>({
            query: ({ activity, comment, reason }) => ({
                url: `${UrlPaths.ResourceName}/${activity.id}${UrlPaths.NotApplicable}`,
                method: "PATCH",
                body: { comment, reason },
            }),
            invalidatesTags: (_result, error, { activity }) =>
                error ? [] : tagsForListAndDetails(activity),
        }),
    }),
});

export const {
    useGetByIdQuery,
    useCompleteMutation,
    useGenerateMutation,
    useNotApplicableMutation,
    useOpenMutation,
} = processActivityArtefactGenerationApi;
