import { ChunkProgressBar } from "@progress/kendo-react-progressbars";
import * as React from "react";

interface ProgressBarProps {
    count: number;
    doneCount: number;
}

const maxCount = 50;

const ProgressBar: React.FC<ProgressBarProps> = ({ count, doneCount }) => {
    const limitedCount = Math.min(maxCount, count);

    const value = (100 * doneCount) / count;

    return (
        <div>
            <ChunkProgressBar value={value} chunkCount={limitedCount} />
            <span className="ml-2">
                {doneCount}/{count}
            </span>
        </div>
    );
};

export default ProgressBar;
