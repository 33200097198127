import AdminConstants from "../../../../Constants/AdminConstants";
import * as React from "react";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { ApplicationState } from "../../../../store";
import * as AdminStore from "../../../../store/admin/store";
import RoleUserAddForm from "../containers/RoleUserAddForm";
import RoleUsersList from "../containers/RoleUsersList";
import { useTitle } from "../../../../common/hooks/useTitle";
import { roleAdminPageFormat } from "./pageTitles";

const getRole = (state: ApplicationState) =>
    state.admin?.domain.role ?? AdminStore.unloadedState.domain.role;

const RoleAdminPage: React.FC = () => {
    const dispatch = useDispatch();
    const role = useSelector(getRole);
    const params = useParams<{ id: string }>();

    React.useEffect(() => {
        dispatch(AdminStore.actionCreators.getRole(params.id));
    }, [dispatch, params.id]);

    useTitle(roleAdminPageFormat(role.name));

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to={`${AdminConstants.ResourceName}`}>
                                Admin
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`${AdminConstants.RolesPagePath}`}>
                                Roles
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item linkAs={"div"} active>
                            {role.name}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>{role.name}</h1>
                </Col>
            </Row>
            <Row className="top10">
                <Col>
                    <RoleUserAddForm />
                </Col>
            </Row>
            <Row className="top10">
                <Col>
                    <RoleUsersList />
                </Col>
            </Row>
            <Row className="top30"></Row>
        </React.Fragment>
    );
};

export default RoleAdminPage;
