import { Button } from "@progress/kendo-react-buttons";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import React from "react";
import { Form } from "react-bootstrap";

interface Props extends GridFilterCellProps {
    value: string;
}

export const MultiTextContainsFilterCell: React.FC<Props> = (props) => {
    return <MultiTextFilterCell {...props} isExactMatch={false} />;
};

export const MultiTextContainsFilterCellServer: React.FC<Props> = (props) => {
    return <MultiTextFilterCellServer {...props} isExactMatch={false} />;
};

const isIn = (current: string, value: string) => {
    const splitValues = value.split(/\r?\n/);
    return splitValues.includes(current);
};

const isInContains = (current: string, value: string) => {
    const splitValues = value.split(/\r?\n/);
    return splitValues.some((a) => a.includes(current));
};

// The filters (functions) for this cell are only used in the client
export const MultiTextFilterCell: React.FC<
    Props & { isExactMatch: boolean }
> = ({ onChange, value, isExactMatch = true }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.value) {
            onChange({
                value: null,
                operator: "",
                syntheticEvent: event,
            });
        } else {
            onChange({
                value: event.target.value as string,
                operator: isExactMatch ? isIn : isInContains,
                syntheticEvent: event,
            });
        }
    };

    const onClear: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        onChange({
            value: null,
            operator: "",
            syntheticEvent: event,
        });
    };

    return (
        <div className="k-filtercell">
            <div className="k-filtercell-wrapper">
                <Form.Control
                    as="textarea"
                    rows={4}
                    className="k-textbox flex-grow-1"
                    onChange={handleChange}
                    value={value}
                />
                <Button
                    title="Clear"
                    disabled={!value}
                    onClick={onClear}
                    icon="filter-clear"
                />
            </div>
        </div>
    );
};

// The filters (text names) for this cell are sent to the server
export const MultiTextFilterCellServer: React.FC<
    Props & { isExactMatch: boolean }
> = ({ onChange, value, isExactMatch = true }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.value) {
            onChange({
                value: null,
                operator: "",
                syntheticEvent: event,
            });
        } else {
            onChange({
                value: event.target.value as string,
                operator: isExactMatch ? "isIn" : "isInContains",
                syntheticEvent: event,
            });
        }
    };

    const onClear: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        onChange({
            value: null,
            operator: "",
            syntheticEvent: event,
        });
    };

    return (
        <div className="k-filtercell">
            <div className="k-filtercell-wrapper">
                <Form.Control
                    as="textarea"
                    rows={4}
                    className="k-textbox flex-grow-1"
                    onChange={handleChange}
                    value={value}
                />
                <Button
                    title="Clear"
                    disabled={!value}
                    onClick={onClear}
                    icon="filter-clear"
                />
            </div>
        </div>
    );
};
