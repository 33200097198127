import * as React from "react";
import { Process } from "../../process/domain/types";
import { ProcessActivity } from "../domain/types";
import ProcessActivityBreadcrumb from "./ProcessActivityBreadcrumb";
import ProcessActivityNavigationBar from "./ProcessActivityNavigationBar";
import { Organisation } from "../../organisation/domain/types";
import { Col, Row } from "react-bootstrap";
interface Props {
    process: Process;
    activity: ProcessActivity;
    organisation: Organisation;
}

const ProcessActivityMainTopBar: React.FC<Props> = ({
    process,
    activity,
    organisation,
}) => {
    return (
        <>
            <Row>
                <Col>
                    <ProcessActivityBreadcrumb
                        process={process}
                        activity={activity}
                        organisation={organisation}
                    ></ProcessActivityBreadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>{activity?.displayName ?? ""}</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ProcessActivityNavigationBar
                        process={process}
                        activity={activity}
                        orgShortName={organisation.shortName}
                    ></ProcessActivityNavigationBar>
                </Col>
            </Row>
        </>
    );
};

export default ProcessActivityMainTopBar;
