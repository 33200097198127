import { consumerApi } from "../../../customization/api/consumerRTKQueryApi";
import { DefectDto } from "../domain/types";

export class DefectRoutes {
    static readonly DefectAPIRoute = "/defects";
}

export interface DefectQueryParams {
    assetId?: string[];
    activityId?: string[];
}

const apiSubPath = (organisationId: string) =>
    `/organisation/${organisationId}${DefectRoutes.DefectAPIRoute}`;

export const defectsApi = consumerApi.injectEndpoints({
    endpoints: (builder) => ({
        getDefects: builder.query<
            DefectDto[],
            { organisationId: string; params: DefectQueryParams }
        >({
            query: ({ organisationId, params }) => {
                const queryString = new URLSearchParams(
                    params as string[][],
                ).toString();
                return `${apiSubPath(organisationId)}?${queryString}`;
            },
        }),
        getDefect: builder.query<
            DefectDto,
            { organisationId: string; id: string }
        >({
            query: ({ organisationId, id }) => {
                return `${apiSubPath(organisationId)}/${id}`;
            },
        }),
    }),
});

export const { useGetDefectsQuery, useGetDefectQuery } = defectsApi;
