export default class UserConstants {
    static readonly RoutePath = "/user";
    static readonly DomainName = "/user";
    static readonly Permission = "/permission";
    static readonly Role = "/role";
    static readonly VerifyB2c = "/verify-b2c";
    static readonly CurrentUser = "/current-user";
    static readonly CurrentUserUpdate = UserConstants.CurrentUser + "/update";
    static readonly UserDisplayNameUpdate = "/update-display-name";
}
