export const subsetConfigs = {
    pow: [
        { bciConfigName: "pow-avon", displayName: "Avon Subset" },
        { bciConfigName: "pow-gent", displayName: "Gent Subset" },
        { bciConfigName: "pow-shoots", displayName: "Shoots Subset" },
    ],
    severn: [
        { bciConfigName: "severn-aust", displayName: "Aust Subset" },
        { bciConfigName: "severn-beachley", displayName: "Beachley Subset" },
        { bciConfigName: "severn-severn", displayName: "Severn Subset" },
        { bciConfigName: "severn-toll", displayName: "Toll Subset" },
        { bciConfigName: "severn-wye", displayName: "Wye Subset" },
    ],
};
