import * as React from "react";
import { useSelector } from "react-redux";
import DynamicForm from "../../../common/components/dynamic-form/DynamicForm";
import FormValuesHelper from "../../../common/components/dynamic-form/helpers/FormValuesHelper";
import {
    DynamicFormFormikHelpers,
    DynamicFormRef,
    DynamicFormValues,
} from "../../../common/components/dynamic-form/types/dynamicFormTypes";
import { ApplicationState } from "../../../store";
import { resolveActivityState } from "../../process-activity/helpers/activityStateHelpers";
import { getActivityFileResUrl } from "../../process-activity/helpers/urlBuilder";
import { ProcessActivityForm } from "../domain/types";
import { usePostProcessActivityFormUpdateContentMutation } from "../store/processActivityFormApiSlice";
import useIsClient from "../../../common/hooks/useIsClient";
import CefaConstants from "../../cefa/constants/CefaConstants";
import { useAppDispatch } from "../../../store/hooks";
import { addError } from "../../../store/features/notifications/notificationsSlice";
import { TemplateContent } from "../../template/domain/types";
import FormTemplatesConstants from "../../../Constants/FormTemplatesConstants";

interface Props {
    activity: ProcessActivityForm;
    formikRef: DynamicFormRef;
    onComplete: () => void;
}

const ProcessActivityFormForm: React.FC<Props> = ({
    activity,
    formikRef,
    onComplete,
}) => {
    const { canEdit } = resolveActivityState(activity);
    const isCefa = useIsClient(CefaConstants.ClientName);
    const dispatch = useAppDispatch();

    const [updateFormContent, updateResult] =
        usePostProcessActivityFormUpdateContentMutation();

    //todo: Switch to the hidden fields in DynamicFormContextProvider
    const hiddenFields = useSelector(
        (state: ApplicationState) =>
            state.processActivityForm?.ui.viewForm.hiddenFields ?? [],
    );

    const initialStoredValues = FormValuesHelper.getInitialValues(
        activity?.content ?? [],
    );

    // Add any default state values which need to be tracked by the form
    const initialValues: DynamicFormValues = {
        ...initialStoredValues,
    };

    function cefaFileLimitExceeded(
        activityContent: TemplateContent[],
        values: DynamicFormValues,
    ): boolean {
        // Get section titles from activity content that that apply to specific cefa file type
        // (we can't get the file entries from here as this is the saved version of the data,
        // we need to get that from values, which is the latest data to be submitted)
        const cefaFileFieldNames = activityContent
            .filter(function (el) {
                return (
                    el.type ===
                    FormTemplatesConstants.ContentType.cefaMultiFileCaption
                );
            })
            .map((a) => a.field);

        // Filter values so we only have entries for the titles that are for the cefa file type
        const cefaFileValues = Object.fromEntries(
            Object.entries(values).filter(function (item) {
                return cefaFileFieldNames.includes(item[0]);
            }),
        );

        // Calculate how many array entries this page has with the cefa file type
        const arrayEntries = Number(
            Object.values(cefaFileValues).reduce(
                (accumulator, currentValue) =>
                    Number(accumulator) +
                    (Array.isArray(currentValue) ? currentValue.length : 0),
                0,
            ),
        );

        if (arrayEntries > CefaConstants.PerPageFileLimit) {
            // Show error message in toast pop-up
            const errorMessage = `Sorry, cannot save as file limit exceeded. This page contains ${arrayEntries} files, but the limit is ${CefaConstants.PerPageFileLimit}.`;
            dispatch(addError(errorMessage));

            return true;
        }
        return false;
    }

    const onSubmit = (
        values: DynamicFormValues,
        { setSubmitting }: DynamicFormFormikHelpers,
    ): void => {
        // Do not proceed if this is CEFA and the file limit has been exceeded
        if (isCefa && cefaFileLimitExceeded(activity.content, values)) {
            return;
        }

        // Convert the form value objects into html form data
        const formData = new FormData();
        FormValuesHelper.convertValuesToHtmlFormData(
            values,
            activity.content,
            formData,
            hiddenFields,
        );

        updateFormContent({
            activityId: activity.id,
            formData,
            shouldInvalidateActivity: false,
        })
            .unwrap()
            .then(() => {
                onComplete();
                setSubmitting(false);
            })
            .catch(() => {
                console.error("Failed to upload file.");
                setSubmitting(false);
            });
    };

    const onSaveDraft = (values: DynamicFormValues): void => {
        // Do not proceed if this is CEFA and the file limit has been exceeded
        if (isCefa && cefaFileLimitExceeded(activity.content, values)) {
            return;
        }

        // Convert the form value objects into html form data
        const formData = new FormData();
        FormValuesHelper.convertValuesToHtmlFormData(
            values,
            activity.content,
            formData,
            hiddenFields,
        );

        updateFormContent({
            activityId: activity.id,
            formData,
            shouldInvalidateActivity: true,
        });
    };

    const getFileUrl = React.useCallback(() => {
        return getActivityFileResUrl(activity?.id);
    }, [activity]);

    return (
        <DynamicForm
            initialValues={initialValues}
            onSubmitCallback={onSubmit}
            saveProps={{
                onSaveDraftCallback: onSaveDraft,
                saveResult: updateResult,
            }}
            isFormReadOnly={!canEdit}
            contentConfig={activity.content}
            formikRef={formikRef}
            getFileUrl={getFileUrl()}
            hiddenFields={hiddenFields}
            processId={activity.processId}
        />
    );
};

export default ProcessActivityFormForm;
