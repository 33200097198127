import * as React from "react";
import { ProcessActivityListItem as ProcessActivityListItemComponent } from "./ProcessActivityListItem";
import { ProcessActivityListItem } from "../domain/types";

interface Props {
    organisationId: string;
    processId: string;
    activities: ProcessActivityListItem[];
}

const ProcessActivityList: React.FC<Props> = ({
    activities,
    organisationId,
    processId,
}) => {
    return (
        <div>
            {activities.map((item, index) => (
                <ProcessActivityListItemComponent
                    organisationId={organisationId}
                    key={index.toString()}
                    processId={processId}
                    activity={item}
                />
            ))}
        </div>
    );
};

export default ProcessActivityList;
