import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { ProcessActivity } from "../../process-activity/domain/types";
import { resolveActivityState } from "../../process-activity/helpers/activityStateHelpers";
import { ListItemState } from "../domain/types";
import { ListItemStateComplete } from "./ListItemStateComplete";
import { ListItemStateDisabled } from "./ListItemStateDisabled";
import { ListItemStateNotApplicable } from "./ListItemStateNotApplicable";
import { ListItemStateOptional } from "./ListItemStateOptional";
import { ListItemStateRequired } from "./ListItemStateRequired";

interface Props {
    activity: ProcessActivity;
    activityUrl: string;
}

const ProcessActivityGroupListItem: React.FC<Props> = ({
    activity,
    activityUrl,
}) => {
    const [state, setState] = React.useState<ListItemState>(
        new ListItemStateDisabled(),
    );
    const activityState = React.useMemo(
        () => resolveActivityState(activity),
        [activity],
    );

    React.useEffect(() => {
        const { isComplete, isNotApplicable, isRequired, isDisabled } =
            activityState;
        if (isComplete) {
            setState(new ListItemStateComplete(activityUrl));
        } else if (isNotApplicable) {
            setState(new ListItemStateNotApplicable(activityUrl));
        } else if (isDisabled) {
            setState(new ListItemStateDisabled());
        } else if (isRequired) {
            setState(new ListItemStateRequired(activityUrl));
        } else {
            setState(new ListItemStateOptional(activityUrl));
        }
    }, [activityState, activityUrl]);

    return (
        <div className="card bottom10">
            <div className={"card-body " + state.cardBodyClass}>
                <Row>
                    <Col xs={1} className="d-flex">
                        {state.icon}
                    </Col>
                    <Col xs={11}>
                        <h4 className="card-title">{activity.displayName}</h4>
                        <p className="card-text">{activity.description}</p>

                        <div className="float-right">{state.button}</div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
export default ProcessActivityGroupListItem;
