import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Formik } from "formik";
import * as React from "react";
import { Button } from "react-bootstrap";
import { PersistGate } from "redux-persist/lib/integration/react";
import ButtonSpinner from "../../../common/components/button/ButtonSpinner";
import { persistor } from "../../../store/configureStore";
import { AssetPreviewModel } from "../../../store/features/asset/asset-api-slice";
import {
    useAddProcessActivityAssetsMutation,
    useReplaceProcessActivityAssetMutation,
} from "../../../store/features/process-activity/processactivity-api-slice";
import { ProcessActivity } from "../domain/types";
import { ACTIVITY_ASSET_SELECTED_FIELD, AddAssetsGrid } from "./AddAssetsGrid";
import "./addAssetsModal.scss";
import { Organisation } from "../../organisation/domain/types";

interface AddAssetsModalProps {
    activity: ProcessActivity;
    multiple?: boolean;
    current: AssetPreviewModel[];
    onClose: () => void;
    organisation: Organisation;
}

const initialFormValue = () => ({ [ACTIVITY_ASSET_SELECTED_FIELD]: [] });

const AddAssetsModal: React.FC<AddAssetsModalProps> = ({
    multiple,
    activity,
    onClose,
    current,
    organisation,
}) => {
    const [addAssets] = useAddProcessActivityAssetsMutation();
    const [replaceAsset] = useReplaceProcessActivityAssetMutation();
    const organisationId = organisation.id;

    const onSubmit = React.useCallback(
        async (values) => {
            const assetIds = values[ACTIVITY_ASSET_SELECTED_FIELD];

            if (multiple)
                await addAssets({
                    activity,
                    assetIds: assetIds.map((x) => x.id),
                    organisationId,
                });
            else
                await replaceAsset({
                    activity,
                    assetId: assetIds.map((x) => x.id)[0],
                    organisationId,
                    current,
                });

            onClose();
        },
        [
            activity,
            addAssets,
            current,
            multiple,
            onClose,
            organisationId,
            replaceAsset,
        ],
    );
    const sideMenuName =
        organisation?.features?.assets?.sideMenuName ?? "Assets";
    const action = multiple ? "Add" : "Select";
    const title = `${action} ${sideMenuName}`;
    return (
        <Dialog
            title={title}
            onClose={onClose}
            width="calc(100vw - 40px)"
            height="calc(100vh - 40px)"
            className="add-assets-modal"
        >
            <Formik
                onSubmit={onSubmit}
                initialValues={initialFormValue()}
                style={{ height: "100%" }}
            >
                {({ values, handleSubmit, isSubmitting }) => (
                    <Form onSubmit={handleSubmit} className="assets-form">
                        <PersistGate persistor={persistor}>
                            <AddAssetsGrid
                                organisationId={organisation.id}
                                activityId={activity.id}
                                multiple={multiple}
                            />
                        </PersistGate>
                        <div className="action-buttons">
                            <DialogActionsBar>
                                <Button
                                    type="button"
                                    variant="outline-secondary"
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    disabled={
                                        !values[ACTIVITY_ASSET_SELECTED_FIELD]
                                            ?.length || isSubmitting
                                    }
                                >
                                    {!isSubmitting && <>Submit</>}
                                    {isSubmitting && <ButtonSpinner />}
                                </Button>
                            </DialogActionsBar>
                        </div>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

AddAssetsModal.defaultProps = {
    multiple: false,
};

export default AddAssetsModal;
