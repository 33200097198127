import * as React from "react";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import CoveringLoadingPanel from "../../../common/components/CoveringLoadingPanel";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import AssetCreateForm from "../containers/AsssetCreateForm";
import AssetPageTitles from "./pageTitles";
import { useGetOrgByShortNameQuery } from "../../../store/features/organisation/organisation-api-slice";

const AssetCreatePage: React.FC = () => {
    const params = useParams<{ id: string; orgShortName: string }>();
    useOrgTitleWithShortName(AssetPageTitles.Create);
    const { data: organisation, isLoading } = useGetOrgByShortNameQuery(
        params.orgShortName,
        {
            skip: !params.orgShortName,
        },
    );
    if (isLoading) return <CoveringLoadingPanel />;

    return (
        <>
            <Row className="top10">
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={"div"}>
                            <Link
                                to={`/${params.orgShortName}${OrganisationConstants.Asset}`}
                            >
                                {organisation?.features?.assets?.sideMenuName ??
                                    "Assets"}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Create</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>
                        Create{" "}
                        {organisation?.features?.assets?.createName ?? "Asset"}
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AssetCreateForm
                        organisation={organisation}
                    ></AssetCreateForm>
                </Col>
            </Row>
        </>
    );
};

export default AssetCreatePage;
