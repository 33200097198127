import * as React from "react";
import { mapDataToLabelValuePairs } from "../../../../../helpers/labelValueDataHelper";
import { ExaminationReview } from "../../domain/examinationsReview";
import "./reviewSummary.scss";

export const summaryLabelsMap = {
    elr: "ELR",
    miles: "Start Miles",
    yards: "Start Yards",
    assetType: "Asset Type",
    structureName: "Struct Name",
    examType: "Exam Type",
    structureReferenceNumber: "Struct No",
    examinationDate: "Exam Date",
    assignedExaminer: "Examiner",
    assignedEngineer: "Engineer",
    chains: "Chains",
};

const renderRow = (label: string, value: string, classExt = "") => (
    <div className={`summary-row ${classExt}`} key={`${label}-${value}`}>
        <div className="summary-label">{label}</div>
        <div>{value}</div>
    </div>
);

interface SummaryProps {
    review: ExaminationReview;
}

const ReviewSummary: React.FC<SummaryProps> = ({ review }) => {
    const summary = mapDataToLabelValuePairs(review, summaryLabelsMap);

    return (
        <div className="review-summary">
            {summary.map(([label, value]) => renderRow(label, value))}
        </div>
    );
};

export default ReviewSummary;
