import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import React from "react";
import { Button } from "react-bootstrap";
import "./AddAssetModal.scss";
import { useCancelApcIncidentMutation } from "../../queries/apcApiSlice";
import { useOrganisationId } from "../../../../organisation/hooks/useOrganisationId";
import { useNavigation } from "../../../../../router/useNavigate";
import { AppRoutes } from "../../../../../router/AppRoutes";

interface CancelIncidentModalProps {
    onClose: () => void;
    incidentId: string;
}

const CancelIncidentModal: React.FC<CancelIncidentModalProps> = ({
    onClose,
    incidentId,
}) => {
    const [cancelMutation] = useCancelApcIncidentMutation();
    const { organisationId } = useOrganisationId();
    const { navigateToWithOrg } = useNavigation();

    function onCancel() {
        cancelMutation({ organisationId, entityId: incidentId })
            .unwrap()
            .then(() => navigateToWithOrg(AppRoutes.Apc));
    }

    return (
        <Dialog
            title={"Are you sure you want to cancel this incident?"}
            onClose={onClose}
            width="calc(50vw - 40px)"
            height="calc(10vw - 40px)"
            className="cancel-incident-modal"
        >
            <div className="action-buttons">
                <DialogActionsBar>
                    <Button
                        type="button"
                        variant="outline-secondary"
                        onClick={onClose}
                    >
                        Abort
                    </Button>
                    <Button type="submit" variant="primary" onClick={onCancel}>
                        Continue
                    </Button>
                </DialogActionsBar>
            </div>
        </Dialog>
    );
};

export default CancelIncidentModal;
