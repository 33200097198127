import * as React from "react";
import ExcelReportDownload from "../../common/components/ExcelReportDownload";
import { useGetPerformanceReportMutation } from "../queries/performanceReportQueries";

const PerformanceReportExportPage: React.FC = () => {
    return (
        <ExcelReportDownload
            pageTitle="Performance Report Download"
            callToAction="Select Contract Year"
            fileName="Performance Report"
            includeDateInFileName
            mutationFn={useGetPerformanceReportMutation}
        />
    );
};

export default PerformanceReportExportPage;
