import * as React from "react";
import { ApplicationState } from "../../../../store";
import * as AdminStore from "../../../../store/admin/store";
import { useDispatch, useSelector } from "react-redux";
import RolesListItem from "../components/RolesListItem";
import { Role } from "../domain/types";

const getRoles = (state: ApplicationState) => state.admin?.domain.roles ?? [];

const RolesList: React.FC = () => {
    const roles = useSelector(getRoles);

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(AdminStore.actionCreators.getRoles());
    }, [dispatch]);

    return (
        <>
            {roles.map((item: Role, index: number) => (
                <RolesListItem
                    key={index.toString()}
                    name={item.name}
                    id={item.id}
                />
            ))}
        </>
    );
};

export default RolesList;
