import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import * as Formik from "formik";
import * as React from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import TextInput from "../../../common/components/form/TextInput";
import { useUpdateUserDisplayNameMutation } from "../../../store/features/user/user-api-slice";
import * as yup from "yup";
import authService from "../../../modules/authorization/services/AuthService";
import { useAppSelector } from "../../../store/hooks";

interface FormValues {
    displayName: string;
}

interface Props {
    onClose: () => void;
    id: string;
    organisationId: string;
    initialDisplayName: string;
    username: string;
}

const formValidation = yup.object({
    displayName: yup
        .string()
        .trim()
        .matches(/^[^0-9*&$"#<>%?]+$/, "Please enter valid display name")
        .required("Required"),
});

const UserDisplayNameModal: React.FC<Props> = ({
    onClose,
    id,
    organisationId,
    initialDisplayName,
    username,
}) => {
    const [updateUserDisplayName] = useUpdateUserDisplayNameMutation();
    const currentUser = useAppSelector((state) => state.authUser);
    const currentUserEditingSelf = username === currentUser.username;
    const initialValues: FormValues = {
        displayName: initialDisplayName,
    };

    const submitForm = (values: FormValues): void => {
        const displayNameHasChanged = initialDisplayName !== values.displayName;

        // Update display name only if the display name has changed
        if (displayNameHasChanged) {
            updateUserDisplayName({
                organisationId: organisationId,
                id: id,
                body: {
                    userDisplayName: values.displayName,
                },
            })
                .then(() => {
                    // If the user edited their own display name, sign them out so that the change
                    // isn't lost
                    if (currentUserEditingSelf) {
                        authService.signOut();
                    }
                })
                .finally(() => {
                    onClose();
                });
        } else {
            onClose();
        }
    };

    return (
        <Dialog
            title={"Edit Display Name"}
            height={"180"}
            width={400}
            contentStyle={{ overflow: "visible" }}
            closeIcon={false}
        >
            <Formik.Formik
                onSubmit={submitForm}
                initialValues={initialValues}
                validationSchema={formValidation}
                validateOnMount={true}
            >
                {({ handleSubmit, isSubmitting, status }): JSX.Element => (
                    <Formik.Form className={"k-form"} onSubmit={handleSubmit}>
                        <TextInput
                            placeholder="Display Name"
                            name="displayName"
                        />

                        {currentUserEditingSelf && (
                            <Form.Text className="text-muted mb-2">
                                Note that if you edit your own display name, you
                                will be logged out automatically so that it can
                                take effect.
                            </Form.Text>
                        )}
                        <DialogActionsBar>
                            <Button
                                type="button"
                                variant="outline-secondary"
                                onClick={onClose}
                                disabled={isSubmitting}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="primary"
                                disabled={isSubmitting}
                            >
                                {isSubmitting && (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="output"
                                        aria-hidden="true"
                                    />
                                )}
                                Submit
                            </Button>
                        </DialogActionsBar>
                    </Formik.Form>
                )}
            </Formik.Formik>
        </Dialog>
    );
};

export default UserDisplayNameModal;
