import React, { useEffect } from "react";
import authFetch from "../../../helpers/ApiHelper";

function useOrganisationFile(
    shortName: string | null,
    fileName: string | null,
    fileType: string | null,
    setState: React.Dispatch<React.SetStateAction<string | null>>,
): void {
    useEffect(() => {
        const fetchOrgFile = async (shortName: string, fileName: string) => {
            const url = `/api/organisation/shortname/${shortName}/orgfile?name=`;

            try {
                if (fileName == null) {
                    console.log(
                        `No ${fileType} information available for ${shortName}.`,
                    );
                    return;
                } else {
                    const response = await authFetch(url.concat(fileName));

                    if (!response.ok || response.status === 204) {
                        return;
                    } else if (fileType === "markdown") {
                        const blob = response.blob();

                        blob.then((blob) => blob.text())
                            .then((x) => setState(x))
                            .catch(() =>
                                console.error("Failed to fetch: ", fileName),
                            );
                    } else {
                        const file = await response.blob();
                        const fileUrl = URL.createObjectURL(file);
                        setState(fileUrl);
                    }
                }
            } catch (error) {
                console.error(
                    `Error fetching organisation file ${fileName}: ${error}`,
                );
            }
        };

        if (shortName) {
            fetchOrgFile(shortName, fileName);
        }
    }, [shortName, fileName, fileType, setState]);
}

export default useOrganisationFile;
