import * as React from "react";

interface CustomPagingFooterProps {
    dataCount?: number;
    total?: number;
    loading: boolean;
}

const CustomPagingFooter: React.FC<CustomPagingFooterProps> = ({
    dataCount,
    total,
    loading,
}) => {
    if (dataCount === total && total === 0) return null;

    return (
        <div className="k-pager-wrap k-pager k-widget">
            <div className="k-pager-info k-label">
                {total && !loading && (
                    <>
                        Showing {dataCount} of {total} items.
                    </>
                )}
            </div>
        </div>
    );
};

export default CustomPagingFooter;
