import { push } from "connected-react-router";
import * as React from "react";
import { Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import LinkRow from "../../../../common/components/link-row";
import { DefectDto } from "../../domain/types";

interface Props {
    defect: DefectDto;
    orgShortName: string;
}

const DefectHistory: React.FC<Props> = ({ defect, orgShortName }) => {
    const dispatch = useDispatch();

    const onNavigate = (processId: string, activityId: string) => () => {
        dispatch(
            push(
                `/${orgShortName}/process/${processId}/activity/${activityId}`,
            ),
        );
    };

    const getDate = (date?: Date): number => date?.getTime() ?? 0;

    const activities = defect.associatedActivities
        .slice()
        .sort(
            (a, b) =>
                getDate(new Date(b.examinationDate)) -
                getDate(new Date(a.examinationDate)),
        );

    return (
        <div className="pt-4">
            <hr />
            <h4>Inspection history</h4>
            <Table borderless={true}>
                <tbody>
                    {activities.map((historyRecord) => (
                        <LinkRow
                            key={historyRecord.processActivityId}
                            title={historyRecord.processName}
                            description={
                                <div className="defect-description">
                                    <div className="col">
                                        {historyRecord.name && (
                                            <div>
                                                {`Defect: ${historyRecord.name}`}
                                            </div>
                                        )}
                                        {historyRecord.examinationDate && (
                                            <div>
                                                {`Confirmed: ${new Date(
                                                    historyRecord.examinationDate,
                                                ).toLocaleDateString("en-GB")}`}
                                            </div>
                                        )}
                                    </div>
                                    <div className="col">
                                        <div className="btn btn-primary">
                                            Show details
                                        </div>
                                    </div>
                                </div>
                            }
                            onNavigate={onNavigate(
                                historyRecord.processId,
                                historyRecord.processActivityId,
                            )}
                        />
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default DefectHistory;
