import { PhotoOrder, PhotoOrderSettings } from "./../types";
import consumerApiService from "../../../../customization/api/consumerApiService";
import { deleteData, getData, postData } from "../../../../helpers/ApiHelper";

enum UrlPaths {
    BaseResource = "pdfreportsettings",
}

const getBaseUrl = () =>
    `${consumerApiService.baseApiUrl}/${UrlPaths.BaseResource}`;

export async function getPhotoOrder(
    processId: string,
): Promise<PhotoOrderSettings> {
    const url = `${getBaseUrl()}/${processId}/photo/order`;

    return getData(url);
}

export async function postPhotoOrder({
    processId,
    photoOrder,
}: {
    processId: string;
    photoOrder: PhotoOrder[];
}): Promise<PhotoOrder[]> {
    const url = `${getBaseUrl()}/${processId}/photo/order`;

    return postData(url, photoOrder);
}

export async function deletePhotoOrder({
    processId,
}: {
    processId: string;
}): Promise<PhotoOrder[]> {
    const url = `${getBaseUrl()}/${processId}/photo/order`;

    return deleteData(url);
}
