import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";
import { WithOrgShortName } from "../../../common/types/WithOrg";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import useOrgPermission from "../../../common/hooks/useOrgPermission";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import UserManagmentPageTitles from "./pageTitles";
import { useOrgTitleById } from "../../../common/hooks/useTitle";
import UserGroupTable from "../components/userGroup/UserGroupTable";
import { useGetUserGroupsByOrgIdQuery } from "../../../store/features/organisation/organisation-api-slice";

const UserGroupManagePage: React.FC = () => {
    const { orgShortName } = useParams<WithOrgShortName>();
    const { organisationId } = useOrganisationId();

    const canCreateGroups = useOrgPermission(
        PermissionConstants.OrgUserGroup.create,
    );

    const {
        data: userGroups,
        isLoading,
        isFetching,
    } = useGetUserGroupsByOrgIdQuery(organisationId, {
        skip: !organisationId,
    });

    useOrgTitleById(organisationId, UserManagmentPageTitles.GroupList);

    return (
        <>
            <h1 className="pt-2">User groups</h1>
            <Row>
                <Col>
                    {canCreateGroups && !isLoading && !isFetching && (
                        <Row className="mt-3">
                            <Col>
                                <Button
                                    as={Link}
                                    to={`/${orgShortName}/${OrganisationConstants.userGroups}${OrganisationConstants.actionCreate}`}
                                    className="float-right"
                                >
                                    Create
                                </Button>
                            </Col>
                        </Row>
                    )}
                    <Row className="mt-3">
                        <Col>
                            <UserGroupTable
                                userGroups={userGroups}
                                orgShortName={orgShortName}
                                isLoading={isLoading}
                                isFetching={isFetching}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default UserGroupManagePage;
