import { OptionTypeBase } from "react-select";

export interface SelectOptionItem extends OptionTypeBase {
    value: string;
    label: string;
}

export const mapToSelectOptions = function <T>(
    items: T[],
    getValue: (item: T) => string,
    getLabel: (item: T) => string,
): SelectOptionItem[] {
    return [
        ...(items || []).map((i) => ({
            value: getValue(i),
            label: getLabel(i),
        })),
    ];
};
