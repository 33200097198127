import * as React from "react";
import { Prompt, useHistory } from "react-router-dom";
import ConfirmBox from "../../../common/components/kendo-dialog-boxes/ConfirmBox";

interface FormUnsavedChangesPromptProps {
    hasUnsavedChanges: boolean;
}

export const FormUnsavedChangesPrompt: React.FC<
    FormUnsavedChangesPromptProps
> = ({ hasUnsavedChanges }) => {
    const [showConfirmation, setShowConfirmation] = React.useState(false);
    const [nextLocation, setNextLocation] = React.useState("");
    const history = useHistory();

    const onLocationChange = (nextLocation) => {
        if (!showConfirmation && hasUnsavedChanges) {
            setShowConfirmation(true);
            setNextLocation(nextLocation.pathname);
            return false;
        }
        return true;
    };

    const handleConfirmNavigationClick = () => {
        setShowConfirmation(false);
        history.push(nextLocation);
    };

    React.useEffect(() => {
        const onBeforeUnload = (e) => {
            if (hasUnsavedChanges) {
                e.preventDefault();
                e.returnValue = "";
            }
        };
        window.addEventListener("beforeunload", onBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", onBeforeUnload);
        };
    }, [hasUnsavedChanges]);

    return (
        <>
            <Prompt when={hasUnsavedChanges} message={onLocationChange} />
            {showConfirmation && (
                <ConfirmBox
                    title="Unsaved Changes"
                    message="You have unsaved changes, are you sure you want to leave?"
                    onConfirm={handleConfirmNavigationClick}
                    onCancel={() => setShowConfirmation(false)}
                />
            )}
        </>
    );
};
