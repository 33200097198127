import { ProcessActivity } from "../../process-activity/domain/types";

export interface ProcessActivityArtefactGeneration extends ProcessActivity {
    requests: ProcessActivityArtefactGenerationRequest[];
}

export interface ProcessActivityArtefactGenerationRequest {
    id: string;
    state: ProcessActivityArtefactGenerationState;
    createdBy: string;
    createdOn: string;
}

export enum ProcessActivityArtefactGenerationState {
    Pending = 0,
    InProgress = 1,
    Completed = 2,
    Failed = 3,
}
