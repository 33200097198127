import { TranslateTagFunc } from "../../../../../query/configureQuery";
import { Tags } from "../../../../../store/features/dcpApi";
import { assetCesDataKey } from "./assetCefaQueryKeys";

const tagsToQueryKeys: TranslateTagFunc = (tag) => {
    if (tag.type === Tags.Asset) {
        return assetCesDataKey(tag.id);
    } else {
        return null;
    }
};

export const assetCefaQueryKeysTranslator = {
    tagsToQueryKeys,
};
