import { Grid, GridColumn } from "@progress/kendo-react-grid";
import React from "react";
import CoveringLoadingPanel from "../../../../../common/components/CoveringLoadingPanel";
import { useGetExaminationsCountAggregatedByType } from "../../query/examinationsReviewQueries";

interface Props {
    organisationId: string;
}

const ExaminationsCount = ({ organisationId }: Props): JSX.Element => {
    const { data, isLoading } =
        useGetExaminationsCountAggregatedByType(organisationId);
    if (isLoading) return <CoveringLoadingPanel />;
    if (Object.keys(data.examinationsCount).length === 0) return null;

    const entires = Object.assign({}, data.examinationsCount);

    return (
        <Grid data={[entires]}>
            {Object.keys(entires).map((field, index) => (
                <GridColumn key={index} field={field} title={field} />
            ))}
        </Grid>
    );
};

export default ExaminationsCount;
