import { GridFieldsMap } from "../../../../../common/components/table/kendo/GridFieldsMap";

export const unexaminedPartsGridMap: GridFieldsMap = {
    level1Number: {
        field: "part",
        label: "Part",
        dataType: "string",
    },
    level2Number: {
        field: "reason",
        label: "Reason",
        dataType: "string",
    },
};
