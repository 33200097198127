import { getter } from "@progress/kendo-react-common";
import {
    getSelectedState,
    GridProps,
    GridSelectionChangeEvent,
} from "@progress/kendo-react-grid";
import { GridSelectableSettings } from "@progress/kendo-react-grid/dist/npm/interfaces/GridSelectableSettings";
import React from "react";
import { SelectedState } from "./SelectedState";

const SELECTED_FIELD = "selected";

export enum GridSelectionMode {
    Multiple = "multiple",
    Single = "single",
}

interface HookResult<T> {
    gridProps: Omit<GridProps, "data">;
    selectedState: SelectedState;
    gridData: T[];
}

export interface SelectableSettings<T> {
    config: GridSelectableSettings;
    onSelectChange?: (selected: T[]) => void;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const useGridSelectableProps = <T>(
    data: T[],
    dataItemKey: string,
    selectableSettings?: SelectableSettings<T>,
): HookResult<T> => {
    const [selectedState, setSelectedState] = React.useState<SelectedState>({});
    const idGetter = React.useMemo(() => getter(dataItemKey), [dataItemKey]);

    const config = selectableSettings?.config;
    const selectableEnabled = config?.enabled;
    const onSelectChange = selectableSettings?.onSelectChange;

    React.useEffect(() => {
        if (!data || !selectableEnabled) return;

        const newSelectedState = {};
        data.forEach(
            (item) =>
                (newSelectedState[idGetter(item)] =
                    selectedState[idGetter(item)] || false),
        );

        handleSelectChange(newSelectedState);
    }, [data]);

    const gridSelectableData = React.useMemo(() => {
        if (!data) return [];
        if (!selectableEnabled) return data;

        return data.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)] || false,
        }));
    }, [data, selectedState, selectableEnabled]);

    const onSelectionChange = (event: GridSelectionChangeEvent) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: dataItemKey,
        });

        handleSelectChange(newSelectedState);
    };

    const handleSelectChange = (newSelectedState: SelectedState) => {
        setSelectedState(newSelectedState);

        if (onSelectChange) {
            onSelectChange(
                data.filter((item) => newSelectedState[idGetter(item)]),
            );
        }
    };

    return selectableEnabled
        ? {
              gridProps: {
                  selectedField: SELECTED_FIELD,
                  selectable: config,
                  onSelectionChange: onSelectionChange,
              },
              selectedState,
              gridData: gridSelectableData,
          }
        : { gridProps: {}, selectedState, gridData: gridSelectableData };
};

export default useGridSelectableProps;
