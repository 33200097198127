import { useState, useEffect } from "react";
import authFetch from "../../../helpers/ApiHelper";

function useOrganisationLogo(shortName: string | null): string | null {
    const [orgLogoUrl, setOrgLogoUrl] = useState<string | null>(null);

    useEffect(() => {
        const fetchOrgLogo = async (shortName: string) => {
            const url = `/api/organisation/${shortName}/logo`;

            try {
                const response = await authFetch(url);
                if (!response.ok || response.status === 204) {
                    return;
                }

                const logo = await response.blob();
                const logoUrl = URL.createObjectURL(logo);

                setOrgLogoUrl(logoUrl);
            } catch (error) {
                console.error("Error fetching logo:", error);
            }
        };

        if (shortName) {
            fetchOrgLogo(shortName);
        }
    }, [shortName]);

    return orgLogoUrl;
}

export default useOrganisationLogo;
