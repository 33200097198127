import { isUndefined } from "lodash";
import * as React from "react";
import { QueryResult } from "../types/rtkQuery/QueryResult";
import CoveringLoadingPanel from "./CoveringLoadingPanel";
import { ErrorInfo } from "./ErrorInfo";

interface QueryResultStatusProps<T> {
    queryResult: QueryResult<T>;
}

interface MultiQueryResultStatusProps {
    results: QueryResult<unknown>[];
}

const QueryResultStatus = function <T>({
    queryResult,
}: QueryResultStatusProps<T>): JSX.Element {
    return <MultiQueryResultStatus results={[queryResult]} />;
};

export const MultiQueryResultStatus = ({
    results,
}: MultiQueryResultStatusProps): JSX.Element => {
    const isLoading = results.some((x) => x.isLoading || x.isFetching);
    if (isLoading) {
        return <CoveringLoadingPanel />;
    }

    const withError = results.find((x) => x.error);
    if (withError !== null && !isUndefined(withError)) {
        return (
            <div className="py-2">
                <ErrorInfo error={withError.error} />
            </div>
        );
    }

    return null;
};

export default QueryResultStatus;
