import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import TemplatesList from "../../../template/containers/TemplatesList";
import { useGetFormTemplateByOrgIdQuery } from "../../../../store/features/form-template/formtemplate-api-slice";

const FormTemplateManagement: React.FC = () => {
    const { id: organisationId } = useParams<{ id: string }>();

    return (
        <Row>
            <Col>
                <Row className="top10">
                    <Col>
                        <TemplatesList
                            organisationId={organisationId}
                            getTemplate={useGetFormTemplateByOrgIdQuery}
                            redirectPath="/form/template"
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default FormTemplateManagement;
