import { useMemo } from "react";
import { InfiniteData } from "react-query";
import { DataContinuationResult } from "../types/DataResult";

type HookResult<T> = {
    flatData: T[];
    totalCount: number;
    dataCount: number;
};

const useInfiniteDataResult = <T>(
    data: InfiniteData<DataContinuationResult<T>>,
): HookResult<T> => {
    const flatData = useMemo(() => {
        if (!data) return [];
        return data?.pages?.map((page) => page.data).flat() || [];
    }, [data]);

    const totalCount = useMemo(() => {
        if (!data) return 0;
        return data?.pages[0]?.total || 0;
    }, [data]);

    const dataCount = flatData.length;

    return {
        flatData,
        totalCount,
        dataCount,
    };
};

export default useInfiniteDataResult;
