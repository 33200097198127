import { Grid, GridColumn, GridRowProps } from "@progress/kendo-react-grid";
import cx from "classnames";
import React from "react";
import { Button } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { ColumnMenuFilter } from "../../../../../common/components/table/kendo/columnFilters";
import useGridProps from "../../../../../common/components/table/kendo/useGridProps";
import { TypedGridCellProps } from "../../../../../common/types/TypedGridCellProps";
import { AppRoutes } from "../../../../../router/AppRoutes";
import { useNavigation } from "../../../../../router/useNavigate";
import BatchingStateConstants from "../../BatchingConstants";
import { BatchDetails, BatchExam } from "../../domain/batching";
import { useBatchRemoveExamMutation } from "../../queries/BatchingApiSlice";

type Props = {
    batch: BatchDetails;
    organisationId: string;
};

export const BatchExamsGrid: React.FC<Props> = ({ batch, organisationId }) => {
    const [removeExam] = useBatchRemoveExamMutation();

    const { gridProps } = useGridProps(batch.exams, {
        filterable: true,
        sortable: true,
        pageable: true,
    });

    const { navigateToWithOrg } = useNavigation();

    const ActionsCell = React.useMemo(
        // eslint-disable-next-line react/display-name
        () => (cell: TypedGridCellProps<BatchExam>) =>
            (
                <td>
                    <Button
                        variant="danger"
                        type="button"
                        onClick={() => {
                            removeExam({
                                id: batch.id,
                                organisationId,
                                processId: cell.dataItem.processId,
                            });
                        }}
                    >
                        <FaTimes />
                    </Button>
                </td>
            ),
        [batch, organisationId, removeExam],
    );

    const rowRender = (
        row: React.ReactElement<HTMLTableRowElement>,
        rowProps: GridRowProps,
    ) => {
        const trProps = {
            ...row.props,
            className: rowProps.dataItem.highlightExam
                ? cx("table-danger", row.props.className)
                : row.props.className,
        };

        return React.cloneElement(row, { ...trProps }, row.props.children);
    };

    return (
        <Grid
            {...gridProps}
            onRowDoubleClick={(e) => {
                navigateToWithOrg(AppRoutes.Process, {
                    processId: e.dataItem.processId,
                });
            }}
            className="mt-3"
            rowRender={rowRender}
        >
            <GridColumn
                field="processName"
                title="Name"
                width="auto"
                sortable
                columnMenu={ColumnMenuFilter}
            />
            <GridColumn
                field="processType"
                title="Type"
                width="auto"
                sortable
                columnMenu={ColumnMenuFilter}
            />
            <GridColumn
                field="structureRefNo"
                title="Structure Ref"
                width="auto"
                sortable
                columnMenu={ColumnMenuFilter}
            />
            <GridColumn
                field="daysSinceExamDate"
                title="Days Since Exam Date"
                width="auto"
                sortable
                columnMenu={ColumnMenuFilter}
            />
            {batch.state === BatchingStateConstants.StateDraft && (
                <GridColumn width="60px" cell={ActionsCell} />
            )}
        </Grid>
    );
};
