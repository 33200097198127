import { QueryKey } from "react-query";

export const userRequestedFileKey = (fileId: string): string[] => [
    "userRequestedFile",
    fileId,
];

const userRequestedFileQueryKeys = {
    userRequestedFileKey: (fileId: string): QueryKey =>
        userRequestedFileKey(fileId),
};

export default userRequestedFileQueryKeys;
