import { AccountInfo } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import MutationResultStatus from "./common/components/MutationResultStatus";
import authService from "./modules/authorization/services/AuthService";
import { useGetConfigQuery } from "./store/config/configApi";
import { AuthUserData, setUser } from "./store/features/auth/authUserSlice";
import { useAppDispatch } from "./store/hooks";

export const AuthProvider: React.FC = ({ children }) => {
    const [loaded, setLoaded] = useState(false);
    const dispatch = useAppDispatch();

    const configResult = useGetConfigQuery();

    useEffect(() => {
        const setUserData = (data: AuthUserData) => dispatch(setUser(data));

        const handleSetAccount = (account: AccountInfo): void => {
            if (account) {
                const username = account.username;
                setUserData({ username, displayName: account.name });
            }
        };

        if (!loaded) {
            const account = authService.initialize(
                configResult.data.AzureAdB2C,
                handleSetAccount,
            );
            setLoaded(true);
            handleSetAccount(account);
        }
    }, [configResult.data.AzureAdB2C, dispatch, loaded]);

    if (!configResult.isSuccess) {
        return (
            <MutationResultStatus mutationResult={configResult} showLoading />
        );
    }

    return loaded ? (
        <MsalProvider instance={authService.msalClient}>
            {children}
        </MsalProvider>
    ) : (
        <MutationResultStatus mutationResult={configResult} showLoading />
    );
};
