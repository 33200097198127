/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from "yup";
import FormTemplatesConstants from "../../../../Constants/FormTemplatesConstants";
import { TemplateContent } from "../../../../modules/template/domain/types";
import {
    YupAnySchemaType,
    YupObjectShapeResult,
    YupStringSchemaType,
} from "./YupTypes";
import {
    validatorFileRequired,
    validatorFileNumberLimit,
    validatorFileSizeMax,
    validatorFileTypeMime,
    validatorIsStringBoolean,
    validatorMaxDate,
    validatorMinDate,
    validatorRegex,
} from "./YupValidators";

yup.addMethod(yup.string, "URL", function (this: YupStringSchemaType, ...args) {
    return this.url(...args);
});

yup.addMethod(yup.string, "stringBoolean", validatorIsStringBoolean);
yup.addMethod<YupAnySchemaType>(yup.mixed, "fileSizeMax", validatorFileSizeMax);
yup.addMethod<YupAnySchemaType>(
    yup.mixed,
    "fileRequired",
    validatorFileRequired,
);
yup.addMethod<YupAnySchemaType>(
    yup.mixed,
    "fileNumberLimit",
    validatorFileNumberLimit,
);
yup.addMethod<YupAnySchemaType>(
    yup.mixed,
    "fileMimeTypeValid",
    validatorFileTypeMime,
);
yup.addMethod<YupAnySchemaType>(yup.mixed, "minDate", validatorMinDate);
yup.addMethod<YupAnySchemaType>(yup.mixed, "maxDate", validatorMaxDate);
yup.addMethod<YupAnySchemaType>(yup.mixed, "regex", validatorRegex);

function initiateConditionalValidations(
    conditionalValidations: any,
    schema: any,
    field: string,
) {
    conditionalValidations.forEach(({ conditions, params }: any) => {
        conditions.forEach((condition: any) => {
            const { field: dependencyFieldName, operator, value } = condition;

            // when more operators will be in use change below "if" to "switch"
            if (
                operator === FormTemplatesConstants.Conditions.Operator.Equals
            ) {
                const message = params[0] ?? "Required";
                schema[field] = schema[field].when(dependencyFieldName, {
                    is: (val: any) => val === value,
                    then: (schemaRef: any) => schemaRef.required(message),
                    otherwise: (schemaRef: any) => schemaRef.notRequired(),
                });
            }
        });
    });
}

function AddSpecialCharacterValidation(
    staticValidations: any,
    type: string,
): any {
    if (
        type === FormTemplatesConstants.ContentType.Text ||
        type === FormTemplatesConstants.ContentType.TextArea
    ) {
        const specialCharacterValidation = {
            params: [
                // eslint-disable-next-line no-control-regex
                /^[^\x00-\x09\x0B-\x0c\x0E-\x1F\x7F]*$/,
                "ASCII Control Characters other than new line are not supported.",
            ],
            type: "matches",
        };
        staticValidations.push(specialCharacterValidation);
    }
}

function AddStaticValidations(staticValidations: any, type: string): any {
    // Special character validations for Text and TextArea inputs
    AddSpecialCharacterValidation(staticValidations, type);
}

// Create schema based on config
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function createYupSchema(schema: any, config: any): any {
    const { field, type, validationType, validations, readOnly = [] } = config;

    if (readOnly) return schema;

    const staticValidations: any[] = [];

    AddStaticValidations(staticValidations, type);

    const conditionalValidations: any[] = [];

    validations.forEach((v: any) => {
        if (v.conditions?.length > 0) {
            conditionalValidations.push(v);
            return;
        }
        staticValidations.push(v);
    });

    const yupMethod = validationType as keyof typeof yup;

    // If type media skip validation as that will be handled by sub form
    if (config.type === "media") {
        return schema;
    }

    if (!yupMethod || !yup[yupMethod]) {
        return schema;
    }

    let validatorLocal = (yup[yupMethod] as any)();
    staticValidations?.forEach((validation: any) => {
        const { params, type: yuptype } = validation;

        if (!validatorLocal[yuptype]) {
            return;
        }

        validatorLocal = validatorLocal[yuptype](...params);
    });

    if (config.type === "table" && config.validationType === "array") {
        // Builds nested validations for tables
        const nestedSchema = config.columns.reduce(createYupSchema, {});
        const arraySchema = yup.array(yup.object().shape(nestedSchema));
        const mergedLocalAndInnerSchema = arraySchema.concat(validatorLocal);

        schema[field] = mergedLocalAndInnerSchema;
        return schema;
    }

    if (field.indexOf(".") !== -1) {
        console.log(""); // nested fields are not covered in this example but are easy to handle though
        // (not tried CM)
    } else {
        // Add a when condition to each validation rule, it is only action when not a hidden field
        schema[field] = (yup[yupMethod] as any)().when(
            "$hiddenFields",
            (hiddenFields: string[], innerSchema: any) => {
                // Use the hidden field additional context to determine if this validation rule is active

                return hiddenFields.includes(config.field)
                    ? innerSchema
                    : validatorLocal;
            },
        );

        initiateConditionalValidations(conditionalValidations, schema, field);
    }
    // Cast validation type and check existence

    return schema;
}

// Pass meta data, returns validation schema
export const getYupSchemaFromMetaData = (
    metadata: TemplateContent[],
    additionalValidations: [],
    forceRemove: string[],
): YupObjectShapeResult => {
    // For all config produce schema and merge
    const yepSchema = metadata.reduce(createYupSchema, {});
    const mergedSchema = {
        ...yepSchema,
        ...additionalValidations,
    };

    forceRemove.forEach((field: string) => {
        delete mergedSchema[field];
    });

    // Create yup object from merged schema
    return yup.object().shape(mergedSchema);
};

const createYupSchemaFromSubFormConfig = (schema: any, config: any): any => {
    const { field, validationType, validations = [] } = config;

    // Cast validation type and check existence
    const yupMethod = validationType as keyof typeof yup;

    if (!yupMethod || !yup[yupMethod]) {
        return schema;
    }

    let validator = (yup[yupMethod] as any)();
    validations?.forEach((validation: any) => {
        const { params, type } = validation;
        const yuptype = type;
        if (!validator[yuptype]) {
            return;
        }
        validator = validator[yuptype](...params);
    });
    if (field.indexOf(".") !== -1) {
        console.log(""); // nested fields are not covered in this example but are easy to handle though
        // (not tried CM)
    } else {
        schema[field] = validator;
    }

    return schema;
};

export const generateYupForSubForm = (
    metadata: TemplateContent[],
): YupObjectShapeResult => {
    const yupSchema = metadata.reduce(createYupSchemaFromSubFormConfig, {});
    return yup.object().shape(yupSchema);
};
