import * as React from "react";
import "./examinationApproval.scss";
import cx from "classnames";
import { useGetOrgByShortNameQuery } from "../../../../../store/features/organisation/organisation-api-slice";
import { useGetActivityByIdQuery } from "../../../../process-activity-approval/store/processActivityApprovalApiSlice";
import { useAppSelector } from "../../../../../store/hooks";
import ProcessActivityApprovalContent from "../../../../process-activity-approval/components/ProcessActivityApprovalContent";
import { Button, Card, Collapse } from "react-bootstrap";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

interface Props {
    activityId: string;
    processId: string;
    orgShortName: string;
}

const ExaminationApproval: React.FC<Props> = ({
    activityId,
    processId,
    orgShortName,
}) => {
    const [approveOpen, setApproveOpen] = React.useState(true);
    const { data: organisation } = useGetOrgByShortNameQuery(orgShortName);
    const userName = useAppSelector((state) => state.authUser?.username);

    const { data: activityApproval } = useGetActivityByIdQuery({
        activityId,
        processId,
    });

    let headerCss = "activity-header";

    switch (true) {
        case activityApproval?.statusType === "Complete":
            headerCss = cx(headerCss, "complete");
            break;
        case activityApproval?.statusType === "NotApplicable":
            headerCss = cx(headerCss, "na");
            break;
        default:
            headerCss = cx(headerCss, "open");
            break;
    }

    const activeUserApproval = React.useMemo(
        () =>
            activityApproval?.approvals.find(
                (a) =>
                    a.approver.userEmail.toLowerCase() ===
                    userName.toLowerCase(),
            ),
        [activityApproval, userName],
    );

    return (
        <Card>
            <Card.Header className={headerCss}>
                <Button
                    onClick={() => setApproveOpen(!approveOpen)}
                    className="mr-2 p-0"
                    variant="link"
                    style={{ color: "#35373d" }}
                    aria-controls="approveOpen"
                    aria-expanded={approveOpen}
                >
                    {approveOpen ? <FaAngleUp /> : <FaAngleDown />}
                </Button>
                <span>Report Actions</span>
            </Card.Header>
            <Collapse in={approveOpen}>
                <Card.Body>
                    {activityApproval && activeUserApproval && (
                        <ProcessActivityApprovalContent
                            organisationId={organisation.id}
                            activity={activityApproval}
                            approvalForCurrentUser={activeUserApproval}
                            approversDisplayName={
                                activityApproval.approversDisplayName
                            }
                            approvalTitle={activityApproval.approvalDisplayName}
                        />
                    )}
                </Card.Body>
            </Collapse>
        </Card>
    );
};

export default ExaminationApproval;
