import React, { useCallback } from "react";
import {
    useInfiniteQuery,
    UseInfiniteQueryResult,
    useQuery,
    UseQueryResult,
} from "react-query";
import { DataContinuationResult } from "../../../common/types/DataResult";
import {
    FilterModel,
    SortParam,
} from "../../../common/types/SortAndFilterOptions";
import { postData } from "../../../helpers/ApiHelper";
import { AssetWithPropsModel } from "../../../store/features/asset/asset-api-slice";
import {
    baseUrl,
    getAssetsContinuation,
    UrlPaths,
} from "../services/assetsService";
import assetQueryKeys from "./assetQueryKeys";

export interface AssetSortAndFilter {
    sortParam?: SortParam;
    filterParams: AssetFilterModel[];
    assetTemplates: string[];
}
export interface AssetFilterModel extends FilterModel {
    isCustomField: boolean;
}

type AssetType = DataContinuationResult<AssetWithPropsModel>;
type ErrorType = [string, { page: number }];

export const useGetInfiniteOrgAssets = (
    organisationId: string,
    sortAndFilter: AssetSortAndFilter,
): UseInfiniteQueryResult<DataContinuationResult<AssetWithPropsModel>> => {
    const assetTemplatesString = JSON.stringify(sortAndFilter.assetTemplates);
    const filtersString = JSON.stringify(sortAndFilter.filterParams);

    const getAssets = useCallback(
        ({
            pageParam = null,
        }): Promise<DataContinuationResult<AssetWithPropsModel>> => {
            const continuationToken = pageParam;

            return getAssetsContinuation({
                continuationToken,
                ...sortAndFilter,
                organisationId,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [organisationId, assetTemplatesString, filtersString],
    );

    const keys = React.useMemo(
        () => assetQueryKeys.orgAssets(organisationId, sortAndFilter),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [organisationId, assetTemplatesString, filtersString],
    );

    const options = {
        getNextPageParam: (lastPage) => {
            // eslint-disable-next-line no-undefined
            return lastPage.continuationToken || undefined;
        },
    };

    return useInfiniteQuery<AssetType, ErrorType>(keys, getAssets, options);
};

export const useGetInfiniteOrgAssetsCount = (
    organisationId: string,
    sortAndFilter: AssetSortAndFilter,
): UseQueryResult<number> => {
    const url = `${baseUrl}/${organisationId}/${UrlPaths.Assets}/count`;

    const assetTemplatesString = JSON.stringify(sortAndFilter.assetTemplates);
    const filtersString = JSON.stringify(sortAndFilter.filterParams);

    const getAssetsCount = useCallback((): Promise<number> => {
        return postData<number>(url, sortAndFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, assetTemplatesString, filtersString]);

    const keys = React.useMemo(
        () => assetQueryKeys.orgAssetsCount(organisationId, sortAndFilter),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [organisationId, assetTemplatesString, filtersString],
    );

    return useQuery(keys, getAssetsCount);
};
