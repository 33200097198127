export const createLink = (to: string, params: unknown): string =>
    Object.keys(params).reduce(
        (str, key) => str.replace(`:${key}`, params[key]),
        to,
    );

export class AppRoute<T = unknown> {
    public path: string;

    constructor(protected featureRouteLink: string) {
        this.path = `/${featureRouteLink}`;
    }

    toLink = (params?: T): string => {
        return params ? createLink(this.path, params) : this.path;
    };
}

export interface OrganisationLinkParams {
    orgShortName: string;
}

export class OrganisationRoute<
    T extends OrganisationLinkParams = OrganisationLinkParams,
> extends AppRoute<T> {
    public path: string;

    constructor(protected featureRouteLink: string) {
        super(featureRouteLink);
        this.path = `/:orgShortName/${featureRouteLink}`;
    }
}
