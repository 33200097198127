import { useField } from "formik";
import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { ApcIncidentAsset } from "../domain/types";

const ViewAssetFormInput: React.FC = () => {
    const [field] = useField<ApcIncidentAsset>("apcAssetDetails");

    return (
        <Row>
            <Col xs={1}>
                <Form.Group>
                    <Form.Label>{"ELR"}</Form.Label>
                    <Form.Control
                        type="text"
                        name="apcAssetDetails.elr"
                        readOnly={true}
                        value={field.value?.elr}
                        plaintext={true}
                    />
                </Form.Group>
            </Col>
            <Col xs={1}>
                <Form.Group>
                    <Form.Label>{"Miles"}</Form.Label>
                    <Form.Control
                        type="text"
                        name="apcAssetDetails.miles"
                        readOnly={true}
                        value={field.value?.miles}
                        plaintext={true}
                    />
                </Form.Group>
            </Col>
            <Col xs={1}>
                <Form.Group>
                    <Form.Label>{"Yards"}</Form.Label>
                    <Form.Control
                        type="text"
                        name="apcAssetDetails.yards"
                        readOnly={true}
                        value={field.value?.yards}
                        plaintext={true}
                    />
                </Form.Group>
            </Col>
            <Col xs={1}>
                <Form.Group>
                    <Form.Label>{"Chains"}</Form.Label>
                    <Form.Control
                        type="text"
                        name="apcAssetDetails.chains"
                        readOnly={true}
                        value={field.value?.chains}
                        plaintext={true}
                    />
                </Form.Group>
            </Col>
            <Col xs={1}>
                <Form.Group>
                    <Form.Label>{"Asset Type"}</Form.Label>
                    <Form.Control
                        type="text"
                        name="apcAssetDetails.assetType"
                        readOnly={true}
                        value={field.value?.assetType}
                        plaintext={true}
                    />
                </Form.Group>
            </Col>
            <Col xs={"auto"}>
                <Form.Group>
                    <Form.Label>{"Structure Ref No"}</Form.Label>
                    <Form.Control
                        type="text"
                        name="apcAssetDetails.structureRefNum"
                        readOnly={true}
                        value={field.value?.structureRefNum}
                        plaintext={true}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label>{"Structure Name"}</Form.Label>
                    <Form.Control
                        type="text"
                        name="apcAssetDetails.structureName"
                        readOnly={true}
                        value={field.value?.structureName}
                        plaintext={true}
                    />
                </Form.Group>
            </Col>
        </Row>
    );
};

export default ViewAssetFormInput;
