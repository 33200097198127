export interface GridFieldDef {
    field: string;
    label?: string;
    dataType?: "number" | "string" | "Date";
    isCustomField?: boolean;
    fieldType?: GridFieldType;
    filterable?: boolean;
    filterType?: "boolean" | "text" | "numeric" | "date";
    sortable?: boolean;
    width?: number;
}

export enum GridFieldType {
    Text,
    Date,
    Number,
    MultiText,
    MultiTextContains,
    ExamStatus,
    ProcessStateMultiSelect,
}

export type GridFieldsMap = Record<string, GridFieldDef>;
