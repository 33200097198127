import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ChunkProgressBar } from "@progress/kendo-react-progressbars";
import * as React from "react";
import { Button } from "react-bootstrap";

interface ProgressDialogProps {
    count: number;
    doneCount: number;
    onClose: () => void;
}

const maxCount = 50;

const ProgressDialog: React.FC<ProgressDialogProps> = ({
    count,
    doneCount,
    onClose,
}) => {
    const limitedCount = Math.min(maxCount, count);

    const value = (100 * doneCount) / count;

    return (
        <Dialog title={"Uploading folder"} onClose={onClose}>
            <div>
                <ChunkProgressBar value={value} chunkCount={limitedCount} />
                <span className="ml-2">
                    {doneCount}/{count}
                </span>
            </div>
            <DialogActionsBar>
                <div className="d-flex flex-row-reverse">
                    <Button variant="primary" onClick={onClose}>
                        Stop
                    </Button>
                </div>
            </DialogActionsBar>
        </Dialog>
    );
};

export default ProgressDialog;
