import { QueryKey } from "react-query";
import { AssetSortAndFilter } from "./assetQueries";

export const orgAssetsKey = (organisationId: string): string[] => [
    "assets",
    organisationId,
];
export const activityAssetsKey = (activityId: string): string[] => [
    "activity",
    activityId,
];

const assetQueryKeys = {
    orgAllAssets: (organisationId: string): QueryKey =>
        orgAssetsKey(organisationId),
    orgAssets: (
        organisationId: string,
        sortAndFilter: AssetSortAndFilter,
    ): QueryKey => [
        ...orgAssetsKey(organisationId),
        JSON.stringify(sortAndFilter),
    ],
    orgAssetsCount: (
        organisationId: string,
        sortAndFilter: AssetSortAndFilter,
    ): QueryKey => [
        ...orgAssetsKey(organisationId),
        "count",
        JSON.stringify(sortAndFilter.filterParams),
        JSON.stringify(sortAndFilter.assetTemplates),
    ],
    OrgAssetsLinkCount: (
        organisationId: string,
        activityId: string,
        sortAndFilter: AssetSortAndFilter,
    ): QueryKey => [
        ...orgAssetsKey(organisationId),
        "count",
        activityId,
        JSON.stringify(sortAndFilter.filterParams),
        JSON.stringify(sortAndFilter.assetTemplates),
    ],
    orgActivityAssets: (
        organisationId: string,
        activityId: string,
        sortAndFilter: AssetSortAndFilter,
    ): QueryKey => [
        ...orgAssetsKey(organisationId),
        ...activityAssetsKey(activityId),
        JSON.stringify(sortAndFilter),
    ],
};

export default assetQueryKeys;
