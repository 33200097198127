import * as React from "react";

export const CenteredCell = (
    props: React.TdHTMLAttributes<HTMLTableCellElement>,
): JSX.Element => {
    return (
        <td {...props}>
            <div className="d-flex align-items-center justify-content-center">
                {props.children}
            </div>
        </td>
    );
};
