import * as React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

interface Props {
    items: [string, string?][];
}
const Breadcrumbs: React.FC<Props> = ({ items }) => {
    return (
        <Breadcrumb>
            {items.map(([name, link]) =>
                link ? (
                    <Breadcrumb.Item key={name} linkAs={"span"}>
                        <Link to={link}>{name}</Link>
                    </Breadcrumb.Item>
                ) : (
                    <Breadcrumb.Item key={name} active>
                        {name}
                    </Breadcrumb.Item>
                ),
            )}
        </Breadcrumb>
    );
};

export default Breadcrumbs;
