import * as React from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { formatIsoDate } from "../../../helpers/dateTimeHelpers";

interface Props {
    name: string;
    description: string;
    id: string;
    lastUpdatedAt: string;
    version: number;
    redirectPath: string;
}

const TemplatesListItem: React.FC<Props> = (props) => {
    return (
        <div className="card bottom10">
            <div className="card-body">
                <h4 className="card-title">{props.name}</h4>
                <Card.Subtitle className="mb-2 text-muted">
                    Version: {props.version}{" "}
                </Card.Subtitle>
                <Card.Subtitle className="mb-2 text-muted">
                    Last Updated: {formatIsoDate(props.lastUpdatedAt)}
                </Card.Subtitle>
                <p className="card-text">{props.description}</p>
                <Link
                    to={`${props.redirectPath}/${props.id}`}
                    className="card-link"
                >
                    View
                </Link>
            </div>
        </div>
    );
};
export default TemplatesListItem;
