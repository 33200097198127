import React from "react";
import { Button } from "react-bootstrap";
import { ExaminationSearchDto } from "../../domain/examinationDto";
import { downloadFile } from "../../../../../services/FileDownloadService";
import { FaDownload } from "react-icons/fa";
import { TypedGridCellProps } from "../../../../../common/types/TypedGridCellProps";

const ExamDownload: React.FC<TypedGridCellProps<ExaminationSearchDto>> = (
    cellProps,
) => {
    const exam = cellProps.dataItem;

    const handleDownload = (storageName: string, fileName: string) => {
        const url = `api/process/report-file/${storageName}/${fileName}`;
        downloadFile(url, fileName).catch(() => {
            console.error("failed to download file");
        });
    };

    return (
        <div className="d-flex justify-content-center py-2">
            {exam.reportStorageName != null && (
                <Button
                    variant="primary"
                    onClick={() => {
                        handleDownload(exam.reportStorageName, exam.fileName);
                    }}
                >
                    <FaDownload />
                </Button>
            )}
        </div>
    );
};

export default ExamDownload;
