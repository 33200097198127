import { State } from "@progress/kendo-data-query";
import { GridColumnProps } from "@progress/kendo-react-grid";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GridColumnDef } from "../assets-grid/assetsGridSlice";

export interface ExaminationReviewGridState {
    gridState?: State;
    gridColumnProps?: GridColumnProps[];
    gridSelectedColumns?: GridColumnDef[];
}

const initialState: ExaminationReviewGridState = {
    gridState: {},
    gridColumnProps: [],
    gridSelectedColumns: [],
};

export const examinationReviewGridFiltersSlice = createSlice({
    name: "examinationReviewGridFilters",
    initialState,
    reducers: {
        setExaminationReviewSelectedColumns: (
            state,
            action: PayloadAction<GridColumnDef[]>,
        ) => {
            state.gridSelectedColumns = action.payload;
        },
        setExaminationReviewColumnProps: (
            state,
            action: PayloadAction<GridColumnProps[]>,
        ) => {
            state.gridColumnProps = action.payload;
        },
        setExaminationReviewGridState: (
            state,
            action: PayloadAction<State>,
        ) => {
            state.gridState = action.payload;
        },
    },
});

export const {
    setExaminationReviewGridState,
    setExaminationReviewColumnProps,
    setExaminationReviewSelectedColumns,
} = examinationReviewGridFiltersSlice.actions;

export const reducer = examinationReviewGridFiltersSlice.reducer;
