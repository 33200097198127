import React, { useEffect, useState } from "react";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
    useGetOrgByIdQuery,
    useGetUserGroupByIdQuery,
    useGetUsersByOrgIdQuery,
} from "../../../store/features/organisation/organisation-api-slice";
import { Permission } from "../../organisation/domain/types";
import { UserGroupPermissions } from "../components/permission/UserGroupPermissions";
import { UserGroupUsers } from "../components/userGroup/UserGroupUsers";
import { useOrgTitleById } from "../../../common/hooks/useTitle";
import UserManagmentPageTitles from "./pageTitles";
import AuditActionsList from "../../../common/components/audit/AuditActionsList";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";
import UpdateUserGroupForm from "../components/UpdateUserGroupForm";

export const UserGroupPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { organisationId } = useOrganisationId();
    const [orgPermissions, setOrgPermissions] = useState([] as Permission[]);
    const {
        data: userGroup,
        isFetching,
        isLoading,
    } = useGetUserGroupByIdQuery({ orgId: organisationId, id: id });

    const { data: organisation, isLoading: orgLoading } = useGetOrgByIdQuery(
        organisationId,
        {
            skip: !organisationId,
        },
    );

    const { data: users } = useGetUsersByOrgIdQuery(organisationId, {
        skip: !organisationId,
    });

    useOrgTitleById(organisationId, UserManagmentPageTitles.GroupView);

    useEffect(() => {
        if (!organisation) {
            return;
        }

        setOrgPermissions(organisation.permissions);
    }, [organisation]);

    useEffect(() => {
        if (!users) {
            return;
        }
    }, [users]);

    return isLoading || orgLoading ? (
        <>
            <span>Loading...</span>
        </>
    ) : (
        <>
            <h1 className="top30">User group</h1>
            <Row>
                <Col>
                    <UpdateUserGroupForm
                        orgId={organisationId}
                        userGroup={userGroup}
                    />
                </Col>
            </Row>
            <Container fluid>
                <Row className="top30" style={{ flexDirection: "column" }}>
                    <Tabs defaultActiveKey="users">
                        <Tab eventKey="users" title="Users">
                            <UserGroupUsers
                                orgId={organisationId}
                                userGroupId={id}
                                addedUsers={userGroup.users}
                                orgUsers={users}
                                isFetching={isFetching}
                                isLoading={isLoading}
                            />
                        </Tab>
                        <Tab eventKey="permissions" title="Permissions">
                            <UserGroupPermissions
                                orgId={organisationId}
                                userGroupId={id}
                                addedPermissions={userGroup.permissions}
                                orgPermissions={orgPermissions}
                                isFetching={isFetching}
                                isLoading={isLoading}
                            />
                        </Tab>
                        <Tab eventKey="history" title="History">
                            <AuditActionsList
                                auditActions={userGroup?.auditActions || []}
                            />
                        </Tab>
                    </Tabs>
                </Row>
            </Container>
        </>
    );
};
