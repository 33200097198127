import { Alert, Col, Row } from "react-bootstrap";
import { DateTimeSelect, TextArea } from "../../../../common/components/form";
import React from "react";

const CompleteControl: React.FC = () => {
    return (
        <>
            <h2>Incident Completion</h2>
            <Row>
                <Col>
                    <Alert variant="primary">
                        <Alert.Heading>
                            NOW RING NR TO INFORM THEM THAT INCIDENT IS CLEARED
                        </Alert.Heading>
                    </Alert>
                </Col>
            </Row>
            <Row>
                <Col xs={"auto"}>
                    <DateTimeSelect
                        label="Time Incident Cleared"
                        name="incidentClearedAt"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <TextArea
                        label="Incident Notes"
                        name="incidentNotes"
                        rows={5}
                    />
                </Col>
            </Row>
        </>
    );
};

export default CompleteControl;
