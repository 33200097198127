import { GridRowClickEvent } from "@progress/kendo-react-grid";
import { CallHistoryMethodAction, push } from "connected-react-router";
import * as React from "react";
import { Badge, Breadcrumb } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import QueryResultStatus from "../../../common/components/QueryResultStatus";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { useSearchFilesAndFoldersQuery } from "../../../store/features/file/fileApiSlice";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";
import { FileAndFoldersGrid } from "../components/FileAndFoldersGrid";
import FileSearch from "../components/FileSearch";
import {
    buildFormFromQuery,
    checkValidSearch,
    mapSeachToViewEntry,
} from "../viewModel/FileSearch";
import {
    EntryType,
    FileViewEntry,
    GroupedFileViewEntries,
    isContextMenuNeeded,
    WithDataItem,
} from "../viewModel/FileViewEntry";
import { useGetOrgByShortNameQuery } from "../../../store/features/organisation/organisation-api-slice";
import { emptyFilterDescriptor } from "../../../common/components/table/kendo/columnFilters";
import { useContextMenuState } from "../hooks/useContextMenu";
import { FileOrFolderContextMenu } from "../components/FileOrFolderContextMenu";
import { downloadFile, openPdfInTab } from "../services/downloadFile";
import { FileRouteParams } from "../viewModel/FileRouteParams";
import useIsClient from "../../../common/hooks/useIsClient";
import SbimConstants from "../../sbim/constants/SbimConstants";

const searchColumnConfig = {
    dateModified: true,
    location: true,
    menu: true,
};
const downloadClick =
    (folder: string, organisationId: string) =>
    async ({ dataItem }: WithDataItem): Promise<void> => {
        openPdfInTab({
            fileId: dataItem.id,
            fileName: dataItem.label,
            folderId: dataItem.parentId,
            organisationId: organisationId,
        });
    };
const navigateToResult =
    (
        dispatch: React.Dispatch<CallHistoryMethodAction<string[]>>,
        orgShortName: string,
        orgId: string,
        folderId: string,
        directDownload: boolean,
    ) =>
    (event: GridRowClickEvent): void => {
        const consts = OrganisationConstants;
        const { id, parentId, type } = event.dataItem as FileViewEntry;

        switch (type) {
            case EntryType.Folder:
                dispatch(push(`/${orgShortName}/${consts.folder}/${id}`));
                break;
            case EntryType.File:
                directDownload
                    ? downloadClick(
                          folderId,
                          orgId,
                      )({ dataItem: event.dataItem })
                    : dispatch(
                          push(
                              `/${orgShortName}/${consts.folder}/${parentId}/${consts.file}/${id}`,
                          ),
                      );
        }
    };

const FileSearchPage: React.FC = (): JSX.Element => {
    const { orgShortName } = useParams<{ orgShortName: string }>();
    const { folderId } = useParams<FileRouteParams>();
    const location = useLocation();
    const dispatch = useDispatch();
    const isSbim = useIsClient(SbimConstants.ClientName);
    const { data: organisation } = useGetOrgByShortNameQuery(orgShortName, {
        skip: !orgShortName,
    });
    const [filter, setFilter] = React.useState(emptyFilterDescriptor);
    const { organisationId, isLoadingOrgId } = useOrganisationId();
    const currentQuery = React.useMemo(
        () => buildFormFromQuery(location.search),
        [location.search],
    );

    const isValidSearch = checkValidSearch(currentQuery);

    const { data, ...searchQueryResult } = useSearchFilesAndFoldersQuery(
        { organisationId: organisationId, query: currentQuery },
        { skip: isLoadingOrgId || !isValidSearch },
    );

    const entries = React.useMemo(
        (): GroupedFileViewEntries => ({
            fileEntries: data
                ? data.map((pFile) => mapSeachToViewEntry(pFile))
                : [],
            virtualEntries: [],
            folderEntries: [],
        }),
        [data],
    );

    const { offset, dataItem, closeContextMenu, rowRender } =
        useContextMenuState(isContextMenuNeeded(true));

    return (
        <>
            <div className="pt-4">
                <Breadcrumb>
                    <Breadcrumb.Item linkAs="span">
                        <Link
                            to={`/${orgShortName}/${OrganisationConstants.folder}`}
                        >
                            {organisation?.features?.files?.sideMenuName ??
                                "files"}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Search</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="pt-4">
                <FileSearch
                    initialQuery={currentQuery}
                    organisation={organisation}
                />
            </div>
            <div className="pt-4">
                {isValidSearch ? (
                    <h5>
                        Showing results for{" "}
                        <Badge variant="primary">{currentQuery.name}</Badge>
                    </h5>
                ) : (
                    <h5>
                        Select search query to look for the{" "}
                        {organisation?.features?.files?.sideMenuName ?? "files"}
                    </h5>
                )}
            </div>
            {isValidSearch && (
                <div className="pt-4">
                    <QueryResultStatus queryResult={searchQueryResult} />
                    {data && (
                        <>
                            <FileAndFoldersGrid
                                key={location.search}
                                entries={entries}
                                filterState={[filter, setFilter]}
                                filterByContains
                                onRowClick={navigateToResult(
                                    dispatch,
                                    organisation.shortName,
                                    organisation.id,
                                    folderId,
                                    isSbim,
                                )}
                                rowRender={rowRender}
                                additionalColumns={searchColumnConfig}
                                gridEmptyText="There are no results that match your search."
                                viewMetadata={null}
                                organisation={organisation}
                            />
                            <FileOrFolderContextMenu
                                folderId={folderId}
                                dataItem={dataItem}
                                offset={offset}
                                close={closeContextMenu}
                                canDeleteFiles={false}
                                canUpdateFiles={false}
                                canMoveFiles={false}
                                canDeleteFolders={false}
                                canUpdateFolders={false}
                                canMoveFolders={false}
                                canViewMetadata={false}
                                organisation={organisation}
                                onEditClick={null}
                                onDeleteFolderClick={() => false}
                                onDeleteFileClick={() => false}
                                onDownloadClick={() =>
                                    downloadFile({
                                        fileId: dataItem.id,
                                        fileName: dataItem.label,
                                        folderId: dataItem.parentId,
                                        organisationId: organisation.id,
                                    })
                                }
                            />
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default FileSearchPage;
