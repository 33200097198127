import {
    GridFieldsMap,
    GridFieldType,
} from "../../../../../common/components/table/kendo/GridFieldsMap";

export const examManagementGridFieldsMap: GridFieldsMap = {
    processId: {
        field: "processId",
        label: "Process Id",
        dataType: "string",
        fieldType: GridFieldType.MultiText,
    },
    carrsGUID: {
        field: "carrsGUID",
        label: "CARRS GUID",
        dataType: "string",
        fieldType: GridFieldType.MultiText,
    },
    carrsExamId: {
        field: "carrsExamId",
        label: "CARRS Exam ID",
        dataType: "string",
        fieldType: GridFieldType.MultiText,
    },
    assetType: {
        field: "assetType",
        label: "Asset Type",
        dataType: "string",
        fieldType: GridFieldType.MultiText,
    },
    contractYear: {
        field: "contractYear",
        label: "Contract Year",
        dataType: "string",
    },
    elr: {
        field: "elr",
        label: "ELR",
        dataType: "string",
    },
    miles: {
        field: "miles",
        label: "Miles",
        dataType: "string",
    },
    structureRefNo: {
        field: "structureRefNum",
        label: "Structure Ref No",
        dataType: "string",
        fieldType: GridFieldType.MultiText,
    },
    processState: {
        field: "processState",
        label: "State",
        dataType: "string",
        fieldType: GridFieldType.ProcessStateMultiSelect,
    },
    processTemplateName: {
        field: "processTemplateName",
        label: "Report Type",
        dataType: "string",
        fieldType: GridFieldType.MultiTextContains,
    },
};

export const gridFilterOperators = {
    text: [
        {
            text: "grid.filterContainsOperator",
            operator: "contains",
        },
    ],
    date: [
        {
            text: "grid.filterEqOperator",
            operator: "eq",
        },
    ],
};
