import { QueryKey } from "react-query";

export const key = "pdf-report-settings";

const pdfReportSettingsQueryKeys = {
    photoOrder: (processId: string): QueryKey => [
        key,
        processId,
        "photo",
        "order",
    ],
};

export default pdfReportSettingsQueryKeys;
