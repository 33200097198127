import ProcessTemplatesConstants from "../Constants/ProcessTemplateConstants";
import authFetch from "../helpers/ApiHelper";
import { ProcessTemplate } from "../modules/process-template/domain/types";

const ProcessTemplateService = {
    GetById: (templateID: string): Promise<ProcessTemplate> => {
        return authFetch(
            `${ProcessTemplatesConstants.ApiEndpoint}/${templateID}`,
        ).then((response) => response.json() as Promise<ProcessTemplate>);
    },
    GetByOrgId: (organisationId: string): Promise<ProcessTemplate[]> => {
        const params = { organisationId: organisationId };
        const searchParams = new URLSearchParams(params).toString();
        return authFetch(
            `${ProcessTemplatesConstants.ApiEndpoint}?${searchParams}`,
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw Error(`${response.status} - ${response.statusText}`);
                }
            })
            .then((data) => {
                return data as Promise<ProcessTemplate[]>;
            });
    },
};

export default ProcessTemplateService;
