import { ProcessType } from "../domain/types";
import ProcessConstants from "../../../Constants/ProcessConstants";
import { ProcessActivityType } from "../../process-activity/domain/types";
import { TemplateTypes } from "../../../common/types/TemplateTypes";
import OrganisationConstants from "../../../Constants/OrganisationConstants";

type Item =
    | { type: ProcessType.Process; id: string }
    | { type: TemplateTypes.Asset; id: string }
    | { type: ProcessActivityType; id: string; parentId: string };

export function getUrlForAssignedItem(
    orgShortName: string,
    item: Item,
): string {
    switch (item.type) {
        case ProcessType.Process:
            return `/${orgShortName}${ProcessConstants.ResourceName}/${item.id}`;
        case TemplateTypes.Asset:
            return `/${orgShortName}${OrganisationConstants.Asset}/${item.id}`;
        case ProcessActivityType.Form:
        case ProcessActivityType.List:
        case ProcessActivityType.Group:
        case ProcessActivityType.Approval:
            return `/${orgShortName}${ProcessConstants.ResourceName}/${item.parentId}${ProcessConstants.Activity}/${item.id}`;
        default:
            throw new Error(`No url mapping for type: ${item.type}`);
    }
}
