import { State } from "@progress/kendo-data-query";
import React from "react";
import { AssetSortAndFilter } from "../../modules/asset/query/assetQueries";
import { AssetTemplateModel } from "../../store/features/asset/asset-api-slice";
import { GridColumnDef } from "../../store/features/assets-grid/assetsGridSlice";
import {
    extractFilters,
    extractSortParam,
} from "../components/table/kendo/helpers";

export const dynamicAssetPropPrefix = "dynAssetProp";

const genSortAndFilter = (
    dataState: State,
    selectedTemplatesIds: string[],
    selectedFields: string[],
): AssetSortAndFilter => {
    const sortParam = extractSortParam(dataState);
    if (sortParam?.field?.includes(dynamicAssetPropPrefix)) {
        sortParam.field = sortParam.field.substring(
            dynamicAssetPropPrefix.length,
        );
    }

    return {
        sortParam,
        assetTemplates: selectedTemplatesIds,
        filterParams: extractFilters(dataState)
            .filter((f) => selectedFields.includes((f.field as string) || ""))
            .map((f) => {
                const field = f.field.toString();
                const isCustomField = field.startsWith(dynamicAssetPropPrefix);
                return {
                    field: isCustomField
                        ? field.substring(dynamicAssetPropPrefix.length)
                        : field,
                    value: f.value.toString(),
                    dataType: "string",
                    operator: f.operator as string,
                    isCustomField,
                };
            }),
    };
};

const useAssetsSortAndFitler = (
    dataState: State,
    selectedTemplates: AssetTemplateModel[],
    selectedColumns: GridColumnDef[],
): AssetSortAndFilter => {
    return React.useMemo(() => {
        return genSortAndFilter(
            dataState,
            selectedTemplates?.map(({ id }) => id) ?? [],
            selectedColumns.map(({ field }) => field) ?? [],
        );
    }, [dataState, selectedTemplates, selectedColumns]);
};

export default useAssetsSortAndFitler;
