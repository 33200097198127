import PermissionConstants from "../../../common/permissions/PermissionConstants";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import { useDeleteProcessByIdMutation } from "../../../store/features/process/process-api-slice";
import { ProcessListItem } from "../domain/types";
import ButtonSpinner from "../../../common/components/button/ButtonSpinner";
import { TypedGridCellProps } from "../../../common/types/TypedGridCellProps";
import { Organisation } from "../../organisation/domain/types";
import ConfirmBox from "../../../common/components/kendo-dialog-boxes/ConfirmBox";

type Props = {
    cell: TypedGridCellProps<ProcessListItem>;
    refetchData();
    organisation: Organisation;
};

const ProcessesListTableRowActions: React.FC<Props> = ({
    cell,
    refetchData,
    organisation,
}) => {
    // eslint-disable-next-line react/display-name
    const [deleteProcess, { isLoading: isDeleteLoading, isSuccess }] =
        useDeleteProcessByIdMutation();

    const canProcessDelete = hasUserOrgPermission(
        organisation.id,
        PermissionConstants.OrgProcess.delete,
    ).hasPermission;

    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleDelete = () => {
        deleteProcess(cell.dataItem.id);
        setShowConfirmation(false);
    };

    React.useEffect(() => {
        if (isSuccess) {
            // refresh the grid
            refetchData();
        }
    }, [isSuccess, refetchData]);

    return (
        <td>
            {canProcessDelete && (
                <>
                    <Button
                        variant="outline-danger"
                        className="top5 ml-1"
                        onClick={() => setShowConfirmation(true)}
                    >
                        Delete
                        {isDeleteLoading && <ButtonSpinner />}
                    </Button>
                    {showConfirmation && (
                        <ConfirmBox
                            title="Delete"
                            message="Are you sure you want to delete this?"
                            onCancel={() => setShowConfirmation(false)}
                            onConfirm={handleDelete}
                        />
                    )}
                </>
            )}
        </td>
    );
};

export default ProcessesListTableRowActions;
