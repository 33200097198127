import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useRemoveUserFromOrganisationMutation } from "../../../../store/features/organisation/organisation-api-slice";
import { TableUser } from "./UsersTable";
import { hasUserOrgPermission } from "../../../../store/features/user/user-api-slice";
import PermissionConstants from "../../../../common/permissions/PermissionConstants";
import UserDisplayNameModal from "../UserDisplayNameModal";
import ConfirmBox from "../../../../common/components/kendo-dialog-boxes/ConfirmBox";

interface Props {
    user: TableUser;
    organisationId: string;
}

const UsersTableActions: React.FC<Props> = ({ user, organisationId }) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [removeUser] = useRemoveUserFromOrganisationMutation();
    const [editActive, setEditActive] = React.useState<boolean>(false);

    const canDelete = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgUser.delete,
    ).hasPermission;
    const canUpdateOthers = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgUser.displayNameUpdateOthers,
    ).hasPermission;

    const clickRemove = (): void => {
        setShowConfirmation(true);
    };

    const onRemove = (): void => {
        removeUser({ userId: user.userId, organisationId });
    };

    return (
        <>
            {!user.isOwner && canUpdateOthers && (
                <Button
                    className="top5 ml-1"
                    onClick={() => setEditActive(true)}
                >
                    Edit
                </Button>
            )}
            {!user.isOwner && canDelete && (
                <Button
                    className="top5 ml-1"
                    variant="danger"
                    onClick={clickRemove}
                >
                    Remove
                </Button>
            )}
            {showConfirmation && (
                <ConfirmBox
                    title="Remove User"
                    message={`Are you sure you want to remove ${user.username}?`}
                    onConfirm={onRemove}
                    onCancel={() => setShowConfirmation(false)}
                />
            )}
            {editActive && (
                <UserDisplayNameModal
                    onClose={() => {
                        setEditActive(false);
                    }}
                    id={user.userId}
                    organisationId={organisationId}
                    initialDisplayName={user.userDisplayName}
                    username={user.username}
                />
            )}
        </>
    );
};

export default UsersTableActions;
