import cx from "classnames";
import * as React from "react";
import { Button, ButtonToolbar } from "react-bootstrap";
import {
    ExamManagement,
    bridgeExamTypes,
    underWaterExamTypes,
} from "../../../examinations/domain/examManagement";
import ChangeExamTypeModal from "./ChangeExamTypeModal";
import { useExamManagementChangeExamTypePermissions } from "./useExamManagementChangeExamTypePermissions";
import { ExaminationState } from "../../../examinations/domain/ExaminationState";

interface Props {
    organisationId: string;
    selectedItems: ExamManagement[];
}

const PlanningOrExaminationStates: string[] = [
    ExaminationState.Planned,
    ExaminationState.ExaminationInProgress,
    ExaminationState.ReturnedToExaminer,
    ExaminationState.Unallocated,
];

const ExamManagementGridActions: React.FC<Props> = ({
    organisationId,
    selectedItems,
}) => {
    const underwaterExamTypes = underWaterExamTypes.map((x) => x.value);
    const bridgeDetailExamTypes = bridgeExamTypes.map((x) => x.value);

    const isValidExamTypeAndStateToChangeExamType = selectedItems?.every(
        (x) =>
            underwaterExamTypes
                .concat(bridgeDetailExamTypes)
                .includes(x.processTemplateName) &&
            PlanningOrExaminationStates.includes(x.processState),
    );

    const { canChangeExamType } =
        useExamManagementChangeExamTypePermissions(organisationId);

    const [changeExamTypeActive, setChangeExamTypeActive] =
        React.useState<boolean>(false);

    return (
        <div>
            {changeExamTypeActive && (
                <ChangeExamTypeModal
                    examinations={selectedItems}
                    organisationId={organisationId}
                    onClose={() => setChangeExamTypeActive(false)}
                />
            )}

            <ButtonToolbar
                className={cx({
                    invisible: selectedItems.length === 0,
                })}
            >
                {canChangeExamType &&
                    isValidExamTypeAndStateToChangeExamType && (
                        <Button
                            variant="outline-secondary"
                            onClick={() => {
                                setChangeExamTypeActive(true);
                            }}
                        >
                            Change Exam Type
                        </Button>
                    )}
            </ButtonToolbar>
        </div>
    );
};

export default ExamManagementGridActions;
