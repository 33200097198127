import { TemplateContentRiskRecord } from "../../domain/types";

export interface FormControlRiskGridDataItem extends TemplateContentRiskRecord {
    originalIndex: number;
    inEdit: boolean;
}

export const mapToRiskGridItem = (
    riskRecord: TemplateContentRiskRecord,
    index: number,
    inEditId: string,
): FormControlRiskGridDataItem =>
    ({
        ...riskRecord,
        originalIndex: index,
        inEdit: riskRecord.id === inEditId,
    } as FormControlRiskGridDataItem);
