import React from "react";
import FormTemplatesConstants from "../../../Constants/FormTemplatesConstants";
import {
    ShowCondition,
    TemplateContentTableColumn,
    TemplateContentValidation,
} from "../../../modules/template/domain/types";
import FormTableCellControlDateTime from "./FormTableCellControlDateTime";
import FormTableCellControlNumber from "./FormTableCellControlNumber";
import FormTableCellControlSelect from "./FormTableCellControlSelect";
import FormTableCellControlText from "./FormTableCellControlText";

interface FormTableCellControlBuilderConfig {
    parentField: string;
    columnConfig: TemplateContentTableColumn;
    rowIndex: number;
    isReadOnly: boolean;
    showConditions: ShowCondition[];
    validationType: string;
    validations: TemplateContentValidation[];
}

interface Props {
    config: FormTableCellControlBuilderConfig;
}

const FormTableCellControlBuilder: React.FC<Props> = ({ config }: Props) => {
    const formikValueFieldAccessor = `${config.parentField}[${config.rowIndex}].${config.columnConfig.field}`;

    const showConditionsForTableField = (): ShowCondition[] => {
        const conditions: ShowCondition[] = [];

        for (const condition of config.showConditions) {
            const fieldAccessor = `${config.parentField}[${config.rowIndex}].${condition.field}`;

            conditions.push({ ...condition, field: fieldAccessor });
        }

        return conditions;
    };

    const configSetting = {
        field: formikValueFieldAccessor,
        isReadOnly: config.isReadOnly || config.columnConfig.readOnly,
        style: config.columnConfig.style,
        showConditions: showConditionsForTableField(),
        validations: config.validations,
        validationType: config.validationType,
    };
    switch (config.columnConfig.type) {
        case FormTemplatesConstants.ContentType.TableText:
            return <FormTableCellControlText config={configSetting} />;
        case FormTemplatesConstants.ContentType.TableNumber:
            return <FormTableCellControlNumber config={configSetting} />;
        case FormTemplatesConstants.ContentType.TableSelect:
            return (
                <FormTableCellControlSelect
                    config={{
                        ...configSetting,
                        options: config.columnConfig.options,
                    }}
                />
            );
        case FormTemplatesConstants.ContentType.TableDateTime:
            return <FormTableCellControlDateTime config={configSetting} />;
        default:
            return <div>Unsupported field</div>;
    }
};

export default FormTableCellControlBuilder;
