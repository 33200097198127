import * as React from "react";
import { Breadcrumb } from "react-bootstrap";
import { useParams } from "react-router";
import { IdAndName } from "../../../../common/types/IdAndName";
import OrganisationConstants from "../../../../Constants/OrganisationConstants";
import { FilesRouteParams } from "../../viewModel/FilesRouteParams";
import "./directory-breadcrumb.css";
import { BreadCrumbItem } from "./breadCrumbItem";

interface DirectoryBreadcrumbProps {
    path: IdAndName[];
    activeId: string;
    onFolderClick?: (folderId: string) => void;
}

export const DirectoryBreadcrumb: React.FC<DirectoryBreadcrumbProps> = ({
    path,
    activeId,
    onFolderClick,
}) => {
    const { orgShortName } = useParams<FilesRouteParams>();
    const { folder } = OrganisationConstants;
    const orgFiles = `/${orgShortName}/${folder}`;

    return (
        <Breadcrumb className="margin-0">
            {path.map(({ id, name }) => (
                <BreadCrumbItem
                    key={id}
                    active={id === activeId}
                    linkTo={`${orgFiles}/${id}`}
                    onClick={onFolderClick ? () => onFolderClick(id) : null}
                >
                    {name}
                </BreadCrumbItem>
            ))}
        </Breadcrumb>
    );
};
