import { useFormikContext } from "formik";
import * as React from "react";
import { Form } from "react-bootstrap";
import { ShowCondition } from "../../../modules/template/domain/types";
import FormControlBase from "./FormControlBase";
import FormPromptComponent from "./FormPromptComponent";

interface FormControlDateConfig {
    field: string;
    label: string;
    style: { color?: string };
    prompt: string;
    uri: string;
    isReadOnly: boolean;
    showConditions: ShowCondition[];
}

interface Props {
    config: FormControlDateConfig;
    type?: "date" | "datetime";
}

const FormControlDate: React.FC<Props> = (props) => {
    const { config, type = "date" } = props;
    const formik = useFormikContext();

    return (
        <FormControlBase
            contentConfig={{
                field: config.field,
                showConditions: config.showConditions,
            }}
        >
            <Form.Label htmlFor={config.field}>{config.label}</Form.Label>
            <Form.Control
                type={type === "date" ? "date" : "datetime-local"}
                name={config.field}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{ ...config.style }}
                value={(formik.values[config.field] as string) ?? ""}
                className={formik.errors[config.field] ? "is-invalid" : ""}
                readOnly={config.isReadOnly}
            />
            <FormPromptComponent config={config} />
        </FormControlBase>
    );
};

export default FormControlDate;
