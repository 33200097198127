import { GridFieldsMap } from "../../../../../common/components/table/kendo/GridFieldsMap";

export const recommendationsGridMap: GridFieldsMap = {
    description: {
        field: "description",
        label: "Description",
        dataType: "string",
        width: 300,
    },
    location: {
        field: "location",
        label: "Location",
        dataType: "string",
        width: 150,
    },
    quantityValue: {
        field: "quantityValue",
        label: "Quantity Value",
        dataType: "string",
        width: 150,
    },
    quantityUnits: {
        field: "quantityUnits",
        label: "Quantity Units",
        dataType: "Date",
        width: 150,
    },
    worksCategory: {
        field: "worksCategory",
        label: "Works Category",
        dataType: "string",
        width: 150,
    },
    estimatePrice: {
        field: "estimatePrice",
        label: "Estimate Price",
        dataType: "string",
        width: 150,
    },
    priority: {
        field: "priority",
        label: "Priority",
        dataType: "string",
        width: 150,
    },
    sme: {
        field: "sme",
        label: "SME",
        dataType: "string",
        width: 100,
    },
    effect: {
        field: "effect",
        label: "Effect",
        dataType: "string",
        width: 100,
    },
    probability: {
        field: "probability",
        label: "Probability",
        dataType: "Date",
        width: 150,
    },
};
