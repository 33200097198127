import {
    Grid,
    GridCellProps,
    GridColumn,
    GridExpandChangeEvent,
} from "@progress/kendo-react-grid";
import React, { useEffect } from "react";
import { SortDescriptor } from "@progress/kendo-data-query";

import DownloadCell from "./DownloadCell";
import CommercialMassUpdaterGridDetails from "./CommercialMassUpdaterGridDetails";
import { CommercialMassUpdate } from "../domain/commercialMassUpdate.types";
import {
    emptyFilterDescriptor,
    SimplifiedMenuFilter,
} from "../../../../common/components/table/kendo/columnFilters";
import useGridProps from "../../../../common/components/table/kendo/useGridProps";
import { jSDateFromIso } from "../../../../helpers/dateTimeHelpers";

interface Props {
    bulkUpdates: CommercialMassUpdate[];
    organisationId: string;
}

type CommercialMassUpdateData = {
    expanded?: boolean;
    createdOn: Date;
} & Omit<CommercialMassUpdate, "createdOn">;

function getCommercialMassUpdateData(
    bulkUpdates: CommercialMassUpdate[],
): CommercialMassUpdateData[] {
    return bulkUpdates.map(({ createdOn, ...other }) => ({
        expanded: false,
        createdOn: jSDateFromIso(createdOn) || null,
        ...other,
    }));
}

const CommercialMassUpdaterGrid: React.FC<Props> = ({
    bulkUpdates,
    organisationId,
}) => {
    const [bulkUpdatesData, setCommercialMassUpdateData] = React.useState<
        CommercialMassUpdateData[]
    >(getCommercialMassUpdateData(bulkUpdates));

    useEffect(() => {
        setCommercialMassUpdateData(getCommercialMassUpdateData(bulkUpdates));
    }, [bulkUpdates]);

    const initialSort: Array<SortDescriptor> = [
        { field: "createdOn", dir: "desc" },
    ];

    const { gridProps } = useGridProps(bulkUpdatesData, {
        filterable: true,
        initialFilter: emptyFilterDescriptor,
        sortable: true,
        pageable: false,
        initialSort: initialSort,
    });

    const handleExpand = React.useCallback(
        (event: GridExpandChangeEvent) => {
            const newData = bulkUpdatesData.map(
                (item: CommercialMassUpdateData) => {
                    if (item.id === event.dataItem.id) {
                        item.expanded = !event.dataItem.expanded;
                    }
                    return item;
                },
            );
            setCommercialMassUpdateData(newData);
        },
        [bulkUpdatesData],
    );

    const GridDownloadCell = React.useCallback(
        (props: GridCellProps) => (
            <DownloadCell cellProps={props} organisationId={organisationId} />
        ),
        [organisationId],
    );

    return (
        <>
            <div className="py-2">
                <Grid
                    {...gridProps}
                    style={{ cursor: "pointer" }}
                    detail={CommercialMassUpdaterGridDetails}
                    expandField="expanded"
                    onExpandChange={handleExpand}
                >
                    <GridColumn
                        field="name"
                        title="Name"
                        columnMenu={SimplifiedMenuFilter}
                    />
                    <GridColumn
                        field="status"
                        title="Status"
                        columnMenu={SimplifiedMenuFilter}
                    />
                    <GridColumn
                        field="createdOn"
                        title="Creation date"
                        filter={"date"}
                        format="{0:d MMM y h:mm a}"
                        columnMenu={SimplifiedMenuFilter}
                    />
                    <GridColumn width={60} cell={GridDownloadCell} />
                </Grid>
            </div>
        </>
    );
};

export default CommercialMassUpdaterGrid;
