import { QueryKey } from "react-query";

export const assetCesDataKey = (id: string): string[] => ["asset-ces-data", id];

const assetCefaQueryKeys = {
    assetCesData: (id: string): QueryKey => [...assetCesDataKey(id)],
    assetCesDefects: (id: string): QueryKey => [
        ...assetCesDataKey(id),
        "defects",
    ],
};

export default assetCefaQueryKeys;
