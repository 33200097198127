import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Formik, FormikErrors } from "formik";
import * as React from "react";
import { Button } from "react-bootstrap";
import CoveringLoadingPanel from "../../../../common/components/CoveringLoadingPanel";
import { DateTimeSelect } from "../../../../common/components/form";
import { Examination } from "../domain/examination";
import { ExaminationScheduleModel } from "../domain/examinationsPlanningDtos";
import { useSetExaminationsPlannedDatesMutation } from "../query/examinationsPlanningQueries";

interface FormValues {
    scheduledStart?: Date;
    scheduledEnd?: Date;
}

const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (values.scheduledStart && !values.scheduledEnd) {
        errors.scheduledEnd = "Planned End is required";
    }

    if (values.scheduledEnd && !values.scheduledStart) {
        errors.scheduledStart = "Planned Start is required";
    }

    if (values.scheduledEnd && values.scheduledStart) {
        if (values.scheduledEnd < values.scheduledStart)
            errors.scheduledEnd = "Planned End can't be before Planned Start";
    }
    return errors;
};

interface Props {
    examinations: Examination[];
    organisationId: string;
    onClose: () => void;
}

const ScheduleModal: React.FC<Props> = ({
    examinations,
    organisationId,
    onClose,
}) => {
    const scheduleExaminations =
        useSetExaminationsPlannedDatesMutation(organisationId);

    const examinationIds = React.useMemo(
        () => examinations.map((e) => e.id),
        [examinations],
    );

    const initialValues: FormValues = {
        scheduledStart: null,
        scheduledEnd: null,
    };

    const onSubmit = React.useCallback(
        async (values: FormValues) => {
            scheduleExaminations.mutate(
                {
                    processActivityIds: examinationIds,
                    scheduledStart: values.scheduledStart,
                    scheduledEnd: values.scheduledEnd,
                } as ExaminationScheduleModel,
                { onSuccess: () => onClose(), onError: () => onClose() },
            );
        },
        [examinationIds, onClose, scheduleExaminations],
    );

    return (
        <Dialog title="Plan Examinations" onClose={onClose}>
            {scheduleExaminations.isLoading && <CoveringLoadingPanel />}
            <Formik
                validate={validate}
                onSubmit={onSubmit}
                initialValues={initialValues}
            >
                {({ values }) => (
                    <Form>
                        <DateTimeSelect
                            type="date"
                            label="Planned Start Date"
                            name="scheduledStart"
                            value={values.scheduledStart?.toString()}
                        ></DateTimeSelect>
                        <DateTimeSelect
                            type="date"
                            label="Planned End Date"
                            name="scheduledEnd"
                            value={values.scheduledEnd?.toString()}
                        ></DateTimeSelect>

                        <DialogActionsBar>
                            <Button
                                type="button"
                                variant="outline-secondary"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button type="submit" variant="primary">
                                Submit
                            </Button>
                        </DialogActionsBar>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default ScheduleModal;
