import { PageChangeEvent, Pager } from "@progress/kendo-react-data-tools";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ListView, ListViewItemProps } from "@progress/kendo-react-listview";
import * as React from "react";
import { Button } from "react-bootstrap";
import { ExistingFile } from "../domain/folder";
import { ExtendedUploadInfo } from "../viewModel/folderUploadHelpers";
import { Organisation } from "../../organisation/domain/types";

interface UploadConfirmDialogProps {
    onCancel: () => void;
    onConfirmNew: () => void | Promise<void>;
    onConfirmReplace: () => void | Promise<void>;
    uploadInfo: ExtendedUploadInfo;
    organisation: Organisation;
}

const ExistingFileRender = (props: ListViewItemProps) => {
    const dataItem = props.dataItem as ExistingFile;

    return (
        <>
            <div className="row m-0">{dataItem.name}</div>
            <div className="row m-0 pl-4 font-weight-light">
                <div className="col-12 text-truncate">{dataItem.location}</div>
            </div>
        </>
    );
};

const UploadConfirmDialog: React.FC<UploadConfirmDialogProps> = ({
    onCancel,
    onConfirmNew,
    onConfirmReplace,
    uploadInfo,
    organisation,
}) => {
    React.useEffect(() => {
        if (uploadInfo.existingFiles.length === 0) {
            onConfirmNew();
        }
    }, [onConfirmNew, uploadInfo.existingFiles.length]);

    const [page, setPage] = React.useState({
        skip: 0,
        take: 10,
    });

    const handlePageChange = (e: PageChangeEvent) => {
        setPage({
            skip: e.skip,
            take: e.take,
        });
    };

    const { skip, take } = page;

    return (
        <Dialog
            title={`Do you want to replace the following ${
                organisation?.features?.files.sideMenuName ?? "files"
            }?`}
            onClose={onCancel}
            className="upload-confirm-dialog"
            width={600}
            height={700}
        >
            <div className="m-0 d-flex flex-column h-100">
                <ListView
                    className="border-0 flex-grow-1 "
                    data={uploadInfo.existingFiles.slice(skip, skip + take)}
                    item={ExistingFileRender}
                />
                <Pager
                    className="mt-auto"
                    skip={skip}
                    take={take}
                    onPageChange={handlePageChange}
                    total={uploadInfo.existingFiles.length}
                />
            </div>
            <DialogActionsBar>
                <div className="d-flex flex-row-reverse">
                    <Button
                        className="m-1"
                        variant="outline-secondary"
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                    {uploadInfo.newFiles.length > 0 && (
                        <Button
                            className="m-1"
                            variant="primary"
                            onClick={onConfirmNew}
                        >
                            Skip
                        </Button>
                    )}
                    <Button
                        className="m-1"
                        variant="secondary"
                        onClick={onConfirmReplace}
                    >
                        Replace{" "}
                        {organisation?.features?.files.sideMenuName ?? "files"}
                    </Button>
                </div>
            </DialogActionsBar>
        </Dialog>
    );
};

export default UploadConfirmDialog;
