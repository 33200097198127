import React from "react";
import cx from "classnames";
import { Nav } from "react-bootstrap";
import { FaCalendar } from "react-icons/fa";
import { NavLink, Route } from "react-router-dom";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import useViewPermissions from "../../../common/hooks/useViewPermissions";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import SbimConstants from "../../sbim/constants/SbimConstants";
import useIsClient from "../../../common/hooks/useIsClient";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import { Organisation } from "../../organisation/domain/types";
import { AppRoutes } from "../../../router/AppRoutes";

const SchedulerMenu: React.FC<{ organisation: Organisation }> = ({
    organisation,
}) => {
    const permission = hasUserOrgPermission(
        organisation.id,
        PermissionConstants.OrgProcess.read,
    ).hasPermission;
    const { canViewScheduler } = useViewPermissions(organisation.id);
    const { shortName: orgShortName } = organisation;
    const isSBIM = useIsClient(SbimConstants.ClientName);
    if (permission) {
        return (
            <>
                {canViewScheduler && (
                    <Nav.Item>
                        <NavLink
                            to={AppRoutes.Scheduler.toLink({ orgShortName })}
                            className={cx(
                                "d-flex align-items-center nav-link",
                                {
                                    "d-flex align-items-center nav-link padding button-override":
                                        isSBIM,
                                },
                            )}
                            exact
                            key="scheduler"
                        >
                            <FaCalendar size="16" className="menu-icon" />
                            <span className="navbar-font-override">
                                {organisation?.features?.scheduler
                                    ?.sideMenuName ?? "Scheduler"}
                            </span>
                        </NavLink>
                    </Nav.Item>
                )}
                <Route
                    path={`/${organisation.shortName}${OrganisationConstants.Asset}`}
                />
            </>
        );
    } else {
        return null;
    }
};

export default SchedulerMenu;
