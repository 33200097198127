import consumerApiService from "../../../customization/api/consumerApiService";
import { ActionButtonEndpointTarget } from "../domain/types";

enum ProcessActivityUrlPaths {
    ApiEndpoint = "/api/process-activities",
}

interface SymbolicUrl {
    activityId: string;
    organisationId: string;
    url: string;
    target: ActionButtonEndpointTarget;
}

export const getActivityFileResUrl = (processActivityId: string) => {
    return (fileId: string): string =>
        processActivityId && fileId
            ? `${ProcessActivityUrlPaths.ApiEndpoint}/${processActivityId}/processactivityfile/${fileId}`
            : "";
};

export const resolveSymbolicUrl = (symbolicUrl: SymbolicUrl): string => {
    const { activityId, organisationId, url, target } = symbolicUrl;

    const targetUrl = url
        .replace("{organisationId}", organisationId)
        .replace("{id}", activityId);

    if (target === ActionButtonEndpointTarget.Client) {
        return `${consumerApiService.baseApiUrl}/${targetUrl}`;
    } else if (target === ActionButtonEndpointTarget.Core) {
        return targetUrl;
    }
    return targetUrl;
};
