import { ErrorResponse, ErrorTitle } from "../../../common/types/Errors";

export const getNotificationsForSpecialErrors = (
    error: ErrorResponse,
): string => {
    if (!error) {
        return "Error (no error information is available)";
    }

    if (error instanceof TypeError) {
        return `TypeError: ${error.message}`;
    }

    if (typeof error === "string") {
        return error;
    }

    // error is ErrorResponse
    if (error.title === ErrorTitle.BusinessRule) {
        return error.detail;
    } else if (error.status === 403) {
        return "You do not have permissions to perform the operation.";
    } else if (error.title && typeof error.title === "string") {
        return error.title;
    }
};
