import {
    createSlice,
    PayloadAction,
    SliceCaseReducers,
} from "@reduxjs/toolkit";

export interface AuthUserData {
    username: string;
    displayName: string;
}

export type AuthUserState = AuthUserData;

const initialState: AuthUserState = {
    username: "",
    displayName: "",
};

const authUserSlice = createSlice<
    AuthUserState,
    SliceCaseReducers<AuthUserState>
>({
    name: "authUserState",
    initialState,
    reducers: {
        setUser(
            state,
            { payload }: PayloadAction<AuthUserData>,
        ): AuthUserState {
            return {
                ...state,
                username: payload.username,
                displayName: payload.displayName,
            };
        },
    },
});

export const { setUser } = authUserSlice.actions;
export const reducer = authUserSlice.reducer;
