import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React from "react";
import { ListGroup } from "react-bootstrap";
import { HintWithTooltip } from "../../../../common/components/HintWithTooltip";
import MassUpdateErrorsGrid from "../../common/components/MassUpdateErrorsGrid";
import {
    CommercialMassUpdate,
    CommercialMassUpdateStatus,
} from "../domain/commercialMassUpdate.types";

const CommercialMassUpdaterGridDetails: React.FC<GridDetailRowProps> = (
    props: GridDetailRowProps,
) => {
    const data: CommercialMassUpdate = props.dataItem;

    if (data.status === CommercialMassUpdateStatus.Pending) {
        return <strong>This Bulk Update is being processed.</strong>;
    }
    if (data.status === CommercialMassUpdateStatus.Error && data.errorMessage) {
        const messages = data.errorMessage.split("\n");
        return (
            <>
                <strong>Failed to validate input rows</strong>
                <MassUpdateErrorsGrid values={messages} />
            </>
        );
    }
    if (data.status === CommercialMassUpdateStatus.Error)
        return (
            <strong>
                An error occurred while processing the file. Please contact the
                administrator for more details.
            </strong>
        );

    return (
        <>
            <div className="pb-3">
                <strong>
                    The Bulk Update has been processed with the following
                    results:
                </strong>
            </div>
            {data.result && (
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        <p>
                            Completed rows{" "}
                            <HintWithTooltip
                                id="completed"
                                text="The amount of rows that were completed processed/total-amount."
                            />
                            : {data.result.completedCount}/
                            {data.result.totalCount}
                        </p>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <p>
                            Failed rows{" "}
                            <HintWithTooltip
                                id="failed"
                                text="The amount of rows that failed to be processed. Details can be found under the current table."
                            />
                            : {data.result.failedCount}
                        </p>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <p>
                            Skipped rows{" "}
                            <HintWithTooltip
                                id="skipped"
                                text="If there were no changes to the data, the row was
                                skipped."
                            />
                            : {data.result.skippedCount}{" "}
                        </p>
                    </ListGroup.Item>
                    {data.rowErrors && data.rowErrors.length > 0 && (
                        <ListGroup.Item>
                            <MassUpdateErrorsGrid
                                values={data.rowErrors.map(
                                    (e) => `[Row: ${e.row}] ${e.message}`,
                                )}
                            />
                        </ListGroup.Item>
                    )}
                </ListGroup>
            )}
        </>
    );
};

export default CommercialMassUpdaterGridDetails;
