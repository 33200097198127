import { HttpMethod } from "../../../../common/http/HttpMethod";
import { consumerApi } from "../../../../customization/api/consumerRTKQueryApi";
import { Tags } from "../../../../store/features/dcpApi";
import { Workbank } from "../domain/types";

export interface UploadWorkbankParams {
    workbankFile: File;
    organisationId: string;
    contractYear: string;
}

export interface GetWorkbankParams {
    workbankId: string;
    organisationId: string;
}

const apiSubPath = (organisationId) =>
    `/organisation/${organisationId}/workbank`;

export const workbankApi = consumerApi.injectEndpoints({
    endpoints: (builder) => ({
        getWorkbanks: builder.query<Workbank[], string>({
            query: (organisationId) => apiSubPath(organisationId),
            providesTags: (_, error, params) =>
                error ? [] : [{ type: Tags.WorkbankUpload, id: params }],
        }),
        uploadWorkbank: builder.mutation<void, UploadWorkbankParams>({
            query: (params) => {
                const body = new FormData();
                body.append("file", params.workbankFile);
                body.append("contractYear", params.contractYear);
                return {
                    url: `${apiSubPath(params.organisationId)}`,
                    method: HttpMethod.Post,
                    body,
                };
            },
            invalidatesTags: (_, error, params) =>
                error
                    ? []
                    : [
                          {
                              type: Tags.WorkbankUpload,
                              id: params.organisationId,
                          },
                      ],
        }),
    }),
});

export const { useGetWorkbanksQuery, useUploadWorkbankMutation } = workbankApi;
