// I'm suppressing the rule, because `{}` is actually the only type that works correctly in this case.
// The suggested type (`Record<string, unknown>`) causes some compilation errors.
// eslint-disable-next-line @typescript-eslint/ban-types
export function hasOwnProperty<X extends {}, Y extends PropertyKey>(
    obj: X,
    prop: Y,
): obj is X & Record<Y, unknown> {
    // eslint-disable-next-line no-prototype-builtins
    return obj.hasOwnProperty(prop);
}
