import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import {
    GridColumnMenuCheckboxFilter,
    GridColumnMenuFilter,
    GridColumnMenuFilterUIProps,
    GridColumnMenuProps,
    GridFilterOperators,
} from "@progress/kendo-react-grid";
import * as React from "react";
import { FormEvent } from "react";
import CustomTextFilter from "./CustomTextFilter";

export const emptyFilterDescriptor: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
};

/** Catching submit event in a div that contains GridColumnMenuFilter/GridColumnMenuCheckboxFilter, is to fix a Kendo bug,
 * which caused the filter to submit the form that conatains the grid.
 * Kendo issue: https://github.com/telerik/kendo-react/issues/1197
 */
const onKendoFilterSubmit = (e: FormEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
};

export const getColumnMenuCheckboxFilter = (data: (string | UnknownRecord)[]) =>
    function ColumnMenuCheckboxFilter(props: GridColumnMenuProps): JSX.Element {
        return (
            <div onSubmit={onKendoFilterSubmit}>
                <GridColumnMenuCheckboxFilter
                    {...props}
                    data={data}
                    expanded={true}
                />
            </div>
        );
    };

export const ColumnMenuFilter: React.FC<GridColumnMenuProps> = (props) => {
    return (
        <div onSubmit={onKendoFilterSubmit}>
            <GridColumnMenuFilter {...props} expanded={true} />
        </div>
    );
};

interface CustomMenuFilterProps {
    filterOperators?: GridFilterOperators;
    customFilterUI?: React.ComponentType<GridColumnMenuFilterUIProps>;
}

const getCustomMenuFilter = ({
    filterOperators,
    customFilterUI,
}: CustomMenuFilterProps): React.FC<GridColumnMenuProps> =>
    function CustomColumnMenuFilter(props: GridColumnMenuProps) {
        return (
            <div onSubmit={onKendoFilterSubmit}>
                <GridColumnMenuFilter
                    {...props}
                    expanded={true}
                    filterOperators={filterOperators}
                    filterUI={customFilterUI}
                />
            </div>
        );
    };

export const commonFilterOperators = {
    text: [
        {
            text: "grid.filterContainsOperator",
            operator: "contains",
        },
    ],
    numeric: [
        {
            text: "grid.filterEqOperator",
            operator: "eq",
        },
        {
            text: "grid.filterGteOperator",
            operator: "gte",
        },
        {
            text: "grid.filterLteOperator",
            operator: "lte",
        },
    ],
    date: [
        {
            text: "grid.filterEqOperator",
            operator: "eq",
        },
        { text: "grid.filterAfterOrEqualOperator", operator: "gte" },
        { text: "grid.filterBeforeOrEqualOperator", operator: "lte" },
    ],
    boolean: [
        {
            text: "grid.filterEqOperator",
            operator: "eq",
        },
    ],
};

export const ContainsMenuFilter = getCustomMenuFilter({
    customFilterUI: CustomTextFilter,
    filterOperators: commonFilterOperators,
});

export const SimplifiedMenuFilter = getCustomMenuFilter({
    filterOperators: commonFilterOperators,
});
