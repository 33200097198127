import React, { useCallback } from "react";
import {
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from "react-query";
import { ExaminationBulkUpdate } from "../domain/examinationBlukUpdate.types";
import {
    getExaminationBulkUpdates,
    uploadExaminationBulkUpdate,
} from "../services/examinationsBulkUpdateService";
import examinationQueryKeys from "./examinationQueryKeys";

export const useGetExaminationBulkUpdates = (
    organisationId: string,
): UseQueryResult<ExaminationBulkUpdate[]> => {
    const getQuery = useCallback(() => {
        return getExaminationBulkUpdates({ organisationId });
    }, [organisationId]);

    const queryKeys = React.useMemo(
        () => examinationQueryKeys.examinationsBulkUpdates(organisationId),
        [organisationId],
    );

    return useQuery(queryKeys, getQuery);
};

export const useUploadExaminationBulkUpdateMutation = (
    organisationId: string,
): UseMutationResult<void, unknown, File> => {
    const queryClient = useQueryClient();

    const uploadExaminationBulkUpdateFunction = (file: File) =>
        uploadExaminationBulkUpdate({ organisationId, file });

    const queryKeys = React.useMemo(
        () => examinationQueryKeys.examinationsBulkUpdates(organisationId),
        [organisationId],
    );

    return useMutation(uploadExaminationBulkUpdateFunction, {
        onSuccess(_result) {
            return queryClient.invalidateQueries(queryKeys);
        },
    });
};
