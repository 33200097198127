import { filterBy, orderBy } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import {
    Grid,
    GridColumn,
    GridNoRecords,
    GridToolbar,
} from "@progress/kendo-react-grid";
import { push } from "connected-react-router";
import * as React from "react";
import { useDispatch } from "react-redux";
import {
    ContainsMenuFilter,
    emptyFilterDescriptor,
    getColumnMenuCheckboxFilter,
    SimplifiedMenuFilter,
} from "../../../common/components/table/kendo/columnFilters";
import useGridProps from "../../../common/components/table/kendo/useGridProps";
import { jSDateFromIso } from "../../../helpers/dateTimeHelpers";
import { SiteWorkDto } from "../domain/types";

interface SiteWorksGridProps {
    siteWorks: SiteWorkDto[];
    orgShortName: string;
}

type SiteWorksData = {
    completionDate: Date;
    reportSubmissionTargetDate: Date;
} & Omit<
    SiteWorkDto,
    "completionDateValue" | "reportSubmissionTargetDateValue"
>;

function getSiteWorksData(siteWorks: SiteWorkDto[]): SiteWorksData[] {
    return siteWorks.map(
        ({
            completionDateValue,
            reportSubmissionTargetDateValue,
            ...other
        }) => ({
            completionDate: jSDateFromIso(completionDateValue) || null,
            reportSubmissionTargetDate:
                jSDateFromIso(reportSubmissionTargetDateValue) || null,
            ...other,
        }),
    );
}

const SiteWorksGrid: React.FC<SiteWorksGridProps> = ({
    siteWorks,
    orgShortName,
}) => {
    const dispatch = useDispatch();

    const siteWorksData: SiteWorksData[] = React.useMemo(
        () => getSiteWorksData(siteWorks),
        [siteWorks],
    );

    const { gridProps, filter, sort } = useGridProps(siteWorksData, {
        filterable: true,
        initialFilter: emptyFilterDescriptor,
        sortable: true,
        initialSort: [{ field: "name", dir: "asc" }],
        pageable: true,
    });

    const _export = React.useRef<ExcelExport | null>(null);
    const excelExport = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    const reportEntries = React.useMemo(
        () => orderBy(filterBy(siteWorksData, filter), sort),
        [siteWorksData, filter, sort],
    );

    const onNavigate = React.useCallback(
        (process: SiteWorkDto) => {
            dispatch(
                push(
                    `/${orgShortName}/process/${process.processId}/activity/${process.processActivityId}`,
                ),
            );
        },
        [orgShortName, dispatch],
    );

    const processTypeCheckboxFilter =
        getColumnMenuCheckboxFilter(siteWorksData);

    return (
        <ExcelExport data={reportEntries} ref={_export}>
            <Grid {...gridProps} onRowClick={(e) => onNavigate(e.dataItem)}>
                <GridNoRecords>There is no data available</GridNoRecords>
                <GridToolbar>
                    <button
                        title="Export Excel"
                        className="k-button k-primary"
                        onClick={excelExport}
                    >
                        Export to Excel
                    </button>
                </GridToolbar>
                <GridColumn
                    field="processName"
                    title="Name"
                    columnMenu={ContainsMenuFilter}
                />
                <GridColumn
                    field="processType"
                    title="Type"
                    columnMenu={processTypeCheckboxFilter}
                />
                <GridColumn
                    field="completionDate"
                    title="Completed Date"
                    filter={"date"}
                    format="{0:d MMM y h:mm a}"
                    columnMenu={SimplifiedMenuFilter}
                />
                <GridColumn
                    field="reportSubmissionTargetDate"
                    title="Report Submission Target Date"
                    filter={"date"}
                    format="{0:d MMM y h:mm a}"
                    columnMenu={SimplifiedMenuFilter}
                />
                <GridColumn
                    field="powerAppName"
                    title="Power App Job code"
                    columnMenu={ContainsMenuFilter}
                />
            </Grid>
        </ExcelExport>
    );
};

export default SiteWorksGrid;
