import * as React from "react";
import { Button } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { TypedGridCellProps } from "../../../../common/types/TypedGridCellProps";
import { FormControlSiteRiskGridDataItem } from "./FormControlSiteRiskGridDataItem";

interface Props {
    onRemove: (dataItem: FormControlSiteRiskGridDataItem) => void;
}

const FormControlSiteRiskGridActions: React.FC<
    TypedGridCellProps<FormControlSiteRiskGridDataItem> & Props
> = ({ dataItem, onRemove }) => {
    const handleRemove = () => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            onRemove(dataItem);
        }
    };

    return (
        <>
            <Button
                size="sm"
                variant="danger"
                className="ml-1"
                onClick={handleRemove}
            >
                <FaTimes />
            </Button>
        </>
    );
};

export default FormControlSiteRiskGridActions;
