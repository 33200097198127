import * as React from "react";
import { AuditAction } from "../../../common/types/AuditAction";
import AuditActionListItem from "../../../common/components/audit/AuditActionListItem";
import { ProcessTemplate } from "../domain/types";

interface Props {
    template: ProcessTemplate;
}

const ProcessTemplateAuditActionList: React.FC<Props> = ({ template }) => {
    const auditActions = template.auditActions;
    return (
        <div>
            <ul className="timeline">
                {auditActions.map((item: AuditAction, index: number) => (
                    <AuditActionListItem key={index.toString()} data={item} />
                ))}
            </ul>
        </div>
    );
};

export default ProcessTemplateAuditActionList;
