import * as React from "react";
import cx from "classnames";
import { Nav } from "react-bootstrap";
import { IconType } from "react-icons";
import { NavLink } from "react-router-dom";
import SbimConstants from "../../../modules/sbim/constants/SbimConstants";
import useIsClient from "../../hooks/useIsClient";
import "./SideMenu.scss";

export interface NavSubLink {
    label: string;
    to: string;
    icon: IconType;
}

const NavSubMenuItem: React.FC<NavSubLink> = ({
    label,
    icon: Icon,
    to,
}): JSX.Element => {
    const isSBIM = useIsClient(SbimConstants.ClientName);
    return (
        <Nav.Item
            className={cx(
                { "bg-white": !isSBIM },
                { "sub-menu-button-colour": isSBIM },
            )}
        >
            <NavLink
                to={to}
                className="d-flex align-items-center nav-link"
                exact
            >
                <Icon size="14" className="menu-icon-small" />
                <span className="nav-text-small">{label}</span>
            </NavLink>
        </Nav.Item>
    );
};
export default NavSubMenuItem;
