import * as React from "react";
import { ProcessActivity } from "../domain/types";
import { Alert, Button } from "react-bootstrap";
import { ActionBarActionProps } from "./ProcessActivityStatusActionBar";
import ButtonSpinner from "../../../common/components/button/ButtonSpinner";
import { formatIsoDate } from "../../../helpers/dateTimeHelpers";
import { resolveActivityState } from "../helpers/activityStateHelpers";
import ConfirmBox from "../../../common/components/kendo-dialog-boxes/ConfirmBox";

interface Props {
    activity: ProcessActivity;
    open: ActionBarActionProps;
}

const ActivityCompleteAlert: React.FC<Props> = (props) => {
    const { activity, open } = props;

    const { canChangeStatus } = resolveActivityState(activity);

    const confirmationRequired = activity.confirmation ?? false;

    const [showConfirmation, setShowConfirmation] = React.useState(false);

    const clickOpen = (): void => {
        if (confirmationRequired) {
            setShowConfirmation(true);
        } else {
            open.actionCallback({ activity });
        }
    };

    if (canChangeStatus) {
        return (
            <Alert variant="success">
                <Alert.Heading>Activity Complete</Alert.Heading>
                <p>
                    This activity was completed by {activity.completedBy} at{" "}
                    {formatIsoDate(activity.completedAt)}. You can reset the
                    form into the open state below.
                </p>
                <hr />
                <div className="d-flex justify-content-end">
                    <Button
                        variant="danger"
                        onClick={clickOpen}
                        disabled={open.actionResult.isLoading}
                    >
                        Open
                        {open.actionResult.isLoading && <ButtonSpinner />}
                    </Button>
                    {showConfirmation && (
                        <ConfirmBox
                            title="Open"
                            message="Are you sure you want to open this activity? Opening this item will open all parent activities."
                            onCancel={() => setShowConfirmation(false)}
                            onConfirm={() => open.actionCallback({ activity })}
                        />
                    )}
                </div>
            </Alert>
        );
    } else {
        return (
            <Alert variant="success">
                <Alert.Heading>Activity Complete</Alert.Heading>
                <p>
                    This activity was completed by {activity.completedBy} at{" "}
                    {formatIsoDate(activity.completedAt)}.
                </p>
            </Alert>
        );
    }
};

export default ActivityCompleteAlert;
