import * as React from "react";
import Breadcrumbs from "../../../../common/components/breadcrumbs/Breadcrumbs";
import { useOrgTitleWithShortName } from "../../../../common/hooks/useTitle";
import { useOrganisationId } from "../../../organisation/hooks/useOrganisationId";
import BatchCreateButton from "../components/batching/BatchCreateButton";
import BatchesGrid from "../components/batching/BatchesGrid";
import BatchExamsSummary from "../components/batching/BatchExamsSummary";
import pageTitles from "./pageTitles";

const BatchingOverviewPage: React.FC = () => {
    useOrgTitleWithShortName(pageTitles.batchCreate);
    const { organisationId } = useOrganisationId();

    return (
        <>
            <div className="mt-3">
                <Breadcrumbs items={[["Batching"]]}></Breadcrumbs>
            </div>
            <div>
                <h1>Batching Overview</h1>
            </div>
            <div className="mt-2">
                <BatchExamsSummary organisationId={organisationId} />
            </div>
            <div className="mt-4">
                <BatchCreateButton organisationId={organisationId} />
            </div>
            <div className="mt-2">
                <BatchesGrid organisationId={organisationId} />
            </div>
        </>
    );
};

export default BatchingOverviewPage;
