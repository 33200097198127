import * as React from "react";
import { Breadcrumb } from "react-bootstrap";
import { useParams } from "react-router";
import { useTitle } from "../../../../common/hooks/useTitle";
import "./DefectDetailsPage.scss";
import { Organisation } from "../../../organisation/domain/types";
import { useGetOrgByShortNameQuery } from "../../../../store/features/organisation/organisation-api-slice";
import DefectDetails from "../containers/DefectDetails";
import DefectHistory from "../containers/DefectHistory";
import { useGetDefectQuery } from "../../queries/defectApiSlice";
import { useGetProcessActivityFormByIdQuery } from "../../../process-activity-form/store/processActivityFormApiSlice";
import CoveringLoadingPanel from "../../../../common/components/CoveringLoadingPanel";
import DefectAssets from "../containers/DefectAssets";

interface Props {
    organisation: Organisation;
}

export const DefectDetailsPage: React.FC<Props> = () => {
    const { defectId, orgShortName } = useParams<{
        defectId: string;
        orgShortName: string;
    }>();

    const { data: organisation } = useGetOrgByShortNameQuery(orgShortName, {
        skip: !orgShortName,
    });

    const { data: defect, isLoading: isDefectLoading } = useGetDefectQuery(
        {
            organisationId: organisation?.id || "",
            id: defectId,
        },
        { skip: !organisation?.id || !defectId },
    );

    const latestActivity =
        defect?.associatedActivities?.[defect.associatedActivities.length - 1];

    useTitle(defect?.displayName);

    const { data: currentDefect, isLoading: isFormLoading } =
        useGetProcessActivityFormByIdQuery(
            {
                activityId: latestActivity?.processActivityId || "",
                processId: latestActivity?.processId || "",
            },
            { skip: !latestActivity },
        );

    if (isDefectLoading || isFormLoading) {
        return <CoveringLoadingPanel />;
    }

    return (
        <>
            {defect && (
                <div className="py-4 defect-details-page">
                    <Breadcrumb>
                        <Breadcrumb.Item>Defects</Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            {defect.displayName}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <DefectDetails
                        defect={defect}
                        orgShortName={orgShortName}
                        organisation={organisation}
                    />
                    <div className="preview">
                        <div className="preview-title">Latest Details:</div>
                        {currentDefect?.content.map(
                            ({ field, type, label, value }) =>
                                type === "header" ? (
                                    <h4 key={field} className="category-title">
                                        {label}
                                    </h4>
                                ) : (
                                    type !== "system" && (
                                        <div key={field} className="item">
                                            <div>{label}</div>
                                            <div>{value}</div>
                                        </div>
                                    )
                                ),
                        )}
                    </div>
                    {defect.associatedAssets.length > 0 && (
                        <DefectAssets
                            assetId={defect.associatedAssets}
                            organisation={organisation}
                        />
                    )}
                    {defect.associatedActivities.length > 1 && (
                        <DefectHistory
                            defect={defect}
                            orgShortName={orgShortName}
                        />
                    )}
                </div>
            )}
        </>
    );
};
