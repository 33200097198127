import { HttpMethod } from "../common/http/HttpMethod";
import RoleConstants from "../Constants/RoleConstants";
import authFetch from "../helpers/ApiHelper";
import { AdminRole, Role, RoleUser } from "../modules/admin/role/domain/types";

const RoleService = {
    getRoles: (): Promise<Role[]> => {
        return authFetch(`${RoleConstants.ApiEndpoint}`, {
            method: HttpMethod.Get,
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => response.json());
    },
    getRole: (id: string): Promise<AdminRole> => {
        return authFetch(`${RoleConstants.ApiEndpoint}/${id}`, {
            method: HttpMethod.Get,
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => response.json());
    },
    removeUserRole: (roleId: string, userId: string): Promise<boolean> => {
        return authFetch(
            `${RoleConstants.ApiEndpoint}/${roleId}${RoleConstants.user}/${userId}`,
            {
                method: HttpMethod.Delete,
                headers: {
                    "Content-Type": "application/json",
                },
            },
        ).then((response) => {
            if (response.ok) {
                return true;
            } else {
                // Raise an exception to reject the promise and trigger the outer .catch() handler.
                // By default, an error response status (4xx, 5xx) does NOT cause the promise to reject!
                throw Error(response.statusText);
            }
        });
    },
    addUserRole: (roleId: string, userId: string): Promise<boolean> => {
        return authFetch(
            `${RoleConstants.ApiEndpoint}/${roleId}${RoleConstants.user}`,
            {
                method: HttpMethod.Post,
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    roleId: roleId,
                    userId: userId,
                }),
            },
        ).then((response) => {
            if (response.ok) {
                return true;
            } else {
                // Raise an exception to reject the promise and trigger the outer .catch() handler.
                // By default, an error response status (4xx, 5xx) does NOT cause the promise to reject!
                throw Error(response.statusText);
            }
        });
    },
    getAvailableUsers: (roleId: string): Promise<RoleUser[]> => {
        return authFetch(
            `${RoleConstants.ApiEndpoint}/${roleId}${RoleConstants.availableUsers}`,
            {
                method: HttpMethod.Get,
                headers: {
                    "Content-Type": "application/json",
                },
            },
        ).then((response) => response.json());
    },
    getRoleUsers: (roleId: string): Promise<RoleUser[]> => {
        return authFetch(
            `${RoleConstants.ApiEndpoint}/${roleId}${RoleConstants.user}`,
            {
                method: HttpMethod.Get,
                headers: {
                    "Content-Type": "application/json",
                },
            },
        ).then((response) => response.json());
    },
};

export default RoleService;
