import {
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from "react-query";
import pdfReportSettingsQueryKeys from "./queryKeys";
import React, { useCallback } from "react";
import { PhotoOrder, PhotoOrderSettings } from "../types";
import {
    deletePhotoOrder,
    getPhotoOrder,
    postPhotoOrder,
} from "../services/api";

export const useGetPdfPhotoOrderByProcessId = (
    processId: string,
): UseQueryResult<PhotoOrderSettings> => {
    const getReview = useCallback(() => {
        return getPhotoOrder(processId);
    }, [processId]);

    const queryKeys = React.useMemo(
        () => pdfReportSettingsQueryKeys.photoOrder(processId),
        [processId],
    );

    return useQuery(queryKeys, getReview);
};

export const usePostPdfPhotoOrderByProcessIdMutation = (
    processId: string,
): UseMutationResult<
    PhotoOrder[],
    unknown,
    {
        processId: string;
        photoOrder: PhotoOrder[];
    }
> => {
    const queryClient = useQueryClient();

    const queryKeys = React.useMemo(
        () => pdfReportSettingsQueryKeys.photoOrder(processId),
        [processId],
    );

    return useMutation(postPhotoOrder, {
        onSuccess(_result) {
            return queryClient.invalidateQueries(queryKeys);
        },
    });
};

export const useDeletePdfPhotoOrderByProcessIdMutation = (
    processId: string,
): UseMutationResult<
    PhotoOrder[],
    unknown,
    {
        processId: string;
    }
> => {
    const queryClient = useQueryClient();

    const queryKeys = React.useMemo(
        () => pdfReportSettingsQueryKeys.photoOrder(processId),
        [processId],
    );

    return useMutation(deletePhotoOrder, {
        onSuccess(_result) {
            return queryClient.invalidateQueries(queryKeys);
        },
    });
};
