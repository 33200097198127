import * as React from "react";
import { Row, Col, Card, Button, Collapse } from "react-bootstrap";
import { useParams } from "react-router";
import CoveringLoadingPanel from "../../../../common/components/CoveringLoadingPanel";
import { useOrganisationId } from "../../../organisation/hooks/useOrganisationId";
import { useGetProcessActivityGroupByIdQuery } from "../../../process-activity-group/store/processActivityGroupApiSlice";
import ExaminationApproval from "../components/ExaminationApproval/ExaminationApproval";
import ExaminationDefectsGrid from "../components/ExaminationDefectsGrid/ExaminationDefectsGrid";
import ExaminationRecommendationsGrid from "../components/ExaminationRecommendationsGrid/ExaminationRecommendationsGrid";
import ReviewFindings from "../components/ReviewFindings/ReviewFindings";
import ReviewSummary from "../components/ReviewSummary/ReviewSummary";
import UnexaminedParts from "../components/UnexaminedParts/UnexaminedParts";
import { useGetExaminationReviewById } from "../query/examinationsReviewQueries";
import { useNavigation } from "../../../../router/useNavigate";
import { FaAngleDown, FaAngleUp, FaCogs } from "react-icons/fa";
import { CefaEngineerContextBar } from "../components/CefaEngineerContextBar";
import { ExamProvider } from "../query/examinationsContextProvider";
import ProcessActivityAlertBar from "../../../process-activity/components/ProcessActivityAlertBar";

const ExaminationReviewDetailsPage: React.FC = () => {
    const { reviewId, orgShortName } = useParams<{
        reviewId: string;
        orgShortName: string;
    }>();
    const [summaryOpen, setSummaryOpen] = React.useState(true);

    const { organisationId, isLoadingOrgId } = useOrganisationId();
    const { navigateToOrgPath } = useNavigation();

    const { data: review, isFetching: isLoadingReview } =
        useGetExaminationReviewById(organisationId, reviewId);
    const { data: reviewPaGroup, isLoading: isLoadingReviewPaGroup } =
        useGetProcessActivityGroupByIdQuery(
            {
                activityId: reviewId,
                processId: review?.processId,
            },
            {
                skip: !review?.processId ?? !"",
            },
        );

    if (isLoadingOrgId || isLoadingReview || isLoadingReviewPaGroup)
        return <CoveringLoadingPanel />;

    if (!review) return <></>;

    return (
        <ExamProvider>
            {!isLoadingReview && !isLoadingReviewPaGroup && (
                <CefaEngineerContextBar
                    organisationId={organisationId}
                    review={review}
                    reviewPaGroup={reviewPaGroup}
                />
            )}
            <ProcessActivityAlertBar
                alerts={reviewPaGroup.alerts.filter(
                    (a) => a.title !== "Preview Report",
                )}
            />
            <Row className="mb-2">
                <Col xs={12} lg="auto">
                    <Card>
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <div>
                                <Button
                                    onClick={() => setSummaryOpen(!summaryOpen)}
                                    variant="link"
                                    className="mr-2 p-0"
                                    style={{ color: "#35373d" }}
                                    aria-controls="summaryOpen"
                                    aria-expanded={summaryOpen}
                                >
                                    {summaryOpen ? (
                                        <FaAngleUp />
                                    ) : (
                                        <FaAngleDown />
                                    )}
                                </Button>
                                <span>Summary</span>
                            </div>
                            <Button
                                variant="link"
                                className="ml-2 p-0"
                                style={{ color: "#35373d" }}
                                onClick={() =>
                                    navigateToOrgPath(
                                        `/${orgShortName}/process/${review.processId}`,
                                    )
                                }
                            >
                                <FaCogs />
                            </Button>
                        </Card.Header>
                        <Collapse in={summaryOpen}>
                            <Card.Body id="summaryOpen">
                                <ReviewSummary review={review} />
                            </Card.Body>
                        </Collapse>
                    </Card>
                </Col>
                <Col xs={12} lg>
                    <UnexaminedParts
                        processId={review.processId}
                        reviewId={review.id}
                        organisationId={organisationId}
                    />
                </Col>
            </Row>
            <Row className="mb-2">
                <Col xs={12}>
                    <ExaminationDefectsGrid
                        organisationId={organisationId}
                        reviewId={reviewId}
                    />
                </Col>
            </Row>
            <Row className="mb-2">
                <Col xs={12}>
                    <ExaminationRecommendationsGrid
                        organisationId={organisationId}
                        reviewId={reviewId}
                    />
                </Col>
            </Row>
            <Row className="mb-2">
                <Col xs={12} lg={6}>
                    <ExaminationApproval
                        orgShortName={orgShortName}
                        processId={review.processId}
                        activityId={review.approvalActivityId}
                    />
                </Col>
                <Col xs={12} lg={6}>
                    <ReviewFindings
                        reviewId={review.id}
                        processId={review.processId}
                        organisationId={organisationId}
                    />
                </Col>
            </Row>
        </ExamProvider>
    );
};

export default ExaminationReviewDetailsPage;
