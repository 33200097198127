import * as React from "react";
import { Card } from "react-bootstrap";
import { resolveActivityState } from "../../process-activity/helpers/activityStateHelpers";
import ApprovalList from "../components/ApprovalList";
import { Approval, ProcessActivityApproval } from "../domain/types";
import ApprovalResponsesHistory from "./ApprovalResponsesHistory";
import ApprovalWrapper from "./ApprovalWrapper";
import TargetFileCard from "./TargetFileCard";
import TargetLinkCard from "./TargetLinkCard";

interface Props {
    organisationId: string;
    activity: ProcessActivityApproval;
    approvalForCurrentUser: Approval;
    approversDisplayName: string;
    approvalTitle: string;
}

const ProcessActivityApprovalContent: React.FC<Props> = ({
    organisationId,
    activity,
    approvalForCurrentUser,
    approversDisplayName,
    approvalTitle,
}) => {
    const { canEdit } = resolveActivityState(activity);

    return (
        <>
            <div className="mt-4">
                <TargetFileCard
                    target={activity.targetFile}
                    activityId={activity.id}
                />
            </div>
            <div className="mt-4">
                <TargetLinkCard target={activity.targetLink} />
            </div>

            {canEdit && approvalForCurrentUser && (
                <div className="mt-4">
                    <ApprovalWrapper
                        activity={activity}
                        approval={approvalForCurrentUser}
                        title={approvalTitle}
                    />
                </div>
            )}
            <div className="mt-4">
                <Card>
                    <Card.Body>
                        <Card.Title>
                            {approversDisplayName ?? "Approvers"}
                        </Card.Title>
                        <div className="pt-2">
                            <ApprovalList
                                organisationId={organisationId}
                                activity={activity}
                                approvals={activity.approvals}
                                isReadOnly={!canEdit}
                            />
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <div className="mt-4">
                <Card>
                    <Card.Body>
                        <Card.Title>History</Card.Title>
                        <ApprovalResponsesHistory
                            responses={activity.approvalResponses}
                            processActivityApproval={activity}
                        />
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};

export default ProcessActivityApprovalContent;
