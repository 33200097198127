import * as React from "react";
import { Row, Col, Breadcrumb } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";
import severnPageTitles from "./pageTitles";
import SiteWorksGrid from "../components/SiteWorksGrid";
import { useGetSiteWorksQuery } from "../store/severn-api-slice";
import QueryResultStatus from "../../../common/components/QueryResultStatus";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";

const OrgReportsSiteWorksPage: React.FC<
    RouteComponentProps<{ orgShortName: string }>
> = (props) => {
    const orgShortName = props.match.params.orgShortName;

    const { organisationId, isLoadingOrgId } = useOrganisationId();
    const { data: siteWorks, ...queryResult } = useGetSiteWorksQuery(
        organisationId,
        { skip: isLoadingOrgId },
    );

    useOrgTitleWithShortName(severnPageTitles.siteWorks);

    return (
        <>
            <Row className="pt-2">
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={"div"}>
                            <Link
                                to={`/${orgShortName}${OrganisationConstants.Report}`}
                            >
                                Reports
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            {severnPageTitles.siteWorks}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>{severnPageTitles.siteWorks}</h1>
                </Col>
            </Row>
            <Row className="top10">
                <Col>
                    <QueryResultStatus queryResult={queryResult} />
                    {siteWorks && (
                        <SiteWorksGrid
                            siteWorks={siteWorks}
                            orgShortName={orgShortName}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default OrgReportsSiteWorksPage;
