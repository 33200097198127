import * as React from "react";
import cx from "classnames";
import { Nav } from "react-bootstrap";
import { IconType } from "react-icons";
import SbimConstants from "../../modules/sbim/constants/SbimConstants";
import useIsClient from "../hooks/useIsClient";
interface NavLinkProps {
    label: string;
    icon: IconType;
    to: string;
    eventKey: string;
    additionalClasses?: string;
}

export default function NavLinkExternal({
    label,
    icon: Icon,
    to,
    eventKey,
    additionalClasses,
}: NavLinkProps): JSX.Element {
    const isSBIM = useIsClient(SbimConstants.ClientName);
    return (
        <Nav.Item className={additionalClasses}>
            <Nav.Link
                href={to}
                eventKey={eventKey}
                className={cx("d-flex align-items-center", {
                    "button-override": isSBIM,
                })}
                target={"_blank"}
            >
                <Icon size="16" className="menu-icon" />
                <span className="navbar-font-override">{label}</span>
            </Nav.Link>
        </Nav.Item>
    );
}
