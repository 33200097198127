import { dcpApi, Tags } from "./../dcpApi";
import FormTemplatesConstants from "../../../Constants/FormTemplatesConstants";
import {
    TemplateCreateModel,
    TemplateUpdateModel,
    Template,
    TemplateCreateResponse,
} from "../../../modules/template/domain/types";

export const formTemplateApi = dcpApi.injectEndpoints({
    endpoints: (builder) => ({
        postFormTemplate: builder.mutation<
            TemplateCreateResponse,
            TemplateCreateModel
        >({
            query: (body) => ({
                url: `${FormTemplatesConstants.ResourceName}`,
                method: "POST",
                body,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: Tags.OrganisationFormTemplate, id: arg.organisationId },
            ],
        }),
        putFormTemplateById: builder.mutation<
            void,
            Pick<TemplateUpdateModel, "id"> & Partial<TemplateUpdateModel>
        >({
            query: ({ id, ...patch }) => ({
                url: `${FormTemplatesConstants.ResourceName}/${id}`,
                method: "PUT",
                body: patch,
            }),
            invalidatesTags: (result, error, arg) => [
                { type: Tags.FormTemplate, id: arg.id },
                { type: Tags.OrganisationFormTemplate, id: arg.organisationId },
            ],
        }),
        getFormTemplateById: builder.query<Template, string>({
            query: (templateID) =>
                `${FormTemplatesConstants.ResourceName}/${templateID}`,
            providesTags: (result, error, id) => [
                { type: Tags.FormTemplate, id },
            ],
        }),
        getFormTemplateByOrgId: builder.query<Template[], string>({
            query: (organisationId) =>
                `${FormTemplatesConstants.ResourceName}?organisationId=${organisationId}`,
            providesTags: (result, error, id) => [
                { type: Tags.OrganisationFormTemplate, id },
            ],
        }),
        deleteFormTemplate: builder.mutation<
            void,
            { id: string; organisationId: string }
        >({
            query: (assetTemplate) => ({
                url: `${FormTemplatesConstants.ResourceName}/${assetTemplate.id}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, formTemplate) => [
                {
                    type: Tags.FormTemplate,
                    id: formTemplate.id,
                },
                {
                    type: Tags.OrganisationFormTemplate,
                    id: formTemplate.organisationId,
                },
            ],
        }),
    }),
});

export const {
    useGetFormTemplateByIdQuery,
    usePostFormTemplateMutation,
    usePutFormTemplateByIdMutation,
    useGetFormTemplateByOrgIdQuery,
    useDeleteFormTemplateMutation,
} = formTemplateApi;
