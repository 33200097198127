import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useGetAssetTemplateByOrgIdQuery } from "../../../../store/features/asset-template/assettemplate-api-slice";
import TemplatesList from "../../../template/containers/TemplatesList";

const AssetTemplateManagement: React.FC = () => {
    const { id: organisationId } = useParams<{ id: string }>();

    return (
        <Row>
            <Col>
                <Row className="top10">
                    <Col>
                        <TemplatesList
                            organisationId={organisationId}
                            getTemplate={useGetAssetTemplateByOrgIdQuery}
                            redirectPath="/asset-template"
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default AssetTemplateManagement;
