import { dcpApi, Tags } from "../../../store/features/dcpApi";
import { ProcessCompleteDto, SiteWorkDto } from "../domain/types";

const apiSubPath = "/severn";

export const severnApi = dcpApi.injectEndpoints({
    endpoints: (builder) => ({
        getSiteWorks: builder.query<SiteWorkDto[], string>({
            query: (organisationId) => ({
                url: `${apiSubPath}/${organisationId}/siteWorks`,
                method: "GET",
            }),
            providesTags: () => [Tags.ProcessList, Tags.ProcessActivities],
        }),
        getProcessesCompleted: builder.query<ProcessCompleteDto[], string>({
            query: (organisationId) => ({
                url: `${apiSubPath}/${organisationId}/completed-report`,
                method: "GET",
            }),
            providesTags: () => [Tags.ProcessList, Tags.ProcessActivities],
        }),
    }),
});

export const { useGetSiteWorksQuery, useGetProcessesCompletedQuery } =
    severnApi;
