import * as React from "react";
import { TypedGridCellProps } from "../../../../../../common/types/TypedGridCellProps";
import { CefaFormControlFileTableDataItem } from "./CefaFormControlFileTableDataItem";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { formatCefaComment } from "../../../helpers/commentHelper";

interface Props {
    cell: TypedGridCellProps<CefaFormControlFileTableDataItem>;
}

const FormControlFileTableCaptionDateCell: React.FC<Props> = (props) => {
    const { cell } = props;
    const [captionDate, setCaptionDate] = React.useState<Date | string>(
        cell.dataItem.captionDate,
    );

    const onChange = (e) => {
        if (cell.onChange) {
            // When the caption date cell is changed, the comment field gets updated so that
            // it contains the comment and the caption date (delimited)
            cell.onChange({
                dataIndex: 0,
                dataItem: cell.dataItem,
                field: "comment",
                syntheticEvent: e.syntheticEvent,
                value: formatCefaComment(cell.dataItem.comment, e.target.value),
            });
            setCaptionDate(e.target.value);
        }
    };

    const initialDate =
        captionDate instanceof Date && !isNaN(captionDate.getTime())
            ? captionDate
            : null;

    // Set time to 00:00:00 in initial date
    if (initialDate) {
        initialDate.setHours(0, 0, 0, 0);
    }

    return (
        <DatePicker
            defaultValue={initialDate}
            format="dd/MM/yy"
            onChange={onChange}
            width={130}
            placeholder=""
            formatPlaceholder="formatPattern"
            disabled={cell.dataItem.readOnly}
        />
    );
};

export default FormControlFileTableCaptionDateCell;
