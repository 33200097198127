import * as React from "react";
import { Alert } from "react-bootstrap";

const Unauthorised: React.FC = () => {
    return (
        <div className="py-2">
            <Alert variant="danger">
                <Alert.Heading>Unauthorised</Alert.Heading>
                <p>You are not authorised to view this content.</p>
            </Alert>
        </div>
    );
};

export default Unauthorised;
