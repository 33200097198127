import { State } from "@progress/kendo-data-query";
import { GridColumnProps } from "@progress/kendo-react-grid";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AssetTemplateModel } from "../asset/asset-api-slice";

export type GridColumnDef = GridColumnProps & {
    field: string;
    title: string;
    ariaColumnIndex?: number;
    headerClassName?: string;
    id?: string;
    isAccessible?: boolean;
    orderIndex?: number;
    reorderable?: boolean;
    resizable?: boolean;
    sortable?: boolean;
    width?: number;
};

export interface AssetsGridState {
    selectedTemplates?: AssetTemplateModel[];
    selectedColumns?: GridColumnDef[];
    gridState?: State;
}

const initialState: AssetsGridState = {
    selectedTemplates: [],
    selectedColumns: [],
    gridState: {},
};

export const assetsGridFiltersSlice = createSlice({
    name: "assetGridFilters",
    initialState,
    reducers: {
        setSelectedTemplates: (
            state,
            action: PayloadAction<AssetTemplateModel[]>,
        ) => {
            state.selectedTemplates = action.payload.filter((t) => Boolean(t));
        },
        setSelectedColumns: (state, action: PayloadAction<GridColumnDef[]>) => {
            state.selectedColumns = action.payload.filter((t) => Boolean(t));
        },
        setGridState: (state, action: PayloadAction<State>) => {
            state.gridState = action.payload;
        },
        removeAllFilters: (state) => {
            state.selectedTemplates = initialState.selectedTemplates;
            state.selectedColumns = initialState.selectedColumns;
        },
    },
});

export const {
    setSelectedTemplates,
    setSelectedColumns,
    setGridState,
    removeAllFilters,
} = assetsGridFiltersSlice.actions;

export const reducer = assetsGridFiltersSlice.reducer;
