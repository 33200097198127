import { QueryKey } from "react-query";
import { searchOptionsKey } from "../../../../common/types/SortAndFilterOptions";
import { ExamRequestsSearchOptions } from "../services/examRequestsService";

export const examRequestsKey = "exam-requests";

const examRequestsQueryKeys = {
    examRequests: (orgId: string): QueryKey => [
        examRequestsKey,
        `${examRequestsKey}-${orgId}`,
    ],
    filteredExamRequests: (
        orgId: string,
        searchOptions: ExamRequestsSearchOptions,
    ): QueryKey => [
        examRequestsKey,
        `${examRequestsKey}-${orgId}`,
        `contractYear:${searchOptions.contractYear}`,
        ...searchOptionsKey(searchOptions),
    ],
};

export default examRequestsQueryKeys;
