export const ApplicationName = "Web";

export const BaseUrl = document
    .getElementsByTagName("base")[0]
    .getAttribute("href");

export const QueryParameterNames = {
    ReturnUrl: "returnUrl",
    Message: "message",
};

export const ApplicationPaths = {
    DefaultLoginRedirectPath: "/",
};
