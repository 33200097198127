export interface Invitation {
    email: string;
    status: InvitationStatus;
    createdAt: string;
    updatedAt: string;
}

export const enum InvitationStatus {
    Pending = "Pending",
    Accepted = "Accepted",
}
