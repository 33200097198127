import { FormikContextType } from "formik";
import { DynamicFormValues } from "../types/dynamicFormTypes";
import { FormControlSelectConfig, Option, State } from "./FormControlSelect";

export class StateSingle implements State {
    formik: FormikContextType<DynamicFormValues>;
    options: Option[];
    config: FormControlSelectConfig;

    constructor(
        formik: FormikContextType<DynamicFormValues>,
        options: Option[],
        config: FormControlSelectConfig,
    ) {
        this.formik = formik;
        this.options = options;
        this.config = config;
    }

    convertFormikValueToOption = (): Option => {
        return this.options.find(
            (a) => a?.value === this.formik.values[this.config.field],
        );
    };

    onChangeHandler = (newValue: Option): void => {
        if (newValue !== null) {
            this.formik.setFieldValue(this.config.field, newValue.value);
        } else {
            this.formik.setFieldValue(this.config.field, "");
        }
    };

    disabledFieldValidation(): void {
        const option = this.config.options.find(
            (opt) => opt.value === this.formik.values[this.config.field],
        );
        const msg = "This value is disabled";
        if (option?.disabled) {
            this.formik.errors[this.config.field] = msg;
        } else if (this.formik.errors[this.config.field] === msg) {
            delete this.formik.errors[this.config.field];
        }
    }
}
