import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import React from "react";
import { Link } from "react-router-dom";
import { TypedGridCellProps } from "../../../common/types/TypedGridCellProps";
import { ActivityAssetPreview } from "../../../store/features/process-activity/types";
import { useActivityNavigation } from "../hooks/useActivityNavigation";

interface Props {
    cell: TypedGridCellProps<ActivityAssetPreview>;
    orgShortName: string;
    processId: string;
}
export const AssetLinkListNameCell: React.FC<Props> = ({
    cell,
    orgShortName,
    processId,
}) => {
    const navigationAttributes = useTableKeyboardNavigation(cell.id);
    const { getActivityUrl } = useActivityNavigation(orgShortName, processId);
    const attributes = cell.dataItem.mirrored
        ? {}
        : { [GRID_COL_INDEX_ATTRIBUTE]: cell.columnIndex };

    return (
        <td
            colSpan={cell.colSpan}
            role={"gridcell"}
            aria-colindex={cell.ariaColumnIndex}
            aria-selected={cell.isSelected}
            {...attributes}
            {...navigationAttributes}
        >
            {cell.dataItem.name}
            {cell.dataItem.mirrored && (
                <span className="ml-2">
                    <span className="text-muted">(Inherited from </span>
                    <Link
                        className="text-primary"
                        to={getActivityUrl(
                            cell.dataItem.mirrorActivityId,
                            cell.dataItem.mirrorProcessId,
                        )}
                    >
                        {cell.dataItem.mirrorActivityName}
                    </Link>
                    <span className="text-muted">)</span>
                </span>
            )}
        </td>
    );
};
