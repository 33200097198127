import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { Redirect, useParams } from "react-router";
import useViewPermissions, {
    PermissionHookResponse,
} from "../../../common/hooks/useViewPermissions";
import { WithOrgShortName } from "../../../common/types/WithOrg";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { AppRoutes } from "../../../router/AppRoutes";
import { useGetOrgByShortNameQuery } from "../../../store/features/organisation/organisation-api-slice";
import SbimConstants from "../../sbim/constants/SbimConstants";
import useIsClient from "../../../common/hooks/useIsClient";

const getRedirectRoute = (
    orgShortName: string,
    permissions: PermissionHookResponse,
): string => {
    if (permissions.canViewProcesses)
        return `/${orgShortName}${OrganisationConstants.Process}`;

    if (permissions.canViewAssets)
        return `/${orgShortName}${OrganisationConstants.Asset}`;

    if (permissions.canViewScheduler)
        return AppRoutes.Scheduler.toLink({ orgShortName });

    if (permissions.canViewFiles)
        return `/${orgShortName}/${OrganisationConstants.files}`;

    if (permissions.canViewHelpSection)
        return `/${orgShortName}/${OrganisationConstants.help}`;

    // Cefa specific landing pages
    if (permissions.canApc) return AppRoutes.Apc.toLink({ orgShortName });
    if (permissions.canViewExaminationsSchedule)
        return AppRoutes.SchedulerExaminations.toLink({ orgShortName });
    if (permissions.canViewExaminationsReview)
        return AppRoutes.EngineerReview.toLink({ orgShortName });
    if (permissions.canMyWork) return AppRoutes.MyWork.toLink({ orgShortName });

    if (permissions.canViewUsers)
        return `/${orgShortName}/${OrganisationConstants.users}`;

    return "";
};

const OrganisationHomeRedirectPage: React.FC = () => {
    const { orgShortName } = useParams<WithOrgShortName>();
    const isSBIM = useIsClient(SbimConstants.ClientName);
    const { data: organisation } = useGetOrgByShortNameQuery(orgShortName, {
        skip: !orgShortName,
    });

    const permissions = useViewPermissions(organisation.id);
    const redirectPath = isSBIM
        ? AppRoutes.OrganisationOverview.toLink({ orgShortName })
        : getRedirectRoute(orgShortName, permissions);

    if (redirectPath) {
        return <Redirect to={redirectPath} />;
    }

    return (
        <Row className="top10">
            <Col>
                <p className="text-center">
                    Please request access to {organisation.name} resources from
                    Organisation Administrators.
                </p>
            </Col>
        </Row>
    );
};

export default OrganisationHomeRedirectPage;
