import { process } from "@progress/kendo-data-query";
import {
    getSelectedState,
    Grid,
    GridColumn,
    GridNoRecords,
    GridRowDoubleClickEvent,
    GridRowProps,
    GridSelectionChangeEvent,
    GridToolbar,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import classNames from "classnames";
import * as React from "react";
import { commonFilterOperators } from "../../../../../common/components/table/kendo/columnFilters";
import GridLoadingPanel from "../../../../../common/components/table/kendo/GridLoadingPanel";
import PermissionConstants from "../../../../../common/permissions/PermissionConstants";
import { AppRoutes } from "../../../../../router/AppRoutes";
import { useNavigation } from "../../../../../router/useNavigate";
import { hasUserOrgPermission } from "../../../../../store/features/user/user-api-slice";
import {
    TemplateContentFilter,
    TemplateContentFilterOperator,
    TemplateContentFilterProperty,
} from "../../../../common/filters/domain/types";
import {
    AssetInfoContentFieldNames,
    PlanningInfoContentFieldNames,
} from "../../../constants/ProcessActivityContentFieldsConsts";
import { ExaminationReview } from "../../domain/examinationsReview";
import {
    useAssignExaminationReviewsMutation,
    useGetExaminationReviews,
} from "../../query/examinationsReviewQueries";
import "./examinationReviewsGrid.scss";
import { betweenDates, BetweenDatesFilterCell } from "./BetweenDatesFilterCell";
import { SelectedState } from "../../../../../common/components/table/kendo/SelectedState";
import DaysSinceExamFilterCell, {
    daysSinceExamInRange,
} from "./DaysSinceExamFilterCell";
import { AreaMultiSelectFilterCell, isIn } from "./AreaMultiSelectFilterCell";
import { usePersistedExaminationReviewGridState } from "../../hooks/usePersistedExaminationReviewGridState";
import { Button } from "react-bootstrap";
import AssignModal from "../AssignModal";
import {
    doesNotContainIsIn,
    ExaminerAndEngineerMultiSelectFilterCell,
    UserType,
} from "./ExaminerAndEngineerMultiSelectFilterCell";
import {
    MultiSelect,
    MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { FilterDescriptor } from "@progress/kendo-react-dropdowns/dist/npm/common/filterDescriptor";
import { YesNoFilterCell } from "./YesNoFilterCell";

const usersFilter: TemplateContentFilter = {
    property: TemplateContentFilterProperty.UserGroup,
    operator: TemplateContentFilterOperator.Any,
    values: ["Engineer"],
};

interface Props {
    organisationId: string;
}

// Function to add a custom filter to the grid state
function applyCustomFilter(gridState, operatorFunc, field) {
    if (!gridState?.filter?.filters) {
        return gridState;
    }

    // Update the filters with the new operator for the specified field
    const newFilters = gridState.filter.filters.map((filter) =>
        filter.field === field ? { ...filter, operator: operatorFunc } : filter,
    );

    // Return a new grid state with updated filters
    return {
        ...gridState,
        filter: {
            ...gridState.filter,
            filters: newFilters,
        },
    };
}

function applyCustomFiltersOperator(gridState) {
    if (!gridState?.filter?.filters?.length) {
        return {
            ...gridState,
            filter: null,
        };
    }

    const filtersOperatorToApply = [
        { operatorFunc: isIn, field: "area" },
        { operatorFunc: daysSinceExamInRange, field: "daysSinceExamDate" },
        { operatorFunc: betweenDates, field: "examinationDate" },
        { operatorFunc: doesNotContainIsIn, field: "assignedEngineer" },
        { operatorFunc: doesNotContainIsIn, field: "assignedExaminer" },
    ];

    return filtersOperatorToApply.reduce(
        (updatedGridState, { operatorFunc, field }) =>
            applyCustomFilter(updatedGridState, operatorFunc, field),
        gridState,
    );
}

const ExaminationReviewsGrid: React.FC<Props> = ({ organisationId }) => {
    const SELECTED_FIELD = "selected";
    const DATA_ITEM_KEY = "id";
    const idGetter = getter(DATA_ITEM_KEY);

    const { navigateToWithOrg } = useNavigation();
    const wrapperRef = React.createRef<HTMLElement>();
    const [selectedState, setSelectedState] = React.useState<SelectedState>({});
    const [assignActive, setAssignActive] = React.useState<boolean>(false);

    const canAssignUsers = hasUserOrgPermission(
        organisationId,
        PermissionConstants.ExaminationReviews.assign,
    ).hasPermission;

    const {
        setSelectedColumns,
        gridSelectedColumns,
        gridState,
        setGridState,
        setColumnsProps,
        gridColumnProps,
    } = usePersistedExaminationReviewGridState();

    const multiSelectAreaFilter = (props) => {
        return (
            <AreaMultiSelectFilterCell {...props} examinations={examinations} />
        );
    };

    const multiSelectExaminerFilter = (props) => {
        return (
            <ExaminerAndEngineerMultiSelectFilterCell
                {...props}
                organisationId={organisationId}
                userType={UserType.Examiner}
            />
        );
    };

    const multiSelectEngineerFilter = (props) => {
        return (
            <ExaminerAndEngineerMultiSelectFilterCell
                {...props}
                organisationId={organisationId}
                userType={UserType.Engineer}
            />
        );
    };

    const columnConfigurations = [
        {
            key: "area",
            field: "area",
            title: "Area",
            width: 300,
            filterCell: multiSelectAreaFilter,
            orderIndex: 1,
        },
        {
            key: PlanningInfoContentFieldNames.ContractYear,
            field: PlanningInfoContentFieldNames.ContractYear,
            title: "Contract Year",
            width: 200,
            orderIndex: 2,
        },
        {
            key: PlanningInfoContentFieldNames.Elr,
            field: PlanningInfoContentFieldNames.Elr,
            title: "ELR",
            width: 180,
            orderIndex: 3,
        },
        {
            key: PlanningInfoContentFieldNames.Miles,
            field: PlanningInfoContentFieldNames.Miles,
            title: "Miles",
            width: 180,
            orderIndex: 4,
        },
        {
            key: PlanningInfoContentFieldNames.Yards,
            field: PlanningInfoContentFieldNames.Yards,
            title: "Yards",
            width: 180,
            orderIndex: 5,
        },
        {
            key: PlanningInfoContentFieldNames.Chains,
            field: PlanningInfoContentFieldNames.Chains,
            title: "Chains",
            width: 180,
            orderIndex: 6,
        },
        {
            key: AssetInfoContentFieldNames.StructureName,
            field: AssetInfoContentFieldNames.StructureName,
            title: "Structure Name",
            width: 300,
            orderIndex: 7,
        },
        {
            key: "structureReferenceNumber",
            field: "structureReferenceNumber",
            title: "Structure Ref",
            width: 300,
            orderIndex: 8,
        },
        {
            key: "examType",
            field: "examType",
            title: "Exam Type",
            width: 300,
            orderIndex: 9,
        },
        {
            key: "examinationDate",
            field: "examinationDate",
            title: "Examination Date",
            width: 300,
            filter: "date",
            format: "{0:dd/MM/yyyy}",
            filterCell: BetweenDatesFilterCell,
            orderIndex: 10,
        },
        {
            key: "defectsTracked",
            field: "defectsTracked",
            title: "Defects Tracked",
            width: 180,
            orderIndex: 11,
        },
        {
            key: "assignedExaminer",
            field: "assignedExaminer",
            title: "Assigned Examiner",
            width: 300,
            filterCell: multiSelectExaminerFilter,
            orderIndex: 12,
        },
        {
            key: "assignedEngineer",
            field: "assignedEngineer",
            title: "Assigned Engineer",
            width: 300,
            filterCell: multiSelectEngineerFilter,
            orderIndex: 13,
        },
        {
            key: "assetType",
            field: "assetType",
            title: "Asset Type",
            width: 150,
            orderIndex: 14,
        },
        {
            key: "carrsExamId",
            field: "carrsExamId",
            title: "CARRS Exam Id",
            width: 180,
            filterCell: YesNoFilterCell,
            orderIndex: 15,
        },
        {
            key: "returnedToExaminer",
            field: "returnedToExaminer",
            title: "Returned To Examiner",
            width: 180,
            filterCell: YesNoFilterCell,
            orderIndex: 16,
        },
        {
            key: "daysSinceExamDate",
            field: "daysSinceExamDate",
            title: "Days Since Exam",
            width: 180,
            filter: "numeric",
            filterCell: DaysSinceExamFilterCell,
            orderIndex: 17,
        },
        {
            key: "state",
            field: "state",
            title: "State",
            width: 200,
            orderIndex: 18,
        },
    ];

    const {
        data: examinations,
        isLoading,
        refetch,
    } = useGetExaminationReviews(organisationId);

    const flatData = React.useMemo(
        () =>
            examinations?.map((dataItem: ExaminationReview) =>
                Object.assign({ selected: false }, dataItem),
            ) ?? [],
        [examinations],
    );

    const onSelectionChange = React.useCallback(
        (event: GridSelectionChangeEvent) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });

            setSelectedState(newSelectedState);
        },
        [selectedState],
    );

    const result = process(
        flatData
            .map((item) => ({
                ...item,
                [SELECTED_FIELD]: selectedState[idGetter(item)],
            }))
            .slice(0),
        applyCustomFiltersOperator(gridState),
    );

    const assignMutation = useAssignExaminationReviewsMutation(organisationId);

    const isLoadingReviews = isLoading;

    const rowRender = (
        row: React.ReactElement<HTMLTableRowElement>,
        rowProps: GridRowProps,
    ) => {
        const item = rowProps.dataItem;
        const trProps = {
            ...row.props,
            className: classNames(
                {
                    "table-danger":
                        (item.daysSinceExamDate >= 28 && !item.selected) ??
                        false,
                    "table-warning":
                        (item.daysSinceExamDate >= 21 &&
                            item.daysSinceExamDate < 28) ??
                        false,
                },
                row.props.className,
            ),
        };
        return React.cloneElement(row, { ...trProps }, row.props.children);
    };

    const rowIndex = (field: string, defaultIndex: number) => {
        return (
            gridColumnProps?.find((x) => x.field === field)?.orderIndex ??
            defaultIndex
        );
    };

    const rowWidth = (field: string, defaultWidth: number) => {
        return (
            gridColumnProps?.find((x) => x.field === field)?.width ??
            defaultWidth
        );
    };

    const isColumnVisible = (field: string) => {
        return !gridSelectedColumns || gridSelectedColumns?.length === 0
            ? true
            : gridSelectedColumns?.map((x) => x.title)?.includes(field);
    };

    const onNavigate = React.useCallback(
        (e: GridRowDoubleClickEvent) => {
            navigateToWithOrg(AppRoutes.EngineerReviewDetails, {
                reviewId: e.dataItem.id,
            });
        },
        [navigateToWithOrg],
    );

    const columnsFieldAndText = columnConfigurations.map((config) => ({
        title: config.title,
        field: config.field,
    }));

    const onColumnChange = (e: MultiSelectChangeEvent) => {
        setSelectedColumns(e.value);

        const updatedFilters = (
            gridState?.filter?.filters as FilterDescriptor[]
        )?.filter((filter) =>
            e.value?.map((x) => x.field)?.includes(filter?.field),
        );

        const newGridState = {
            ...gridState,
            filter: {
                ...gridState?.filter,
                filters: updatedFilters,
            },
        };

        setGridState(newGridState);
    };

    const toolbar = (
        <GridToolbar>
            <div className="toolbar-wrapper">
                <Button
                    variant="primary"
                    onClick={() => {
                        setAssignActive(true);
                    }}
                    disabled={
                        flatData.filter((item) => selectedState[idGetter(item)])
                            .length === 0 && canAssignUsers
                    }
                >
                    Assign Engineer
                </Button>
                <div className="flex-grow-1"></div>
                <div className="toolbar-container">
                    <MultiSelect
                        data={columnsFieldAndText}
                        autoClose={false}
                        onChange={onColumnChange}
                        textField="title"
                        dataItemKey="field"
                        value={gridSelectedColumns}
                        placeholder="Select columns"
                        tags={
                            gridSelectedColumns?.length > 0
                                ? [
                                      {
                                          text: `${gridSelectedColumns?.length} columns selected`,
                                          data: columnsFieldAndText,
                                      },
                                  ]
                                : null
                        }
                        style={{
                            width: "300px",
                            height: "40px",
                        }}
                    />
                </div>
            </div>
        </GridToolbar>
    );

    return (
        <div>
            {assignActive && (
                <AssignModal
                    title="Assign Engineer"
                    examinations={flatData.filter(
                        (item) => selectedState[idGetter(item)],
                    )}
                    organisationId={organisationId}
                    usersFilter={usersFilter}
                    assignMutation={assignMutation}
                    onClose={() => {
                        setAssignActive(false);
                        refetch();
                    }}
                />
            )}

            <div
                ref={wrapperRef as React.RefObject<HTMLDivElement>}
                className="examinations-review-grid-h pt-2"
            >
                <Grid
                    style={{ height: "630px" }}
                    sortable={true}
                    data={result}
                    {...gridState}
                    selectable={{
                        enabled: true,
                        mode: "multiple",
                        cell: false,
                    }}
                    fixedScroll={true}
                    reorderable={true}
                    resizable={true}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    onSelectionChange={onSelectionChange}
                    onDataStateChange={(e) => {
                        setGridState(e.dataState);
                    }}
                    filterable
                    filterOperators={commonFilterOperators}
                    onRowDoubleClick={onNavigate}
                    className="examinations-review-grid-h"
                    rowRender={rowRender}
                    onColumnResize={(e) => {
                        setColumnsProps(e.columns);
                    }}
                    onColumnReorder={(e) => {
                        setColumnsProps(e.columns);
                    }}
                >
                    {toolbar}
                    <GridNoRecords>There is no data available</GridNoRecords>
                    {columnConfigurations.map(
                        (column) =>
                            isColumnVisible(column.title) && (
                                <GridColumn
                                    key={column.key}
                                    field={column.field}
                                    title={column.title}
                                    width={rowWidth(column.field, column.width)}
                                    {...(column.filterCell && {
                                        filterCell: column.filterCell,
                                    })}
                                    {...(column.filter && {
                                        filter: column.filter as
                                            | "boolean"
                                            | "text"
                                            | "date"
                                            | "numeric",
                                    })}
                                    {...(column.format && {
                                        format: column.format,
                                    })}
                                    orderIndex={rowIndex(
                                        column.field,
                                        column.orderIndex,
                                    )}
                                />
                            ),
                    )}
                </Grid>
                {isLoadingReviews && <GridLoadingPanel gridRef={wrapperRef} />}
            </div>
        </div>
    );
};

export default ExaminationReviewsGrid;
