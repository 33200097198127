import { push } from "connected-react-router";
import * as Formik from "formik";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../store/hooks";

import {
    Row,
    Col,
    FormGroup,
    Form,
    Button,
    Breadcrumb,
    Tabs,
    Tab,
} from "react-bootstrap";
import { useParams } from "react-router";
import {
    useDeleteReportTemplateMutation,
    useGetReportTemplateByIdQuery,
} from "../../../store/features/process-report-template/process-report-template-api-slice";
import { Link } from "react-router-dom";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import ReportTemplateFilesList from "../components/ReportTemplateFilesList";
import AuditActionsList from "../../../common/components/audit/AuditActionsList";
import { usePatchProcessReportTemplateByIdMutation } from "../../../store/features/process-report-template/process-report-template-api-slice";
import ProcessTemplateConstants from "../../../Constants/ProcessTemplateConstants";
import { useGetProcessTemplateByIdQuery } from "../../../store/features/process-template/process-template-api-slice";
import LoadingSpinner from "../../../common/components/CoveringLoadingPanel";
import { Errors } from "../../../common/types/Errors";
import bsCustomFileInput from "bs-custom-file-input";
import { useOrgTitleById } from "../../../common/hooks/useTitle";
import ReportTemplatePageTitles from "./pageTitles";
import { useGetOrgByShortNameQuery } from "../../../store/features/organisation/organisation-api-slice";
import ConfirmBox from "../../../common/components/kendo-dialog-boxes/ConfirmBox";

interface ReportTemplateViewePageProps {
    orgShortName: string;
}
interface FormValues {
    fileTemplate: string;
    name: string;
    description: string;
}

const ReportTemplateViewPage: React.FC<ReportTemplateViewePageProps> = ({
    orgShortName,
}) => {
    const { data: organisation } = useGetOrgByShortNameQuery(orgShortName, {
        skip: !orgShortName,
    });
    const appDispatch = useAppDispatch();
    const { id: organisationId } = useParams<{ id: string }>();
    const [isReadOnly, setReadOnly] = useState(true);
    const [showConfirmation, setShowConfirmation] = React.useState(false);
    const params = useParams<{
        processTemplateId: string;
        processReportTemplateId: string;
    }>();
    const { data: processTemplate, isLoading: processTemplateIsLoading } =
        useGetProcessTemplateByIdQuery(params.processTemplateId, {
            skip: !params.processTemplateId,
        });
    const [updateProcessReportTemplate] =
        usePatchProcessReportTemplateByIdMutation();
    const [deleteProcessReportTemplate] = useDeleteReportTemplateMutation();

    const {
        data: processReportTemplate,
        isLoading: processReportTemplateIsLoading,
    } = useGetReportTemplateByIdQuery(params.processReportTemplateId, {
        skip: !params.processReportTemplateId,
    });

    const handleEdit = () => {
        setReadOnly(false);
    };
    const handleDelete = () => {
        deleteProcessReportTemplate({
            id: processReportTemplate.id,
            processTemplateId: processReportTemplate.processTemplateId,
        });
        setShowConfirmation(false);
        appDispatch(
            push(
                `${ProcessTemplateConstants.RoutePath}/${params.processTemplateId}?tab=reportTemplate`,
            ),
        );
    };
    const getInitialValues = (): FormValues => {
        const values = {
            fileTemplate: "",
            name: processReportTemplate.name,
            description: processReportTemplate.description,
        };
        return values;
    };
    const validate = (values: FormValues) => {
        const errors: Errors = {};

        if (!values.name) {
            errors.name = "Required";
        }
        return errors;
    };

    useOrgTitleById(organisationId, ReportTemplatePageTitles.View);

    useEffect(() => {
        bsCustomFileInput.init();
    }, [isReadOnly]);

    if (processTemplateIsLoading || processReportTemplateIsLoading)
        return <LoadingSpinner />;
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={"div"}>
                            <Link
                                to={`${OrganisationConstants.RoutePath}/${organisationId}`}
                            >
                                Manage
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link
                                to={`${OrganisationConstants.RoutePath}/${organisationId}?tab=processTemplates`}
                            >
                                {organisation?.features?.process
                                    ?.sideMenuName ?? "Process"}{" "}
                                Templates
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link
                                to={`${ProcessTemplateConstants.RoutePath}/${params.processTemplateId}?tab=home`}
                            >
                                {processTemplate.name}
                            </Link>
                        </Breadcrumb.Item>

                        <Breadcrumb.Item>
                            <Link
                                to={`${ProcessTemplateConstants.RoutePath}/${params.processTemplateId}?tab=reportTemplate`}
                            >
                                Report Templates
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            {processReportTemplate.name}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                {isReadOnly && (
                    <Col>
                        <div className="float-right">
                            <Button variant="primary" onClick={handleEdit}>
                                Edit
                            </Button>
                            <Button
                                className="ml-1"
                                variant="danger"
                                onClick={() => setShowConfirmation(true)}
                            >
                                Delete
                            </Button>
                        </div>
                    </Col>
                )}
                {showConfirmation && (
                    <ConfirmBox
                        title="Delete"
                        message={`Are you sure you want to delete ${processReportTemplate.name} template?`}
                        onCancel={() => setShowConfirmation(false)}
                        onConfirm={handleDelete}
                    />
                )}
            </Row>
            <Row>
                <Col>
                    <Tabs defaultActiveKey="home" id="tab">
                        <Tab eventKey="home" title="Report Template">
                            <Row className="top10">
                                <Col>
                                    <div>
                                        <Formik.Formik
                                            initialValues={getInitialValues()}
                                            validate={validate}
                                            onSubmit={async (
                                                values,
                                                { setSubmitting },
                                            ) => {
                                                setSubmitting(true);
                                                const formData = new FormData();
                                                formData.append(
                                                    "id",
                                                    processReportTemplate.id,
                                                );
                                                formData.append(
                                                    "file",
                                                    values.fileTemplate,
                                                );
                                                formData.append(
                                                    "name",
                                                    values.name,
                                                );
                                                formData.append(
                                                    "description",
                                                    values.description,
                                                );

                                                await updateProcessReportTemplate(
                                                    formData,
                                                )
                                                    .then(() =>
                                                        setReadOnly(true),
                                                    )
                                                    .catch((error) => {
                                                        console.log(
                                                            "Request failed:",
                                                            error.message,
                                                        );
                                                    });
                                                setSubmitting(false);
                                            }}
                                        >
                                            {({
                                                values,
                                                errors,
                                                touched,
                                                handleChange,
                                                handleBlur,
                                                setFieldValue,
                                                resetForm,
                                            }) => (
                                                <Formik.Form>
                                                    <FormGroup>
                                                        <Form.Label>
                                                            Name
                                                        </Form.Label>
                                                        <Form.Control
                                                            as="input"
                                                            name="name"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={values.name}
                                                            readOnly={
                                                                isReadOnly
                                                            }
                                                            className={
                                                                touched.name &&
                                                                errors.name
                                                                    ? "is-invalid"
                                                                    : "form-control"
                                                            }
                                                        />
                                                        {errors.name ? (
                                                            <div className="invalid-feedback">
                                                                {errors.name}
                                                            </div>
                                                        ) : null}
                                                    </FormGroup>
                                                    <Form.Group controlId="formDescription">
                                                        <Form.Label>
                                                            Description
                                                        </Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="description"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            readOnly={
                                                                isReadOnly
                                                            }
                                                            value={
                                                                values.description
                                                            }
                                                        />
                                                    </Form.Group>
                                                    <FormGroup>
                                                        <Form.Label>
                                                            Active File Version
                                                        </Form.Label>
                                                        <Form.Control
                                                            as="input"
                                                            name="name"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                            value={
                                                                processReportTemplate.activeFileVersion
                                                            }
                                                            readOnly={true}
                                                        />
                                                    </FormGroup>

                                                    {!isReadOnly && (
                                                        <FormGroup>
                                                            <Form.File
                                                                id="formcheck-api-custom"
                                                                lang="en"
                                                                custom
                                                                className="bottom10 "
                                                            >
                                                                <Form.File.Label>
                                                                    New Template
                                                                    file
                                                                </Form.File.Label>
                                                                <Form.File.Input
                                                                    type="file"
                                                                    name="fileTemplate"
                                                                    id="fileTemplate"
                                                                    onChange={(
                                                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                                        event: any,
                                                                    ) => {
                                                                        setFieldValue(
                                                                            "fileTemplate",
                                                                            event
                                                                                .target
                                                                                .files[0],
                                                                        );
                                                                    }}
                                                                    className={
                                                                        "form-control"
                                                                    }
                                                                    onBlur={
                                                                        handleBlur
                                                                    }
                                                                />
                                                                {/* {errors.fileTemplate ? <div className="invalid-feedback">{errors.name}</div> : null} */}
                                                                <Formik.ErrorMessage
                                                                    name="fileTemplate"
                                                                    component="div"
                                                                    className="invalid-feedback display"
                                                                />
                                                            </Form.File>
                                                        </FormGroup>
                                                    )}

                                                    <div>
                                                        <ReportTemplateFilesList
                                                            files={
                                                                processReportTemplate.files
                                                            }
                                                        />
                                                    </div>
                                                    <div className="top10 float-right">
                                                        {!isReadOnly && (
                                                            <Button
                                                                variant="primary"
                                                                type="submit"
                                                            >
                                                                Update
                                                            </Button>
                                                        )}
                                                        {!isReadOnly && (
                                                            <Button
                                                                variant="secondary"
                                                                className="ml-1"
                                                                onClick={() => {
                                                                    resetForm();
                                                                    setReadOnly(
                                                                        true,
                                                                    );
                                                                }}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        )}
                                                    </div>
                                                </Formik.Form>
                                            )}
                                        </Formik.Formik>
                                    </div>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="history" title="History">
                            <AuditActionsList
                                auditActions={
                                    processReportTemplate.auditActions
                                }
                            />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </React.Fragment>
    );
};
export default ReportTemplateViewPage;
