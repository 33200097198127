import { process, State } from "@progress/kendo-data-query";
import { getter } from "@progress/kendo-react-common";
import {
    getSelectedState,
    Grid,
    GridColumn,
    GridNoRecords,
    GridRowProps,
    GridSelectionChangeEvent,
    GridToolbar,
} from "@progress/kendo-react-grid";
import { Switch } from "@progress/kendo-react-inputs";
import cx from "classnames";
import * as React from "react";
import {
    MultiTextContainsFilterCell,
    MultiTextFilterCell,
} from "../../../../../common/components/table/kendo/filterCells/MultiTextFilterCell";
import {
    GridFieldDef,
    GridFieldType,
} from "../../../../../common/components/table/kendo/GridFieldsMap";
import GridLoadingPanel from "../../../../../common/components/table/kendo/GridLoadingPanel";
import { resolveSortAndFilterOptions } from "../../../../../common/components/table/kendo/helpers";
import { SelectedState } from "../../../../../common/components/table/kendo/SelectedState";
import {
    ExaminationPlanning,
    ExaminationScheduleProgressStatus,
    ExaminationStatusType,
} from "../../domain/examinationsPlanning";
import { useGetExaminations } from "../../query/examinationsPlanningQueries";
import ExaminationSchedulesGridActions from "./ExaminationSchedulesGridActions";
import { ExaminationStatusFilterCell } from "./ExaminationStatusFilterCell";
import { gridFieldsMap, gridFilterOperators } from "./gridConfiguration";
import { ProcessStateMultiSelectFilterCell } from "./ProcessStateMultiSelectFilterCell";
import ProgressBar from "./ProgressBar";
import "./examinationSchedulesGrid.scss";

const grisColProps = (field: GridFieldDef, index: number) => ({
    key: index,
    field: field.field,
    title: field.label || field.field,
    width: 300,
    sortable: !field.isCustomField,
});

interface Props {
    organisationId: string;
}

interface ScheduleExaminationStatus {
    isProcessing: boolean;
    total?: number;
    completed?: number;
}

const ExaminationSchedulesGrid: React.FC<Props> = ({ organisationId }) => {
    const SELECTED_FIELD = "selected";
    const DATA_ITEM_KEY = "processId";
    const idGetter = getter(DATA_ITEM_KEY);

    const [scheduleExamStatus, setScheduleExamStatus] =
        React.useState<ScheduleExaminationStatus>({
            isProcessing: false,
            total: 0,
            completed: 0,
        });

    const wrapperRef = React.createRef<HTMLElement>();
    const [dataState, setDataState] = React.useState<State>({
        sort: [
            {
                field: "nrRequestedDate",
                dir: "asc",
            },
        ],
        skip: 0,
        take: 100,
    });
    const [selectedState, setSelectedState] = React.useState<SelectedState>({});
    const [onlyUnallocated, setOnlyUnallocated] = React.useState(true);

    const query = React.useMemo(
        () => ({
            ...resolveSortAndFilterOptions(dataState, gridFieldsMap),
            onlyUnallocated,
        }),
        [onlyUnallocated],
    );

    const { data: examinations, isFetching } = useGetExaminations(
        organisationId,
        query,
    );

    const isLoadingExaminations = isFetching;

    React.useMemo(() => {
        if (!examinations) return [];

        const remainingScheduleToProcess = examinations.filter((obj) => {
            return (
                obj.examinationScheduleStatus !==
                ExaminationScheduleProgressStatus.Todo
            );
        }).length;

        if (remainingScheduleToProcess > 0) {
            const completedSchedules =
                scheduleExamStatus.total - remainingScheduleToProcess;

            setScheduleExamStatus({
                isProcessing: true,
                total: scheduleExamStatus.total,
                completed: completedSchedules,
            });
        } else {
            setScheduleExamStatus({
                isProcessing: false,
                total: scheduleExamStatus.total,
            });
        }
    }, [examinations, scheduleExamStatus.total]);

    const flatData = React.useMemo(
        () =>
            examinations?.map((dataItem: ExaminationPlanning) =>
                Object.assign({ selected: false }, dataItem),
            ) ?? [],
        [examinations],
    );

    const result = process(
        flatData.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
        })),
        dataState,
    );

    const onSelectionChange = React.useCallback(
        (event: GridSelectionChangeEvent) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });

            const selectedScheduleExams = flatData
                .filter((item) => newSelectedState[idGetter(item)])
                .filter((obj) => {
                    return (
                        obj.examinationScheduleStatus ===
                        ExaminationScheduleProgressStatus.Todo
                    );
                });

            if (!scheduleExamStatus.isProcessing) {
                setScheduleExamStatus({
                    total: selectedScheduleExams.length,
                    isProcessing: false,
                });
            }

            setSelectedState(newSelectedState);
        },
        [flatData, idGetter, scheduleExamStatus.isProcessing, selectedState],
    );

    const rowRender = (
        row: React.ReactElement<HTMLTableRowElement>,
        rowProps: GridRowProps,
    ) => {
        const item = rowProps.dataItem as ExaminationPlanning;
        const planned = item.processState === ExaminationStatusType.Planned;

        const trProps = {
            ...row.props,
            className: planned
                ? cx("completed", row.props.className)
                : row.props.className,
        };
        return React.cloneElement(row, { ...trProps }, row.props.children);
    };

    const switchColumnType = React.useCallback(
        (field: GridFieldDef, index: number): JSX.Element => {
            switch (field.fieldType) {
                case GridFieldType.ExamStatus: {
                    return (
                        <GridColumn
                            {...grisColProps(field, index)}
                            filterCell={ExaminationStatusFilterCell}
                        />
                    );
                }
                case GridFieldType.MultiText: {
                    return (
                        <GridColumn
                            {...grisColProps(field, index)}
                            filterCell={MultiTextFilterCell}
                        />
                    );
                }
                case GridFieldType.MultiTextContains: {
                    return (
                        <GridColumn
                            {...grisColProps(field, index)}
                            filterCell={MultiTextContainsFilterCell}
                        />
                    );
                }
                case GridFieldType.ProcessStateMultiSelect: {
                    return (
                        <GridColumn
                            {...grisColProps(field, index)}
                            filterCell={ProcessStateMultiSelectFilterCell}
                        />
                    );
                }
                case GridFieldType.Date: {
                    return (
                        <GridColumn
                            {...grisColProps(field, index)}
                            filter={"date"}
                            format="{0:dd/MM/yyyy}"
                        />
                    );
                }
                default: {
                    return <GridColumn {...grisColProps(field, index)} />;
                }
            }
        },
        [],
    );
    const togglableSwitch = (
        label: string,
        onChange: () => void,
        checked: boolean,
    ) => {
        return (
            <>
                <div className="ml-2 mr-2">{label}</div>
                <Switch onChange={onChange} checked={checked}></Switch>
            </>
        );
    };

    const toolbar = (
        <GridToolbar>
            <ExaminationSchedulesGridActions
                organisationId={organisationId}
                selectedItems={flatData.filter(
                    (item) => selectedState[idGetter(item)],
                )}
            ></ExaminationSchedulesGridActions>
            <div className="flex-grow-1 justify-content-end">
                {togglableSwitch(
                    "Only Unallocated",
                    () => setOnlyUnallocated(!onlyUnallocated),
                    onlyUnallocated,
                )}
            </div>
        </GridToolbar>
    );

    return (
        <div
            ref={wrapperRef as React.RefObject<HTMLDivElement>}
            className="exam-schedules-grid-h pt-4"
        >
            <Grid
                sortable={true}
                data={result}
                {...dataState}
                selectable={{
                    enabled: true,
                    mode: "multiple",
                    cell: false,
                }}
                filterable
                filterOperators={gridFilterOperators}
                className="exam-schedules-grid-h"
                rowRender={rowRender}
                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                onSelectionChange={onSelectionChange}
                onDataStateChange={(e) => {
                    setDataState(e.dataState);
                }}
                pageable={{
                    buttonCount: 4,
                    info: true,
                    pageSizes: [100],
                }}
            >
                {toolbar}
                <GridNoRecords>
                    <div style={{ width: "100%", textAlign: "left" }}>
                        {isLoadingExaminations && "Loading..."}
                        {!isLoadingExaminations && "There is no data available"}
                    </div>
                </GridNoRecords>
                {Object.values(gridFieldsMap).map(switchColumnType)}
            </Grid>

            {scheduleExamStatus.isProcessing && (
                <ProgressBar
                    count={scheduleExamStatus.total}
                    doneCount={scheduleExamStatus.completed}
                />
            )}

            {isLoadingExaminations && <GridLoadingPanel gridRef={wrapperRef} />}
        </div>
    );
};

export default ExaminationSchedulesGrid;
