import React from "react";
import {
    CustomSelect,
    DateTimeSelect,
    TextArea,
    TextInput,
} from "../../../../common/components/form";
import {
    getLineDispensationOptions,
    getMomStatusOptions,
    getTrainsStopOptions,
} from "../common/options";
import { Col, Row } from "react-bootstrap";
import TextInputPhone from "../../../../common/components/form/TextInputPhone";

const NetworkRailControlInfo: React.FC = () => {
    return (
        <>
            <h2>Network Rail Control Information</h2>
            <Row>
                <Col xs={"auto"}>
                    <TextInput
                        label="Network Rail Incident Number"
                        name="nrIncidentNumber"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={"auto"}>
                    <DateTimeSelect
                        label="Date and Time Incident"
                        name="incidentAt"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={"auto"}>
                    <DateTimeSelect
                        label="Time Amey Notified"
                        name="ameyNotifiedAt"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={"auto"}>
                    <DateTimeSelect
                        label="Time Reported to NR"
                        name="nrNotifiedAt"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <TextArea
                        label="Nature of Incident"
                        name="natureOfIncident"
                        rows={5}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={"auto"}>
                    <TextInputPhone
                        label="NR Control Tel No"
                        name="nrControlTelNum"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={"auto"}>
                    <TextInput
                        label="NR Controller Name"
                        name="nrControllerName"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={"4"}>
                    <CustomSelect
                        name="lineDispensation"
                        label="Line Dispensation"
                        isClearable={false}
                        options={getLineDispensationOptions()}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={"auto"}>
                    <CustomSelect
                        name="trainsStop"
                        label="Are Trains on Stop?"
                        isClearable={false}
                        options={getTrainsStopOptions()}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={"3"}>
                    <CustomSelect
                        name="momStatus"
                        label="MOM Status"
                        isClearable={false}
                        options={getMomStatusOptions()}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={"auto"}>
                    <TextInput label="MOM Name" name="momName" />
                </Col>
            </Row>
            <Row>
                <Col xs={"auto"}>
                    <DateTimeSelect label="MOM ETA" name="momEta" />
                </Col>
            </Row>
            <Row>
                <Col xs={"auto"}>
                    <TextInputPhone label="MOM Tel No" name="momTelNum" />
                </Col>
            </Row>
            <Row>
                <Col xs={"auto"}>
                    <TextInput label="Signal Box Name" name="signalBoxName" />
                </Col>
            </Row>
            <Row>
                <Col xs={"auto"}>
                    <TextInputPhone
                        label="Signal Box Tel No"
                        name="signalBoxTelNum"
                    />
                </Col>
            </Row>
        </>
    );
};

export default NetworkRailControlInfo;
