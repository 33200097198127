import { State } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import React from "react";
import CustomPagingFooter from "../../../common/components/table/kendo/CustomPagingFooter";
import { GridFieldDef } from "../../../common/components/table/kendo/GridFieldsMap";
import GridLoadingPanel from "../../../common/components/table/kendo/GridLoadingPanel";
import { resolveSortAndFilterOptions } from "../../../common/components/table/kendo/helpers";
import useInfiniteGridProps from "../../../common/components/table/kendo/useInfiniteGridProps";
import useInfiniteDataResult from "../../../common/hooks/useInfiniteDataResult";
import { TypedGridCellProps } from "../../../common/types/TypedGridCellProps";
import { useNavigation } from "../../../router/useNavigate";
import { ProcessListItem } from "../domain/types";
import { useGetInfiniteProcesses } from "../queries/processQueries";
import ProcessesListTableRowActions from "./ProcessesListTableRowActions";
import {
    gridFieldsMap,
    gridFilterOperators,
} from "./ProcessInfiniteGridConfiguration";
import { Organisation } from "../../organisation/domain/types";
import "./processGrid.scss";

const grisColProps = (field: GridFieldDef, index: number) => ({
    key: index,
    field: field.field,
    title: field.label || field.field,
    sortable: !field.isCustomField,
});

interface Props {
    organisation: Organisation;
}

const ProcessInfiniteGrid: React.FC<Props> = ({ organisation }) => {
    const { navigateToOrgPath: navigateToPath } = useNavigation();
    const wrapperRef = React.createRef<HTMLElement>();
    const [dataState, setDataState] = React.useState<State>({});

    const query = React.useMemo(
        () => ({
            ...resolveSortAndFilterOptions(dataState, gridFieldsMap),
        }),
        [dataState],
    );

    const {
        data: processes,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        refetch,
    } = useGetInfiniteProcesses(organisation.id, query);

    const { flatData, totalCount, dataCount } =
        useInfiniteDataResult(processes);

    const isLoadingProcesses = isFetching || isFetchingNextPage;

    const gridProps = useInfiniteGridProps({
        data: flatData,
        dataState,
        hasNextPage,
        setDataState,
        loading: isLoadingProcesses,
        fetchMore: () => {
            fetchNextPage();
        },
        gridRef: wrapperRef,
    });

    const onNavigate = React.useCallback(
        (id: string) => {
            navigateToPath(`/:orgShortName/process/${id}`);
        },
        [navigateToPath],
    );

    const switchColumnType = React.useCallback(
        (field: GridFieldDef, index: number): JSX.Element => {
            return <GridColumn {...grisColProps(field, index)} />;
        },
        [],
    );

    return (
        <div
            ref={wrapperRef as React.RefObject<HTMLDivElement>}
            className="process-grid-h pt-2"
        >
            <Grid
                {...gridProps}
                filterable
                filterOperators={gridFilterOperators}
                onRowDoubleClick={(e) => onNavigate(e.dataItem.id)}
                className="process-grid-h"
            >
                {}
                <GridNoRecords>
                    {isLoadingProcesses && "Loading..."}
                    {!isLoadingProcesses && "There is no data available"}
                </GridNoRecords>
                {Object.values(gridFieldsMap).map(switchColumnType)}
                <GridColumn
                    key="actions"
                    field="actions"
                    title=" "
                    filterable={false}
                    cell={(
                        cell: TypedGridCellProps<ProcessListItem>,
                    ): JSX.Element => (
                        <ProcessesListTableRowActions
                            organisation={organisation}
                            cell={cell}
                            refetchData={refetch}
                        />
                    )}
                    width="96px"
                />
            </Grid>

            {isLoadingProcesses && <GridLoadingPanel gridRef={wrapperRef} />}
            <CustomPagingFooter
                total={totalCount}
                loading={isLoadingProcesses}
                dataCount={dataCount}
            />
        </div>
    );
};

export default ProcessInfiniteGrid;
