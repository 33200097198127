import React from "react";
import { Spinner } from "react-bootstrap";

const InlineCenteredLoader: React.FC<{ text?: string }> = ({ text }) => (
    <div className="d-flex justify-content-center py-2">
        <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />
    </div>
);

export default InlineCenteredLoader;
