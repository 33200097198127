import { OrganisationUser } from "../../../organisation/domain/types";
import { UserAssignment } from "../../../process-activity/domain/types";
import { TemplateContentFilter } from "../domain/types";
import { filterItems } from "./filtersHelper";

export const withUserAssgnmentFeatureFilter = (
    users: OrganisationUser[],
    userAssignment: UserAssignment,
): OrganisationUser[] => {
    if (!users) return [];
    if (
        !userAssignment ||
        !userAssignment.enabled ||
        userAssignment.filter?.values.length === 0
    )
        return users;
    return users.filter((user) => filterItems(userAssignment.filter, user));
};

export const withUserFilter = (
    users: OrganisationUser[],
    filter: TemplateContentFilter,
): OrganisationUser[] => {
    if (!users) return [];
    if (!filter?.values) return users;
    if (filter.values.length === 0) return users;

    return users.filter((user) => filterItems(filter, user));
};
