import { dcpApi } from "../dcpApi";
import UserRequestedFilesConstants from "../../../Constants/UserRequestedFilesConstants";
import { RequestedFileDto } from "../../../modules/user-requested-files/queries/types";

type UserRequestedFileHistoryQueryParam = {
    organisationId: string;
    tags: string[];
    filterOnTags: boolean;
};

export const userRequestedFilesApi = dcpApi.injectEndpoints({
    endpoints: (builder) => ({
        getUserRequestedFileHistory: builder.query<
            RequestedFileDto[],
            UserRequestedFileHistoryQueryParam
        >({
            query: ({
                organisationId,
                tags,
                filterOnTags,
            }: UserRequestedFileHistoryQueryParam) => {
                if (filterOnTags) {
                    const queryString = new URLSearchParams();
                    tags.forEach((item) => queryString.append("tags", item));
                    return `${
                        UserRequestedFilesConstants.RoutePath
                    }/${organisationId}${
                        UserRequestedFilesConstants.ControllerName
                    }${
                        UserRequestedFilesConstants.GetHistoricUserRequestedFiles
                    }?${queryString.toString()}`;
                }
                return `${UserRequestedFilesConstants.RoutePath}/${organisationId}${UserRequestedFilesConstants.ControllerName}${UserRequestedFilesConstants.GetHistoricUserRequestedFiles}`;
            },
        }),
    }),
});

export const { useGetUserRequestedFileHistoryQuery } = userRequestedFilesApi;
