import * as yup from "yup";

export const baseSchema = yup.object({
    incidentAt: yup.string().nullable().required("Required"),
    natureOfIncident: yup.string().nullable().required("Required"),
    nrNotifiedAt: yup.string().nullable().required("Required"),
    ameyNotifiedAt: yup.string().nullable().required("Required"),
    nrControlTelNum: yup
        .string()
        .trim()
        .nullable()
        .required("Required")
        .matches(
            /^((\+44)|(0)) ?\d{4} ?\d{6}$/,
            "Must provide valid UK phone number",
        ),
    lineDispensation: yup.mixed().nullable().required("Required"),
    trainsStop: yup.mixed().nullable().required("Required"),
    momStatus: yup.mixed().nullable().required("Required"),
    examinerMobNum: yup
        .string()
        .trim()
        .notRequired()
        .when("examinerId", {
            is: (val) => !!val,
            then: yup.string().nullable().required("Required"),
        })
        .matches(
            /^((\+44)|(0)) ?\d{4} ?\d{6}$/,
            "Must provide valid UK phone number",
        ),
    examinerNotifiedAt: yup
        .string()
        .nullable()
        .when("examinerId", {
            is: (val) => !!val,
            then: yup.string().nullable().required("Required"),
        })
        .nullable(),
    examinerEtaAt: yup
        .string()
        .nullable()
        .when("examinerId", {
            is: (val) => !!val,
            then: yup.string().nullable().required("Required"),
        })
        .nullable(),
    cossIwaForm: yup
        .mixed()
        .when("examinerId", {
            is: (val) => !!val,
            then: yup.mixed().nullable().required("Required"),
        })
        .nullable(),
    momTelNum: yup
        .string()
        .trim()
        .nullable()
        .notRequired()
        .matches(
            /^((\+44)|(0)) ?\d{4} ?\d{6}$/,
            "Must provide valid UK phone number",
        ),
    examinerConfirmedToNrAt: yup
        .string()
        .notRequired()
        .nullable()
        .when("incidentClearedAt", {
            is: (val) => (val === "" ? false : val),
            then: yup.string().required("Required").nullable(),
        }),
    examinerSiteArrivalAt: yup
        .string()
        .notRequired()
        .nullable()
        .when("incidentClearedAt", {
            is: (val) => (val === "" ? false : val),
            then: yup.string().required("Required").nullable(),
        }),
    passedAtLineSpeedAt: yup
        .string()
        .notRequired()
        .nullable()
        .when("incidentClearedAt", {
            is: (val) => (val === "" ? false : val),
            then: yup.string().required("Required").nullable(),
        }),
    examinerDepartureSiteAt: yup
        .string()
        .notRequired()
        .nullable()
        .when("incidentClearedAt", {
            is: (val) => (val === "" ? false : val),
            then: yup.string().required("Required").nullable(),
        }),
});
