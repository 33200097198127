import {
    MultiSelect,
    MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { ExaminationReview } from "../../domain/examinationsReview";

interface Props extends GridFilterCellProps {
    value: string[];
    examinations: ExaminationReview[];
}

export const MultiTextContainsFilterCell: React.FC<Props> = (props) => {
    return <AreaMultiSelectFilterCell {...props} />;
};

export const isIn = (current: string, value: string) => {
    if (value.length === 0) return value;
    return value.includes(current);
};

export const AreaMultiSelectFilterCell: React.FC<Props> = ({
    onChange,
    value,
    examinations,
}) => {
    const onDropdownChange = React.useCallback(
        ({ value }: MultiSelectChangeEvent) => {
            onChange({
                value: value,
                operator: isIn,
                syntheticEvent: null,
            });
        },
        [],
    );

    const onClear: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        onChange({
            value: null,
            operator: "",
            syntheticEvent: event,
        });
    };

    return (
        <div className="k-filtercell">
            <div className="k-filtercell-wrapper">
                <MultiSelect
                    data={Array.from(
                        new Set(
                            examinations?.map((x) => x.area)?.filter((n) => n),
                        ),
                    )}
                    onChange={onDropdownChange}
                    placeholder={"Select areas"}
                    value={value}
                />
                <Button
                    title="Clear"
                    disabled={!value}
                    onClick={onClear}
                    icon="filter-clear"
                />
            </div>
        </div>
    );
};
