import { DataContinuationResult } from "../../../common/types/DataResult";
import { InfiniteQueryOptions } from "../../../common/types/SortAndFilterOptions";
import ApiConstants from "../../../Constants/ApiConstants";
import { postData } from "../../../helpers/ApiHelper";
import { ProcessListItem } from "../domain/types";

enum UrlPaths {
    BaseResource = "Process",
    Paginated = "paginated",
}

export type ProcessSearchOptions = InfiniteQueryOptions;

interface GetProcessesArgs {
    organisationId: string;
    query: ProcessSearchOptions;
}

const baseUrl = `${ApiConstants.ApiPrefix}/${UrlPaths.BaseResource}`;

export async function getInfiniteProcesses({
    organisationId,
    query,
}: GetProcessesArgs): Promise<DataContinuationResult<ProcessListItem>> {
    const url = `${baseUrl}/${UrlPaths.Paginated}/${organisationId}`;
    const result = postData<DataContinuationResult<ProcessListItem>>(
        url,
        query,
    );

    const response = await result;
    if (!response.data) return;
    return {
        ...response,
        data: response.data,
    };
}
