import { AuditAction } from "../../../common/types/AuditAction";
import { CommonFeature } from "../../../common/types/CommonFeature";
import { TemplateContentConfigOption } from "../../template/domain/types";

export interface ProcessTemplateListItem {
    id: string;
    name: string;
    description: string;
    lastUpdatedAt: string;
    version: number;
    features: ProcessTemplateFeaturesWrapper;
}

export interface ProcessTemplateFeaturesWrapper {
    userAssignment: CommonFeature;
    scheduleEnabled: CommonFeature;
}

export interface ProcessTemplateState {
    name: string;
    isInitial?: boolean;
}

export interface ProcessPropertyTemplate {
    type: string;
    label: string;
    field: string;
    value: string;
    uri: string;
    configOptions: TemplateContentConfigOption[];
}

export interface ProcessTemplate {
    id: string;
    name: string;
    description: string;
    organisationId: string;
    lastUpdatedAt: string;
    version: number;
    auditActions: AuditAction[];
    config: string;
    propertyConfig: ProcessPropertyTemplate[];
    states: ProcessTemplateState[];
    features: ProcessTemplateFeaturesWrapper;
}

export interface AssetLinkTemplate {
    enabled: boolean;
    multiple?: boolean;
}

export interface User {
    id: string;
    username: string;
}

export enum UiMode {
    VIEWING = "Viewing",
    EDITING = "Editing",
    CREATING = "Creating",
}

export enum ProcessTemplateUiTabs {
    ProcessTab = "Process",
    HistoryTab = "History",
    UserRolesTab = "Users",
    ReportTemplateTab = "Report Template",
}
