import { Window } from "@progress/kendo-react-dialogs";
import * as React from "react";
import ProcessActivityFormWrapper from "../../../../process-activity-form/components/ProcessActivityFormWrapper";
import { useGetOrgByIdQuery } from "../../../../../store/features/organisation/organisation-api-slice";
import { useGetProcessActivityFormByIdQuery } from "../../../../process-activity-form/store/processActivityFormApiSlice";

interface ExaminationActivityModalProps {
    activityId: string;
    processId: string;
    organisationId: string;
    onClose: () => void;
}

const prefBase = "examActivityModal-";
export enum WindowProperty {
    Width = "Width",
    Height = "Height",
    Top = "Top",
    Left = "Left",
    Fullscreen = "Fullscreen",
}

enum WindowStage {
    Default = "DEFAULT",
    FullScreen = "FULLSCREEN",
}

function storePreference(key: string, value: string) {
    if (key && value !== null) {
        localStorage.setItem(prefBase + key, value);
    }
}

function getPreference(
    key: string,
    defaultValue: number,
    minimumValue: number,
    maxValue: number,
): number {
    const storedValue = localStorage.getItem(prefBase + key) ?? defaultValue;
    const storedValueAsNumber = Number(storedValue);

    if (
        storedValueAsNumber >= minimumValue &&
        (maxValue === 0 || storedValueAsNumber <= maxValue)
    ) {
        return storedValueAsNumber;
    } else if (storedValueAsNumber > maxValue) {
        return maxValue;
    } else {
        return minimumValue;
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const storeAllPreferences = (target: any) => {
    // Store whether full screen or not
    const isFullscreen = target?.state?.stage === WindowStage.FullScreen;
    storePreference(WindowProperty.Fullscreen, isFullscreen ? "1" : "0");

    // Only store position and dimensions if not full screen
    if (!isFullscreen) {
        storePreference(WindowProperty.Width, target?.state?.width);
        storePreference(WindowProperty.Height, target?.state?.height);
        storePreference(WindowProperty.Top, target?.state?.top);
        storePreference(WindowProperty.Left, target?.state?.left);
    }
};

const ExaminationActivityModal: React.FC<ExaminationActivityModalProps> = ({
    activityId,
    processId,
    organisationId,
    onClose,
}) => {
    const { data: organisation } = useGetOrgByIdQuery(organisationId);
    const windowRef = React.createRef<Window>();
    const [userHasCompleted, setUserHasCompleted] = React.useState(false);
    const maxWidth = window?.innerWidth ?? 0;
    const maxHeight = window?.innerHeight ?? 0;
    const minWidth = 350;
    const minHeight = 200;

    // Get stored preferences for window position and size, using defaults
    // if no stored value or the retrieved value is below minimum or too large for browser
    const initialWidth = getPreference(
        WindowProperty.Width,
        750,
        minWidth,
        maxWidth,
    );
    const initialHeight = getPreference(
        WindowProperty.Height,
        450,
        minHeight,
        maxHeight,
    );
    const initialTop = getPreference(WindowProperty.Top, 70, 1, maxHeight);
    const initialLeft = getPreference(WindowProperty.Left, 200, 1, maxWidth);
    const isFullScreen = getPreference(WindowProperty.Fullscreen, 0, 0, 1);
    const [windowStage, setWindowStage] = React.useState(
        isFullScreen === 1 ? WindowStage.FullScreen : WindowStage.Default,
    );

    const handleStageChange = (e) => {
        setWindowStage(e.state);
    };

    // If the user completes, store the preferences for window position and close the window
    React.useEffect(() => {
        if (userHasCompleted) {
            storeAllPreferences(windowRef?.current);
            onClose();
        }
    }, [userHasCompleted, onClose, windowRef]);

    const { data: activityForm } = useGetProcessActivityFormByIdQuery(
        { activityId, processId },
        {
            skip: !activityId,
        },
    );

    return (
        <Window
            ref={windowRef}
            title={activityForm?.displayName ?? "..."}
            onClose={(event) => {
                storeAllPreferences(event?.target);
                onClose();
            }}
            onStageChange={handleStageChange}
            initialWidth={initialWidth}
            initialHeight={initialHeight}
            initialLeft={initialLeft}
            initialTop={initialTop}
            stage={windowStage}
            resizable={true}
            style={{
                position: "fixed",
                margin: 5,
                borderWidth: 2,
                borderColor: "grey",
                userSelect: "none",
            }}
            modal={false}
        >
            <ProcessActivityFormWrapper
                processId={processId}
                activityId={activityId}
                organisation={organisation}
                disableTopBar={true}
                setUserHasCompleted={setUserHasCompleted}
            />
        </Window>
    );
};

export default ExaminationActivityModal;
