import ExamNamesConstants from "../Constants/ExamNamesConstants";

export interface ExamManagement {
    processId: string;
    processTemplateName?: string;
    contractYear?: string;
    elr?: string;
    miles?: string;
    carrsGuid?: string;
    carrsExamId?: string;
    processState: string;
    AssetType: string;
    StructureRefNum: string;
}

export const underWaterExamTypes = [
    {
        value: ExamNamesConstants.UnderwaterExam,
        label: ExamNamesConstants.UnderwaterExam,
    },
    {
        value: ExamNamesConstants.ScourStageOne,
        label: ExamNamesConstants.ScourStageOne,
    },
    {
        value: ExamNamesConstants.ScourStageTwo,
        label: ExamNamesConstants.ScourStageTwo,
    },
    {
        value: ExamNamesConstants.ScourStageOneReview,
        label: ExamNamesConstants.ScourStageOneReview,
    },
    {
        value: ExamNamesConstants.UnderwaterScourTwoReview,
        label: ExamNamesConstants.UnderwaterScourTwoReview,
    },
    {
        value: ExamNamesConstants.ScourExam,
        label: ExamNamesConstants.ScourExam,
    },
    {
        value: ExamNamesConstants.UnderwaterScourOne,
        label: ExamNamesConstants.UnderwaterScourOne,
    },
    {
        value: ExamNamesConstants.UnderwaterScourOneReview,
        label: ExamNamesConstants.UnderwaterScourOneReview,
    },
    {
        value: ExamNamesConstants.UnderwaterScourTwo,
        label: ExamNamesConstants.UnderwaterScourTwo,
    },
];

export const bridgeExamTypes = [
    {
        value: ExamNamesConstants.BridgeDetailed,
        label: ExamNamesConstants.BridgeDetailed,
    },
    {
        value: ExamNamesConstants.BridgeDetailedHCE,
        label: ExamNamesConstants.BridgeDetailedHCE,
    },
];
