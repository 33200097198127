import * as React from "react";
import { RouteComponentProps } from "react-router";
import Breadcrumbs from "../../../../common/components/breadcrumbs/Breadcrumbs";
import CoveringLoadingPanel from "../../../../common/components/CoveringLoadingPanel";
import { useOrgTitleWithShortName } from "../../../../common/hooks/useTitle";
import { useOrganisationId } from "../../../organisation/hooks/useOrganisationId";
import MyWorkGrid from "../components/MyWorkGrid/MyWorkGrid";
import pageTitles from "./pageTitles";

const MyWorkPage: React.FC<
    RouteComponentProps<{ orgShortName: string }>
> = () => {
    const { organisationId, isLoadingOrgId } = useOrganisationId();

    useOrgTitleWithShortName(pageTitles.myWork);

    if (isLoadingOrgId) return <CoveringLoadingPanel />;

    return (
        <>
            <div className="pt-2">
                <Breadcrumbs items={[[pageTitles.myWork]]}></Breadcrumbs>
            </div>
            <div>
                <h1>{pageTitles.myWork}</h1>
            </div>
            <div className="pt-2">
                <MyWorkGrid organisationId={organisationId} />
            </div>
        </>
    );
};

export default MyWorkPage;
