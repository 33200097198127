import React from "react";
import {
    useInfiniteQuery,
    UseInfiniteQueryResult,
    useMutation,
} from "react-query";
import { DataContinuationResult } from "../../../../common/types/DataResult";
import { getWork, setClaimExam } from "../services/examinationsPlanningService";
import { Work } from "../domain/Work";
import { queryClient } from "../../../../query/configureQuery";

const MyWork = "mywork";

export const useGetInfiniteWork = (
    organisationId: string,
): UseInfiniteQueryResult<DataContinuationResult<Work>> => {
    const getData = React.useCallback(
        ({ pageParam = null }): Promise<DataContinuationResult<Work>> => {
            const continuationToken = pageParam;

            return getWork({
                organisationId,
                query: { continuationToken },
            });
        },
        [organisationId],
    );

    const queryKeys = React.useMemo(
        () => [MyWork, organisationId],
        [organisationId],
    );

    return useInfiniteQuery<
        DataContinuationResult<Work>,
        [string, { page: number }]
    >(queryKeys, getData, {
        getNextPageParam: (lastResult) => {
            return lastResult.continuationToken;
        },
    });
};

export const useClaimExamMutation = () => {
    return useMutation(setClaimExam, {
        onSuccess(_result, args) {
            return queryClient.invalidateQueries([MyWork, args.organisationId]);
        },
    });
};
