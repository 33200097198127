import * as React from "react";
import { Form, Button, Table } from "react-bootstrap";
import { useTable, CellProps, useBlockLayout, Column } from "react-table";
import { ArrayHelpers, getIn, useFormikContext } from "formik";
import { FaPlus } from "react-icons/fa";
import FormTableCellActions from "./FormTableCellActions";
import FormControlBase from "./FormControlBase";
import {
    TemplateContentTableColumn,
    TemplateContentTableRow,
    TemplateContentTableColumnGroup,
    ShowCondition,
    TemplateContentTableRowValue,
} from "../../../modules/template/domain/types";
import FormPromptComponent from "./FormPromptComponent";
import FormTableCellControlBuilder from "./FormTableCellControlBuilder";
import classnames from "classnames";

interface FormTableConfig {
    field: string;
    label: string;
    isReadOnly: boolean;
    prompt: string;
    uri: string;
    columns: TemplateContentTableColumn[];
    rows: TemplateContentTableRow[];
    columnGroups: TemplateContentTableColumnGroup[];
    showConditions: ShowCondition[];
}

interface Props {
    config: FormTableConfig;
    arrayHelpers: ArrayHelpers;
}

const formObjectFromConfig = (columnsConfig: TemplateContentTableColumn[]) => {
    const newObject: unknown = {};
    columnsConfig.forEach((propertyItem: TemplateContentTableColumn) => {
        newObject[propertyItem.field] = "";
    });
    return newObject;
};

const columnGroupBuilder = (
    displayName: string,
    columns: any,
    key: string,
): any => {
    return {
        Header: displayName,
        id: key,
        columns: columns,
    };
};

const columnBuilder = (
    formColumnConfig: TemplateContentTableColumn,
    tableConfig: FormTableConfig,
    width: number,
): any => {
    return {
        Header: formColumnConfig.displayName,
        accessor: (row: any) => {
            return row[formColumnConfig.field];
        },
        id: formColumnConfig.field,

        Cell: ({ row }: CellProps<TemplateContentTableRowValue>) => {
            return (
                <FormTableCellControlBuilder
                    config={{
                        columnConfig: formColumnConfig,
                        parentField: tableConfig.field,
                        rowIndex: row.index,
                        isReadOnly: tableConfig.isReadOnly,
                        showConditions: formColumnConfig.showConditions,
                        validations: formColumnConfig.validations,
                        validationType: formColumnConfig.validationType,
                    }}
                />
            );
        },
        minWidth: width / tableConfig.columns.length,
    };
};

const convertConfigToReactTableColumns = (
    config: FormTableConfig,
    width: number,
) => {
    const dataColumns: any = [];
    if (config.columnGroups) {
        config.columnGroups.forEach((columnGroup) => {
            const columns = config.columns
                .filter(
                    (column) =>
                        columnGroup.columns.findIndex(
                            (groupKey) => groupKey.field === column.field,
                        ) > -1,
                )
                .map((column) => columnBuilder(column, config, width));
            if (!columnGroup.displayName) {
                dataColumns.push(...columns);
            } else {
                dataColumns.push(
                    columnGroupBuilder(
                        columnGroup.displayName,
                        columns,
                        columnGroup.key,
                    ),
                );
            }
        });
    }
    return dataColumns;
};
const FormTable: React.FC<Props> = (props) => {
    const { config, arrayHelpers } = props;

    if (!config.columns) config.columns = [];

    const { values, errors } = useFormikContext();

    const emptyRowObject = formObjectFromConfig(config.columns);

    const ref = React.useRef(null);
    const [width, setWidth] = React.useState(0);

    React.useLayoutEffect(() => {
        // - 200 to account for action buttons (Same size on every table)
        if (ref.current?.offsetWidth) {
            setWidth(ref.current.offsetWidth - 200);
        }
    }, [ref.current?.offsetWidth]);

    const columns: Column<object>[] = React.useMemo(() => {
        const actions = [
            {
                Header: "",
                accessor: "table-actions",

                Cell: ({
                    row: { index },
                }: CellProps<TemplateContentTableRowValue>) => {
                    return (
                        <FormTableCellActions
                            arrayHelpers={arrayHelpers}
                            index={index}
                            emptyRowObject={emptyRowObject}
                        />
                    );
                },
            },
        ];
        const dataColumns = convertConfigToReactTableColumns(config, width);
        const tableColumns = [];
        tableColumns.push(...dataColumns);
        if (!config.isReadOnly) tableColumns.push(...actions);

        return tableColumns;
    }, [config.columns, config.isReadOnly, width]);

    const data = React.useMemo(
        () => getIn(values, config.field) || [],
        [values, config.field],
    );

    const tableInstance = useTable({ columns, data }, useBlockLayout);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance;

    return (
        <FormControlBase
            contentConfig={{
                field: config.field,
                showConditions: config.showConditions,
            }}
        >
            <Form.Group ref={ref} key={config.field}>
                <Form.Label htmlFor={config.field}>{config.label}</Form.Label>
                <FormPromptComponent config={config} />
                <Table
                    {...getTableProps()}
                    className={classnames("table-responsive", {
                        "is-invalid": errors[config.field],
                    })}
                >
                    <thead>
                        {
                            // Loop over the header rows
                            headerGroups.map((headerGroup, groupIndex) => (
                                // Apply the header row props

                                <tr
                                    {...headerGroup.getHeaderGroupProps()}
                                    key={`header-group-${groupIndex}`}
                                >
                                    {
                                        // Loop over the headers in each row
                                        headerGroup.headers.map(
                                            (column, index) => (
                                                // Apply the header cell props

                                                <th
                                                    {...column.getHeaderProps()}
                                                    key={`header-col-${index}`}
                                                >
                                                    {
                                                        // Render the header
                                                        column.render("Header")
                                                    }
                                                </th>
                                            ),
                                        )
                                    }
                                </tr>
                            ))
                        }
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                            // Loop over the table rows
                            rows.map((row, rowIndex) => {
                                // Prepare the row for display
                                prepareRow(row);
                                return (
                                    // Apply the row props

                                    <tr
                                        {...row.getRowProps()}
                                        key={`row-key-${rowIndex}`}
                                    >
                                        {
                                            // Loop over the rows cells
                                            row.cells.map(
                                                (cell, rowCellIndex) => {
                                                    // Apply the cell props
                                                    return (
                                                        <td
                                                            key={`row-cell-key-${rowCellIndex}`}
                                                            {...cell.getCellProps()}
                                                        >
                                                            {
                                                                // Render the cell contents
                                                                cell.render(
                                                                    "Cell",
                                                                )
                                                            }
                                                        </td>
                                                    );
                                                },
                                            )
                                        }
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>
                {!config.isReadOnly && (
                    <Button
                        className="bottom10"
                        size="sm"
                        onClick={() => arrayHelpers.push(emptyRowObject)}
                    >
                        <FaPlus />
                    </Button>
                )}
            </Form.Group>
        </FormControlBase>
    );
};

export default FormTable;
