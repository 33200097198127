import * as React from "react";
import { FaBook } from "react-icons/fa";
import NavLinkExternal from "../NavLinkExternal";
import { useGetConfigQuery } from "../../../store/config/configApi";

const DocumentationNavLink: React.FC = () => {
    const { data: config } = useGetConfigQuery();

    if (!config.DocumentationUrl) return null;

    return (
        <>
            <NavLinkExternal
                to={config.DocumentationUrl}
                label="Documentation"
                icon={FaBook}
                eventKey="documentation"
            />
        </>
    );
};

export default DocumentationNavLink;
