import * as React from "react";
import { RouteComponentProps } from "react-router";
import Breadcrumbs from "../../../../common/components/breadcrumbs/Breadcrumbs";
import CoveringLoadingPanel from "../../../../common/components/CoveringLoadingPanel";
import { useOrgTitleWithShortName } from "../../../../common/hooks/useTitle";
import { useOrganisationId } from "../../../organisation/hooks/useOrganisationId";
import ExamRequestsInfiniteGrid from "../components/ExamRequestsInfiniteGrid";

const pageTitle = "Exam requests";

const ExamRequestsPage: React.FC<
    RouteComponentProps<{ orgShortName: string }>
> = () => {
    const { organisationId, isLoadingOrgId } = useOrganisationId();

    useOrgTitleWithShortName(pageTitle);

    if (isLoadingOrgId) return <CoveringLoadingPanel />;

    return (
        <>
            <div className="mt-3">
                <Breadcrumbs items={[[pageTitle]]}></Breadcrumbs>
            </div>
            <div>
                <h1>{pageTitle}</h1>
            </div>
            <div className="mt-4">
                <ExamRequestsInfiniteGrid organisationId={organisationId} />
            </div>
        </>
    );
};

export default ExamRequestsPage;
