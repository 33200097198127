import { ArrayHelpers } from "formik";
import * as React from "react";
import { Button } from "react-bootstrap";
import { FaPlus, FaTimes } from "react-icons/fa";
import ConfirmBox from "../kendo-dialog-boxes/ConfirmBox";

interface Props {
    arrayHelpers: ArrayHelpers;
    index: number;
    emptyRowObject: any;
}

const FormTableCellActions: React.FC<Props> = (props) => {
    const { arrayHelpers, index, emptyRowObject } = props;
    const [showConfirmation, setShowConfirmation] = React.useState(false);
    // TODO Add disable to buttons when form is in read only state
    return (
        <>
            <Button
                size="sm"
                className="ml-1"
                onClick={() => arrayHelpers.insert(index, emptyRowObject)}
            >
                <FaPlus />
            </Button>
            <Button
                title="Delete"
                size="sm"
                variant="danger"
                className="ml-1"
                onClick={() => setShowConfirmation(true)}
            >
                <FaTimes />
            </Button>
            {showConfirmation && (
                <ConfirmBox
                    title="Delete"
                    message="Are you sure you want to delete this item?"
                    onCancel={() => setShowConfirmation(false)}
                    onConfirm={() => arrayHelpers.remove(index)}
                />
            )}
        </>
    );
};

export default FormTableCellActions;
