const bodyStyle = getComputedStyle(document.body);

function getBreakpointValue(name: string): number {
    const prop = bodyStyle.getPropertyValue(name);
    if (/^\d+px$/.test(prop)) {
        return parseInt(prop);
    }
    throw new Error(
        `Property value of ${name} should be a number of pixels. It is ${prop}.`,
    );
}

/**
 * Medium breakpoint defined by Bootstrap.
 */
export const breakpointMd = getBreakpointValue("--breakpoint-md");

/**
 * Large breakpoint defined by Bootstrap.
 */
export const breakpointLg = getBreakpointValue("--breakpoint-lg");
