import * as React from "react";
import { useParams } from "react-router";
import { useGetOrgByIdQuery } from "../../../../store/features/organisation/organisation-api-slice";
import GridLoadingPanel from "../../../../common/components/table/kendo/GridLoadingPanel";
import useGridProps from "../../../../common/components/table/kendo/useGridProps";
import {
    ContainsMenuFilter,
    emptyFilterDescriptor,
} from "../../../../common/components/table/kendo/columnFilters";
import { Grid } from "@progress/kendo-react-grid";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";

/**
 * Options object for conditional rendering per permission
 */
export interface TablePermission {
    id: string;
    name: string;
}

/**
 *  Table to display an ogranisation's permissions and actions
 */
const PermissionTable: React.FC = () => {
    const { id: organisationId } = useParams<{ id: string }>();
    const organisationQuery = useGetOrgByIdQuery(organisationId);
    const permissions = organisationQuery.data?.permissions ?? [];

    const wrapperRef = React.createRef<HTMLElement>();

    const { gridProps } = useGridProps(permissions, {
        filterable: true,
        initialFilter: emptyFilterDescriptor,
        sortable: true,
        initialSort: [{ field: "permission", dir: "asc" }],
        pageable: true,
    });

    return (
        <>
            <Grid {...gridProps}>
                <Column
                    field="name"
                    title="Permission"
                    headerClassName="grid-header"
                    columnMenu={ContainsMenuFilter}
                />
            </Grid>
            {organisationQuery.isLoading ||
                (organisationQuery.isFetching && (
                    <GridLoadingPanel gridRef={wrapperRef} />
                ))}
        </>
    );
};

export default PermissionTable;
