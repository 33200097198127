import consumerApiService from "../../../../customization/api/consumerApiService";
import { getData } from "../../../../helpers/ApiHelper";

enum UrlPaths {
    BaseResource = "organisation",
    Examinations = "examinations",
    ExaminationsContextBar = "exam-context-bar",
}

const getBaseUrl = () =>
    `${consumerApiService.baseApiUrl}/${UrlPaths.BaseResource}`;

export async function getExamContextBar(
    organisationId: string,
    processId: string,
): Promise<string> {
    const url = `${getBaseUrl()}/${organisationId}/${UrlPaths.Examinations}/${
        UrlPaths.ExaminationsContextBar
    }/${processId}`;

    return getData(url);
}
