import React from "react";
import { Button } from "react-bootstrap";
import GridLoadingPanel from "../../table/kendo/GridLoadingPanel";
import { User } from "../../../../modules/organisation/domain/organisation";
import { UserGroup } from "../../../../modules/user-management/domain/types";
import {
    useAddUserToGroupMutation,
    useRemoveUserFromGroupMutation,
} from "../../../../store/features/organisation/organisation-api-slice";

interface Props {
    orgId: string;
    user: User;
    userGroup: UserGroup;
    refetchUserGroups: () => object;
    setCanCloseDialog: (value: boolean) => void;
}

const UserGroupsTableActions: React.FC<Props> = ({
    orgId,
    user,
    userGroup,
    refetchUserGroups,
    setCanCloseDialog,
}) => {
    const wrapperRef = React.createRef<HTMLElement>();

    const [processingAction, setProcessingAction] =
        React.useState<boolean>(false);

    const hasUser = typeof user === "object";
    const inGroup = hasUser
        ? userGroup.users.some((u) => u.id === user.id)
        : false;

    const [addUserToGroup] = useAddUserToGroupMutation();
    const [removeUserFromGroup] = useRemoveUserFromGroupMutation();

    const onRemove = async () => {
        setCanCloseDialog(false);
        setProcessingAction(true);
        const res = await removeUserFromGroup({
            userId: user.id,
            orgId: orgId,
            groupId: userGroup.id,
        });
        // check query succeeded
        if ("data" in res) {
            refetchUserGroups();
        }
        setProcessingAction(false);
        setCanCloseDialog(true);
    };

    const onJoin = async () => {
        setCanCloseDialog(false);
        setProcessingAction(true);
        const res = await addUserToGroup({
            body: {
                userId: user.id,
            },
            orgId: orgId,
            groupId: userGroup.id,
        });
        // check query succeeded
        if ("data" in res) {
            refetchUserGroups();
        }
        setProcessingAction(false);
        setCanCloseDialog(true);
    };

    if (processingAction) {
        return <GridLoadingPanel gridRef={wrapperRef} />;
    }

    return (
        <>
            {hasUser && !inGroup && (
                <Button className="top5 ml-1" onClick={() => onJoin()}>
                    Join
                </Button>
            )}
            {hasUser && inGroup && (
                <Button
                    className="top5 ml-1"
                    variant="danger"
                    onClick={() => onRemove()}
                >
                    Leave
                </Button>
            )}
        </>
    );
};

export default UserGroupsTableActions;
