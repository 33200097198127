import AdminConstants from "../../../../Constants/AdminConstants";
import * as React from "react";
import { Breadcrumb, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTitle } from "../../../../common/hooks/useTitle";
import AdminPageTitles from "./pageTitles";
import { useGetConfigQuery } from "../../../../store/config/configApi";

const AdminHomePage: React.FC = () => {
    useTitle(AdminPageTitles.Home);
    const { data: config } = useGetConfigQuery();

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={"div"} active>
                            Admin
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>Site Admin</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>User Admin</Card.Title>
                            <Card.Text>
                                Manage users and assigned roles
                            </Card.Text>
                            <Link
                                to={AdminConstants.UsersPagePath}
                                className="btn btn-primary disabled"
                            >
                                Go
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="top10">
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>Role Admin</Card.Title>
                            <Card.Text>
                                Manage roles and assigned users
                            </Card.Text>
                            <Link
                                to={AdminConstants.RolesPagePath}
                                className="btn btn-primary"
                            >
                                Go
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="top10">
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>About</Card.Title>
                            <Card.Text>Version: {config.version}</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="top30"></Row>
        </React.Fragment>
    );
};

export default AdminHomePage;
