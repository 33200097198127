import { v4 as uuidv4 } from "uuid";
import { TemplateContentFileRecord } from "../../../../modules/template/domain/types";

export interface FormControlFileTableDataItem
    extends TemplateContentFileRecord {
    originalIndex: number;
    inEdit: boolean;
}

export const mapToFileTableItem = (
    fileRecord: TemplateContentFileRecord,
    index: number,
    inEditId: string,
): FormControlFileTableDataItem =>
    ({
        ...fileRecord,
        originalIndex: index,
        inEdit: fileRecord.id === inEditId,
        uuid: uuidv4(),
    } as FormControlFileTableDataItem);
