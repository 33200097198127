import * as React from "react";
import { Button } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import ButtonSpinner from "../../../common/components/button/ButtonSpinner";

const AssetLinkActions: React.FC<{
    onRemove: () => void;
    isLoading: boolean;
}> = ({ onRemove, isLoading }) => {
    return (
        <Button
            variant="danger"
            type="button"
            onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onRemove();
            }}
            className="d-flex"
            disabled={isLoading}
        >
            {!isLoading && <FaTimes />}
            {isLoading && <ButtonSpinner />}
        </Button>
    );
};

export default AssetLinkActions;
