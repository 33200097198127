import * as React from "react";
import { Breadcrumb, Card } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import QueryResultStatus from "../../../common/components/QueryResultStatus";
import { useOrgTitleById } from "../../../common/hooks/useTitle";
import { TemplateTypes } from "../../../common/types/TemplateTypes";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { usePostAssetTemplateMutation } from "../../../store/features/asset-template/assettemplate-api-slice";
import { usePostFormTemplateMutation } from "../../../store/features/form-template/formtemplate-api-slice";
import { useGetOrgByIdQuery } from "../../../store/features/organisation/organisation-api-slice";
import TemplateForm, { ValidatedCallback } from "../containers/TemplateForm";
import TemplatePreview from "../containers/TemplatePreview";
import { TemplateFormActionArg } from "../viewModel/TemplateFormActions";
import { TemplateFormValues } from "../viewModel/TemplateFormValues";
import { formatTemplateCreateTitle } from "./pageTitles";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

interface TemplateCreatePageProps {
    TemplateType: TemplateTypes;
}

const TemplateCreatePage: React.FC<TemplateCreatePageProps> = (props) => {
    const query = useQuery();
    const orgId = query.get("organisationId");

    const [configPreview, setConfigPreview] = React.useState("");

    useOrgTitleById(orgId, formatTemplateCreateTitle(props.TemplateType));

    const { data: organisation, ...organisationQueryResult } =
        useGetOrgByIdQuery(orgId, {
            skip: !orgId,
        });

    const [createFormTemplate, createFormTemplateResult] =
        usePostFormTemplateMutation();
    const [createAssetTemplate, createAssetTemplateResult] =
        usePostAssetTemplateMutation();

    const [createTemplate, createTemplateResult] =
        props.TemplateType === TemplateTypes.Form
            ? [createFormTemplate, createFormTemplateResult]
            : [createAssetTemplate, createAssetTemplateResult];
    const createTemplateActionCallback = ({
        values,
    }: TemplateFormActionArg<TemplateFormValues>) => {
        return createTemplate({
            ...values,
            contents: JSON.parse(values.config),
            trackedObjects: JSON.parse(values.trackedObjects),
        });
    };

    const createRedirectPath =
        props.TemplateType === TemplateTypes.Form
            ? "/form/template"
            : "/asset-template";
    const deleteRedirectTabKey =
        props.TemplateType === TemplateTypes.Form
            ? "formTemplates"
            : "assetTemplates";

    const initialValues = {
        name: "",
        description: "",
        config: "",
        organisationId: orgId,
        trackedObjects:
            props.TemplateType === TemplateTypes.Asset ? "[]" : null,
    };

    const handleConfigValidated: ValidatedCallback = ({
        value,
        isValid,
    }): void => {
        if (isValid) {
            setConfigPreview(value);
        }
    };

    if (!organisation)
        return <QueryResultStatus queryResult={organisationQueryResult} />;

    return (
        <>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={"span"}>
                            <Link
                                to={`${OrganisationConstants.RoutePath}/${orgId}`}
                            >
                                Manage
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item linkAs={"span"}>
                            <Link
                                to={`${OrganisationConstants.RoutePath}/${orgId}`}
                            >
                                {props.TemplateType} Template
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Create </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>{organisation.name}</h1>
                    <h2>
                        Create {props.TemplateType.toLocaleLowerCase()} template
                    </h2>
                </Col>
            </Row>
            <Row className="py-3">
                <Col>
                    <TemplateForm
                        organisationId={orgId}
                        initialValues={initialValues}
                        createTemplate={{
                            actionCallback: createTemplateActionCallback,
                            actionResult: createTemplateResult,
                        }}
                        createRedirectPath={createRedirectPath}
                        deleteRedirectTabKey={deleteRedirectTabKey}
                        onConfigValidated={handleConfigValidated}
                    ></TemplateForm>
                </Col>
                <Col>
                    <Card>
                        <Card.Header as="h5">
                            {props.TemplateType} Preview
                        </Card.Header>
                        <Card.Body>
                            <TemplatePreview
                                configPreview={configPreview}
                            ></TemplatePreview>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default TemplateCreatePage;
