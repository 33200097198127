import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Formik } from "formik";
import * as React from "react";
import { Button, FormLabel } from "react-bootstrap";
import CoveringLoadingPanel from "../../../common/components/CoveringLoadingPanel";
import { CustomActionButton } from "../domain/types";
import { resolveSymbolicUrl } from "../helpers/urlBuilder";
import { useCustomButtonMutation } from "../services/processActivityService";

interface FormValues {
    organisationId: string;
    id: string;
}

interface Props {
    onClose: () => void;
    activityId: string;
    organisationId: string;
    customActionButton: CustomActionButton;
}

const CustomActionButtonPrompt: React.FC<Props> = ({
    onClose,
    activityId,
    organisationId,
    customActionButton,
}) => {
    const url = resolveSymbolicUrl({
        activityId: activityId,
        organisationId: organisationId,
        url: customActionButton.endpoint.url,
        target: customActionButton.endpoint.target,
    });

    const actionButtonAction = useCustomButtonMutation(activityId, url);

    const initialValues: FormValues = {
        id: "",
        organisationId: "",
    };

    const onSubmit = async ({ id: reviewId, organisationId }: FormValues) => {
        actionButtonAction.mutate(
            {
                reviewId,
                organisationId,
            },
            { onSuccess: () => onClose(), onError: () => onClose() },
        );
    };

    return (
        <Dialog
            onClose={onClose}
            title={customActionButton.prompt.title}
            width={400}
            contentStyle={{ overflow: "visible" }}
        >
            {actionButtonAction.isLoading && <CoveringLoadingPanel />}

            <Formik onSubmit={onSubmit} initialValues={initialValues}>
                {() => (
                    <Form>
                        <FormLabel>
                            <strong>
                                {customActionButton.prompt.description}
                            </strong>
                        </FormLabel>
                        <div>
                            <Button
                                onClick={onClose}
                                variant="secondary"
                                className="mr-1"
                            >
                                Cancel
                            </Button>
                            <Button
                                className="mr-1"
                                type="submit"
                                variant={customActionButton.prompt.variant}
                            >
                                {customActionButton.label}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default CustomActionButtonPrompt;
