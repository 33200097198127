import * as React from "react";
import { AuditAction } from "../../types/AuditAction";
import { formatIsoDate } from "../../../helpers/dateTimeHelpers";

const AuditActionListItem: React.FC<{ data: AuditAction }> = (props) => {
    const { data } = props;
    return (
        <li>
            <span>
                <strong>{data.action}&nbsp;</strong>
                {data.data}
            </span>

            <span className="px-1">
                <strong>by</strong>
            </span>

            <span>{data.performedByUsername}</span>

            <span className="ml-auto">{formatIsoDate(data.performedAt)}</span>
        </li>
    );
};

export default AuditActionListItem;
