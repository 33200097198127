import * as React from "react";
import Breadcrumbs from "../../../../common/components/breadcrumbs/Breadcrumbs";
import { useOrgTitleWithShortName } from "../../../../common/hooks/useTitle";
import { useOrganisationId } from "../../../organisation/hooks/useOrganisationId";
import pageTitles from "./pageTitles";
import IncidentsGrid from "../components/IncidentsGrid";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../../../router/AppRoutes";
import { useNavigation } from "../../../../router/useNavigate";
import { Row, Col } from "react-bootstrap";

const BatchingOverviewPage: React.FC = () => {
    useOrgTitleWithShortName(pageTitles.apcOverview);
    const { getOrgLink: getLink } = useNavigation();
    const { organisationId } = useOrganisationId();

    return (
        <>
            <Row className="mt-4">
                <Col>
                    <Breadcrumbs items={[["APC"]]}></Breadcrumbs>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>APC</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Link
                        to={getLink(AppRoutes.ApcCreate)}
                        className="btn btn-primary float-right"
                    >
                        Create Incident
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col>
                    <IncidentsGrid organisationId={organisationId} />
                </Col>
            </Row>
        </>
    );
};

export default BatchingOverviewPage;
