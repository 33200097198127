import * as Yup from "yup";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { isBusinessRule } from "../../../helpers/typeGuards";
import { Organisation } from "../domain/types";
import { SelectOptionItem } from "../../../common/types/reactSelect/SelectOptionItem";

export interface OrganisationFormValues {
    name: string;
    shortName: string;
    description: string;
    ownerName: string;
    owner: SelectOptionItem;
}

export const getInitialOrgForm = (): OrganisationFormValues => ({
    name: "",
    shortName: "",
    description: "",
    ownerName: "",
    owner: null,
});

export const getInitialEditOrgForm = (
    org: Organisation,
    usersOptions: SelectOptionItem[],
): OrganisationFormValues => {
    if (!org || !usersOptions) return getInitialOrgForm();

    const owner = usersOptions.find((o) => o.value === org.ownerId);

    return {
        name: org.name,
        shortName: org.shortName,
        description: org.description,
        ownerName: owner?.username ?? "",
        owner: owner,
    };
};

const fieldTestRegex = /(.*):(.*)/m;

const descFieldToName = (field: string): string => {
    if (field === "Short name") return "shortName";

    return field;
};

export const parseEndpointError = (
    error: FetchBaseQueryError,
): { field: string; fieldError: string } => {
    const data = error.data;

    if (!isBusinessRule(data)) return;

    const match = data.detail.match(fieldTestRegex);

    if (match.length < 3) return { field: null, fieldError: data.detail };

    return { field: descFieldToName(match[1]), fieldError: match[2] };
};

export const handleMutationError = (
    setFieldError: (field: string, fieldError: string) => string,
    error: FetchBaseQueryError,
): void => {
    if (error.status === 400) {
        const { field, fieldError } = parseEndpointError(error);

        if (field) setFieldError(descFieldToName(field), fieldError);
    }
};

export const orgValidationSchema = Yup.object().shape({
    name: Yup.string()
        .trim("Cannot start or end with whitespaces")
        .strict(true)
        .required("Required"),
    description: Yup.string().required("Required"),
    owner: Yup.object().nullable().required("Required"),
    shortName: Yup.string()
        .matches(/^[A-Za-z0-9]*$/, "No special characters")
        .required("Required"),
});
