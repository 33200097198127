import {
    ErrorMessage,
    FieldAttributes,
    useField,
    useFormikContext,
} from "formik";
import React from "react";
import { Form } from "react-bootstrap";
import { Editor, EditorTools } from "@progress/kendo-react-editor";

type Props = {
    label?: string;
    height?: number;
    onValueChange?: (value: string) => void;
} & FieldAttributes<unknown>;

const RichTextEditor: React.FC<Props> = ({
    label,
    height,
    value,
    ...props
}) => {
    const [field, meta] = useField(props);
    const { setFieldValue } = useFormikContext();
    const {
        Bold,
        Italic,
        Underline,
        Strikethrough,
        Subscript,
        Superscript,
        AlignLeft,
        AlignCenter,
        AlignRight,
        AlignJustify,
        Indent,
        Outdent,
        OrderedList,
        UnorderedList,
        Undo,
        Redo,
        FontSize,
        FontName,
        FormatBlock,
        Link,
        Unlink,
        InsertImage,
        ViewHtml,
        InsertTable,
        AddRowBefore,
        AddRowAfter,
        AddColumnBefore,
        AddColumnAfter,
        DeleteRow,
        DeleteColumn,
        DeleteTable,
        MergeCells,
        SplitCell,
    } = EditorTools;

    return (
        <Form.Group controlId={field.name}>
            {label && <Form.Label>{label}</Form.Label>}
            <Editor
                contentStyle={{ height: height ?? 600 }}
                className={meta.touched && meta.error ? "is-invalid" : ""}
                onChange={(val) => setFieldValue(props.name, val.html)}
                value={value}
                tools={[
                    [Bold, Italic, Underline, Strikethrough],
                    [Subscript, Superscript],
                    [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                    [Indent, Outdent],
                    [OrderedList, UnorderedList],
                    FontSize,
                    FontName,
                    FormatBlock,
                    [Undo, Redo],
                    [Link, Unlink, InsertImage, ViewHtml],
                    [InsertTable],
                    [
                        AddRowBefore,
                        AddRowAfter,
                        AddColumnBefore,
                        AddColumnAfter,
                    ],
                    [DeleteRow, DeleteColumn, DeleteTable],
                    [MergeCells, SplitCell],
                ]}
            />
            <ErrorMessage
                name={field.name}
                className="invalid-feedback"
                component="div"
            />
        </Form.Group>
    );
};

export default RichTextEditor;
