import React from "react";
import { TableUserGroup } from "./UserGroupTable";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import OrganisationConstants from "../../../../Constants/OrganisationConstants";
import "./UserGroupTableActions.scss";

interface Props {
    userGroup: TableUserGroup;
    orgShortName: string;
}

const UserGroupsTableActions: React.FC<Props> = ({
    userGroup,
    orgShortName,
}) => {
    return (
        <Button
            as={Link}
            to={`/${orgShortName}/${OrganisationConstants.userGroups}/${userGroup.id}`}
            variant="secondary"
            id="grid-button"
            className="top5 ml-1"
        >
            Manage
        </Button>
    );
};

export default UserGroupsTableActions;
