import * as React from "react";
import { FileButton } from "../../../common/components/button/FileButton";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import { ReplaceFileParams } from "../../../store/features/file/fileApiSlice";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";

interface Props {
    organisationId: string;
    folderId: string;
    fileId: string;
    replaceFile: (params: ReplaceFileParams) => void;
}

export const FileReplaceButton: React.FC<Props> = ({
    organisationId,
    folderId,
    fileId,
    replaceFile,
}) => {
    const canReplace = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgFilesFileReplace,
    ).hasPermission;

    const handleFileSelection = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const file = ev.target.files[0];
        replaceFile({ organisationId, folderId, file, fileId });
    };

    return canReplace ? (
        <FileButton variant="outline-secondary" onChange={handleFileSelection}>
            Replace
        </FileButton>
    ) : null;
};
