import { useParams } from "react-router";
import { WithOrgShortName } from "../../../common/types/WithOrg";
import { useGetOrgByShortNameQuery } from "../../../store/features/organisation/organisation-api-slice";

export function useOrganisationId(): {
    isLoadingOrgId: boolean;
    organisationId: string | undefined;
} {
    const { orgShortName } = useParams<WithOrgShortName>();

    const { data: organisation, isLoading } = useGetOrgByShortNameQuery(
        orgShortName,
        {
            skip: !orgShortName,
        },
    );
    return { organisationId: organisation?.id, isLoadingOrgId: isLoading };
}
