import React, { useCallback } from "react";
import {
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from "react-query";
import { ExaminationsMassRemoval } from "../domain/examinationsMassRemoval.types";
import {
    getExaminationMassRemovals,
    uploadExaminationMassRemoval,
} from "../services/examinationsMassRemovalService";
import examinationQueryKeys from "./examinationQueryKeys";

export const useGetExaminationMassRemovals = (
    organisationId: string,
): UseQueryResult<ExaminationsMassRemoval[]> => {
    const getQuery = useCallback(() => {
        return getExaminationMassRemovals({ organisationId });
    }, [organisationId]);

    const queryKeys = React.useMemo(
        () => examinationQueryKeys.examinationMassRemovals(organisationId),
        [organisationId],
    );

    return useQuery(queryKeys, getQuery);
};

export const useUploadExaminationMassRemovalMutation = (
    organisationId: string,
): UseMutationResult<void, unknown, File> => {
    const queryClient = useQueryClient();

    const uploadExaminationMassRemovalFunction = (file: File) =>
        uploadExaminationMassRemoval({ organisationId, file });

    const queryKeys = React.useMemo(
        () => examinationQueryKeys.examinationMassRemovals(organisationId),
        [organisationId],
    );

    return useMutation(uploadExaminationMassRemovalFunction, {
        onSuccess(_result) {
            return queryClient.invalidateQueries(queryKeys);
        },
    });
};
