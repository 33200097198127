import * as React from "react";
import ExcelReportDownload from "./../../common/components/ExcelReportDownload";
import { useGetSsowMutation } from "../queries/ssowReportQueries";

const SsowDataExportPage: React.FC = () => {
    return (
        <ExcelReportDownload
            pageTitle="Data Export"
            callToAction="Select Date Range"
            fileName="DataExport"
            includeDateInFileName
            mutationFn={useGetSsowMutation}
        />
    );
};

export default SsowDataExportPage;
