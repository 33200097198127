import React, { lazy, Suspense, useEffect } from "react";
import useIsClient from "../../../common/hooks/useIsClient";
import customComponentsService from "../../../customization/custom-components/customComponentsService";
import CefaConstants from "../constants/CefaConstants";

const AssetCustomDataComponentLazy = lazy(() =>
    import("./asset/components/asset-ces-data/AssetCesDataSummary").catch(
        (e) => {
            console.log(`Error loading AssetCesData component: ${e}`);
            return null;
        },
    ),
);

const AssetCesDefectsLazy = lazy(() =>
    import("./asset/components/asset-ces-defects/AssetCesDefects").catch(
        (e) => {
            console.log(`Error loading AssetCesDefects component: ${e}`);
            return null;
        },
    ),
);

const AssetCustomDataComponent: React.FC<{ assetId: string }> = ({
    assetId,
}) => (
    <Suspense fallback={<div>Loading...</div>}>
        <AssetCustomDataComponentLazy assetId={assetId} />
    </Suspense>
);

const AssetCesDefectsComponent: React.FC<{ assetId: string }> = ({
    assetId,
}) => (
    <Suspense fallback={<div>Loading...</div>}>
        <AssetCesDefectsLazy assetId={assetId} />
    </Suspense>
);

export const CefaCustomComponentsProvider: React.FC = ({ children }) => {
    const isCefa = useIsClient(CefaConstants.ClientName);

    useEffect(() => {
        if (isCefa) {
            customComponentsService.initialize({
                assetCustomDataTabs: [
                    {
                        displayName: "CES Asset Data",
                        Component: AssetCustomDataComponent,
                    },
                    {
                        displayName: "CES Asset Defects",
                        Component: AssetCesDefectsComponent,
                    },
                ],
            });
        }
    }, [isCefa]);

    return <>{children}</>;
};
