import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HelpItem } from "../../../help-section/domain/types";

interface Props {
    helpItem: HelpItem;
    organisationId: string;
}

const HelpSectionHelpListItem: React.FC<Props> = ({
    helpItem,
    organisationId,
}) => {
    return (
        <Card className="bottom10">
            <Card.Body>
                <Card.Subtitle className="mb-1">{helpItem.name} </Card.Subtitle>
                <Card.Text className="mb-2">{helpItem.description}</Card.Text>
                <Link
                    to={`/organisation/${organisationId}/help-items/${helpItem.id}`}
                    className="card-link"
                >
                    View
                </Link>
            </Card.Body>
        </Card>
    );
};

export default HelpSectionHelpListItem;
