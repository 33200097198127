import * as React from "react";

interface DescriptionListProps {
    children: [string, string][];
    className?: string;
}

interface DescriptionListItemProps {
    item: [string, string];
}

export function DescriptionListItem({
    item,
}: DescriptionListItemProps): JSX.Element {
    const [label, value] = item;
    return (
        <>
            <dt>{label}</dt>
            <dd>{value}</dd>
        </>
    );
}

export function DescriptionList({
    children,
    className,
}: DescriptionListProps): JSX.Element {
    return (
        <dl className={className}>
            {children.map((item, index) => (
                <DescriptionListItem key={index} item={item} />
            ))}
        </dl>
    );
}
