import {
    InfiniteQueryOptions,
    SortAndFilterOptions,
} from "../../../../common/types/SortAndFilterOptions";
import consumerApiService from "../../../../customization/api/consumerApiService";
import { getData, patchData, postData } from "../../../../helpers/ApiHelper";
import { ProcessActivityForm } from "../../../process-activity-form/domain/types";
import { ExaminationAssignModel } from "../domain/examinationsPlanningDtos";
import {
    AggregatedDefectData,
    ExaminationReview,
    ExaminationTrackingDefect,
    ExaminationTrackingRecommendation,
    UnexaminedParts,
} from "../domain/examinationsReview";
import {
    ExaminationReviewDetailsDto,
    ExaminationReviewDto,
    UnexaminedPartsDto,
    mapToExaminationReview,
} from "../domain/examinationsReviewDtos";
import {
    LoadPastRecommendationsPostModel,
    PastRecommendation,
} from "./../domain/PastRecommendation";

enum UrlPaths {
    BaseResource = "organisation",
    ExaminationsReview = "examinationsreview",
    UnexaminedParts = "unexamined-parts",
    Assign = "assign",
    ReviewFindings = "review-findings",
    Count = "count",
    PastRecommendations = "past-recommendations",
    RefreshEngineerDefects = "refresh-engineer-defects",
    Load = "load",
    ExamByProcess = "exam-by-process",
}

interface GetExaminationReviewByIdArgs {
    organisationId: string;
    reviewId: string;
}

export interface ExaminationsCount {
    examinationsCount: Record<string, number>;
}

const getBaseUrl = () =>
    `${consumerApiService.baseApiUrl}/${UrlPaths.BaseResource}`;

export async function getUnexaminedPartsByReviewId({
    organisationId,
    reviewId,
}: GetExaminationReviewByIdArgs): Promise<UnexaminedParts> {
    const url = `${getBaseUrl()}/${organisationId}/${
        UrlPaths.ExaminationsReview
    }/${reviewId}/${UrlPaths.UnexaminedParts}`;
    const response = await getData<UnexaminedPartsDto>(url);

    if (!response) return;

    return response;
}

export async function getExaminationReviewById({
    organisationId,
    reviewId,
}: GetExaminationReviewByIdArgs): Promise<ExaminationReview> {
    const url = `${getBaseUrl()}/${organisationId}/${
        UrlPaths.ExaminationsReview
    }/${reviewId}`;
    const response = await getData<ExaminationReviewDetailsDto>(url);

    if (!response) return;

    return mapToExaminationReview(response);
}

export interface ExaminationReviewsSearchOptions extends SortAndFilterOptions {
    nextPage?: number;
}

interface GetExaminationReviewsArgs {
    organisationId: string;
    query: ExaminationReviewsSearchOptions;
}

export async function getExaminationReviews({
    organisationId,
    query,
}: GetExaminationReviewsArgs): Promise<ExaminationReview[]> {
    const url = `${getBaseUrl()}/${organisationId}/${
        UrlPaths.ExaminationsReview
    }`;
    const result = postData<ExaminationReviewDto[]>(url, query);

    const response = await result;
    if (!response) return;
    return response.map(mapToExaminationReview);
}

export async function getExaminationReview(
    organisationId: string,
    processId: string,
): Promise<ExaminationReview> {
    const url = `${getBaseUrl()}/${organisationId}/${
        UrlPaths.ExaminationsReview
    }/${UrlPaths.ExamByProcess}/${processId}`;

    return getData(url);
}

export async function getDefects({
    organisationId,
    reviewId,
}: {
    organisationId: string;
    reviewId: string;
}): Promise<AggregatedDefectData> {
    const url = `${getBaseUrl()}/${organisationId}/${
        UrlPaths.ExaminationsReview
    }/${reviewId}/defects`;
    const response = await getData<AggregatedDefectData>(url);

    return {
        ...response,
        trackingDefects: response.trackingDefects.map(
            (a): ExaminationTrackingDefect => ({
                ...a,
                // Convert any date string to JS date object needed for kendo grid
                examDate: a.examDate ? new Date(a.examDate.toString()) : null,
                // Convert any defect number string to a number for kendo grid
                defectNumber: a.defectNumber ? Number(a.defectNumber) : null,
            }),
        ),
    };
}

export async function getRecommendations({
    organisationId,
    reviewId,
}: {
    organisationId: string;
    reviewId: string;
}): Promise<ExaminationTrackingRecommendation[]> {
    const url = `${getBaseUrl()}/${organisationId}/${
        UrlPaths.ExaminationsReview
    }/${reviewId}/recommendations`;

    return getData(url);
}

export async function getReviewFindingsByReviewId({
    organisationId,
    reviewId,
}: {
    organisationId: string;
    reviewId: string;
}): Promise<ProcessActivityForm> {
    const url = `${getBaseUrl()}/${organisationId}/${
        UrlPaths.ExaminationsReview
    }/${reviewId}/${UrlPaths.ReviewFindings}`;
    const response = await getData<ProcessActivityForm>(url);

    if (!response) return;

    return response;
}

interface AssignExaminationsArgs {
    organisationId: string;
    patchModel: ExaminationAssignModel;
}

export function assignExaminationReviews({
    organisationId,
    patchModel,
}: AssignExaminationsArgs): Promise<void> {
    const url = `${getBaseUrl()}/${organisationId}/${
        UrlPaths.ExaminationsReview
    }/${UrlPaths.Assign}`;

    return patchData(url, patchModel);
}

export async function getExaminationsCountAggregatedByType({
    organisationId,
}: {
    organisationId: string;
}): Promise<ExaminationsCount> {
    const url = `${getBaseUrl()}/${organisationId}/${
        UrlPaths.ExaminationsReview
    }/${UrlPaths.Count}`;
    const response = await getData<ExaminationsCount>(url);

    if (!response) return;

    return response;
}

export type PastRecommendationsSearchOptions = InfiniteQueryOptions;

interface GetPastRecommendationsArgs {
    activityId: string;
    organisationId: string;
}

export function refreshEngineerDefects({
    organisationId,
    activityId,
}: GetPastRecommendationsArgs): Promise<void> {
    const url = `${getBaseUrl()}/${organisationId}/${
        UrlPaths.ExaminationsReview
    }/${activityId}/${UrlPaths.RefreshEngineerDefects}`;

    return postData(url);
}

export async function getPastRecommendations({
    activityId,
    organisationId,
}: GetPastRecommendationsArgs): Promise<PastRecommendation[]> {
    const url = `${getBaseUrl()}/${organisationId}/${
        UrlPaths.ExaminationsReview
    }/${activityId}/${UrlPaths.PastRecommendations}`;
    const result = getData<PastRecommendation[]>(url);

    const response = await result;
    if (!response) return;
    return response;
}

interface LoadPastRecommendationsArgs {
    activityId: string;
    organisationId: string;
    postModel: LoadPastRecommendationsPostModel;
}

export function loadPastRecommendations({
    activityId,
    organisationId,
    postModel,
}: LoadPastRecommendationsArgs): Promise<void> {
    const url = `${getBaseUrl()}/${organisationId}/${
        UrlPaths.ExaminationsReview
    }/${activityId}/${UrlPaths.PastRecommendations}/${UrlPaths.Load}`;
    return postData(url, postModel);
}
