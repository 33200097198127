import * as React from "react";
import Select from "react-select";
import {
    mapToSelectOptions,
    SelectOptionItem,
} from "../../../common/types/reactSelect/SelectOptionItem";
import { TypedGridCellProps } from "../../../common/types/TypedGridCellProps";
import { BaseUser } from "../../organisation/domain/organisation";
import { ApprovalTableItem } from "../viewModel/ApprovalTableItem";

interface Props {
    users: BaseUser[];
    onSelect: (selectedUser: BaseUser) => void;
}

export const UsernameCell: React.FC<
    TypedGridCellProps<ApprovalTableItem> & Props
> = ({ users, onSelect, dataItem }) => {
    const selectRef = React.useRef(null);
    const [selectedUser, setSelectedUser] =
        React.useState<SelectOptionItem>(null);

    React.useEffect(() => {
        selectRef?.current?.select?.inputRef?.focus();
    }, []);

    const handleOnChange = (selectedOption: SelectOptionItem) => {
        if (!selectedOption) return;

        setSelectedUser(selectedOption);
        onSelect(users.find((u) => u.userId === selectedOption.value));
    };

    return (
        <td>
            {dataItem.inEdit ? (
                <>
                    <Select
                        ref={selectRef}
                        name="selectedUser"
                        value={selectedUser}
                        onChange={handleOnChange}
                        options={mapToSelectOptions(
                            users,
                            (u) => u.userId,
                            (u) => u.username,
                        )}
                        classNamePrefix="react-select"
                        className="overflow-visible"
                        menuPortalTarget={document.body}
                    />
                </>
            ) : (
                dataItem.name
            )}
        </td>
    );
};
