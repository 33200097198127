import cx from "classnames";
import React from "react";
import { Nav } from "react-bootstrap";
import { FaToriiGate } from "react-icons/fa";
import { NavLink, Route } from "react-router-dom";
import "../../../../src/common/components/navigation-sidebar/SideMenu.scss";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import useIsClient from "../../../common/hooks/useIsClient";
import useViewPermissions from "../../../common/hooks/useViewPermissions";
import { Organisation } from "../../organisation/domain/types";
import SbimConstants from "../../sbim/constants/SbimConstants";

const AssetsMenu: React.FC<{ organisation: Organisation }> = ({
    organisation,
}) => {
    const { canViewAssets } = useViewPermissions(organisation.id);
    const isSBIM = useIsClient(SbimConstants.ClientName);

    return (
        <>
            {canViewAssets && (
                <Nav.Item>
                    <NavLink
                        to={`/${organisation.shortName}${OrganisationConstants.Asset}`}
                        className={cx("d-flex align-items-center nav-link", {
                            "d-flex align-items-center nav-link padding button-override":
                                isSBIM,
                        })}
                        exact
                        key="asset"
                    >
                        <FaToriiGate size="16" className="menu-icon" />
                        <span className="navbar-font-override">
                            {organisation?.features?.assets?.sideMenuName ??
                                "Assets"}
                        </span>
                    </NavLink>
                </Nav.Item>
            )}
            <Route
                path={`/${organisation.shortName}${OrganisationConstants.Asset}`}
            />
        </>
    );
};

export default AssetsMenu;
