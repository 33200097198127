import { goBack, push } from "connected-react-router";
import * as Formik from "formik";
import * as React from "react";
import { Button } from "react-bootstrap";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { useAppDispatch } from "../../../store/hooks";
import { useGetAssetTemplateByOrgIdQuery } from "../../../store/features/asset-template/assettemplate-api-slice";
import { Errors } from "../../../common/types/Errors";
import { usePostAssetMutation } from "../../../store/features/asset/asset-api-slice";
import { useURLQueryParams } from "../../../common/hooks/useURLQueryParams";
import {
    mapToSelectOptions,
    SelectOptionItem,
} from "../../../common/types/reactSelect/SelectOptionItem";
import { addSuccess } from "../../../store/features/notifications/notificationsSlice";
import {
    TextInput,
    TextArea,
    CustomSelect,
} from "../../../common/components/form";
import { Organisation } from "../../organisation/domain/types";

interface FormValues {
    name: string;
    description: string;
    assetTemplate: SelectOptionItem;
}

const validate = (values: FormValues) => {
    const errors: Errors = {};

    if (!values.name) {
        errors.name = "Required";
    }

    if (!values.assetTemplate?.value) {
        errors.assetTemplate = "Required";
    }

    return errors;
};

export interface AssetCreateFormProps {
    organisation: Organisation;
}

const AssetCreateForm: React.FC<AssetCreateFormProps> = ({ organisation }) => {
    const dispatch = useAppDispatch();
    const [createAsset] = usePostAssetMutation();
    const { data: assetTemplates, isLoading: assetTemplatesIsLoading } =
        useGetAssetTemplateByOrgIdQuery(organisation.id, {
            skip: !organisation.id,
        });

    const parentId = useURLQueryParams("parent");

    const createSuccessful = (assetId: string) => {
        dispatch(
            push(
                `/${organisation.shortName}${OrganisationConstants.Asset}/${assetId}`,
            ),
        );
    };

    const initialValues: FormValues = {
        name: "",
        description: "",
        assetTemplate: null,
    };

    const cancel = () => {
        dispatch(goBack());
    };

    const onSubmit = async (
        values: FormValues,
        { setSubmitting }: Formik.FormikHelpers<FormValues>,
    ) => {
        await createAsset({
            name: values.name,
            description: values.description,
            assetTemplateId: values.assetTemplate?.value,
            parentId: parentId,
            organisationId: organisation.id,
        })
            .unwrap()
            .then((data) => {
                dispatch(
                    addSuccess(
                        `${
                            organisation?.features?.assets?.createName ??
                            "Asset"
                        } created successfully.`,
                    ),
                );
                createSuccessful(data.id);
            })
            .catch(() => {
                setSubmitting(false);
            });
    };

    return (
        <Formik.Formik
            enableReinitialize
            initialValues={initialValues}
            validate={validate}
            onSubmit={onSubmit}
        >
            {({ isSubmitting }) => (
                <Formik.Form>
                    <TextInput
                        label="Name"
                        placeholder="Name"
                        name="name"
                        description={`The name for your ${
                            organisation?.features?.assets?.createName ??
                            "Asset"
                        }.`}
                    />
                    <TextArea label="Description" name="description" rows={3} />
                    <CustomSelect
                        name="assetTemplate"
                        label="Select Template"
                        placeholder="Select template..."
                        readOnly={assetTemplatesIsLoading}
                        options={mapToSelectOptions(
                            assetTemplates,
                            (i) => i.id,
                            (i) => i.name,
                        )}
                    />
                    <div className="d-flex justify-content-end">
                        <Button
                            variant="primary"
                            type="submit"
                            disabled={isSubmitting}
                        >
                            Create
                        </Button>
                        <Button
                            variant="danger"
                            className="ml-1"
                            onClick={cancel}
                        >
                            Cancel
                        </Button>
                    </div>
                </Formik.Form>
            )}
        </Formik.Formik>
    );
};

export default AssetCreateForm;
