export type LabelValuePair = [string, string];

export function mapDataToLabelValuePairs(
    data: unknown,
    summaryLabelsMap: Record<string, string>,
): LabelValuePair[] {
    return Object.entries(data).reduce<LabelValuePair[]>(
        (accumulator, [key, value]) => {
            const label = summaryLabelsMap[key];
            if (label)
                if (value instanceof Date) {
                    accumulator.push([
                        label || key,
                        value?.toLocaleDateString("en-gb") ?? "",
                    ]);
                } else {
                    accumulator.push([label || key, value?.toString() ?? ""]);
                }

            return accumulator;
        },
        [],
    );
}
