import { FileViewEntry } from "../../viewModel/FileViewEntry";
import * as React from "react";
import { TypedGridCellProps } from "../../../../common/types/TypedGridCellProps";
import { setName } from "../../../../store/features/folder/fileOrFolderNameSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { Button } from "react-bootstrap";
import { NameChangeContext } from "../NameChangeContextProvider";

function NameInput() {
    const folderName = useAppSelector((s) => s.fileOrFolderName);
    const dispatch = useAppDispatch();
    const inputRef = React.useRef<HTMLInputElement>(null);

    const { submitNameChange, cancelNameChange } =
        React.useContext(NameChangeContext);

    React.useEffect(() => {
        const input = inputRef.current;
        input?.focus();
        input?.select();
    }, []);

    const submit = () => {
        submitNameChange();
        cancel();
    };

    const cancel = () => {
        cancelNameChange();
    };

    return (
        <div className="d-flex position-relative">
            <input
                type="text"
                className="k-textbox flex-grow-1"
                value={folderName}
                onChange={({ target }) => dispatch(setName(target.value))}
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        submit();
                    }
                }}
                onKeyDown={(e) => {
                    if (e.key === "Escape") {
                        cancel();
                    }
                }}
                ref={inputRef}
            />
            <Button className="mr-2 ml-2" onClick={submit}>
                Confirm
            </Button>
        </div>
    );
}

export function NameCell({
    dataItem,
    selectionChange,
}: TypedGridCellProps<FileViewEntry>): JSX.Element {
    return (
        <td
            role={dataItem.inEdit ? "" : "button"}
            onClick={(ev) => {
                selectionChange?.({ syntheticEvent: ev });
            }}
        >
            {dataItem.inEdit ? <NameInput /> : dataItem.label}
        </td>
    );
}
