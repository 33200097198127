import { Card } from "react-bootstrap";
import { CardBody, CardHeader } from "reactstrap";
import React from "react";
import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartLegend,
    SeriesType,
    ChartValueAxis,
    ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import { BridgeComponentChart } from "../../domain/types";

export interface BridgeConditionIndexGraphProps {
    chart: BridgeComponentChart;
    title: string;
    type: SeriesType;
}

export const BridgeConditionIndexGraph: React.FC<
    BridgeConditionIndexGraphProps
> = (props) => {
    const { categories, series } = props.chart;
    const { title, type } = props;
    const id = crypto.randomUUID();
    return (
        <div className="mt-2">
            <Card className="card d-flex flex-column">
                <CardHeader>
                    <p
                        className="h4"
                        style={{ textAlign: "left", fontWeight: "bold" }}
                    >
                        {title}
                    </p>
                </CardHeader>
                <CardBody
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "left",
                        alignItems: "left",
                    }}
                >
                    <Chart key={id}>
                        <ChartLegend position="top" orientation="horizontal" />
                        <ChartValueAxis>
                            <ChartValueAxisItem
                                min={0}
                                max={100}
                                majorUnit={10}
                            />
                        </ChartValueAxis>
                        <ChartCategoryAxis>
                            <ChartCategoryAxisItem
                                categories={categories}
                                labels={{ rotation: 45 }}
                            />
                        </ChartCategoryAxis>
                        <ChartSeries>
                            {series.map((item, index) => (
                                <ChartSeriesItem
                                    key={id + "-" + index}
                                    type={type}
                                    tooltip={{ visible: true }}
                                    data={item.values}
                                    name={item.name}
                                />
                            ))}
                        </ChartSeries>
                    </Chart>
                </CardBody>
            </Card>
        </div>
    );
};
