import {
    Grid,
    GridColumn,
    GridRowClickEvent,
} from "@progress/kendo-react-grid";
import React from "react";
import { useParams } from "react-router";
import { SimplifiedMenuFilter } from "../../../../common/components/table/kendo/columnFilters";
import useGridProps from "../../../../common/components/table/kendo/useGridProps";
import { TemplateTypes } from "../../../../common/types/TemplateTypes";
import { WithOrgShortName } from "../../../../common/types/WithOrg";
import { getUrlForAssignedItem } from "../../../process/helpers/getUrlForAssignedItem";
import { Item } from "../domain/types";

interface Props {
    values: Item[];
}

const CreatedAssetsGrid: React.FC<Props> = ({ values }) => {
    const { orgShortName } = useParams<WithOrgShortName>();

    const { gridProps } = useGridProps(values, {
        filterable: true,
        sortable: true,
        initialSort: [{ field: "name", dir: "desc" }],
        pageable: true,
    });

    const handleRowClick = React.useCallback(
        (event: GridRowClickEvent) => {
            window.location.href = getUrlForAssignedItem(orgShortName, {
                type: TemplateTypes.Asset,
                id: event.dataItem.id,
            });
        },
        [orgShortName],
    );

    return (
        <Grid {...gridProps} onRowClick={handleRowClick}>
            <GridColumn
                field="name"
                title="Created Assets"
                columnMenu={SimplifiedMenuFilter}
            />
        </Grid>
    );
};

export default CreatedAssetsGrid;
