import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import React, { useState } from "react";
import { Button, Col, Form, Row, Form as bsForm } from "react-bootstrap";
import { Organisation } from "../../../organisation/domain/types";
import { UploadFileParams } from "../../../../store/features/file/fileApiSlice";

interface CaptureMetaDataProps {
    organisation: Organisation;
    folderId: string;
    uploadFile: (params: UploadFileParams) => void;
    onClose: () => void;
    fileList: File[];
}

const CaptureMetadataModal: React.FC<CaptureMetaDataProps> = (props) => {
    const { organisation, uploadFile, folderId, onClose, fileList } = props;
    const [metaData, setMetaData] = useState<{ [key: string]: string }>();

    const handleInputChange = (field: string, value: string) => {
        setMetaData({
            ...metaData,
            [field]: value,
        });
    };

    const upload = () => {
        uploadFile({
            organisationId: organisation.id,
            folderId,
            files: fileList,
            metaData,
        });
        onClose();
    };

    const onSkip = () => {
        setMetaData(null);
        upload();
        onClose();
    };

    return (
        <Dialog
            title={"Add Metadata"}
            onClose={onClose}
            width="calc(100vw - 40px)"
            height="calc(100vh - 40px)"
            className="add-metadata-modal"
        >
            <Form className="metadata-form">
                {organisation.features?.files?.collectMetaData &&
                    organisation.features?.files?.metaData?.map(
                        (field, index) => {
                            const currentValue = metaData?.[field.field] || "";

                            return (
                                <Row key={"row" + index} className="mb-3">
                                    <Col xs={4} className="text-end">
                                        <bsForm.Label htmlFor={field.field}>
                                            {field.field}
                                        </bsForm.Label>
                                    </Col>
                                    <Col xs={8}>
                                        {field.options.length > 0 ? (
                                            <Form.Control
                                                key={"dropdown-" + index}
                                                as="select"
                                                name={field.field}
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        field.field,
                                                        e.target.value,
                                                    )
                                                }
                                            >
                                                <option value="">
                                                    Select value
                                                </option>
                                                {field.options.map(
                                                    (option, i) => (
                                                        <option
                                                            key={"option" + i}
                                                            value={option}
                                                        >
                                                            {option}
                                                        </option>
                                                    ),
                                                )}
                                            </Form.Control>
                                        ) : (
                                            <Form.Control
                                                as="textarea"
                                                key={"textarea" + index}
                                                type="text"
                                                name={field.field}
                                                value={currentValue}
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        field.field,
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                        )}
                                    </Col>
                                </Row>
                            );
                        },
                    )}
                <div className="action-buttons">
                    <DialogActionsBar>
                        <Button
                            type="button"
                            variant="outline-secondary"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="button"
                            variant="secondary"
                            onClick={onSkip}
                        >
                            Skip
                        </Button>
                        <Button
                            type="button"
                            variant="primary"
                            disabled={false}
                            onClick={upload}
                        >
                            Submit
                        </Button>
                    </DialogActionsBar>
                </div>
            </Form>
        </Dialog>
    );
};

export default CaptureMetadataModal;
