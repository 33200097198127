import {
    State,
    process,
    FilterOperator,
    CompositeFilterDescriptor,
    FilterDescriptor,
    isCompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import { GridEvent, GridProps } from "@progress/kendo-react-grid";
import { GridSelectableSettings } from "@progress/kendo-react-grid/dist/npm/interfaces/GridSelectableSettings";
import produce from "immer";
import { WritableDraft } from "immer/dist/internal";
import * as React from "react";
import { useDebouncedCallback } from "use-debounce";
import { isFilterDescriptor } from "../../../../helpers/typeGuards";
import useGridSelectableProps from "./useGridSelectableProps";

interface SelectableArguments<T> {
    config: GridSelectableSettings;
    onSelectChange: (selected: T[]) => void;
}

interface InfiniteGridArguments<T> {
    data: T[];
    dataState: State;
    setDataState: (state: State) => void;
    gridRef: React.RefObject<HTMLElement>;
    fetchMore: () => Promise<void> | void;
    initialDataState?: State;
    hasNextPage?: boolean;
    loading?: boolean;
    dataItemKey?: string;
    selectable?: SelectableArguments<T>;
    filterable?: boolean;
}

interface InfiniteGridAddProps<T> {
    data: T[];
}

type InfiniteGridProps<T> = GridProps & InfiniteGridAddProps<T>;

const scrollCondition = (event: GridEvent): boolean => {
    const e = event.nativeEvent;

    return (
        e.target.scrollTop + 10 >= e.target.scrollHeight - e.target.clientHeight
    );
};

/**
 * Checks if the filter operator is in the supported list of operators
 * @param filterOperator kendo filter operator
 * @returns bool check
 */
const IsSupportedFilterOperator = (filterOperator: FilterDescriptor) => {
    return Object.keys(FilterOperator).some(
        (v) => v === filterOperator.operator,
    );
};

/**
 * Returns CompositeFilterDescriptor with any unsupported filter operators removed
 * @param compositeFilterDescriptor
 */
const cleanseCompositeFilterDescriptor = (
    compositeFilterDescriptor: WritableDraft<CompositeFilterDescriptor>,
) => {
    // Remove any unsupported filter operators
    compositeFilterDescriptor.filters =
        compositeFilterDescriptor.filters.filter(
            (filter) =>
                isCompositeFilterDescriptor(filter) ||
                (isFilterDescriptor(filter) &&
                    (IsSupportedFilterOperator(filter) ||
                        typeof filter.operator === "function")),
        );
    // Check for any nested filter operators
    compositeFilterDescriptor.filters.forEach((filter) => {
        if (isCompositeFilterDescriptor(filter)) {
            cleanseCompositeFilterDescriptor(filter);
        }
    });
};

/**
 * This function will filter custom filter operators not supported by process function of data-query
 * @param datastate input data state with unsupported operators
 * @returns datastate with unsupported operators removed.
 */
const filterCustomFilterOperators = (dataState: State): State => {
    const a = produce(dataState, (draft) => {
        if (draft.filter) {
            cleanseCompositeFilterDescriptor(draft.filter);
        }
    });
    return a;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const useInfiniteGridProps = <T extends object>(
    args: InfiniteGridArguments<T>,
): InfiniteGridProps<T> => {
    const {
        data,
        dataState,
        setDataState,
        gridRef,
        fetchMore,
        hasNextPage = false,
        loading = false,
        dataItemKey = "id",
        selectable,
        filterable,
        initialDataState = {},
    } = args;

    const [resultState, setResultState] = React.useState(
        process(data, initialDataState),
    );

    const scrollHandler = useDebouncedCallback((event: GridEvent) => {
        if (scrollCondition(event) && hasNextPage && !loading) {
            fetchMore();
        }
    }, 200);

    const onDataStateChange = React.useCallback(
        (e) => {
            gridRef.current.querySelector(".k-grid-content").scrollTop = 0;
            setDataState(e.dataState);
            setResultState(
                process(data, filterCustomFilterOperators(e.dataState)),
            );
        },
        [data, gridRef, setDataState],
    );

    React.useEffect(() => {
        setResultState(process(data, filterCustomFilterOperators(dataState)));
    }, [data, dataState]);

    const gridSelectable = useGridSelectableProps(
        resultState.data,
        dataItemKey,
        selectable,
    );
    const { gridProps: selectableProps, gridData } = gridSelectable;

    return {
        data: gridData,
        dataItemKey,
        onScroll: scrollHandler,
        fixedScroll: true,
        reorderable: true,
        resizable: true,
        filterable,
        sortable: true,
        ...dataState,
        onDataStateChange,
        ...selectableProps,
    };
};

export default useInfiniteGridProps;
