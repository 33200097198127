// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export const PROCESS_ACTIVITY_FORM_CONTENT_HIDDEN_FIELD_ADD =
    "PROCESS_ACTIVITY_FORM_CONTENT_HIDDEN_FIELD_ADD";
export const PROCESS_ACTIVITY_FORM_CONTENT_HIDDEN_FIELD_REMOVE =
    "PROCESS_ACTIVITY_FORM_CONTENT_HIDDEN_FIELD_REMOVE";

export interface ProcessActivityFormContentHiddenFieldAdd {
    type: typeof PROCESS_ACTIVITY_FORM_CONTENT_HIDDEN_FIELD_ADD;
    data: string;
}

export interface ProcessActivityFormContentHiddenFieldRemove {
    type: typeof PROCESS_ACTIVITY_FORM_CONTENT_HIDDEN_FIELD_REMOVE;
    data: string;
}
