/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
    HeaderGroup,
    useSortBy,
    useTable,
    Row as TableRow,
    Cell,
} from "react-table";
import { ApplicationState } from "../../../store";
import * as ReportStore from "../../../store/report/store";
import { FormValues } from "../../../store/report/store";

interface Props {
    formikValues: FormValues;
    touched: any;
    validate: () => void;
    setValue: (field: string, value: any) => void;
    setTouched: (field: string, touched: boolean) => void;
    errors: any;
}

interface ListItem {
    id: string;
    name: string;
    selected: boolean;
}

const getData = (state: ApplicationState): ListItem[] => {
    const forms = state.report?.domain.orgFormsList ?? [];
    const template =
        state.report?.domain.reportTemplate ??
        ReportStore.unloadedState.domain.reportTemplate;
    const listItems = forms.map((a): ListItem => {
        return {
            ...a,
            selected: template.formIds.includes(a.id),
        };
    });
    return listItems;
};

const getEdit = (state: ApplicationState): boolean => {
    return (
        state.report?.ui.reportTemplate.isEdit ??
        ReportStore.unloadedState.ui.reportTemplate.isEdit
    );
};

/**
 *  Table to display an ogranisation's users and actions
 */
const ReportFormTable: React.FC<Props> = (props) => {
    const items = useSelector(getData);
    const isEdit = useSelector(getEdit);

    const columns = React.useMemo(() => {
        const columnArray = [
            {
                Header: "Select",
                accessor: (row: ListItem): boolean => row.selected,
                isSorted: false,
            },
            {
                Header: "Name",
                accessor: (row: ListItem): string => row.name,
            },
        ];

        if (!isEdit) {
            const indexOf = columnArray.findIndex((a) => (a.Header = "Select"));
            if (indexOf > -1) {
                columnArray.splice(indexOf, 1);
            }
        }

        return columnArray;
    }, [isEdit]);

    const data = React.useMemo(() => items, [items]);

    const tableInstance = useTable<ListItem>(
        {
            columns,
            data,
        },
        // useGlobalFilter,
        useSortBy,
    );

    const {
        getTableProps,
        getTableBodyProps,
        // setGlobalFilter,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    const produceHeaderGroupHeaders = (column, headerGroupId) => {
        const isSortedIcon = column.isSortedDesc ? " 🔽" : " 🔼";
        const sortedIcon = column.isSorted ? isSortedIcon : "";

        const header = column.render("Header");

        const columnProps = {
            ...column.getHeaderProps(column.getSortByToggleProps()),
        };

        return (
            // Apply the header cell props
            <th
                key={`reportFormTable_header_${headerGroupId}_${column.id}`}
                {...columnProps}
            >
                {
                    // Render the header
                    header
                }
                <span>{sortedIcon}</span>
            </th>
        );
    };

    const produceHeaderGroup = (headerGroup: HeaderGroup<ListItem>) => (
        // Apply the header row props
        <tr
            key={`reportFormTable_header_${headerGroup.id}`}
            {...headerGroup.getHeaderGroupProps()}
        >
            {
                // Loop over the headers in each row
                headerGroup.headers.map((header) =>
                    produceHeaderGroupHeaders(header, headerGroup.id),
                )
            }
        </tr>
    );

    const produceCell = (
        cell: Cell<ListItem, any>,
        row: TableRow<ListItem>,
    ) => {
        const checkClassNames =
            props.errors.formSelection && props.touched.formSelection
                ? "form-control form-check is-invalid"
                : "form-check";
        // Apply the cell props
        return (
            <td
                key={`reportFormTable_content_${row.id}_${cell.column.id}`}
                {...cell.getCellProps()}
            >
                {cell.column.Header !== "Select" ? (
                    // Render the cell contents
                    cell.render("Cell")
                ) : (
                    <Form.Check
                        className={checkClassNames}
                        type="checkbox"
                        id={row.original.id}
                        defaultChecked={props.formikValues.formSelection.includes(
                            row.original.id,
                        )}
                        onChange={(
                            event: React.FormEvent<HTMLInputElement>,
                        ): void => {
                            props.setTouched("formSelection", true);
                            const checked = event.currentTarget.checked;
                            const formId = row.original.id;
                            const currentSelected = [
                                ...props.formikValues.formSelection,
                            ];
                            if (checked) {
                                if (!currentSelected.includes(formId)) {
                                    currentSelected.push(formId);
                                }
                            } else {
                                const index =
                                    props.formikValues.formSelection.indexOf(
                                        formId,
                                    );
                                if (index > -1) {
                                    currentSelected.splice(index, 1);
                                }
                            }
                            props.setValue("formSelection", currentSelected);
                        }}
                    ></Form.Check>
                )}
            </td>
        );
    };

    const produceRow = (row: TableRow<ListItem>) => {
        // Prepare the row for display
        prepareRow(row);
        return (
            // Apply the row props
            <tr
                key={`reportFormTable_content_${row.id}`}
                {...row.getRowProps()}
            >
                {
                    // Loop over the rows cells
                    row.cells.map((cell) => produceCell(cell, row))
                }
            </tr>
        );
    };

    return (
        <>
            {/* <GlobalFilter globalFilter={"2"} setGlobalFilter={setGlobalFilter} /> */}
            <Row>
                <Col>
                    <Table {...getTableProps()}>
                        <thead>
                            {
                                // Loop over the header rows
                                headerGroups.map(produceHeaderGroup)
                            }
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {/* {rows.map(renderRow) => ()} */}
                            {
                                // Loop over the table rows
                                rows.map(produceRow)
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>{" "}
        </>
    );
};

export default ReportFormTable;
