export default class ReportConstants {
    static readonly ApiPrefix = "/api";
    static readonly ResourceName = "/report";
    static readonly ReportTemplate = "/report-template";
    static readonly Ui = "/ui";
    static readonly ApiEndpoint = `${ReportConstants.ApiPrefix}${ReportConstants.ResourceName}`;
    static readonly ApiDownloadReport = `${ReportConstants.ApiEndpoint}${ReportConstants.Ui}/organisation-reports-download`;
    static readonly ApiDeleteReport = `${ReportConstants.ApiEndpoint}${ReportConstants.Ui}/organisation-reports-delete`;
    static readonly ApiPostGenerateReport = `${ReportConstants.ApiEndpoint}/generate`;
    static readonly ApiGetReportTemplateListItems = `${ReportConstants.ApiEndpoint}${ReportConstants.Ui}/organisation-report-template-list-items`;
    static readonly ApiGetFormListItems = `${ReportConstants.ApiEndpoint}${ReportConstants.Ui}/organisation-reports-list-template-items`;
    static readonly ApiGet = `${ReportConstants.ApiEndpoint}${ReportConstants.Ui}/organisation-reports-list-template-items`;
    static readonly ApiReportFormListItems = `${ReportConstants.ApiEndpoint}${ReportConstants.Ui}/report-form-list-items`;
}
