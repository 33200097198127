import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Formik } from "formik";
import * as React from "react";
import { Button } from "react-bootstrap";
import { useLoadPastRecommendationMutation } from "../../query/examinationsReviewQueries";
import {
    PastRecommendationAddGrid,
    RECOMMENDATION_SELECTED_FIELD,
} from "./PastRecommendationAddGrid";

interface LoadPastRecommendationsModalProps {
    recommendationsActivityId: string;
    organisationId: string;
    onClose: () => void;
}

const LoadPastRecommendationsModal: React.FC<
    LoadPastRecommendationsModalProps
> = ({ recommendationsActivityId, organisationId, onClose }) => {
    const postLoadPastRecommendation = useLoadPastRecommendationMutation(
        organisationId,
        recommendationsActivityId,
    );

    const initialFormValues = React.useMemo(() => {
        return { [RECOMMENDATION_SELECTED_FIELD]: [] };
    }, []);

    const onSubmit = React.useCallback(
        async (values) => {
            postLoadPastRecommendation.mutate({
                selectedIds: values[RECOMMENDATION_SELECTED_FIELD].map(
                    (dataItem) => dataItem.id,
                ),
            });
            onClose();
        },
        [onClose, postLoadPastRecommendation],
    );

    return (
        <Dialog
            title="Load Past Recommendations"
            onClose={onClose}
            width="calc(100vw - 40px)"
            height="calc(100vh - 40px)"
        >
            <Formik onSubmit={onSubmit} initialValues={initialFormValues}>
                {({ values, handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                        <PastRecommendationAddGrid
                            organisationId={organisationId}
                            activityId={recommendationsActivityId}
                        />
                        <DialogActionsBar>
                            <Button
                                type="button"
                                variant="outline-secondary"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="primary"
                                disabled={
                                    values[RECOMMENDATION_SELECTED_FIELD]
                                        ?.length === 0
                                }
                            >
                                Load Selected
                            </Button>
                        </DialogActionsBar>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default LoadPastRecommendationsModal;
