export default class ProcessReportTemplateConstants {
    static readonly ApiEndpoint = "/api/processreporttemplate";
    static readonly ResourceName = "processreporttemplate";
    static readonly RoutePath = "/process-template";
    static readonly Role = "/role";
    static readonly User = "/user";
    static readonly ActionCreate = "/create";
    static readonly ReportTemplateRoutePath = "/process-template";
    static readonly ReportTemplateCreate = "/report-template/create";
    static readonly ReportTemplateView = "/report-template";
    static readonly ReportTemplateFilePath = "/file";
}
