import ApiConstants from "../../../Constants/ApiConstants";
import { getData } from "../../../helpers/ApiHelper";
import { RequestedFileDto } from "./types";

enum UrlPaths {
    BaseResource = "organisation",
    UserRequestedFiles = "UserRequestedFiles",
    GetUserRequestedFileStatus = "GetUserRequestedFileStatus",
}

const baseUrl = `${ApiConstants.ApiPrefix}/${UrlPaths.BaseResource}`;

export async function getUserRequestedFileStatus({
    organisationId,
    fileId,
}: {
    organisationId: string;
    fileId: string;
}): Promise<RequestedFileDto> {
    const url = `${baseUrl}/${organisationId}/${UrlPaths.UserRequestedFiles}/${UrlPaths.GetUserRequestedFileStatus}/${fileId}`;
    const response = await getData<RequestedFileDto>(url);

    if (!response) return;

    return response;
}
