import React, { useMemo } from "react";
import { useParams } from "react-router";
import BridgeConditionIndexGraphComponent from "../components/bci-components/BridgeConditionIndexGraphComponent";
import { subsetConfigs } from "./orgReportsBciConfigs";

const titleConfigs = {
    lineGraph: [
        {
            bciTitleName:
                "Bridge Condition Index (Average by Component Group) For",
        },
        {
            bciTitleName:
                "Bridge Condition Index (Critical by Component Group) For",
        },
        {
            bciTitleName:
                "Bridge Condition Index (Average by Defect Group) For",
        },
        {
            bciTitleName:
                "Bridge Condition Index (Critical by Defect Group) For",
        },
    ],
    barGraph: [],
};

const OrgReportsBciChartsPage: React.FC = () => {
    const { orgShortName } = useParams<{ orgShortName: string }>();

    const getOrgSpecificCharts = useMemo(() => {
        return subsetConfigs[orgShortName]?.flatMap((config) => {
            return [
                ...titleConfigs.lineGraph.map((titleConfig) => (
                    <BridgeConditionIndexGraphComponent
                        key={`${config.bciConfigName}-${titleConfig.bciTitleName}`}
                        bciConfigName={config.bciConfigName}
                        title={`${titleConfig.bciTitleName} ${config.displayName}`}
                        type={"line"}
                        chartType={
                            titleConfig.bciTitleName.includes("Component")
                                ? "ComponentGroup"
                                : "DefectCategory"
                        }
                        scoreType={
                            titleConfig.bciTitleName.includes("Critical")
                                ? "Critical"
                                : "Average"
                        }
                    />
                )),
                ...titleConfigs.barGraph.map((titleConfig) => (
                    <BridgeConditionIndexGraphComponent
                        key={`${config.bciConfigName}-${titleConfig.bciTitleName}`}
                        bciConfigName={config.bciConfigName}
                        title={`${titleConfig.bciTitleName} ${config.displayName}`}
                        type={"column"}
                        chartType={
                            titleConfig.bciTitleName.includes("Component")
                                ? "ComponentGroup"
                                : "DefectCategory"
                        }
                        scoreType="Average"
                    />
                )),
            ];
        });
    }, [orgShortName]);

    return (
        <div>
            <h1>
                BCI Graphs{" "}
                <small style={{ fontSize: "0.5em" }}>
                    (BCI scores are valid only when all elements of the bridge
                    or structure have been inspected and logged in the system)
                </small>
            </h1>
            <BridgeConditionIndexGraphComponent
                chartType="ComponentGroup"
                scoreType="Average"
                title="Bridge Condition Index (Average) by Component Group"
                bciConfigName={orgShortName + "-bridge"}
                type={"line"}
            />
            <BridgeConditionIndexGraphComponent
                chartType="ComponentGroup"
                scoreType="Critical"
                title="Bridge Condition Index (Critical) by Component Group"
                bciConfigName={orgShortName + "-bridge"}
                type={"line"}
            />
            <BridgeConditionIndexGraphComponent
                chartType="DefectCategory"
                scoreType="Average"
                title="Bridge Condition Index (Average) by Defect Category"
                bciConfigName={orgShortName + "-bridge"}
                type={"line"}
            />
            <BridgeConditionIndexGraphComponent
                chartType="DefectCategory"
                scoreType="Critical"
                title="Bridge Condition Index (Critical) by Defect Category"
                bciConfigName={orgShortName + "-bridge"}
                type={"line"}
            />
            {getOrgSpecificCharts}
        </div>
    );
};

export default OrgReportsBciChartsPage;
