import * as React from "react";
import ReactMarkdown from "react-markdown";
import { Card, Tab } from "react-bootstrap";

type HelpDisplayItemProps = {
    id: string;
    name: string;
    description: string;
    email: string;
    phone: string;
};

const HelpDisplayItem = ({
    id,
    description,
    email,
    phone,
}: HelpDisplayItemProps): JSX.Element => (
    <Tab.Pane eventKey={id}>
        <Card>
            <Card.Body>
                <ReactMarkdown>{description}</ReactMarkdown>
            </Card.Body>
            {(phone || email) && (
                <Card.Footer>
                    <Card.Subtitle>Contact:</Card.Subtitle>
                    {email && (
                        <Card.Link
                            href={`mailto:${email}`}
                            className="help-section-item-link"
                        >
                            Email: {email}
                        </Card.Link>
                    )}
                    {phone && (
                        <Card.Link href={`tel:${phone}`}>
                            Phone: {phone}
                        </Card.Link>
                    )}
                </Card.Footer>
            )}
        </Card>
    </Tab.Pane>
);

export default HelpDisplayItem;
