import { AppThunkAction } from "..";
import * as Actions from "./actions";
import ReportService from "../../services/ReportService";

// declared type strings (and not any other arbitrary string).
export type KnownAction =
    | Actions.RequestReportListItemsAction
    | Actions.ReceiveReportListItemsAction
    | Actions.RequestReportTemplateListItemsAction
    | Actions.ReceiveReportTemplateListItemsAction
    | Actions.RequestReportTemplateAction
    | Actions.ReceiveReportTemplateAction
    | Actions.DeleteOrgReportTemplateSuccessAction
    | Actions.DeleteOrgReportSuccessAction
    | Actions.ReportEditStart;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    getOrgListItems:
        (organisationId: string): AppThunkAction<KnownAction> =>
        async (dispatch) => {
            // Only load data if it's something we don't already have (and are not already loading)
            ReportService.GetOrgTemplateListItems(organisationId)
                .then((data) => {
                    dispatch({
                        type: Actions.RECEIVE_ORG_TEMPLATE_LIST_ITEMS,
                        data: data,
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
            dispatch({ type: Actions.REQUEST_ORG_TEMPLATE_LIST_ITEMS });
        },
    getReportListItemsByTemplate:
        (formTemplateId: string): AppThunkAction<KnownAction> =>
        async (dispatch) => {
            // Only load data if it's something we don't already have (and are not already loading)
            ReportService.GetFormListItemsByTemplate(formTemplateId)
                .then((data) => {
                    dispatch({
                        type: Actions.RECEIVE_REPORT_LIST_ITEMS,
                        data: data,
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
            dispatch({ type: Actions.REQUEST_REPORT_LIST_ITEMS });
        },
    initEdit:
        (reportTemplateId: string): AppThunkAction<KnownAction> =>
        async (dispatch) => {
            // Only load data if it's something we don't already have (and are not already loading)
            ReportService.GetReportTemplate(reportTemplateId)
                .then((data) => {
                    dispatch({
                        type: Actions.RECEIVE_REPORT_TEMPLATE,
                        data: data,
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
            dispatch({ type: Actions.REQUEST_REPORT_TEMPLATE });
            ReportService.GetFormListItemsByReport(reportTemplateId)
                .then((data) => {
                    dispatch({
                        type: Actions.RECEIVE_REPORT_LIST_ITEMS,
                        data: data,
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
            dispatch({ type: Actions.REQUEST_REPORT_LIST_ITEMS });
        },
    delete:
        (reportTemplateId: string): AppThunkAction<KnownAction> =>
        async (dispatch) => {
            ReportService.Delete(reportTemplateId)
                .then(() => {
                    dispatch({
                        type: Actions.DELETE_ORG_REPORT_TEMPLATE_SUCCESS,
                        data: reportTemplateId,
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    deleteReport:
        (
            reportTemplateId: string,
            reportId: string,
        ): AppThunkAction<KnownAction> =>
        async (dispatch) => {
            ReportService.DeleteReport(reportTemplateId, reportId)
                .then(() => {
                    dispatch({
                        type: Actions.DELETE_ORG_REPORT_SUCCESS,
                        data: reportId,
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    startEdit:
        (formTemplateId: string): AppThunkAction<KnownAction> =>
        async (dispatch) => {
            dispatch({ type: Actions.REQUEST_REPORT_LIST_ITEMS });
            dispatch({ type: Actions.REPORT_EDIT_START });
            ReportService.GetFormListItemsByTemplate(formTemplateId)
                .then((data) => {
                    dispatch({
                        type: Actions.RECEIVE_REPORT_LIST_ITEMS,
                        data: data,
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    initCreate: (): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({ type: Actions.REPORT_EDIT_START });
    },
};
