import * as React from "react";
import {
    TemplateContentStyle,
    ShowCondition,
} from "../../../modules/template/domain/types";
import FormControlBase from "./FormControlBase";

interface FormUriConfig {
    field: string;
    value: string;
    prompt: string;
    uri: string;
    style: TemplateContentStyle;
    showConditions: ShowCondition[];
}

interface Prop {
    config: FormUriConfig;
}

const FormUriComponent: React.FC<Prop> = (props) => {
    const { config } = props;
    return (
        <FormControlBase
            contentConfig={{
                field: config.field,
                showConditions: config.showConditions,
            }}
        >
            <a
                href={config.uri}
                target="_blank"
                rel="noreferrer"
                className={`h${config.style?.size ?? 4}`}
                style={{ color: `${config.style?.color ?? "none"}` }}
            >
                {config.value}
            </a>
        </FormControlBase>
    );
};

export default FormUriComponent;
