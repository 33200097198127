import {
    Grid,
    GridCellProps,
    GridColumn,
    GridExpandChangeEvent,
} from "@progress/kendo-react-grid";
import React, { useEffect } from "react";
import { SortDescriptor } from "@progress/kendo-data-query";
import { jSDateFromIso } from "../../../../../helpers/dateTimeHelpers";
import {
    emptyFilterDescriptor,
    SimplifiedMenuFilter,
} from "../../../../../common/components/table/kendo/columnFilters";
import useGridProps from "../../../../../common/components/table/kendo/useGridProps";
import DownloadCell from "./DownloadCell";
import ExaminationBulkUpdaterGridDetails from "./ExaminationBulkUpdaterGridDetails";
import { ExaminationBulkUpdate } from "../../domain/examinationBlukUpdate.types";

interface Props {
    bulkUpdates: ExaminationBulkUpdate[];
    organisationId: string;
}

type ExaminationBulkUpdateData = {
    expanded?: boolean;
    createdOn: Date;
} & Omit<ExaminationBulkUpdate, "createdOn">;

function getExaminationBulkUpdateData(
    bulkUpdates: ExaminationBulkUpdate[],
): ExaminationBulkUpdateData[] {
    return bulkUpdates.map(({ createdOn, ...other }) => ({
        expanded: false,
        createdOn: jSDateFromIso(createdOn) || null,
        ...other,
    }));
}

const ExaminationBulkUpdaterGrid: React.FC<Props> = ({
    bulkUpdates,
    organisationId,
}) => {
    const [bulkUpdatesData, setExaminationBulkUpdateData] = React.useState<
        ExaminationBulkUpdateData[]
    >(getExaminationBulkUpdateData(bulkUpdates));

    useEffect(() => {
        setExaminationBulkUpdateData(getExaminationBulkUpdateData(bulkUpdates));
    }, [bulkUpdates]);

    const initialSort: Array<SortDescriptor> = [
        { field: "createdOn", dir: "desc" },
    ];

    const { gridProps } = useGridProps(bulkUpdatesData, {
        filterable: true,
        initialFilter: emptyFilterDescriptor,
        sortable: true,
        pageable: false,
        initialSort: initialSort,
    });

    const handleExpand = (event: GridExpandChangeEvent) => {
        const newData = bulkUpdatesData.map(
            (item: ExaminationBulkUpdateData) => {
                if (item.id === event.dataItem.id) {
                    item.expanded = !event.dataItem.expanded;
                }
                return item;
            },
        );
        setExaminationBulkUpdateData(newData);
    };

    const GridDownloadCell = (props: GridCellProps) => (
        <DownloadCell cellProps={props} organisationId={organisationId} />
    );

    return (
        <>
            <div className="py-2">
                <Grid
                    {...gridProps}
                    style={{ cursor: "pointer" }}
                    detail={ExaminationBulkUpdaterGridDetails}
                    expandField="expanded"
                    onExpandChange={handleExpand}
                >
                    <GridColumn
                        field="name"
                        title="Name"
                        columnMenu={SimplifiedMenuFilter}
                    />
                    <GridColumn
                        field="status"
                        title="Status"
                        columnMenu={SimplifiedMenuFilter}
                    />
                    <GridColumn
                        field="createdOn"
                        title="Creation date"
                        filter={"date"}
                        format="{0:d MMM y h:mm a}"
                        columnMenu={SimplifiedMenuFilter}
                    />
                    <GridColumn width={60} cell={GridDownloadCell} />
                </Grid>
            </div>
        </>
    );
};

export default ExaminationBulkUpdaterGrid;
