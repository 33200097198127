import * as React from "react";
import { useNavigation } from "../../../../router/useNavigate";
import { useGetApcIncidentsQuery } from "../queries/apcApiSlice";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { useParams } from "react-router";
import useGridProps from "../../../../common/components/table/kendo/useGridProps";
import { AppRoutes } from "../../../../router/AppRoutes";
import CoveringLoadingPanel from "../../../../common/components/CoveringLoadingPanel";
import { DateTime } from "luxon";
import { ColumnMenuFilter } from "../../../../common/components/table/kendo/columnFilters";

type IncidentsGridPros = {
    organisationId: string;
};

interface GridRow {
    ameyNotifiedAtJs: Date;
    incidentAtJs: Date;
    nrNotifiedAtJs: Date;
    id: string;
    incidentNumber: string;
    reportType: string;
    state: string;
    examiner: string;
}

const IncidentsGrid: React.FC<IncidentsGridPros> = ({ organisationId }) => {
    const { navigateToOrgPath: navigateToPath } = useNavigation();
    const { orgShortName } = useParams<{ orgShortName: string }>();
    const { data, isLoading } = useGetApcIncidentsQuery(
        { organisationId },
        { refetchOnFocus: true, skip: !organisationId },
    );

    const parsedData =
        data?.map(
            ({
                ameyNotifiedAt,
                incidentAt,
                nrNotifiedAt,
                ...other
            }): GridRow => {
                return {
                    // Example: ISO date string to JS date with timezone
                    ameyNotifiedAtJs: DateTime.fromISO(
                        ameyNotifiedAt.toString(),
                        {
                            zone: "utc",
                        },
                    )
                        .setZone(
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                        )
                        .toJSDate(),
                    incidentAtJs: DateTime.fromISO(incidentAt.toString(), {
                        zone: "utc",
                    })
                        .setZone(
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                        )
                        .toJSDate(),
                    nrNotifiedAtJs: DateTime.fromISO(nrNotifiedAt.toString(), {
                        zone: "utc",
                    })
                        .setZone(
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                        )
                        .toJSDate(),
                    ...other,
                };
            },
        ) ?? [];

    const { gridProps } = useGridProps(parsedData, {
        sortable: true,
        pageable: true,
    });

    if (isLoading) return <CoveringLoadingPanel />;

    return (
        <Grid
            {...gridProps}
            dataItemKey={"id"}
            fixedScroll={true}
            reorderable={true}
            resizable={true}
            sortable={true}
            className="examinations-grid-h m-3"
            onRowDoubleClick={(ev) =>
                navigateToPath(
                    AppRoutes.ApcDetails.toLink({
                        orgShortName: orgShortName,
                        apcId: ev.dataItem.id,
                    }),
                )
            }
        >
            <GridColumn
                field="incidentNumber"
                title="Incident Number"
                filter={"text"}
                columnMenu={ColumnMenuFilter}
                sortable
            />
            <GridColumn
                field="ameyNotifiedAtJs"
                title="Time Amey Notified"
                format="{0:HH:mm}"
                // filter={"text"}
                // columnMenu={ColumnMenuFilter}
                sortable
            />
            <GridColumn
                field="incidentAtJs"
                title="Time of Incident"
                // filter={"text"}
                format="{0:HH:mm}"
                // columnMenu={ColumnMenuFilter}
                sortable
            />
            <GridColumn
                field="nrNotifiedAtJs"
                title="Time Reported To NR"
                sortable
                // filter={"text"}
                // columnMenu={ColumnMenuFilter}
                format="{0:HH:mm}"
            />
            <GridColumn
                field="examDetails.examType"
                title="Type of Report"
                sortable
                columnMenu={ColumnMenuFilter}
                filter={"text"}
            />
            <GridColumn
                field="apcAssetDetails.elr"
                title="ELR"
                sortable
                columnMenu={ColumnMenuFilter}
                filter={"text"}
            />
            <GridColumn
                field="apcAssetDetails.miles"
                title="Miles"
                sortable
                filter={"text"}
                // columnMenu={ColumnMenuFilter}
            />
            <GridColumn
                field="apcAssetDetails.yards"
                title="Yards"
                sortable
                filter={"text"}
                // columnMenu={ColumnMenuFilter}
            />
            <GridColumn
                field="apcAssetDetails.chains"
                title="Chains"
                sortable
                filter={"text"}
                // columnMenu={ColumnMenuFilter}
            />
            <GridColumn
                field="apcAssetDetails.structureRefNum"
                title="Structure Ref"
                sortable
                filter={"text"}
                columnMenu={ColumnMenuFilter}
            />
            {/* <GridColumn field="apcAssetDetails.structureName" title="Structure Name" sortable /> */}
            <GridColumn
                field="examinerEmail"
                title="Examiner"
                sortable
                filter={"text"}
                columnMenu={ColumnMenuFilter}
            />
            <GridColumn
                field="state"
                title="Status"
                sortable
                filter={"text"}
                columnMenu={ColumnMenuFilter}
            />
        </Grid>
    );
};

export default IncidentsGrid;
