import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { CustomSelect, TextInput } from "../../../../common/components/form";
import { getCreateExamOptions } from "../common/options";
import { useNavigation } from "../../../../router/useNavigate";

interface ExamViewControlProps {
    processId: string;
}
const ExamViewControl: React.FC<ExamViewControlProps> = ({ processId }) => {
    const { navigateToOrgPath: navigateToPath } = useNavigation();
    const onNavigate = React.useCallback(() => {
        navigateToPath(`/:orgShortName/process/${processId}`);
    }, [navigateToPath, processId]);

    return (
        <>
            <Row>
                <Col xs={4}>
                    <TextInput
                        label="CARRS Exam Id"
                        name="examDetails.carrsExamId"
                        plainText={true}
                        readOnly={true}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={4}>
                    <CustomSelect
                        name="examDetails.examType"
                        label="Report Type"
                        options={getCreateExamOptions()}
                        plainText={true}
                        readOnly={true}
                    />
                </Col>
            </Row>
            {processId && (
                <Row>
                    <Col xs={4}>
                        <Button
                            onClick={onNavigate}
                            type="button"
                            variant="outline-primary"
                            className="mb-4"
                        >
                            View Exam
                        </Button>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default ExamViewControl;
