import React from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { ErrorResponse } from "./common/types/Errors";
import { getNotificationsForSpecialErrors } from "./store/features/notifications/errorNotification";
import { addError } from "./store/features/notifications/notificationsSlice";

export const QueryClientSetup: React.FC = ({ children }) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const onError = React.useCallback(
        (data: unknown) => {
            const error = getNotificationsForSpecialErrors(
                data as ErrorResponse,
            );
            dispatch(addError(error));
        },
        [dispatch],
    );

    React.useEffect(() => {
        queryClient.setDefaultOptions({
            queries: {
                refetchOnWindowFocus: false,
                retry: false,
                onError,
            },
            mutations: {
                onError,
            },
        });
    }, [onError, queryClient]);

    return <>{children}</>;
};
