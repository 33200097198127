import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";

export default class Identity {
    private account: AccountInfo;
    private rawIdToken: string;

    constructor(tokenResponse: AuthenticationResult) {
        this.account = tokenResponse.account;
        this.rawIdToken = tokenResponse.accessToken;
    }

    get emailAddress(): string {
        return this.account.username;
    }

    get name(): string {
        return this.account.name;
    }

    get accessToken(): string {
        return this.rawIdToken;
    }
}
