export interface Workbank {
    id: string;
    name: string;
    status: WorkbankStatus;
    createdBy: string;
    createdOn: string;
    createdProcessesCount: number;
    createdAssetsCount: number;
    createdAssets: Item[];
    failedRowsCount: number;
    failedItems: Item[];
    skippedRowsCount: number;
    missingAssetTemplateNames: string[];
    missingProcessTemplateNames: string[];
    missingMappings: string[];
    expanded?: boolean;
    contractYear: string;
    errorMessage: string;
}

export const enum WorkbankStatus {
    Pending = "Pending",
    Processing = "Processing",
    Complete = "Complete",
    Error = "Error",
}

export interface Item {
    id: string;
    name: string;
    type: string;
    reason: string;
    organisationId: string;
}
