import * as Formik from "formik";
import React from "react";
import { Button, ButtonToolbar } from "react-bootstrap";
import ButtonSpinner from "../../../common/components/button/ButtonSpinner";
import TextArea from "../../../common/components/form/TextArea";
import { ProcessActivity } from "../../process-activity/domain/types";
import { Approval, ProcessActivityApproval } from "../domain/types";

export interface ApproveActionProps {
    actionCallback: (arg: {
        activity: ProcessActivity;
        approvalId: string;
        comment: string;
    }) => Promise<unknown>;
    actionResult: {
        isLoading: boolean;
        isError: boolean;
        isSuccess: boolean;
        error?: unknown;
    };
    actionDisplayName: string;
}

export enum ApprovalType {
    Approve = "Approve",
    Reject = "Reject",
}

interface FormValues {
    comment: string;
}

interface Props {
    activity: ProcessActivityApproval;
    approval: Approval;

    approve: ApproveActionProps;
    reject: ApproveActionProps;
}

const ApproveRejectForm: React.FC<Props> = ({
    activity,
    approval,
    approve,
    reject,
}) => {
    const [approvalType, setApprovalType] = React.useState(
        ApprovalType.Approve,
    );
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    React.useEffect(() => {
        if (!approve.actionResult.isLoading) {
            setIsSubmitting(false);
        }
    }, [approve.actionResult.isLoading]);

    React.useEffect(() => {
        if (!reject.actionResult.isLoading) {
            setIsSubmitting(false);
        }
    }, [reject.actionResult.isLoading]);

    const handleApprove = React.useCallback(() => {
        setApprovalType(ApprovalType.Approve);
        if (activity.approveCommentDisabled) {
            approve.actionCallback({
                activity,
                approvalId: approval.id,
                comment: "Approved",
            });
        } else {
            setIsSubmitting(true);
        }
    }, [activity, approval.id, approve]);

    const handleReject = (): void => {
        setApprovalType(ApprovalType.Reject);
        setIsSubmitting(true);
    };

    const handleSubmitApproval = React.useCallback(
        ({ comment }: FormValues): void => {
            if (approvalType === ApprovalType.Reject) {
                reject.actionCallback({
                    activity,
                    approvalId: approval.id,
                    comment,
                });
            } else {
                approve.actionCallback({
                    activity,
                    approvalId: approval.id,
                    comment,
                });
            }
        },
        [activity, approval.id, approvalType, approve, reject],
    );

    const approveDisplayName = approve.actionDisplayName ?? "Approve";
    const rejectDisplayName = reject.actionDisplayName ?? "Reject";

    if (!isSubmitting) {
        return (
            <ButtonToolbar className="pt-2">
                <Button onClick={handleApprove} disabled={isSubmitting}>
                    {approveDisplayName}
                    {approve.actionResult.isLoading && <ButtonSpinner />}
                </Button>
                <Button
                    className="ml-1"
                    variant="danger"
                    onClick={handleReject}
                    disabled={isSubmitting}
                >
                    {rejectDisplayName}
                </Button>
            </ButtonToolbar>
        );
    }

    return (
        <div className="pt-2">
            <Formik.Formik
                initialValues={{ comment: "" }}
                validate={(
                    values: FormValues,
                ): Formik.FormikErrors<FormValues> => {
                    const errors: Formik.FormikErrors<FormValues> = {};
                    if (
                        approvalType === ApprovalType.Reject &&
                        !values.comment
                    ) {
                        errors.comment = "Required";
                    }
                    return errors;
                }}
                onSubmit={handleSubmitApproval}
                enableReinitialize={true}
            >
                {({ values, isSubmitting }): JSX.Element => (
                    <Formik.Form>
                        <TextArea
                            label="Comment"
                            name="comment"
                            value={values.comment}
                            rows={5}
                        />
                        <ButtonToolbar>
                            <Button
                                type="submit"
                                variant={
                                    approvalType === ApprovalType.Reject
                                        ? "danger"
                                        : "primary"
                                }
                                disabled={isSubmitting}
                            >
                                {approvalType === ApprovalType.Reject
                                    ? rejectDisplayName
                                    : approveDisplayName}
                                {isSubmitting && <ButtonSpinner />}
                            </Button>
                            <Button
                                variant="secondary"
                                className="ml-1"
                                onClick={(): void => setIsSubmitting(false)}
                            >
                                Cancel
                            </Button>
                        </ButtonToolbar>
                    </Formik.Form>
                )}
            </Formik.Formik>
        </div>
    );
};

export default ApproveRejectForm;
