import React from "react";
import { Button } from "react-bootstrap";
import * as Formik from "formik";
import * as yup from "yup";
import {
    useAddFileCommentMutation,
    useUpdateFileCommentMutation,
} from "../../../store/features/file/file-comments-api-slice";
import MutationResultStatus from "../../../common/components/MutationResultStatus";
import { FileComment } from "../domain/file";
import { FileCommentProps } from "./FileComments";
import RichTextEditor from "../../../common/components/form/RichTextEditor";

interface Props {
    comment?: FileComment;
    props: FileCommentProps;
    isEdit?: boolean;
    onCompleted: () => void;
}

export const FileCommentForm: React.FC<Props> = ({
    comment,
    props,
    isEdit,
    onCompleted,
}) => {
    const validation = yup.object({
        data: yup.string().required("Required"),
    });

    const initialFormValues = {
        data: comment?.data ?? "",
    };

    const [addComment, addCommentResult] = useAddFileCommentMutation();
    const [updateComment, updateCommentResult] = useUpdateFileCommentMutation();

    const handleSubmit = (formValues, { setSubmitting, resetForm }) => {
        const params = { ...props, data: formValues.data };
        if (isEdit) {
            updateComment({
                ...params,
                commentId: comment.id,
            })
                .unwrap()
                .then(() => {
                    resetForm();
                    onCompleted();
                })
                .finally(() => setSubmitting(false));
        } else {
            addComment(params)
                .unwrap()
                .then(() => {
                    resetForm();
                    onCompleted();
                })
                .finally(() => setSubmitting(false));
        }
    };

    return (
        <>
            <MutationResultStatus
                mutationResult={addCommentResult}
                showLoading
            />
            <MutationResultStatus
                mutationResult={updateCommentResult}
                showLoading
            />
            <Formik.Formik
                initialValues={initialFormValues}
                onSubmit={handleSubmit}
                validationSchema={validation}
            >
                {({ values, isSubmitting }) => (
                    <Formik.Form>
                        <RichTextEditor
                            name="data"
                            value={values.data}
                            height={200}
                        />
                        <Button
                            className={"float-right ml-2"}
                            variant="danger"
                            onClick={onCompleted}
                            disabled={isSubmitting}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={"float-right"}
                            variant="primary"
                            type="submit"
                            disabled={isSubmitting}
                        >
                            Submit
                        </Button>
                    </Formik.Form>
                )}
            </Formik.Formik>
        </>
    );
};
