import { TemplateContent } from "../../../../modules/template/domain/types";

export interface ParseResult {
    output: TemplateContent[];
    error: string;
}

const FormConfigHelper = {
    parseConfig: (rawConfig: string): ParseResult => {
        if (rawConfig) {
            try {
                return {
                    output: JSON.parse(rawConfig) as TemplateContent[],
                    error: "",
                };
            } catch (error) {
                return {
                    output: [] as TemplateContent[],
                    error: (error as { message: string }).message,
                };
            }
        } else {
            return {
                output: [] as TemplateContent[],
                error: "",
            };
        }
    },
};
export default FormConfigHelper;
