import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

/* 
    Simple type to be used as a result of RTK Queries hooks
*/
export interface QueryResult<T> {
    isUninitialized?: boolean;
    isLoading: boolean;
    isFetching: boolean;
    isSuccess?: boolean;
    isError?: boolean;
    data?: T;
    error?: FetchBaseQueryError | SerializedError;
}

export function isQueryError<T>(
    response: Partial<QueryResult<T>>,
): response is { error: FetchBaseQueryError } {
    return response.isError && !!response.error && "status" in response.error;
}

export function isQueryErrorForbidden<T>(response: QueryResult<T>): boolean {
    return isQueryError(response) && response.error.status === 403;
}
