import { Alert, Col, Row } from "react-bootstrap";
import { DateTimeSelect } from "../../../../common/components/form";
import React from "react";

const ExaminerInformationControl: React.FC = () => {
    return (
        <>
            <h2>Examiner Information</h2>
            <Col>
                <Alert variant="primary">
                    <Alert.Heading>
                        NOW RING NR TO INFORM OF EXAMINER DETAILS AND ENTER CALL
                        TIME BELOW
                    </Alert.Heading>
                </Alert>
            </Col>
            <Row>
                <Col xs={"auto"}>
                    <DateTimeSelect
                        label="Time Examiner Details Confirmed to NR"
                        name="examinerConfirmedToNrAt"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={"auto"}>
                    <DateTimeSelect
                        label="Time Examiner Arrived on Site"
                        name="examinerSiteArrivalAt"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={"auto"}>
                    <DateTimeSelect
                        label="Time Passes at MPH"
                        name="timePassesAtMph"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={"auto"}>
                    <DateTimeSelect
                        label="Passed at (MPH)"
                        name="passedAtMph"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={"auto"}>
                    <DateTimeSelect
                        label="Time Passed at Linespeed"
                        name="passedAtLineSpeedAt"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={"auto"}>
                    <DateTimeSelect
                        label="Time Examiner Departure from Site"
                        name="examinerDepartureSiteAt"
                    />
                </Col>
            </Row>
        </>
    );
};
export default ExaminerInformationControl;
