import * as React from "react";
import { Row, Col } from "react-bootstrap";
import AddUserForm from "../components/user/AddUserForm";
import UserTable from "../components/user/UsersTable";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";
import useOrgPermission from "../../../common/hooks/useOrgPermission";
import UserManagmentPageTitles from "./pageTitles";
import { useOrgTitleById } from "../../../common/hooks/useTitle";
import { useGetUsersByOrgIdQuery } from "../../../store/features/organisation/organisation-api-slice";

const userAddPermissions = [
    PermissionConstants.OrgUser.create,
    PermissionConstants.OrgUserGroup.read,
    PermissionConstants.OrgGroupUsers.manage,
];

const UsersManagementPage: React.FC = () => {
    const { organisationId } = useOrganisationId();
    const canAdd = useOrgPermission(userAddPermissions);
    const {
        data: users,
        isFetching,
        isLoading,
    } = useGetUsersByOrgIdQuery(organisationId, {
        skip: !organisationId,
    });
    useOrgTitleById(organisationId, UserManagmentPageTitles.UserList);

    return (
        <>
            <h1 className="top30">Users</h1>
            <Row>
                <Col>
                    {canAdd && !isLoading && !isFetching && (
                        <Row className="mt-3">
                            <Col>
                                <AddUserForm organisationId={organisationId} />
                            </Col>
                        </Row>
                    )}
                    <Row className="mt-3">
                        <Col>
                            <UserTable
                                organisationId={organisationId}
                                organisationUsers={users}
                                isFetching={isFetching}
                                isLoading={isLoading}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default UsersManagementPage;
