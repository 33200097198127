import { QueryKey } from "react-query";
import {
    InfiniteQueryOptions,
    searchOptionsKey,
} from "../../../../../common/types/SortAndFilterOptions";

export const missingCarrsExamsKey = "missing-carrs-exams";

const missingCarrsExamsQueryKeys = {
    missingCarrsExams: (orgId: string): QueryKey => [
        missingCarrsExamsKey,
        `${missingCarrsExamsKey}-${orgId}`,
    ],
    filteredMissingCarrsExams: (
        orgId: string,
        searchOptions: InfiniteQueryOptions,
    ): QueryKey => [
        missingCarrsExamsKey,
        `${missingCarrsExamsKey}-${orgId}`,
        ...searchOptionsKey(searchOptions),
    ],
};

export default missingCarrsExamsQueryKeys;
