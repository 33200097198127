import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import CoveringLoadingPanel from "../../../common/components/CoveringLoadingPanel";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { AssetModel } from "../../../store/features/asset/asset-api-slice";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";
import AssetList from "./AssetList";

interface AssetDetailsFormProps {
    asset: AssetModel;
    orgShortName: string;
}
const AssetHierarchy: React.FC<AssetDetailsFormProps> = ({
    asset,
    orgShortName,
}) => {
    const { organisationId, isLoadingOrgId } = useOrganisationId();
    if (isLoadingOrgId) return <CoveringLoadingPanel />;

    const canCreateAsset = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgAsset.create,
    ).hasPermission;

    return (
        <>
            <Row className="top10">
                <Col>
                    {canCreateAsset && (
                        <Link
                            to={`/${orgShortName}${OrganisationConstants.AssetCreate}?parent=${asset.id}`}
                            className="btn btn-primary float-right"
                        >
                            Create
                        </Link>
                    )}
                </Col>
            </Row>
            <Row className="top10">
                <div>
                    <AssetList
                        assets={asset.children}
                        orgShortName={orgShortName}
                    />
                </div>
            </Row>
        </>
    );
};

export default AssetHierarchy;
