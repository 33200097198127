import * as React from "react";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { MultiQueryResultStatus } from "../../../common/components/QueryResultStatus";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { useGetProcessesCompletedQuery } from "../store/severn-api-slice";
import InspectionsGrid from "../components/InspectionsGrid";
import severnPageTitles from "./pageTitles";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";

const OrgReportsInspectionsPage: React.FC<
    RouteComponentProps<{ orgShortName: string }>
> = (props) => {
    const orgShortName = props.match.params.orgShortName;

    useOrgTitleWithShortName(severnPageTitles.inspections);

    const { organisationId, isLoadingOrgId } = useOrganisationId();

    const reportQueryResults = useGetProcessesCompletedQuery(organisationId, {
        skip: isLoadingOrgId,
    });

    const { data: processes } = reportQueryResults;

    return (
        <>
            <MultiQueryResultStatus results={[reportQueryResults]} />
            <Row className="pt-2">
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={"div"}>
                            <Link
                                to={`/${orgShortName}${OrganisationConstants.Report}`}
                            >
                                Reports
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            {severnPageTitles.inspections}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>{severnPageTitles.inspections}</h1>
                </Col>
            </Row>
            {processes && (
                <Row className="pt-2">
                    <Col>
                        <InspectionsGrid processes={processes} />
                    </Col>
                </Row>
            )}
        </>
    );
};

export default OrgReportsInspectionsPage;
