import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { IdAndName } from "../../../../common/types/IdAndName";
import { useRemoveUserFromGroupMutation } from "../../../../store/features/organisation/organisation-api-slice";

interface Props {
    orgId: string;
    userGroupId: string;
    canRemoveUsers: boolean;
    user: IdAndName;
}

const UserGroupUsersTableActions: React.FC<Props> = ({
    orgId,
    userGroupId,
    canRemoveUsers,
    user,
}) => {
    const [removeUserFromGroup] = useRemoveUserFromGroupMutation();
    const [isRemoving, setIsRemoving] = useState(false);

    const handleRemoveUserFromGroup = async (userId: string) => {
        await removeUserFromGroup({
            userId: userId,
            orgId: orgId,
            groupId: userGroupId,
        });
    };

    const onRemove = async (id: string) => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            await handleRemoveUserFromGroup(id);
            setIsRemoving(true);
        }
    };

    return (
        <>
            {canRemoveUsers && (
                <Button
                    variant="danger"
                    onClick={async (): Promise<void> => onRemove(user.id)}
                    disabled={isRemoving}
                >
                    Remove
                </Button>
            )}
        </>
    );
};

export default UserGroupUsersTableActions;
