import { push, goBack } from "connected-react-router";
import RoleService from "../../services/RoleService";
import { AppThunkAction } from "..";
import {
    AdminRoleUserAddCancelAction,
    AdminRoleUserAddStartAction,
    AdminRoleUserAddSuccessAction,
    AdminRoleUserAddUserReceiveUsersAction,
    AdminRoleUserRemoveSuccessAction,
    AdminRoleUsersReceiveAction,
    ADMIN_ROLE_USERS_RECEIVE,
    ADMIN_ROLE_USER_ADD_CANCEL,
    ADMIN_ROLE_USER_ADD_RECEIVE_USERS,
    ADMIN_ROLE_USER_ADD_START,
    ADMIN_ROLE_USER_ADD_SUCCESS,
    ADMIN_ROLE_USER_REMOVE_SUCCESS,
    ReceiveRoleAction,
    ReceiveRolesAction,
    RECEIVE_ROLE,
    RECEIVE_ROLES,
    RequestRoleAction,
    RequestRolesAction,
    REQUEST_ROLE,
    REQUEST_ROLES,
} from "./actions";

// declared type strings (and not any other arbitrary string).
export type KnownAction =
    | RequestRolesAction
    | ReceiveRolesAction
    | RequestRoleAction
    | ReceiveRoleAction
    | AdminRoleUserRemoveSuccessAction
    | AdminRoleUserAddCancelAction
    | AdminRoleUserAddStartAction
    | AdminRoleUserAddSuccessAction
    | AdminRoleUserAddUserReceiveUsersAction
    | AdminRoleUsersReceiveAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    push: push,
    goBack: goBack,
    getRoles: (): AppThunkAction<KnownAction> => async (dispatch) => {
        // Only load data if it's something we don't already have (and are not already loading)
        RoleService.getRoles()
            .then((data) => {
                dispatch({
                    type: RECEIVE_ROLES,
                    data: data,
                });
            })
            .catch((error) => {
                console.error(error);
            });
        dispatch({ type: REQUEST_ROLES });
    },
    getRole:
        (id: string): AppThunkAction<KnownAction> =>
        async (dispatch) => {
            RoleService.getRole(id)
                .then((data) => {
                    dispatch({
                        type: RECEIVE_ROLE,
                        data: data,
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
            dispatch({ type: REQUEST_ROLE });
        },
    removeRoleUser:
        (roleId: string, userId: string): AppThunkAction<KnownAction> =>
        async (dispatch) => {
            RoleService.removeUserRole(roleId, userId)
                .then(() => {
                    dispatch({
                        type: ADMIN_ROLE_USER_REMOVE_SUCCESS,
                        data: {
                            roleId: roleId,
                            userId: userId,
                        },
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    startAddRoleUser:
        (roleId: string): AppThunkAction<KnownAction> =>
        async (dispatch) => {
            dispatch({
                type: ADMIN_ROLE_USER_ADD_START,
            });

            RoleService.getAvailableUsers(roleId)
                .then((data) =>
                    dispatch({
                        type: ADMIN_ROLE_USER_ADD_RECEIVE_USERS,
                        data: data,
                    }),
                )
                .catch((error) => {
                    console.error(error);
                });
        },
    cancelAddRoleUser: (): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({
            type: ADMIN_ROLE_USER_ADD_CANCEL,
        });
    },
    addRoleUserSubmit:
        (roleId: string, userId: string): AppThunkAction<KnownAction> =>
        async (dispatch) => {
            RoleService.addUserRole(roleId, userId)
                .then(() => {
                    dispatch({
                        type: ADMIN_ROLE_USER_ADD_SUCCESS,
                    });
                    RoleService.getRoleUsers(roleId)
                        .then((data) => {
                            dispatch({
                                type: ADMIN_ROLE_USERS_RECEIVE,
                                data: data,
                            });
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                })
                .catch((error) => {
                    console.error(error);
                });
        },
};
