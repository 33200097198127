export interface Errors {
    [key: string]: unknown;
}

export interface ErrorResponse {
    title: ErrorTitle;
    status: number;
    detail: string;
    type: string;
    traceId: string;
}

export enum ErrorTitle {
    BusinessRule = "Business rule",
}
