interface AssetCustomDataTabConfig {
    displayName: string;
    Component: React.FC<{ assetId: string }>;
}

type CustomComponentsConfig = {
    assetCustomDataTabs: AssetCustomDataTabConfig[];
};

/* Service providing management and access to custom components configuration.
   Use it to provide & get custom component to be placed within core components */
class CustomComponentsService {
    private _assetCustomDataTabs: AssetCustomDataTabConfig[];

    initialize({ assetCustomDataTabs }: CustomComponentsConfig): void {
        this._assetCustomDataTabs = assetCustomDataTabs;
    }

    public get assetCustomDataTabs() {
        return this._assetCustomDataTabs || [];
    }
}

const customComponentsService = new CustomComponentsService();
export default customComponentsService;
