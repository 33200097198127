import PermissionConstants from "../../../../../common/permissions/PermissionConstants";
import { hasUserOrgPermission } from "../../../../../store/features/user/user-api-slice";

type HookResult = {
    canChangeExamType: boolean;
};
export const useExamManagementChangeExamTypePermissions = (
    organisationId: string,
): HookResult => {
    const canChangeExamType = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgExamManagementChangeExamType,
    ).hasPermission;

    return {
        canChangeExamType,
    };
};
