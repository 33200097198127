import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ListItemState } from "../domain/types";

export class ListItemStateComplete implements ListItemState {
    button: JSX.Element;
    cardBodyClass: string;
    icon: JSX.Element;

    constructor(activityUrl: string) {
        this.button = (
            <Link to={activityUrl} className="btn btn-primary">
                View
            </Link>
        );

        this.icon = (
            <FaCheckCircle
                name="rowing"
                size="24"
                color="green"
                className="align-self-center"
            />
        );

        this.cardBodyClass = "alert-success";
    }
}
