export default class EmployeesConstants {
    static readonly IdField = "id";
    static readonly EmployeeField = "employee";
    static readonly EmployeeLabel = "Employee";
    static readonly EmployeeWidth = 0.15;
    static readonly RoleField = "role";
    static readonly RoleLabel = "Role";
    static readonly RoleWidth = 0.1;
    static readonly CompetenceField = "competence";
    static readonly CompetenceLabel = "Competence";
    static readonly CompetenceWidth = 0.1;
    static readonly TrainingField = "training";
    static readonly TrainingLabel = "Training";
    static readonly TrainingWidth = 0.1;
    static readonly ExperienceField = "experience";
    static readonly ExperienceLabel = "Experience";
    static readonly ExperienceWidth = 0.1;
    static readonly ContactField = "contact";
    static readonly ContactLabel = "Contact No.";
    static readonly ContactWidth = 0.1;
    static readonly MedicalField = "medical";
    static readonly MedicalLabel =
        "Known medical conditions that may affect their ability to carry out the task?";
    static readonly MedicalWidth = 0.1;
    static readonly ActionWidth = 0.025;
}
