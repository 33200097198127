import * as React from "react";
import { Button } from "react-bootstrap";
import { ProcessActivityPreview } from "../../../process-activity/domain/types";
import { useGetProcessByIdQuery } from "../../../../store/features/process/process-api-slice";
import { useParams } from "react-router";
import { ExaminationState } from "../../examinations/domain/ExaminationState";
import CefaConstants from "../../constants/CefaConstants";
import useIsClient from "../../../../common/hooks/useIsClient";
import ExamPhotosOrderModal from "./ExamPhotosOrderModal";
import { ExaminationActivityName as ExaminationActivityNames } from "../../examinations/Constants/ProcessActivityNames";

interface Props {
    activity: ProcessActivityPreview;
}

const supportedProcessStateList = [
    ExaminationState.Planned,
    ExaminationState.ExaminationInProgress,
    ExaminationState.ReturnedToExaminer,
];

const supportedProcessActivityList = [
    ExaminationState.Planned,
    ExaminationState.ExaminationInProgress,
    ExaminationState.ReturnedToExaminer,
];

const ExamPhotoOrderButton: React.FC<Props> = ({ activity }) => {
    const params = useParams<{
        processId: string;
        orgShortName: string;
        activityId: string;
    }>();
    const isCefa = useIsClient(CefaConstants.ClientName);

    const [orderPhotoModalActive, setOrderPhotoModalActive] =
        React.useState<boolean>(false);

    const modalOnClose = React.useCallback(() => {
        setOrderPhotoModalActive(false);
    }, []);

    const AssetModelOpen = React.useCallback(() => {
        setOrderPhotoModalActive(true);
    }, []);

    const { data: process } = useGetProcessByIdQuery(params.processId, {
        skip: !params.processId,
    });
    const supportedProcessState =
        supportedProcessStateList.indexOf(process.state as ExaminationState) >
        -1;

    const supportedActivity =
        ExaminationActivityNames.indexOf(activity.name as ExaminationState) >
        -1;

    const displayButton = isCefa && supportedProcessState && supportedActivity;

    if (displayButton) {
        return (
            <>
                <Button
                    onClick={AssetModelOpen}
                    className="mr-2"
                    variant="secondary"
                >
                    Order Photos
                </Button>
                {orderPhotoModalActive && (
                    <ExamPhotosOrderModal
                        processId={activity.processId}
                        onClose={modalOnClose}
                    />
                )}
            </>
        );
    } else {
        return null;
    }
};

export default ExamPhotoOrderButton;
