import * as React from "react";
import cx from "classnames";
import { Nav } from "react-bootstrap";
import { IconType } from "react-icons";
import { NavLink, Route } from "react-router-dom";
import NavSubMenuItem, { NavSubLink } from "./NavSubMenuItem";
import "./SideMenu.scss";
import SbimConstants from "../../../modules/sbim/constants/SbimConstants";
import useIsClient from "../../hooks/useIsClient";

interface NavLinkWithMenuProps {
    label: string;
    icon: IconType;
    to: string;
    eventKey: string;
    additionalClasses?: string;
    subLinks: NavSubLink[];
    subMenuRoute: string;
}

const NavLinkWithMenu = ({
    label,
    icon: Icon,
    to,
    additionalClasses,
    subMenuRoute,
    subLinks,
}: NavLinkWithMenuProps): JSX.Element => {
    const isSBIM = useIsClient(SbimConstants.ClientName);
    return (
        <>
            <Nav.Item className={additionalClasses}>
                <NavLink
                    to={to}
                    className={cx("d-flex align-items-center nav-link ", {
                        "padding button-override": isSBIM,
                    })}
                    exact
                >
                    <Icon size="16" className="menu-icon" />
                    <span className="navbar-font-override">{label}</span>
                </NavLink>
            </Nav.Item>
            <Route path={subMenuRoute}>
                {subLinks.length > 0 && <hr className="m-0" />}
                {subLinks.map((subItem) => (
                    <NavSubMenuItem key={subItem.label} {...subItem} />
                ))}
                {subLinks.length > 0 && <hr className="m-0" />}
            </Route>
        </>
    );
};

export default NavLinkWithMenu;
