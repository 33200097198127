import * as React from "react";
import { ApplicationState } from "../../../store";
import { useSelector } from "react-redux";
import AuditActionListItem from "../../../common/components/audit/AuditActionListItem";
import { AuditAction } from "../../../common/types/AuditAction";

const getAuditActions = (state: ApplicationState): AuditAction[] =>
    state.report?.domain.reportTemplate.auditActions ?? [];
const OrgReportAuditActionList: React.FC = () => {
    const auditActions = useSelector(getAuditActions);

    return (
        <div>
            <ul className="timeline">
                {auditActions.map((item: AuditAction, index: number) => (
                    <AuditActionListItem key={index.toString()} data={item} />
                ))}
            </ul>
        </div>
    );
};
export default OrgReportAuditActionList;
