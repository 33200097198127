import OrganisationsConstants from "../../../Constants/OrganisationsConstants";
import { OrganisationListItem } from "../../../modules/organisation/domain/organisation";
import { dcpApi, Tags } from "./../dcpApi";

export const organisationsApi = dcpApi.injectEndpoints({
    endpoints: (builder) => ({
        getOrganisations: builder.query<OrganisationListItem[], void>({
            query: () => `${OrganisationsConstants.ResourceName}`,
            providesTags: () => [{ type: Tags.Organisations }],
        }),
    }),
});

export const { useGetOrganisationsQuery } = organisationsApi;
