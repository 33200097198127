import {
    Grid,
    GridColumn,
    GridNoRecords,
    GridSelectionChangeEvent,
    GridToolbar,
    getSelectedState,
} from "@progress/kendo-react-grid";
import React from "react";
import {
    GridFieldDef,
    GridFieldType,
} from "../../../../../common/components/table/kendo/GridFieldsMap";
import GridLoadingPanel from "../../../../../common/components/table/kendo/GridLoadingPanel";
import { SortAndFilterOptions } from "../../../../../common/types/SortAndFilterOptions";
import {
    examManagementGridFieldsMap,
    gridFilterOperators,
} from "./ExamManagementInfiniteGridConfiguration";
import { useGetInfiniteExamManagementExamQuery } from "../queries/examManagementQueries";
import { ExamManagement } from "../../../examinations/domain/examManagement";
import { process, State } from "@progress/kendo-data-query";
import { SelectedState } from "../../../../../common/components/table/kendo/SelectedState";
import { getter } from "@progress/kendo-react-common";
import {
    MultiTextContainsFilterCell,
    MultiTextFilterCell,
} from "../../../../../common/components/table/kendo/filterCells/MultiTextFilterCell";
import { ProcessStateMultiSelectFilterCell } from "../../../examinations/components/ExaminationSchedulesGrid/ProcessStateMultiSelectFilterCell";
import ExamManagementGridActions from "./ExamManagementGridActions";
import "./examManagement.scss";

const grisColProps = (field: GridFieldDef, index: number) => ({
    key: index,
    field: field.field,
    title: field.label || field.field,
    sortable: field.sortable ?? true,
    filterable: field.filterable ?? true,
    width: 300,
});

interface Props {
    organisationId: string;
    onFilterSortChange?: (params: SortAndFilterOptions) => void;
}

const ExamManagementInfiniteGrid: React.FC<Props> = ({ organisationId }) => {
    const SELECTED_FIELD = "selected";

    const wrapperRef = React.createRef<HTMLElement>();
    const [dataState, setDataState] = React.useState<State>({
        skip: 0,
        take: 100,
    });

    const [selectedState, setSelectedState] = React.useState<SelectedState>({});
    const DATA_ITEM_KEY = "processId";

    const idGetter = getter(DATA_ITEM_KEY);

    const { data: examinations, isFetching } =
        useGetInfiniteExamManagementExamQuery(organisationId);

    const flatData = React.useMemo(
        () =>
            examinations?.map((dataItem: ExamManagement) =>
                Object.assign({ selected: false }, dataItem),
            ) ?? [],
        [examinations],
    );

    const result = process(
        flatData.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
        })),
        dataState,
    );

    const switchColumnType = React.useCallback(
        (field: GridFieldDef, index: number): JSX.Element => {
            switch (field.fieldType) {
                case GridFieldType.MultiText: {
                    return (
                        <GridColumn
                            {...grisColProps(field, index)}
                            filterCell={MultiTextFilterCell}
                        />
                    );
                }
                case GridFieldType.MultiTextContains: {
                    return (
                        <GridColumn
                            {...grisColProps(field, index)}
                            filterCell={MultiTextContainsFilterCell}
                        />
                    );
                }
                case GridFieldType.ProcessStateMultiSelect: {
                    return (
                        <GridColumn
                            {...grisColProps(field, index)}
                            filterCell={ProcessStateMultiSelectFilterCell}
                        />
                    );
                }
                default: {
                    return <GridColumn {...grisColProps(field, index)} />;
                }
            }
        },
        [],
    );

    const onSelectionChange = React.useCallback(
        (event: GridSelectionChangeEvent) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            setSelectedState(newSelectedState);
        },
        [selectedState],
    );

    const toolbar = (
        <GridToolbar>
            <ExamManagementGridActions
                organisationId={organisationId}
                selectedItems={flatData.filter(
                    (item) => selectedState[idGetter(item)],
                )}
            ></ExamManagementGridActions>
        </GridToolbar>
    );

    return (
        <div
            ref={wrapperRef as React.RefObject<HTMLDivElement>}
            className="exam-management-grid-h pt-4"
        >
            <Grid
                sortable={true}
                data={result}
                {...dataState}
                selectable={{
                    enabled: true,
                    mode: "multiple",
                    cell: false,
                }}
                filterable
                filterOperators={gridFilterOperators}
                className="exam-management-grid-h"
                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                onSelectionChange={onSelectionChange}
                onDataStateChange={(e) => {
                    setDataState(e.dataState);
                }}
                pageable={{
                    buttonCount: 4,
                    info: true,
                    pageSizes: [100],
                }}
            >
                {toolbar}
                <GridNoRecords>
                    <div style={{ width: "100%", textAlign: "left" }}>
                        {isFetching && "Loading..."}
                        {!isFetching && "There is no data available"}
                    </div>
                </GridNoRecords>
                {Object.values(examManagementGridFieldsMap).map(
                    switchColumnType,
                )}
            </Grid>

            {isFetching && <GridLoadingPanel gridRef={wrapperRef} />}
        </div>
    );
};

export default ExamManagementInfiniteGrid;
