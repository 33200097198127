import { IdAndName } from "../../../common/types/IdAndName";

export interface ListedFolder {
    path: IdAndName[];
    subFolders: BasicFileProps[];
    files: BasicFileProps[];
    tags: string[];
    isFavorite: boolean;
}

export interface BasicFileProps {
    id: string;
    name: string;
    versionNo: number;
    createdBy: string;
    createdOn: string;
    lastUpdatedBy: string;
    lastUpdatedAt: string;
    metadata?: { [key: string]: string };
}

export enum SearchResultType {
    File = 0,
    Folder = 1,
}

export interface FileSearchItem {
    id: string;
    name: string;
    createdBy: string;
    lastUpdatedBy: string;
    createdOn: string;
    lastUpdatedAt: string;
    dateModified: string;
    parentId: string;
    type: SearchResultType;
    location: string;
}

export function isNewNameDifferent(
    { subFolders, files }: ListedFolder,
    { id, name }: IdAndName,
): boolean {
    const { name: oldName } = subFolders.concat(files).find((x) => x.id === id);
    return name !== oldName;
}

export function getPathAsString({ path }: ListedFolder): string {
    return path.map((x) => x.name).join("/");
}

export interface FilePreview {
    name: string;
    index: number;
}

export interface SubFolder {
    subFolders: Record<string, SubFolder>;
    files: FilePreview[];
}

export interface FolderUploadData {
    subFolders: Record<string, SubFolder>;
}

export interface ExistingFile {
    id: string;
    index: number;
    name: string;
    folderId: string;
    location: string;
}

export interface FolderUploadInfo {
    existingFiles: ExistingFile[];
}
