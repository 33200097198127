import * as React from "react";
import { ProcessActivity, ProcessActivityStatusType } from "../domain/types";
import ActivityCompleteAlert from "./ActivityCompleteAlert";
import ActivityNotApplicableAlert from "./ActivityNotApplicableAlert";
import ProcessActivityStatusActionBar, {
    ActionBarActionProps,
    ActionBarNotApplicableActionProps,
} from "./ProcessActivityStatusActionBar";

export interface ActivityStatusBarActionProps {
    complete?: ActionBarActionProps;
    notApplicable: ActionBarNotApplicableActionProps;
    open: ActionBarActionProps;
}

interface Props extends ActivityStatusBarActionProps {
    activity: ProcessActivity;
}

const ProcessActivityStatusBar: React.FC<Props> = ({
    activity,
    open,
    complete,
    notApplicable,
}) => {
    switch (activity.statusType) {
        case ProcessActivityStatusType.NotApplicable:
            return (
                <div className="pt-2">
                    <ActivityNotApplicableAlert
                        activity={activity}
                        open={open}
                    />
                </div>
            );
        case ProcessActivityStatusType.Complete:
            return (
                <div className="pt-2">
                    <ActivityCompleteAlert activity={activity} open={open} />
                </div>
            );
        default:
            return (
                <div className="mb-1">
                    <ProcessActivityStatusActionBar
                        activity={activity}
                        notApplicable={notApplicable}
                        complete={complete}
                    />
                </div>
            );
    }
};

export default ProcessActivityStatusBar;
