import { MultiSelect } from "@progress/kendo-react-dropdowns";
import * as React from "react";
import { Button } from "react-bootstrap";
import { GridColumnDef } from "../../../store/features/assets-grid/assetsGridSlice";
import { usePersistedAssetsGridState } from "../../asset/hooks/usePersistedAssetsGridState";
import OrgAssetLinkList from "./OrgAssetLinkList";

export const ACTIVITY_ASSET_SELECTED_FIELD = "assets";
interface AddAssetsGridProps {
    organisationId: string;
    activityId: string;
    multiple: boolean;
}

const defaultColumns: GridColumnDef[] = [
    {
        field: "name",
        title: "Name",
    },
    {
        field: "description",
        title: "Description",
    },
];

export const AddAssetsGrid: React.FC<AddAssetsGridProps> = ({
    organisationId,
    activityId,
    multiple,
}) => {
    const { templatesSelectProps, columnsSelectProps, clearFilters } =
        usePersistedAssetsGridState(organisationId, defaultColumns);

    return (
        <>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                }}
            >
                <div>Filters:</div>
                <MultiSelect {...templatesSelectProps} />
                <MultiSelect {...columnsSelectProps} />
                <Button
                    onClick={clearFilters}
                    variant="outline-secondary"
                    style={{ whiteSpace: "nowrap" }}
                >
                    Clear Filters
                </Button>
            </div>
            <OrgAssetLinkList
                organisationId={organisationId}
                activityId={activityId}
                selectMultiple={multiple}
            />
        </>
    );
};
