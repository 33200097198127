import * as React from "react";
import { Navbar, Nav } from "react-bootstrap";
import "./NavMenu.scss";
import { Link } from "react-router-dom";
import LoginMenu from "./LoginMenu";
import { useGetConfigQuery } from "../../../store/config/configApi";
import SbimConstants from "../../../modules/sbim/constants/SbimConstants";
import useIsClient from "../../hooks/useIsClient";

const NavMenu: React.FC = () => {
    const { data: config } = useGetConfigQuery();
    const isSBIM = useIsClient(SbimConstants.ClientName);
    let title = "DCP";
    if (config.Branding?.Title) {
        title = config.Branding.Title;
    }

    return (
        <header>
            <Navbar
                id="nav-header-menu"
                className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3"
                bg={isSBIM ? null : "light"}
                expand="lg"
            >
                <Navbar.Brand
                    as={Link}
                    className="col-sm-3 col-md-2 mr-0"
                    to="/"
                >
                    <span className="text-items">{title}</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <LoginMenu />
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </header>
    );
};

export default NavMenu;
