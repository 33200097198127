enum ProcessActivityPageTitles {
    Main = "Process Activity",
}

const formatActivityPageTitle = (
    processName: string,
    activityName: string,
    loading: boolean,
): string => {
    if (loading) return `Loading...`;

    return `${processName} : ${activityName}`;
};

export { ProcessActivityPageTitles, formatActivityPageTitle };
