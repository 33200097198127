import { useFormikContext } from "formik";
import * as React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import FormTemplatesConstants from "../../../../Constants/FormTemplatesConstants";
import {
    ShowCondition,
    TemplateContentOption,
} from "../../../../modules/template/domain/types";
import { SelectOptionItem } from "../../../types/reactSelect/SelectOptionItem";
import FormControlBase from "../FormControlBase";

const styles = {
    textbox: {
        marginTop: "20px",
    },
};

export interface FormControlTextTemplateConfig {
    field: string;
    label: string;
    style: unknown;
    prompt: string;
    uri: string;
    isReadOnly: boolean;
    options: TemplateContentOption[];
    showConditions: ShowCondition[];
    rows: number;
}

interface Props {
    config: FormControlTextTemplateConfig;
}

const FormControlTextTemplateSelect: React.FC<Props> = (props) => {
    const { config } = props;
    const formik = useFormikContext();
    const templateCheckCondition = (showCondition: ShowCondition): boolean => {
        // Replace with switch when more operators are supported
        if (
            showCondition.operator ===
            FormTemplatesConstants.Conditions.Operator.Equals
        ) {
            return formik.values[showCondition.field] === showCondition.value;
        } else {
            throw Error(
                `Select show condition operator '${showCondition.operator}' not supported`,
            );
        }
    };

    const templateOptions: SelectOptionItem[] = config.options
        .filter((x) => {
            if (!x.showConditions) return true;

            const orConditions = x.showConditions.filter(
                (y) => y.logic === FormTemplatesConstants.Conditions.Logic.Or,
            );

            const andConditions = x.showConditions.filter(
                (z) => z.logic === FormTemplatesConstants.Conditions.Logic.And,
            );
            // If no conditions
            return (
                x.showConditions.length === 0 ||
                // If one or condition is met and no and
                (orConditions.some(templateCheckCondition) &&
                    andConditions.every(templateCheckCondition))
            );
        })
        .map((option) => {
            return {
                label: option.display,
                value: option.value,
            };
        });

    function handleSelectedOption(option: SelectOptionItem) {
        if (option != null) {
            formik.setFieldValue(config.field, option.value);
        }
    }

    return (
        <FormControlBase
            contentConfig={{
                field: config.field,
                showConditions: config.showConditions,
            }}
        >
            <Form.Label htmlFor={config.field}>{config.label}</Form.Label>
            <Select
                onChange={handleSelectedOption}
                options={templateOptions}
                classNamePrefix="react-select"
                isDisabled={config.isReadOnly}
            ></Select>
            <Form.Control
                as="textarea"
                name={config.field}
                onChange={formik.handleChange}
                className={formik.errors[config.field] ? "is-invalid" : ""}
                readOnly={config.isReadOnly}
                onBlur={formik.handleBlur}
                style={styles.textbox}
                value={formik.values[config.field] ?? ""}
                rows={config.rows}
            />

            <Form.Text className="text-muted">{config.prompt}</Form.Text>
        </FormControlBase>
    );
};

export default FormControlTextTemplateSelect;
