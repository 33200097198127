import { Alert, Col, Row } from "react-bootstrap";
import { DateTimeSelect } from "../../../../common/components/form";
import React from "react";
import TextInputPhone from "../../../../common/components/form/TextInputPhone";
import UserSelect from "../../../../common/components/form/UserSelect";
import { useOrganisationId } from "../../../organisation/hooks/useOrganisationId";
import {
    TemplateContentFilter,
    TemplateContentFilterOperator,
    TemplateContentFilterProperty,
} from "../../../common/filters/domain/types";

const usersExaminerFilter: TemplateContentFilter = {
    property: TemplateContentFilterProperty.UserGroup,
    operator: TemplateContentFilterOperator.Any,
    values: ["Examiner"],
};

const ExaminerControl: React.FC = () => {
    const { organisationId } = useOrganisationId();
    return (
        <>
            <h2>Examiner Details</h2>
            <Alert variant="primary">
                <Alert.Heading>
                    NOW USE CEFA Rapid Response SharePoint Site TO IDENTIFY AND
                    CONTACT THE NEAREST EXAMINER
                </Alert.Heading>
                <Alert.Link
                    target="_blank"
                    href="https://ameygroup.sharepoint.com/sites/RapidResponsetest/"
                >
                    https://ameygroup.sharepoint.com/sites/RapidResponsetest/
                </Alert.Link>
            </Alert>
            <Row>
                <Col xs={2}>
                    <UserSelect
                        label="Select Examiner"
                        name="examinerId"
                        organisationId={organisationId}
                        filterOptions={usersExaminerFilter}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={"auto"}>
                    <TextInputPhone
                        label="Examiner Mob No"
                        name="examinerMobNum"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={"auto"}>
                    <DateTimeSelect
                        label="Time Examiner Notified"
                        name="examinerNotifiedAt"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={"auto"}>
                    <DateTimeSelect label="Examiner ETA" name="examinerEtaAt" />
                </Col>
            </Row>
        </>
    );
};

export default ExaminerControl;
