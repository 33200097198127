import * as React from "react";
import { Row, Col, Breadcrumb } from "react-bootstrap";
import { Redirect, RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";
import OrgReportPageTitles from "./pageTitles";

const OrgReportsListPage: React.FC<
    RouteComponentProps<{ orgShortName: string }>
> = (props) => {
    const orgShortName = props.match.params.orgShortName;

    useOrgTitleWithShortName(OrgReportPageTitles.List);

    return (
        <>
            <Row className="top10">
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item active>Reports</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>Reports</h1>
                </Col>
            </Row>
            <Row className="top10">
                <Col>
                    <Link
                        to={`/${orgShortName}${OrganisationConstants.ReportCreate}`}
                        className="btn btn-primary"
                    >
                        Create new report
                    </Link>
                </Col>
            </Row>
            <Row className="top10">
                <Col>
                    {/* Temporary severn solution for not showing report main page */}
                    <Redirect
                        to={`/${orgShortName}${OrganisationConstants.siteWorks}`}
                    />
                    {/* <OrgReportsTable></OrgReportsTable> */}
                </Col>
            </Row>
        </>
    );
};

export default OrgReportsListPage;
