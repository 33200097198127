import bsCustomFileInput from "bs-custom-file-input";
import { FieldArray, Formik, FormikProps, useFormikContext } from "formik";
import * as React from "react";
import { useState } from "react";
import { Button, Form, FormGroup, FormLabel } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import {
    ShowCondition,
    TemplateContent,
    TemplateContentFileRecord,
    TemplateContentStyle,
    YupValidationConfig,
} from "../../../../modules/template/domain/types";
import FormControlBase from "../FormControlBase";
import { generateYupForSubForm } from "../yupValidation/YupValidation";
import FormControlFileTable from "./FormControlFileTable";

interface FormControlMediaConfig {
    field: string;
    label: string;
    style: TemplateContentStyle;
    prompt: string;
    uri: string;
    isReadOnly: boolean;
    files: TemplateContentFileRecord[];
    validationsFile: YupValidationConfig;
    validationsComment: YupValidationConfig;
    mediaOnly: boolean;
    multiple: boolean;
    showConditions: ShowCondition[];
}

interface Props {
    config: FormControlMediaConfig;
}

interface FileInfo {
    mediaFiles: File[];
    mediaComment: string;
}

function generateFileSubFieldContents(
    mediaFieldConfig: FormControlMediaConfig,
) {
    return {
        mediaFiles: {
            field: "mediaFiles",
            label: "File",
            validationType: mediaFieldConfig.validationsFile.validationType,
            validations: [
                ...mediaFieldConfig.validationsFile.validations,
                // Add in any permanent validation rules here
                ...[
                    {
                        type: "fileRequired",
                        params: ["Please select a file"],
                    },
                ],
            ],
        } as TemplateContent,
        mediaComment: {
            field: "mediaComment",
            label: "Comment",
            validationType: mediaFieldConfig.validationsComment.validationType,
            validations: mediaFieldConfig.validationsComment.validations,
        } as TemplateContent,
    };
}

const FormControlFile: React.FC<Props> = (props) => {
    const { config } = props;
    const formik = useFormikContext();
    const fieldsContents = React.useMemo(
        () => generateFileSubFieldContents(config),
        [config],
    );

    const formValidationSchema = React.useMemo(
        () =>
            generateYupForSubForm([
                fieldsContents.mediaFiles,
                fieldsContents.mediaComment,
            ]),
        [fieldsContents],
    );

    // This is a hack to reset the file input component after submission
    const [fileInputKey, setFileInputKey] = React.useState(Date.now());
    const [isAdding, setIsAdding] = useState(false);

    React.useEffect(() => {
        bsCustomFileInput.init();
    }, [isAdding]);

    const onClickAdd = () => {
        setIsAdding(true);
    };

    const value = (formik.values[config.field] ||
        []) as TemplateContentFileRecord[];

    const showAddButton =
        !isAdding &&
        !config.isReadOnly &&
        (config.multiple || value.length < 1);

    return (
        <FormControlBase
            contentConfig={{
                field: config.field,
                showConditions: config.showConditions,
            }}
        >
            <FieldArray name={config.field}>
                {(arrayHelpers) => (
                    <>
                        <FormLabel>
                            <strong>{config.label}</strong>
                        </FormLabel>
                        {config.prompt && (
                            <Form.Text className="text-muted mb-2">
                                {config.prompt}
                            </Form.Text>
                        )}
                        {showAddButton && (
                            <div>
                                <Button size="sm" onClick={onClickAdd}>
                                    <FaPlus />
                                </Button>
                            </div>
                        )}
                        {isAdding && (
                            <Formik
                                validationSchema={formValidationSchema}
                                enableReinitialize={true}
                                initialValues={{
                                    mediaFiles: [] as File[],
                                    mediaComment: "",
                                }}
                                onSubmit={(
                                    values: FileInfo,
                                    { resetForm },
                                ): void => {
                                    values.mediaFiles.forEach((a: File) => {
                                        const fileInfo: TemplateContentFileRecord =
                                            {
                                                uuid: uuidv4(),
                                                id: "",
                                                comment: values.mediaComment,
                                                fileName: a.name,
                                                localFile: a,
                                                size: a.size,
                                                storageName: "",
                                                createdOn: "",
                                            };
                                        arrayHelpers.push(fileInfo);
                                    });
                                    setFileInputKey(Date.now());
                                    resetForm();
                                    setIsAdding(false);
                                }}
                            >
                                {(formikProps: FormikProps<FileInfo>) => {
                                    return (
                                        <Form noValidate>
                                            <FormGroup>
                                                <Form.Label
                                                    htmlFor={"mediaFiles"}
                                                >
                                                    {
                                                        fieldsContents
                                                            .mediaFiles.label
                                                    }
                                                </Form.Label>
                                                <Form.File
                                                    id="formcheck-api-custom"
                                                    lang="en"
                                                    custom
                                                    className="mb-2 "
                                                    key={fileInputKey}
                                                >
                                                    <Form.File.Label
                                                        data-browse="Button text"
                                                        className={
                                                            formikProps.errors
                                                                .mediaFiles
                                                                ? "is-invalid form-control"
                                                                : ""
                                                        }
                                                    >
                                                        Select media file
                                                    </Form.File.Label>
                                                    <Form.File.Input
                                                        name={"mediaFiles"}
                                                        onChange={(
                                                            event: React.ChangeEvent<HTMLInputElement>,
                                                        ) => {
                                                            formikProps.setFieldValue(
                                                                "mediaFiles",
                                                                Array.from(
                                                                    event
                                                                        .currentTarget
                                                                        .files,
                                                                ),
                                                            );
                                                        }}
                                                    />
                                                    {formikProps.errors
                                                        .mediaFiles && (
                                                        <div className="invalid-feedback display">
                                                            {
                                                                formikProps
                                                                    .errors
                                                                    .mediaFiles
                                                            }
                                                        </div>
                                                    )}
                                                </Form.File>
                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Label
                                                    htmlFor={"mediaComment"}
                                                >
                                                    {
                                                        fieldsContents
                                                            .mediaComment.label
                                                    }
                                                </Form.Label>
                                                <Form.Control
                                                    name={"mediaComment"}
                                                    onChange={(
                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                    ) => {
                                                        formikProps.setFieldValue(
                                                            "mediaComment",
                                                            event.target.value,
                                                        );
                                                    }}
                                                    value={
                                                        formikProps.values
                                                            .mediaComment
                                                    }
                                                    className={
                                                        formikProps.errors[
                                                            "mediaComment"
                                                        ]
                                                            ? "is-invalid"
                                                            : ""
                                                    }
                                                    // This section prevents the page from refreshing when pressing Enter.
                                                    onKeyDown={(e) => {
                                                        if (e.key === "Enter") {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                />
                                                {formikProps.errors
                                                    .mediaComment && (
                                                    <div className="invalid-feedback display">
                                                        {
                                                            formikProps.errors
                                                                .mediaComment
                                                        }
                                                    </div>
                                                )}
                                            </FormGroup>
                                            <div className="mt-2">
                                                <Button
                                                    variant="primary"
                                                    onClick={() => {
                                                        formikProps.submitForm();
                                                    }}
                                                >
                                                    Attach
                                                </Button>
                                                <Button
                                                    variant="secondary"
                                                    className="ml-1"
                                                    onClick={() => {
                                                        formikProps.resetForm();
                                                        setIsAdding(false);
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        )}
                        <div className="mt-3">
                            <FormControlFileTable
                                arrayHelpers={arrayHelpers}
                                config={config}
                                isValid={!formik.errors[config.field]}
                                readonly={config.isReadOnly}
                            />
                        </div>
                    </>
                )}
            </FieldArray>
        </FormControlBase>
    );
};

export default FormControlFile;
