import consumerApiService from "../../../../customization/api/consumerApiService";
import { getData, postFormData } from "../../../../helpers/ApiHelper";
import { CommercialMassUpdate } from "../domain/commercialMassUpdate.types";

enum UrlPaths {
    BaseResource = "organisation",
    CommercialMassUpdater = "CommercialMassUpdater",
    DownloadTemplate = "DownloadTemplate",
}

interface GetMassUpdatesArgs {
    organisationId: string;
}

const getBaseUrl = () =>
    `${consumerApiService.baseApiUrl}/${UrlPaths.BaseResource}`;

export async function getCommercialMassUpdates({
    organisationId,
}: GetMassUpdatesArgs): Promise<CommercialMassUpdate[]> {
    const url = `${getBaseUrl()}/${organisationId}/${
        UrlPaths.CommercialMassUpdater
    }`;
    const response = await getData<CommercialMassUpdate[]>(url);

    if (!response) return;

    return response;
}

export interface UploadMassUpdatesArgs {
    organisationId: string;
    file: File;
}

export async function uploadCommercialMassUpdate({
    organisationId,
    file,
}: UploadMassUpdatesArgs): Promise<void> {
    const url = `${getBaseUrl()}/${organisationId}/${
        UrlPaths.CommercialMassUpdater
    }`;
    const body = new FormData();
    body.append("file", file);

    await postFormData<void>(url, body);
}
