import * as React from "react";
import { useParams } from "react-router";
import { FilesRouteParams } from "../viewModel/FilesRouteParams";
import FilePageTitles from "../pageTitles";
import FolderContents from "../containers/FolderContents";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";
import { useGetOrgByShortNameQuery } from "../../../store/features/organisation/organisation-api-slice";

export const FolderPage: React.FC = () => {
    const { folderId, orgShortName } = useParams<FilesRouteParams>();
    const { data: organisation } = useGetOrgByShortNameQuery(orgShortName);

    useOrgTitleWithShortName(FilePageTitles.Main);

    return <FolderContents folderId={folderId} organisation={organisation} />;
};
