import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Permission } from "../../../organisation/domain/types";
import { useRemovePermissionFromGroupMutation } from "../../../../store/features/organisation/organisation-api-slice";

interface Props {
    orgId: string;
    userGroupId: string;
    canRemovePermissions: boolean;
    permission: Permission;
}

const UserGroupPermissionsTableActions: React.FC<Props> = ({
    orgId,
    userGroupId,
    canRemovePermissions,
    permission,
}) => {
    const [removePermissionFromGroup] = useRemovePermissionFromGroupMutation();
    const [isRemoving, setIsRemoving] = useState(false);

    const handleRemovePermissionFromGroup = async (id: string) => {
        await removePermissionFromGroup({
            orgId: orgId,
            groupId: userGroupId,
            permissionId: id,
        });
    };

    const onRemove = async (id: string): Promise<void> => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            setIsRemoving(true);
            await handleRemovePermissionFromGroup(id);
        }
    };

    return (
        <>
            {canRemovePermissions && (
                <Button
                    variant="danger"
                    onClick={async (): Promise<void> => onRemove(permission.id)}
                    disabled={isRemoving}
                >
                    Remove
                </Button>
            )}
        </>
    );
};

export default UserGroupPermissionsTableActions;
