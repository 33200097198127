import React from "react";
import { Col, Row } from "react-bootstrap";
import { useOrgTitleById } from "../../../common/hooks/useTitle";
import UserManagmentPageTitles from "./pageTitles";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";
import CreateUserGroupForm from "../components/CreateUserGroupForm";
import { useParams } from "react-router";
import { WithOrgShortName } from "../../../common/types/WithOrg";

export const UserGroupCreatePage: React.FC = () => {
    const { orgShortName } = useParams<WithOrgShortName>();
    const { organisationId } = useOrganisationId();

    useOrgTitleById(organisationId, UserManagmentPageTitles.GroupCreate);

    return (
        <>
            <h1 className="top30">Create user group</h1>
            <Row>
                <Col>
                    <CreateUserGroupForm
                        orgId={organisationId}
                        orgShortName={orgShortName}
                    />
                </Col>
            </Row>
        </>
    );
};
