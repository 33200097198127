import { TemplateContentSiteRiskRecord } from "../../domain/types";

export interface FormControlSiteRiskGridDataItem
    extends TemplateContentSiteRiskRecord {
    originalIndex: number;
    inEdit: boolean;
}

export const mapToSiteRiskGridItem = (
    riskRecord: TemplateContentSiteRiskRecord,
    index: number,
    inEditId: string,
): FormControlSiteRiskGridDataItem =>
    ({
        ...riskRecord,
        originalIndex: index,
        inEdit: riskRecord.id === inEditId,
    } as FormControlSiteRiskGridDataItem);
