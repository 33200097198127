import { ChipListDataChangeEvent } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import React from "react";
import MutationResultStatus from "../../../common/components/MutationResultStatus";
import QueryResultStatus from "../../../common/components/QueryResultStatus";
import Tags from "../../../common/components/Tags";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import {
    useAddFileTagMutation,
    usePatchFileTagsMutation,
} from "../../../store/features/file/fileApiSlice";
import { useGetTagHintsQuery } from "../../../store/features/organisation/organisation-api-slice";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import { FileDetails } from "../domain/file";
import { TagWithIndex, wrapTags } from "../utils/fileAndFolderTags";

interface Props {
    file: FileDetails;
    folderId: string;
    organisationId: string;
}

const FileTags: React.FC<Props> = ({ file, folderId, organisationId }) => {
    const [addTag, addTagResult] = useAddFileTagMutation();
    const [deleteTag, deleteTagResult] = usePatchFileTagsMutation();
    const { data: tagHints, ...hintsResult } =
        useGetTagHintsQuery(organisationId);

    const canAddTag = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgFilesFileTags.create,
    ).hasPermission;
    const canDeleteTag = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgFilesFileTags.delete,
    ).hasPermission;

    const handleAddTag = (event: ComboBoxChangeEvent) => {
        const value = event.value;
        if (!value || !value.text || file.tags.includes(value.text)) return;

        addTag({
            fileId: file.id,
            folderId,
            organisationId,
            tag: value.text,
        });
    };

    const handleRemoveTag = (event: ChipListDataChangeEvent) => {
        if (!event.value) return;
        const tags = event.value.map((tag: TagWithIndex) => tag.text);
        const tagToRemove = file.tags.filter((tag) => !tags.includes(tag))[0];

        deleteTag({
            fileId: file.id,
            folderId,
            organisationId,
            tag: tagToRemove,
        });
    };

    return (
        <div className="pt-4">
            <QueryResultStatus queryResult={hintsResult} />
            <MutationResultStatus mutationResult={addTagResult} showLoading />
            <MutationResultStatus
                mutationResult={deleteTagResult}
                showLoading
            />
            <Tags
                canCreateTags={canAddTag}
                canDeleteTags={canDeleteTag}
                addTag={handleAddTag}
                removeTag={handleRemoveTag}
                elementTags={wrapTags(file.tags)}
                availableTags={wrapTags(tagHints)}
            />
        </div>
    );
};

export default FileTags;
