import {
    TranslateQueryKeyFunc,
    TranslateTagFunc,
} from "../../../../query/configureQuery";
import { Tags } from "../../../../store/features/dcpApi";
import { activitiesDetailsTags } from "../../../process-activity/store/activityTag";
import { examinationsKey } from "./examinationQueryKeys";

const tagsToQueryKeys: TranslateTagFunc = (tag) => {
    switch (tag.type) {
        case Tags.ProcessActivity:
        case Tags.ProcessAssignedList:
            return examinationsKey;
        default:
            return null;
    }
};

const queryKeysToTags: TranslateQueryKeyFunc = (queryKey, ids) => {
    switch (queryKey) {
        case examinationsKey:
            return [
                ...(ids ? [...activitiesDetailsTags(ids)] : []),
                { type: Tags.ProcessAssignedList },
            ];
        default:
            return null;
    }
};

export const examinationQueryKeysTranslator = {
    tagsToQueryKeys,
    queryKeysToTags,
};
