import { GridColumnDef } from "../../../../store/features/assets-grid/assetsGridSlice";

export const reactiveExamAssetGridDefaultColumns: GridColumnDef[] = [
    {
        field: "dynAssetPropcarrsGuid",
        title: "CARRS GUID",
    },
    {
        field: "dynAssetPropelr",
        title: "ELR",
    },
    {
        field: "dynAssetPropmiles",
        title: "Miles",
    },
    {
        field: "dynAssetPropchains",
        title: "Chains",
    },
    {
        field: "dynAssetPropyards",
        title: "Yards",
    },
    {
        field: "dynAssetPropstructureRefNo",
        title: "Structure Ref No",
    },
    {
        field: "dynAssetPropassetType",
        title: "Asset Type",
    },
    {
        field: "dynAssetPropstructureName",
        title: "Structure Name",
    },
    {
        field: "dynAssetPropsadId",
        title: "SADID",
    },
];
