export const PlanningInfoContentFieldNames = {
    CarrsGuid: "carrsGUID",
    SupplementaryInfo: "supplementaryInfo",
    NrRequestedDate: "nrRequestedDate",
    CarrsExamId: "carrsExamId",
    NrExamFrequency: "nrExamFrequency",
    NrExamType: "nrExamType",
    NrJobId: "nrJobId",
    NrCrNumber: "nrCrNumber",
    NrCrRemit: "nrCrRemit",
    ContractYear: "contractYear",
    LotNumber: "lotNumber",
    PlannedStartDate: "plannedStartDate",
    PlannedEndDate: "plannedEndDate",
    PlannedExaminer: "plannedExaminer",
    Elr: "elr",
    Miles: "miles",
    Yards: "yards",
    Chains: "chains",
    AssetType: "assetType",
    MissingCarrsExamFile: "missingCarrsExamFile",
    ProcessId: "processId",
};

export const AssetInfoContentFieldNames = {
    Elr: "elr",
    Miles: "miles",
    Yards: "yards",
    Chains: "chains",
    StructureName: "structureName",
    StructureReferenceNumber: "structureRefNo",
    AssetType: "assetType",
};
