import { useEffect } from "react";

// Updates the height of a <textarea> when the value changes.
const useAutosizeTextArea = (
    textAreaRef: HTMLTextAreaElement | null,
    value: string,
): void => {
    useEffect(() => {
        if (textAreaRef) {
            // Store original height of the text area. In case we want to
            // restore it back to this value.
            const previousHeight = textAreaRef.style.height;

            // We need to reset the height momentarily to get the correct scrollHeight for the textarea
            textAreaRef.style.height = "0px";

            const scrollHeight = textAreaRef.scrollHeight;

            if (scrollHeight > 0) {
                // We then set the height directly, outside of the render loop
                // Trying to set this with state or a ref will product an incorrect value.
                textAreaRef.style.height = scrollHeight + "px";
            } else {
                // I don't think we'd ever want to shrink a text area this
                // small. Reset it back to it's original value.
                textAreaRef.style.height = previousHeight;
            }
        }
    }, [textAreaRef, value]);
};

export default useAutosizeTextArea;
