import {
    AppInsightsContext,
    ReactPlugin,
} from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import * as React from "react";
import { useGetConfigQuery } from "./store/config/configApi";
import { history } from "./store/configureStore";

const AppInsights: React.FC = ({ children }) => {
    const { data: config } = useGetConfigQuery();
    const instrumentationKey = config.applicationInsightsKey;

    if (!instrumentationKey) {
        return <>{children}</>;
    }

    const browserHistory = history;
    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: instrumentationKey,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory },
            },
        },
    });

    appInsights.loadAppInsights();

    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            {children}
        </AppInsightsContext.Provider>
    );
};

export default AppInsights;
