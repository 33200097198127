import consumerApiService from "../../../../customization/api/consumerApiService";
import { DataContinuationResult } from "../../../../common/types/DataResult";
import { InfiniteQueryOptions } from "../../../../common/types/SortAndFilterOptions";
import { postData } from "../../../../helpers/ApiHelper";
import { ExaminationSearchDto } from "../domain/examinationDto";

enum UrlPaths {
    BaseResource = "organisation",
    Examinations = "examinationssearch",
    Paginated = "paginated",
}

export type ExamSearchOptions = InfiniteQueryOptions;

interface GetProcessesArgs {
    organisationId: string;
    query: ExamSearchOptions;
}

const getBaseUrl = () =>
    `${consumerApiService.baseApiUrl}/${UrlPaths.BaseResource}`;

export async function getInfiniteExams({
    organisationId,
    query,
}: GetProcessesArgs): Promise<DataContinuationResult<ExaminationSearchDto>> {
    const url = `${getBaseUrl()}/${organisationId}/${UrlPaths.Examinations}/${
        UrlPaths.Paginated
    }`;
    const result = postData<DataContinuationResult<ExaminationSearchDto>>(
        url,
        query,
    );

    const response = await result;
    if (!response.data) return;
    return {
        ...response,
        data: response.data,
    };
}
