import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import useConsumerApiSettings from "../../../../customization/api/useConsumerApiSettings";
import { postData } from "../../../../helpers/ApiHelper";
import { RequestedFileDto } from "../../../user-requested-files/queries/types";
import userRequestedFileQueryKeys from "../../../user-requested-files/queries/userRequestedFileQueryKeys";

export const useGetPerformanceReportMutation = (
    organisationId: string,
    selectedYears: string[],
): UseMutationResult<RequestedFileDto, unknown, unknown> => {
    const queryClient = useQueryClient();

    const { baseApiUrl } = useConsumerApiSettings();

    const mutationFn = () => {
        const url = `${baseApiUrl}/organisation/${organisationId}/performanceReport`;
        return postData<RequestedFileDto>(url, { years: selectedYears });
    };

    return useMutation(mutationFn, {
        onSuccess(result, _args) {
            return queryClient.invalidateQueries(
                userRequestedFileQueryKeys.userRequestedFileKey(result.id),
            );
        },
    });
};
