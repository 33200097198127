import * as React from "react";
import { Breadcrumb, Col, Row, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import OrgReportManageForm from "../containers/OrgReportManageForm";
import OrgReportAuditActionList from "../containers/OrgReportAuditActionList";
import { Link } from "react-router-dom";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import OrgReportItemDetails from "../containers/OrgReportItemDetails";
import { ApplicationState } from "../../../store";
import * as ReportStore from "../../../store/report/store";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";
import OrgReportPageTitles from "./pageTitles";
import { Organisation } from "../../organisation/domain/types";

interface Props {
    organisation: Organisation;
}
const OrgReportManagePage: React.FC<Props> = ({ organisation }) => {
    const dispatch = useDispatch();
    const params = useParams<{ id: string; orgShortName: string }>();

    const reportName = useSelector(
        (state: ApplicationState) =>
            state.report?.domain.reportTemplate.name ??
            ReportStore.unloadedState.domain.reportTemplate.name,
    );

    React.useEffect(() => {
        if (params.id) {
            dispatch(ReportStore.actionCreators.initEdit(params.id));
        }
    }, [dispatch, params.id]);

    useOrgTitleWithShortName(OrgReportPageTitles.Manage);

    return (
        <>
            <Row className="top10">
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={"div"}>
                            <Link
                                to={`/${params.orgShortName}${OrganisationConstants.Report}`}
                            >
                                Reports
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>{reportName}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>Report</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Tabs defaultActiveKey="details" id="tab">
                        <Tab
                            className="top10"
                            eventKey="details"
                            title="Details"
                        >
                            <OrgReportManageForm />
                        </Tab>
                        <Tab eventKey="reports" title="Files">
                            <OrgReportItemDetails organisation={organisation} />
                        </Tab>
                        <Tab eventKey="history" title="History">
                            <OrgReportAuditActionList />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </>
    );
};

export default OrgReportManagePage;
