export enum ProcessActivityNames {
    Examination = "examination",
    EngineerReview = "engineerReview",
    ExaminationPlanningInfo = "planningInfo",
    ExaminationAssetInfo = "assetInfo",
    EngineerApproval = "engineerApproval",
    UnexaminedParts = "unexaminedParts",
    UnexaminedPart = "unexaminedPart",
    NewDefects = "newDefects",
    TrackingDefect = "trackingDefect",
    TrackingDefects = "trackingDefects",
    Recommendation = "recommendation",
    Recommendations = "recommendations",
    EngineersReviewResult = "engineersReviewResult",
    CoverSheet = "coverSheet",
    PreviousReportedDefects = "previousReportedDefects",
    PreviousRecommendations = "previousRecommendations",
    SpecialRequirements = "specialRequirements",
    LastReportStatus = "lastReportStatus",
    ExaminersComments = "examinersComments",
    Photos = "photos",
    UnderLoad = "underload",
    StructureViewed = "structureViewed",
    StructureExamined = "structureExamined",
    Elements = "elements",
    ElementsEnhanced = "elementsEnhanced",
    MonitoringDevices = "monitoringDevices",
    Environment = "environment",
    Timber = "timber",
    Attachments = "attachments",
    DimensionSheet = "dimensionSheet",
    RecordLocation = "recordLocation",
    DefectPrevious = "defectPrevious",
    PreviousRecommendation = "previousRecomendation",
    ElementEnhancedMainGirder = "elementsEnhancedMainGirder",
    ElementEnhancedDeckFloor = "elementsEnhancedDeckFloor",
    ElementEnhancedWingRetainingWalls = "elementsEnhancedWingRetainingWalls",
    ElementEnhancedDefectParapetsPilasters = "elementsEnhancedParapetsPilasters",
    ElementEnhancedDrainage = "elementsEnhancedDrainage",
    ElementEnhancedTrackRoad = "elementsEnhancedTrackRoad",
    SupplementaryInformation = "supplementaryInformation",
    NewDefect = "defect",
    LowHeadRoom = "lowheadRoom",
    ElementEnhancedCrossGirder = "elementsEnhancedCrossGirder",
    ElementEnhancedRailBearers = "elementsEnhancedRailBearers",
    ElementEnhancedRivetsBolts = "elementsEnhancedRivetsBolts",
    ElementEnhancedRingBarrel = "elementsEnhancedArchRingBarrel",
    ElementEnhancedHeadwalls = "elementsEnhancedSpandrelsHeadwalls",
    ElementEnhancedAbutments = "elementsEnhancedAbutments",
    ElementEnhancedPiers = "elementsEnhancedPiers",
    ElementEnhancedPointing = "elementsEnhancedPointing",
    ElementEnhancedColumnsCylinders = "elementsEnhancedColumnsCylinders",
    ElementEnhancedTrestlesCrossheads = "elementsEnhancedTrestlesCrossheads",
    ElementEnhancedBedstonesCills = "elementsEnhancedBedstonesCills",
    ElementEnhancedBearings = "elementsEnhancedBearings",
    ElementEnhancedBallastPlatesBoards = "elementsEnhancedBallastPlatesBoards",
    ElementEnhancedLongitudinalTimbers = "elementsEnhancedLongitudinalTimbers",
    ElementEnhancedWaterProofing = "elementsEnhancedWaterProofing",
    ElementEnhancedGuttersDownpipes = "elementsEnhancedGuttersDownpipes",
    ElementEnhancedHandrails = "elementsEnhancedHandrails",
    ElementEnhancedPainting = "elementsEnhancedPainting",
    ElementEnhancedRevetmentWalls = "elementsEnhancedRevetmentWalls",
    ElementEnhancedVegetation = "elementsEnhancedVegetation",
    ElementEnhancedDebris = "elementsEnhancedDebris",
    ElementEnhancedRubbish = "elementsEnhancedRubbish",
    ElementEnhancedNumberPlate = "elementsEnhancedNumberPlate",
    ElementEnhancedDefinedElement1 = "elementsEnhancedDefinedElement1",
    ElementEnhancedDefinedElement2 = "elementsEnhancedDefinedElement2",
    ElementEnhancedDefinedElement3 = "elementsEnhancedDefinedElement3",
    ElementEnhancedDefinedElement4 = "elementsEnhancedDefinedElement4",
}

export const ExaminationActivityName: string[] = [
    ProcessActivityNames.Examination,
    ProcessActivityNames.ExaminationAssetInfo,
    ProcessActivityNames.NewDefects,
    ProcessActivityNames.CoverSheet,
    ProcessActivityNames.ExaminationAssetInfo,
    ProcessActivityNames.PreviousReportedDefects,
    ProcessActivityNames.PreviousRecommendations,
    ProcessActivityNames.SpecialRequirements,
    ProcessActivityNames.LastReportStatus,
    ProcessActivityNames.ExaminersComments,
    ProcessActivityNames.Photos,
    ProcessActivityNames.UnderLoad,
    ProcessActivityNames.StructureViewed,
    ProcessActivityNames.StructureExamined,
    ProcessActivityNames.Elements,
    ProcessActivityNames.ElementsEnhanced,
    ProcessActivityNames.MonitoringDevices,
    ProcessActivityNames.Environment,
    ProcessActivityNames.Timber,
    ProcessActivityNames.Attachments,
    ProcessActivityNames.DimensionSheet,
    ProcessActivityNames.RecordLocation,
    ProcessActivityNames.DefectPrevious,
    ProcessActivityNames.PreviousRecommendation,
    ProcessActivityNames.ElementEnhancedMainGirder,
    ProcessActivityNames.ElementEnhancedDeckFloor,
    ProcessActivityNames.ElementEnhancedWingRetainingWalls,
    ProcessActivityNames.ElementEnhancedDefectParapetsPilasters,
    ProcessActivityNames.ElementEnhancedDrainage,
    ProcessActivityNames.ElementEnhancedTrackRoad,
    ProcessActivityNames.SupplementaryInformation,
    ProcessActivityNames.NewDefect,
    ProcessActivityNames.LowHeadRoom,
    ProcessActivityNames.ElementEnhancedCrossGirder,
    ProcessActivityNames.ElementEnhancedRailBearers,
    ProcessActivityNames.ElementEnhancedRivetsBolts,
    ProcessActivityNames.ElementEnhancedRingBarrel,
    ProcessActivityNames.ElementEnhancedHeadwalls,
    ProcessActivityNames.ElementEnhancedAbutments,
    ProcessActivityNames.ElementEnhancedPiers,
    ProcessActivityNames.ElementEnhancedPointing,
    ProcessActivityNames.ElementEnhancedColumnsCylinders,
    ProcessActivityNames.ElementEnhancedTrestlesCrossheads,
    ProcessActivityNames.ElementEnhancedBedstonesCills,
    ProcessActivityNames.ElementEnhancedBearings,
    ProcessActivityNames.ElementEnhancedBallastPlatesBoards,
    ProcessActivityNames.ElementEnhancedLongitudinalTimbers,
    ProcessActivityNames.ElementEnhancedWaterProofing,
    ProcessActivityNames.ElementEnhancedGuttersDownpipes,
    ProcessActivityNames.ElementEnhancedHandrails,
    ProcessActivityNames.ElementEnhancedPainting,
    ProcessActivityNames.ElementEnhancedRevetmentWalls,
    ProcessActivityNames.ElementEnhancedVegetation,
    ProcessActivityNames.ElementEnhancedDebris,
    ProcessActivityNames.ElementEnhancedRubbish,
    ProcessActivityNames.ElementEnhancedNumberPlate,
    ProcessActivityNames.ElementEnhancedDefinedElement1,
    ProcessActivityNames.ElementEnhancedDefinedElement2,
    ProcessActivityNames.ElementEnhancedDefinedElement3,
    ProcessActivityNames.ElementEnhancedDefinedElement4,
];
