import AdminConstants from "../../../../Constants/AdminConstants";
import * as React from "react";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import RolesList from "../containers/RolesList";
import { useTitle } from "../../../../common/hooks/useTitle";
import { AdminRolePageTitles } from "./pageTitles";

const RolesAdminPage: React.FC = () => {
    useTitle(AdminRolePageTitles.Main);

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={"div"}>
                            <Link to={`${AdminConstants.ResourceName}`}>
                                Admin
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item linkAs={"div"} active>
                            Roles
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>Roles Admin</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <RolesList />
                </Col>
            </Row>
            <Row className="top30"></Row>
        </React.Fragment>
    );
};

export default RolesAdminPage;
