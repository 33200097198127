import { useField } from "formik";
import * as React from "react";
import { Form } from "react-bootstrap";
import { TemplateTableFieldBaseConfig } from "../../../modules/template/domain/types";
import FormControlBase from "./FormControlBase";

interface Props {
    config: TemplateTableFieldBaseConfig;
}

const FormTableCellControlText: React.FC<Props> = (props) => {
    const { config } = props;
    const [field, meta] = useField(config.field);

    return (
        <FormControlBase
            contentConfig={{
                field: config.field,
                showConditions: config.showConditions,
            }}
        >
            <Form.Group key={config.field}>
                <Form.Control
                    type="text"
                    style={{ ...config.style }}
                    className={meta.error ? "is-invalid" : ""}
                    readOnly={config.isReadOnly}
                    {...field}
                    {...props}
                />
            </Form.Group>
        </FormControlBase>
    );
};

export default FormTableCellControlText;
