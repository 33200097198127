import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import * as AdminStore from "../../../../store/admin/store";
import { Row, Col, Button } from "react-bootstrap";
import * as Formik from "formik";
import {
    mapToSelectOptions,
    SelectOptionItem,
} from "../../../../common/types/reactSelect/SelectOptionItem";
import { CustomSelect } from "../../../../common/components/form";
import { Errors } from "../../../../common/types/Errors";

interface FormValues {
    selectedUser: SelectOptionItem;
}

const validate = (values: FormValues) => {
    const errors: Errors = {};

    if (!values.selectedUser?.value) {
        errors.selectedRoleUser = "Required";
    }

    return errors;
};

const RoleAddUserForm: React.FC = () => {
    const dispatch = useDispatch();

    const role = useSelector(
        (state: ApplicationState) =>
            state.admin?.domain.role ?? AdminStore.unloadedState.domain.role,
    );

    const availableRoleUsers = useSelector((state: ApplicationState) => {
        if (!state.admin?.domain.availableRoleUsers) {
            return AdminStore.unloadedState.domain.availableRoleUsers;
        }
        return state.admin?.domain.availableRoleUsers;
    });

    const initialFormValues: FormValues = React.useMemo(() => {
        return { selectedUser: null };
    }, []);

    const uiState = useSelector((state: ApplicationState) => {
        return (
            state.admin?.ui.role.user ?? AdminStore.unloadedState.ui.role.user
        );
    });

    const startAddRoleUser = () => {
        dispatch(AdminStore.actionCreators.startAddRoleUser(role.id));
    };

    const cancel = () => {
        dispatch(AdminStore.actionCreators.cancelAddRoleUser());
    };

    if (uiState.isAdding) {
        return (
            <Formik.Formik
                enableReinitialize
                initialValues={initialFormValues}
                validate={validate}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    dispatch(
                        AdminStore.actionCreators.addRoleUserSubmit(
                            role.id,
                            values.selectedUser?.value,
                        ),
                    );
                    setSubmitting(false);
                    resetForm();
                }}
            >
                {({ isSubmitting }) => (
                    <Formik.Form translate="yes">
                        <Row>
                            <Col>
                                <CustomSelect
                                    name="selectedUser"
                                    label="Add user"
                                    placeholder="Select user..."
                                    options={mapToSelectOptions(
                                        availableRoleUsers,
                                        (u) => u.id,
                                        (u) =>
                                            `${u.userDisplayName} (${u.username})`,
                                    )}
                                />
                            </Col>
                        </Row>
                        <Button
                            variant="primary"
                            type="submit"
                            disabled={isSubmitting}
                        >
                            Add
                        </Button>
                        <Button
                            variant="outline-secondary"
                            className="ml-1"
                            onClick={cancel}
                        >
                            Cancel
                        </Button>
                    </Formik.Form>
                )}
            </Formik.Formik>
        );
    } else {
        return (
            <div className="d-flex justify-content-end">
                <Button className="float-right" onClick={startAddRoleUser}>
                    Add User
                </Button>
            </div>
        );
    }
};

export default RoleAddUserForm;
