import {
    SchedulerItem,
    SchedulerItemProps,
} from "@progress/kendo-react-scheduler";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useParams } from "react-router";
import * as React from "react";

const CustomSchedulerItem: React.FC = (props: SchedulerItemProps) => {
    const { dataItem } = props;
    const dispatch = useDispatch();
    const params = useParams<{
        orgShortName: string;
    }>();

    // This will build the correct redirection URL for either a process or activity
    const redirectToDetails = React.useCallback(() => {
        console.log(dataItem);
        const { id, processId } = dataItem;
        // If item has a process Id property then it is an activity
        const url = processId
            ? `/${params.orgShortName}/process/${processId}/activity/${id}`
            : `/${params.orgShortName}/process/${id}`;
        dispatch(push(url));
    }, [dataItem, dispatch, params.orgShortName]);

    return <SchedulerItem {...props} onDoubleClick={redirectToDetails} />;
};

export default CustomSchedulerItem;
