import { FieldAttributes, useField } from "formik";
import React from "react";
import { Form } from "react-bootstrap";

type Props = {
    label: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    readOnly?: boolean;
} & FieldAttributes<Record<string, unknown>>;

const Checkbox: React.FC<Props> = ({ label, onChange, readOnly, ...props }) => {
    const [field] = useField(props);

    return (
        <Form.Group controlId={field.name}>
            <Form.Check
                name={field.name}
                type="checkbox"
                label={label}
                onChange={onChange ?? field.onChange}
                readOnly={readOnly}
                disabled={readOnly}
            />
        </Form.Group>
    );
};

Checkbox.defaultProps = {
    checked: false,
};

export default Checkbox;
