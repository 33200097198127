import { FilesPageMode } from "./FilesPageMode";
import { DateTime } from "luxon";
import { IdAndName } from "../../../common/types/IdAndName";
import { BasicFileProps, ListedFolder } from "../domain/folder";
import {
    SelectedState,
    SelectedStateItem,
} from "../../../common/components/table/kendo/SelectedState";

export enum EntryType {
    Folder,
    File,
    NewFolder,
    NavigateUp,
}

export interface FileViewEntry extends UnknownRecord {
    type: EntryType;
    inEdit: boolean;
    id: string | null;
    label: string;
    dateModified: Date | null;
    modifiedBy: string | null;
    selected: SelectedStateItem;
    parentId?: string;
    location?: string;
    createdOn?: string;
    metadata?: { [key: string]: string };
}

export interface WithDataItem {
    dataItem: FileViewEntry;
}

export interface GroupedFileViewEntries {
    virtualEntries: FileViewEntry[];
    folderEntries: FileViewEntry[];
    fileEntries: FileViewEntry[];
}

const mapBasicFileProps =
    (type: EntryType) =>
    (
        item: BasicFileProps,
        inEdit: boolean,
        selectedState: SelectedState,
    ): FileViewEntry => {
        const entry = {
            type,
            inEdit,
            selected: selectedState[item.id] ?? false,
            id: item.id,
            label: item.name,
            dateModified: DateTime.fromISO(
                item.lastUpdatedAt || item.createdOn,
            ).toJSDate(),
            modifiedBy: item.lastUpdatedBy || item.createdBy,
        };

        if (item.metadata) {
            Object.keys(item.metadata).forEach((key) => {
                entry[key] = item.metadata[key];
            });
        }
        return entry;
    };
const mapSubFolder = mapBasicFileProps(EntryType.Folder);
const mapFile = mapBasicFileProps(EntryType.File);

function mapSubFolders(
    subFolders: BasicFileProps[] = [],
    editedId: string,
    selectedState: SelectedState,
): FileViewEntry[] {
    return subFolders.map((x) =>
        mapSubFolder(x, x.id === editedId, selectedState),
    );
}

function mapFiles(
    files: BasicFileProps[] = [],
    editedId: string,
    selectedState: SelectedState,
): FileViewEntry[] {
    return files.map((x) => mapFile(x, x.id === editedId, selectedState));
}

function getNavigateUpEntry(path: IdAndName[] = []): FileViewEntry[] {
    switch (path.length) {
        case 0:
        case 1: {
            return [];
        }
        default: {
            const up = path[path.length - 2];
            return [
                {
                    type: EntryType.NavigateUp,
                    inEdit: false,
                    selected: false,
                    id: up.id,
                    label: "[..]",
                    dateModified: null,
                    modifiedBy: null,
                },
            ];
        }
    }
}

function getNewFolderEntry(isAddingNew: boolean): FileViewEntry[] {
    if (isAddingNew) {
        return [
            {
                type: EntryType.NewFolder,
                inEdit: true,
                selected: false,
                id: null,
                label: null,
                dateModified: null,
                modifiedBy: null,
            },
        ];
    }
    return [];
}

export function mapToViewEntries(
    folder: ListedFolder,
    mode: FilesPageMode = { mode: "Default" },
    selectedState: SelectedState = {},
): GroupedFileViewEntries {
    const editedId = mode.mode === "EditExisting" ? mode.id : null;
    return {
        virtualEntries: getNavigateUpEntry(folder?.path).concat(
            getNewFolderEntry(mode.mode === "AddNew"),
        ),
        folderEntries: mapSubFolders(
            folder?.subFolders,
            editedId,
            selectedState,
        ),
        fileEntries: mapFiles(folder?.files, editedId, selectedState),
    };
}

export function getSelectedViewEntries(
    entries: GroupedFileViewEntries,
): GroupedFileViewEntries {
    const filtered = (items) => items.filter((x) => x.selected);
    return {
        virtualEntries: [],
        fileEntries: filtered(entries.fileEntries),
        folderEntries: filtered(entries.folderEntries),
    };
}

export const isContextMenuNeeded =
    (hasAnyFolderModificationPermissions: boolean) =>
    (entry: FileViewEntry): boolean => {
        switch (entry.type) {
            case EntryType.Folder:
                return hasAnyFolderModificationPermissions;
            case EntryType.File:
                return true;
            case EntryType.NewFolder:
            case EntryType.NavigateUp:
                return false;
            default:
                throw new Error(
                    `Not implemented for entry type ${EntryType[entry.type]}`,
                );
        }
    };

export function fileViewEntriesAsIdsAndNames(
    entries: FileViewEntry[],
): IdAndName[] {
    return entries.map(({ id, label }) => ({ id, name: label }));
}
