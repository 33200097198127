import * as React from "react";
import { Row, Col, Breadcrumb } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import pageTitles from "./pageTitles";
import { useGetOrgByShortNameQuery } from "../../../../store/features/organisation/organisation-api-slice";
import { useOrgTitleWithShortName } from "../../../../common/hooks/useTitle";
import ExamInfiniteGrid from "../components/ExaminationsSearch/ExamInfiniteGrid";

const ExaminationSearchPage: React.FC<
    RouteComponentProps<{ orgShortName: string }>
> = (props) => {
    const orgShortName = props.match.params.orgShortName;

    const { data: organisation } = useGetOrgByShortNameQuery(orgShortName, {
        skip: !orgShortName,
    });

    useOrgTitleWithShortName(pageTitles.searchExaminations);

    return (
        <>
            <Row className="top10">
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item active>
                            {organisation?.features?.process?.sideMenuName ??
                                pageTitles.searchExaminations}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>
                        {organisation?.features?.process?.sideMenuName ??
                            pageTitles.searchExaminations}
                    </h1>
                </Col>
            </Row>
            <Row className="top10">
                <Col>
                    <ExamInfiniteGrid organisation={organisation} />
                </Col>
            </Row>
        </>
    );
};

export default ExaminationSearchPage;
