import { FieldArray, Formik, FormikProps, useFormikContext } from "formik";
import * as React from "react";
import { useState } from "react";
import { Button, Form, FormGroup, FormLabel } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { v4 as uuid } from "uuid";
import {
    ShowCondition,
    TemplateContentTableRow,
    TemplateContentStyle,
} from "../../../template/domain/types";
import FormControlBase from "../../../../common/components/dynamic-form/FormControlBase";
import FormControlRiskGrid from "./FormControlRiskGrid";
import { TemplateContentRiskRecord } from "../../domain/types";
import RiskConstants from "../consts/RiskConstants";

interface FormControlRiskConfig {
    field: string;
    label: string;
    style: TemplateContentStyle;
    prompt: string;
    uri: string;
    rows: TemplateContentTableRow[];
    isReadOnly: boolean;
    multiple: boolean;
    showConditions: ShowCondition[];
}

interface Props {
    config: FormControlRiskConfig;
}

interface RiskInfo {
    assessmentRef: string;
    subActivity: string;
    hazard: string;
    residualRisk: number;
    control: string;
}

const FormControlRisk: React.FC<Props> = (props) => {
    const { config } = props;
    const formik = useFormikContext();
    const [isAdding, setIsAdding] = useState(false);

    const onClickAdd = () => {
        setIsAdding(true);
    };

    const value = (formik.values[config.field] ||
        []) as TemplateContentTableRow[];

    const showAddButton =
        !isAdding &&
        !config.isReadOnly &&
        (config.multiple || value.length < 1);

    return (
        <FormControlBase
            contentConfig={{
                field: config.field,
                showConditions: config.showConditions,
            }}
        >
            <FieldArray name={config.field}>
                {(arrayHelpers) => (
                    <Form.Group key={config.field}>
                        <FormLabel>
                            <strong>{config.label}</strong>
                        </FormLabel>
                        {config.prompt && (
                            <Form.Text className="text-muted mb-2">
                                {config.prompt}
                            </Form.Text>
                        )}
                        {showAddButton && (
                            <div>
                                <Button size="sm" onClick={onClickAdd}>
                                    <FaPlus />
                                </Button>
                            </div>
                        )}
                        {isAdding && (
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    assessmentRef: "",
                                    subActivity: "",
                                    hazard: "",
                                    residualRisk: 0,
                                    control: "",
                                }}
                                onSubmit={(
                                    values: RiskInfo,
                                    { resetForm },
                                ): void => {
                                    const riskInfo: TemplateContentRiskRecord =
                                        {
                                            id: uuid(),
                                            assessmentRef: values.assessmentRef,
                                            subActivity: values.subActivity,
                                            hazard: values.hazard,
                                            residualRisk: values.residualRisk,
                                            control: values.control,
                                        };
                                    arrayHelpers.push(riskInfo);
                                    resetForm();
                                    setIsAdding(false);
                                }}
                            >
                                {(formikProps: FormikProps<RiskInfo>) => {
                                    return (
                                        <Form noValidate>
                                            <FormGroup>
                                                <Form.Label
                                                    htmlFor={
                                                        RiskConstants.AssessmentRefField
                                                    }
                                                >
                                                    {
                                                        RiskConstants.AssessmentRefLabel
                                                    }
                                                </Form.Label>
                                                <Form.Control
                                                    name={
                                                        RiskConstants.AssessmentRefField
                                                    }
                                                    onChange={(
                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                    ) => {
                                                        formikProps.setFieldValue(
                                                            RiskConstants.AssessmentRefField,
                                                            event.target.value,
                                                        );
                                                    }}
                                                    value={
                                                        formikProps.values
                                                            .assessmentRef
                                                    }
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Label
                                                    htmlFor={
                                                        RiskConstants.SubActivityField
                                                    }
                                                >
                                                    {
                                                        RiskConstants.SubActivityLabel
                                                    }
                                                </Form.Label>
                                                <Form.Control
                                                    name={
                                                        RiskConstants.SubActivityField
                                                    }
                                                    onChange={(
                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                    ) => {
                                                        formikProps.setFieldValue(
                                                            RiskConstants.SubActivityField,
                                                            event.target.value,
                                                        );
                                                    }}
                                                    value={
                                                        formikProps.values
                                                            .subActivity
                                                    }
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Label
                                                    htmlFor={
                                                        RiskConstants.HazardField
                                                    }
                                                >
                                                    {RiskConstants.HazardLabel}
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={5}
                                                    name={
                                                        RiskConstants.HazardField
                                                    }
                                                    onChange={(
                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                    ) => {
                                                        formikProps.setFieldValue(
                                                            RiskConstants.HazardField,
                                                            event.target.value,
                                                        );
                                                    }}
                                                    value={
                                                        formikProps.values
                                                            .hazard
                                                    }
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Label
                                                    htmlFor={
                                                        RiskConstants.ResidualRiskField
                                                    }
                                                >
                                                    {
                                                        RiskConstants.ResidualRiskLabel
                                                    }
                                                </Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name={
                                                        RiskConstants.ResidualRiskField
                                                    }
                                                    onChange={(
                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                    ) => {
                                                        formikProps.setFieldValue(
                                                            RiskConstants.ResidualRiskField,
                                                            event.target.value,
                                                        );
                                                    }}
                                                    value={
                                                        formikProps.values
                                                            .residualRisk
                                                    }
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Label
                                                    htmlFor={
                                                        RiskConstants.ControlField
                                                    }
                                                >
                                                    {RiskConstants.ControlLabel}
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={5}
                                                    name={
                                                        RiskConstants.ControlField
                                                    }
                                                    onChange={(
                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                    ) => {
                                                        formikProps.setFieldValue(
                                                            RiskConstants.ControlField,
                                                            event.target.value,
                                                        );
                                                    }}
                                                    value={
                                                        formikProps.values
                                                            .control
                                                    }
                                                />
                                            </FormGroup>
                                            <div className="mt-2">
                                                <Button
                                                    variant="primary"
                                                    onClick={() => {
                                                        formikProps.submitForm();
                                                    }}
                                                >
                                                    Add
                                                </Button>
                                                <Button
                                                    variant="secondary"
                                                    className="ml-1"
                                                    onClick={() => {
                                                        formikProps.resetForm();
                                                        setIsAdding(false);
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        )}
                        <div className="mt-3">
                            <FormControlRiskGrid
                                arrayHelpers={arrayHelpers}
                                config={config}
                                isValid={!formik.errors[config.field]}
                            />
                        </div>

                        {formik.errors[config.field] ? (
                            <div className="invalid-feedback display">
                                {formik.errors[config.field]}
                            </div>
                        ) : null}
                    </Form.Group>
                )}
            </FieldArray>
        </FormControlBase>
    );
};

export default FormControlRisk;
