import { ProcessAssignedConstants } from "../../../Constants/ProcessAssignedConstants";
import { dcpApi, Tags } from "../../../store/features/dcpApi";
import { AssignedListItem } from "../domain/types";

type QueryParam = {
    organisationId: string;
    hideCompleted: boolean;
};

export const processAssignedApi = dcpApi.injectEndpoints({
    endpoints: (builder) => ({
        getProccessAssignedList: builder.query<AssignedListItem[], QueryParam>({
            query: ({ organisationId, hideCompleted }: QueryParam) =>
                `${ProcessAssignedConstants.ApiEndpoint}${
                    ProcessAssignedConstants.Ui
                }${ProcessAssignedConstants.AssignedActivitiesListItems}?${
                    ProcessAssignedConstants.OrganisationId
                }=${organisationId}&showCompletedActivities=${!hideCompleted}`,
            providesTags: (_, error) =>
                error ? [] : [{ type: Tags.ProcessAssignedList }],
        }),
    }),
});

export const { useGetProccessAssignedListQuery } = processAssignedApi;
