import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import * as React from "react";
import { Alert, Button } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { parseEndpointError } from "../viewModel/organisationForm";

const OrgFormError: React.FC<{
    error: FetchBaseQueryError;
    onClear: () => void;
}> = ({ error, onClear }) => {
    let message = "There was an error during submision";

    if (error.status === 400) {
        const parsedErr = parseEndpointError(error);

        if (parsedErr.field) return null;

        message = `Validation: ${parsedErr.fieldError}`;
    }

    return (
        <Alert variant="danger">
            {message}
            <Button variant="danger" onClick={onClear}>
                <FaTimes />
            </Button>
        </Alert>
    );
};

export default OrgFormError;
