import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { formatIsoDate } from "../../../helpers/dateTimeHelpers";
import {
    ApprovalResponse,
    ApprovalResponseType,
    ProcessActivityApproval,
} from "../domain/types";

const ApprovalResponseItem: React.FC<{
    data: ApprovalResponse;
    processActivityApproval: ProcessActivityApproval;
}> = (props) => {
    const { data, processActivityApproval } = props;

    let responseType;
    if (data.responseType === 0) {
        responseType =
            processActivityApproval.approvalActionHistoricalDisplayName;
    } else if (data.responseType === 1) {
        responseType =
            processActivityApproval.rejectActionHistoricalDisplayName;
    } else if (data.responseType === 2) {
        responseType = "Reset";
    }

    return (
        <li className="mb-3">
            <Row className="align-items-start">
                <Col xs={12} md="auto">
                    {data.approver.userEmail}
                </Col>

                <Col xs={12} md="auto">
                    <strong>
                        {responseType ??
                            ApprovalResponseType[data.responseType]}
                    </strong>
                </Col>

                <Col xs={12} md="auto" className="text-break">
                    {data.comments.length > 0 && (
                        <>
                            with comments <strong>{data.comments[0]}</strong>
                        </>
                    )}
                </Col>

                <Col xs={12} md="auto" className="text-nowrap">
                    {formatIsoDate(data.performedAt)}
                </Col>
            </Row>
        </li>
    );
};

export default ApprovalResponseItem;
