import * as React from "react";
import ProcessTemplatesListItem from "./ProcessTemplateListItem";
import * as ProcessTemplateStore from "../../../../store/process-template/store";
import { useGetProcessTemplatesByOrgIdQuery } from "../../../../store/features/process-template/process-template-api-slice";
import { useParams } from "react-router";

const ProcessTemplatesList: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const { data: templates } = useGetProcessTemplatesByOrgIdQuery(id);

    return (
        <div>
            {(templates || []).map(
                (
                    item: ProcessTemplateStore.ProcessTemplateListItem,
                    index: number,
                ) => (
                    <ProcessTemplatesListItem
                        key={index.toString()}
                        name={item.name}
                        description={item.description}
                        id={item.id}
                        lastUpdatedAt={item.lastUpdatedAt}
                        version={item.version}
                    />
                ),
            )}
        </div>
    );
};

export default ProcessTemplatesList;
