import React from "react";
import { Button } from "react-bootstrap";
import { FaExclamationCircle } from "react-icons/fa";
import { ListItemState } from "../domain/types";

export class ListItemStateDisabled implements ListItemState {
    button: JSX.Element;
    cardBodyClass: string;
    icon: JSX.Element;

    constructor() {
        this.button = <Button disabled>Disabled</Button>;

        this.icon = (
            <FaExclamationCircle
                name="rowing"
                size="24"
                color="black"
                className="align-self-center"
            />
        );

        this.cardBodyClass = "alert-secondary";
    }
}
