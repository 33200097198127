import * as React from "react";
import { IdAndName } from "../../../../common/types/IdAndName";
import useGridProps from "../../../../common/components/table/kendo/useGridProps";
import {
    ContainsMenuFilter,
    emptyFilterDescriptor,
} from "../../../../common/components/table/kendo/columnFilters";
import { Grid } from "@progress/kendo-react-grid";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import UserGroupUsersTableActions from "./UserGroupUsersTableActions";

interface Props {
    orgId: string;
    userGroupId: string;
    users: IdAndName[];
    canRemoveUsers: boolean;
    isLoading: boolean;
    isFetching: boolean;
}

const UserGroupUsersTable: React.FC<Props> = (props) => {
    const { canRemoveUsers, orgId, userGroupId } = props;

    const UserCommandCell = React.useCallback(
        (props) => (
            <td>
                <UserGroupUsersTableActions
                    orgId={orgId}
                    userGroupId={userGroupId}
                    canRemoveUsers={canRemoveUsers}
                    user={props.dataItem}
                />
            </td>
        ),
        [orgId, userGroupId, canRemoveUsers],
    );

    const { gridProps } = useGridProps(props.users, {
        filterable: true,
        initialFilter: emptyFilterDescriptor,
        sortable: true,
        initialSort: [{ field: "username", dir: "asc" }],
        pageable: true,
    });

    return (
        <Grid {...gridProps}>
            <Column
                field="name"
                title="User Name"
                headerClassName="grid-header"
                columnMenu={ContainsMenuFilter}
            />
            <Column
                field="actions"
                title="Actions"
                cell={UserCommandCell}
                width="200px"
            />
        </Grid>
    );
};

export default UserGroupUsersTable;
