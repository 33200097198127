import {
    DayView,
    MonthView,
    Scheduler,
    SchedulerItemProps,
    SchedulerSlotProps,
    WeekView,
} from "@progress/kendo-react-scheduler";
import { push } from "connected-react-router";
import moment from "moment";
import * as React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import {
    useGetByOrganisationIdQuery,
    usePatchProcessActivityScheduleByIdMutation,
} from "../../../store/features/process-activity/processactivity-api-slice";
import {
    useGetProcessListItemsByOrganisationIdQuery,
    useUpdateProcessMutation,
} from "../../../store/features/process/process-api-slice";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";
import CustomSchedulerSlot from "../components/CustomerSchedulerSlot";
import CustomSchedulerItem from "../components/CustomSchedulerItem";
import SchedulerPageTitles from "./pageTitles";
import { useGetOrgByShortNameQuery } from "../../../store/features/organisation/organisation-api-slice";

interface ProcessUpdate {
    id: string;
    name: string;
    description: string;
    assignedToId: string;
    scheduledStart: string;
    scheduledEnd: string;
}

interface ProcessActivityUpdate {
    id: string;
    scheduledStart: string;
    scheduledEnd: string;
}

const OrgSchedulerListPage: React.FC = () => {
    const dispatch = useDispatch();

    const { organisationId } = useOrganisationId();

    const canViewProcesses = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgProcess.read,
    ).hasPermission;

    const canUpdateProcesses = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgProcess.update,
    ).hasPermission;

    const { data: processListItems } =
        useGetProcessListItemsByOrganisationIdQuery({
            organisationId,
        });

    const orgProcesses =
        processListItems?.filter(
            (processActivity) =>
                processActivity.scheduledStart && processActivity.scheduledEnd,
        ) ?? [];

    const { data: processActivities = [], refetch } =
        useGetByOrganisationIdQuery({
            organisationId: organisationId,
            onlyScheduled: true,
        });

    const organisationProcessActivities = processActivities ?? [];

    const params = useParams<{
        scheduledStart: string;
        orgShortName: string;
    }>();
    const displayDate = params.scheduledStart
        ? new Date(params.scheduledStart)
        : new Date();

    const [schedulerView, setSchedulerView] = React.useState("month");

    const [
        showScheduleUpdateConfirmationModal,
        setScheduleUpdateConfirmationModal,
    ] = React.useState(false);
    const [updatedSchedule, setUpdatedSchedule] = React.useState({
        id: "",
        name: "",
        scheduledStart: "",
        scheduledEnd: "",
        type: "",
        assignedToId: "",
        description: "",
    });
    const [update] = useUpdateProcessMutation();
    const [patchProcessActivitySchedule] =
        usePatchProcessActivityScheduleByIdMutation();

    const [scheduledStart, setScheduledStart] = React.useState("");
    const [
        showProcessCreationConfirmationModal,
        setShowProcessCreationConfirmationModal,
    ] = React.useState(false);

    const handleProcessCreationConfirmationModalClose = React.useCallback(
        () => setShowProcessCreationConfirmationModal(false),
        [],
    );

    const handleProcessCreationConfirmationModalConfirm =
        React.useCallback(() => {
            dispatch(
                push(
                    `/${params.orgShortName}/process/create?${OrganisationConstants.queryParamScheduledStart}=${scheduledStart}`,
                ),
            );
        }, [dispatch, params.orgShortName, scheduledStart]);
    const handleScheduleUpdateConfirmationModalClose = React.useCallback(
        () => setScheduleUpdateConfirmationModal(false),
        [],
    );
    const handleScheduleUpdateConfirmationModal = React.useCallback(
        () => setScheduleUpdateConfirmationModal(true),
        [],
    );

    const UpdateProcess = React.useCallback(
        (values: ProcessUpdate) => {
            update({
                id: values.id,
                name: values.name,
                description: values.description,
                organisationId: organisationId,
                assignedToId: values.assignedToId,
                scheduledStart: values.scheduledStart,
                scheduledEnd: values.scheduledEnd,
            }).finally(refetch);
        },
        [organisationId, refetch, update],
    );

    const UpdateProcessActivity = React.useCallback(
        (updated: ProcessActivityUpdate) => {
            patchProcessActivitySchedule({
                activityId: updated.id,
                scheduledStart: updated.scheduledStart,
                scheduledEnd: updated.scheduledEnd,
            }).finally(refetch);
        },
        [patchProcessActivitySchedule, refetch],
    );

    const handleScheduleUpdateConfirmationModalConfirm =
        React.useCallback(() => {
            updatedSchedule.type === "Process"
                ? UpdateProcess(updatedSchedule)
                : UpdateProcessActivity(updatedSchedule);
            setScheduleUpdateConfirmationModal(false);
        }, [UpdateProcess, UpdateProcessActivity, updatedSchedule]);

    const scheduledProcesses = canViewProcesses
        ? orgProcesses.map((processListItem) => ({
              id: processListItem.id,
              name: processListItem.name,
              scheduledStart: processListItem.scheduledStart
                  ? new Date(processListItem.scheduledStart.toString())
                  : null,
              scheduledEnd: processListItem.scheduledEnd
                  ? new Date(processListItem.scheduledEnd.toString())
                  : null,
              type: "Process",
              assignedToId: processListItem.assignedToId,
              description: processListItem.description,
          }))
        : [];

    const scheduledProcessActivities = canViewProcesses
        ? organisationProcessActivities.map((processActivity) => ({
              id: processActivity.id,
              name:
                  processActivity.processName +
                  " : " +
                  processActivity.displayName,
              scheduledStart: processActivity.scheduledStart
                  ? new Date(processActivity.scheduledStart.toString())
                  : null,
              scheduledEnd: processActivity.scheduledEnd
                  ? new Date(processActivity.scheduledEnd.toString())
                  : null,
              processId: processActivity.processId,
              type: processActivity.type,
              // this property is not used in the scheduler, empty value to allow merging with processes
              assignedToId: "",
              description: processActivity.description,
              parentProcessId: processActivity.parentProcessId,
          }))
        : [];

    const modelFields = {
        id: "id",
        title: "name",
        start: "scheduledStart",
        end: "scheduledEnd",
        type: "type",
        assignedToId: "assignedToId",
        description: "description",
    };

    const handleDataChange = React.useCallback(
        ({ updated }) => {
            if (canUpdateProcesses) {
                setUpdatedSchedule(updated[0]);
                handleScheduleUpdateConfirmationModal();
            }
        },
        [canUpdateProcesses, handleScheduleUpdateConfirmationModal],
    );

    const handleViewChange = React.useCallback((event) => {
        setSchedulerView(event.value);
    }, []);

    const customItem = (props: SchedulerItemProps) => (
        <CustomSchedulerItem {...props} />
    );

    const customSlot = (props: SchedulerSlotProps) => (
        <CustomSchedulerSlot
            setStart={setScheduledStart}
            setShowModal={setShowProcessCreationConfirmationModal}
            {...props}
        />
    );

    useOrgTitleWithShortName(SchedulerPageTitles.Main);

    const { data: organisation } = useGetOrgByShortNameQuery(
        params.orgShortName,
        {
            skip: !params.orgShortName,
        },
    );

    return (
        <div>
            <Modal
                show={showProcessCreationConfirmationModal}
                onHide={handleProcessCreationConfirmationModalClose}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Create{" "}
                        {organisation?.features?.process?.createName ??
                            "Process"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Would you like to create a new{" "}
                    {organisation?.features?.process?.createName ?? "process"}{" "}
                    on {moment(scheduledStart).format("DD-MMM-YYYY h:mm a")}?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleProcessCreationConfirmationModalClose}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleProcessCreationConfirmationModalConfirm}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showScheduleUpdateConfirmationModal}
                onHide={handleScheduleUpdateConfirmationModalClose}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Update{" "}
                        {organisation?.features?.scheduler?.pageHeaderName ??
                            "Schedule"}
                        ?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Would you like to update the{" "}
                    {organisation?.features?.scheduler?.pageHeaderName ??
                        "Schedule"}
                    ?
                    <br /> New Start Date:{" "}
                    {moment(updatedSchedule.scheduledStart).format(
                        "DD-MMM-YYYY h:mm a",
                    )}
                    <br /> New End Date:{" "}
                    {moment(updatedSchedule.scheduledEnd).format(
                        "DD-MMM-YYYY h:mm a",
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleScheduleUpdateConfirmationModalClose}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleScheduleUpdateConfirmationModalConfirm}
                    >
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <h1 className="scheduler-heading top30">
                {organisation?.features?.scheduler?.pageHeaderName
                    ? "Scheduled Tasks"
                    : "Scheduler"}
            </h1>
            <Scheduler
                view={schedulerView}
                onViewChange={handleViewChange}
                onDataChange={handleDataChange}
                data={scheduledProcesses.concat(scheduledProcessActivities)}
                defaultDate={displayDate}
                modelFields={modelFields}
                item={customItem}
                slot={customSlot}
                editable={{
                    remove: false,
                    resize: true,
                    add: false,
                    edit: false,
                    select: true,
                    drag: true,
                }}
            >
                <DayView />
                <WeekView />
                <MonthView />
            </Scheduler>
        </div>
    );
};

export default OrgSchedulerListPage;
