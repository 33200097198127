import { useField, useFormikContext } from "formik";
import * as React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { ShowCondition } from "../../../modules/template/domain/types";
import FormControlBase from "./FormControlBase";
import FormPromptComponent from "./FormPromptComponent";
import { CSSProperties } from "react";

interface FormControlWhat3WordsConfig {
    field: string;
    label: string;
    style: CSSProperties;
    prompt: string;
    uri: string;
    isReadOnly: boolean;
    showConditions: ShowCondition[];
}

interface Props {
    config: FormControlWhat3WordsConfig;
}

const FormControlWhat3Words: React.FC<Props> = (props) => {
    const { config } = props;
    const [field, updateField] = React.useState("");
    const [, , entryHelpers] = useField(config.field);
    const formik = useFormikContext();

    React.useEffect(() => {
        const regex =
            /^\/*[^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]+[.｡。･・︒។։။۔።।][^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]+[.｡。･・︒។։။۔።।][^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]+$/;
        if (regex.test(field) === false) {
            entryHelpers.setError(
                "Invalid What3Words, make sure format is 'one.two.three'",
            );
            return;
        }

        entryHelpers.setValue(`///${field}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field]);

    React.useEffect(() => {
        const value = (formik.values[config.field] as string) ?? "";
        if (value !== "") {
            const trimmedValue = value.replace(/\//g, "");
            updateField(trimmedValue);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FormControlBase
            contentConfig={{
                field: config.field,
                showConditions: config.showConditions,
            }}
        >
            <Form.Label htmlFor={config.field}>
                {config.label} - Click{" "}
                <a
                    href="https://what3words.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    here
                </a>{" "}
                to go to What3Words
            </Form.Label>
            <InputGroup>
                <InputGroup.Text>{"///"}</InputGroup.Text>
                <Form.Control
                    id="inlineFormInputGroup"
                    placeholder="one.two.three"
                    type="text"
                    name="fieldOne"
                    onChange={(e) => updateField(e.target.value)}
                    onBlur={formik.handleBlur}
                    style={{ ...config.style }}
                    value={field}
                    className={formik.errors[config.field] ? "is-invalid" : ""}
                    readOnly={config.isReadOnly}
                />
            </InputGroup>
            <FormPromptComponent config={config} />
        </FormControlBase>
    );
};

export default FormControlWhat3Words;
