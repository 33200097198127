import React from "react";
import { Card } from "react-bootstrap";
import {
    Invitation,
    InvitationStatus,
} from "../../../invitation/domain/Invitation";
import { formatIsoDate } from "../../../../helpers/dateTimeHelpers";

interface Props {
    invitation: Invitation;
}

const InvitationListItem: React.FC<Props> = ({ invitation }) => {
    return (
        <Card className="bottom10">
            <Card.Body>
                <Card.Subtitle className="mb-1">
                    {invitation.email}
                </Card.Subtitle>
                <Card.Text>Status: {invitation.status}</Card.Text>
            </Card.Body>
            <Card.Footer>
                <small className="text-muted">
                    Created at {formatIsoDate(invitation.createdAt)}
                    {invitation.status === InvitationStatus.Accepted && (
                        <>, accepted at {formatIsoDate(invitation.updatedAt)}</>
                    )}
                </small>
            </Card.Footer>
        </Card>
    );
};

export default InvitationListItem;
