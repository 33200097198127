import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { OrganisationListItem } from "../domain/organisation";
import useOrganisationLogo from "../hooks/useOrganisationLogo";
import { OrganisationRowImage } from "./OrganisationRowImage";

interface Props {
    organisation: OrganisationListItem;
}

export const OrganisationRowItem: React.FC<Props> = ({ organisation }) => {
    const orgLogo = useOrganisationLogo(organisation.shortName);

    return (
        <tr className={organisation.shortName}>
            <td colSpan={2}>
                <strong>{organisation.name}</strong>
                <br />
                {
                    <OrganisationRowImage
                        organisation={organisation}
                        orgLogo={orgLogo}
                    />
                }
            </td>
            <td>{organisation.description}</td>
            <td>
                <Button
                    as={Link}
                    variant="primary"
                    to={`${organisation.shortName}`}
                    className="top5 ml-1"
                >
                    View
                </Button>
                {organisation.canManage && (
                    <Button
                        variant="secondary"
                        as={Link}
                        to={
                            OrganisationConstants.RoutePath +
                            "/" +
                            organisation.id
                        }
                        className="top5 ml-1"
                    >
                        Manage
                    </Button>
                )}
            </td>
        </tr>
    );
};
